import axios from "axios";

const psnToken = "dummyToken";
const baseUrl = "/v1";

export const getResource = async id => {
  let url = `${baseUrl}/resource?id=${id}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err.message);
    });
};
export const getMembershipGuideImg = async (isPc = true) => {
  let id = isPc ? "MEMBERSHIP_INFO_IMG_PC" : "MEMBERSHIP_INFO_IMG_MOBILE";
  return await getResource(id);
};
export const getTickerReservationImg = async (isPc = true) => {
  let id = isPc ? "TICKET_RESERVE_IMG_PC" : "TICKET_RESERVE_IMG_MOBILE";
  return await getResource(id);
};
export const getChampionsFieldGuideImg = async _ => {
  let id = "TIGERS_CHAMP_FIELD_IMG";
  return await getResource(id);
};
export const getSeasonPassImg = async (isPc = true) => {
  let id = isPc ? "TICKET_SEASON_IMG_PC" : "TICKET_SEASON_IMG_MOBILE";
  return await getResource(id);
};
export const getSkyboxImg = async (isPc = true) => {
  let id = isPc ? "TICKET_SKYBOX_IMG_PC" : "TICKET_SKYBOX_IMG_MOBILE";
  return await getResource(id);
};
export const getChampionsFieldInfoImg = async (isPc = true) => {
  let id = isPc ? "TIGERS_CHAMP_INFO_IMG_PC" : "TIGERS_CHAMP_INFO_IMG_MOBILE";
  return await getResource(id);
};
export const getOrganizationImg = async (isPc = true) => {
  let id = "TIGERS_CHART_IMG";
  return await getResource(id);
};
export const getCheerPartyImg = async (isPc = true) => {
  let id = isPc ? "TIGERS_CHEER_IMG_PC" : "TIGERS_CHEER_IMG_MOBILE";
  return await getResource(id);
};
export const getChallengersYoutube = async () => {
  let id = "CHALLENGERS_INTRO";
  return await getResource(id);
};

//get list
export const getResourceList = async () => {
  let url = `${baseUrl}/resource/list`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};
