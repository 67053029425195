import React from "react";
import SubMenuComponent from "../common/SubMenu.component";
import SubTabComponent from "./SubTab.component";
const ContComponent = (props) => (
  <div className="mb_cont sub_cont player">
    {props.tabNum < 4 ? <Tab1Content {...props} /> : ""}
    {props.tabNum === 4 ? <Tab4Content {...props} /> : ""}
  </div>
);

const Tab1Content = (props) => (
  <div className="mb_contIn">
    <SubMenuComponent subMenu={props.subMenu} subMenuName={props.subMenuName} />
    <SubTabComponent {...props} />
    {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
    {/* <!-- [N]::등록선수가 없을때 className="noPlayer" --> */}
    <div className="mbTable mb">
      <ul>
        <li>No</li>
        {props.entryStatusObj.list.map((item, idx) => (
          <li key={idx}>{idx + 1}</li>
        ))}
      </ul>
      <ul>
        <li>선수명</li>
        {props.entryStatusObj.list.map((item, idx) => (
          <li key={idx}>{item.playerName}</li>
        ))}
      </ul>
    </div>
    {/* <!-- [N]::등록선수가 없을때 className="noPlayer" --> */}
    <div className="table_Typ01 mbScroll">
      <table>
        <caption>엔트리현황, 선수 현황표</caption>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          {/* <col />
          <col />
          <col /> */}
        </colgroup>
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">선수명</th>
            <th onClick={(evt) => props.onClickBtnSort("POSITION")} scope="col">
              포지션<span className="downArow"></span>
            </th>
            <th scope="col">배번</th>
            <th onClick={(evt) => props.onClickBtnSort("HITTYPE")} scope="col">
              투타<span className="downArow"></span>
            </th>
            <th onClick={(evt) => props.onClickBtnSort("BIRTH")} scope="col">
              생년월일<span className="downArow"></span>
            </th>
            <th onClick={(evt) => props.onClickBtnSort("HEIGHT")} scope="col">
              신장<span className="downArow"></span>
            </th>
            <th onClick={(evt) => props.onClickBtnSort("WEIGHT")} scope="col">
              체중<span className="downArow"></span>
            </th>
            {/* <th onClick={evt => props.onClickBtnSort("INDATE")} scope="col">
              입단일<span className="downArow"></span>
            </th>
            <th onClick={evt => props.onClickBtnSort("PROMISE")} scope="col">
              계약금<span className="downArow"></span>
            </th>
            <th onClick={evt => props.onClickBtnSort("MONEY")} scope="col">
              연봉<span className="downArow"></span>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {
            // 22 시즌 오프일때만 임시 주석처리
            // props.tabNum !== 3 &&
            props.entryStatusObj.list.map((item, idx) => (
              <tr key={idx}>
                <th scope="row">{idx + 1}</th>
                <th scope="row">{item.playerName}</th>
                <td>{item.position2}</td>
                <td>{item.backnum}</td>
                <td>{item.hittype}</td>
                <td>{item.birth}</td>
                <td>{item.height}</td>
                <td>{item.weight}</td>
                {/*  현재 데이터가 없어 indate 조회 안되는 중 */}
                {/* <td>{item.indate}</td>
              <td>{item.promise}</td>
              <td>{item.money}</td> */}
              </tr>
            ))
          }
          {/* <!-- <tr>
                                <td colSpan="11">등록된 선수가 없습니다.</td>
                            </tr> --> */}
        </tbody>
      </table>
    </div>
  </div>
);

const Tab4Content = (props) => (
  <div className="mb_contIn">
    <SubMenuComponent />
    <SubTabComponent {...props} />
    <div className="srch clear">
      <p
        className="btn_srch"
        style={{ cursor: "pointer" }}
        onClick={props.onClickBtnSearch}
      >
        검색
      </p>
      <p>
        <label htmlFor="cell" className="blind">
          월
        </label>
        <select
          id="cell"
          defaultValue={props.expiredEntryStatus.selectedMonth}
          onChange={(evt) =>
            props.onChangeSelectList(evt, "expiredEntryStatus", "selectedMonth")
          }
        >
          {props.monthSelectList.map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.value}월
            </option>
          ))}
        </select>
      </p>
      <p>
        <label htmlFor="cell" className="blind">
          년도
        </label>
        <select
          id="cell"
          defaultValue={props.expiredEntryStatus.selectedYear}
          onChange={(evt) =>
            props.onChangeSelectList(evt, "expiredEntryStatus", "selectedYear")
          }
        >
          {props.yearSelectList.map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.value}시즌
            </option>
          ))}
        </select>
      </p>
    </div>
    <div className="table_Typ01">
      <table>
        <caption>등록/말소 현황표</caption>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">등록일</th>
            <th scope="col">등록선수</th>
            <th scope="col">말소선수</th>
          </tr>
        </thead>
        {Object.keys(props.expiredEntryStatus.listObj).length !== 0 ? (
          <tbody>
            {Object.keys(props.expiredEntryStatus.listObj).map((key, idx) => (
              <tr key={idx}>
                <td>{key}</td>
                <td>{props.expiredEntryStatus.listObj[key]["regY"]}</td>
                <td>{props.expiredEntryStatus.listObj[key]["regN"]}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tr>
            <td colSpan="3">선택하신 기간에 등록/말소된 선수가 없습니다.</td>
          </tr>
        )}
      </table>
    </div>
  </div>
);

export default ContComponent;
