import React from "react";

import PaginationComponent from "../../../common/Pagination.component";
import ImgComponent from "../../../common/Img.component";
import * as ImageHelper from "../../../../helpers/Image.helper";

const ContComponent = props => (
  <div className="mb_cont pictorial">
    <div className="mb_contIn">
      <div className="srch clear">
        <p>
          <label for="cell" className="blind">
            년도
          </label>
          <select
            id="cell"
            value={props.selectedYear}
            onChange={props.onChangeYearSelectList}
          >
            {props.yearSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        </p>
        <p
          onClick={props.onClickBtnSearch}
          className="btn_srch"
          style={{ cursor: "pointer" }}
        >
          검색
        </p>
      </div>
      <div className="pictoIn">
        <ul className="clear">
          {props.list.map((item, idx) => (
            <li key={idx} onClick={evt => props.onClickBtnArticle(evt, item.artcSeq)}>
              <p>
                <ImgComponent
                  imgUrl={item.imgFilePath}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                  imgName={item.artcSubTitle}
                />
              </p>
              <ul>
                <li>{item.artcTitle}</li>
                <li>
                  <span className="look">{item.viewCnt}</span>
                  <span className="good">{item.likeCnt}</span>
                </li>
              </ul>
            </li>
          ))}
        </ul>
        <PaginationComponent
          paginationObj={props.paginationObj}
          onClickBtnPageNum={props.onClickBtnPageNum}
        />
      </div>
    </div>
  </div>
);

export default ContComponent;
