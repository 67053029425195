import React, { Component } from "react";

import SubHeadComponent from "./SubHead.component";
import {
  ContFindCellComponent,
  ContFindEmailComponent
} from "./Cont.component";
import ContSetPwComponent from "./ContSetPw.component";

import * as UserService from "../../../services/User.service";
import * as RegExHelper from "../../../helpers/RegEx.helper";

class FindPwContainer extends Component {
  emailRegEx = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  constructor(props) {
    super(props);
    this.state = {
      tabIdx: 0,
      simpleId: "",
      certSeq: null,
      authNum: "",
      cellObj: {
        num1: "010",
        num2: "",
        num3: "",
        placeholder: "인증번호 6자 입력",
        cellphoneNum: ""
      },
      emailObj: {
        emailId: "",
        emailDomain: "",
        placeholder: "인증번호 6자 입력",
        email: ""
      },
      pwObj: {
        password: "",
        rePassword: ""
      },
      hasAuthorized: false
    };
  }

  onClickBtnTab = tabIdx => {
    if (this.state.tabIdx !== tabIdx) {
      // 탭 바뀌면 입력된 데이터 초기화
      let cellObj = this.state.cellObj;
      cellObj.num1 = "010";
      cellObj.num2 = "";
      cellObj.num3 = "";
      cellObj.cellphoneNum = "";

      let emailObj = this.state.emailObj;
      emailObj.emailId = "";
      emailObj.emailDomain = "";
      emailObj.email = "";

      this.setState({
        tabIdx,
        certSeq: "",
        cellObj,
        emailObj
      });
    }
  };

  onChangeInputSimpleId = evt => this.setState({ simpleId: evt.target.value });
  onChangeInput = (evt, type, key) => {
    if (type === "cell") {
      if (!isNaN(evt.target.value)) {
        let cellObj = this.state.cellObj;
        cellObj[key] = evt.target.value;

        cellObj[
          "cellphoneNum"
        ] = `${cellObj["num1"]}-${cellObj["num2"]}-${cellObj["num3"]}`;

        this.setState({ cellObj });
      }
    } else if (type === "email") {
      let emailObj = this.state.emailObj;
      emailObj[key] = evt.target.value;

      emailObj["email"] = `${emailObj["emailId"]}@${emailObj["emailDomain"]}`;

      this.setState({ emailObj });
    }
  };

  onChangeSelectCellphoneNum1 = evt => {
    let cellObj = this.state.cellObj;
    cellObj["num1"] = evt.target.value;
    this.setState({ cellObj });
  };

  onChangeEmailDomainSelectList = evt => {
    // console.log(onChangeEmailDomainSelectList);
    let emailObj = this.state.emailObj;
    emailObj["emailDomain"] = evt.target.value;
    emailObj["email"] = `${emailObj["emailId"]}@${emailObj["emailDomain"]}`;
    this.setState({ emailObj });
  };

  onChangeAuthNum = evt => {
    if (isNaN(evt.target.value)) {
      return false;
    }
    this.setState({ authNum: evt.target.value });
  };

  onClickBtnRequestAuthNum = async (evt, type) => {
    try {
      const simpleId = this.state.simpleId;
      if (simpleId.trim() === "") {
        throw new Error("아이디를 입력해주세요.");
      }

      if (type === "cell") {
        let cellObj = this.state.cellObj;
        if (cellObj["cellphoneNum"].length < 12) {
          throw new Error("휴대폰 번호를 정확히 입력해주세요.");
        }
        const certSeq = await UserService.requestAuthNumByCellphone(
          "FIND_PW",
          cellObj["cellphoneNum"],
          simpleId
        );
        this.setState({ certSeq });
        alert("인증 번호를 발송했습니다.");
      } else if (type === "email") {
        let emailObj = this.state.emailObj;
        if (!RegExHelper.checkEmail(emailObj["email"])) {
          throw new Error("이메일 주소를 정확히 입력해주세요.");
        }
        const certSeq = await UserService.requestAuthNumByEmail(
          "FIND_PW",
          emailObj["email"],
          simpleId
        );
        this.setState({ certSeq });
        alert("인증 번호를 발송했습니다.");
      }
    } catch (err) {
      alert(err.message);
      if(err.message === "휴면계정입니다.") {
        this.props.history.push('/login', { simpleId: this.state.simpleId, isInactiveMember: true });
      }
    }
  };

  onClickBtnRequestAuth = async (evt, type) => {
    try {
      const simpleId = this.state.simpleId;
      const certSeq = this.state.certSeq;
      const authNum = this.state.authNum;
      if (simpleId.trim() === "") {
        throw new Error("아이디를 입력해주세요.");
      } else if (certSeq.trim() === "") {
        throw new Error("인증요청을 먼저 해주세요.");
      } else if (authNum.trim() === "") {
        throw new Error("인증번호를 입력해주세요.");
      }

      if (type === "cell") {
        const cellphoneNum = this.state.cellObj.cellphoneNum;
        await UserService.requestCheckAuthNumByCellphone(
          "FIND_PW",
          certSeq,
          authNum,
          cellphoneNum,
          simpleId
        );
        this.setState({ hasAuthorized: true });
      } else if (type === "email") {
        const email = this.state.emailObj.email;
        await UserService.requestCheckAuthNumByEmail(
          "FIND_PW",
          certSeq,
          authNum,
          email,
          simpleId
        );
        this.setState({ hasAuthorized: true });
      }
    } catch (err) {
      alert(err.message);
      if(err.message === "휴면계정입니다.") {
        this.props.history.push('/login', { simpleId: this.state.simpleId, isInactiveMember: true });
      }
    }
  };
  onChangeInputPw = async (evt, key) => {
    let pwObj = this.state.pwObj;
    pwObj[key] = evt.target.value;
    this.setState({ pwObj });
  };

  onClickBtnUpdatePw = async evt => {
    try {
      const simpleId = this.state.simpleId;
      const pwObj = this.state.pwObj;
      if (simpleId.trim() === "") {
        alert("입력된 아이디가 없습니다.");
        return;
      } else if (!RegExHelper.checkPassword(pwObj.password)) {
        // RegExHelper.checkPassword 에서 예외 처리
      } else if (pwObj.password !== pwObj.rePassword) {
        alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        return;
      }

      await UserService.updatePw(simpleId, pwObj.password);
      alert("비밀번호가 변경되었습니다.");
      this.props.history.push("/login");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onEnterKey = evt => {
    if (evt.key === "Enter") {
      evt.target.alt === "휴대폰 인증" &&
        this.onClickBtnRequestAuthNum(evt, "cell");
      evt.target.alt === "이메일 인증" &&
        this.onClickBtnRequestAuthNum(evt, "email");
      evt.target.alt === "휴대폰 인증 완료" &&
        this.onClickBtnRequestAuth(evt, "cell");
      evt.target.alt === "이메일 인증 완료" &&
        this.onClickBtnRequestAuth(evt, "email");
      (evt.target.id === "userPw" || evt.target.id === "userPw2") &&
        this.onClickBtnUpdatePw();
    }
  };

  render = () => (
    <div className="container">
      <SubHeadComponent />
      {!this.state.hasAuthorized ? (
        this.state.tabIdx === 0 ? (
          <ContFindCellComponent
            onEnterKey={this.onEnterKey}
            onClickBtnTab={this.onClickBtnTab}
            cellObj={this.state.cellObj}
            simpleId={this.state.simpleId}
            onClickBtnRequestAuthNum={this.onClickBtnRequestAuthNum}
            onClickBtnRequestAuth={this.onClickBtnRequestAuth}
            onChangeInputSimpleId={this.onChangeInputSimpleId}
            onChangeAuthNum={this.onChangeAuthNum}
            onChangeSelectCellphoneNum1={this.onChangeSelectCellphoneNum1}
            onFocusInput={this.onFocusInput}
            onChangeInput={this.onChangeInput}
          />
        ) : (
          <ContFindEmailComponent
            onEnterKey={this.onEnterKey}
            onClickBtnTab={this.onClickBtnTab}
            emailObj={this.state.emailObj}
            simpleId={this.state.simpleId}
            onClickBtnRequestAuthNum={this.onClickBtnRequestAuthNum}
            onClickBtnRequestAuth={this.onClickBtnRequestAuth}
            onChangeInputSimpleId={this.onChangeInputSimpleId}
            onChangeAuthNum={this.onChangeAuthNum}
            onFocusInput={this.onFocusInput}
            onChangeInput={this.onChangeInput}
            onChangeEmailDomainSelectList={this.onChangeEmailDomainSelectList}
          />
        )
      ) : (
        <ContSetPwComponent
          onEnterKey={this.onEnterKey}
          pwObj={this.state.pwObj}
          onChangeInputPw={this.onChangeInputPw}
          onClickBtnUpdatePw={this.onClickBtnUpdatePw}
        />
      )}
    </div>
  );
}

export default FindPwContainer;
