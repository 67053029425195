import React, {useEffect, useState} from "react";
import SubMenuComponent from "../common/SubMenu.container";
import Latest from "./content/Latest";
import Old200325 from "./content/Old200325";
import Old230228 from './content/Old230228'

const ContPrivacyComponent = props => {
  const [version, setVersion] = useState("Latest")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [version])

  return (
    <div className="mb_cont sub_cont policy">
      <div className="mb_contIn">
        <SubMenuComponent subMenu={props.subMenu}/>
        {
          version === "Latest" ?
          <Latest setVersion={setVersion} /> :
          version === "Old200325" ?
            <Old200325 setVersion={setVersion} /> :
            <Old230228 setVersion={setVersion} />
        }
      </div>
    </div>
  )
};

export default ContPrivacyComponent;
