import React from 'react';
import SubMenuComponent from '../common/SubMenu.container';
const ContOperationComponent = props => (
	<div className="mb_cont sub_cont policy">
		<div className="mb_contIn">
			<SubMenuComponent
				subMenu={props.subMenu} />
			<article>
				<h4>KIA타이거즈 홈페이지 운영정책</h4>
				<h5>1. 일반원칙</h5>
				<p>1) 운영정책은 약관에서 정한 사항을 보충하는 역할을 하며, 서비스 내에서 발생할 수 있는 문제에 대하여 일관성 있게 대처하기 위하여 회사가 제공하는 서비스 운영 기준과 회원이 지켜야 할 세부적인 사항이 규정되어 있습니다.<br />
					2) 본 운영정책은 KIA타이거즈 홈페이지(이하 “당사이트”)에 적용됩니다.<br />
					3) 본 운영정책은 “당사이트”를 이용하기 위해 회원 가입하는 절차에서 동의를 받고 있으며, 회원 가입 이후부터 효력이 발생합니다.<br />
					4) 회사는 더 나은 서비스 제공을 위하여 수시로 운영정책을 변경할 수 있으며, 변경되는 내용을 이용자가 쉽게 알 수 있는 방법(홈페이지 공지, 이메일 통보 등)으로 고지합니다.<br />
					5) 게시물이란 이용자가 게시판 등 웹 서비스를 이용함에 있어 서비스상에 게시한 문자, 그림, 음성 또는 이들의 조합으로 이루어진 정보 등의 글, 사진, 동영상을 의미합니다.<br />
					6) 운영정책에 언급되지 않은 사항은 약관 및 본 운영정책의 취지, 회사 내부적인 판단, 그리고 관계법령을 통해 조율될 수 있습니다.</p>
			</article>
			<article>
				<h5>2. 회원의 권리와 의무</h5>
				<h6>2-1 회원의 권리</h6>
				<p>1) 회원은 ‘당 사이트’의 원활한 이용을 위해 이메일(kiatigers@kiatigers.co.kr), 전화상담 및 대면상담을 통해 서비스에 관련한 건의, 문의 및 제보 등을 접수할 수 있습니다.<br />
					2) 서비스를 이용하는 중 다른 회원으로부터 운영정책 상 위배되는 행동에 의해 피해를 입은 경우, 회사에 이를 제보해 운영정책의 적용을 요청할 수 있습니다.</p>
				<h6>2-2 회원의 의무</h6>
				<p>1) 고객은 회원가입시 정확한 정보를 기입해야 하며, 허위 정보를 입력하거나 타인의 신상정보를 도용하여 가입한 경우, ‘서비스 이용약관’ 제5조(이용계약체결). 제19조(이용제한) 항목에 의거하여 가입거부, 계약해지 또는 서비스 이용제한을 받게 되며, 도용에 따른 법적 조치가 진행될 수 있습니다.<br />
					2) 회원이 운영정책을 위반할 시에 회사는 규정에 따라 서비스 이용제한 및 이용계약 해지를 실시할 수 있으며, 운영정책을 숙지하지 않아 발생한 피해에 대해서는 회사가 보상하지 않습니다.<br />
					3) 회원은 표(게시판 이용제한기준표)에서 명시하는 각 규정을 위반할 경우 서비스 이용제한 및 이용계약해지 등을 받게 됩니다.<br />
					4) 회원은 다음행위를 하여서는 안됩니다.</p>
				<ul className="txtBar">
					<li>가입 신청 또는 회원정보 변경시 허위내용의 등록</li>
					<li>다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위</li>
					<li>다른 사용자의 개인정보를 수집 또는 저장하는 행위</li>
					<li>타인의 사생활을 침해할 수 있는 내용을 전송, 게시, 게재, 쪽지 또는 기타의 방법으로 전송하는 행위</li>
					<li>타인의 개인정보(성명, 주민등록번호, 주소, 전화번호, 사진, 영상 등에 의해 특정 개인을 알아볼 수 있는 정보)를 동의 없이 전송, 게시, 게재, 쪽지 또는 기타의 방법으로 전송하는 행위</li>
					<li>“당사이트”가 게시한 정보의 변경하거나 “당사이트”의 동의 없이 영리 또는 비영리 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위</li>
					<li>“당사이트”가 정한 정보 이외의 정보(컴퓨터프로그램 등) 등의 송신 또는 게시</li>
					<li>해킹 또는 컴퓨터바이러스, 불법 프로그램의 유포</li>
					<li>“당사이트”의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의편지, 피라미드조직 기타 다른 형태의 권유를 게시, 게재 또는 기타의 방법으로 전송하는 행위</li>
					<li>“당사이트”와 기타 제3자의 업무를 방해하는 행위</li>
					<li>법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 쪽지 또는 기타의 방법으로 전송하는 행위</li>
					<li>“당사이트”의 동의 없이 영리를 목적으로 ‘서비스’를 이용하는 행위 (예를 들어, 커뮤니티 게시판을 통해 이득을 목적으로 물건, 입장권 등을 판매하는 행위)</li>
					<li>“당사이트”와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위 (예를 들어, 음원, 웹툰, 기사, 사진, 영상물 등을 저작권자 동의 없이 전체 또는 일부 편집하여 게재하는 행위)</li>
					<li>허위사실의 작성 또는 유포</li>
					<li>“당사이트” 및 타인의 명예를 훼손하는 행위</li>
					<li>저속, 음란, 모욕적, 위협적인 내용을 게시, 게재 또는 기타의 방법으로 전송하는 행위</li>
					<li>타인에 대한 비방, 비난, 비하, 조롱하는 내용을 게시, 게재 또는 기타의 방법으로 전송하는 행위</li>
					<li>타인의 의사에 반하여 광고성 정보 등을 지속적으로 게시 및 전송하는 행위</li>
					<li>일정한 내용을 지속적으로 게재 및 전송하여 타인의 “당사이트” 이용을 방해하는 행위</li>
					<li>범죄행위를 목적으로 하거나 기타범죄행위와 관련된 행위</li>
					<li>“당사이트” 또는 제3자를 가장 또는 사칭하여 글을 게시하거나 메일을 발송하는 행위</li>
					<li>수동과 자동을 불문하고 부정한 방법을 사용하거나 타인의 조회(클릭)를 유도하여 인위적으로 조회를 발생시키는 행위</li>
					<li>특정 지역이나 종교, 인종, 장애 등을 비하, 조롱하거나 비난하는 행위</li>
					<li>선량한 풍속, 기타사회질서, 윤리를 해하는 행위</li>
					<li>서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
					<li>기타 커뮤니티 게시판의 용도와 무관하다고 판단되는 내용을 게시, 게재 또는 기타의 방법으로 전송하는 행위</li>
				</ul>
			</article>
			<article>
				<h5>3. 이용제한정책</h5>
				<h6>1) 가입제한</h6>
				<p>“당사이트”는 안정적인 서비스 운영 및 이용자의 보호를 위해 다음과 같은 경우에 “회원”의 이용계약해지 또는 가입 제한할 수 있습니다.<br />
					① “회원”이 이용약관 및 운영정책 위반으로 영구이용정지된 이력이 있는 경우<br />
					② “회원”이 이용약관 및 운영정책을 중대하게 위반하는 행동을 한 후 자진하여 탈퇴한 이력이 있는 경우</p>
				<h6>2) 이용제한</h6>
				<p>① 이용제한의 종류는 아래와 같습니다.
				<ul className="txtBar">
						<li>영구이용정지: “당사이트”의 이용계약해지(탈퇴) 및 재가입 불허</li>
						<li>이용계약해지: “당사이트”의 이용계약해지(탈퇴) 및 일정 기간 재가입 제한</li>
						<li>글쓰기 제한: “당사이트”의 커뮤니티 게시판 글쓰기 권한 정지</li>
						<li>게시물 삭제: 이용약관 및 운영정책에 위반되는 게시물 삭제</li>
						<li>경고: 서비스 이용에 대한 주의 및 권고 등</li>
					</ul>

					<br />
					② 회사는 “영구이용정지”, “이용계약해지”, “글쓰기 제한” “게시물 삭제”, “경고”를 동시에 적용할 수 있습니다.<br />
					③ 동시에 2가지 이상의 약관위반 행위가 확인된 경우, 회사는 각 행위에 해당하는 제재를 병과할 수 있습니다.<br />
					④ 회사는 다른 이용자의 약관위반 행위에 가공하거나 공모한 이용자 또는 약관위반 행위를 돕거나 그로 인해 부당한 이득을 취한 이용자에 대해서도 해당 약관위반 행위에 대한 제재를 적용할 수 있습니다.<br />
					⑤ 회사는 제재 시 이용제한내용, 이용제한사유를 쪽지, 댓글 등을 통해 고지합니다.</p>
				<h6>3) 이용제한 기준표</h6>
				<p>① 게시판 이용제한기준은 아래와 같습니다. (표 1, 2, 3, 4 참조)</p>
				<h6>이용제한 기준표 1</h6>
				<div className="polyTbOver">
					<table className="polyTb thBg">
						<caption>이용제한 기준표 1</caption>
						<colgroup>
							<col />
							<col />
							<col />
							<col />
							<col />
						</colgroup>
						<thead>
							<tr>
								<th colSpan="2">구분</th>
								<th>내용</th>
								<th>이용제한</th>
								<th>비고</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th rowSpan="2">개인정보보호법<br />주민등록법</th>
								<td style={{ width: "10%" }}>개인정보도용</td>
								<td>
									1) 타인의 개인정보도용<br />
									2) 타인 아이디, 비번을 도용하여 부정 사용하는 행위<br />
									3) 타인의 동의 없이 타인 명의의 계정사용<br />
									4) 타인의 개인정보를 이용한 가입 및 활동 등
							</td>
								<td rowSpan="5" className="txtCenter">영구이용정지 및<br />이용계약해지(재가입 불허)</td>
								<td rowSpan="5" className="txtCenter">발견 즉시<br />이용제한 시행</td>
							</tr>
							<tr>
								<td>개인정보침해</td>
								<td>
									1) 다른 사용자의 개인정보 수집 또는 저장행위<br />
									2) 타인의 Privacy를 침해할 수 있는 내용이나 개인정보(성명, 주민등록번호, 주소,전화번호, 사진, 영상 등에 의해 특정 개인을 알아볼 수 있는 정보 등)를 동의 없이 전송,게시, 게재, 쪽지 또는 기타의 방법으로 전송하는 행위
							</td>
							</tr>
							<tr>
								<th>정보통신망법<br />컴퓨터프로그램<br />보호법 등</th>
								<td>서비스의 비도덕적 이용</td>
								<td>1) 해킹, 비정상적인 접속 시도<br />
									2) 컴퓨터 바이러스 및 불법프로그램의 유포<br />
									3) 악성코드가 포함되어 있거나 시스템 장애를 유도 하는 내용 게재<br />
									4) 당 사이트가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시</td>
							</tr>
							<tr>
								<th rowSpan="3">기타 관련법령</th>
								<td>범죄행위 관련</td>
								<td>범죄행위를 목적으로 하거나 기타범죄행위와 관련된 행위</td>
							</tr>
							<tr>
								<td>관계자 사칭</td>
								<td>구단 직원 및 관계자임을 사칭 또는 가장하거나 사칭하여 다른 회원으로 부터 이득을 취하는 행위</td>
							</tr>
							<tr>
								<td>명예훼손 모욕죄<br />폭행죄(폭력)</td>
								<td>당사이트 이용과 관련하여 타인을 명예훼손, 모욕, 폭행한 경우</td>
								<td className="txtCenter">이용계약해지 및 향후 5년간 재가입 불허</td>
								<td className="txtCenter">당사이트 이용시 발생한 분쟁으로 법적 처벌 확정시 이용제한 즉시 시행</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h6>이용제한 기준표 2</h6>
				<div className="polyTbOver">
					<table className="polyTb thBg tdCenter">
						<caption>이용제한 기준표 2</caption>
						<colgroup>
							<col />
							<col />
							<col />
							<col />
							<col />
							<col />
							<col />
						</colgroup>
						<thead>
							<tr>
								<th>구분</th>
								<th style={{ width: "30%" }}>내용</th>
								<th>1차</th>
								<th>2차</th>
								<th>3차</th>
								<th>4차</th>
								<th>5차</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>사칭 또는 가장</th>
								<td>타인을 사칭 또는 가장하여 게시물을 게재하거나 전자우편, 쪽지 등을 전송하는 행위</td>
								<td rowSpan="4">글쓰기 1주일 제한 및 해당 게시물 삭제</td>
								<td rowSpan="4">글쓰기 2주일 제한 및 해당 게시물 삭제</td>
								<td rowSpan="4">글쓰기 1개월 제한 및 해당 게시물 삭제</td>
								<td rowSpan="4">글쓰기 3개월 제한 및 해당 게시물 삭제</td>
								<td rowSpan="4">이용계약 해지 (회원탈퇴)및 해당 게시물 삭제</td>
							</tr>
							<tr>
								<th>타인에 대한 위협, 협박</th>
								<td>현실에 대한 위협 또는 상대방에게 공포심을 느끼게 하는 표현이나 행동을 하는 행위</td>
							</tr>
							<tr>
								<th>명예 훼손 및 모욕적 언행</th>
								<td>타인에 대한 근거없는 조롱, 비방, 비난, 비하, 인신 공격 등의 내용을 게재하는 행위</td>
							</tr>
							<tr>
								<th>허위사실 유포</th>
								<td>허위 사실의 작성 및 유포</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="txtRed">* 상기 명시된 제한 기준 5회 위반시 이용계약 해지 및 향후 3년간 재가입 불허</p>
				<h6>이용제한 기준표 3</h6>
				<div className="polyTbOver">
					<table className="polyTb thBg tdCenter">
						<caption>이용제한 기준표</caption>
						<thead>
							<tr>
								<th>구분</th>
								<th style={{ width: "30%" }}>내용</th>
								<th style={{ width: "7.7%" }}>1차</th>
								<th style={{ width: "7.7%" }}>2차</th>
								<th style={{ width: "7.7%" }}>3차</th>
								<th style={{ width: "7.7%" }}>4차</th>
								<th style={{ width: "7.7%" }}>5차</th>
								<th style={{ width: "7.7%" }}>6차</th>
								<th style={{ width: "7.7%" }}>7차</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>반국가적 내용 게재</th>
								<td>반국가적 내용의 게시물 게재</td>
								<td rowSpan="8">경고 및 해당 게시물 삭제</td>
								<td rowSpan="8">글쓰기 1주일 제한 및 해당 게시물 삭제</td>
								<td rowSpan="8">글쓰기 2주일 제한 및 해당 게시물 삭제</td>
								<td rowSpan="8">글쓰기 1개월 제한 및 해당 게시물 삭제</td>
								<td rowSpan="8">글쓰기 2개월 제한 및 해당 게시물 삭제</td>
								<td rowSpan="8">글쓰기 3개월 제한 및 해당 게시물 삭제</td>
								<td rowSpan="8">이용계약 해지 (회원탈퇴)및 해당 게시물 삭제</td>
							</tr>
							<tr>
								<th>당 사이트가 게시한 정보의 변경</th>
								<td>당 사이트가 게시한 내용의 무단 변경하거나 이를 편집하여 영리, 비영리 목적으로 제 3자에게 제공하는 행위 등</td>
							</tr>
							<tr>
								<th>영리 목적의 당사이트 이용</th>
								<td>당 사이트의 동의 없이 영리 목적으로 ‘서비스’를 이용하는 행위 (예를 들어, 커뮤니티 게시판을 통해 이득을 목적으로 물건, 입장권을 판매하는 행위)</td>
							</tr>
							<tr>
								<th>상업,광고,홍보행위</th>
								<td>1) 당 사이트의 승인을 받지 아니한 광고, 판촉물, 스팸메일 등을 전송, 게재 <br />
									2) 타인의 의사에 반하여 광고성 정보 등을 지속적으로 게시 및 전송하는 행위</td>
							</tr>
							<tr>
								<th>게시판 도배</th>
								<td>일정 내용의 글을 반복적으로 올려 다수 이용자들의 사이트 이용을 방해하는 행위</td>
							</tr>
							<tr>
								<th>인위적인 조회수 조작</th>
								<td>수동과 자동을 불문하고 부정한 방법을 사용하거나 타인의 조회(클릭)를 유도하여 인위적으로 조회를 발생시키는 행위</td>
							</tr>
							<tr>
								<th>저속, 음란, 비상식적 내용의 게시물 삭제</th>
								<td>선정적 단어 등을 통해 성적 수치심을 느끼게 하는 내용을 게재하는 행위</td>
							</tr>
							<tr>
								<th>지역, 종교, 인종, 장애 등을 비하, 조롱, 비난하는 행위</th>
								<td>특정 지역이나 종교, 인종, 장애 등을 비하, 조롱하거나 비난하는 행위</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="txtRed">※ 상기 명시된 제한 기준 7회 위반시 이용계약해지 및 향후 3년간 재가입 불허</p>
				<h6>이용제한 기준표 4</h6>
				<div className="polyTbOver">
					<table className="polyTb thBg tdCenter">
						<caption>이용제한 기준표</caption>
						<thead>
							<tr>
								<th>구분</th>
								<th className="w30" style={{ width: "30%" }}>내용</th>
								<th>1차</th>
								<th>2차</th>
								<th>3차</th>
								<th>4차</th>
								<th>5차</th>
								<th>6차</th>
								<th>7차</th>
								<th>8차</th>
								<th>9차</th>
								<th>10차</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>클린호사방제도</th>
								<td>게시물, 댓글에 욕설 게재</td>
								<td colSpan="3">글쓰기 1주일 제한</td>
								<td colSpan="3">글쓰기 2주일 제한</td>
								<td colSpan="3">글쓰기 30일 제한</td>
								<td>이용계약 해지<br />(회원탈퇴)</td>
							</tr>
							<tr>
								<th>저작권 침해</th>
								<td>저작권 관련 게시물 게재<br />(기사, 사진, 웹툰, 음원, 영상 등)</td>
								<td colSpan="10">해당 게시물 삭제 및 경고(5회 이상 위반시 이용제한 시행)</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="txtRed">※ 클린호사방제도 10회 위반시 이용계약해지 및 향후 3년간 재가입 불허</p><br />
				<p>② 아래의 행위에 대해서는 “당 사이트” 게시물의 내용과 성격, 수위, 회사 내부적인 판단에 따라 서비스 이용에 제한을 시행할 수 있습니다.
				<ul className="txtBar">
						<li>법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 쪽지 또는 기타의 방법으로 전송하는 행위</li>
						<li>선량한 풍속, 기타사회질서, 윤리를 해하는 행위</li>
						<li>서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
						<li>게시판 성격과 무관하거나 커뮤니티를 저해하는 게시물을 게재하는 행위</li>
						<li>기타 약관, 각 게시판 정책 및 관련 법령에서 금지하는 게시물을 게재하는 행위</li>
					</ul>
				</p>
				<p>③ 이용제한기준 중 이용제한기준표2의 명예훼손, 모욕죄, 폭행죄에 따른 법적 처벌 확정과 이용제한기준표3의 내용은 “회원”이 관련자료를 수반하여 제보하는 경우 “당사이트” 관리자가 사실관계를 확인하여 이용제한을 시행합니다.<br />
					④ 게시판 이용제한기준표에 따른 위반사항은 재가입 여부에 상관없이 누적되어 적용됩니다.<br />
					⑤ 게시판 이용제한기준은 “회원”이 작성한 게시물 (게시판에 올린 게시물, 댓글, 쪽지 포함) 1개당 1회 위반으로 간주합니다.</p>
			</article>
			<article>
				<h5>4. 계정관리</h5>
				<p>1) 계정관리는 회원 본인 스스로 주의를 기울여야 합니다.<br />
					2) 회원 본인이 정보를 잘못 등록하거나, 관리 소홀 및 부주의로 발생한 모든 문제의 책임은 1차적으로 회원 본인에게 있습니다.<br />
					3) 회원은 타인의 개인정보를 이용해서는 안됩니다. 타인의 주민등록번호, 계정 등을 도용하는 행위는 관련 법률에 의거하여 형사처벌 대상이 됩니다.<br />
					4) 개인정보보호정책에 입각하여 ‘당 사이트’ 관리자는 특정 고객의 개인정보를 타인에게 유출하지 않습니다. (단, 관련 법령에서 정하고 있는 경우 혹은 서비스 내의 문제로 공공기관의 수사 협조 요청이 있을 경우 고객 정보를 해당 기관에 제공할 수 있습니다.)</p>
			</article>
			<article>
				<h5>5. 신고∙문의 방법 및 이의제기</h5>
				<p>- 이용자는 “당 사이트”의 1:1문의, 이메일 문의, 전화상담, 대면상담을 통해 게시판 서비스를 포함하여 계정과 관련한 각종 문의, 요청, 건의, 제재와 관련된 이의제기를 할 수 있습니다.</p>
				<p>
					<span className="txtRed">[전화상담] </span>
					운영시간 : 월요일 ~ 금요일(09:00~18:00) (단, 시즌 중에는 경기일정에 따라 상이해질 수 있습니다)<br />
					전화번호 : 070-7686-8000
			</p><br />
				<p>
					<span className="txtRed">[대면상담]</span>운영시간 : 월요일 ~ 금요일(09:00~18:00) (단, 시즌 중에는 경기일정에 따라 상이해질 수 있습니다)<br />
					주소 : 광주광역시 북구 서림로 10번지 광주-기아 챔피언스 필드 2층
			</p>
			</article>

		</div>
	</div>
)

export default ContOperationComponent;