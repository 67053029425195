import React, {Component} from "react"
import {Redirect} from "react-router-dom"
import cookie from "react-cookies"
import SubHeadComponent from "./SubHead.component"
import ContComponent from "./Cont.component"

import SubHeadConfirmPasswordComponent from "./SubHeadConfirmPassword.component"
import ContConfirmPasswordComponent from "./ContConfirmPassword.component"

import * as UserService from "../../services/User.service"
import * as AuthService from "../../services/Auth.service"
import * as RegExHelper from "../../helpers/RegEx.helper"
import * as CommonHelper from "../../helpers/Common.helper"
import * as AuthHelper from "../../helpers/Auth.helper"
import * as DateHeler from "../../helpers/Date.helper"
import {decodeAES256} from "../../helpers/Crypto.helper"

class MypageContainer extends Component {
  constructor(props) {
    super(props)

    const userObj = {
      simpleId: "",
      memberName: "",
      memberDivDispNm: "",
      memberId: "",

      mobpNo: "",
      mobpNo1: "",
      mobpNo2: "",
      mobpNo3: "",
      smsYn: "",

      postNo: "",
      addr1: "",
      addr2: "",

      memberEmail: "",
      memberEmailId: "",
      memberEmailDomain: "",
      memberEmailDomainSelectValue: "",
      emailYn: "",

      memberPw: "",
      memberNewPw: "",
      memberNewPwRe: ""
    }

    const mobpAuthObj = {
      hasAuth: true,
      mobpNo: "",
      mobpNo1: "",
      mobpNo2: "",
      mobpNo3: "",
      certNo: null,
      certSeq: null
    }

    const authObj = {
      password: ""
    }

    const secessionObj = {
      type: 1,
      reason: "",
      isOpen: false
    }

    this.state = {
      hasPermission: false,
      loginInfo: AuthHelper.getLoginInfo(),
      userObj,
      mobpAuthObj,
      authObj,
      secessionObj,
      cellphoneNum1SelectList: CommonHelper.getCellphoneNum1List(),
      isOpenedAddressSearchPopup: false,
      isRealName: AuthHelper.checkHasRealName(),
      redirect: null,
      myPageTabOptions: {
        status: 1,
        subHead: "내 정보 관리"
      },
      blindList: null,
      blindListAllChecked: false,
      blindReqData: []
    }
  }

  componentDidCatch = (err, info) => {
    console.log("componentDidCatch")
    console.error(err)
    console.log(info)
  }

  componentDidMount = async (_) => {
    window.addEventListener("message", this.onMessage)
    try {
      const result = await UserService.getMyInfo()
      // 20230225 blyse
      const blindList = await UserService.getBlindList(decodeAES256(result.user.memberId))

      blindList.list.map((item, idx) => {
        item.regDttm = DateHeler.convertTimestampToDatetime(item.regDttm)
        item.isChecked = false
      })
      this.setState({blindList: blindList.list})

      let userInfo = result.user
      if (!userInfo["memberName"]) {
        userInfo["memberName"] = this.state.loginInfo.user.memberName
      }

      let email = ""
      let emailId = ""
      let emailDomain = ""
      if (userInfo["memberEmail"]) {
        email = decodeAES256(userInfo["memberEmail"])
        emailId = email.split("@")[0]
        emailDomain = email.split("@")[1]
      }

      let userMobpNo = decodeAES256(userInfo.mobpNo)

      let mobpNo1 = userMobpNo.substr(0, 3)
      let mobpNo2 = userMobpNo.length === 11 ? userMobpNo.substr(3, 4) : userMobpNo.substr(3, 3)
      let mobpNo3 = userMobpNo.length === 11 ? userMobpNo.substr(7, 4) : userMobpNo.substr(6, 4)

      const userObj = {
        simpleId: decodeAES256(userInfo.simpleId),
        memberName: decodeAES256(userInfo.memberName),
        memberDivDispNm: userInfo.memberDivDispNm,
        memberId: decodeAES256(userInfo.memberId),
        mobileCardNo: decodeAES256(userInfo.mobileCardNo),

        mobpNo: userInfo.mobpNo,
        mobpNo1,
        mobpNo2,
        mobpNo3,
        smsYn: userInfo.smsYn,

        postNo: decodeAES256(userInfo.postNo),
        addr1: decodeAES256(userInfo.addr1),
        addr2: decodeAES256(userInfo.addr2),

        memberEmail: email,
        memberEmailId: emailId,
        memberEmailDomain: emailDomain,
        memberEmailDomainSelectValue: "",
        emailYn: userInfo.emailYn,

        memberPw: "",
        memberNewPw: "",
        memberNewPwRe: "",
        ad_sms_terms: ""
      }

      // get terms
      const terms_result = await UserService.getTermsList();
      const termsList = terms_result.list;
      const term8 = termsList.filter(t => t.termsKind == 8)[0];
      userObj.ad_sms_terms = term8["termsContents"];

      const mobpAuthObj = {
        hasAuth: true,
        mobpNo: userInfo.mobpNo,
        mobpNo1,
        mobpNo2,
        mobpNo3,
        certNo: null,
        certSeq: null
      }
      console.log("userObj", userObj)
      console.log("loginInfo", this.props.loginInfo)
      this.setState({userObj, mobpAuthObj})
      console.log(result)
    } catch (err) {
      console.error(err)
      alert(err)
      AuthHelper.removeLoginInfo()
      this.props.history.push("/")
    }
  }

  componentWillUnmount = (_) => {
    window.removeEventListener("message", this.onMessage)
  }

  onChangeSelectList = (evt, objName, key) => {
    console.log(`onChangeSelectList`, evt, objName, key)
    let obj = this.state[objName]
    obj[key] = evt.target.value
    if (objName === "userObj" && key === "mobpNo1") {
      obj["mobpNo"] = evt.target.value + this.state.userObj.mobpNo2 + this.state.userObj.mobpNo3
    } else if (objName === "userObj" && key === "emailDomainSelectValue") {
      obj["memberEmail"] = `${obj["memberEmailId"]}@${evt.target.value}`
      obj["memberEmailDomain"] = evt.target.value
    }
    this.setState({[objName]: obj})
  }
  onChangeInput = (evt, objName, key) => {
    console.log(`onChangeInput`, evt, objName, key)
    if ((key === "mobpNo2" || key === "mobpNo3") && isNaN(evt.target.value)) {
      return false
    }

    let obj = this.state[objName]
    obj[key] = evt.target.value
    if (objName === "userObj" && (key === "memberEmailId" || key === "memberEmailDomain")) {
      obj["memberEmail"] = `${obj["memberEmailId"].trim()}@${obj["memberEmailDomain"].trim()}`
    } else if (objName === "mobpAuthObj") {
      obj["hasAuth"] = obj["mobpNo"] === obj["mobpNo1"] + obj["mobpNo2"] + obj["mobpNo3"]
    }

    this.setState({[objName]: obj})
  }

  onClickBtnConfirmPassword = async (evt) => {
    try {
      const password = this.state["authObj"]["password"].trim()
      if (password === "") {
        throw new Error("비밀번호를 입력해주세요.")
      }
      // RegExHelper.checkPassword(password);

      await AuthService.checkPw(password)
      this.setState({hasPermission: true})
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  onClickBtnShowAddressSearchPopup = (evt) => {
    if (!this.state.isRealName) return false
    this.setState({isOpenedAddressSearchPopup: true})
  }

  // 주소 검색 팝업 콜백
  onCompleteAddress = (data) => {
    console.log(data)
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }

    let userObj = this.state.userObj
    userObj["postNo"] = data.zonecode
    userObj["addr1"] = fullAddress
    userObj["addr2"] = extraAddress

    this.setState({
      isOpenedAddressSearchPopup: false,
      userObj
    })
  }

  onClickBtnModalBg = (_) => {
    this.setState({isOpenedAddressSearchPopup: false})
  }
  onChangeCheckbox = (evt, objName, key) => {
    let obj = this.state[objName]
    obj[key] = obj[key] === "Y" ? "N" : "Y"
    console.log(evt.target.value, objName, key, obj[key])
    this.setState({[objName]: obj})
  }

  onClickBtnModify = async (evt) => {
    try {
      if (!this.state.isRealName) {
        throw new Error("본인 인증(휴대폰, 아이핀)을 먼저 진행해주세요.")
      }
      let {simpleId, memberId, smsYn, postNo, addr1, addr2, memberEmail, emailYn, memberPw, memberNewPw, memberNewPwRe} = this.state.userObj
      let {hasAuth, mobpNo1, mobpNo2, mobpNo3} = this.state.mobpAuthObj

      console.log(this.state.userObj)
      if (mobpNo1.trim() === "" || isNaN(mobpNo1)) {
        throw new Error("휴대폰 번호를 입력해주세요.")
      } else if (mobpNo2.trim() === "" || isNaN(mobpNo2)) {
        throw new Error("휴대폰 번호를 입력해주세요.")
      } else if (mobpNo3.trim() === "" || isNaN(mobpNo3)) {
        throw new Error("휴대폰 번호를 입력해주세요.")
      } else if (!hasAuth) {
        throw new Error("휴대폰 번호를 변경하시려면 인증하셔야 합니다.")
      } else if (
        (emailYn === "Y" && !RegExHelper.checkEmail(memberEmail)) ||
        (emailYn === "N" && memberEmail.length > 0 && !RegExHelper.checkEmail(memberEmail.trim())) ||
        ( memberEmail.trim().length === 0 )
      ) {
        throw new Error("올바른 이메일주소를 입력해주세요.")
      } else if (postNo.trim() === '' || addr1.trim() === '') {
          throw new Error("주소를 검색해주세요.");
      // } else if (addr2.trim() === '') {
      //     throw new Error("상세주소를 입력해주세요.");
      }

      // y/n 값 을 반대로 해야 정상값

      console.log(smsYn, emailYn);
      // smsYn = smsYn ? "N" : "Y"
      // emailYn = emailYn ? "N" : "Y"
      console.log(smsYn, emailYn);
      const mobpNo = mobpNo1 + mobpNo2 + mobpNo3
      let params = {
        simpleId,
        memberId,
        mobpNo,
        mobpNo1,
        mobpNo2,
        mobpNo3,
        smsYn,
        postNo,
        addr1,
        addr2,
        memberEmail,
        emailYn
      }

      if (memberPw.trim() !== "" || memberNewPw.trim() !== "" || memberNewPwRe.trim() !== "") {
        if (memberPw.trim() === "") {
          throw new Error("기존 비밀번호를 입력해주세요.")
        } else if (memberNewPw.trim() === "") {
          throw new Error("변경 비밀번호를 입력해주세요.")
        } else if (memberNewPw !== memberNewPwRe) {
          throw new Error("변경 비밀번호와 변경 비밀번호 확증류 일치하지 않습니다.")
        }

        RegExHelper.checkPassword(memberNewPw)

        params["memberPw"] = memberPw
        params["memberNewPw"] = memberNewPw
      }

      console.log(params)
      await UserService.updateMyInfo(params)
      this.props.history.push("/")
      alert("수정되었습니다.")
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }
  onClickBtnOpenSecessionPopup = (evt) => {
    if (!this.state.isRealName) return false
    const secessionObj = this.state.secessionObj
    secessionObj["isOpen"] = true
    this.setState({secessionObj})
  }
  onClickSecessionReason = (evt) => {
    let secessionObj = this.state.secessionObj
    secessionObj["type"] = evt.target.value
    this.setState({secessionObj})
  }
  onClickBtnSecession = async (_) => {
    try {
      let {type, reason} = this.state.secessionObj
      await UserService.secession(type, reason)
      alert("탈퇴 처리 되었습니다.")
      const secessionObj = this.state.secessionObj
      secessionObj["isOpen"] = false
      this.setState({secessionObj}, (_) => {
        AuthHelper.removeLoginInfo()
        this.props.history.push("/")
      })
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }
  onClickBtnClosePopup = (_) => {
    let secessionObj = this.state.secessionObj
    secessionObj["isOpen"] = false
    this.setState({secessionObj})
  }

  onClickBtnRequestAuthNum = async (_) => {
    if (!this.state.isRealName) return false
    try {
      let mobpAuthObj = this.state.mobpAuthObj
      const {mobpNo1, mobpNo2, mobpNo3} = mobpAuthObj
      const mobpNo = mobpNo1 + mobpNo2 + mobpNo3

      if (mobpNo === this.state.userObj.mobpNo) {
        throw new Error("이미 인증된 번호 입니다.")
      } else if (mobpNo.length < 10) {
        throw new Error("휴대폰 번호를 정확히 입력해주세요.")
      }

      const certSeq = await UserService.requestAuthNumByCellphone("JOIN", mobpNo)
      mobpAuthObj["certSeq"] = certSeq
      mobpAuthObj["certNo"] = ""
      mobpAuthObj["hasAuth"] = false
      this.setState({mobpAuthObj})

      alert("인증 번호를 전송하였습니다.")
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  onClickBtnRequestAuth = async (_) => {
    if (!this.state.isRealName) return false
    try {
      let mobpAuthObj = this.state.mobpAuthObj
      const {certSeq, certNo, mobpNo1, mobpNo2, mobpNo3} = mobpAuthObj

      const mobpNo = mobpNo1 + mobpNo2 + mobpNo3
      if (mobpNo == mobpAuthObj["mobpNo"]) {
        throw new Error("이미 인증 된 번호입니다.")
      } else if (certSeq.trim() === "") {
        throw new Error("인증요청을 먼저 해주세요.")
      } else if (certNo.trim() === "") {
        throw new Error("인증번호를 입력해주세요.")
      }

      const result = await UserService.requestCheckAuthNumByCellphone("JOIN", certSeq, certNo, mobpNo)
      mobpAuthObj["mobpNo"] = mobpNo
      mobpAuthObj["hasAuth"] = true
      this.setState({mobpAuthObj})
      //console.log(result);
      alert("인증 되었습니다.")
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  onClickBtnOpenCellAuth = (evt) => {
    console.log("onClickBtnOpenCellAuth")
    window.open(
      "/v1/user/identify/phone/request",
      "mobilem",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    )
  }

  onClickBtnOpenIpinAuth = (evt) => {
    console.log("onClickBtnOpenIpinAuth")
    window.open("/v1/user/identify/ipin/request", "ipin2", "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no")
  }

  onMessage = (evt) => {
    console.log("onMessage", evt.data.type)
    if (evt.data.type === "IPIN2" || evt.data.type === "MOBILEM") {
      let authType = evt.data.type
      let certData = evt.data.data
      this.doAuthRealName(authType, certData)
    } else {
      //   alert("본인 인증에 실패했습니다.");
    }
  }

  doAuthRealName = async (authType, certData) => {
    try {
      await UserService.authRealName(authType, certData)
      AuthHelper.updateRealName()
      this.setState({isRealName: true})
      alert("본인 인증이 완료되었습니다.")
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  onEnterKey = (evt) => {
    if (evt.key === "Enter") {
      this.onClickBtnConfirmPassword()
    }
  }

  onClickChangeTab = (evt, tabnum) => {
    tabnum === 1
      ? this.setState({
          myPageTabOptions: {
            status: tabnum,
            subHead: "내 정보 관리"
          }
        })
      : this.setState({
          myPageTabOptions: {
            status: tabnum,
            subHead: "블라인드리스트"
          }
        })
  }

  onClickBtnCheckAll = () => {
    if (this.state.blindListAllChecked === false) {
      this.state.blindList.map((item, idx) => (item.isChecked = true))
      this.setState({blindListAllChecked: true})
    } else {
      this.state.blindList.map((item, idx) => (item.isChecked = false))
      this.setState({blindListAllChecked: false})
    }
    console.log(this.state.blindList)
    console.log(this.state.blindListAllChecked)
  }

  onChangeBlindChkBox = (idx) => {
    let blindList = this.state.blindList

    blindList[idx].isChecked = !blindList[idx].isChecked
    this.setState({blindList: blindList})
  }

  onClickBtnDeleteBlind = async () => {
    let blindSeq = ""
    const memberId = this.state.userObj.memberId
    this.state.blindList.map((item, idx) => {
      item.isChecked === true && (blindSeq.length === 0 ? (blindSeq = item.blindSeq) : (blindSeq += "," + item.blindSeq))
    })

    if (window.confirm("정말 삭제하시겠습니까?")) {
      if (blindSeq === "") {
        alert("블라인드 대상을 선택 해주세요")
        return
      }
      await UserService.deleteBlindList(blindSeq, memberId)
        .then(() => {
          alert("선택된 블라인드 리스트를 삭제했습니다.")
          window.location.reload()
        })
        .catch((err) => {
          console.error(err)
        })
    }

    // const blindReq = this.state.blindList
  }

  render = () =>
    !this.state.hasPermission ? (
      this.props.loginInfo ? (
        <div className="container">
          <SubHeadConfirmPasswordComponent />
          <ContConfirmPasswordComponent
            onChangeInput={this.onChangeInput}
            loginInfo={this.props.loginInfo}
            authObj={this.state.authObj}
            onClickBtnConfirmPassword={this.onClickBtnConfirmPassword}
            onEnterKey={this.onEnterKey}
          />
        </div>
      ) : null
    ) : (
      <div className="container">
        <SubHeadComponent myPageTabOptions={this.state.myPageTabOptions} />
        <ContComponent
          onChangeSelectList={this.onChangeSelectList}
          onChangeInput={this.onChangeInput}
          loginInfo={this.props.loginInfo}
          userObj={this.state.userObj}
          mobpAuthObj={this.state.mobpAuthObj}
          cellphoneNum1SelectList={this.state.cellphoneNum1SelectList}
          isRealName={this.state.isRealName}
          isOpenedAddressSearchPopup={this.state.isOpenedAddressSearchPopup}
          onCompleteAddress={this.onCompleteAddress}
          onClickBtnModalBg={this.onClickBtnModalBg}
          onClickBtnRequestAuthNum={this.onClickBtnRequestAuthNum}
          onClickBtnRequestAuth={this.onClickBtnRequestAuth}
          onClickBtnShowAddressSearchPopup={this.onClickBtnShowAddressSearchPopup}
          onClickBtnModify={this.onClickBtnModify}
          onChangeCheckbox={this.onChangeCheckbox}
          onClickBtnOpenSecessionPopup={this.onClickBtnOpenSecessionPopup}
          secessionObj={this.state.secessionObj}
          onClickSecessionReason={this.onClickSecessionReason}
          onClickBtnSecession={this.onClickBtnSecession}
          onClickBtnClosePopup={this.onClickBtnClosePopup}
          onClickBtnOpenCellAuth={this.onClickBtnOpenCellAuth}
          onClickBtnOpenIpinAuth={this.onClickBtnOpenIpinAuth}
          myPageTabOptions={this.state.myPageTabOptions}
          onClickChangeTab={this.onClickChangeTab}
          onClickBtnCheckAll={this.onClickBtnCheckAll}
          blindList={this.state.blindList}
          onChangeBlindChkBox={this.onChangeBlindChkBox}
          onClickBtnDeleteBlind={this.onClickBtnDeleteBlind}
        />
      </div>
    )
}

export default MypageContainer
