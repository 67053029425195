import React, { Component } from 'react';
import SubHeadComponent from '../../common/SubHead.component';
import ContComponent from './Cont.component';
import queryString from 'query-string';

import * as AuthHelper from '../../../../helpers/Auth.helper';
import * as ArticleService from '../../../../services/Article.service';
import * as DateHelper from '../../../../helpers/Date.helper';
import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from '../../../../context/AnalyticsContext';

class InfoContainer extends Component {
    static contextType = AnalyticsContext;

    constructor(props) {
        super(props);

        const articleId = this.props.match.params.articleId;

        this.state = {
            subMenu: 'press',
            subMenuName: '보도자료',
            articleId,
            info: null,

            checkDidLike: false,
        }
    }

    componentDidMount = async _ => {
        try {
            const currentAnalyticsVal = this.context.state;
            const prevUrl = currentAnalyticsVal.currentUrl;
            this.context.action(currentAnalyticsVal);
        
            currentAnalyticsVal.deps2 = "03";
            currentAnalyticsVal.deps3 = "02";
            currentAnalyticsVal.deps4 = "01";
            currentAnalyticsVal.prevUrl = prevUrl;
            currentAnalyticsVal.currentUrl = window.location.href;
        
            await CommonService.insertAnalytics(this.context.state);

            const result = await ArticleService.getPressArticleInfo(this.state.articleId);

            let info = result.article;
            info['regDate'] = DateHelper.convertTimestampToDate(info['regDttm']);
            info['regTime'] = DateHelper.convertTimestampToTime(info['regDttm']);

            let checkDidLike = false;
            if (AuthHelper.checkHasLogin()) {
                let checkLikeResult = await ArticleService.checkLike(this.state.articleId);
                checkDidLike = (checkLikeResult['articleLike']);
                console.log('checkLikeResult', checkLikeResult);
            }

            this.setState({ info: result.article, checkDidLike })
        } catch (err) {
            //alert(err.message);
        }
    }

    onClickBtnList = _ => {
        const queryStringParams = queryString.parse(this.props.location.search);
        const isFromHome = (queryStringParams['fromHome'] ? queryStringParams['fromHome'] : 'n').toLowerCase() === 'y';
        if (isFromHome) {
            this.props.history.push('/contents/press');
        } else {
            this.props.history.goBack();
        }
    }

    onClickBtnLike = async (evt) => {
        try {
            if (!AuthHelper.checkHasLogin()) {
                throw new Error("로그인이 필요합니다.");
            }

            const checkLikeResult = await ArticleService.checkLike(this.state.info.artcSeq);
            if (checkLikeResult.articleLike != null) {
                throw new Error("이미 좋아요 한 게시물입니다.");
            }

            const result = await ArticleService.doLike(this.state.info.artcSeq);
            let info = this.state.info;
            info.likeCnt++;
            this.setState({ info, checkDidLike:true });
            alert(result.message);
        } catch (err) {
            //alert(err.message);
        }
    }

    render = _ => (
        <div className='container'>
            <SubHeadComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName} />
            <ContComponent
                info={this.state.info}
                onClickBtnList={this.onClickBtnList}
                onClickBtnLike={this.onClickBtnLike}

                checkDidLike={this.state.checkDidLike}
            />
        </div>
    )
}

export default InfoContainer;
