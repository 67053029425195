import React, { Component } from "react";

import SubHeadComponent from "./SubHead.component";
import ContComponent from "./Cont.component";

import ContStep1Component from "./ContStep1.component";
import ContStep2Component from "./ContStep2.component";
import ContStep3Component from "./ContStep3.component";
import ContStep4Component from "./ContStep4.component";

import * as RegExHelper from "../../helpers/RegEx.helper";
import * as UserService from "../../services/User.service";
import * as DateHelper from "../../helpers/Date.helper";

class SignupContainer extends Component {
  constructor(props) {
    super(props);

    let yearSelectList = DateHelper.getYearSelectList(1900);
    let monthSelectList = DateHelper.getMonthSelectList();

    this.state = {
      stepNo: 1,
      isChild: false,
      isOpenedAddressSearchPopup: false,
      checkAuthType: "",

      yearSelectList,
      monthSelectList,
      daySelectList: [],
      terms3temp: {},

      termsObj: {
        isInit: false,
        terms1Text: "",
        terms2Text: "",
        terms3Text: "",
        // ,terms4Text: ''
      },
      step1Obj: {
        identityType: null,
        identityData: null,
      },
      step2Obj: {
        hasAgreeWithTerms1: false,
        hasAgreeWithTerms2: false,
        hasAgreeWithTerms3: false,
        // hasAgreeWithTerms4: false,
        // hasAgreeWithTerms5: false,
        hasAgreeWithAllTerms: false,
      },
      step3Obj: {
        id: "",
        idPlaceholder: "6자 이상(영소문자, 숫자)",
        checkedId: "",
        realNameRequestSeq: "",

        pw: "",
        pwPlaceholder: "8자 이상(영소문자, 숫자, 특수문자)",
        rePw: "",
        rePwPlaceholder: "8자 이상(영소문자, 숫자, 특수문자)",

        cellNum1: "010",
        cellNum2: "",
        cellNum3: "",
        cellphoneNum: "",
        checkedCellphoneNum: "",
        certSeq: "",
        certNo: "",
        isReceiveSms: false,

        zoneCode: "",
        address1: "",
        address2: "",

        emailId: "",
        emailDomain: "",
        email: "",
        isReceiveEmail: false,

        recommenderId: "",
        recommenderIdPlaceholder: "6자 이상(영소문자, 숫자)",
        checkedRecommenderId: "",

        childUserName: "",
        childBirthDate: "",
        childBirthDateYear: "",
        childBirthDateMonth: "",
        childBirthDateDay: "",
        childSex: "1",
      },
      signupObj: {
        "user.simpleId": "",
        "user.memberPw": "",

        "user.mobpNo": "",

        "user.memberEmail": "",

        "user.isAdult": "",

        "user.postNo": "",
        "user.addr1": "",
        "user.addr2": "",

        "user.recommenderId": "",

        "user.smsYn": "",
        "user.emailYn": "",

        isOver14y: "",
        "user.childYn": "",
        "user.childUserName": "",
        "user.childBirthDate": "",
        "user.childSex": "",
        realNameRequestSeq: "",
        realNameAuthType: "",
        certData: "",

        // For Test
        "approvedTerms[0].termsId": "CL201403101417410001",
        "approvedTerms[0].termsKind": "1",
        "approvedTerms[1].termsId": "CL201403101417410001",
        "approvedTerms[1].termsKind": "2",
        // 'approvedTerms[2].termsId': 'CL201403101417410001',
        // 'approvedTerms[2].termsKind': '3',
        // 'approvedTerms[3].termsId': 'CL201403101417410001',
        // 'approvedTerms[3].termsKind': '4',

        // 'approvedTerms[0].termsId':'',
        // 'approvedTerms[0].termsKind':'',
        // 'approvedTerms[1].termsId':'',
        // 'approvedTerms[1].termsKind':'',
        // 'approvedTerms[2].termsId':'',
        // 'approvedTerms[2].termsKind':'',
        // 'approvedTerms[3].termsId':'',
        // 'approvedTerms[3].termsKind':'',
      },
    };
    // console.log(this.state);
  }

  componentDidMount = async () => {
    window.addEventListener("message", this.onMessage);
    try {
      if (!this.state.termsObj.isInit) {
        const result = await UserService.getTermsList();
        const termsList = result.list;
        let termsObj = this.state.termsObj;

        // termsObj["terms1Text"] = termsList[0]["termsContents"];
        // termsObj["terms2Text"] = termsList[3]["termsContents"];
        // termsObj["terms3Text"] = termsList[8]["termsContents"];
        const term0 = termsList.filter(t => t.termsKind == 1)[0];
        const term3 = termsList.filter(t => t.termsKind == 2)[0];
        const term8 = termsList.filter(t => t.termsKind == 8)[0];
        termsObj["terms1Text"] = term0["termsContents"];
        termsObj["terms2Text"] = term3["termsContents"];
        termsObj["terms3Text"] = term8["termsContents"];

        // termsObj['terms4Text'] = termsList[3]['termsContents'];
        let signupObj = this.state.signupObj;
        // 서비스 이용 약관
        // signupObj["approvedTerms[0].termsId"] = termsList[0].termsId;
        // signupObj["approvedTerms[0].termsKind"] = termsList[0].termsKind;
        signupObj["approvedTerms[0].termsId"] = term0.termsId;
        signupObj["approvedTerms[0].termsKind"] = term0.termsKind;

        // 개인정보 취급 방침
        // signupObj["approvedTerms[1].termsId"] = termsList[3].termsId;
        // signupObj["approvedTerms[1].termsKind"] = termsList[3].termsKind;
        signupObj["approvedTerms[1].termsId"] = term3.termsId;
        signupObj["approvedTerms[1].termsKind"] = term3.termsKind;

        // 마케팅 및 광고 활용 동의 대한 안내
        // this.state.terms3temp.termsId = termsList[8].termsId;
        // this.state.terms3temp.termsKind = termsList[8].termsKind;
        this.state.terms3temp.termsId = term8.termsId;
        this.state.terms3temp.termsKind = term8.termsKind;

        // 개인정보 제 3자 제공 동의
        // signupObj['approvedTerms[3].termsId'] = termsList[3].termsId;
        // signupObj['approvedTerms[3].termsKind'] = termsList[3].termsKind;
        termsObj["isInit"] = true;
        this.setState({ termsObj });
      }
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  componentWillUnmount = (_) => {
    window.removeEventListener("message", this.onMessage);
  };

  // Step 0
  onClickBtnRegular = (evt) => this.setState({ stepNo: 1, isChild: false });
  onClickBtnChild = (evt) => this.setState({ stepNo: 1, isChild: true });

  // Step 1
  onClickBtnOpenCellAuthInStep1 = (evt) => {
    window.open(
      "/v1/user/identify/phone/request",
      "mobilem",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  onClickBtnOpenIpinAuthInStep1 = (evt) => {
    window.open(
      "/v1/user/identify/ipin/request",
      "ipin2",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  // 폐기
  //   onClickBtnOkStep2 = (evt) => {
  //     try {
  //       const step1Obj = this.state.step1Obj;
  //       if (!step1Obj["identityType"] || !step1Obj["identityData"]) {
  //         throw new Error("본인 인증 절차를 진행해주세요");
  //       }

  //       let signupObj = this.state.signupObj;

  //       signupObj["realNameAuthType"] = step1Obj["identityType"];
  //       signupObj["certData"] = step1Obj["identityData"];

  //       // step2로 보내기
  //       this.setState({
  //         stepNo: 2,
  //         signupObj,
  //       });
  //     } catch (err) {
  //       //alert(err.message);
  //     }
  //   };

  onMessage = async (evt) => {
    if (evt.data.type === "IPIN2" || evt.data.type === "MOBILEM") {
      let step1Obj = this.state.step1Obj;
      step1Obj["identityType"] = evt.data.type;
      step1Obj["identityData"] = evt.data.data;

      let signupObj = this.state.signupObj;
      signupObj["realNameAuthType"] = step1Obj["identityType"];
      signupObj["certData"] = step1Obj["identityData"];
      signupObj["isOver14y"] = !this.state.isChild ? "Y" : "N";
      signupObj["user.isAdult"] = !this.state.isChild ? "Y" : "N";

      if (!this.state.isChild) {
        const result = await UserService.checkUsedId(
          signupObj["realNameAuthType"],
          signupObj["certData"],
          signupObj["isOver14y"]
        );

        if (!result) {
          window.location.href = "/login";
        } else {
          if(result.isUnderAge) {
            alert("만 14세 미만은 회원가입을 하실 수 없습니다");
            window.location.href = "/";
            return;
          }
          if (evt.data.type === "MOBILEM") {
            if (result.certedPhoneNo.length === 10) {
              this.state.step3Obj.cellNum1 = result.certedPhoneNo.substr(0, 3);
              this.state.step3Obj.cellNum2 = result.certedPhoneNo.substr(3, 3);
              this.state.step3Obj.cellNum3 = result.certedPhoneNo.substr(6, 4);
              this.state.step3Obj.cellphoneNum =
                result.certedPhoneNo.substr(0, 3) +
                "-" +
                result.certedPhoneNo.substr(3, 3) +
                "-" +
                result.certedPhoneNo.substr(6, 4);
              this.state.step3Obj.checkedCellphoneNum = this.state.step3Obj.cellphoneNum;
            } else {
              this.state.step3Obj.cellNum1 = result.certedPhoneNo.substr(0, 3);
              this.state.step3Obj.cellNum2 = result.certedPhoneNo.substr(3, 4);
              this.state.step3Obj.cellNum3 = result.certedPhoneNo.substr(7, 4);
              this.state.step3Obj.cellphoneNum =
                result.certedPhoneNo.substr(0, 3) +
                "-" +
                result.certedPhoneNo.substr(3, 4) +
                "-" +
                result.certedPhoneNo.substr(7, 4);
              this.state.step3Obj.checkedCellphoneNum = this.state.step3Obj.cellphoneNum;
            }
          }

          this.setState({
            step1Obj,
            signupObj,
            checkAuthType: step1Obj["identityType"],
            stepNo: 2,
          });
        }
      } else {
        this.setState({
          step1Obj,
          signupObj,
          stepNo: 2,
        });
      }
      // step2로 보내기
      //   this.setState({
      //     stepNo: 2,
      //     signupObj,
      //   });

      //   this.doSignup(signupObj);
    }
  };

  // Step 2
  onChangeCheckboxInStep2 = (evt, objName, key) => {
    //console.log('onChangeCheckboxInStep2');
    let step2Obj = this.state.step2Obj;
    if (key === "hasAgreeWithAllTerms") {
      //console.log('step1Obj in hasAgreeWithAllTerms', step1Obj);
      if (step2Obj["hasAgreeWithAllTerms"]) {
        step2Obj["hasAgreeWithTerms1"] = false;
        step2Obj["hasAgreeWithTerms2"] = false;
        step2Obj["hasAgreeWithTerms3"] = false;
        // step1Obj['hasAgreeWithTerms4'] = false;
        // step1Obj['hasAgreeWithTerms5'] = false;
        step2Obj["hasAgreeWithAllTerms"] = false;
      } else {
        step2Obj["hasAgreeWithTerms1"] = true;
        step2Obj["hasAgreeWithTerms2"] = true;
        step2Obj["hasAgreeWithTerms3"] = true;
        // step1Obj['hasAgreeWithTerms4'] = true;
        // step1Obj['hasAgreeWithTerms5'] = true;
        step2Obj["hasAgreeWithAllTerms"] = true;
      }
    } else {
      //console.log('step1Obj', step1Obj);
      step2Obj[key] = !step2Obj[key];
      step2Obj["hasAgreeWithAllTerms"] =
        step2Obj["hasAgreeWithTerms1"] &&
        step2Obj["hasAgreeWithTerms2"] &&
        step2Obj["hasAgreeWithTerms3"];
      // && step1Obj['hasAgreeWithTerms4']
      // && step1Obj['hasAgreeWithTerms3']
    }
    this.setState({ step2Obj });
  };

  onClickBtnOkStep2 = (evt) => {
    let signupObj = this.state.signupObj;
    if (
      !this.state.step2Obj.hasAgreeWithTerms1 ||
      !this.state.step2Obj.hasAgreeWithTerms2
      // || !this.state.step1Obj.hasAgreeWithTerms3
    ) {
      alert("필수 약관에 모두 동의해주세요.");
      return;
    }

    if (this.state.step2Obj.hasAgreeWithTerms3) {
      signupObj["approvedTerms[2].termsId"] = this.state.terms3temp.termsId;
      signupObj["approvedTerms[2].termsKind"] = this.state.terms3temp.termsKind;
    }

    this.setState({
      stepNo: 3,
      signupObj,
    });
  };
  onClickBtnCancelStep3 = (evt) => {
    const check = window.confirm("회원가입을 취소하시겠습니까?");
    if (check) {
      window.location.href = "/";
    }
  }

  // Step 3
  onClickBtnCheckExistsId = async (evt) => {
    try {
      let step3Obj = this.state.step3Obj;
      if (step3Obj.id.trim() === "") {
        alert("아이디를 입력해주세요.");
        return;
        // throw new Error("아이디를 입력해주세요.");

      }

      const result = await UserService.checkExistsId(step3Obj.id);

      if (result.duplication === "Y") {
        alert("이미 가입된 아이디가 존재합니다.");
        return;
        // throw new Error("이미 가입된 아이디가 존재합니다.");
      }

      step3Obj.checkedId = step3Obj.id;
      step3Obj.realNameRequestSeq = result.realNameRequestSeq;
      this.setState({ step3Obj });

      alert("사용할 수 있는 아이디입니다.");
    } catch (err) {
      // console.error(err);
      alert(err.message);
    }
  };

  onChangeInputInStep3 = (evt, objName, key) => {
    let obj = this.state[objName];

    // 객체에 미리 데이터를 넣어두면 다음 setState 시 값이 반영되어버림(object 참조 )
    // obj[key] = evt.target.value;

    if (
      key === "cellNum1" ||
      key === "cellNum2" ||
      key === "cellNum3" ||
      key === "certNo"
    ) {
      // 휴대폰 입력시 영문 입력 방지
      if (isNaN(evt.target.value)) {
        return false;
      }
      obj[key] = evt.target.value;
      obj[
        "cellphoneNum"
      ] = `${obj["cellNum1"]}-${obj["cellNum2"]}-${obj["cellNum3"]}`;
      this.setState({ [objName]: obj });
    } else if (key === "emailId" || key === "emailDomain") {
      //console.log('email ', key, evt.target.value);
      obj[key] = evt.target.value;
      obj["email"] = `${obj["emailId"].trim()}@${obj["emailDomain"].trim()}`;
      this.setState({ [objName]: obj });
    } else {
      obj[key] = evt.target.value;
      this.setState({ [objName]: obj });
    }
  };

  onChangeSelectListInStep3 = (evt, objName, key) => {
    let obj = this.state[objName];
    obj[key] = evt.target.value;
    if (key === "cellNum1") {
      obj[
        "cellphoneNum"
      ] = `${obj["cellNum1"]}-${obj["cellNum2"]}-${obj["cellNum3"]}`;
      this.setState({ [objName]: obj });
    } else if (key === "emailDomain") {
      obj["email"] = `${obj["emailId"]}@${obj["emailDomain"]}`;
      this.setState({ [objName]: obj });
    } else if (
      key === "childBirthDateYear" ||
      key === "childBirthDateMonth" ||
      key === "childBirthDateDay"
    ) {
      const birthYear = obj["childBirthDateYear"];
      const birthMonth =
        (parseInt(obj["childBirthDateMonth"], 10) < 10 ? "0" : "") +
        obj["childBirthDateMonth"];
      const birthDay =
        (parseInt(obj["childBirthDateDay"], 10) < 10 ? "0" : "") +
        obj["childBirthDateDay"];

      obj["childBirthDate"] = `${birthYear}-${birthMonth}-${birthDay}`;

      if (key === "childBirthDateYear") {
        obj["childBirthDateMonth"] = "";
        obj["childBirthDateDay"] = "";
        const daySelectList = [];
        this.setState({
          [objName]: obj,
          daySelectList,
        });
      } else if (key === "childBirthDateMonth") {
        const daySelectList = DateHelper.getDaySelectList(
          obj["childBirthDateYear"],
          obj["childBirthDateMonth"]
        );
        obj["childBirthDateDay"] = "";
        this.setState({
          [objName]: obj,
          daySelectList,
        });
      } else {
        this.setState({ [objName]: obj });
      }
    } else {
      this.setState({ [objName]: obj });
    }
  };

  onChangeCheckboxInStep3 = (evt, objName, key) => {
    let obj = this.state[objName];
    obj[key] = evt.target.checked;
    this.setState({ [objName]: obj });
  };

  onClickChildSexRadioButton = (evt) => {
    //console.log('onClickChildSexRadioButton', evt.target.value);
    let step3Obj = this.state.step3Obj;
    step3Obj["childSex"] = evt.target.value;
    this.setState({ step3Obj });
  };

  // 인증번호 요청
  onClickBtnRequestAuthNumInStep3 = async (evt) => {
    //console.log('onClickBtnRequestAuthNumInStep3');
    try {
      let step3Obj = this.state.step3Obj;
      if (step3Obj.cellphoneNum.length < 12) {
        alert("휴대폰 번호를 정확히 입력해주세요.");
        return;
        // throw new Error("휴대폰 번호를 정확히 입력해주세요.");
      }

      const certSeq = await UserService.requestAuthNumByCellphone(
        "JOIN",
        this.state.step3Obj.cellphoneNum
      );
      step3Obj["certSeq"] = certSeq;
      this.setState({ step3Obj });

      alert("인증 번호를 전송하였습니다.");
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };
  // 인증번호 인증 요청
  onClickBtnRequestAuthInStep3 = async (evt) => {
    try {
      const certSeq = this.state.step3Obj.certSeq;
      const certNo = this.state.step3Obj.certNo;
      const cellphoneNum = this.state.step3Obj.cellphoneNum;
      if (certSeq.trim() === "") {
        alert("인증번호 요청을 먼저 해주세요.");
        return;
        // throw new Error("인증요청을 먼저 해주세요.");
      } else if (certNo.trim() === "") {
        alert("인증번호를 입력해주세요.");
        return;
        // throw new Error("인증번호를 입력해주세요.");
      }

      const result = await UserService.requestCheckAuthNumByCellphone(
        "JOIN",
        certSeq,
        certNo,
        cellphoneNum
      );

      let step3Obj = this.state.step3Obj;
      step3Obj["checkedCellphoneNum"] = cellphoneNum;
      this.setState({ step3Obj });
      //console.log(result);
      alert("인증 되었습니다.");
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  // 주소 검색 팝업 콜백
  onCompleteAddress = (data) => {
    //console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    let step3Obj = this.state.step3Obj;
    step3Obj["zoneCode"] = data.zonecode;
    step3Obj["address1"] = fullAddress;
    step3Obj["address2"] = extraAddress;

    this.setState({
      isOpenedAddressSearchPopup: false,
      step3Obj,
    });
  };

  onClickBtnModalBg = (_) => {
    this.setState({ isOpenedAddressSearchPopup: false });
  };

  onClickBtnCheckRecommenderIdInStep3 = async (evt) => {
    let step3Obj = this.state.step3Obj;
    try {
      const recommenderId = step3Obj.recommenderId;
      if (recommenderId.trim() === "") {
        throw new Error("추천인 아이디를 입력해주세요.");
      }

      const result = await UserService.checkExistsId(step3Obj.recommenderId);

      if (result.duplication !== "Y") {
        throw new Error("해당 추천아이디가 존재하지 않습니다.");
      }

      step3Obj["checkedRecommenderId"] = step3Obj["recommenderId"];
      this.setState({ step3Obj });
      alert("추천 아이디가 존재합니다.");
    } catch (err) {
      console.error(err);
      if (err.message === "이미 존재하는 회원 아이디입니다.") {
        step3Obj["checkedRecommenderId"] = step3Obj["recommenderId"];
        this.setState({ step3Obj });
        alert("추천 아이디가 존재합니다.");
      } else {
        //alert(err.message);
      }
    }
  };

  onClickBtnShowAddressSearchPopup = (evt) => {
    this.setState({ isOpenedAddressSearchPopup: true });
  };

  onClickBtnOkStep3 = (evt) => {
    try {
      // 입력값 밸리데이션
      const step3Obj = this.state.step3Obj;
      if (step3Obj.id.trim() === "") {
        alert("아이디를 입력해주세요.");
        return;
        // throw new Error("아이디를 입력해주세요.");
      } else if (step3Obj.realNameRequestSeq === "") {
        alert("아이디 중복확인을 해주세요");
        return;
        // throw new Error("아이디 중복확인을 해주세요");
      } else if (step3Obj.id !== step3Obj.checkedId) {
        alert("중복 검사한 아이디가 변경되었습니다. 다시 중복확인을 해주세요.");
        return;
        // throw new Error(
        //   "중복 검사한 아이디가 변경되었습니다. 다시 중복확인을 해주세요."
        // );
      }

      // 비밀번호 정규식 확인
      RegExHelper.checkPassword(step3Obj.pw);

      if (step3Obj.pw !== step3Obj.rePw) {
        // throw new Error("비밀번호와 비밀번호 확인의 값이 다릅니다.");
        alert("비밀번호와 비밀번호 확인의 값이 다릅니다.");
        return;
      }

      if (step3Obj.cellphoneNum.length < 12) {
        alert("휴대폰 번호를 입력해주세요.");
        return;
        // throw new Error("휴대폰 번호를 입력해주세요.");
      } else if (step3Obj.checkedCellphoneNum.trim() === "") {
        alert("휴대폰번호 인증을 해주세요.");
        return;
        // throw new Error("휴대폰번호 인증을 해주세요.");
      } else if (step3Obj.cellphoneNum !== step3Obj.checkedCellphoneNum) {
        alert("인증된 휴대폰 번호가 변경되었습니다. 다시 인증해주세요.")
        return;
        // throw new Error(
        //   "인증된 휴대폰 번호가 변경되었습니다. 다시 인증해주세요."
        // );
      }

      if (step3Obj.email.trim() !== "") {
        RegExHelper.checkEmail(step3Obj.email.trim());
      }

      if(step3Obj.email.trim() === ""){
        alert("이메일 주소를 입력해주세요.");
        return;
      }

      // if (
      //   step3Obj["recommenderId"].trim() !== "" &&
      //   step3Obj["recommenderId"] !== step3Obj["checkedRecommenderId"]
      // ) {
      //   throw new Error("추천인 아이디를 확인해주세요.");
      // }

      if (this.state.isChild) {
        if (step3Obj.childUserName.trim() === "") {
          throw new Error("어린이 이름을 입력해주세요.");
        } else if (
          step3Obj.childBirthDateYear === "" ||
          step3Obj.childBirthDateMonth === "" ||
          step3Obj.childBirthDateDay === ""
        ) {
          throw new Error("어린이 생년월일을 선택해주세요.");
        }
      }

      // 입력값 signupObj 로 설정
      let signupObj = this.state.signupObj;
      signupObj["user.simpleId"] = step3Obj["id"];
      signupObj["user.memberPw"] = step3Obj["pw"];

      signupObj["user.mobpNo"] = step3Obj["checkedCellphoneNum"].replace(
        /-/g,
        ""
      );

      signupObj["user.memberEmail"] = step3Obj["email"];

      if (step3Obj["zoneCode"].trim() !== "") {
        signupObj["user.postNo"] = step3Obj["zoneCode"];
        signupObj["user.addr1"] = step3Obj["address1"];
        signupObj["user.addr2"] = step3Obj["address2"];
      } else {
        alert("주소를 입력해주세요.");
        return;
      }

      if (step3Obj["recommenderId"].trim() !== "") {
        signupObj["user.recommenderId"] = step3Obj["checkedRecommenderId"];
      } else {
        signupObj["user.recommenderId"] = "";
      }

      signupObj["user.smsYn"] = step3Obj["isReceiveSms"] ? "Y" : "N";
      signupObj["user.emailYn"] = step3Obj["isReceiveEmail"] ? "Y" : "N";

      signupObj["user.childYn"] = this.state.isChild ? "Y" : "N";
      if (this.state.isChild) {
        signupObj["user.childUserName"] = step3Obj["childUserName"];
        signupObj["user.childBirthDate"] = step3Obj["childBirthDate"];
        signupObj["user.childSex"] = step3Obj["childSex"];
      }

      signupObj["realNameRequestSeq"] = step3Obj["realNameRequestSeq"];

      //console.log('signupObj', signupObj);
      this.setState({ signupObj });

      this.doSignup(signupObj);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnCancelStep2 = (evt) =>
    alert("약관에 동의하지 않으시면 회원가입을 진행할 수 없습니다.");

  doSignup = async (params) => {
    try {
      const result = await UserService.signup(params);
      this.setState({ stepNo: 4 });
      console.log(result);
    } catch (err) {
      // console.error(err);
      alert(err.message);
    }
  };

  render = () => (
    <div className="container">
      <SubHeadComponent />

      {/* 최초 진입  */}
      {this.state.stepNo === 0 ? (
        <ContComponent
          onClickBtnRegular={this.onClickBtnRegular}
          onClickBtnChild={this.onClickBtnChild}
        />
      ) : null}

      {/* Step 1 */}
      {this.state.stepNo === 1 ? (
        <ContStep1Component
          isChild={this.state.isChild}
          onClickBtnOpenCellAuthInStep1={this.onClickBtnOpenCellAuthInStep1}
          onClickBtnOpenIpinAuthInStep1={this.onClickBtnOpenIpinAuthInStep1}
        />
      ) : null}

      {/* Step 2 */}
      {this.state.stepNo === 2 ? (
        <ContStep2Component
          isChild={this.state.isChild}
          onChangeCheckboxInStep2={this.onChangeCheckboxInStep2}
          termsObj={this.state.termsObj}
          step2Obj={this.state.step2Obj}
          onClickBtnOkStep2={this.onClickBtnOkStep2}
          onClickBtnCancelStep2={this.onClickBtnCancelStep2}
        />
      ) : null}

      {/* Step 3 */}
      {this.state.stepNo === 3 ? (
        <ContStep3Component
          step3Obj={this.state.step3Obj}
          isChild={this.state.isChild}
          checkAuthType={this.state.checkAuthType}
          yearSelectList={this.state.yearSelectList}
          monthSelectList={this.state.monthSelectList}
          daySelectList={this.state.daySelectList}
          isOpenedAddressSearchPopup={this.state.isOpenedAddressSearchPopup}
          onClickBtnShowAddressSearchPopup={
            this.onClickBtnShowAddressSearchPopup
          }
          onCompleteAddress={this.onCompleteAddress}
          onClickBtnModalBg={this.onClickBtnModalBg}
          onChangeInputInStep3={this.onChangeInputInStep3}
          onChangeCheckboxInStep3={this.onChangeCheckboxInStep3}
          onClickBtnCheckExistsId={this.onClickBtnCheckExistsId}
          onChangeSelectListInStep3={this.onChangeSelectListInStep3}
          onClickBtnRequestAuthNumInStep3={this.onClickBtnRequestAuthNumInStep3}
          onClickBtnRequestAuthInStep3={this.onClickBtnRequestAuthInStep3}
          onClickBtnCheckRecommenderIdInStep3={
            this.onClickBtnCheckRecommenderIdInStep3
          }
          onClickChildSexRadioButton={this.onClickChildSexRadioButton}
          onClickBtnOkStep3={this.onClickBtnOkStep3}
          onClickBtnCancelStep3={this.onClickBtnCancelStep3}
        />
      ) : null}

      {/* Step 4 */}
      {this.state.stepNo === 4 ? <ContStep4Component /> : null}
    </div>
  );
}

export default SignupContainer;
