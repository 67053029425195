import React from 'react';

import Slider from 'react-slick';

const options = {
    autoplay: true,
    speed: 500,
    infinite: true,
    autoplaySpeed: 1500,
    arrows: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    fade: false,
    responsive: [
        {
            breakpoint: 1080,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        }
    ]
}

const SlidePartnerComponent = props => (
    <>
     <section className="partner m0auto">
         <ul className="sl_ptn">
            <Slider
                ref={slider => props.setSliderRef('slide-partner', slider)}
                {...options}>
                {props.list.map((item, idx) =>
                    <li key={idx}>
                        <a href={item.link} target="_blank" rel='noopener noreferrer'>
                            <img src={item.imgFilePath} alt={item.artcTitle} />
                        </a>
                    </li>
                )}
            </Slider>
        </ul>
        <p onClick={evt => props.onClickBtnSlidePrev(evt, 'slide-partner')} className="sl_ptn_prev"><img src="/img/main/sl_ptn_prev.png" alt="partner 이전버튼" /></p>
        <p onClick={evt => props.onClickBtnSlideNext(evt, 'slide-partner')} className="sl_ptn_next"><img src="/img/main/sl_ptn_next.png" alt="partner 다음버튼" /></p>
     </section>
    </>
)

export default SlidePartnerComponent;
