import React, { Component } from 'react';
import ContComponent from './Cont.component';

import * as CommonHelper from '../../../../../helpers/Common.helper';
import * as DateHelper from '../../../../../helpers/Date.helper';
import * as GameService from '../../../../../services/Game.service';

class TeamRankingContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearSelectList: DateHelper.getYearSelectList(),
            monthSelectList: DateHelper.getMonthSelectList(),
            dayOfWeekSelectList: DateHelper.getDayOfWeekSelectList(),

            teamRankingList: [],

            teamRankingSelectListObj: {
                selectedYear: process.env.REACT_APP_SEASON_YEAR,
                selectedMonth: '',
                selectedDayNightFlag: '',
                selectedHomeAwayFlag: '',
                selectedDayOfWeek: ''
            },

            winLoseResult: null,
            winLoseListObj: {}
        }
    }

    componentDidMount = async _ => {
        try {
            const majorTeamRankingResult = await GameService.getMajorTeamRankingByYear(process.env.REACT_APP_SEASON_YEAR);
            const teamRankingList = majorTeamRankingResult.list;


            const year = this.state.teamRankingSelectListObj.selectedYear;
            const params = {
                month: this.state.teamRankingSelectListObj.selectedMonth,
                homeAwayFlag: this.state.teamRankingSelectListObj.selectedHomeAwayFlag,
                dayNightFlag: this.state.teamRankingSelectListObj.selectedDayNightFlag,
                dayOfWeek: this.state.teamRankingSelectListObj.selectedDayOfWeek,
            }
            const winLoseResult = await GameService.getEachTeamWinLose(year, params);
            const winLoseListObj = this.makeWinLoseListObj(winLoseResult.list);
            this.setState({
                teamRankingList,
                winLoseResult,
                winLoseListObj
            });
            console.log(winLoseListObj);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    makeWinLoseListObj = (list) => {
        if (Object.keys(list).length === 0) {
            return {};
        }
        let winLoseListObj = {};
        for (let i = 0; i < list.length; i++) {
            let key = list[i]['teamCode'];
            let vsKey = list[i]['vsTeamCode'];
            if (!winLoseListObj[key]) {
                winLoseListObj[key] = {};
                winLoseListObj[key][key] = '';
            }
            if (!winLoseListObj[key]['totalObj']) {
                winLoseListObj[key]['totalObj'] = {
                    win: 0, lose: 0, drawn: 0
                };
            }

            winLoseListObj[key][vsKey] = `${list[i]['win']} - ${list[i]['lose']} - ${list[i]['drawn']}`;
            winLoseListObj[key]['totalObj']['win'] += parseInt(list[i]['win'], 10);
            winLoseListObj[key]['totalObj']['lose'] += parseInt(list[i]['lose'], 10);
            winLoseListObj[key]['totalObj']['drawn'] += parseInt(list[i]['drawn'], 10);
        }

        Object.keys(winLoseListObj).map((key, idx) => {
            let item = winLoseListObj[key];
            let totalObj = item['totalObj'];
            item["homeTotal"] = `${totalObj['win']} - ${totalObj['lose']} - ${totalObj['drawn']}`
            item["awayTotal"] = `${totalObj['lose']} - ${totalObj['win']} - ${totalObj['drawn']}`
        });

        const teamCodeList = CommonHelper.getTeamCodeList();
        for (let i = 0; i < teamCodeList.length; i++) {
            const teamCode = teamCodeList[i];
            if (!winLoseListObj[teamCode]) {
                winLoseListObj[teamCode] = {};
            }
        }

        console.log('winLoseListObj', winLoseListObj);

        return winLoseListObj;
    }

    onClickBtnSearch = async (evt) => {
        try {
            const year = this.state.teamRankingSelectListObj.selectedYear;
            const params = {
                month: this.state.teamRankingSelectListObj.selectedMonth,
                homeAwayFlag: this.state.teamRankingSelectListObj.selectedHomeAwayFlag,
                dayNightFlag: this.state.teamRankingSelectListObj.selectedDayNightFlag,
                dayOfWeek: this.state.teamRankingSelectListObj.selectedDayOfWeek,
            }
            const winLoseResult = await GameService.getEachTeamWinLose(year, params);
            const winLoseListObj = this.makeWinLoseListObj(winLoseResult.list);
            this.setState({
                winLoseResult,
                winLoseListObj
            });
            console.log(winLoseListObj);
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    onChangeSelectList = (evt, objName, key) => {
        let obj = this.state[objName];
        obj[key] = evt.target.value;
        if (key !== 'selectedYear') {
            this.setState({ [objName]: obj });
        } else {
            const monthSelectList = (evt.target.value == DateHelper.getCurrentYear()
              ? DateHelper.getMonthSelectList(DateHelper.getCurrentMonth())
              : DateHelper.getMonthSelectList());
            obj['selectedMonth'] = '';
            this.setState({
                [objName]: obj,
                monthSelectList,
            })
        }
        console.log(obj);
    }

    render = _ => (
      <ContComponent
        yearSelectList={this.state.yearSelectList}
        monthSelectList={this.state.monthSelectList}
        dayOfWeekSelectList={this.state.dayOfWeekSelectList}

        onChangeSelectList={this.onChangeSelectList}
        onClickBtnSearch={this.onClickBtnSearch}

        teamRankingList={this.state.teamRankingList}

        teamRankingSelectListObj={this.state.teamRankingSelectListObj}
        winLoseResult={this.state.winLoseResult}
        winLoseListObj={this.state.winLoseListObj}
      />
    )
}

export default TeamRankingContainer;
