import React from "react";

import * as CommonHelper from "../../../helpers/Common.helper";

const SubHeadComponent = props =>
  !CommonHelper.checkIsOnWebview() ? (
    <div className="sub_head">
      <div className="sub_HeadIn1 player_head">
        {props.subMenuName ? <h2>{props.subMenuName}</h2> : null}
        <ul>
          <li>
            <img src="/img/sub/ico_home.png" alt="홈 바로가기" />
          </li>
          <li>PLAYERS</li>
          {props.subMenuName ? <li>{props.subMenuName}</li> : null}
        </ul>
      </div>
    </div>
  ) : null;

export default SubHeadComponent;
