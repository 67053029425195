import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";
// import ContComponent from '../../common/PlayerInfoCont.component';

import * as DateHelper from "../../../../helpers/Date.helper";
import * as GameService from "../../../../services/Game.service";
import * as ArticleService from "../../../../services/Article.service";

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

import $ from "jquery";

class InfoContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const playerCode = this.props.match.params.playerCode;
    const uri = this.props.match.path
      .split("/")
      .slice(0, 3)
      .join("/");
    this.state = {
      list: [],
      subMenuName: "코칭스태프",
      info: null,
      detailRecordList: null,
      playerCode,
      uri,
      subTabNum: 1,

      galleryList: [],
      newsList: [],
      selectedPopupContent: null,

      subtabTabletHeight: "157px",
      screenMode: "",
      initialSlideNum: 0
    };
  }

  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "02";
    currentAnalyticsVal.deps3 = "01";
    currentAnalyticsVal.deps4 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    $(window)
      .resize(this.onResizeWindow)
      .trigger("resize");
    this.init();
  };

  componentWillUnmount = _ => {
    $(window).unbind("resize");
  };

  onResizeWindow = evt => {
    const width = evt.target.innerWidth;
    let screenMode = "";
    if (width > 1200) {
      screenMode = "pc";
    } else if (width > 780) {
      screenMode = "tablet";
    } else {
      screenMode = "mobile";
    }
    this.setState({ screenMode }, _ => {
      const subtabTabletHeight = this.getSubTabHeight(
        this.state.screenMode,
        this.state.subTabNum
      );
      this.setState({ subtabTabletHeight });
    });
  };

  init = async _ => {
    try {
      const result = await GameService.getCoachingStaffInfo(
        this.state.playerCode
      );

      // const list
      const info = result.coachstep;

      const detailRecordList = result["yearrecordlist"]
        ? result["yearrecordlist"]
        : null;

      let position = info.position2 === "투수" ? "pitcher" : "hitter";

      const list = this.makeList(this.props.entryTabNum);
      let initialSlideNum = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i].pcode === this.state.playerCode) {
          initialSlideNum = i;
          break;
        }
      }

      let newsList = [];
      let galleryList = [];

      if (info && info["playerName"]) {
        // news List
        const newsParams = {
          "search.searchCategory": 200,
          "search.searchWord": info.playerName,
          "search.max": 10
        };
        const newsResult = await ArticleService.getNewsArticleList(newsParams);
        // const newsResult = {
        //   list: []
        // }
        newsList = newsResult.list;
        for (let i = 0; i < newsList.length; i++) {
          newsList[i]["regDate"] = DateHelper.convertTimestampToDate(
            newsList[i]["regDttm"]
          );
        }

        // Gallery List
        const galleryParams = {
          "search.searchCategory": 500,
          "search.searchWord": info.playerName
        };

        const galleryResult = await ArticleService.getPlayerPicture(
          info.playerName
        );
        // const galleryResult = {
        //   list: []
        // }
        galleryList = galleryResult.list;
        // for (let i = 0; i < galleryList.length; i++) {
        //   galleryList[i]["regDate"] = DateHelper.convertTimestampToDate(
        //     galleryList[i]["regDttm"]
        //   );
        // }
      }

      let idx = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i]["pcode"] === this.state.playerCode) {
          idx = i;
          break;
        }
      }
      if (this.playerSlider !== null && this.playerSlider !== undefined)
        this.playerSlider.slickGoTo(idx);

      this.setState(
        {
          info,
          list,
          position,
          detailRecordList,
          galleryList,
          newsList,
          initialSlideNum
        },
        _ => {
          const subtabTabletHeight = this.getSubTabHeight(
            this.state.screenMode,
            this.state.subTabNum
          );
          this.setState({ subtabTabletHeight });
        }
      );
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnEntryTab = (evt, tabNum) => {
    this.props.setEntryTabNum(tabNum);
  };

  componentDidUpdate = prevProps => {
    if (this.props.entryTabNum !== prevProps.entryTabNum) {
      this.updateList(this.props.entryTabNum);
    } else if (this.props.coachingStaffList !== prevProps.coachingStaffList) {
      this.updateList(this.props.entryTabNum);
    } else if (this.props.location !== prevProps.location) {
      this.updatePlayerCode(this.props.match.params.playerCode);
    }
  };

  updatePlayerCode = async playerCode => {
    const result = await GameService.getCoachingStaffInfo(playerCode);
    const info = result.coachstep;
    const detailRecordList = result["yearrecordlist"]
      ? result["yearrecordlist"]
      : null;

    let position = null;
    if (detailRecordList) {
      position = detailRecordList[0]["allHit"] ? "hitter" : "pitcher";
    }
    const list = this.makeList(this.props.entryTabNum);

    // news List
    let newsList = [];
    let galleryList = [];
    if (info && info["playerName"]) {
      const newsParams = {
        "search.searchCategory": 200,
        "search.searchWord": info.playerName,
        "search.max": 10

      };
      const newsResult = await ArticleService.getNewsArticleList(newsParams);
      newsList = newsResult.list;
      for (let i = 0; i < newsList.length; i++) {
        newsList[i]["regDate"] = DateHelper.convertTimestampToDate(
          newsList[i]["regDttm"]
        );
      }

      // Gallery List
      // const galleryParams = {
      //   "search.searchCategory": 500,
      //   "search.searchWord": info.playerName
      // };
      const galleryResult = await ArticleService.getPlayerPicture(
        info.playerName
      );
      galleryList = galleryResult.list;
      // for (let i = 0; i < galleryList.length; i++) {
      //   galleryList[i]["regDate"] = DateHelper.convertTimestampToDate(
      //     galleryList[i]["regDttm"]
      //   );
      // }
    }

    let idx = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]["pcode"] === playerCode) {
        idx = i;
        break;
      }
    }
    if (this.playerSlider !== null && this.playerSlider !== undefined)
      this.playerSlider.slickGoTo(idx);

    this.setState(
      {
        info,
        list,
        position,
        detailRecordList,
        playerCode,
        newsList,
        galleryList
      },
      _ => {
        const subtabTabletHeight = this.getSubTabHeight(
          this.state.screenMode,
          this.state.subTabNum
        );
        this.setState({ subtabTabletHeight });
      }
    );
  };

  updateList = entryTabNum => {
    const list = this.makeList(entryTabNum);
    this.setState({ list });
  };

  makeList = entryTabNum => {
    let list = [];
    for (let i = 0; i < this.props.coachingStaffList.length; i++) {
      const coachingStaffObj = this.props.coachingStaffList[i];
      if (coachingStaffObj.entry === entryTabNum) {
        list.push(coachingStaffObj);
      }
    }
    return list;
  };

  setSliderRef = (type, slider) => {
    if (type === "player" && !this.playerSlider) {
      console.log("setPlayerSlider");
      this.playerSlider = slider;
    } else if (type === "news" && !this.newsSlider) {
      console.log("setNewsSlider");
      this.newsSlider = slider;
    } else if (type === "gallery" && !this.gallerySlider) {
      console.log("setGallerySlider");
      this.gallerySlider = slider;
    }
  };

  onClickBtnPlayerSliderPrev = (evt, type) => {
    console.log("onClickBtnPlayerSliderPrev");
    console.log(this.playerSlider);
    this.playerSlider.slickPrev();
  };

  onClickBtnSlidePrev = (evt, type) => {
    if (type === "player" && this.playerSlider) {
      this.playerSlider.slickPrev();
    } else if (type === "news" && this.newsSlider) {
      this.newsSlider.slickPrev();
    } else if (type === "gallery" && this.gallerySlider) {
      this.gallerySlider.slickPrev();
    }
  };
  onClickBtnSlideNext = (evt, type) => {
    console.log("onClickBtnSlideNext", type);
    if (type === "player" && this.playerSlider) {
      this.playerSlider.slickNext();
    } else if (type === "news" && this.newsSlider) {
      this.newsSlider.slickNext();
    } else if (type === "gallery" && this.gallerySlider) {
      this.gallerySlider.slickNext();
    }
  };

  onClickBtnSubTab = (evt, tabNum) => {
    this.setState(
      {
        subTabNum: tabNum
      },
      _ => {
        let subtabTabletHeight = this.getSubTabHeight(
          this.state.screenMode,
          tabNum
        );
        this.setState({ subtabTabletHeight });
      }
    );
  };
  getSubTabHeight = (screenMode, tabNum) => {
    console.log("getSubTabHeight", screenMode, tabNum);
    let divHeight = 0;
    if (screenMode === "pc") {
      divHeight = "auto";
    } else {
      if (tabNum === 2) {
        divHeight = $(".mb_news").height();
      } else if (tabNum === 3) {
        divHeight = $(".mb_gallery").height();
      } else {
        divHeight = "auto";
      }
    }
    if (divHeight !== "auto") {
      divHeight += "px";
    }
    return divHeight;
  };

  onClickBtnPlayer = (evt, playerCode) => {
    if (!playerCode) {
      alert("코칭스텝 정보를 준비중입니다.");
    } else {
      const uri = `${this.state.uri}/${playerCode}`;
      this.props.history.push(uri);
    }
  };

  onClickBtnNews = (evt, articleId) => {
    this.props.history.push(`/contents/news/${articleId}`);
  };

  onClickBtnPicture = (evt, articleId) => {
    console.log("onClickBtnPicture", articleId);
    this.setState({ selectedPopupContent: true });
  };

  onClickBtnClosePopup = () => {
    console.log("onClickBtnClosePopup");
    this.setState({ selectedPopupContent: false });
  };

  render = _ => (
    <div className="container">
      <SubHeadComponent subMenuName={this.props.subMenuName} />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        onClickBtnPlayerSliderPrev={this.onClickBtnPlayerSliderPrev}
        list={this.state.list}
        path={this.props.match.path}
        onClickBtnPlayer={this.onClickBtnPlayer}
        subMenu={this.props.subMenu}
        subMenuName={this.props.subMenuName}
        screenMode={this.state.screenMode}
        entryTabNum={this.props.entryTabNum}
        onClickBtnEntryTab={this.onClickBtnEntryTab}
        uri={this.state.uri}
        playerCode={this.state.playerCode}
        info={this.state.info}
        position={this.state.position}
        detailRecordList={this.state.detailRecordList}
        subTabNum={this.state.subTabNum}
        onClickBtnSubTab={this.onClickBtnSubTab}
        galleryList={this.state.galleryList}
        onClickBtnPicture={this.onClickBtnPicture}
        newsList={this.state.newsList}
        onClickBtnNews={this.onClickBtnNews}
        initialSlideNum={this.state.initialSlideNum}
        setSliderRef={this.setSliderRef}
        onClickBtnSlidePrev={this.onClickBtnSlidePrev}
        onClickBtnSlideNext={this.onClickBtnSlideNext}
        subtabTabletHeight={this.state.subtabTabletHeight}
        selectedPopupContent={this.state.selectedPopupContent}
        onClickBtnClosePopup={this.onClickBtnClosePopup}
      />
    </div>
  );
}

export default InfoContainer;
