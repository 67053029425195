import React from "react"
import {Route, Switch} from "react-router-dom"
import {PopupProvider} from "../../context/PopupContext"
import * as AuthHelper from "../../helpers/Auth.helper"
import GoodsDetail from "../auction/Goods/GoodsDetail"
import GoodsAuctionList from "./Goods/GoodsAuctionList"
import MyGoodsBidDetail from "./Goods/MyGoodsBidDetail"
import MyGoodsBidList from "./Goods/MyGoodsBidList"

function GoodsAuction() {
  React.useEffect(() => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.")
      AuthHelper.removeLoginInfo()
      document.location.href = "/login"
    }
  }, [])

  return (
    <PopupProvider>
      <Switch>
        <Route path="/auction/my-bid-list/:id" component={MyGoodsBidDetail}></Route>
        <Route path="/auction/my-bid-list" component={MyGoodsBidList} />
        <Route path="/auction/goods-list/:id" component={GoodsDetail} />
        <Route path="/auction/goods-list" component={GoodsAuctionList} />
        <Route>
          <h1>Not Found</h1>
        </Route>
      </Switch>
    </PopupProvider>
  )
}

export default GoodsAuction
