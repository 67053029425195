import React from 'react';

const WeeklyRecordComponent = props => (
    props.info.position2 === '투' ?
        <WeeklyRecordComponentForPitcher {...props} />
        :
        <WeeklyRecordComponentForHitter {...props} />
)

const WeeklyRecordComponentForHitter = props => (
    <table>
        <caption>요일별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">요일</th>
                <th scope="col">타석</th>
                <th scope="col">타수</th>
                <th scope="col">득점</th>
                <th scope="col">안타</th>
                <th scope="col">2타</th>
                <th scope="col">3타</th>
                <th scope="col">홈런</th>
                <th scope="col">타점</th>
                <th scope="col">희타</th>
                <th scope="col">희비</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">병살</th>
                <th scope="col">타율</th>
            </tr>
        </thead>
        <tbody>
            {props.weeklyRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.run}</td>
                    <td>{item.hit}</td>
                    <td>{item.h2}</td>
                    <td>{item.h3}</td>
                    <td>{item.hr}</td>
                    <td>{item.rbi}</td>
                    <td>{item.sh}</td>
                    <td>{item.sf}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.gd}</td>
                    <td>{item.hra}</td>
                </tr>
            )}
            {props.weeklyRecordList.length === 0 ?
                <tr>
                    <td colSpan="16">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.weeklyTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.weeklyTotalRecord.pa}</td>
                    <td>{props.weeklyTotalRecord.ab}</td>
                    <td>{props.weeklyTotalRecord.run}</td>
                    <td>{props.weeklyTotalRecord.hit}</td>
                    <td>{props.weeklyTotalRecord.h2}</td>
                    <td>{props.weeklyTotalRecord.h3}</td>
                    <td>{props.weeklyTotalRecord.hr}</td>
                    <td>{props.weeklyTotalRecord.rbi}</td>
                    <td>{props.weeklyTotalRecord.sh}</td>
                    <td>{props.weeklyTotalRecord.sf}</td>
                    <td>{props.weeklyTotalRecord.bb}</td>
                    <td>{props.weeklyTotalRecord.hp}</td>
                    <td>{props.weeklyTotalRecord.kk}</td>
                    <td>{props.weeklyTotalRecord.gd}</td>
                    <td>{(props.weeklyTotalRecord.hit / props.weeklyTotalRecord.ab).toFixed(3)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)

const WeeklyRecordComponentForPitcher = props => (
    <table>
        <caption>요일별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">요일</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">세이브</th>
                <th scope="col">홀드</th>
                <th scope="col">이닝</th>
                <th scope="col">투구</th>
                <th scope="col">타자</th>
                <th scope="col">타수</th>
                <th scope="col">안타</th>
                <th scope="col">홈런</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">폭투</th>
                <th scope="col">보크</th>
                <th scope="col">실점</th>
                <th scope="col">자책</th>
                <th scope="col">방어율</th>
            </tr>
        </thead>
        <tbody>
            {props.weeklyRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.w}</td>
                    <td>{item.l}</td>
                    <td>{item.sv}</td>
                    <td>{item.hold}</td>
                    <td>{item.innDisplay}</td>
                    <td>{item.tugucount}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.hit}</td>
                    <td>{item.hr}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.wp}</td>
                    <td>{item.bk}</td>
                    <td>{item.r}</td>
                    <td>{item.er}</td>
                    <td>{item.era}</td>
                </tr>
            )}
            {props.weeklyRecordList.length === 0 ?
                <tr>
                    <td colSpan="19">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.weeklyTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.weeklyTotalRecord.w}</td>
                    <td>{props.weeklyTotalRecord.l}</td>
                    <td>{props.weeklyTotalRecord.sv}</td>
                    <td>{props.weeklyTotalRecord.hold}</td>
                    <td>
                        {props.weeklyTotalRecord.inn2 % 3 === 0
                        ? parseInt(props.weeklyTotalRecord.inn2 / 3)
                        : parseInt(props.weeklyTotalRecord.inn2 / 3) +
                            " " +
                            (props.weeklyTotalRecord.inn2 % 3) +
                            "/3"}
                    </td>
                    <td>{props.weeklyTotalRecord.tugucount}</td>
                    <td>{props.weeklyTotalRecord.pa}</td>
                    <td>{props.weeklyTotalRecord.ab}</td>
                    <td>{props.weeklyTotalRecord.hit}</td>
                    <td>{props.weeklyTotalRecord.hr}</td>
                    <td>{props.weeklyTotalRecord.bb}</td>
                    <td>{props.weeklyTotalRecord.hp}</td>
                    <td>{props.weeklyTotalRecord.kk}</td>
                    <td>{props.weeklyTotalRecord.wp}</td>
                    <td>{props.weeklyTotalRecord.bk}</td>
                    <td>{props.weeklyTotalRecord.r}</td>
                    <td>{props.weeklyTotalRecord.er}</td>
                    <td>{((props.weeklyTotalRecord.er * 9) / (props.weeklyTotalRecord.inn2 / 3)).toFixed(2)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)


export default WeeklyRecordComponent;