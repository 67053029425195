import React from 'react';

export const BestDefenceRateComponent = props => (
    <table>
        <caption>최고방어율 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최고방어율</th>
                <td>{props.bestEraObj.matchTeam.era !== '100.00' ? props.bestEraObj.matchTeam.era : '0.00'}</td>
                <td>
                    {props.bestEraObj.matchTeam.imgUrl ?
                        <img src={props.bestEraObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최고방어율</th>
                <td>{props.bestEraObj.matchTeam.era !== '100.00' ? props.bestEraObj.matchTeam.era : '0.00'}</td>
                <td>{props.bestEraObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최고방어율</th>
                <td>{props.bestEraObj.matchTeam.era !== '100.00' ? props.bestEraObj.matchTeam.era : '0.00'}</td>
                <td>{props.bestEraObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestDefenceRateComponent;