import React, { Component } from 'react';
import SubHeadComponent from '../../common/SubHead.component';
import ContComponent from './Cont.component';
// import ContComponent from '../../common/PlayerInfoCont.component';

import * as ImageHelper from '../../../../helpers/Image.helper';
import * as PlayerHelper from '../../../../helpers/Player.helper';
import * as DateHelper from '../../../../helpers/Date.helper';
import * as GameService from '../../../../services/Game.service'
import * as ArticleService from '../../../../services/Article.service';

import $ from 'jquery';

import AnalyticsContext from '../../../../context/AnalyticsContext';
import * as CommonService from '../../../../services/Common.service'

class InfoContainer extends Component {
    static contextType = AnalyticsContext

    constructor(props) {
        super(props);

        const playerCode = this.props.match.params.playerCode;
        const uri = this.props.match.path.split('/').splice(0, 3).join('/');

        const subMenu = this.props.match.path.split('/')[2];
        const subMenuName = this.getSubMenuNameByType(subMenu);
        const positionInitial = PlayerHelper.getPositionInitialByType(subMenu);

        const yearSelectList = DateHelper.getYearSelectList();

        this.state = {
            subMenu,
            subMenuName,

            positionInitial,

            selectedYear: DateHelper.getCurrentYear(),
            yearSelectList,

            position: null,

            uri,
            info: null,
            playerList: [],
            playerSliderDefaultIdx: 0,
            weeklyRecordList: [],
            weeklyTotalRecord: null,
            monthlyRecordList: [],
            monthlyTotalRecord: null,
            homeAwayRecordList: [],
            homeAwayTotalRecord: null,
            dayNightRecordList: [],
            dayNightTotalRecord: null,
            matchTeamRecordList: [],
            matchTeamTotalRecord: null,
            summaryInfo: {},

            yearrecordlist: [],
            seasonallgame: [],
            seasonallgameTotal: null,

            playerCode,
            playerListSlider: null,
            subTabNum: 1,
            thirdTabNum: 1,
            thirdTabHeight: 'auto',
            graphTabNum: 1,
            monthlyGraphData: [],

            fourthTabNum: 1,

            // 투수
            bestEraObj: null,
            bestWinObj: null,
            bestHoldObj: null,
            bestSaveObj: null,
            bestKkObj: null,
            bestWraObj: null,

            // 타자
            bestHraObj: null,
            bestHitObj: null,
            bestHrObj: null,
            bestRbiObj: null,
            bestBraObj: null,
            bestSbObj: null,

            fifthTabHeight: (43 * 11) + "px",
            fifthTabNum: 1,

            galleryList: [],
            newsList: [],
            selectedPopUpContent: false,

            subtabTableHeight: "157px",
            screenMode: '',
        }
    }

    componentDidMount = async _ => {const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);

        currentAnalyticsVal.deps2 = "02";
        let deps3 = "";

        switch (this.state.positionInitial) {
          case "투":
            deps3 = "02"
            break;

          case "포":
            deps3 = "03"
            break;

          case "내":
            deps3 = "04"
            break;

          case "외":
            deps3 = "05"
            break;

          case "군":
            deps3 = "06"
            break;

          default:
            break;
        };

        currentAnalyticsVal.deps3 = deps3;
        currentAnalyticsVal.deps4 = "01";

        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href;

        await CommonService.insertAnalytics(this.context.state);

        $(window).resize(this.onResizeWindow).trigger('resize');
        await this.init();
    }

    componentDidUpdate = prevProps => {
        if (this.props.location !== prevProps.location) {
            this.updatePlayerCode(this.props.match.params.playerCode);
        }
    }

    componentWillUnmount = _ => {
        $(window).unbind('resize');
    }

    onResizeWindow = evt => {
        const width = evt.target.innerWidth;
        let screenMode = '';
        if (width > 1200) {
            screenMode = 'pc';
        } else if (width > 780) {
            screenMode = 'tablet';
        } else {
            screenMode = 'mobile';
        }
        this.setState({ screenMode }, _ => {
            const subtabTableHeight = this.getSubTabHeight(this.state.screenMode, this.state.subTabNum);
            this.setState({ subtabTableHeight });
        });
    }

    getSubMenuNameByType = type => {
        switch (type) {
            case 'pitcher': return '투수';
            case 'catcher': return '포수';
            case 'infielder': return '내야수';
            case 'outfielder': return '외야수';
            case 'military-enlisted': return '군입대선수';
            default: return '';
        }
    }

    updatePlayerCode = async (playerCode, year = this.state.selectedYear) => {
        try {
            const { playerList,
                playerSliderDefaultIdx,

                info,
                summaryInfo,
                weeklyRecordList,
                weeklyTotalRecord,
                monthlyRecordList,
                monthlyTotalRecord,
                homeAwayRecordList,
                homeAwayTotalRecord,
                dayNightRecordList,
                dayNightTotalRecord,
                matchTeamRecordList,
                matchTeamTotalRecord,

                monthlyGraphData,

                newsList,
                galleryList,

                yearrecordlist,
                seasonallgame,
                seasonallgameTotal } = await this.getInfo(playerCode, year);

            if ((this.playerListSlider !== null) && (this.playerListSlider !== undefined))
                this.playerListSlider.slickGoTo(playerSliderDefaultIdx);

            this.setState({
                playerCode,
                selectedYear: year,
                info,
                playerList,

                newsList,
                galleryList,
                summaryInfo,

                weeklyRecordList,
                weeklyTotalRecord,
                monthlyRecordList,
                monthlyTotalRecord,
                homeAwayRecordList,
                homeAwayTotalRecord,
                dayNightRecordList,
                dayNightTotalRecord,
                matchTeamRecordList,
                matchTeamTotalRecord,

                monthlyGraphData,
                yearrecordlist,
                seasonallgame,
                seasonallgameTotal,

                // bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj
            }, _ => {
                if (this.state.positionInitial === '투') {
                    const { bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj }
                        = this.makeBestRecordObjForPitcher(matchTeamRecordList, monthlyRecordList, weeklyRecordList);
                    this.setState({ bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj }, _ =>
                        this.refreshAllTabHeight())
                } else {
                    const { bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj }
                        = this.makeBestRecordObjForHitter(matchTeamRecordList, monthlyRecordList, weeklyRecordList);
                    this.setState({ bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj }, _ =>
                        this.refreshAllTabHeight());
                }
            });
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    refreshAllTabHeight = _ => {
        const subtabTableHeight = this.getSubTabHeight(this.state.screenMode, this.state.subTabNum);
        const thirdTabHeight = this.getThirdTabHeight(this.state.thirdTabNum);
        const fifthTabHeight = this.getFifthTabHeight(this.state.fifthTabNum);
        console.log('subtabTableHeight, thirdTabHeight, fifthTabHeight', subtabTableHeight, thirdTabHeight, fifthTabHeight);
        this.setState({ subtabTableHeight, thirdTabHeight, fifthTabHeight })
    }

    onClickBtnPlayer = (evt, playerCode) => {
        if (!playerCode) {
            alert('선수 정보를 준비중입니다.');
        } else {
            const uri = `${this.state.uri}/${playerCode}`;
            this.props.history.push(uri);
        }
    }

    makeBestRecordObjForHitter = (matchTeamRecordList, monthlyRecordList, weeklyRecordList) => {
        console.log('makeBestRecordObjForHitter', matchTeamRecordList, monthlyRecordList, weeklyRecordList)
        let defaultObj = {
            matchTeam: {
                imgUrl: null,
                groupIf: '',
                hra: '0.000',
                hit: 0,
                hr: 0,
                rbi: 0,
                bra: '0.000',
                sb: 0,
            },
            monthly: {
                groupIf: '',
                hra: '0.000',
                hit: 0,
                hr: 0,
                rbi: 0,
                bra: '0.000',
                sb: 0,
            },
            weekly: {
                groupIf: '',
                hra: '0.000',
                hit: 0,
                hr: 0,
                rbi: 0,
                bra: '0.000',
                sb: 0,
            }
        }


        // 최고 최고 타율
        let bestHraObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 최다 안타
        let bestHitObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 최다 홈런
        let bestHrObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 최다 타점
        let bestRbiObj = JSON.parse(JSON.stringify(defaultObj));
        // 최고 최고 출루율
        let bestBraObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 탈 최다 도루
        let bestSbObj = JSON.parse(JSON.stringify(defaultObj));

        for (let i = 0; i < matchTeamRecordList.length; i++) {
            const record = matchTeamRecordList[i];

            if (parseFloat(bestHraObj['matchTeam']['hra']) < parseFloat(record['hra'])) {
                bestHraObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestHraObj['matchTeam']['groupIf'] = record['groupIf'];
                bestHraObj['matchTeam']['hra'] = record['hra'];
            }

            if (parseInt(bestHitObj['matchTeam']['hit']) <= parseInt(record['hit'])) {
                console.log("overwrite");
                bestHitObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestHitObj['matchTeam']['groupIf'] = record['groupIf'];
                bestHitObj['matchTeam']['hit'] = record['hit'];
            }
            if (parseInt(bestHrObj['matchTeam']['hr']) <= parseInt(record['hr'])) {
                bestHrObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestHrObj['matchTeam']['groupIf'] = record['groupIf'];
                bestHrObj['matchTeam']['hr'] = record['hr'];
            }
            if (parseInt(bestRbiObj['matchTeam']['rbi']) <= parseInt(record['rbi'])) {
                bestRbiObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestRbiObj['matchTeam']['groupIf'] = record['groupIf'];
                bestRbiObj['matchTeam']['rbi'] = record['rbi'];
            }
            if (parseFloat(bestBraObj['matchTeam']['bra']) <= parseFloat(record['bra'])) {
                bestBraObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestBraObj['matchTeam']['groupIf'] = record['groupIf'];
                bestBraObj['matchTeam']['bra'] = record['bra'];
            }
            if (parseInt(bestSbObj['matchTeam']['sb']) < parseInt(record['sb'])) {
                bestSbObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestSbObj['matchTeam']['groupIf'] = record['groupIf'];
                bestSbObj['matchTeam']['sb'] = record['sb'];
            }
        }

        for (let i = 0; i < monthlyRecordList.length; i++) {
            const record = monthlyRecordList[i];
            if (parseFloat(bestHraObj['monthly']['hra']) < parseFloat(record['hra'])) {
                bestHraObj['monthly']['groupIf'] = record['groupIf'];
                bestHraObj['monthly']['hra'] = record['hra'];
            }
            if (parseInt(bestHitObj['monthly']['hit']) <= parseInt(record['hit'])) {
                bestHitObj['monthly']['groupIf'] = record['groupIf'];
                bestHitObj['monthly']['hit'] = record['hit'];
            }
            if (parseInt(bestHrObj['monthly']['hr']) <= parseInt(record['hr'])) {
                bestHrObj['monthly']['groupIf'] = record['groupIf'];
                bestHrObj['monthly']['hr'] = record['hr'];
            }
            if (parseInt(bestRbiObj['monthly']['rbi']) <= parseInt(record['rbi'])) {
                bestRbiObj['monthly']['groupIf'] = record['groupIf'];
                bestRbiObj['monthly']['rbi'] = record['rbi'];
            }
            if (parseFloat(bestBraObj['monthly']['bra']) <= parseFloat(record['bra'])) {
                bestBraObj['monthly']['groupIf'] = record['groupIf'];
                bestBraObj['monthly']['bra'] = record['bra'];
            }
            if (parseInt(bestSbObj['monthly']['sb']) < parseInt(record['sb'])) {
                bestSbObj['monthly']['groupIf'] = record['groupIf'];
                bestSbObj['monthly']['sb'] = record['sb'];
            }
        }
        for (let i = 0; i < weeklyRecordList.length; i++) {
            const record = weeklyRecordList[i];
            if (parseFloat(bestHraObj['weekly']['hra']) < parseFloat(record['hra'])) {
                bestHraObj['weekly']['groupIf'] = record['groupIf'];
                bestHraObj['weekly']['hra'] = record['hra'];
            }
            if (parseInt(bestHitObj['weekly']['hit']) <= parseInt(record['hit'])) {
                bestHitObj['weekly']['groupIf'] = record['groupIf'];
                bestHitObj['weekly']['hit'] = record['hit'];
            }
            if (parseInt(bestHrObj['weekly']['hr']) <= parseInt(record['hr'])) {
                bestHrObj['weekly']['groupIf'] = record['groupIf'];
                bestHrObj['weekly']['hr'] = record['hr'];
            }
            if (parseInt(bestRbiObj['weekly']['rbi']) <= parseInt(record['rbi'])) {
                bestRbiObj['weekly']['groupIf'] = record['groupIf'];
                bestRbiObj['weekly']['rbi'] = record['rbi'];
            }
            if (parseFloat(bestBraObj['weekly']['bra']) <= parseFloat(record['bra'])) {
                bestBraObj['weekly']['groupIf'] = record['groupIf'];
                bestBraObj['weekly']['bra'] = record['bra'];
            }
            if (parseInt(bestSbObj['weekly']['sb']) < parseInt(record['sb'])) {
                bestSbObj['weekly']['groupIf'] = record['groupIf'];
                bestSbObj['weekly']['sb'] = record['sb'];
            }
        }
        console.log('bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj ', bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj)
        return { bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj };
    }

    makeBestRecordObjForPitcher = (matchTeamRecordList, monthlyRecordList, weeklyRecordList) => {
        let defaultObj = {
            matchTeam: {
                imgUrl: null,
                groupIf: '',
                era: '100.00',
                w: 0,
                hold: 0,
                sv: 0,
                wra: 0,
                kk: 0,
            },
            monthly: {
                groupIf: '',
                era: '100.00',
                w: 0,
                hold: 0,
                sv: 0,
                wra: 0,
                kk: 0,
            },
            weekly: {
                groupIf: '',
                era: '100.00',
                w: 0,
                hold: 0,
                sv: 0,
                wra: 0,
                kk: 0,
            }
        }

        // 최고 방어율
        let bestEraObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 승리
        let bestWinObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 홀드
        let bestHoldObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 세이브
        let bestSaveObj = JSON.parse(JSON.stringify(defaultObj));
        // 최고 승률
        let bestWraObj = JSON.parse(JSON.stringify(defaultObj));
        // 최다 탈 삼진
        let bestKkObj = JSON.parse(JSON.stringify(defaultObj));

        for (let i = 0; i < matchTeamRecordList.length; i++) {
            const record = matchTeamRecordList[i];

            if (parseFloat(bestEraObj['matchTeam']['era']) > parseFloat(record['era'])) {
                bestEraObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestEraObj['matchTeam']['groupIf'] = record['groupIf'];
                bestEraObj['matchTeam']['era'] = record['era'];
            }

            if (parseInt(bestWinObj['matchTeam']['w']) < parseInt(record['w'])) {
                bestWinObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestWinObj['matchTeam']['groupIf'] = record['groupIf'];
                bestWinObj['matchTeam']['w'] = record['w'];
            }
            if (parseInt(bestHoldObj['matchTeam']['hold']) < parseInt(record['hold'])) {
                bestHoldObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestHoldObj['matchTeam']['groupIf'] = record['groupIf'];
                bestHoldObj['matchTeam']['hold'] = record['hold'];
            }
            if (parseInt(bestSaveObj['matchTeam']['sv']) < parseInt(record['sv'])) {
                bestSaveObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestSaveObj['matchTeam']['groupIf'] = record['groupIf'];
                bestSaveObj['matchTeam']['sv'] = record['sv'];
            }
            if (parseInt(bestWraObj['matchTeam']['wra']) < parseInt(record['wra'])) {
                bestWraObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestWraObj['matchTeam']['groupIf'] = record['groupIf'];
                bestWraObj['matchTeam']['wra'] = record['wra'];
            }
            if (parseInt(bestKkObj['matchTeam']['kk']) < parseInt(record['kk'])) {
                bestKkObj['matchTeam']['imgUrl'] = ImageHelper.getEmblemImgByTeamName(record['groupIf'])
                bestKkObj['matchTeam']['groupIf'] = record['groupIf'];
                bestKkObj['matchTeam']['kk'] = record['kk'];
            }
        }


        for (let i = 0; i < monthlyRecordList.length; i++) {
            const record = monthlyRecordList[i];
            if (parseFloat(bestEraObj['monthly']['era']) > parseFloat(record['era'])) {
                bestEraObj['monthly']['groupIf'] = record['groupIf'];
                bestEraObj['monthly']['era'] = record['era'];
            }
            if (parseInt(bestWinObj['monthly']['w']) < parseInt(record['w'])) {
                bestWinObj['monthly']['groupIf'] = record['groupIf'];
                bestWinObj['monthly']['w'] = record['w'];
            }
            if (parseInt(bestHoldObj['monthly']['hold']) < parseInt(record['hold'])) {
                bestHoldObj['monthly']['groupIf'] = record['groupIf'];
                bestHoldObj['monthly']['hold'] = record['hold'];
            }
            if (parseInt(bestSaveObj['monthly']['sv']) < parseInt(record['sv'])) {
                bestSaveObj['monthly']['groupIf'] = record['groupIf'];
                bestSaveObj['monthly']['sv'] = record['sv'];
            }
            if (parseInt(bestWraObj['monthly']['wra']) < parseInt(record['wra'])) {
                bestWraObj['monthly']['groupIf'] = record['groupIf'];
                bestWraObj['monthly']['wra'] = record['wra'];
            }
            if (parseInt(bestKkObj['monthly']['kk']) < parseInt(record['kk'])) {
                bestKkObj['monthly']['groupIf'] = record['groupIf'];
                bestKkObj['monthly']['kk'] = record['kk'];
            }
        }
        for (let i = 0; i < weeklyRecordList.length; i++) {
            const record = weeklyRecordList[i];
            if (parseFloat(bestEraObj['weekly']['era']) > parseFloat(record['era'])) {
                bestEraObj['weekly']['groupIf'] = record['groupIf'];
                bestEraObj['weekly']['era'] = record['era'];
            }
            if (parseInt(bestWinObj['weekly']['w']) < parseInt(record['w'])) {
                bestWinObj['weekly']['groupIf'] = record['groupIf'];
                bestWinObj['weekly']['w'] = record['w'];
            }
            if (parseInt(bestHoldObj['weekly']['hold']) < parseInt(record['hold'])) {
                bestHoldObj['weekly']['groupIf'] = record['groupIf'];
                bestHoldObj['weekly']['hold'] = record['hold'];
            }
            if (parseInt(bestSaveObj['weekly']['sv']) < parseInt(record['sv'])) {
                bestSaveObj['weekly']['groupIf'] = record['groupIf'];
                bestSaveObj['weekly']['sv'] = record['sv'];
            }
            if (parseInt(bestWraObj['weekly']['wra']) < parseInt(record['wra'])) {
                bestWraObj['weekly']['groupIf'] = record['groupIf'];
                bestWraObj['weekly']['wra'] = record['wra'];
            }
            if (parseInt(bestKkObj['weekly']['kk']) < parseInt(record['kk'])) {
                bestKkObj['weekly']['groupIf'] = record['groupIf'];
                bestKkObj['weekly']['kk'] = record['kk'];
            }
        }
        console.log('bestWinObj', bestWinObj);
        return { bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj };
    }


    makeTotalRecord = (recordList, positionInitial) => {
        if (recordList.length === 0) return false;

        let totalRecord = {};
        for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i];
            Object.keys(record).forEach((key, idx) => {
                if (!totalRecord[key]) {
                    totalRecord[key] = 0;
                }
                if (key === 'era' || key === 'hra') {
                    totalRecord[key] += parseFloat(record[key]);
                } else {
                    totalRecord[key] += parseInt(record[key], 10);
                }
            });
        }
        if (totalRecord) {
            if (positionInitial === '투') {
                if (totalRecord['era']) {
                    totalRecord['era'] = parseFloat(totalRecord['era'] / recordList.length).toFixed(3);
                }
            } else {
                if (totalRecord['hra']) {
                    totalRecord['hra'] = parseFloat(totalRecord['hra'] / recordList.length).toFixed(3);
                }
            }
        }
        console.log('totalRecord', positionInitial, totalRecord, recordList.length);
        return totalRecord;
    }

    getInfo = async (playerCode, year = 2019) => {
        console.log('getInfo', playerCode, year);
        let result = null;
        let info = null;
        if (this.state.positionInitial === '군') {
            result = await GameService.getMilitaryEnlistedInfo(playerCode, year);
            info = result.gameplayer;
        } else if (this.state.positionInitial === '투') {
            result = await GameService.getPitcherInfo(playerCode, year);
            info = result.gameplayer;
        } else {
            result = await GameService.getHitterInfo(playerCode, year);
            info = result.gameplayer;
        }

        const playerList = result.playerList;
        // const summaryInfo = result['seasonsummary'] ? result['seasonsummary'] : result['seasonsummaryfutures'];
        const summaryInfo = result['seasonsummary']; //1군 경기만 노출
        const weeklyRecordList = result.byweek;
        const weeklyTotalRecord = this.makeTotalRecord(weeklyRecordList, this.state.positionInitial);
        const monthlyRecordList = result.bymonth;
        const monthlyTotalRecord = this.makeTotalRecord(monthlyRecordList, this.state.positionInitial);
        const homeAwayRecordList = result.byvisit;
        const homeAwayTotalRecord = this.makeTotalRecord(homeAwayRecordList, this.state.positionInitial);
        const dayNightRecordList = result.bydaytime;
        const dayNightTotalRecord = this.makeTotalRecord(dayNightRecordList, this.state.positionInitial);
        const matchTeamRecordList = result.bymatchteam;
        const matchTeamTotalRecord = this.makeTotalRecord(matchTeamRecordList, this.state.positionInitial);

        // console.log('weeklyTotalRecord', weeklyTotalRecord)
        // console.log('monthlyTotalRecord', monthlyTotalRecord)
        // console.log('homeAwayTotalRecord', homeAwayTotalRecord)
        // console.log('dayNightTotalRecord', dayNightTotalRecord)
        // console.log('matchTeamTotalRecord', matchTeamTotalRecord)

        let playerSliderDefaultIdx = 0;
        for (let i = 0; i < playerList.length; i++) {
            // 군입대 선수 제외
            if (playerList[i]['armyyn'] === 'Y') {
                delete playerList[i];
                continue;
            }

            // 최초 리스트에 표시할 선수 인덱스 선택
            if (playerList[i]['pcode'] == playerCode) {
                playerSliderDefaultIdx = i;
            }
        }

        // 통산기록
        const yearrecordlist = result.yearrecordlist;

        // 상세기록
        let seasonallgame = result.seasonallgame;
        // 상세기록 합계
        let seasonallgameTotal = null;
        for (let i = 0; i < seasonallgame.length; i++) {
            if (i === 0) {
                seasonallgameTotal = {};
            }
            let season = seasonallgame[i];
            Object.keys(season).map((key, idx) => {
                if (!seasonallgameTotal[key]) {
                    seasonallgameTotal[key] = 0;
                }
                seasonallgameTotal[key] += (season[key] * 1);
                return true;
            });

            console.log(seasonallgame[i]['wls']);
            if (seasonallgame[i]['wls'] === 'W') {
                if (seasonallgame[i]['changeInn'] === '') {
                    seasonallgame[i]['wlsString'] = "선발승"
                } else {
                    seasonallgame[i]['wlsString'] = "구원승"
                }
            } else if (seasonallgame[i]['wls'] === 'L') {
                seasonallgame[i]['wlsString'] = "패"
            } else if (seasonallgame[i]['wls'] === 'H') {
                seasonallgame[i]['wlsString'] = "홀드"
            } else if (seasonallgame[i]['wls'] === 'S') {
                seasonallgame[i]['wlsString'] = "세이브"
            } else {
                seasonallgame[i]['wlsString'] = ""
            }
        }

        if (seasonallgameTotal) {
            if (this.state.positionInitial === '투') {
                if (seasonallgameTotal['era']) {
                    seasonallgameTotal['era'] = (seasonallgameTotal['era'] / seasonallgameTotal.length)
                }
            } else {
                if (seasonallgameTotal['hra']) {
                    seasonallgameTotal['hra'] = (seasonallgameTotal['hra'] / seasonallgameTotal.length)
                }
            }
        }
        console.log('seasonallgame', seasonallgame)
        console.log('seasonallgameTotal', seasonallgameTotal)

        // news
        const newsParams = {
            'search.pos': 0,
            'search.max': 5,
            'search.searchCategory': 200,
            'search.searchWord': info.playerName
        }
        const newsResult = await ArticleService.getNewsArticleList(newsParams);
        let newsList = newsResult.list
        for (let i = 0; i < newsList.length; i++) {
            newsList[i]['regDate'] = DateHelper.convertTimestampToDate(newsList[i]['regDttm']);
        }
        console.log('newsList', newsList);

        // const galleryParams = {
        // 'search.pos': 0,
        // 'search.max': 5,
        // 'search.searchCategory': 500,
        // 'search.searchWord': info.playerName
        // }
        // const galleryResult = await ArticleService.getPicturesList(galleryParams);
        const galleryResult = await ArticleService.getPlayerPicture(info.playerName)
        let galleryList = galleryResult.list;
        // for (let i = 0; i < galleryList.length; i++) {
        //     galleryList[i]['regDate'] = DateHelper.convertTimestampToDate(galleryList[i]['regDttm']);
        // }

        // Make Graph Info
        const monthlyGraphData = this.makeMonthlyGraphData(this.state.graphTabNum, monthlyRecordList, this.state.positionInitial);

        return {
            playerList,
            playerSliderDefaultIdx,

            info,
            summaryInfo,
            weeklyRecordList,
            weeklyTotalRecord,
            monthlyRecordList,
            monthlyTotalRecord,
            homeAwayRecordList,
            homeAwayTotalRecord,
            dayNightRecordList,
            dayNightTotalRecord,
            matchTeamRecordList,
            matchTeamTotalRecord,

            monthlyGraphData,

            newsList,
            galleryList,

            yearrecordlist,
            seasonallgame,
            seasonallgameTotal
        }
    }

    init = async _ => {
        try {
            const { playerList,
                playerSliderDefaultIdx,

                info,
                summaryInfo,
                weeklyRecordList,
                weeklyTotalRecord,
                monthlyRecordList,
                monthlyTotalRecord,
                homeAwayRecordList,
                homeAwayTotalRecord,
                dayNightRecordList,
                dayNightTotalRecord,
                matchTeamRecordList,
                matchTeamTotalRecord,

                monthlyGraphData,

                newsList,
                galleryList,

                yearrecordlist,
                seasonallgame } = await this.getInfo(this.state.playerCode, this.state.selectedYear);

            if ((this.playerListSlider !== null) && (this.playerListSlider !== undefined))
                this.playerListSlider.slickGoTo(playerSliderDefaultIdx);

            this.setState({
                playerList,
                playerSliderDefaultIdx,

                info,
                summaryInfo,
                weeklyRecordList,
                weeklyTotalRecord,
                monthlyRecordList,
                monthlyTotalRecord,
                homeAwayRecordList,
                homeAwayTotalRecord,
                dayNightRecordList,
                dayNightTotalRecord,
                matchTeamRecordList,
                matchTeamTotalRecord,

                monthlyGraphData,

                newsList,
                galleryList,

                yearrecordlist,
                seasonallgame
            }, _ => {
                if (this.state.positionInitial === '투') {
                    const { bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj }
                        = this.makeBestRecordObjForPitcher(matchTeamRecordList, monthlyRecordList, weeklyRecordList);
                    this.setState({ bestEraObj, bestWinObj, bestHoldObj, bestSaveObj, bestKkObj, bestWraObj }, _ => this.refreshAllTabHeight())
                } else {
                    const { bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj }
                        = this.makeBestRecordObjForHitter(matchTeamRecordList, monthlyRecordList, weeklyRecordList);
                    this.setState({ bestHraObj, bestHitObj, bestHrObj, bestRbiObj, bestBraObj, bestSbObj }, _ => this.refreshAllTabHeight())
                }
            });
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    setSliderRef = (type, slider) => {
        if (type === 'player') {
            this.playerListSlider = slider;
        } else if (type === 'news') {
            this.newsListSlider = slider;
        } else if (type === 'gallery') {
            this.galleryListSlider = slider;
        }
    }

    onClickBtnSlidePrev = (evt, type) => {
        if (type === 'player') {
            this.playerListSlider.slickPrev();
        } else if (type === 'news') {
            this.newsListSlider.slickPrev();
        } else if (type === 'gallery') {
            this.galleryListSlider.slickPrev();
        }
    }
    onClickBtnSlideNext = (evt, type) => {
        if (type === 'player') {
            this.playerListSlider.slickNext();
        } else if (type === 'news') {
            this.newsListSlider.slickNext();
        } else if (type === 'gallery') {
            this.galleryListSlider.slickNext();
        }
    }

    onClickBtnSubTab = (evt, tabNum) => {
        this.setState({
            subTabNum: tabNum
        }, _ => {
            let subtabTableHeight = this.getSubTabHeight(this.state.screenMode, tabNum);
            console.log('subtabTableHeight', subtabTableHeight)
            this.setState({ subtabTableHeight })
        });
    }
    getSubTabHeight = (screenMode, tabNum) => {
        console.log('getSubTabHeight', screenMode, tabNum);
        let divHeight = 0;
        if (screenMode === 'pc') {
            divHeight = 'auto';
        } else {
            if (tabNum === 2) {
                divHeight = $('.mb_news').height();
            } else if (tabNum === 3) {
                divHeight = $('.mb_gallery').height();
            } else {
                divHeight = 'auto';
            }
        }
        if (divHeight !== 'auto') {
            divHeight += 'px';
        }
        return divHeight;
    }

    onChangeSelectList = (evt) => {
        console.log('evt.target.value', evt.target.value);
        this.setState({ selectedYear: evt.target.value });
    }
    onClickBtnSearch = evt => {
        this.updatePlayerCode(this.state.playerCode, this.state.selectedYear);
    }

    onClickBtnThirdTab = (evt, tabNum) => {
        let thirdTabHeight = this.getThirdTabHeight(tabNum);
        this.setState({ thirdTabNum: tabNum, thirdTabHeight })
    };
    getThirdTabHeight = tabNum => {
        let tabHeight = 'auto';
        if (tabNum === 2) {
            tabHeight = 40 + (45 * (2 + this.state.yearrecordlist.length));
            tabHeight += 40 + (45 * (2 + this.state.seasonallgame.length));
            tabHeight += "px";
            console.log('tabHeight', tabHeight);
        }
        return tabHeight;
    }
    onClickBtnGraphTab = (evt, tabNum) => {
        const list = this.state.monthlyRecordList;
        const monthlyGraphData = this.makeMonthlyGraphData(tabNum, list, this.state.positionInitial);
        this.setState({
            graphTabNum: tabNum,
            monthlyGraphData
        });
    };
    makeMonthlyGraphData = (tabNum, list, positionInitial) => {
        console.log('makeMonthlyGraphData');
        let key = '';
        if (positionInitial === '투') {
            switch (tabNum) {
                case 1: key = 'era'; break;
                case 2: key = 'w'; break;
                case 3: key = 'hold'; break;
                case 4: key = 'save'; break;
                case 5: key = 'wra'; break;
                case 6: key = 'kk'; break;
                default: break;
            }
        } else {
            switch (tabNum) {
                case 1: key = 'hra'; break;
                case 2: key = 'hit'; break;
                case 3: key = 'hr'; break;
                case 4: key = 'rbi'; break;
                case 5: key = 'bra'; break;
                case 6: key = 'sb'; break;
                default: break;
            }
        }
        let graphData = {
            labels: [],
            datasets: [{
                label: null,
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: []
            }]
        }
        for (let i = 0; i < list.length; i++) {
            const record = list[i];
            graphData.labels.push(record['groupIf']);
            graphData.datasets[0].data.push(record[key]);
        }
        console.log(graphData);
        return graphData;
    }

    onClickBtnFourthTab = (evt, tabNum) => this.setState({ fourthTabNum: tabNum });
    onClickBtnFifthTab = (evt, tabNum) => {
        const fifthTabHeight = this.getFifthTabHeight(tabNum);
        this.setState({
            fifthTabNum: tabNum,
            fifthTabHeight
        })
    }
    getFifthTabHeight = (tabNum) => {
        let rows = 0;
        switch (tabNum) {
            case 1: rows = this.state.matchTeamRecordList.length; break;
            case 2: rows = this.state.monthlyRecordList.length; break;
            case 3: rows = this.state.weeklyRecordList.length; break;
            case 4: rows = this.state.dayNightRecordList.length; break;
            case 5: rows = this.state.homeAwayRecordList.length; break;
            default: rows = 0;
        }

        return (43 * (2 + rows));
    }

    onClickBtnNews = (evt, articleId) => {
        this.props.history.push(`/contents/news/${articleId}`);
    }

    onClickBtnPicture = (evt, name) => {
        console.log("사진 버튼 클릭")
        this.setState({ selectedPopupContent: true })
    }
    onClickBtnClosePopup = () => {
        console.log('onClickBtnClosePopup');
        this.setState({ selectedPopupContent: false })
    }

    render = _ => (
        <div className='container'>
            <SubHeadComponent
                subMenuName={this.state.subMenuName} />
            <div className="sbHead_appView tb"></div>
            <ContComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName}

                path={this.props.match.path}
                uri={this.state.uri}

                selectedYear={this.state.selectedYear}
                yearSelectList={this.state.yearSelectList}

                playerList={this.state.playerList}
                playerSliderDefaultIdx={this.state.playerSliderDefaultIdx}

                onClickBtnPlayer={this.onClickBtnPlayer}
                playerCode={this.state.playerCode}
                info={this.state.info}
                summaryInfo={this.state.summaryInfo}
                positionInitial={this.state.positionInitial}

                galleryList={this.state.galleryList}
                onClickBtnPicture={this.onClickBtnPicture}

                newsList={this.state.newsList}
                onClickBtnNews={this.onClickBtnNews}

                setSliderRef={this.setSliderRef}
                onClickBtnSlidePrev={this.onClickBtnSlidePrev}
                onClickBtnSlideNext={this.onClickBtnSlideNext}

                subTabNum={this.state.subTabNum}
                subtabTableHeight={this.state.subtabTableHeight}
                onClickBtnSubTab={this.onClickBtnSubTab}

                onChangeSelectList={this.onChangeSelectList}
                onClickBtnSearch={this.onClickBtnSearch}

                yearrecordlist={this.state.yearrecordlist}
                seasonallgame={this.state.seasonallgame}

                thirdTabHeight={this.state.thirdTabHeight}
                thirdTabNum={this.state.thirdTabNum}

                graphTabNum={this.state.graphTabNum}
                monthlyGraphData={this.state.monthlyGraphData}
                onClickBtnGraphTab={this.onClickBtnGraphTab}
                onClickBtnThirdTab={this.onClickBtnThirdTab}

                fourthTabNum={this.state.fourthTabNum}
                onClickBtnFourthTab={this.onClickBtnFourthTab}

                // 투수 정보
                bestEraObj={this.state.bestEraObj}
                bestWinObj={this.state.bestWinObj}
                bestHoldObj={this.state.bestHoldObj}
                bestSaveObj={this.state.bestSaveObj}
                bestKkObj={this.state.bestKkObj}
                bestWraObj={this.state.bestWraObj}

                // 타자 정보
                bestHraObj={this.state.bestHraObj}
                bestHitObj={this.state.bestHitObj}
                bestHrObj={this.state.bestHrObj}
                bestRbiObj={this.state.bestRbiObj}
                bestBraObj={this.state.bestBraObj}
                bestSbObj={this.state.bestSbObj}

                fifthTabNum={this.state.fifthTabNum}
                fifthTabHeight={this.state.fifthTabHeight}
                onClickBtnFifthTab={this.onClickBtnFifthTab}

                weeklyRecordList={this.state.weeklyRecordList}
                weeklyTotalRecord={this.state.weeklyTotalRecord}
                monthlyRecordList={this.state.monthlyRecordList}
                monthlyTotalRecord={this.state.monthlyTotalRecord}
                homeAwayRecordList={this.state.homeAwayRecordList}
                homeAwayTotalRecord={this.state.homeAwayTotalRecord}
                dayNightRecordList={this.state.dayNightRecordList}
                dayNightTotalRecord={this.state.dayNightTotalRecord}
                matchTeamRecordList={this.state.matchTeamRecordList}
                matchTeamTotalRecord={this.state.matchTeamTotalRecord}

                selectedPopupContent={this.state.selectedPopupContent}
                onClickBtnClosePopup={this.onClickBtnClosePopup}
            />
        </div>
    )
}

export default InfoContainer;
