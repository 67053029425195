import React from "react";

const ContComponent = props => (
  <div className="mb_cont tigers">
    <div className="mb_contIn">
      <article>
        <h5 className="lineRed">
          <span className="txtRed">KIA타이거즈</span> 프론트 조직도
        </h5>
        {props.imgUrlObj ? (
          <div className="chart">
            <img
              src={props.imgUrlObj.pc}
              alt="대표이사 아래 단장과 지원실장, 단장아래, 육성총괄 그아래 운영지원팀, 운영기획팀, 홍보팀/ 지원실장 아래 재무팀, 마케팅팀, 비지니스지원팀"
            />
          </div>
        ) : null}
      </article>
    </div>
  </div>
);
export default ContComponent;
