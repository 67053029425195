import React from 'react';

export const BestHoldComponent = props => (
    <table>
        <caption>최다타점 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최다타점</th>
                <td>{props.bestRbiObj.matchTeam.rbi}</td>
                <td>
                    {props.bestRbiObj.matchTeam.imgUrl ?
                        <img src={props.bestRbiObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최다타점</th>
                <td>{props.bestRbiObj.monthly.rbi}</td>
                <td>{props.bestRbiObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최다타점</th>
                <td>{props.bestRbiObj.weekly.rbi}</td>
                <td>{props.bestRbiObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestHoldComponent;