import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'
import * as AuthHelper from '../../../helpers/Auth.helper'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { checkEventTarget, getEventApplyStatus, getEventDetail, requestApplyEvent } from '../../../services/Event.service'
import {checkIsOnWebview} from "../../../helpers/Common.helper";

dayjs.locale('ko')

const kboTeamList = {
  63: "/img/ticket/emblem/hanwha.png",
  58: "/img/ticket/emblem/kia.png",
  62: "/img/ticket/emblem/kt.png",
  59: "/img/ticket/emblem/lg.png",
  60: "/img/ticket/emblem/lotte.png",
  64: "/img/ticket/emblem/nc.png",
  55: "/img/ticket/emblem/doosan.png",
  476: "/img/ticket/emblem/ssg.png",
  57: "/img/ticket/emblem/samsung.png",
  61: "/img/ticket/emblem/kiwoom.png"
}

class DetailContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    const eventIdx = this.props.match.params.eventIdx;
    // if eventIdx Not number
    if(!eventIdx || isNaN(eventIdx)) {
        alert('잘못된 접근입니다.');
        document.location.href = '/';
    }


    this.state = {
      subMenu: "guide",
      subMenuName: "투어프로그램 신청",
      title: '',
      contents: '',
      userId: '',
      userName: '',
      mobile_no: '',
      child_name: '',
      child_birth_date: '',
      tour_date: '',
      eventIdx,
      eventInfo: {},
      confirm: ''
    };
  }

  componentDidMount = async (_) => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/kids/tour/'+this.state.eventIdx;
      return;
    } else {
      this.setState({ isInit: true });
    }

    // eventInfo request
    try {
      const eventInfo = await getEventDetail(this.state.eventIdx);
      console.log(eventInfo);



    const now = dayjs();
    const start = dayjs(eventInfo['start_apply'], 'YYYYMMDD HH:mm');
    const end = dayjs(eventInfo['end_apply'], 'YYYYMMDD HH:mm');

    if (now.isBefore(start) || now.isAfter(end)) {
      alert('이벤트 기간이 아닙니다.');
      if(checkIsOnWebview()) {
        document.location.href = 'about:blank';
      } else {
        document.location.href = '/';
      }
      return;
    }

    // const checkTarget = await checkEventTarget()
    // if(!checkTarget) {
    //   alert('2023년 어린이 회원만 신청 가능합니다.')
    //   document.location.href = '/';
    //   return;
    // }

    // const alreadyApply = await getEventApplyStatus({eventIdx:1});
    // if(!alreadyApply) {
    //   alert('이미 신청이 완료되었습니다.')
    //   // history back
    //   window.history.back();
    //   return;
    // }

    const loginInfo = AuthHelper.getLoginInfo();

    const userId = loginInfo.user.simpleId;
    const userName = loginInfo.user.memberName;


    //230130 주석
    this.setState({userId: userId, userName: userName, eventInfo: eventInfo});
    } catch (e) {
      if(e.message === "세션이 만료되었습니다.") {
        document.location.href = '/login?redirect=/event/kids/tour/'+this.state.eventIdx;
        return;
      }
      if(checkIsOnWebview()) {
        document.location.href = 'about:blank';
      } else {
        document.location.href = '/';
      }
    }
  };

  onChangeTextAreaContents = (evt) => {
    this.setState({ contents: evt.target.value });
  };
  onChangeInputMobileNo = (evt) => {
    this.setState({ mobile_no: evt.target.value });
  };

  onChangeInputChildName = (evt) => {
    this.setState({ child_name: evt.target.value });
  };

  onChangeInputTourDate = (evt) => {
    this.setState({ tour_date: evt.target.value });
  };

  onChangeInputChildBirthDate = (evt) => {
    this.setState({ child_birth_date: evt.target.value });
  }

  onClickBtnRequest = async (_) => {
    //mobile_no
    // child_name
    // tour_date
    // userId
    // userName

    const { mobile_no, child_name, child_birth_date, userId, userName, confirm, eventIdx} = this.state;
    const loginInfo = AuthHelper.getLoginInfo();
    const userNo = loginInfo.user.userNo;
    const userNm = loginInfo.user.memberName;
    const userMobile = loginInfo.user.mobileNo;

    if(!mobile_no) {
      alert('연락처를 입력해주세요.');
      return;
    }

    const isValidPhoneNumber = phoneNumber => {
      const pattern = /^01[0-9]{9}$/;
      return pattern.test(phoneNumber.replace(/-/g, ''));
    }

    if(mobile_no.length < 10 || !isValidPhoneNumber(mobile_no) ) {
      alert('연락처를 올바르게 입력해주세요.');
      return;
    }

    if(!child_name) {
      alert('어린이 이름을 입력해주세요.');
      return;
    }

    // if(!tour_date) {
    //   alert('투어 일정을 입력해주세요.');
    //   return;
    // }

    if(!child_birth_date) {
      alert('어린이 생년월일을 입력해주세요.');
      return;
    }

    if(!confirm) {
      alert('신청 동의를 체크해주세요.');
      return;
    }

    const check = window.confirm("이벤트 신청 시 취소 및 수정이 불가합니다.\n이벤트 신청을 하시겠습니까?")
    if(check) {
      const data = {
        userNo,
        userNm,
        userMobile,
        add_info1: child_name,
        mobile_no,
        add_info2: child_birth_date,
        eventIdx,
        confirm
      }
      const result = await requestApplyEvent(data)
      if(result.status) {
        alert('이벤트 응모가 완료되었습니다.');
        if(checkIsOnWebview()) {
          document.location.href = '/event/success';
        } else {
          document.location.href = '/';
        }
      } else {
        alert('이벤트 신청에 실패하였습니다.\n' + result.message);
      }
    }


  }

  onChangeInputConfirm  = (evt) => {
    this.setState({confirm: evt.target.checked});
  }



  render = (_) => (
    <div className="container">
      {/*<SubHeadComponent subMenuName={this.state.subMenuName} />*/}

      {/* 230127 주석 */}
      {/*{ !CommonHelper.checkIsOnWebview() && (*/}
      {/*  <GuideTabComponent*/}
      {/*    onClickBtnChangeMembership={this.onClickBtnChangeMembership}*/}
      {/*    tabNum={this.state.tabNum}*/}
      {/*  />*/}
      {/*)}*/}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        onChangeTextAreaContents={this.onChangeTextAreaContents}
        onChangeInputChildName={this.onChangeInputChildName}
        onChangeInputMobileNo={this.onChangeInputMobileNo}
        onChangeInputTourDate={this.onChangeInputTourDate}
        onClickBtnRequest={this.onClickBtnRequest}
        contents={this.state.contents}
        title={this.state.title}
        userId={this.state.userId}
        userName={this.state.userName}
        mobile_no={this.state.mobile_no}
        child_name={this.state.child_name}
        child_birth_date={this.state.child_birth_date}
        onChangeInputChildBirthDate={this.onChangeInputChildBirthDate}
        eventInfo={this.state.eventInfo}
        onChangeInputConfirm={this.onChangeInputConfirm}
      /> }
    </div>
  );
}

export default DetailContainer;
