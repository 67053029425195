// axios 인터 셉터
import axios from "axios"
import cookie from "react-cookies"
import * as AuthHelper from "../helpers/Auth.helper"
import * as JSBridgeHelper from "../helpers/JSBridge.helper"

export const init = () => {
  // console.log("Axios.interceptor::init()")
  let loginInfo = cookie.load("loginInfo")
  // console.log(loginInfo)

  axios.defaults.headers.common["AKey"] = "f68cNbKYSKJYan41zIcjOmbRUxQ="
  axios.defaults.headers.common["App-Agent"] = "platformCode=70;platformVer=1.0.0;deviceId=WEB;appName=tigersWeb;appVer=1.0.0;deviceModel=Browser;"

  axios.interceptors.request.use(
    (config) => {
      let loginInfo = cookie.load("loginInfo")
      console.log(":@@@@@@@@@@@@@@@@@@@@@@@@@@");
      console.log(config, loginInfo)

      if (loginInfo) {
        if (loginInfo["accessToken"]) {
          config.headers["Access-Token"] = loginInfo["accessToken"]
        }
        if (loginInfo["refreshToken"]) {
          config.headers["Refresh-Token"] = loginInfo["refreshToken"]
        }
      }
      return config
    },
    (err) => {
      console.log("###");
      console.error(err)
      Promise.reject(err)
    }
  )

  axios.interceptors.response.use((response) => {
    console.log(response);
    return new Promise((resolve, reject) => {
      console.log("axios.interceptors.response")
      console.log(response.data)

      const result = response.data
      let status = result.status
      console.log(status)
      console.log(response.status);
      if(response.status === 401) {
        // alert(`세션이 만료되었습니다.`)
        AuthHelper.removeLoginInfo()
        window.location.href = "/login"

        return resolve({message: "세션이 만료되었습니다1"});
      }
      // test
      //JSBridgeHelper.testBridge('aaa', 'bbb');

      // status = 8122;

      // 8101	"유효하지 않은 Token입니다"
      // 8111	"유효하지 않은 Access Token입니다"
      // 8112	"만료된 Access Token입니다"
      // 8121	"유효하지 않은 Refresh Token입니다"
      // 8122	"만료된 Refresh Token입니다"

      // console.log('response.config', response.config);
      // Test code for expired accessToken
      // if (response.config.url.includes('/v1/article/updateReply')) {
      //     console.log('modify reply')
      //     status = 8112;
      // }
      // refresh AccessToken
      if (status == 8112) {
        // console.log("doRefreshToken")
        let config = response.config

        const memberId = AuthHelper.getMemberId()
        if (!memberId) {
          AuthHelper.removeLoginInfo()
          alert("정상적인 접근이 아닙니다.(refreshAccessToken")
          window.location.href = "/"
        }
        const refreshTokenData = {}
        // refreshTokenData.append("user.memberId", memberId)
        refreshTokenData["user.memberId"] = memberId
        const accessToken = AuthHelper.getAccessToken()
        const refreshToken = AuthHelper.getRefreshToken()
        let headers = config.headers
        headers["Access-Token"] = accessToken
        headers["Refresh-Token"] = refreshToken

        // console.log("headers", headers)

        const refreshAxios = axios.create({headers})
        return refreshAxios
          .post("/v1/auth/refreshAccessToken", refreshTokenData)
          .then(({status, data}) => {
            // console.log(status)
            // console.log("prev accessToken", accessToken)
            // console.log("new accessToken", data.data)
            if (status == 8112) {
              throw new Error("다시 로그인 해주세요.")
            }

            let loginInfo = AuthHelper.getLoginInfo()
            loginInfo.accessToken = data.data.accessToken
            loginInfo.refreshToken = data.data.refreshToken
            AuthHelper.setLoginInfo(loginInfo)

            // native app 쪽으로 accessToken, refreshToken 을 넘겨준다
            JSBridgeHelper.testBridge(loginInfo.accessToken, loginInfo.refreshToken)

            return loginInfo
          })
          .then((loginInfo) => {
            console.log("try again")
            let retryHeaders = config.headers
            retryHeaders["Access-Token"] = loginInfo.accessToken
            retryHeaders["Refresh-Token"] = loginInfo.refreshToken

            const retryAxios = axios.create({headers: retryHeaders})

            if (config.method === "get") {
              return retryAxios.get(config.url)
            } else if (config.method === "post") {
              return retryAxios.post(config.url, config.data)
            }
          })
          .then((response) => {
            // console.log("try again response")
            // console.log(response)
            return resolve(response)
          })
          .catch((err) => {
            // alert(`로그인이 필요합니다.(${status})`);
            AuthHelper.removeLoginInfo()
            window.location.href = "/login"
            return resolve(response)

            // original
            // console.log('after refreshAxios error');
            // console.error(err);
            // throw new Error(err.message);
          })

        //     })

        // const token = result.data.new_token;
        // loginInfo['accessToken'] = token;
        // // cookie.save('loginInfo', loginInfo, { domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: false, httpOnly: false, path: '/' });

        // let config = response.config;
        // const newAxios = axios.create({ headers: { 'Authorization': `bearer ${token}` } });

        // if (config.method === 'get') {
        //     return newAxios.get(config.url)
        //         .then(newResponse => {
        //             return resolve(newResponse);
        //         })
        // }
        // else {
        //     if (config.data != null) {
        //         if ('object' !== typeof config.data) {
        //             config.data = JSON.parse(config.data);
        //         }
        //     }

        //     if (config.method === 'post') {
        //         return newAxios.post(config.url, config.data)
        //             .then(newResponse => {
        //                 return resolve(newResponse);
        //             });
        //     }
        //     else if (config.method === 'put') {
        //         return newAxios.put(config.url, config.data)
        //             .then(newResponse => {
        //                 return resolve(newResponse);
        //             });
        //     }
        // }
      } else if (status == 1000 || status == 2016 || status == 8101 || status == 8111 || status == 8121 || status == 8122) {
        AuthHelper.removeLoginInfo()
        if (status == 2016 || status === 8111 || status === 8121 || status === 8122) {
          window.location.href = "/login"
        }
        if (status === 8111 || status === 8121 || status === 8122) {
          alert(`세션이 만료되었습니다.2`)
        } else {
          alert(`로그인이 필요합니다.(${status})`)
        }
        return resolve(response)
      } else if(response.status === 401) {

        alert(`세션이 만료되었습니다.3`)
        AuthHelper.removeLoginInfo()
        window.location.href = "/login"
        return resolve(response);
      } else {
        return resolve(response)
      }
    })
  }, (error) => {
    console.log(error);
    const statusCode = error.response.status;
    if(statusCode === 401) {
      // alert(`세션이 만료되었습니다.`)
      AuthHelper.removeLoginInfo();
      // 현재 브라우저 URL PATH로 login?redirect=현재URLPATH 를 만들어서 이동
      // console.log(window.location.href);
      // console.log(window.location.pathname);

      window.location.href = "/login?redirect=" + window.location.pathname;
      return Promise.reject({message: "세션이 만료되었습니다.", status: 401})
    }
    return Promise.reject(error);
  })
}
