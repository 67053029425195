import React from 'react';

import Slider from 'react-slick';

import ImgComponent from '../../../common/Img.component';
import * as ImageHelper from '../../../../helpers/Image.helper'

const sliderOptions = {
    autoplay: true,
    speed: 500,
    // arrows: true,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: false,
    draggable: false,
}

const NewsListSliderComponent = props => (
    props.newsList.length > sliderOptions.slidesToShow ?
        <Slider
            ref={slider => props.setSliderRef("news", slider)}
            {...sliderOptions}>
            {props.newsList.map((item, idx) =>
                <li onClick={evt => props.onClickBtnNews(evt, item.artcSeq)} key={idx}
                    className='news-list-item'>
                    <p>
                        <ImgComponent
                            imgUrl={item.imgFilePath}
                            defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                            imgName={item.artcTitle} />
                    </p>
                    <p>{item.artcTitle}</p>
                    <p><span className="date">{item.regDate}</span><span className="num">{item.viewCnt}</span></p>
                </li>
            )}
        </Slider>
        :
        props.newsList.map((item, idx) =>
            <li onClick={evt => props.onClickBtnNews(evt, item.artcSeq)} key={idx}
                className='news-list-item'>
                <p>
                    <ImgComponent
                        imgUrl={item.imgFilePath}
                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                        imgName={item.artcTitle} />
                </p>
                <p>{item.artcTitle}</p>
                <p><span className="date">{item.regDate}</span><span className="num">{item.viewCnt}</span></p>
            </li>
        )
)

export default NewsListSliderComponent