import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import ListContainer from './list/List.container';
import FormContainer from './form/Form.container';
import InfoContainer from './info/Info.container';

import * as AuthHelper from '../../../helpers/Auth.helper';

class InquiryContainer extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            isInit: false
        });
    }

    componentDidMount = _ => {
        //230127 주석
      alert("1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.");
      window.history.back();
        if (!AuthHelper.checkHasLogin()) {
            alert("로그인 후 이용하실 수 있는 페이지입니다.");
            AuthHelper.removeLoginInfo();
            document.location.href = '/login';
        } else {
            this.setState({ isInit: true });
        }
    }

    render = _ => (
        this.state.isInit ?
            <Switch>
                <Route exact path={`${this.props.match.path}`} component={ListContainer} />
                <Route exact path={`${this.props.match.path}/write`} component={FormContainer} />
                <Route exact path={`${this.props.match.path}/:articleId`} component={InfoContainer} />
                <Route exact path={`${this.props.match.path}/:articleId/modify`} component={FormContainer} />
            </Switch>
            : null
    )
}

export default InquiryContainer;
