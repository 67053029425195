import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CKEditor from "ckeditor4-react"

CKEditor.editorUrl = "/js/ckeditor/ckeditor.js"

/*
* 운영 환경에서 console 무력화
*/
if (process.env.REACT_APP_ENV === "PROD") {
    console.log = () => { }
    console.info = () => { }
    console.warn = () => { }
    console.error = () => { }
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

