import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import listContainer from './list/List.container'
import tourDetailContainer from './tour/Detail.container'
import ceremonialDetailContainer from './ceremonial/Detail.container'
import quizDetailContainer from './quiz/Detail.container'
import youthCampContainer from './youth-camp/Detail.container'
import tballFestaContainer from './tball-festa/Detail.container'
import successContainer from './success/Cont.component';
import returnContainer from './return-message/Cont.component';

class EventContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}/list`} component={listContainer} />
            <Route exact path={`${this.props.match.path}/quiz/:eventIdx`} component={quizDetailContainer} />
            <Route exact path={`${this.props.match.path}/kids/ceremonial`} component={ceremonialDetailContainer} />
            <Route exact path={`${this.props.match.path}/kids/tour/:eventIdx`} component={tourDetailContainer} />
            <Route exact path={`${this.props.match.path}/kids/tball-festa`} component={tballFestaContainer} />
            <Route exact path={`${this.props.match.path}/youth/baseball-camp`} component={youthCampContainer} />
            <Route exact path={`${this.props.match.path}/success`} component={successContainer} />
            <Route exact path={`${this.props.match.path}/return`} component={returnContainer} />
        </Switch>
    )
}

export default EventContainer;
