import React from "react";
import { Link } from "react-router-dom";
import SubMenuComponent from "./SubMenu.component";

import * as ImageHelper from "../../../helpers/Image.helper";
import ImgComponent from "../../common/Img.component";

const SearchPlayerListContComponent = (props) => (
  <div className="mb_cont sub_cont player">
    <div className="mb_contIn">
      <div className="list" style={{ marginTop: "80px" }}>
        <div
          className="srchArea"
          style={{
            borderBottom: "0px",
          }}
        >
          <input
            placeholder="선수명을 입력해주세요."
            onChange={(evt) => {
              props.onChangeSearchPlayerKeyWord(evt);
            }}
            onKeyPress={(evt) => props.onEnterKey(evt)}
          ></input>
          <button
            type="button"
            onClick={() => {
              props.onClickBtnSearchPlayer();
            }}
          >
            검색
          </button>
        </div>
        {props.searchPitcherList.length !== 0 && (
          <ul className="clear">
            <h3 className="lineRed">투수</h3>
            {props.searchPitcherList.map((item, idx) => (
              <li key={idx}>
                <Link
                  to={`/players/${
                    item.armyYn === "Y" ? "military-enlisted" : "pitcher"
                  }/${item.pcode}`}
                >
                  <ImgComponent
                    imgUrl={item.playerImg}
                    defaultImgUrl={ImageHelper.getDefaultPlayerListOffImg()}
                    imgName={item.playerName}
                  />
                  <div className="txt">
                    <ul>
                      <li className="num">NO.{item.backnum}</li>
                      <li className="name">{item.playerName}</li>
                    </ul>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
        {props.searchBatterList.length !== 0 && (
          <ul className="clear">
            <h3 className="lineRed">타자</h3>
            {props.searchBatterList.map((item, idx) => (
              <li key={idx}>
                <Link
                  to={`/players/${
                    item.armyYn === "Y" ? "military-enlisted" : item.position
                  }/${item.pcode}`}
                >
                  <ImgComponent
                    imgUrl={item.playerImg}
                    defaultImgUrl={ImageHelper.getDefaultPlayerListOffImg()}
                    imgName={item.playerName}
                  />
                  <div className="txt">
                    <ul>
                      <li className="num">NO.{item.backnum}</li>
                      <li className="name">{item.playerName}</li>
                    </ul>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
);

export default SearchPlayerListContComponent;
