import React from "react";
import { Link } from "react-router-dom";

const SubMenuComponent = props => (
  <div className="tabWrap">
    <ul className="tab01 clear">
      <li className={props.thirdMenu === "bi" ? "on" : ""}>
        <Link to="/tigers/bi/intro">CI</Link>
      </li>
      <li className={props.thirdMenu === "emblem" ? "on" : ""}>
        <Link to="/tigers/bi/emblem">엠블럼</Link>
      </li>
      <li className={props.thirdMenu === "uniform" ? "on" : ""}>
        <Link to="/tigers/bi/uniform">유니폼</Link>
      </li>
      <li className={props.thirdMenu === "mascot" ? "on" : ""}>
        <Link to="/tigers/bi/mascot">마스코트</Link>
      </li>
    </ul>
  </div>
);

export default SubMenuComponent;
