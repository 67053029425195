export const checkId = str => {
  const idTest = /^[a-z0-9]{2,3}/i;

  if (!idTest.test(str)) {
    throw new Error(
      "아이디는 영소문자, 숫자를 포함해 6자 이상으로 작성해주세요."
    );
  }
};
export const checkPassword = password => {
  const num = password.search(/[0-9]/g);
  const eng = password.search(/[a-z]/gi);
  const spe = password.search(/[`~!@#$%^&*|\\\'\";:\/?]/gi);

  if (password.length < 8 || password.length > 30) {
    throw new Error("비밀번호는 8자리 ~ 30자리 이내로 입력해주세요.");
  } else if (password.search(/\s/) !== -1) {
    throw new Error("비밀번호는 공백없이 입력해주세요.");
  } else if (num < 0 || eng < 0 || spe < 0) {
    throw new Error("비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.");
  }
  return true;
};

export const checkEmail = email => {
  const emailTest = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!emailTest.test(email)) {
    throw new Error("이메일 주소를 정확히 입력해주세요.");
  }
  return true;
};
