import React from "react";

const SubTabComponent = props => (
  <ul className="tab02 w4 clear">
    <li className={props.tabNum === 1 ? "on" : ""}>
      <a onClick={evt => props.onClickBtnTab(evt, 1)}>1군</a>
    </li>
    <li className={props.tabNum === 2 ? "on" : ""}>
      <a onClick={evt => props.onClickBtnTab(evt, 2)}>퓨처스</a>
    </li>
    <li className={props.tabNum === 3 ? "on" : ""}>
      <a onClick={evt => props.onClickBtnTab(evt, 3)} className="mb_line2">
        육성/잔류/
        <br />
        재활군
      </a>
    </li>
    <li className={props.tabNum === 4 ? "on" : ""}>
      <a onClick={evt => props.onClickBtnTab(evt, 4)} className="mb_line2">
        등록/말소 <br />
        현황
      </a>
    </li>
  </ul>
);

export default SubTabComponent;
