import React, { Component } from 'react';
import SubHeadComponent from '../../common/SubHead.component';
import ContComponent from './Cont.component';
import queryString from 'query-string';

import * as ArticleService from '../../../../services/Article.service';
import * as ListHelper from '../../../../helpers/List.helper';
import * as DateHelper from '../../../../helpers/Date.helper';
import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from '../../../../context/AnalyticsContext';


class ListContainer extends Component {
    static contextType = AnalyticsContext;

    constructor(props) {
        super(props);

        const queryStringParams = queryString.parse(this.props.location.search);

        const tabNum = parseInt(queryStringParams['tabNum'] ? queryStringParams['tabNum'] : 1, 10);
        const currentPage = parseInt(queryStringParams['page'] ? queryStringParams['page'] : 1, 10);
        const selectedSearchFilter = queryStringParams['searchFilter'] ? queryStringParams['searchFilter'] : 100;
        const searchKeyword = queryStringParams['searchKeyword'] ? queryStringParams['searchKeyword'] : '';
        const selectedSort = queryStringParams['sort'] ? queryStringParams['sort'] : '';

        const sortSelectList = ListHelper.getSortSelectList();
        const searchFilterSelectList = ListHelper.getSearchFilterSelectList();

        this.state = {
            subMenu: 'tv',
            subMenuName: '타이거즈 TV',
            searchKeyword,
            selectedSearchFilter,
            selectedSort,

            sortSelectList,
            searchFilterSelectList,

            currentPage,
            totalPage: 0,
            paginationObj: null,
            perPage: 9,
            tabNum,
            list: []
        }
    }

    getCategorySeqFromTabNum = tabNum => {
        switch (tabNum) {
            case 2: return 15;
            case 3: return 16;
            default: return null;
        }
    }

    componentDidMount = async _ => {
        try {
            const currentAnalyticsVal = this.context.state;
            const prevUrl = currentAnalyticsVal.currentUrl;
            this.context.action(currentAnalyticsVal);
        
            currentAnalyticsVal.deps2 = "03";
            currentAnalyticsVal.deps3 = "06"; //
            currentAnalyticsVal.prevUrl = prevUrl;
            currentAnalyticsVal.queryString = window.location.href.split('?').length === 2 ? decodeURI(window.location.href.split('?')[1]) : "";
            currentAnalyticsVal.currentUrl = window.location.href.split('?')[0];
        
            await CommonService.insertAnalytics(this.context.state);

            const offset = ListHelper.makeOffset(this.state.currentPage, this.state.perPage);
            const categorySeq = this.getCategorySeqFromTabNum(this.state.tabNum);
            const result = await this.getList(offset, this.state.searchKeyword, this.state.selectedSearchFilter, categorySeq, this.state.selectedSort);

            let totalPage = 0;
            let paginationObj = null;

            for (let i = 0; i < result.list.length; i++) {
                if (i === 0) {
                    totalPage = result.list[0].totalPage
                    paginationObj = ListHelper.makePaginationObj(totalPage, this.state.currentPage, this.state.perPage, 5);
                }

                result.list[i]['regDate'] = DateHelper.convertTimestampToDate(result.list[i]['regDttm']);
                result.list[i]['regTime'] = DateHelper.convertTimestampToTime(result.list[i]['regDttm']);
            }

            this.setState({ list: result.list, totalPage, paginationObj });
        } catch (err) {
            //alert(err.message);
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.location !== prevProps.location) {
            const currentAnalyticsVal = this.context.state;
            const prevUrl = currentAnalyticsVal.currentUrl;
            this.context.action(currentAnalyticsVal);
        
            currentAnalyticsVal.deps2 = "03";
            currentAnalyticsVal.deps3 = "06";//
            currentAnalyticsVal.prevUrl = prevUrl;
            currentAnalyticsVal.queryString = window.location.href.split('?').length === 2 ? decodeURI(window.location.href.split('?')[1]) : "";
            currentAnalyticsVal.currentUrl = window.location.href.split('?')[0];
        
            await CommonService.insertAnalytics(this.context.state);

            const queryStringParams = queryString.parse(this.props.location.search);
            console.log('queryStringParams', queryStringParams)

            const page = parseInt(queryStringParams['page'] ? queryStringParams['page'] : 1, 10);
            const searchKeyword = queryStringParams['searchKeyword'] ? queryStringParams['searchKeyword'] : '';
            const searchFilter = queryStringParams['searchFilter'] ? queryStringParams['searchFilter'] : 100;
            const tabNum = parseInt(queryStringParams['tabNum'] ? queryStringParams['tabNum'] : 1);
            const selectedSort = queryStringParams['sort'] ? queryStringParams['sort'] : '';

            this.updateList(page, searchKeyword, searchFilter, tabNum, selectedSort);
        }
    }

    updateList = async (page, searchKeyword, searchFilter, tabNum, selectedSort) => {
        console.log('updateList', page, searchKeyword, searchFilter, tabNum, selectedSort)
        try {
            const offset = ListHelper.makeOffset(page, this.state.perPage);
            const categorySeq = this.getCategorySeqFromTabNum(tabNum);
            const result = await this.getList(offset, searchKeyword, searchFilter, categorySeq, selectedSort);

            let totalPage = 0;
            let paginationObj = null;

            for (let i = 0; i < result.list.length; i++) {
                if (i === 0) {
                    totalPage = result.list[0].totalPage
                    paginationObj = ListHelper.makePaginationObj(totalPage, page, this.state.perPage, 5);
                }

                result.list[i]['regDate'] = DateHelper.convertTimestampToDate(result.list[i]['regDttm']);
                result.list[i]['regTime'] = DateHelper.convertTimestampToTime(result.list[i]['regDttm']);
            }
            console.log(paginationObj);
            this.setState({ list: result.list, totalPage, paginationObj, currentPage: page, tabNum });
        } catch (err) {
            //alert(err.message);
        }
    }

    getList = async (offset, searchKeyword, searchFilter, categorySeq, selectedSort) => {
        let listParams = {
            'search.pos': offset,
            'search.max': this.state.perPage
        }
        if (searchKeyword) {
            listParams['search.searchWord'] = searchKeyword;
            listParams['search.searchCategory'] = searchFilter;
        }
        if (categorySeq) {
            listParams['article.boardCatSeq'] = categorySeq
        }

        if (selectedSort !== '') {
            listParams['search.sort'] = selectedSort;
        }

        console.log('listParams', listParams);

        return ArticleService.getTvArticleList(listParams);
    }

    onClickBtnTab = async (evt, tabNum) => {
        let url = `/contents/tv?page=1&tabNum=${tabNum}`;
        this.props.history.push(url);
        this.setState({ selectedSearchFilter: 100, searchKeyword: '', selectedSort: '' })
    }

    onChangeSelectList = (evt, key) => {
        this.setState({ [key]: evt.target.value });
    }
    onChangeInput = (evt) => {
        this.setState({ searchKeyword: evt.target.value });
    }

    onClickBtnSearch = (evt) => {
        console.log('onClickBtnSearch');
        let url = '/contents/tv?page=1';
        if (this.state.searchKeyword.trim() !== '') {
            url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
            url += `&searchFilter=${this.state.selectedSearchFilter}`;
        }

        if (this.state.tabNum) {
            url += `&tabNum=${this.state.tabNum}`;
        }

        if (this.state.selectedSort != '') {
            url += `&sort=${this.state.selectedSort}`;
        }

        this.props.history.push(url);
        
    }

    onClickBtnPageNum = (evt, pageNum) => {
        console.log('onClickBtnPageNum', pageNum, this.state);
        let url = `/contents/tv?page=${pageNum}`;
        if (this.state.searchKeyword.trim() !== '') {
            url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
            url += `&searchFilter=${this.state.selectedSearchFilter}`;
        }

        if (this.state.tabNum) {
            url += `&tabNum=${this.state.tabNum}`;
        }

        if (this.state.selectedSort != '') {
            url += `&sort=${this.state.selectedSort}`;
        }
        this.props.history.push(url);
    }

    onEnterKeyPress = (evt) =>{
        if(evt.key === 'Enter'){
          this.onClickBtnSearch();
        }
    }

    render = _ => (
        <div className='container'>
            <SubHeadComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName} />
            <ContComponent
                tabNum={this.state.tabNum}
                onClickBtnTab={this.onClickBtnTab}

                searchKeyword={this.state.searchKeyword}
                selectedSearchFilter={this.state.selectedSearchFilter}
                searchFilterSelectList={this.state.searchFilterSelectList}

                sortSelectList={this.state.sortSelectList}
                selectedSort={this.state.selectedSort}

                onChangeSelectList={this.onChangeSelectList}

                onClickBtnSearch={this.onClickBtnSearch}
                onChangeInput={this.onChangeInput}
                list={this.state.list}

                paginationObj={this.state.paginationObj}
                onClickBtnPageNum={this.onClickBtnPageNum}
                onEnterKeyPress={this.onEnterKeyPress}
            />
        </div>
    )
}

export default ListContainer;
