import React from "react";

import { Link } from "react-router-dom";

const SubMenuComponent = props => (
  <ul className="tab02 tab02_2 clear terms_tab">
    <li
      style={{ width: "25%" }}
      className={props.subMenu === "privacy" ? "on" : ""}
    >
      <Link to="/terms/privacy">개인정보처리방침</Link>
    </li>
    <li
      style={{ width: "25%" }}
      className={props.subMenu === "service" ? "on" : ""}
    >
      <Link to="/terms/service">서비스이용약관</Link>
    </li>
    <li
      style={{ width: "25%" }}
      className={props.subMenu === "operation" ? "on" : ""}
    >
      <Link to="/terms/operation">운영정책</Link>
    </li>
    <li
      style={{ width: "25%" }}
      className={props.subMenu === "email" ? "on" : ""}
    >
      <Link to="/terms/email">이메일주소무단수집거부</Link>
    </li>
  </ul>
);

export default SubMenuComponent;
