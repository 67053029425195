import React from "react";

const ContSetPwComponent = props => (
  <div className="mb_cont join fnd pw">
    <div className="mb_contIn">
      <div className="box">
        <div>
          <h4>비밀번호 재설정</h4>
          <p>새로운 비밀번호를 설정해주세요.</p>
        </div>
        <form action="/" method="post">
          <fieldset>
            <legend>비밀번호 재설정</legend>
            <div>
              <label htmlFor="userPw">새 비밀번호 입력</label>
              <input
                type="password"
                id="userPw"
                placeholder="8자리 이상(영문자, 숫자, 특수문자)"
                value={props.pwObj.password}
                onChange={evt => props.onChangeInputPw(evt, "password")}
                onKeyPress={evt => props.onEnterKey(evt)}
              />
            </div>
            <div>
              <label htmlFor="userPw2">새 비밀번호 확인</label>
              <input
                type="password"
                id="userPw2"
                placeholder="8자리 이상(영문자, 숫자, 특수문자)"
                value={props.pwObj.rePassword}
                onChange={evt => props.onChangeInputPw(evt, "rePassword")}
                onKeyPress={evt => props.onEnterKey(evt)}
              />
            </div>
          </fieldset>
        </form>
        <div
          className="comp_btn"
          onClick={props.onClickBtnUpdatePw}
          style={{ float: "none", margin: "0px auto" }}
        >
          확인
        </div>
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContSetPwComponent;
