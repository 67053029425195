import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import SubHeadAppViewComponent from "../../../common/SubHeadAppView.component";

import * as ResourceService from "../../../../services/Resource.service";

import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

import $ from "jquery";
import seatsData from './seatInfo';

import SeatSubHeadAppViewComponent from "../../../common/SeatSubHeadAppView.component";

class StadiumSeatGuideContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = "champions-field";
    const thirdMenu = "seat-guide";
    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "좌석안내도",
      tabNum: 4,
      imgUrlObj: null,

      imgObj: {
        isInit: false,
        naturalImgWidth: 0,
        naturalImgHeight: 0,
        ratio: 1,
        ticketMapHeight: 0,
      },

      result: "",
      webViewReady: false,
      webViewButton: false,

      isOpen: false, //모달
      selectedSeat: "", //선택좌석
      seats: seatsData  //좌석정보
    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "03";
    currentAnalyticsVal.deps4 = "03";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    $(window).resize(this.onResizeWindow);
    const stadiumResult = await ResourceService.getChampionsFieldGuideImg();

    const imgUrlObj = {
      stadium: stadiumResult.resource.value,
    };

    this.setState({ imgUrlObj });



    this.setState(function (state, props) {
      // window.flutter_inappwebview = {
      //   callHandler: function(handlerName, args) {
      //       return new Promise(function(resolve, reject) {
      //           resolve({result: "success", handlerName: handlerName, args: args});
      //       });
      //   }
      // }

      window.addEventListener(
        "flutterInAppWebViewPlatformReady",
        function (event) {
          console.log("flutterInAppWebViewPlatformReady");
          window.flutter_inappwebview
            .callHandler("WebViewReady")
            .then(function (result) {
              console.log("Handler", state);
              alert("result: " + JSON.stringify(result));
              console.log(result);
              state.result = result;
              state.webViewReady = true;
            });
        }
      );

      // let event = new CustomEvent('flutterInAppWebViewPlatformReady', {detail: {}})
      //   window.dispatchEvent(event);
    });
  };

  componentWillUnmount = (_) => {
    $(window).unbind("resize");
  };

  onClickBtnTicketReservation = (evt) => {
    console.log("!!");
    alert("!! " + this.state.webViewReady);
    if (this.state.webViewReady) {
      window.flutter_inappwebview
        .callHandler("openAppPage", {
          name: "reserveTicket",
          game_date: "2023-10-04",
          game_time: "15:00:00",
          result: this.state.result,
        })
        .then((result) => {
          alert("result: " + JSON.stringify(result));
          console.log(result);
        });
    }
  };


  onLoadImg = ({ target: img }) => {
    console.log("onLoadImg");
    console.log(img.naturalWidth);
    console.log(img.naturalHeight);

    let imgObj = this.state.imgObj;
    imgObj.naturalWidth = img.naturalWidth;
    imgObj.naturalHeight = img.naturalHeight;
    imgObj.ratio = imgObj.naturalWidth / imgObj.naturalHeight;
    this.setState({ imgObj }, (_) => $(window).trigger("resize"));
  };

  onResizeWindow = (evt) => {
    let imgObj = this.state.imgObj;
    const ticketMapWidth = $(".ticketMap").width();
    const ticketMapHeight = ticketMapWidth / this.state.imgObj.ratio;
    if (!imgObj.isInit) {
      imgObj.isInit = true;
    }
    imgObj.ticketMapHeight = ticketMapHeight;
    this.setState({ imgObj });
  };




  openModal = (event, seatId) => {
    //선택된 좌석정보
    const selectedSeat = this.state.seats.find((seat) => seat.key === seatId);
    
    // 모달 열기, 선택된 좌석 정보
    this.setState({
      isOpen: true,
      selectedSeat: {
        ...selectedSeat, 
      }
    });
  };

  openModalMobile = () => {
    console.log("좌석열기");
    alert("좌석열기!! " + this.state.webViewButton);
    if (this.state.webViewButton) {
      window.flutter_inappwebview
        .callHandler("open", {
          result: this.state.result,
        })
        .then((result) => {
          alert("result: " + JSON.stringify(result));
          console.log(result);
        });
    }
  }
  

  closeModal = () => {
    this.setState({ isOpen: false });
  };
  
  closeModalMobile = () => {
    console.log("좌석닫기");
    alert("좌석닫기!! " + this.state.webViewButton);
    if (this.state.webViewButton) {
      window.flutter_inappwebview
        .callHandler("close", {
          result: this.state.result,
        })
        .then((result) => {
          alert("result: " + JSON.stringify(result));
          console.log(result);
        });
    }
  }


  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      {/* <SubHeadAppViewComponent /> */}
      <SeatSubHeadAppViewComponent />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        imgObj={this.state.imgObj}
        imgUrlObj={this.state.imgUrlObj}

        onClickBtnTicketReservation={this.onClickBtnTicketReservation}

        // 좌석뷰모달 
        isOpen={this.state.isOpen}
        closeModal={this.closeModal}
        openModal={this.openModal}
        seats={this.state.seats}
        selectedSeat={this.state.selectedSeat}
        openModalMobile={this.openModalMobile}
        closeModalMobile={this.closeModalMobile}
        />

        {this.state.imgUrlObj ? (
          <div style={{ display: "none" }}>
            <img src={this.state.imgUrlObj.stadium} onLoad={this.onLoadImg} />
          </div>
        ) : null}
  
     
    </div>
  );
}

export default StadiumSeatGuideContainer;
