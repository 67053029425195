import React from "react";
import { Link } from "react-router-dom";

import * as ImageHelper from "../../../../helpers/Image.helper";
import ImgComponent from "../../../common/Img.component";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont game msrView">
    <div className="mb_contIn">
      <div class="tabWrap">{/* <!-- tab 없음 --> */}</div>
      {/* <!-- //tabWrap --> */}
      <div className="control">
        <div className="controlIn">
          {/* 제거 예정 */}

          {/* <select className="selYear"
            name=""
            id=""
            value={props.selectedYear}
            onChange={evt => props.onChangeSelectList(evt, "selectedYear")}
          >
            {props.selectYearList.map((item, idx) => (
              <option key={idx} value={item.value}>{item.text}</option>
            ))}
          </select>
          <select className="selMonth"
            name=""
            id=""
            value={props.selectedMonth}
            onChange={evt => {
              props.onChangeSelectList(evt, "selectedMonth");
            }}
          >
            {props.selectMonthList.map((item, idx) => (
              <option key={idx} value={item.value}>{item.text}</option>
            ))}
          </select>
          <select className="selDay"
            name=""
            id=""
            value={props.selectedDay}
            onChange={evt => props.onChangeSelectList(evt, "selectedDay")}
          >
            {props.selectDayList.map((item, idx) => (
              <option key={idx} value={item.value}>{item.text}</option>
            ))}
          </select>
          <button className="calBtn"
            onClick={props.onClickBtnShowInfo}
            type="button">
            이동
          </button> */}

          <div>
            <a onClick={props.onClickBtnPrevGame} className="prev fll">
              <img src="/img/sub/btn_arr.png" alt="이전" />
            </a>
            <a onClick={props.onClickBtnNextGame} className="next flr">
              <img src="/img/sub/btn_arr.png" alt="다음" />
            </a>
          </div>
        </div>
      </div>
      <section className="section01">
        <CurrentGameInfoComponent {...props} />
        <ScoreBoardComponent {...props} />
      </section>
      {props.awayHitterRecordList.length === 0 ? (
        <div className="collect_result">
          <h1>경기결과 집계 중입니다</h1>
        </div>
      ) : (
        <GameRecordComponent {...props} />
      )}
      <div
        onClick={props.onClickBtnBack}
        className="comp_btn game_detail_btn"
        style={{ margin: "10px auto" }}
      >
        일정보기
      </div>
    </div>
  </div>
);

const CurrentGameInfoComponent = (props) =>
  props.currentGameInfo ? (
    <div className="gameSchedule">
      <div className="inner">
        <p className="date">
          {props.currentGameInfo.formattedGameDate} (
          {props.currentGameInfo.gweek})
        </p>
        <div className="clear">
          <div className="teamOn teamL">
            <figure>
              <p className="emble">
                <img
                  src={props.awayTeamInfo["emblemImgUrl"]}
                  alt={props.awayTeamInfo["name"]}
                />
              </p>
              <figcaption>{props.awayTeamInfo["name"]}</figcaption>
            </figure>
            {props.awayTeamInfo["pitcherInfo"] ? (
              <p className="info">
                <span
                  className={
                    (props.awayTeamInfo["pitcherInfo"]["wls"] === "W"
                      ? "win"
                      : "lose") + " WoL"
                  }
                ></span>
                {props.awayTeamInfo["pitcherInfo"]["name"]}
              </p>
            ) : props.currentGameInfo.hscore ===
              props.currentGameInfo.vscore ? (
              ""
            ) : (
              <p className="info"></p>
            )}
            {/* {props.currentGameInfo.vname ? (
              <p className="info">
                <span
                  className={
                    (props.currentGameInfo.hscore < props.currentGameInfo.vscore
                      ? "win"
                      : "lose") + " WoL"
                  }
                ></span>
                {props.currentGameInfo.vname}
              </p>
            ) : (
                <p className="info">선발 발표 전</p>
              )} */}
          </div>
          <div className="gameOn clear">
            <strong
              className={
                "score teamL" +
                (props.currentGameInfo.visitKey !== "HT" ? " op6" : "")
              }
            >
              {props.scoreBoard.map((item, idx) =>
                item.bhome === 0 ? item.run : ""
              )}
            </strong>
            <p>
              <span>{props.currentGameInfo.stadium}</span>
              <span>{props.currentGameInfo.gtime}</span>
            </p>
            <strong
              className={
                "score teamR" +
                (props.currentGameInfo.homeKey !== "HT" ? " op6" : "")
              }
            >
              {props.scoreBoard.map((item, idx) =>
                item.bhome === 1 ? item.run : ""
              )}
            </strong>
            <span className="bdRndBox now">종료</span>
          </div>
          <div className="teamOn teamR">
            <figure>
              <p className="emble">
                <img
                  src={props.homeTeamInfo["emblemImgUrl"]}
                  alt={props.homeTeamInfo["name"]}
                />
              </p>
              <figcaption>{props.homeTeamInfo["name"]}</figcaption>
            </figure>
            {props.homeTeamInfo["pitcherInfo"] ? (
              <p className="info">
                <span
                  className={
                    (props.homeTeamInfo["pitcherInfo"]["wls"] === "W"
                      ? "win"
                      : "lose") + " WoL"
                  }
                ></span>
                {props.homeTeamInfo["pitcherInfo"]["name"]}
              </p>
            ) : props.currentGameInfo.hscore ===
              props.currentGameInfo.vscore ? (
              ""
            ) : (
              <p className="info"></p>
            )}
          </div>
          {/* 이름이 없을경우 선발 발표 전 */}
          {/* {props.currentGameInfo.hname ? (
              <p className="info">
                <span
                  className={
                    (props.currentGameInfo.hscore > props.currentGameInfo.vscore
                      ? "win"
                      : "lose") + " WoL"
                  }
                ></span>
                {props.currentGameInfo.hname}
              </p>
            ) : (
                <p className="info">선발 발표 전</p>
              )} */}
        </div>
      </div>
    </div>
  ) : null;

const ScoreBoardComponent = (props) =>
  props.scoreBoard ? (
    <div className="gameScoreBox">
      <div className="inner">
        <table className="table_score">
          <caption>경기 진행현황 표</caption>
          <colgroup>
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <col width="5%" />
                  <col width="5%" />
                  <col width="5%" />
                </>
              )}
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">1</th>
              <th scope="col">2</th>
              <th scope="col">3</th>
              <th scope="col">4</th>
              <th scope="col">5</th>
              <th scope="col">6</th>
              <th scope="col">7</th>
              <th scope="col">8</th>
              <th scope="col">9</th>
              <th scope="col">10</th>
              <th scope="col">11</th>
              <th scope="col">12</th>
              {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <th scope="col">13</th>
                  <th scope="col">14</th>
                  <th scope="col">15</th>
                </>
              )}
              <th scope="col">R</th>
              <th scope="col">H</th>
              <th scope="col">E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{props.scoreBoard[0].bhomeName}</th>
              <td>{props.scoreBoard[0]["score1"]}</td>
              <td>{props.scoreBoard[0]["score2"]}</td>
              <td>{props.scoreBoard[0]["score3"]}</td>
              <td>{props.scoreBoard[0]["score4"]}</td>
              <td>{props.scoreBoard[0]["score5"]}</td>
              <td>{props.scoreBoard[0]["score6"]}</td>
              <td>{props.scoreBoard[0]["score7"]}</td>
              <td>{props.scoreBoard[0]["score8"]}</td>
              <td>{props.scoreBoard[0]["score9"]}</td>
              <td>{(props.scoreBoard[0]["score10"] === "" || props.scoreBoard[0]["score10"] === undefined) ? "-" : props.scoreBoard[0]["score10"]}</td>
              <td>{(props.scoreBoard[0]["score11"] === "" || props.scoreBoard[0]["score11"] === undefined) ? "-" : props.scoreBoard[0]["score11"]}</td>
              <td>{(props.scoreBoard[0]["score12"] === "" || props.scoreBoard[0]["score12"] === undefined) ? "-" : props.scoreBoard[0]["score12"]}</td>
              {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <td>{props.scoreBoard[0]["score13"] === "" ? "-" : props.scoreBoard[0]["score13"]}</td>
                  <td>{props.scoreBoard[0]["score14"] === "" ? "-" : props.scoreBoard[0]["score14"]}</td>
                  <td>{props.scoreBoard[0]["score15"] === "" ? "-" : props.scoreBoard[0]["score15"]}</td>
                </>
              )}
              <td>{props.scoreBoard[0]["run"]}</td>
              <td>{props.scoreBoard[0]["hit"]}</td>
              <td>{props.scoreBoard[0]["error"]}</td>
            </tr>
            <tr>
              <th scope="row">{props.scoreBoard[1].bhomeName}</th>
              <td>{props.scoreBoard[1]["score1"]}</td>
              <td>{props.scoreBoard[1]["score2"]}</td>
              <td>{props.scoreBoard[1]["score3"]}</td>
              <td>{props.scoreBoard[1]["score4"]}</td>
              <td>{props.scoreBoard[1]["score5"]}</td>
              <td>{props.scoreBoard[1]["score6"]}</td>
              <td>{props.scoreBoard[1]["score7"]}</td>
              <td>{props.scoreBoard[1]["score8"]}</td>
              <td>{props.scoreBoard[1]["score9"]}</td>
              <td>{(props.scoreBoard[1]["score10"] === "" || props.scoreBoard[1]["score10"] === undefined) ? "-" : props.scoreBoard[1]["score10"]}</td>
              <td>{(props.scoreBoard[1]["score11"] === "" || props.scoreBoard[1]["score11"] === undefined) ? "-" : props.scoreBoard[1]["score11"]}</td>
              <td>{(props.scoreBoard[1]["score12"] === "" || props.scoreBoard[1]["score12"] === undefined) ? "-" : props.scoreBoard[1]["score12"]}</td>
              {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <td>{props.scoreBoard[1]["score13"] === "" ? "-" : props.scoreBoard[1]["score13"]}</td>
                  <td>{props.scoreBoard[1]["score14"] === "" ? "-" : props.scoreBoard[1]["score14"]}</td>
                  <td>{props.scoreBoard[1]["score15"] === "" ? "-" : props.scoreBoard[1]["score15"]}</td>
                </>
              )}
              <td>{props.scoreBoard[1]["run"]}</td>
              <td>{props.scoreBoard[1]["hit"]}</td>
              <td>{props.scoreBoard[1]["error"]}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null;

const OtherTeamInfoComponent = (props) =>
  props.otherTeamInfoList ? (
    <article className="w50p mb100p otherGames">
      <h3 className="arcTit">타 구단 경기결과</h3>
      <table className="bdtRed trBdb">
        <caption>타 구단 경기결과 표</caption>
        <colgroup>
          <col width="33.333%" />
          <col width="11.111%" />
          <col width="11.111%" />
          <col width="11.111%" />
          <col width="33.333%" />
        </colgroup>
        <tbody>
          {props.otherTeamInfoList.map((item, idx) => (
            <tr key={idx}>
              <td scope="col" className="tar">
                <span className="mgr15">{item.home}</span>
                <span className="emble">
                  <img src={item.homeEmblemImgUrl} alt={item.home} />
                </span>
              </td>
              <td>{item.hscore}</td>
              <td className="vs">VS</td>
              <td>{item.vscore}</td>
              <td className="tal">
                <span className="emble mgr15">
                  <img src={item.awayEmblemImgUrl} alt="두산 엠블럼" />
                </span>
                <span>{item.visit}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </article>
  ) : null;

const EntryPitcherInfoComponent = (props) =>
  props.awayEntryPitcherInfo && props.homeEntryPitcherInfo ? (
    <article className="w50p mb100p startingPitcher">
      <h3 className="arcTit">선발투수 안내</h3>
      <table className="bdtRed">
        <caption>선발투수 안내 표</caption>
        <colgroup>
          <col width="33.333%" />
          <col width="33.333%" />
          <col width="33.333%" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col" className="tar">
              <span className="emble mgr15">
                <img
                  src={props.homeEmblemImgUrl}
                  alt={props.homeTeamName + " 엠블럼"}
                />
              </span>
              <span>{props.homeEntryPitcherInfo.pitcherName}</span>
            </th>
            <th scope="col" className="vs">
              vs
            </th>
            <th scope="col" className="tal">
              <span className="mgr15">
                {props.awayEntryPitcherInfo.pitcherName}
              </span>
              <span className="emble">
                <img
                  src={props.awayEmblemImgUrl}
                  alt={props.awayTeamName + " 엠블럼"}
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className="tar">
              {props.homeEntryPitcherInfo.gamenum}G&nbsp;
              {props.homeEntryPitcherInfo.wtotal}승&nbsp;
              {props.homeEntryPitcherInfo.ltotal}패&nbsp;
              {props.homeEntryPitcherInfo.svtotal}세&nbsp;
            </td>
            <td>성적</td>
            <td className="tal">
              {props.awayEntryPitcherInfo.gamenum}G&nbsp;
              {props.awayEntryPitcherInfo.wtotal}승&nbsp;
              {props.awayEntryPitcherInfo.ltotal}패&nbsp;
              {props.awayEntryPitcherInfo.svtotal}세&nbsp;
            </td>
          </tr>
          <tr>
            <td scope="row" className="tar">
              {props.homeEntryPitcherInfo.eratotal}
            </td>
            <td>방어율</td>
            <td className="tal">{props.awayEntryPitcherInfo.eratotal}</td>
          </tr>
          <tr>
            <td scope="row" className="tar">
              {props.homeEntryPitcherInfo.kk}
            </td>
            <td>탈삼진</td>
            <td className="tal">{props.awayEntryPitcherInfo.kk}</td>
          </tr>
          <tr>
            <td scope="row" className="tar">
              {props.homeEntryPitcherInfo.w}승&nbsp;
              {props.homeEntryPitcherInfo.l}패&nbsp;
              {props.homeEntryPitcherInfo.sv}세&nbsp;
            </td>
            <td>상대팀 성적</td>
            <td className="tal">
              {props.awayEntryPitcherInfo.w}승&nbsp;
              {props.awayEntryPitcherInfo.l}패&nbsp;
              {props.awayEntryPitcherInfo.sv}세&nbsp;
            </td>
          </tr>
          <tr>
            <td scope="row" className="tar">
              {props.homeEntryPitcherInfo.era}
            </td>
            <td>상대팀 방어율</td>
            <td className="tal">{props.awayEntryPitcherInfo.era}</td>
          </tr>
        </tbody>
      </table>
    </article>
  ) : null;

const ExpiredEntryStatusComponent = (props) =>
  Object.keys(props.expiredEntryStatusListObj).length > 0 ? (
    <section className="section03">
      <article className="w100p">
        <h3 className="arcTit">
          엔트리 변동사항
          <Link to="/players/entry-status?tabNum=4" className="link_more">
            더보기 +
          </Link>
        </h3>
        <div className="table_Typ01">
          <table>
            <caption>엔트리 변동사항 표</caption>
            <colgroup>
              <col width="33.333%" />
              <col width="33.333%" />
              <col width="33.333%" />
            </colgroup>
            <thead>
              {Object.keys(props.expiredEntryStatusListObj).map((key, idx) => (
                <tr key={idx}>
                  <td>{key}</td>
                  <td>{props.expiredEntryStatus.listObj[key]["regY"]}</td>
                  <td>{props.expiredEntryStatus.listObj[key]["regN"]}</td>
                </tr>
              ))}
            </thead>
            <tbody>
              <tr>
                <td>2019.09.24</td>
                <td>이인행</td>
                <td>최형우</td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </section>
  ) : null;

const GameRecordComponent = (props) => (
  <section className="section05">
    {/* 원정팀 타자 기록 */}
    <article className="w100p">
      <h3 className="arcTit">
        <span>{props.awayTeamInfo["name"]}</span> 타자 기록
        <span className="tbInfo">
          <img src="/img/sub/ico_baseball.png" alt="야구공" className="ico" />
          선발 라인업
        </span>
      </h3>

      <div className="mbTable w60p mb">
        <ul>
          <li>라인업</li>
          {props.awayHitterRecordList.map((item, idx) => (
            <li key={idx}>
              {!item.changeinn ? (
                <img
                  src="/img/sub/ico_baseball.png"
                  alt="선발 아이콘"
                  className="ico"
                />
              ) : null}
              {/* 2뮤  */}
              {item.name}
            </li>
          ))}
          {props.awayHitterRecordList && <li className="tFoot"></li>}
        </ul>
      </div>
      <div className="table_Typ01 mbScroll02">
        <table className="headDark">
          <caption>라인업 별 타자기록 표</caption>
          <colgroup>
            <col width="auto" />
            <col width="5%" />
            <col width="6%" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <col width="auto" />
                <col width="auto" />
                <col width="auto" />
              </>
            )}
            {/* <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            <col width={props.colWidth} />
            {props.scoreBoard[0]["score13"] !== "" && (
                <>
                  <col width={props.colWidth} />
                  <col width={props.colWidth} />
                  <col width={props.colWidth} />
                </>
            )} */}
            {/* {props.lastInning > 9 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 10 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 11 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 12 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 13 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 14 ? <col width={props.colWidth} /> : null} */}
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="5%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowSpan="2" colSpan="3" className="pc_tb">
                라인업
              </th>
              <th scope="col" rowSpan="2">
                1회
              </th>
              <th scope="col" rowSpan="2">
                2회
              </th>
              <th scope="col" rowSpan="2">
                3회
              </th>
              <th scope="col" rowSpan="2">
                4회
              </th>
              <th scope="col" rowSpan="2">
                5회
              </th>
              <th scope="col" rowSpan="2">
                6회
              </th>
              <th scope="col" rowSpan="2">
                7회
              </th>
              <th scope="col" rowSpan="2">
                8회
              </th>
              <th scope="col" rowSpan="2">
                9회
              </th>
              <th scope="col" rowSpan="2">
                10회
              </th>
              <th scope="col" rowSpan="2">
                11회
              </th>
              <th scope="col" rowSpan="2">
                12회
              </th>
              {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <th scope="col" rowSpan="2">
                    13회
                  </th>
                  <th scope="col" rowSpan="2">
                    14회
                  </th>
                  <th scope="col" rowSpan="2">
                    15회
                  </th>
                </>
              )}
              {/* {props.lastInning > 9 ? (
                <th scope="col" rowSpan="2">
                  10회
                </th>
              ) : null}
              {props.lastInning > 10 ? (
                <th scope="col" rowSpan="2">
                  11회
                </th>
              ) : null}
              {props.lastInning > 11 ? (
                <th scope="col" rowSpan="2">
                  12회
                </th>
              ) : null}
              {props.lastInning > 12 ? (
                <th scope="col" rowSpan="2">
                  13회
                </th>
              ) : null}
              {props.lastInning > 13 ? (
                <th scope="col" rowSpan="2">
                  14회
                </th>
              ) : null}
              {props.lastInning > 14 ? (
                <th scope="col" rowSpan="2">
                  15회
                </th>
              ) : null} */}
              <th scope="col" rowSpan="2">
                타수
              </th>
              <th scope="col" rowSpan="2">
                안타
              </th>
              <th scope="col" rowSpan="2">
                타점
              </th>
              <th scope="col" rowSpan="2">
                득점
              </th>
              <th scope="col" colSpan="2">
                타율
              </th>
            </tr>
            <tr className="stay">
              <th scope="col">시즌</th>
              <th scope="col">5게임</th>
            </tr>
          </thead>
          <tbody>
            {props.awayHitterRecordList.map((item, idx) => (
              <tr key={idx}>
                <th scope="row" className="bdrn pc_tb">
                  {!item.changeinn ? (
                    <img src="/img/sub/ico_baseball.png" alt="선발 아이콘" />
                  ) : null}
                </th>
                <th className="bdrn pc_tb">{item.position}</th>
                <th className="tal pc_tb">{item.name}</th>
                <td>{item.inn1}</td>
                <td>{item.inn2}</td>
                <td>{item.inn3}</td>
                <td>{item.inn4}</td>
                <td>{item.inn5}</td>
                <td>{item.inn6}</td>
                <td>{item.inn7}</td>
                <td>{item.inn8}</td>
                <td>{item.inn9}</td>
                <td>{item.inn10}</td>
                <td>{item.inn11}</td>
                <td>{item.inn12}</td>
                {props.scoreBoard[0]["score13"] !== undefined && (
                  <>
                    <td>{item.inn13}</td>
                    <td>{item.inn14}</td>
                    <td>{item.inn15}</td>
                  </>
                )}
                {/* {props.lastInning > 9 ? <td>{item.inn10}</td> : null}
                {props.lastInning > 10 ? <td>{item.inn11}</td> : null}
                {props.lastInning > 11 ? <td>{item.inn12}</td> : null}
                {props.lastInning > 12 ? <td>{item.inn13}</td> : null}
                {props.lastInning > 13 ? <td>{item.inn14}</td> : null}
                {props.lastInning > 14 ? <td>{item.inn15}</td> : null} */}
                <td>{item.ab}</td>
                <td>{item.hit}</td>
                <td>{item.rbi}</td>
                <td>{item.run}</td>
                <td>{item.sHitRate}</td>
                <td>{item.hra}</td>
              </tr>
            ))}
            {props.awayHitterTotalRecord ? (
              <tr className="tfoot">
                <td
                  colSpan={props.scoreBoard[0]["score13"] !== undefined ? props.hitterTotalRecordColSpan + 3 : props.hitterTotalRecordColSpan}
                  // colSpan={props.hitterTotalRecordColSpan}
                  className="colorRed chgColto15"
                >
                  합계
                </td>
                <td>{props.awayHitterTotalRecord.ab}</td>
                <td>{props.awayHitterTotalRecord.hit}</td>
                <td>{props.awayHitterTotalRecord.rbi}</td>
                <td>{props.awayHitterTotalRecord.run}</td>
                <td>-</td>
                <td>-</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </article>
    {/* 홈팀 타자 기록 */}
    <article className="w100p">
      <h3 className="arcTit">
        <span>{props.homeTeamInfo["name"]}</span> 타자 기록
        <span className="tbInfo">
          <img src="/img/sub/ico_baseball.png" alt="야구공" className="ico" />
          선발 라인업
        </span>
      </h3>

      <div className="mbTable w60p mb">
        <ul>
          <li>라인업</li>
          {props.homeHitterRecordList.map((item, idx) => (
            <li key={idx}>
              {!item.changeinn ? (
                <img
                  src="/img/sub/ico_baseball.png"
                  alt="선발 아이콘"
                  className="ico"
                />
              ) : null}
              {/* 2뮤  */}
              {item.name}
            </li>
          ))}
          {props.awayHitterRecordList && <li className="tFoot"></li>}
        </ul>
      </div>
      <div className="table_Typ01 mbScroll02">
        <table className="headDark">
          <caption>라인업 별 타자기록 표</caption>
          <colgroup>
            <col width="auto" />
            <col width="5%" />
            <col width="6%" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <col width="auto" />
                <col width="auto" />
                <col width="auto" />
              </>
            )}
            {/* {props.lastInning > 9 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 10 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 11 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 12 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 13 ? <col width={props.colWidth} /> : null}
            {props.lastInning > 14 ? <col width={props.colWidth} /> : null} */}
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="5%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowSpan="2" colSpan="3" className="pc_tb">
                라인업
              </th>
              <th scope="col" rowSpan="2">
                1회
              </th>
              <th scope="col" rowSpan="2">
                2회
              </th>
              <th scope="col" rowSpan="2">
                3회
              </th>
              <th scope="col" rowSpan="2">
                4회
              </th>
              <th scope="col" rowSpan="2">
                5회
              </th>
              <th scope="col" rowSpan="2">
                6회
              </th>
              <th scope="col" rowSpan="2">
                7회
              </th>
              <th scope="col" rowSpan="2">
                8회
              </th>
              <th scope="col" rowSpan="2">
                9회
              </th>
              <th scope="col" rowSpan="2">
                10회
              </th>
              <th scope="col" rowSpan="2">
                11회
              </th>
              <th scope="col" rowSpan="2">
                12회
              </th>
              {props.scoreBoard[0]["score13"] !== undefined && (
                <>
                  <th scope="col" rowSpan="2">
                    13회
                  </th>
                  <th scope="col" rowSpan="2">
                    14회
                  </th>
                  <th scope="col" rowSpan="2">
                    15회
                  </th>
                </>
              )}
              {/* {props.lastInning > 9 ? (
                <th scope="col" rowSpan="2">
                  10회
                </th>
              ) : null}
              {props.lastInning > 10 ? (
                <th scope="col" rowSpan="2">
                  11회
                </th>
              ) : null}
              {props.lastInning > 11 ? (
                <th scope="col" rowSpan="2">
                  12회
                </th>
              ) : null}
              {props.lastInning > 12 ? (
                <th scope="col" rowSpan="2">
                  13회
                </th>
              ) : null}
              {props.lastInning > 13 ? (
                <th scope="col" rowSpan="2">
                  14회
                </th>
              ) : null}
              {props.lastInning > 14 ? (
                <th scope="col" rowSpan="2">
                  15회
                </th>
              ) : null} */}
              <th scope="col" rowSpan="2">
                타수
              </th>
              <th scope="col" rowSpan="2">
                안타
              </th>
              <th scope="col" rowSpan="2">
                타점
              </th>
              <th scope="col" rowSpan="2">
                득점
              </th>
              <th scope="col" colSpan="2">
                타율
              </th>
            </tr>
            <tr className="stay">
              <th scope="col">시즌</th>
              <th scope="col">5게임</th>
            </tr>
          </thead>
          <tbody>
            {props.homeHitterRecordList.map((item, idx) => (
              <tr key={idx}>
                <th scope="row" className="bdrn pc_tb">
                  {!item.changeinn ? (
                    <img src="/img/sub/ico_baseball.png" alt="선발 아이콘" />
                  ) : null}
                  {/* <img src="/img/sub/ico_baseball.png" alt="선발 아이콘" /> */}
                </th>
                <th className="bdrn pc_tb">{item.position}</th>
                <th className="tal pc_tb">{item.name}</th>
                <td>{item.inn1}</td>
                <td>{item.inn2}</td>
                <td>{item.inn3}</td>
                <td>{item.inn4}</td>
                <td>{item.inn5}</td>
                <td>{item.inn6}</td>
                <td>{item.inn7}</td>
                <td>{item.inn8}</td>
                <td>{item.inn9}</td>
                <td>{item.inn10}</td>
                <td>{item.inn11}</td>
                <td>{item.inn12}</td>
                {props.scoreBoard[0]["score13"] !== undefined && (
                  <>
                    <td>{item.inn13}</td>
                    <td>{item.inn14}</td>
                    <td>{item.inn15}</td>
                  </>
                )}
                {/* {props.lastInning > 9 ? <td>{item.inn10}</td> : null}
                {props.lastInning > 10 ? <td>{item.inn11}</td> : null}
                {props.lastInning > 11 ? <td>{item.inn12}</td> : null}
                {props.lastInning > 12 ? <td>{item.inn13}</td> : null}
                {props.lastInning > 13 ? <td>{item.inn14}</td> : null}
                {props.lastInning > 14 ? <td>{item.inn15}</td> : null} */}
                <td>{item.ab}</td>
                <td>{item.hit}</td>
                <td>{item.rbi}</td>
                <td>{item.run}</td>
                <td>{item.sHitRate}</td>
                <td>{item.hra}</td>
              </tr>
            ))}
            {props.homeHitterTotalRecord ? (
              <tr className="tfoot">
                <td
                  colSpan={props.scoreBoard[0]["score13"] !== undefined ? props.hitterTotalRecordColSpan + 3 : props.hitterTotalRecordColSpan}
                  // colSpan={props.hitterTotalRecordColSpan}
                  className="colorRed chgColto15"
                >
                  합계
                </td>
                <td>{props.homeHitterTotalRecord.ab}</td>
                <td>{props.homeHitterTotalRecord.hit}</td>
                <td>{props.homeHitterTotalRecord.rbi}</td>
                <td>{props.homeHitterTotalRecord.run}</td>
                <td>-</td>
                <td>-</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </article>
    {/* <!-- 타자기록 스크립트 -->
    <script>
            chgColto15();
        $(window).resize(function(){
            var ww = $(window).width();
            if (ww < 781)
            {
                $('.chgColto15').attr('colSpan', '15');
            }else{
                $('.chgColto15').attr('colSpan', '18');
}
});
        function chgColto15(){
            var ww = $(window).width();
            if (ww < 781)
            {
                $('.chgColto15').attr('colSpan', '15');
            }else{
                $('.chgColto15').attr('colSpan', '18');
}
};
    </script>
        <!-- 타자기록 스크립트 --> */}
    {/* 원정팀 투수 기록 */}
    <article className="w100p">
      <h3 className="arcTit">
        <span>{props.awayTeamInfo["name"]}</span> 투수 기록
      </h3>
      <div className="table_Typ01 mbScroll mbThNofix">
        <table>
          <caption>라인업 별 투수기록 표</caption>
          <colgroup>
            <col width="12%" />
            <col width="8%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                라인업
              </th>
              <th scope="col">경기</th>
              <th scope="col">승</th>
              <th scope="col">패</th>
              <th scope="col">세</th>
              <th scope="col">이닝</th>
              <th scope="col">타자</th>
              <th scope="col">투구</th>
              <th scope="col">타수</th>
              <th scope="col">안타</th>
              <th scope="col">홈런</th>
              <th scope="col">4사</th>
              <th scope="col">삼진</th>
              <th scope="col">실점</th>
              <th scope="col">자책</th>
              <th scope="col">ERA</th>
            </tr>
          </thead>
          <tbody>
            {props.awayPitcherRecordList.map((item, idx) => (
              <tr key={idx}>
                <th scope="row" className="bdrn">
                  {item.name}
                </th>
                <th className="tal">{item.resultStr}</th>
                <td>{item.game}</td>
                <td>{item.w}</td>
                <td>{item.l}</td>
                <td>{item.s}</td>
                <td>{item.inn}</td>
                <td>{item.pa}</td>
                <td>{item.bf}</td>
                <td>{item.ab}</td>
                <td>{item.hit}</td>
                <td>{item.hr}</td>
                <td>{item.bbhp}</td>
                <td>{item.kk}</td>
                <td>{item.r}</td>
                <td>{item.er}</td>
                <td>
                  {item.accmInn2 !== 0
                    ? ((item.accmEr * 9) / (item.accmInn2 / 3)).toFixed(2)
                    : (0.0).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
    {/* 홈 팀 투수 기록 */}
    <article className="w100p">
      <h3 className="arcTit">
        <span>{props.homeTeamInfo["name"]}</span> 투수 기록
      </h3>
      <div className="table_Typ01 mbScroll mbThNofix">
        <table>
          <caption>라인업 별 투수기록 표</caption>
          <colgroup>
            <col width="12%" />
            <col width="8%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                라인업
              </th>
              <th scope="col">경기</th>
              <th scope="col">승</th>
              <th scope="col">패</th>
              <th scope="col">세</th>
              <th scope="col">이닝</th>
              <th scope="col">타자</th>
              <th scope="col">투구</th>
              <th scope="col">타수</th>
              <th scope="col">안타</th>
              <th scope="col">홈런</th>
              <th scope="col">4사</th>
              <th scope="col">삼진</th>
              <th scope="col">실점</th>
              <th scope="col">자책</th>
              <th scope="col">ERA</th>
            </tr>
          </thead>
          <tbody>
            {props.homePitcherRecordList.map((item, idx) => (
              <tr key={idx}>
                <th scope="row" className="bdrn">
                  {item.name}
                </th>
                <th className="tal">{item.resultStr}</th>
                <td>{item.game}</td>
                <td>{item.w}</td>
                <td>{item.l}</td>
                <td>{item.s}</td>
                <td>{item.inn}</td>
                <td>{item.pa}</td>
                <td>{item.bf}</td>
                <td>{item.ab}</td>
                <td>{item.hit}</td>
                <td>{item.hr}</td>
                <td>{item.bbhp}</td>
                <td>{item.kk}</td>
                <td>{item.r}</td>
                <td>{item.er}</td>
                <td>
                  {item.accmInn2 !== 0
                    ? ((item.accmEr * 9) / (item.accmInn2 / 3)).toFixed(2)
                    : (0.0).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  </section>
);
export default ContComponent;
