import React from "react";

const ContComponent = (props) => (
  <div className="rankingTeam">
    <section>
      <h6>{props.selectedYearForNorthLeague}년 북부리그</h6>
      {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
      {/* <!-- [N]::등록선수가 없을때div className="noPlayer" -->			 */}
      {props.northLeagueList.length > 0 ? (
        <div className="mbTable mb">
          <ul>
            <li>순위</li>
            {props.northLeagueList.map((item, idx) => (
              <li>{idx + 1}</li>
            ))}
          </ul>
          <ul>
            <li>구단</li>
            {props.northLeagueList.map((item, idx) => (
              <li key={idx}>{item.teamname}</li>
            ))}
          </ul>
        </div>
      ) : null}
      {/* <!--//mbTable --> */}
      {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
      <div
        className={
          props.northLeagueList.length === 0
            ? "noPlayer"
            : "table_Typ01 mbScroll w8"
        }
      >
        {props.northLeagueList.length === 0 ? (
          <table>
            <caption>{props.selectedYearForNorthLeague}년 북부리그</caption>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td colSpan="8">경기 데이터가 존재하지 않습니다.</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <caption>{props.selectedYearForNorthLeague}년 북부리그</caption>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">순위</th>
                <th scope="col">구단</th>
                <th scope="col">경기수</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">무</th>
                <th scope="col">승률</th>
                <th scope="col">승차</th>
              </tr>
            </thead>
            <tbody>
              {props.northLeagueList.map((item, idx) => (
                <tr key={idx}>
                  <th scope="row">{idx + 1}</th>
                  <th scope="row">{item.teamname}</th>
                  <td>{item.game}</td>
                  <td>{item.w}</td>
                  <td>{item.l}</td>
                  <td>{item.d}</td>
                  <td>{item.wra}</td>
                  <td>{item.gd}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* <!--//table_Typ01 --> */}
    </section>
    <section>
      <h6>{props.selectedYearForSouthLeague}년 남부리그</h6>
      {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
      {/* <!-- [N]::등록선수가 없을때div className="noPlayer" -->			 */}
      {props.southLeagueList.length > 0 ? (
        <div className="mbTable mb">
          <ul>
            <li>순위</li>
            {props.southLeagueList.map((item, idx) => (
              <li key={idx} className={item.teamname === "KIA" ? "kia" : ""}>
                {idx + 1}
              </li>
            ))}
            {console.log(props)}
          </ul>
          <ul>
            <li>구단</li>
            {props.southLeagueList.map((item, idx) => (
              <li key={idx} className={item.teamname === "KIA" ? "kia" : ""}>
                {item.teamname}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {/* <!--//mbTable --> */}
      {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
      <div
        className={
          props.southLeagueList.length === 0
            ? "noPlayer"
            : "table_Typ01 mbScroll w8"
        }
      >
        {props.southLeagueList.length === 0 ? (
          <table>
            <caption>{props.selectedYearForSouthLeague}년 남부리그</caption>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td colSpan="8">경기 데이터가 존재하지 않습니다.</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <caption>{props.selectedYearForSouthLeague}년 남부리그</caption>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">순위</th>
                <th scope="col">구단</th>
                <th scope="col">경기수</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">무</th>
                <th scope="col">승률</th>
                <th scope="col">승차</th>
              </tr>
            </thead>
            <tbody>
              {props.southLeagueList.map((item, idx) => (
                <tr key={idx} className={item.teamname === "KIA" ? "kia" : ""}>
                  <th scope="row">{idx + 1}</th>
                  <th scope="row">{item.teamname}</th>
                  <td>{item.game}</td>
                  <td>{item.w}</td>
                  <td>{item.l}</td>
                  <td>{item.d}</td>
                  <td>{item.wra}</td>
                  <td>{item.gd}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* <!--//table_Typ01 --> */}
    </section>
  </div>
  // <!-- //rankingTeam -->
  // < !-- //mb_contIn -->
);

export default ContComponent;
