import React from 'react';

const ImgComponent = ({ imgUrl, defaultImgUrl, imgName = '' }) => (
    <img src={imgUrl ? imgUrl : defaultImgUrl} alt={imgName}
        onError={(e) => {
            e.target.onError = null;
            e.target.src = defaultImgUrl
        }} />
)

export default ImgComponent;