import axios from "axios";
import { data } from "jquery";

const psnToken = "dummyToken";
const baseUrl = "/v1";

// https://api.kiatigers.info/v1/common/getEncryptedTlReqParam
export const getEncryptedId = (_) => {
  let url = `${baseUrl}/common/getEncryptedTlReqParam`;
  return axios
    .post(url)
    .then(({ status, data }) => {
      if (data.status !== 0 && data.status !== 8004) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const insertAnalytics = async (req) => {
  return true;
    // let url = `${baseUrl}/web/analytics`
    // let data = new FormData();
    // data.append("analytics.atClassA", req.deps1);
    // data.append("analytics.atClassB", req.deps2);
    // data.append("analytics.atClassC", req.deps3);
    // data.append("analytics.atClassD", req.deps4);
    // data.append("analytics.atHttpRefferer", req.prevUrl);
    // data.append("analytics.atUrl", req.currentUrl);
    // data.append("analytics.atQueryString", req.queryString);
    // data.append("analytics.atChannel", !req.isMobile ? "WEB" : "APP");
    //
    // return await axios
    //     .post(url, data)
    //     .then(({data}) => {
    //         if (data.insert){
    //             return "success";
    //         } else {
    //             return "fail"
    //         }
    //     }).catch((err) => {
    //         console.error(err);
    //         throw new Error(err.message);
    //       });
}
