import React, {useCallback} from "react"
import {endOfMinute, isPast, parse} from "date-fns"
import {useHistory, useParams} from "react-router-dom"
import * as GoodsAuctionserviceAndBid from "../../../services/GoodsAuctionAndBid.service"
import {useQuery} from "react-query"

const MyGoodsBidDetail = () => {
  let history = useHistory()
  const {id} = useParams()

  const {isLoading, data} = useQuery(["myGoodsBidList", id], () => GoodsAuctionserviceAndBid.auctionBidDetail(id), {staleTime: 1000})

  const onClickReBiddingGoods = useCallback(
    (id) => {
      history.push(`/auction/goods-list/${id}`)
    },
    [history]
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  const endDate = data ? parse(data.endDt, "yyyy-MM-dd HH:mm:ss", 0) : 0
  const isAuctionEnd = isPast(endOfMinute(endDate))

  return (
    <div className="my-goods-bid-detail">
      {data && data.length > 0}
      <div className="goods-area">
        <img src={data.imagePath && data.imagePath.length > 0 ? data.imagePath : "/img/main/no_goods_img.png"} alt="bidding-goods-img" />
        <p>{data.productName}</p>
      </div>

      <div className="contour-line"> </div>

      <div className="current-bid-price">
        <h5>{isAuctionEnd ? "낙찰가" : "현재가"}</h5>
        <p>{`${data.currentPrice.toLocaleString("en")}원`}</p>
      </div>

      <div className="my-bidding-price">
        <h5>내 입찰가</h5>
        <p>{data.myBidPrice.toLocaleString("en")}원</p>
      </div>

      <div className={isAuctionEnd ? "" : data.myBidPrice === data.currentPrice ? "emphasize-higest-bid-price" : ""}>
        <p> {data.myBidPrice === data.currentPrice ? "현재 최고입찰자 입니다." : null}</p>
      </div>

      <div className="re-bid-button">
        <button
          disabled={isAuctionEnd}
          onClick={() => {
            onClickReBiddingGoods(data.productId)
          }}
          className="re-bid"
        >
          {data.myBidPrice === data.currentPrice ? `굿즈보러가기 >` : `재입찰하기 >`}
        </button>
      </div>

      <div className="whether-bid-or-not">
        <h5>입찰 여부</h5>
        <p>{isAuctionEnd ? (data.currentPrice === data.myBidPrice ? "입찰 성공" : "미입찰") : "경매 진행중"}</p>
      </div>
    </div>
  )
}

export default MyGoodsBidDetail
