import React, { Component } from "react";

import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import queryString from "query-string";
import * as QnaService from "../../../../services/Qna.service";
import * as ListHelper from "../../../../helpers/List.helper";
import * as DateHelper from "../../../../helpers/Date.helper";
import * as AuthHelper from "../../../../helpers/Auth.helper";
import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";

class ListContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const queryStringParams = queryString.parse(this.props.location.search);

    const currentPage = parseInt(
      queryStringParams["page"] ? queryStringParams["page"] : 1,
      10
    );
    const searchKeyword = queryStringParams["searchKeyword"]
      ? queryStringParams["searchKeyword"]
      : "";
    const isMineYn = queryStringParams["isMineYn"]
      ? queryStringParams["isMineYn"]
      : "Y";

    this.state = {
      subMenu: "inquiry",
      subMenuName: "1:1 문의",

      currentPage,
      searchKeyword,
      isMineYn,
      totalPage: 0,
      paginationObj: null,
      perPage: 10,
      tabNum: 1,
      list: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      // if (!AuthHelper.getLoginInfo()) {
      //     AuthHelper.removeLoginInfo();
      //     document.location.href = '/login';
      // }
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "04";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const offset = ListHelper.makeOffset(
        this.state.currentPage,
        this.state.perPage
      );
      const result = await QnaService.getList(
        offset,
        this.state.perPage,
        this.state.isMineYn,
        this.state.searchKeyword
      );

      let totalPage = 0;
      let paginationObj = null;
      let list = result.list;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (i === 0) {
          totalPage = item.totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            this.state.currentPage,
            this.state.perPage,
            10
          );
        }

        list[i]["regDate"] = DateHelper.convertTimestampToDate(
          list[i]["regDttm"],
          "."
        );
        list[i]["regTime"] = DateHelper.convertTimestampToTime(
          list[i]["regDttm"]
        );
      }
      console.log(list);
      this.setState({ list, totalPage, paginationObj });
    } catch (err) {
      console.error(err);
      alert(err.message);
      if(err.message === "1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.") {
        document.location.href = "/";
      }
      // if (err.message == '인증 오류입니다. 로그인을 하신 후에 이용해 주세요') {
      //     AuthHelper.removeLoginInfo();
      //     document.location.href = '/login';
      // }
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "04";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const queryStringParams = queryString.parse(this.props.location.search);

      const page = parseInt(
        queryStringParams["page"] ? queryStringParams["page"] : 1,
        10
      );
      const searchKeyword = queryStringParams["searchKeyword"]
        ? queryStringParams["searchKeyword"]
        : "";
      const isMineYn = queryStringParams["isMineYn"]
        ? queryStringParams["isMineYn"]
        : "N";

      this.updateList(page, searchKeyword, isMineYn);
    }
  };

  updateList = async (page, searchKeyword, isMineYn) => {
    try {
      const offset = ListHelper.makeOffset(page, this.state.perPage);

      const result = await QnaService.getList(
        offset,
        this.state.perPage,
        isMineYn,
        searchKeyword
      );
      let totalPage = 0;
      let paginationObj = null;
      let list = result.list;
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          totalPage = list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            page,
            this.state.perPage,
            10
          );
        }

        list[i]["regDate"] = DateHelper.convertTimestampToDate(
          list[i]["regDttm"],
          "."
        );
        list[i]["regTime"] = DateHelper.convertTimestampToTime(
          list[i]["regDttm"]
        );
      }
      // console.log(list);
      this.setState({
        list,
        totalPage,
        paginationObj,
        searchKeyword,
        isMineYn,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
      if(err.message === "1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.") {
        document.location.href = "/";
      }
      // if (err.message == '인증 오류입니다. 로그인을 하신 후에 이용해 주세요') {
      //     AuthHelper.removeLoginInfo();
      //     document.location.href = '/login';
      // }
    }
  };

  onChangeInput = (evt) => {
    const targetValue = evt.target.value;
    let invalidValue = targetValue.replace("\\", "").replace("|", "");
    this.setState({ searchKeyword: invalidValue });
  };

  onClickBtnSearch = (evt) => {
    let url = `/membership/inquiry?page=1`;
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
    }
    if (this.state.isMineYn) {
      url += `&isMineYn=${this.state.isMineYn}`;
    }
    this.props.history.push(url);
  };

  onClickBtnPageNum = (evt, pageNum) => {
    let url = `/membership/inquiry?page=${pageNum}`;
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
    }

    if (this.state.isMineYn) {
      url += `&isMineYn=${this.state.isMineYn}`;
    }
    this.props.history.push(url);
  };

  onClickBtnToggleType = (evt) => {
    let url = `/membership/inquiry?page=${1}`;

    let isMineYn = "N";
    if (this.state.isMineYn === "Y") {
      isMineYn = "N";
    } else {
      isMineYn = "Y";
    }
    url += `&isMineYn=${isMineYn}`;

    this.props.history.push(url);
    // this.setState({})
  };

  onClickBtnWrite = (_) => {
    this.props.history.push("/membership/inquiry/write");
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        onClickBtnToggleType={this.onClickBtnToggleType}
        isMineYn={this.state.isMineYn}
        list={this.state.list}
        searchKeyword={this.state.searchKeyword}
        onChangeInput={this.onChangeInput}
        onClickBtnSearch={this.onClickBtnSearch}
        onClickBtnWrite={this.onClickBtnWrite}
        paginationObj={this.state.paginationObj}
        onClickBtnPageNum={this.onClickBtnPageNum}
      />
    </div>
  );
}

export default ListContainer;
