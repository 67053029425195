import React from 'react';

const AlertPopupComponent = props => (
    <div className="on pop">
        <p className="popBg"></p>
        <div className="popIn">
            <p className="popX"><img onClick={props.onClickBtnclose} src="/img/sub/pop_close.png" alt="팝업닫기 버튼" /></p>
            <div>
                <p className="bold">{props.title}</p>
              <p>
              {props.message.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                )
              }
              )}
              </p>
                <div className="popBtnWrap">
                    <button onClick={props.onClickBtnOk} type="button">확인</button>
                </div>
            </div>
        </div>
    </div>
)

export default AlertPopupComponent;
