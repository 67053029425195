import React from 'react';
import { Link } from 'react-router-dom';
import ImgComponent from '../../../common/Img.component';
import * as ImageHelper from '../../../../helpers/Image.helper';

import PaginationComponent from '../../../common/Pagination.component';
const ContComponent = (props) => (
    <div className="mb_cont contents bodo">
        <div className="mb_contIn ">
            <div className="board">
                {/* <!-- srchArea --> */}
                <div className="srchArea clear">
                    <select
                        onChange={props.onChangeSelectList}
                        defaultValue={props.selectedSearchFilter}
                        name="" id="">
                        {props.searchFilterSelectList.map((item, idx) =>
                            <option key={idx} value={item.value}>{item.text}</option>
                        )}
                    </select>
                    <input defaultValue={props.searchKeyword}
                        onChange={props.onChangeInput}
                        onKeyPress={props.onEnterKeyPress}
                        type="text" placeholder="검색어를 입력해주세요" />
                    <button
                        onClick={props.onClickBtnSearch}
                        type="button">검색</button>
                </div>
                {/* <!-- //srchArea --> */}

                {/* <!-- board --> */}
                <div className="bdList type02">
                    <ul>
                        {props.list.map((item, idx) =>
                            <li key={idx} className={item.noticeYn === "Y" ? "clear on" : "clear"}>
                                <p className="num pc"><span className="">{item.noticeYn === "Y" ? "HOT" : item.artcSeq}</span></p>

                                <p className={(item.imgFilePath ? "hasImg" : "") + " sbj"}>
                                    {/* <!-- 이미지가 있는 경우 className="hasImg" --> */}
                                    <Link to={`/contents/press/${item.artcSeq}`} className="clear">
                                        {item.imgFilePath ?
                                            <span className="thumb">
                                                <ImgComponent
                                                    imgUrl={item.imgFilePath}
                                                    defaultImgUrl={ImageHelper.getContentsPressListNoImg()} />
                                            </span>
                                            : null}
                                        <span className="con">
                                            <strong>{item.artcTitle}</strong>
                                            <span dangerouslySetInnerHTML={{ __html: item.artcContent }}></span>
                                        </span>
                                    </Link>
                                </p>
                                <p className="info clear">
                                    <span className="date">{item.regDate}<em className="time">{item.regTime}</em></span>
                                    <span className="view"><i></i>{item.viewCnt}</span>
                                    <span className="like"><i></i>{item.likeCnt}</span>
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
                {/* <!-- //bdList --> */}
                <PaginationComponent
                    paginationObj={props.paginationObj}
                    onClickBtnPageNum={props.onClickBtnPageNum}
                />
                {/* <div className="paging">
                    <div className="pgingIn">
                        <p><img src="../../img/sub/pging_prev2.png" alt="게시판글 페이징 10씩 앞글보기" /></p>
                        <p><img src="../../img/sub/pging_prev.png" alt="게시판글 페이징 1씩 앞글보기" /></p>
                        <ol>
                            <li className="on">1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>10</li>
                        </ol>
                        <p><img src="../../img/sub/pging_next.png" alt="게시판글 페이징 1씩 뒷글보기" /></p>
                        <p><img src="../../img/sub/pging_next2.png" alt="게시판글 페이징 10씩 뒷글보기" /></p>
                    </div>
                </div> */}
            </div>
            {/* <!-- //board --> */}
        </div>
        {/* <!-- //mb_contIn --> */}
    </div >
)
export default ContComponent;