import React from 'react';
import SubMenuComponent from '../common/SubMenu.component';
import TeamRankingContainer from './team-ranking/TeamRanking.container';
import EachTeamScoreContainer from './each-team-score/EachTeamScore.container';
import FuturesRankingContainer from './futures-ranking/FuturesRanking.container'

const TeamRankingContComponent = (props) => (
    <div className="mb_cont sub_cont gameH">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName}
                thirdMenu={props.thirdMenu}
                thirdMenuName={props.thirdMenuName} />
            <ul className="tab02 w3 clear">
                <li className={props.tabNum === 1 ? "on" : ""}
                    style={{ width: "33.33333333%" }}>
                    <a onClick={evt => props.onClickBtnTab(evt, 1)}>팀순위</a>
                </li>
                <li className={props.tabNum === 2 ? "on" : ""}
                    style={{ width: "33.33333333%" }}>
                    <a onClick={evt => props.onClickBtnTab(evt, 2)}>팀별성적</a>
                </li>
                <li className={props.tabNum === 3 ? "on" : ""}
                    style={{ width: "33.33333333%" }}>
                    <a onClick={evt => props.onClickBtnTab(evt, 3)}>퓨처스 순위</a>
                </li>
            </ul>
            {props.tabNum === 1 ? <TeamRankingContainer {...props} /> : null}
            {props.tabNum === 2 ? <EachTeamScoreContainer {...props} /> : null}
            {props.tabNum === 3 ? <FuturesRankingContainer {...props} /> : null}
        </div>
    </div>
)

export default TeamRankingContComponent;
