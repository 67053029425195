import React from "react"
import { Route } from "react-router-dom"
import Switch from "react-router-dom/es/Switch"
import { PopupProvider } from "../../context/PopupContext"
import * as AuthHelper from "../../helpers/Auth.helper"
import ScorePointOrderProduct from "./products/ScorePointOrderProduct"
import ScorePointProductDetail from "./products/ScorePointProductDetail"
import ScorePointProducts from "./products/ScorePointProducts"

function ScorePoints() {
  React.useEffect(() => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.")
      AuthHelper.removeLoginInfo()
      document.location.href = "/login"
    }
  }, [])

  return (
    <PopupProvider>
      <Switch>
        <Route path="/score-points/products/:id/order" component={ScorePointOrderProduct} />
        <Route path="/score-points/products/:id" component={ScorePointProductDetail} />
        <Route path="/score-points/products" component={ScorePointProducts} />
        <Route>
          <h1>Not Found</h1>
        </Route>
      </Switch>
    </PopupProvider>
  )
}

export default ScorePoints
