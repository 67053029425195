import React from "react";
import SubMenuComponent from "../common/SubMenu.component";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const zoomPanPinchOptions = {
  transformEnabled: true,
  centerContent: true,
};

const ContComponent = (props) => (
  <div className="mb_cont sub_cont tigers champ champInfo2">
    <div className="mb_contIn">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      {/* <!-- //tabWrap --> */}
      <div className="ticketMapWrap">
        <p>광주-기아 챔피언스 필드</p>
        <div
          className="ticketMap"
          style={{ height: props.imgObj.ticketMapHeight }}
        >
          {props.imgObj.isInit ? (
            <TransformWrapper defaultScale={1} options={zoomPanPinchOptions}>
              <TransformComponent>
                <img src={props.imgUrlObj.stadium} alt="test" />
              </TransformComponent>
            </TransformWrapper>
          ) : null}
        </div>
        <p className="pc_tb ticketTxt">
          <strong>면적_</strong>57,646㎥(620,496ft²)
          <strong>규모_</strong>지하2층 지상5층
          <strong>관람석 수_</strong>20,500석
          <strong>경기장 규모_</strong>좌/우 99M, 중 121M Left/Right 325ft,
          Center 400ft
        </p>
        {/* <img src="/img/champions_field_2017.jpg" alt="면적, 규모, 관람석 수, 경기장 규모" className="ticketMapImg mb" /> */}
      </div>
      <div className="champBtn">
        {/* <button type="button" className="btnGray">좌석별 view 보기</button> */}
        <button onClick={props.onClickBtnTicketReservation} type="button">
          입장권 안내
        </button>
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
