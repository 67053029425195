import React, { Component } from 'react';
import SubHeadComponent from '../../common/SubHead.component';
import ContComponent from './Cont.component';
import queryString from 'query-string';

import * as ArticleService from '../../../../../services/Article.service';
import * as DateHelper from '../../../../../helpers/Date.helper';
import * as AuthHelper from '../../../../../helpers/Auth.helper';

class InfoContainer extends Component {
    constructor(props) {
        super(props);

        const articleId = this.props.match.params.articleId;

        const subMenu = "archive";
        const thirdMenu = "episode";

        this.state = {
            articleId,
            info: null,
            checkDidLike: false,
            subMenu,
            subMenuName: "지난 콘텐츠 보기",
            thirdMenu,
            thirdMenuName: "타이거즈 에피소드",
            tabNum: 1,
        }
    }

    componentDidMount = async _ => {
        try {
            const result = await ArticleService.getEpisodeArticleInfo(this.state.articleId);

            let info = result.article;
            info['regDate'] = DateHelper.convertTimestampToDate(info['regDttm']);
            info['regTime'] = DateHelper.convertTimestampToTime(info['regDttm']);

            let checkDidLike = false;
            if (AuthHelper.checkHasLogin()) {
                let checkLikeResult = await ArticleService.checkLike(this.state.articleId);
                checkDidLike = (checkLikeResult['articleLike']);
                console.log('checkLikeResult', checkLikeResult);
            }

            this.setState({ info: result.article, checkDidLike })
        } catch (err) {
            //alert(err.message);
        }
    }

    onClickBtnList = _ => {
        const queryStringParams = queryString.parse(this.props.location.search);
        const isFromHome = (queryStringParams['fromHome'] ? queryStringParams['fromHome'] : 'n').toLowerCase() === 'y';
        if (isFromHome) {
            this.props.history.push('/contents/archive/episode');
        } else {
            this.props.history.goBack();
        }
    }

    onClickBtnLike = async (evt) => {
        try {
            if (!AuthHelper.checkHasLogin()) {
                throw new Error("로그인이 필요합니다.");
            }

            const checkLikeResult = await ArticleService.checkLike(this.state.info.artcSeq);
            if (checkLikeResult.articleLike != null) {
                throw new Error("이미 좋아요 한 게시물입니다.");
            }

            const result = await ArticleService.doLike(this.state.info.artcSeq);
            let info = this.state.info;
            info.likeCnt++;
            this.setState({ info, checkDidLike: true });
            alert(result.message);
        } catch (err) {
            //alert(err.message);
        }
    }

    render = _ => (
        <div className='container'>
            <SubHeadComponent
                subMenuName={this.state.subMenuName}
                thirdMenuName={this.state.thirdMenuName} 
            />
            <div className="sbHead_appView tb"></div>
            <ContComponent
                info={this.state.info}
                onClickBtnList={this.onClickBtnList}
                onClickBtnLike={this.onClickBtnLike}
                checkDidLike={this.state.checkDidLike}
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName}
                thirdMenu={this.state.thirdMenu}
                thirdMenuName={this.state.thirdMenuName}
            />
        </div>
    )
}

export default InfoContainer;
