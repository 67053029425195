import React from "react"

const ContComponent = (props) => (
  <div className="mb_cont membership faq">
    <div className="mb_contIn ">
      <div className="faqBnr por">
        <p className="bg">
          <img src="../../img/sub/bg_faq.png" alt="배경이미지" className="pc_tb" />
          <img src="../../img/sub/bg_faq_mb.png" alt="배경이미지" className="mb" />
        </p>
        <div className="txt clear">
          <p>
            질문내용을 입력하시면,
            <br />
            관련된 답변을 바로 찾아 드립니다.
          </p>
          <div className="srchBox">
            <input onKeyPress={props.onEnterKeyPress} value={props.searchKeyword} onChange={props.onChangeInput} type="text" placeholder="검색어를 입력해주세요" />
            <button onClick={props.onClickBtnSearch} type="button">
              검색
            </button>
          </div>
        </div>
      </div>
      <div className="board">
        {/* <!-- board --> */}
        <div className="bdTab ovX pc">
          <ul className="clear fl">
            <li className={props.tabNum === 0 ? "on" : ""}>
              <a onClick={(evt) => props.onClickBtnTab(evt, 0)}>전체</a>
            </li>
            {props.tabData.map((item,idx) => (
              <li key={idx} className={props.tabNum === item.boardCatSeq ? "on" : ""}>
                <a onClick={(evt) => props.onClickBtnTab(evt, item.boardCatSeq)}>{item.boardCatName}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="bdTab_mobile">
          <select id="tab_select" value={props.tabNum} className="mb tb" onChange={(evt) => props.onClickBtnTab(evt, evt.target.value)}>
            <option value={0}>전체</option>
            {props.tabData.map((item,idx) => (
              <option key={idx} value={item.boardCatSeq}>{item.boardCatName}</option>
            ))}
          </select>
        </div>

        <div className="faqList">
          {props.list.length > 0 ? props.list.map((item, idx) => (
            <dl key={idx} onClick={(evt) => props.onClickBtnToggleArticle(evt, idx)}>
              <dt className={item.className}>
                <p className="faq_label">
                  [{item.boardCatName}] <span className="faq_question">{item.artcTitle}</span>
                </p>
              </dt>
              <dd>
                {item.artcContent.split("\n").map((line, idx) => (
                  <span key={idx}>
                  {line}
                    <br />
                </span>
                ))}
                {/*<span dangerouslySetInnerHTML={{__html: item.artcContent}}></span>*/}
              </dd>
            </dl>
          )) : <p className="no_search_result">검색 결과가 없습니다.</p>}
        </div>
        {/* <!-- //faqList --> */}
        {/*페이징 삭제*/}
        {/* <PaginationComponent paginationObj={props.paginationObj} onClickBtnPageNum={props.onClickBtnPageNum} /> */}
      </div>
      {/* <!-- //board --> */}
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
)

export default ContComponent
