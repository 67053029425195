// AES256 decoding
export const decodeAES256 = (data = "") => {
  const CryptoJS = require("crypto-js")

  const secretKey = "com.kiatigers.me"
  const iv = "com.kiatigers.me"

  const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  })

  const aes256DecodeData = cipher.toString(CryptoJS.enc.Utf8)

  return aes256DecodeData
}

// SHA256 hashing
export const encodeSHA256 = (data) => {
  const crypto = require("crypto")

  const saltKey = "helixtech"

  const hashed = crypto
    .createHash("sha256")
    .update(data + saltKey)
    .digest("hex")

  return hashed
}
