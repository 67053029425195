import React from 'react';

import Slider from 'react-slick';

const options = {
    autoplay: true,
    speed: 500,
    arrows: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
}

const SlideBannerMobileComponent = props => (
    <section className="mb_vs_slide1 mb">
        <ul className="mb_sl1">
            <Slider
                ref={slider => props.setSliderRef('slide-banner-mobile', slider)}
                {...options}>
                {props.list.map((item, idx) =>
                    <li key={idx}><img src={item.mobileImgFilePath} alt={item.name} /></li>
                )}
            </Slider>
        </ul>
        <div className="sl1_bg"></div>
    </section>
)

export default SlideBannerMobileComponent;