import React from 'react';
import SubMenuComponent from '../../common/SubMenu.component';

import Slider from 'react-slick';

const pcSliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    // asNavFor: 'pcSlider'
}
const navSliderOptions = {
    slidesToShow: 5,
    slidesToScroll: 1,
    // asNavFor: 'navSlider',
    //dots: false,
    //centerMode: true,
    focusOnSelect: true
}
const mobileSliderOptions = {
    autoplay: false,
    speed: 500,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: false,
    // prevArrow: $('.mbChampPrev'),
    // nextArrow: $('.mbChampNext')
}


const ContComponent = (props) => (
    <div className="mb_cont sub_cont tigers champ infoView">
        <div className="mb_contIn">
            <SubMenuComponent
                thirdMenu={props.thirdMenu} />
            <div className="inforViewIn">
                <h6 className="h6Bar">{props.title}</h6>
            </div>
            <div className="pictoSlide pc">
                <div className="pictoPc">
                    <div className="pictoBig">
                        <ul className="pcChampToFor">
                            <Slider
                                ref={slider => props.setSliderRef('pc-slider', slider)}
                                {...pcSliderOptions}
                                asNavFor={props.navSlider}
                                initialSlide={props.initialSlideNum}>
                                {props.pcSlideList.map((imageUrl, idx) =>
                                    <li key={idx}>
                                        <img src={imageUrl} alt="관람석" />
                                    </li>
                                )}

                                {/* <li><img src="/img/sub/champ_stadium01_02.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_03.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_04.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_05.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_06.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_07.png" alt="관람석" /></li>
                                <li><img src="/img/sub/champ_stadium01_08.png" alt="관람석" /></li> */}
                            </Slider>

                        </ul>
                    </div>
                    {/* <!-- //big -->				 */}
                    <div className="pictoSmall">
                        <ul className="pcChampToNav">
                            {props.pcSlideList.length > 1 ?
                                <Slider
                                    ref={slider => props.setSliderRef('nav-slider', slider)}
                                    {...navSliderOptions}
                                    asNavFor={props.pcSlider}
                                    initialSlide={props.initialSlideNum}>
                                    {props.pcSlideList.map((imageUrl, idx) =>
                                        <li key={idx}>
                                            <img src={imageUrl} alt="관람석" />
                                        </li>
                                    )}
                                </Slider>
                                :
                                <div className='slick-track'>
                                    <div className='slick-slide slick-current slick-active' style={{ display: 'block' }}>
                                        <div>
                                            <li style={{width:'169px'}}>
                                                <img src={props.pcSlideList[0]} alt="선수단시설" />
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                    </div>
                    {/* <!-- //small --> */}
                </div>
                {/* <!-- // pictoPc --> */}
            </div>
            <div className="pictoMb tb">
                <div>
                    <ul className="mbChampSlide">
                        <Slider
                            ref={slider => props.setSliderRef('mobile-slider', slider)}
                            {...mobileSliderOptions}
                            initialSlide={props.initialSlideNum}>
                            {props.mobileSlideList.map((imageUrl, idx) =>
                                <li key={idx}>
                                    <img src={imageUrl} alt="관람석" />
                                </li>
                            )}
                            {/* <li><img src="/img/sub/champ_stadium01_01.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_02.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_03.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_04.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_05.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_06.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_07.png" alt="관람석" /></li>
                            <li><img src="/img/sub/champ_stadium01_08.png" alt="관람석" /></li> */}
                        </Slider>
                    </ul>
                    <p className="mbChampPrev"
                        onClick={props.onClickBtnSlidePrev}><img src="/img/sub/pop_prev.png" alt="슬라이드 다음버튼" /></p>
                    <p className="mbChampNext"
                        onClick={props.onClickBtnSlideNext}><img src="/img/sub/pop_next.png" alt="슬라이드 다음버튼" /></p>
                </div>
            </div>
            {/* <!-- //pictoMb --> */}

            <div className="btnView">
                <div className="btnViewIn">
                    <button type="button" className="goList"
                        onClick={props.onClickBtnShowList}>목록보기</button>
                </div>
            </div>
        </div>
        {/* <!-- //mb_contIn --> */}
    </div>
)
export default ContComponent