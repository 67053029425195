import React from "react"
import {Link} from "react-router-dom"

const ContComponent = (props) => (
  <div className="mb_cont sns">
    <div className="mb_contIn ">
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.twitterUrl}
            onClick={() => {
              props.goSnsLink(props.twitterUrl, "twitter")
            }}
          >
            {/* <img src="/img/sub/sns_twitter.png" alt="twitter" /> */}
            <img src={props.imgPath.SNS_TWITTER} alt="twitter" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.instagramUrl}
            onClick={() => {
              props.goSnsLink(props.instagramUrl, "instagram")
            }}
          >
            <img src="/img/main/banner_instagram_pc.png" alt="instagram" style={{width: "412px", height: "276px"}} className="pc" />
            <img src="/img/main/banner_instagram_pc.png" style={{maxHeight: "29vw"}} alt="instagram" className="tb" />
            {/* <img src={props.imgPath.SNS_INSTAGRAM} alt="instagram" /> */}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.youtubeUrl}
            onClick={() => {
              props.goSnsLink(props.youtubeUrl, "youtube")
            }}
          >
            {/* <img src="/img/sub/sns_utube.png" alt="youtube" /> */}
            <img src={props.imgPath.SNS_YOUTUBE} alt="youtube" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.naverPostUrl}
            onClick={() => {
              props.goSnsLink(props.naverPostUrl, "naver")
            }}
          >
            {/* <img src="/img/sub/sns_naver.png" alt="네이버포스트" /> */}
            <img src={props.imgPath.SNS_NAVERPOST} alt="네이버포스트" />
          </a>
        </li>
        {/* <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.kakaoUrl}
            onClick={() => {
              props.goSnsLink(props.kakaoUrl, "kakao")
            }}
          >
            <img src={props.imgPath.SNS_KAKAOTALK} alt="카카오톡채널" />
          </a>
        </li> */}
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props._1boonUrl}
            onClick={() => {
              props.goSnsLink(props._1boonUrl, "1boon")
            }}
          >
            <img src={props.imgPath.SNS_1BOON} alt="1boon" />
          </a>
        </li>
      </ul>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
)

export default ContComponent
