import React, { createContext } from "react";

const AnalyticsContext = createContext({});

const AnalyticsProvider = ({ children }) => {
  const analyticsValue = {
    deps1: "01",
    deps2: "00",
    deps3: "00",
    deps4: "00",
    prevUrl: "",
    currentUrl: "",
    queryString: "",
    isMobile: ""
  };

  const setInitialDeps = (currentValue) => {
      currentValue.deps1 = "01";
      currentValue.deps2 = "00";
      currentValue.deps3 = "00";
      currentValue.deps4 = "00";
      currentValue.queryString = "";
  }

  return (
    <AnalyticsContext.Provider
      value={{ state: analyticsValue, action: setInitialDeps }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

const AnalyticsConsumer = AnalyticsContext.Consumer;

export { AnalyticsProvider, AnalyticsConsumer };

export default AnalyticsContext;
