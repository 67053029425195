import React from "react";
import * as DateHelper from "../../../../helpers/Date.helper";

const ContComponent = (props) =>
  props.info ? (
    <div className="mb_cont loveView">
      <div className="mb_contIn bdView">
        <div className="board">
          <div className="h6Bar">
            {props.info.artcTitle}
            <p className="icoWrap">
              <span className="user">{props.info.simpleId}</span>
              <span className="date">
                {props.info.regDate}&nbsp;&nbsp;{props.info.regTime}
              </span>
              <span className="view">
                <i></i>
                {props.info.viewCnt}
              </span>
              <span className="like">
                <i></i>
                {props.info.likeCnt}
              </span>
            </p>
          </div>
          <div className="viewCont viewContBtn">
            {/* {props.info.imgFilePath ?
                            <img src={props.info.imgFilePath} alt="보도자료 이미지" />
                            : null} */}
            <p
              className="tigerLoveCont"
              dangerouslySetInnerHTML={{ __html: props.info.artcContent }}
            ></p>
            {props.info.isMineYn === "Y" ? (
              <div className="vCntBtn">
                {/* <p onClick={props.onClickBtnShowReportPopup}>신고하기</p> */}
                <p onClick={props.onClickBtnModify}>수정</p>
                <p onClick={props.onClickBtnRemove}>삭제</p>
              </div>
            ) : (
              <div className="vCntBtn">
                <p onClick={props.onClickBtnShowReportPopup}>신고하기</p>
                {/* <p onClick={props.onClickBtnModify}>수정</p>
                                <p onClick={props.onClickBtnRemove}>삭제</p> */}
              </div>
            )}
          </div>
          <div className="icoWrap">
            <div>
              <button onClick={props.onClickBtnList} className="bgGray">
                목록
              </button>
              <button
                onClick={props.onClickBtnLike}
                type="button"
                className={(props.checkDidLike ? "like2" : "like") + " heart"}
              >
                <i></i>
                {props.info.likeCnt}
              </button>
              {/* <!-- 좋아요 버튼 --> */}
            </div>
          </div>
        </div>
        {/* <!-- //board --> */}
        <div className="arrowPN">
          <p>
            <i className="prev"></i>
            <strong>이전글</strong>
            {props.info.prevArtcSeq ? (
              <span
                onClick={(evt) =>
                  props.onClickBtnShowArticle(evt, props.info.prevArtcSeq)
                }
              >
                {props.info.prevArtcTitle}
              </span>
            ) : (
              <span>이전글이 없습니다.</span>
            )}
          </p>
          <p>
            <i className="next"></i>
            <strong>다음글</strong>
            {props.info.nextArtcSeq ? (
              <span
                onClick={(evt) =>
                  props.onClickBtnShowArticle(evt, props.info.nextArtcSeq)
                }
              >
                {props.info.nextArtcTitle}
              </span>
            ) : (
              <span>다음글이 없습니다.</span>
            )}
          </p>
        </div>
        <ReplyListComponent {...props} />
      </div>
    </div>
  ) : null;

const ReplyListComponent = (props) => (
  <div className="replyWrap">
    {props.replyList.map((item, idx) => (
      <div key={idx} className="repl">
        <div>
          <span>{item.simpleId}</span>
          <em>{DateHelper.convertTimestampToDatetime(item.regDttm)}</em>
          {props.userPermissionByInfo === "Y" &&
          props.userPermissionByCookie ? (
            <i onClick={(evt) => props.onClickBtnShowReplyForm(evt, idx)}>
              답글
            </i>
          ) : null}
          {item.isMine && props.userPermissionByInfo === "Y" ? (
            !item.isOnModify ? (
              <ul className="repo clear">
                <li
                  onClick={(evt) => props.onClickBtnShowModifyReply(evt, idx)}
                >
                  수정
                </li>
                <li
                  onClick={(evt) =>
                    props.onClickBtnRemoveReply(evt, item.replySeq)
                  }
                >
                  삭제
                </li>
              </ul>
            ) : (
              <ul className="repo clear">
                <li
                  onClick={(evt) => props.onClickBtnHideModifyReply(evt, idx)}
                >
                  수정취소
                </li>
              </ul>
            )
          ) : (
            <ul className="repo repo1">
              {/* <li onClick={evt => props.onClickBtnShowReplyReportPopup(evt, idx)} className="replPopBtn">신고</li> */}
            </ul>
          )}
          {!item.isOnModify ? (
            <div className="repl_content">{item.replyContent}</div>
          ) : null}
          {!item.isMine ? (
            <ReportReplyPopupComponent
              item={item}
              idx={idx}
              onClickBtnHideReplyReportPopup={
                props.onClickBtnHideReplyReportPopup
              }
              onClickBtnDoReplyReport={props.onClickBtnDoReplyReport}
              onClickReportRadio={props.onClickReportRadio}
            />
          ) : null}
        </div>
        {item.isOnModify ? (
          <p className="mt10">
            <textarea
              placeholder="수정할 댓글 내용"
              value={item.modifyObj["replyContent"]}
              onChange={(evt) => props.onChangeReplyTextAreaOnModify(evt, idx)}
            ></textarea>
            <span
              className="btnRegst"
              onClick={(evt) => props.onClickBtnReplyValidationCheck(item.modifyObj["replyContent"]) === false && props.onClickBtnModifyReply(evt, item) }
            >
              수정
            </span>
          </p>
        ) : null}

        {item.subReplyList.map((subItem, subIdx) => (
          <div key={subIdx} className="rerepl">
            <div className={subItem.isOnModify ? "" : "rereplTxt"}>
              <span>{subItem.simpleId}</span>
              <em>{DateHelper.convertTimestampToDatetime(subItem.regDttm)}</em>
              {subItem.isMine ? (
                !subItem.isOnModify ? (
                  <ul className="repo repo1">
                    <li
                      onClick={(evt) =>
                        props.onClickBtnShowModifyReply(
                          evt,
                          idx,
                          subItem.replySeq
                        )
                      }
                    >
                      수정
                    </li>
                    <li
                      onClick={(evt) =>
                        props.onClickBtnRemoveReply(evt, subItem.replySeq)
                      }
                    >
                      삭제
                    </li>
                  </ul>
                ) : (
                  <ul className="repo clear reCncl">
                    <li
                      onClick={(evt) =>
                        props.onClickBtnHideModifyReply(
                          evt,
                          idx,
                          subItem.replySeq
                        )
                      }
                    >
                      수정취소
                    </li>
                  </ul>
                )
              ) : (
                <li className="repo repo1">
                  {/* <li className="replPopBtn">신고</li> */}
                </li>
              )}
            </div>
            {!subItem.isOnModify ? (
              <div className="repl_content">{subItem.replyContent}</div>
            ) : null}
            {subItem.isOnModify &&
            props.userPermissionByCookie &&
            props.userPermissionByInfo === "Y" ? (
              <p className="mt10">
                <textarea
                  placeholder="수정할 댓글 내용"
                  value={subItem.modifyObj["replyContent"]}
                  onChange={(evt) =>
                    props.onChangeReplyTextAreaOnModify(
                      evt,
                      idx,
                      subItem.replySeq
                    )
                  }
                ></textarea>
                <span
                  className="btnRegst"
                  onClick={(evt) => props.onClickBtnReplyValidationCheck(subItem.modifyObj["replyContent"]) === false && props.onClickBtnModifyReply(evt, subItem)}
                >
                  수정
                </span>
              </p>
            ) : null}
          </div>
        ))}
        {item.isOpenedForm && props.userPermissionByInfo === "Y" ? (
          <div className="rerepl">
            <p>
              <textarea
                placeholder="답글 내용"
                value={item.replyContentValue}
                onChange={(evt) => props.onChangeReplyTextAreaOfReply(evt, idx)}
              ></textarea>
              <span
                className="btnRegst"
                onClick={(evt) =>props.onClickBtnReplyValidationCheck(item.replyContentValue) === false && props.onClickBtnCreateReplyOfReply(evt, idx)}
              >
                등록
              </span>
            </p>
          </div>
        ) : null}
      </div>
    ))}
    {props.userPermissionByCookie && props.userPermissionByInfo === "Y" ? (
      <div className="repl2">
        <p>
          <textarea
            placeholder="댓글 내용"
            value={props.replyContentValue}
            onChange={props.onChangeReplyTextArea}
          ></textarea>
          <span
          onClick={() => { props.onClickBtnReplyValidationCheck(props.replyContentValue) === false && props.onClickBtnCreateReply() }}
          className="btnRegst">
            등록
          </span>
        </p>
      </div>
    ) : null}

    {/* <div className="rerepl">
            <p>
                <textarea placeholder="답글 내용"></textarea>
                <span className="btnRegst">등록</span>
            </p>
        </div>
        <div className="repl">
            <div>
                <span>point9585</span>
                <em>2019.11.05  14:03 </em>
                <i>답글</i>
                <ul className="repo clear">
                    <li>수정</li>
                    <li>삭제</li>
                </ul>
            </div>
            <div>기아타이거즈 화이팅!!!</div>
        </div>
        <div className="repl">
            <div>
                <span>point9585</span>
                <em>2019.11.05  14:03 </em>
                <i>답글</i>
                <ul className="repo clear">
                    <li>수정취소</li>
                </ul>
            </div>
            <p className="mt10">
                <textarea placeholder="기아타이거즈 화이팅!!!"></textarea>
                <span className="btnRegst">수정</span>
            </p>
        </div>
        <div className="repl2">
            <p>
                <textarea placeholder="댓글 내용"></textarea>
                <span className="btnRegst">등록</span>
            </p>

        </div> */}
    {props.isOnShowReportPopup ? (
      <ReportPopupComponent
        info={props.info}
        onClickBtnHideReportPopup={props.onClickBtnHideReportPopup}
        onClickBtnDoReport={props.onClickBtnDoReport}
        onClickReportRadio={props.onClickReportRadio}
      />
    ) : null}
  </div>
);

const ReportPopupComponent = (props) => (
  <div className="repl">
    <div>
      <div className="popup dropOut on">
        {/* <!-- '신고'를 누르면 popup에 className="on" --> */}
        <div className="pcPopIn replPop">
          <p
            className="popClose"
            onClick={(evt) => props.onClickBtnHideReportPopup()}
          >
            <img src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
          </p>
          <div>
            <h3>신고하기</h3>
            <p>
              내용 : <span>{props.info.artcTitle}</span>
            </p>
            <p>
              작성자 : <span>{props.info.writerName}</span>
              <span>({props.info.simpleId})</span>
            </p>
            <hr />
            <p className="more1">
              <a href="/terms/operation">운영원칙자세히보기</a>
              사유선택 :{" "}
              <span>
                여러 사유에 해당되는 경우, 대표적인 사유 1개를 선택해주세요.
              </span>
            </p>
            <p className="popRadio" onClick={() => props.onClickReportRadio(1)}>
              <input type="radio" id="radio1" name="receive" />
              <label htmlFor="radio1">부적절한 홍보 게시글 </label>
            </p>
            <p className="popRadio" onClick={() => props.onClickReportRadio(2)}>
              <input type="radio" id="radio2" name="receive" />
              <label htmlFor="radio2">
                음란성 또는 청소년에게 부적합한 내용
              </label>
            </p>
            <p className="popRadio" onClick={() => props.onClickReportRadio(3)}>
              <input type="radio" id="radio3" name="receive" />
              <label htmlFor="radio3">
                명예훼손/사생활 침해 및 저작권침해 등
              </label>
            </p>
            <p className="popRadio" onClick={() => props.onClickReportRadio(4)}>
              <input type="radio" id="radio4" name="receive" />
              <label htmlFor="radio4">기타</label>
            </p>
            <div className="loginBtn_wrap">
              <div className="login_btn01">
                <ul className="login_btn01In">
                  <li>
                    <a
                      onClick={(evt) =>
                        props.onClickBtnDoReport(evt, props.idx)
                      }
                    >
                      신고하기
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(evt) => {
                        props.onClickBtnHideReportPopup(evt, props.idx);
                      }}
                    >
                      취소
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- loginBtn_wrap --> */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ReportReplyPopupComponent = (props) =>
  props.item.isOnShowReportPopup ? (
    <div className="popup dropOut on">
      {/* <!-- '신고'를 누르면 popup에 className="on" --> */}
      <div className="pcPopIn replPop">
        <p
          className="popClose"
          onClick={(evt) => props.onClickBtnHideReportPopup(evt, props.idx)}
        >
          <img src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
        </p>
        <div>
          <h3>신고하기</h3>
          <p>
            내용 : <span>{props.item.replyContent}</span>
          </p>
          <p>
            작성자 : <span>{props.item.writerName}</span>
            <span>({props.item.simpleId})</span>
          </p>
          <hr />
          <p className="more1">
            <a href="/terms/operation">운영원칙자세히보기</a>
          </p>
          <p>
            사유선택 :
            <span>
              여러 사유에 해당되는 경우, 대표적인 사유 1개를 선택해주세요.
            </span>
          </p>
          <p className="popRadio">
            <input type="radio" id="radio1" name="receive" />
            <label htmlFor="radio1">부적절한 홍보 게시글 </label>
          </p>
          <p className="popRadio">
            <input type="radio" id="radio2" name="receive" />
            <label htmlFor="radio2">
              음란성 또는 청소년에게 부적합한 내용{" "}
            </label>
          </p>
          <p className="popRadio">
            <input type="radio" id="radio3" name="receive" />
            <label htmlFor="radio3">
              명예훼손/사생활 침해 및 저작권침해 등
            </label>
          </p>
          <p className="popRadio">
            <input type="radio" id="radio4" name="receive" />
            <label htmlFor="radio4">기타</label>
          </p>
          <div className="loginBtn_wrap">
            <div className="login_btn01">
              <ul className="login_btn01In">
                <li>
                  <a
                    onClick={(evt) => props.onClickBtnDoReport(evt, props.idx)}
                  >
                    신고하기
                  </a>
                </li>
                <li>
                  <a
                    onClick={(evt) =>
                      props.onClickBtnHideReportPopup(evt, props.idx)
                    }
                  >
                    취소
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- loginBtn_wrap --> */}
        </div>
      </div>
      {/* <!-- //poPopIn -->	 */}
    </div>
  ) : null;

export default ContComponent;
