import React, { Component } from 'react';

import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';

class SmartOrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenu: 'smart-order',
            subMenuName: "스마트 오더"
        }
    }

    render = _ => (
        <div className="container">
            <SubHeadComponent
                subMenuName={this.state.subMenuName} />
            <ContComponent />
        </div>
    )
}

export default SmartOrderContainer;