import queryString from "query-string";
import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as AuthHelper from "../../../../helpers/Auth.helper";
import * as CommonHelper from "../../../../helpers/Common.helper";
import * as DateHelper from "../../../../helpers/Date.helper";
import * as ArticleService from "../../../../services/Article.service";
import * as AuthService from "../../../../services/Auth.service";
import * as CommonService from "../../../../services/Common.service";

class InfoContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    const articleId = this.props.match.params.articleId;

    this.state = {
      subMenu: "tigers",
      subMenuName: "호랑이 사랑방",
      articleId,
      info: null,
      replyList: [],
      replyContentValue: "",
      reportPopupObj: {
        isOnShow: false,
        replySeq: null,
      },
      selectedReportContent: "",
      checkDidLike: false,
      isOnShowReportPopup: false,
      userPermissionByCookie: false,
      userPermissionByInfo: "",
      banWordList:[],
    };
  }

  componentDidMount = async (_) => {
    let copiedState = {...this.state}
    try {
      const userPermissionByCookie = AuthHelper.checkHasLoveRoomAuth();
      const userPermissionByInfo = await AuthService.getUserBoardPermission();

      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "02";
      currentAnalyticsVal.deps4 = "01";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      this.updateArticle(this.state.articleId);

      copiedState = {
        ...copiedState,
        userPermissionByCookie,
        userPermissionByInfo : userPermissionByInfo.boardPermission,
      }

      const banWordList = await ArticleService.getFilterWord().then((data)=>data)
      copiedState.banWordList = banWordList

    } catch (err) {
      //alert(err.message);
    }
    this.setState({
      ...copiedState
    })
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      this.updateArticle(this.props.match.params.articleId);
    }
  };

  updateArticle = async (articleId) => {
    const result = await ArticleService.getTigersArticleInfo(articleId);

    let info = result.article;
    info["regDate"] = DateHelper.convertTimestampToDate(info["regDttm"]);
    info["regTime"] = DateHelper.convertTimestampToTime(info["regDttm"]);

    const replyListResult = await ArticleService.getReplyList(articleId);
    const replyList = this.makeReplyList(replyListResult.list);
    // const replyList = []

    let checkDidLike = false;
    if (AuthHelper.checkHasLogin()) {
      let checkLikeResult = await ArticleService.checkLike(articleId);
      checkDidLike = checkLikeResult["articleLike"];
    }

    this.setState({ info: result.article, replyList, checkDidLike, articleId });
  };

  makeReplyList = (replyList) => {
    // check is mine or not
    for (let i = 0; i < replyList.length; i++) {
      replyList[i]["isMine"] = replyList[i].regr == AuthHelper.getMemberId();
      replyList[i]["isOnModify"] = false;
      replyList[i]["isOnShowReportPopup"] = false;
      replyList[i]["subReplyList"] = [];
    }

    // make child reply list
    for (let i = 0; i < replyList.length; i++) {
      const replySeq = replyList[i]["replySeq"];
      for (let j = 0; j < replyList.length; j++) {
        if (replyList[j]["parentReplySeq"] == replySeq) {
          if (!replyList[i]["subReplyList"]) {
            replyList[i]["subReplyList"] = [];
          }
          replyList[i]["subReplyList"].push(
            CommonHelper.cloneObj(replyList[j])
          );
        }
      }
    }

    for (let i = replyList.length - 1; i >= 0; i--) {
      if (replyList[i]["parentReplySeq"] != 0) {
        delete replyList[i];
      } else {
        replyList[i]["isOpenedForm"] = false;
        replyList[i]["replyContentValue"] = "";
      }
    }
    return replyList;
  };

  onClickBtnShowReplyForm = (evt, idx) => {
    let replyList = this.state.replyList;
    replyList[idx]["isOpenedForm"] = true;
    this.setState({ replyList });
  };

  onChangeReplyTextAreaOfReply = (evt, idx) => {
    let replyList = this.state.replyList;
    replyList[idx]["replyContentValue"] = evt.target.value;
    this.setState({ replyList });
  };
  onClickBtnCreateReplyOfReply = async (evt, idx) => {
    try {
      if (!AuthHelper.checkHasLoveRoomAuth()) {
        throw new Error("댓글 작성 권한이 없습니다.");
      }
      const replyInfo = this.state.replyList[idx];
      let articleId = this.state.articleId;
      let replyId = replyInfo.replySeq;
      let content = replyInfo.replyContentValue;

      if (content.trim() === "") {
        throw new Error("댓글 내용을 입력해주세요.");
      }

      const result = await ArticleService.createReplyInfo(
        articleId,
        content,
        replyId
      );

      const replyListResult = await ArticleService.getReplyList(
        this.state.articleId
      );
      const replyList = this.makeReplyList(replyListResult.list);

      this.setState({ replyList });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  onChangeReplyTextArea = (evt) => {
    this.setState({ replyContentValue: evt.target.value });
  };
  onClickBtnCreateReply = async (evt) => {
    try {
      const userPermissionByInfo = await AuthService.getUserBoardPermission();
      if (
        !this.state.userPermissionByCookie ||
        userPermissionByInfo.boardPermission === "N"
      ) {
        throw new Error("댓글 작성 권한이 없습니다.");
      }

      if (this.state.replyContentValue.trim() === "") {
        throw new Error("댓글 내용을 입력해주세요.");
      }

      const result = await ArticleService.createReplyInfo(
        this.state.articleId,
        this.state.replyContentValue
      );

      const replyListResult = await ArticleService.getReplyList(
        this.state.articleId
      );
      const replyList = this.makeReplyList(replyListResult.list);

      this.setState({
        replyList,
        replyContentValue: "",
      });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };
  onClickBtnShowModifyReply = (evt, idx, seq = null) => {
    console.log("onClickBtnShowModifyReply", evt, idx, seq);
    let replyList = this.state.replyList;
    if (!seq) {
      replyList[idx]["isOnModify"] = true;
      replyList[idx]["modifyObj"] = {
        replyContent: replyList[idx]["replyContent"],
      };
      this.setState({ replyList });
    } else {
      for (let i = 0; i < replyList[idx].subReplyList.length; i++) {
        if (replyList[idx].subReplyList[i]["replySeq"] == seq) {
          replyList[idx].subReplyList[i]["isOnModify"] = true;
          replyList[idx].subReplyList[i]["modifyObj"] = {
            replyContent: replyList[idx].subReplyList[i]["replyContent"],
          };
          this.setState({ replyList });
          break;
        }
      }
    }
  };
  onClickBtnHideModifyReply = (evt, idx, seq = null) => {
    let replyList = this.state.replyList;
    if (!seq) {
      replyList[idx]["isOnModify"] = false;
      replyList[idx]["modifyObj"] = null;
      this.setState({ replyList });
    } else {
      for (let i = 0; i < replyList[idx].subReplyList.length; i++) {
        if (replyList[idx].subReplyList[i]["replySeq"] == seq) {
          replyList[idx].subReplyList[i]["isOnModify"] = false;
          replyList[idx].subReplyList[i]["modifyObj"] = null;
          this.setState({ replyList });
          break;
        }
      }
    }
  };
  onChangeReplyTextAreaOnModify = (evt, idx, seq = null) => {
    let replyList = this.state.replyList;
    if (!seq) {
      replyList[idx]["modifyObj"] = {
        replyContent: evt.target.value,
      };
      this.setState({ replyList });
    } else {
      for (let i = 0; i < replyList[idx].subReplyList.length; i++) {
        if (replyList[idx].subReplyList[i]["replySeq"] == seq) {
          replyList[idx].subReplyList[i]["modifyObj"] = {
            replyContent: evt.target.value,
          };
          this.setState({ replyList });
          break;
        }
      }
    }
  };
  onClickBtnModifyReply = async (evt, item) => {
    try {
      const seq = item.replySeq;
      const content = item.modifyObj["replyContent"];

      const result = await ArticleService.modifyReplyInfo(seq, content);

      const replyListResult = await ArticleService.getReplyList(
        this.state.articleId
      );
      const replyList = this.makeReplyList(replyListResult.list);

      this.setState({ replyList });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };
  onClickBtnRemoveReply = async (evt, seq) => {
    try {
      const result = await ArticleService.removeReplyInfo(
        this.state.articleId,
        seq
      );

      const replyListResult = await ArticleService.getReplyList(
        this.state.articleId
      );
      const replyList = this.makeReplyList(replyListResult.list);

      this.setState({ replyList });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  onClickBtnShowReportPopup = (_) => {
    this.setState({ isOnShowReportPopup: true });
  };
  onClickBtnHideReportPopup = (_) => {
    this.setState({ isOnShowReportPopup: false });
  };
  onClickBtnDoReport = async (evt) => {
    console.log(evt.target);
    try {
      let result = await ArticleService.reportArticle(
        this.state.articleId,
        this.state.selectedReportContent
      );
      if (result.message) {
        alert(result.message);
        this.setState({ isOnShowReportPopup: false });
      } else {
        alert("신고가 완료되었습니다.");
        this.setState({ isOnShowReportPopup: false });
      }
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  onClickBtnShowReplyReportPopup = (evt, idx) => {
    let replyList = this.state.replyList;
    replyList[idx]["isOnShowReportPopup"] = true;
    this.setState({ replyList });
  };
  onClickBtnHideReplyReportPopup = (evt, idx) => {
    let replyList = this.state.replyList;
    replyList[idx]["isOnShowReportPopup"] = false;
    this.setState({ replyList });
  };
  onClickBtnModify = (_) => {
    this.props.history.push(
      `/membership/tigers/${this.state.articleId}/modify`
    );
  };
  onClickBtnRemove = async (_) => {
    try {
      if (window.confirm("정말 삭제하시겠습니까?")) {
        const result = await ArticleService.deleteArticle(this.state.articleId);
        this.props.history.goBack();
        // console.log(result);
      }
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };
  onClickBtnDoReplyReport = (evt) => {};

  onClickBtnList = (_) => {
    const queryStringParams = queryString.parse(this.props.location.search);
    const isFromARrticle =
      (queryStringParams["fromArticle"]
        ? queryStringParams["fromArticle"]
        : "n"
      ).toLowerCase() === "y";
    if (isFromARrticle) {
      this.props.history.push(`/membership/tigers`);
    } else {
      this.props.history.goBack();
    }
  };

  onClickBtnLike = async (evt) => {
    try {
      if (!AuthHelper.checkHasLogin()) {
        throw new Error("로그인이 필요합니다.");
      }

      const checkLikeResult = await ArticleService.checkLike(
        this.state.info.artcSeq
      );
      if (checkLikeResult.articleLike != null) {
        throw new Error("이미 좋아요 한 게시물입니다.");
      }

      const result = await ArticleService.doLike(this.state.info.artcSeq);
      let info = this.state.info;
      info.likeCnt++;
      this.setState({ info, checkDidLike: true });
      alert(result.message);
    } catch (err) {
      //alert(err.message);
    }
  };

  onClickBtnShowArticle = (evt, articleId) => {
    const uri = `/membership/tigers/${articleId}?fromArticle=y`;
    this.props.history.push(uri);
  };

  onClickReportRadio = (radioNum) => {
    let reportContent = this.state.selectedReportContent;

    switch (radioNum) {
      case 1:
        reportContent = "부적절한 홍보 게시글";
        break;

      case 2:
        reportContent = "음란성 또는 청소년에게 부적합한 내용";
        break;

      case 3:
        reportContent = "명예훼손/사생활 침해 및 저작권침해 등";
        break;

      case 4:
        reportContent = "기타";
        break;

      default:
        return;
    }
    this.setState({ selectedReportContent: reportContent });
  };

  onClickBtnReplyValidationCheck = (replyContent) =>{
    try{
      const replyFilter = this.state.banWordList.some(i=> replyContent.includes(i))
      if (replyFilter) {
        throw new Error(
          "이 글에는 홈페이지 이용 정책에 위배되는 단어가 포함되어 있어 글을 등록할 수 없습니다. 이를 위반하고 글을 작성할 경우, 일정 기간 글쓰기 제한 또는 이용 해지 처리될 수 있으니 주의해주세요. 홈페이지 하단의 이용 정책을 확인하신 후, 이에 맞게 글을 수정하시어 모두가 이용하고 싶은 호랑이 사랑방을 만들어주세요."
        )
      }
      return replyFilter
    }catch(err){
      alert(err.message)
    }
  }

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      <ContComponent
        info={this.state.info}
        replyList={this.state.replyList}
        onClickBtnShowReplyForm={this.onClickBtnShowReplyForm}
        onClickBtnShowArticle={this.onClickBtnShowArticle}
        onClickBtnList={this.onClickBtnList}
        onClickBtnLike={this.onClickBtnLike}
        onClickBtnShowModifyReply={this.onClickBtnShowModifyReply}
        onClickBtnHideModifyReply={this.onClickBtnHideModifyReply}
        onChangeReplyTextAreaOnModify={this.onChangeReplyTextAreaOnModify}
        onClickBtnModifyReply={this.onClickBtnModifyReply}
        onClickBtnRemoveReply={this.onClickBtnRemoveReply}
        onChangeReplyTextAreaOfReply={this.onChangeReplyTextAreaOfReply}
        onClickBtnCreateReplyOfReply={this.onClickBtnCreateReplyOfReply}
        replyContentValue={this.state.replyContentValue}
        onChangeReplyTextArea={this.onChangeReplyTextArea}
        onClickBtnCreateReply={this.onClickBtnCreateReply}
        isOnShowReportPopup={this.state.isOnShowReportPopup}
        onClickBtnShowReportPopup={this.onClickBtnShowReportPopup}
        onClickBtnHideReportPopup={this.onClickBtnHideReportPopup}
        onClickBtnDoReport={this.onClickBtnDoReport}
        reportPopupObj={this.state.reportPopupObj}
        onClickBtnShowReplyReportPopup={this.onClickBtnShowReplyReportPopup}
        onClickBtnHideReplyReportPopup={this.onClickBtnHideReplyReportPopup}
        onClickBtnDoReplyReport={this.onClickBtnDoReplyReport}
        onClickBtnModify={this.onClickBtnModify}
        onClickBtnRemove={this.onClickBtnRemove}
        checkDidLike={this.state.checkDidLike}
        onClickReportRadio={this.onClickReportRadio}
        userPermissionByCookie={this.state.userPermissionByCookie}
        userPermissionByInfo={this.state.userPermissionByInfo}
        onClickBtnReplyValidationCheck={this.onClickBtnReplyValidationCheck}
      />
    </div>
  );
}

export default InfoContainer;
