import Slider from "react-slick";
import React from "react";
import * as PropTypes from "prop-types";

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1
}

function ProductImageSlider({images}) {
  return (
    <div className="top">
      <Slider {...sliderSettings}>
        {images && images.map((imgInfo) => (
          <img key={`${imgInfo.imageId}`}
               src={imgInfo.path}
               alt="상품이미지"/>
        ))}
        {!images && <img src="/img/main/no_goods_img.png" alt="상품이미지" />}
      </Slider>
    </div>
  );
}

ProductImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageId: PropTypes.number,
      path: PropTypes.string
    })
  )
  ,
};

export default ProductImageSlider