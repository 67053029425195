import React from 'react';

const ContComponent = props => (
    <div className="mb_cont cheer">
        {props.imgUrlObj ?
            <div className="mb_contIn">
                <p className="pc_tb"><img src={props.imgUrlObj.pc} alt="응원단장 서한국 키177cm, 장내MC 김영호 키176cm, 치어리더 류세미 키177cm 생일 5월28일, 치어리더 원민주 키173cm 생일 4월20일, 치어리더 김소연 키168cm 생일7월25일, 치어리더 송여름 키173cm 11월23일, 치어리더 정가예 168cm 생일2월6일, 치어리더 김재혜 키167cm 생일2월10일, 치어리더 김지민 키169cm 생일2월3일, 치어리더 임혜진 키165cm 생일4월19일, 치어리더 이이슬 키168cm 생일7월19일, 치어리더 장은송 키168cm 생일3월27일" /></p>
                <p className="mb"><img src={props.imgUrlObj.m} alt="응원단 소개 모바일 이미지" /></p>
            </div>
            : null}
    </div>
)
export default ContComponent;