import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ImgComponent from "../../../common/Img.component";
import * as ImageHelper from "../../../../helpers/Image.helper";

const sliderOptions = {
  autoplay: false,
  speed: 500,
  arrows: true,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  fade: false
};

const PlayerListSliderComponent = props => {
  return (
    <div className="plyr_slide2">
      <ul>
        <Slider
          ref={slider => props.setSliderRef("player", slider)}
          initialSlide={props.playerSliderDefaultIdx}
          {...sliderOptions}
        >
          {props.list.map((item, idx) => (
            <li key={idx} className={props.playerCode === item.pcode ? "on" : ""}>
              <a onClick={evt => props.onClickBtnPlayer(evt, item.pcode)}>
                <ImgComponent
                  imgUrl={item.playerOffImg}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListOffImg()}
                />
                <ImgComponent
                  imgUrl={item.playerImg}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                />
                <div className="txt">
                  <p className="pos">{item.position2}</p>
                  <p className="num">NO.{item.backnum}</p>
                  <p className="name">{item.playerName}</p>
                </div>
              </a>
            </li>
          ))}
        </Slider>
      </ul>
      <p
        className="plyr_prev"
        onClick={evt => props.onClickBtnSlidePrev(evt, "player")}
      >
        <img src="/img/sub/view_prev.png" alt="슬라이드 이전버튼"/>
      </p>
      <p
        className="plyr_next"
        onClick={evt => props.onClickBtnSlideNext(evt, "player")}
      >
        <img src="/img/sub/view_next.png" alt="슬라이드 다음튼"/>
      </p>
    </div>
  )
};

export default PlayerListSliderComponent;
