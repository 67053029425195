import React from "react";

const ContStep1Component = (props) => (
  <div className="mb_cont join join_step2 join_step3">
    <div className="mb_contIn">
      {props.isChild ? (
        <p className="join_prcs pc_tb">
          <img
            src="../../img/sub/join_prcs01_child.png"
            alt="보호자인증-약관동의-정보입력-가입완료 중 보호자인증 단계"
          />
        </p>
      ) : (
        <p className="join_prcs pc_tb">
          <img
            src="../../img/sub/join_prcs01.png"
            alt="본인인증-약관동의-정보입력-가입완료 중 본인인증 단계"
          />
        </p>
      )}
      {props.isChild ? (
        <p className="join_prcs mb">
          <img
            src="../../img/sub/mb_join_prcs01_child.png"
            alt="보호자인증-약관동의-정보입력-가입완료 중 보호자인증 단계"
          />
        </p>
      ) : (
        <p className="join_prcs mb">
          <img
            src="../../img/sub/mb_join_prcs01.png"
            alt="본인인증-약관동의-정보입력-가입완료 중 본인인증 단계"
          />
        </p>
      )}
      <div className="box">
        <p className="pc">
          다음의 인증 방법 중 하나를 선택해
          <br />
          본인 인증을 해주시기 바랍니다.
        </p>
        <p className="tb">실명인증 방법을 선택해주세요</p>
        <div className="login_btn01">
          <ul className="login_btn01In clear">
            <li>
              <a onClick={props.onClickBtnOpenCellAuthInStep1}>
                휴대폰<span className="tb">(본인 명의)</span>
                <br className="pc" />
                <span className="pc">인증</span>
              </a>
            </li>
            <li>
              <a onClick={props.onClickBtnOpenIpinAuthInStep1}>
                아이핀
                <br className="pc" />
                <span className="pc">인증</span>
              </a>
            </li>
          </ul>
          <ul className="txt pc clear">
            <li>
              국내 이동통신사에서 <br />
              본인 명의로 가입한 핸드폰만 <br />
              인증 가능 합니다.
            </li>
            <li>
              발급받으신 아이핀으로
              <br />
              본인 확인을 하실 수 있습니다.{" "}
            </li>
          </ul>
          <div className="txt tb">
            <p>
              · 본인 명의로 이동 통신사에 가입한 휴대폰 으로만 이용 가능합니다.
            </p>
            <p>
              · 소지하고 계신 휴대폰이 본인 명의가 아닌 경우(법인/MVNO 가입자
              포함), 아이핀을 선택해 주세요.
            </p>
            <p>
              [인증 이용문의처]
              <br />
              -NICE 평가정보 : 02-2122-4000
            </p>
          </div>
        </div>
        {/* <div className="comp_btn tb">실명인증</div> */}
      </div>
    </div>
  </div>
);
export default ContStep1Component;
