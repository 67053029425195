import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "../Cont.component";
import { Link } from "react-router-dom";

import * as GameService from "../../../../services/Game.service";
import * as DateHelper from "../../../../helpers/Date.helper";
import * as ImageHelper from "../../../../helpers/Image.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class MinorContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const yearMonth = this.props.match.params.yearmonth;
    const currentYear = yearMonth.substr(0, 4);
    const currentMonth = yearMonth.substr(4, 2);

    this.state = {
      subMenuName: "경기일정/결과",
      thirdMenu: "minor",
      thirdMenuName: "퓨처스리그",
      selectedYear: currentYear,
      selectedMonth: currentMonth,
      yearSelectList: DateHelper.getYearSelectList(),
      monthSelectList: DateHelper.getMonthSelectList(),
      monthlyScheduleObj: [],
      recentlyGamesPrev: null,
      recentlyGamesCurrent: null,
      recentlyGamesNext: null,
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "04";
      currentAnalyticsVal.deps3 = "01";
      currentAnalyticsVal.deps4 = "02";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const yearMonth = this.state.selectedYear + this.state.selectedMonth;

      const scheduleList = await GameService.getMinorMonthlySchedule(yearMonth);
      const monthlyScheduleObj = this.makeScheduleCalendar(
        this.state.selectedYear,
        this.state.selectedMonth,
        scheduleList
      );

      let recentlyGames = await GameService.getMinorRecentlyGames();
      recentlyGames = this.makeRecentlyGames(recentlyGames);

      this.setState({
        monthlyScheduleObj,
        recentlyGamesPrev: recentlyGames["prev"] ? recentlyGames["prev"] : null,
        recentlyGamesCurrent: recentlyGames["current"]
          ? recentlyGames["current"]
          : null,
        recentlyGamesNext: recentlyGames["next"] ? recentlyGames["next"] : null,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const yearMonth = this.props.match.params.yearmonth;
      const currentYear = yearMonth.substr(0, 4);
      const currentMonth = yearMonth.substr(4, 2);
      this.loadMonthlySchedule(currentYear, currentMonth);
    }
  };

  loadMonthlySchedule = async (year, month) => {
    const yearMonth = year + month;
    const scheduleList = await GameService.getMinorMonthlySchedule(yearMonth);
    const monthlyScheduleObj = this.makeScheduleCalendar(
      year,
      month,
      scheduleList
    );
    this.setState({
      monthlyScheduleObj,
      selectedYear: year,
      selectedMonth: month,
    });
  };

  makeRecentlyGames = (obj) => {
    console.log("makeRecentlyGames");
    if (obj.prev) {
      obj.prev["homeEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.prev["homeKey"]
      );
      obj.prev["visitEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.prev["visitKey"]
      );
    }
    if (obj.current) {
      obj.current["homeEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.current["homeKey"]
      );
      obj.current["visitEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.current["visitKey"]
      );
    }
    if (obj.next) {
      obj.next["homeEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.next["homeKey"]
      );
      obj.next["visitEmblemUrl"] = ImageHelper.getEmblemImgByTeamCode(
        obj.next["visitKey"]
      );
    }
    return obj;
  };

  makeScheduleCalendar = (year, month, scheduleList) => {
    let scheduleListObj = {};
    for (let i = 0; i < scheduleList.length; i++) {
      const scheduleObj = scheduleList[i];
      console.log("scheduleObj", scheduleObj.displayDate.substring(6, 8));
      const day = parseInt(scheduleObj.displayDate.substring(6, 8), 10);
      console.log("day", day);
      if (!scheduleListObj[day]) {
        scheduleListObj[day] = {
          gameKey: scheduleObj["gmkey"],
          gameDate: scheduleObj["displayDate"],
          stadiumName: scheduleObj["stadium"], // stadium
          gameTime: scheduleObj["gtime"], // gtime
          gameResult: scheduleObj["outcome"], // outcome(승, 패, 무, 취)
          homeScore: scheduleObj["homeScore"], // homeScore
          awayScore: scheduleObj["visitScore"], // visitScore
          matchTeamName: scheduleObj["matchTeamName"],
          matchTeamEmblemUrl: ImageHelper.getEmblemImgByTeamCode(
            scheduleObj["matchTeamCode"]
          ),
          isHomeGame: scheduleObj["homeKey"] === "HT",
        };
      } else {
        scheduleListObj[day]["doubleHeader"] = {
          gameKey: scheduleObj["gmkey"],
          gameDate: scheduleObj["displayDate"],
          stadiumName: scheduleObj["stadium"], // stadium
          gameTime: scheduleObj["gtime"], // gtime
          gameResult: scheduleObj["outcome"], // outcome(승, 패, 무, 취)
          homeScore: scheduleObj["homeScore"], // homeScore
          awayScore: scheduleObj["visitScore"], // visitScore
          matchTeamName: scheduleObj["matchTeamName"],
          matchTeamEmblemUrl: ImageHelper.getEmblemImgByTeamCode(
            scheduleObj["matchTeamCode"]
          ),
          isHomeGame: scheduleObj["homeKey"] === "HT",
          isDoubleHeader: scheduleListObj[day] != null,
        };
      }
    }
    console.log("scheduleList", scheduleList);
    console.log("scheduleListObj", scheduleListObj);

    let monthlyScheduleObj = [];
    let startDayOfWeek = DateHelper.getDayOfWeek(year, month, 1);
    let lastDayOfMonth = DateHelper.getLastDayOfMonth(year, month);
    console.log("startDayOfWeek", startDayOfWeek);
    console.log("lastDayOfMonth", lastDayOfMonth);
    let day = 1;

    for (let i = 0; i < 6; i++) {
      let weeklySchedule = [];
      for (let j = 0; j < 7; j++) {
        if (day > lastDayOfMonth) {
          break;
        }

        if (i === 0 && j < startDayOfWeek) {
          weeklySchedule.push(null);
        } else {
          let info = scheduleListObj[day] ? scheduleListObj[day] : null;
          let dailySchedule = {
            day: day++,
            info,
          };
          weeklySchedule.push(dailySchedule);

          if (day > lastDayOfMonth) {
            break;
          }
        }
      }

      if (weeklySchedule.length === 0) {
        break;
      }

      monthlyScheduleObj.push(weeklySchedule);
    }
    return monthlyScheduleObj;
  };

  onChangeSelectedYear = (evt) => {
    this.setState({ selectedYear: evt.target.value });
    let uri = this.props.match.path.split("/").slice(0, 4).join("/");
    const yearMonth = evt.target.value + this.state.selectedMonth;
    this.props.history.push(`${uri}/${yearMonth}`);
  };
  onChangeSelectedMonth = (evt) => {
    this.setState({ selectedMonth: evt.target.value });
    let uri = this.props.match.path.split("/").slice(0, 4).join("/");
    const yearMonth = this.state.selectedYear + evt.target.value;
    this.props.history.push(`${uri}/${yearMonth}`);
  };
  onClickBtnShow = (evt) => {
    console.log(this.props.match);
    let uri = this.props.match.path.split("/").slice(0, 4).join("/");
    const yearMonth = this.state.selectedYear + this.state.selectedMonth;
    this.props.history.push(`${uri}/${yearMonth}`);
  };

  onClickBtnPrevMonth = (evt) => {
    let uri = this.props.match.path.split("/").slice(0, 4).join("/");

    let month = parseInt(this.state.selectedMonth, 10) - 1;
    month = month < 1 ? 12 : month;
    const year =
      month === 12
        ? parseInt(this.state.selectedYear, 10) - 1
        : parseInt(this.state.selectedYear, 10);

    month = (month < 10 ? "0" + month : month) + "";

    uri = `${uri}/${year}${month}`;
    this.props.history.push(uri);
  };
  onClickBtnNextMonth = (evt) => {
    let uri = this.props.match.path.split("/").slice(0, 4).join("/");

    let month = parseInt(this.state.selectedMonth, 10) + 1;
    month = month > 12 ? 1 : month;
    const year =
      month === 1
        ? parseInt(this.state.selectedYear, 10) + 1
        : parseInt(this.state.selectedYear, 10);

    month = (month < 10 ? "0" + month : month) + "";

    uri = `${uri}/${year}${month}`;
    this.props.history.push(uri);
  };

  onClickBtnGameInfo = (evt, gameKey, gameDate, gameResult = null) => {
    console.log("onClickBtnShow", gameKey, gameDate, gameResult);

    if (gameResult === "취") {
      alert("경기결과가 존재하지 않습니다.");
      return false;
    }
    const uri = `/game/schedule/view?type=minor&gameKey=${gameKey}&gameDate=${gameDate}`;
    this.props.history.push(uri);
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      {/* 240123 주석시작 */}
      {/* {process.env.REACT_APP_PREPARING_GAME_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont game msr tabLeft">
          <div className="mb_contIn">
            <div className="tabWrap">
              <ul className="tab01 clear">
                <li className={this.state.thirdMenu === "major" ? "on" : ""}>
                  <Link to="/game/schedule/major">1군</Link>
                </li>
                <li className={this.state.thirdMenu === "minor" ? "on" : ""}>
                  <Link to="/game/schedule/minor">퓨처스리그</Link>
                </li>
              </ul>
            </div>

            <div style={{ marginTop: "50px" }}>
              <img
                src="/img/KIA_service_stop.png"
                alt="season_off_pc"
                className="pc"
              />
              <img
                src="/img/KIA_service_stop.png"
                alt="season_off_mb"
                className="greeting_mb tb"
              />
            </div>
          </div>
        </div>
      ) : (
        <ContComponent
          thirdMenu={this.state.thirdMenu}
          yearSelectList={this.state.yearSelectList}
          monthSelectList={this.state.monthSelectList}
          onClickBtnShow={this.onClickBtnShow}
          onClickBtnPrevMonth={this.onClickBtnPrevMonth}
          onClickBtnNextMonth={this.onClickBtnNextMonth}
          onChangeSelectedYear={this.onChangeSelectedYear}
          onChangeSelectedMonth={this.onChangeSelectedMonth}
          selectedYear={this.state.selectedYear}
          selectedMonth={this.state.selectedMonth}
          monthlyScheduleObj={this.state.monthlyScheduleObj}
          recentlyGamesPrev={this.state.recentlyGamesPrev}
          recentlyGamesCurrent={this.state.recentlyGamesCurrent}
          recentlyGamesNext={this.state.recentlyGamesNext}
          onClickBtnGameInfo={this.onClickBtnGameInfo}
        />
      )} */}
      {/* 240123 주석끝 */}
      <ContComponent
          thirdMenu={this.state.thirdMenu}
          yearSelectList={this.state.yearSelectList}
          monthSelectList={this.state.monthSelectList}
          onClickBtnShow={this.onClickBtnShow}
          onClickBtnPrevMonth={this.onClickBtnPrevMonth}
          onClickBtnNextMonth={this.onClickBtnNextMonth}
          onChangeSelectedYear={this.onChangeSelectedYear}
          onChangeSelectedMonth={this.onChangeSelectedMonth}
          selectedYear={this.state.selectedYear}
          selectedMonth={this.state.selectedMonth}
          monthlyScheduleObj={this.state.monthlyScheduleObj}
          recentlyGamesPrev={this.state.recentlyGamesPrev}
          recentlyGamesCurrent={this.state.recentlyGamesCurrent}
          recentlyGamesNext={this.state.recentlyGamesNext}
          onClickBtnGameInfo={this.onClickBtnGameInfo}
        />
    </div>
  );
}

export default MinorContainer;
