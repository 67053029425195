import React from 'react';

const AuthNameConfirmPopupComponent = props => (
    <div className="on pop">
        <p className="popBg"></p>
        <div className="popIn">
            <p className="popX"><img onClick={props.onClickBtnCancel} src="/img/sub/pop_close.png" alt="팝업닫기 버튼" /></p>
            <div>
                <p>KIA TIGERS 서비스를 이용하시려면 <br />본인인증이 추가로 필요합니다.<br />내 정보 관리에서 본인인증을 완료해주세요.</p>
                <p className="bold">내 정보 관리 페이지로 이동하시겠습니까?</p>
                <div className="popBtnWrap">
                    <button onClick={props.onClickBtnCancel} type="button" className="gray">취소</button>
                    <button onClick={props.onClickBtnConfirm} type="button">확인</button>
                </div>
            </div>
        </div>
    </div>
)

export default AuthNameConfirmPopupComponent;