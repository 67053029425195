import React from "react";
import { decodeAES256 } from "../../helpers/Crypto.helper";


const ContConfirmPasswordComponent = props => (
  <div className="mb_cont join fnd pw">
    <div className="mb_contIn">
      <div className="box">
        <div>
          <h4>비밀번호 확인</h4>
          <p>정확한 본인 확인을 위해 다시 한번 비밀번호를 입력해주세요.</p>
        </div>
        <form action="/" method="post">
          <fieldset>
            <legend>비밀번호 확인</legend>
            <div>
              <label htmlFor="userID">아이디</label>
              <input
                type="text"
                id="userID"
                value={decodeAES256(props.loginInfo.user.simpleId)}
                readOnly
              />
            </div>
            <div>
              <label htmlFor="userPw">비밀번호</label>
              <input
                type="password"
                id="userPw"
                value={props.authObj.password}
                placeholder="비밀번호 입력"
                onChange={evt =>
                  props.onChangeInput(evt, "authObj", "password")
                }
                onKeyPress={evt => props.onEnterKey(evt)}
              />
            </div>
          </fieldset>
          <div className="comp_btn" onClick={props.onClickBtnConfirmPassword}>
            확인하기
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default ContConfirmPasswordComponent;
