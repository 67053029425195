import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ListContainer from './list/List.container';
import InfoContainer from './info/Info.container';

class NoticeContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}`} component={ListContainer} />
            <Route path ={`${this.props.match.path}/:articleId`} component={InfoContainer} />
        </Switch>
    )
}

export default NoticeContainer;