import axios from "axios";

const psnToken = "dummyToken";

const baseUrl = "/v1";

// https://api.kiatigers.info/v1/article/getArticleList?article.boardCode=hosabang&search.pos=0&search.max=3
export const getList = (queryString) => {
  const url = `${baseUrl}/article/getArticleList${queryString}`;
  // console.log(url);
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};
export const getTigersArticleList = (params) => {
  let queryString = "?article.boardCode=hosabang";
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  console.log("queryString", queryString);
  return getList(queryString);
};
export const getPressArticleList = (params) => {
  let queryString = "?article.boardCode=press_release";
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getList(queryString);
};

export const getNoticeArticleList = (params) => {
  let queryString = "?article.boardCode=notice";
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${encodeURI(`${params[key]}`)}`)
  );
  return getList(queryString);
};

export const getNewsArticleList = (params) => {
  let queryString = "?article.boardCode=news";
  Object.keys(params).map((key, idx) => {
    queryString += `&${key}=`;
    queryString += encodeURI(`${params[key]}`);
    return queryString;
  });
  return getList(queryString);
};

export const getEpisodeArticleList = (params = {}) => {
  let queryString = "?article.boardCode=episode";
  Object.keys(params).map((key, idx) => {
    queryString += `&${key}=`;
    queryString += encodeURI(`${params[key]}`);
    return queryString;
  });
  return getList(queryString);
};

export const getTvArticleList = (params = {}) => {
  let queryString = "?article.boardCode=tv";
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getList(queryString);
};

export const getPicturesList = (params = {}) => {
  let queryString = "?article.boardCode=photo";
  Object.keys(params).map((key, idx) => {
    queryString += `&${key}=`;
    queryString += encodeURI(`${params[key]}`);
    return queryString;
  });
  return getList(queryString);
};

export const getInfo = (queryString) => {
  const url = `${baseUrl}/article/detail${queryString}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const getTigersArticleInfo = async (id) => {
  let queryString = `?article.boardCode=hosabang&article.artcSeq=${id}`;
  return getInfo(queryString);
};
export const getPressArticleInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=press_release&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const getNoticeArticleInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=notice&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const getNewsArticleInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=news&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const getTvArticleInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=tv&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const getPicturesInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=photo&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const getEpisodeArticleInfo = (id, params = {}) => {
  let queryString = `?article.boardCode=episode&article.artcSeq=${id}`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getInfo(queryString);
};

export const doLike = (id) => {
  const url = `${baseUrl}/article/like`;
  // let data = new FormData();
  // data.append("articleLike.artcSeq", id);
  const data = {
    "articleLike.artcSeq": id,
  }
  return axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 좋아요 확인
// https://api.kiatigers.info/v1/article/like?articleLike.artcSeq=960923
export const checkLike = (id) => {
  const url = `${baseUrl}/article/like?articleLike.artcSeq=${id}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getSponsorList = async () => {
  let queryString = `?article.boardCode=sponsor&search.max=100`;
  return await getList(queryString);
};

export const getFaqList = async (params) => {
  let queryString = `?article.boardCode=faq`;
  Object.keys(params).map(
    (key, idx) => (queryString += `&${key}=${params[key]}`)
  );
  return getList(queryString);
};

// https://api.kiatigers.info/v1/article/hotissue
export const getHotIssueList = async (params = {}) => {
  const url = `${baseUrl}/article/hotissue`;
  return await axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 댓글 목록
// https://api.kiatigers.info/v1/article/getReplyList?reply.artcSeq=811675
export const getReplyList = async (id) => {
  const url = `${baseUrl}/article/getReplyList?reply.artcSeq=${id}`;
  return await axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 댓글 등록
// https://api.kiatigers.info/v1/article/createReply
// reply.artcSeq	Number	댓글 일련번호	필수
// reply.replyContent	String	내용	필수
// reply.replySeq	Number	댓글 일련번호	옵션
export const createReplyInfo = async (id, content, replySeq = null) => {
  console.log("createReplyInfo", id, content, replySeq);
  const url = `${baseUrl}/article/createReply`;
  // let data = new FormData();
  // data.append("reply.artcSeq", id);
  // data.append("reply.replyContent", content);

  const data = {
    "reply.artcSeq": id,
    "reply.replyContent": content
  }

  if (replySeq) {
    // data.append("reply.replySeq", replySeq);
    data["reply.replySeq"] = replySeq;
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 댓글 삭제
// https://api.kiatigers.info/v1/article/deleteReply
// reply.replySeq	Number	댓글 일련번호	필수
// reply.artcSeq	Number	게시물 일련번호	필수	해당 게시물의 댓글 수 체크
export const removeReplyInfo = async (articleId, replySeq) => {
  const url = `${baseUrl}/article/deleteReply`;
  // let data = new FormData();
  // data.append("reply.artcSeq", articleId);
  // data.append("reply.replySeq", replySeq);

  const data = {
    "reply.artcSeq": articleId,
    "reply.replySeq": replySeq
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 댓글 수정
// https://api.kiatigers.info/v1/article/updateReply
// reply.replySeq	Number	댓글 일련번호	필수
// reply.replyContent	String	내용	옵션
export const modifyReplyInfo = async (replySeq, content) => {
  const url = `${baseUrl}/article/updateReply`;
  // let data = new FormData();
  // data.append("reply.replySeq", replySeq);
  // data.append("reply.replyContent", content);

  const data = {
    "reply.replySeq": replySeq,
    "reply.replyContent": content
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 게시글 신고
// https://api.kiatigers.info/v1/article/report
export const reportArticle = async (articleSeq, content) => {
  const url = `${baseUrl}/article/report`;
  // let data = new FormData();
  // data.append("articleReport.artcSeq", articleSeq);
  // data.append("articleReport.reportReason", content);

  const data = {
    "articleReport.artcSeq": articleSeq,
    "articleReport.reportReason": content
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 게시글 등록
// article.artcTitle	String	제목	필수
// article.artcContent	String	내용	필수
// https://api.kiatigers.info/v1/article/createArticle
export const createArticle = async (title, contents) => {
  const url = `${baseUrl}/article/createArticle`;
  // let data = new FormData();
  // data.append("article.artcTitle", title);
  // data.append("article.artcContent", contents);

  const data = {
    "article.artcTitle": title,
    "article.artcContent": contents
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 게시글 수정
// article.artcSeq	Number	게시판 일련번호	필수
// article.artcContent	String	내용	옵션
// article.artcTitle	String	제목	옵션
// https://api.kiatigers.info/v1/article/updateArticle
export const updateArticle = async (articleId, title, contents) => {
  const url = `${baseUrl}/article/updateArticle`;
  // let data = new FormData();
  // data.append("article.artcSeq", articleId);
  // data.append("article.artcTitle", title);
  // data.append("article.artcContent", contents);


  const data = {
    "article.artcSeq": articleId,
    "article.artcTitle": title,
    "article.artcContent": contents
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 게시글 삭제
// article.artcSeq	Number	게시판 일련번호	필수
// https://api.kiatigers.info/v1/article/deleteArticle
export const deleteArticle = async (articleId) => {
  const url = `${baseUrl}/article/deleteArticle`;
  // let data = new FormData();
  // data.append("article.artcSeq", articleId);

  const data = {
    "article.artcSeq": articleId
  }

  return await axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getPlayerPicture = (param) => {
  const encodeParam = encodeURI(param);
  const url =
    baseUrl +
    "/photo/files?articleFile.description=" +
    encodeParam +
    "&search.max=10";
  console.log(url);
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//게시글 필터 단어
export const getFilterWord = async () => {
  let url = `${baseUrl}/article/getBanWordList`
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        data.data.banWordList=[]
      }
        return data.data.banWordList

    })
}
