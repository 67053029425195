import React, { Component } from "react";
import queryString from "query-string";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";
import * as ListHelper from "../../../helpers/List.helper";
import * as ArticleService from "../../../services/Article.service";
import AnalyticsContext from "../../../context/AnalyticsContext";
import * as CommonService from "../../../services/Common.service";
import { getInternal } from "../../../services/ScorePoint.service";



class FaqContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const queryStringParams = queryString.parse(this.props.location.search);

    const tabNum = parseInt(
      queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 0,
      10
    );
    const currentPage = parseInt(
      queryStringParams["page"] ? queryStringParams["page"] : 1,
      10
    );
    const searchKeyword = queryStringParams["searchKeyword"]
      ? queryStringParams["searchKeyword"]
      : "";

    this.state = {
      subMenu: "faq",
      subMenuName: "FAQ",
      tabNum,
      currentPage,
      searchKeyword,

      tabData: [],

      totalPage: 0,
      perPage: 100,
      paginationObj: null,
      list: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      // alert("준비중입니다");
      // history back
      // this.props.history.goBack();
      // return;

      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "03";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);
      //tabData을 가져오는 코드 추가

      //230130 주석
      const tabData = await getInternal(`/article/getBoardCategoryList?article.boardCode=faq`).then((data)=>data.list)

      const offset = ListHelper.makeOffset(
        this.state.currentPage,
        this.state.perPage
      );

      //230130 주석
      const result = await this.getList(
        offset,
        this.state.searchKeyword,
        this.state.tabNum
      );

      let totalPage = 0;
      let paginationObj = null;
      //230130 주석
      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            this.state.currentPage,
            this.state.perPage,
            5
          );
        }
      }

      this.setState({
        //230130 주석
        list: result.list,
        totalPage,
        paginationObj,
        //230130 주석
        tabData
       });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const queryStringParams = queryString.parse(this.props.location.search);

      const page = parseInt(
        queryStringParams["page"] ? queryStringParams["page"] : 1,
        10
      );
      const searchKeyword = queryStringParams["searchKeyword"]
        ? queryStringParams["searchKeyword"]
        : "";
      const tabNum = parseInt(
        queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 0
      );

      this.updateList(page, searchKeyword, tabNum);
    }
  };

  //230130 주석
  updateList = async (page, searchKeyword, tabNum) => {
    // console.log("updateList", page, searchKeyword, tabNum);
    try {
      const offset = ListHelper.makeOffset(page, this.state.perPage);
      const result = await this.getList(offset, searchKeyword, tabNum);

      let totalPage = 0;
      let paginationObj = null;

      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            page,
            this.state.perPage,
            5
          );
        }
      }

      this.setState({
        list: result.list,
        totalPage,
        paginationObj,
        currentPage: page,
        tabNum,
      });
    } catch (err) {
      //alert(err.message);
    }
  };

  getList = async (offset, searchKeyword, categorySeq) => {
    let listParams = {
      "search.pos": offset,
      "search.max": this.state.perPage,
    };

    if (searchKeyword) {
      listParams["search.searchWord"] = searchKeyword;
      listParams["search.searchCategory"] = 100;
    }

    listParams["article.boardCatSeq"] = categorySeq;

    return ArticleService.getFaqList(listParams);
  };

  onClickBtnTab = (evt, tabNum) => {
    let url = `/membership/faq?page=1&tabNum=${tabNum}`;
    this.props.history.push(url);
    this.setState({ selectedSearchFilter: 100, searchKeyword: "" });
  };

  onClickBtnToggleArticle = (evt, idx) => {
    let list = this.state.list;
    for (let i = 0; i < list.length; i++) {
      if (i === idx) {
        list[i]["className"] = list[i]["className"] === "on" ? "" : "on";
      } else {
        list[i]["className"] = "";
      }
    }
    this.setState({ list });
  };

  onClickBtnPageNum = (evt, pageNum) => {
    console.log("onClickBtnPageNum", pageNum);
    let url = `/membership/faq?page=${pageNum}&tabNum=${this.state.tabNum}`;
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
    }
    this.props.history.push(url);
  };

  onChangeInput = (evt) => {
    this.setState({ searchKeyword: evt.target.value });
  };
  onClickBtnSearch = () => {
    let url = `/membership/faq?page=1&tabNum=0`;
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
      this.props.history.push(url);
    }
  };
  onEnterKeyPress = (evt) =>{
    if(evt.key==='Enter'){
      this.onClickBtnSearch();
    }
  }

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />

      <ContComponent
        tabNum={this.state.tabNum}
        tabData={this.state.tabData}
        onClickBtnTab={this.onClickBtnTab}
        searchKeyword={this.state.searchKeyword}
        onChangeInput={this.onChangeInput}
        onEnterKeyPress={this.onEnterKeyPress}
        onClickBtnSearch={this.onClickBtnSearch}
        list={this.state.list}
        onClickBtnToggleArticle={this.onClickBtnToggleArticle}
        paginationObj={this.state.paginationObj}
        onClickBtnPageNum={this.onClickBtnPageNum}
      />
    </div>
  );
}

export default FaqContainer;
