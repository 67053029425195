import React from "react";

const ContComponent = () => (
  <div className="mb_cont intro2">
    <div className="mb_contIn">
      <div className="vs">
        <img src="/img/sub/intro2_vs.png" alt="TIGERS" className="pc_tb" />
        <img src="/img/sub/intro2_vs_mb.png" alt="TIGERS" className="mb" />
        <h3>
          <strong>BASEBALL CLUB</strong> KIA타이거즈
        </h3>
        <h4>
          한국야구의 <strong>우수성</strong>을 세계 만방에 널리 알리는!
        </h4>
        <h4>
          <strong>한국프로야구를 대표</strong>하는!{" "}
        </h4>
      </div>
      <article>
        <div>
          <img
            src="/img/sub/intro2_dt01.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>V11 신화 이룩한 한국 최고의 명문구단</h5>
          <p>
            KIA타이거즈는 11번의 한국시리즈를 제패한 KBO리그의 최고 명문
            구단입니다. 지난 2009년 정규시즌과 한국시리즈 통합우승을 차지했던
            KIA는 2017년 다시 통합우승을 차지하며 전통의 명가 지위를 공고히
            했습니다.
            <br />
            <br />
            KIA타이거즈의 한국시리즈 불패신화는 영원합니다.
            <br />
            1983년 프로야구 출범 2년째를 맞아 한국시리즈에서 첫 우승을 이룬
            이후, 86년부터 89년까지 4년간 한국시리즈 불패의 신화를 이룩했습니다.
            KIA타이거즈의 한국시리즈 불패 신화는 여기서 그치지 않았습니다. 90년
            잠시 숨을 고른 후 91년과 93년 또 다시 한국시리즈를 제패하는 투지를
            불태웠고, 이후 96년과 97년 2년 연속 챔피언에 오르며 한국프로야구를
            대표하는 명문 구단임을 팬들에게 각인 시켰습니다.
          </p>
        </div>
      </article>
      <article>
        <div>
          <img
            src="/img/sub/intro2_dt02.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>끈끈한 팀워크와 투혼 간직한 야구명가</h5>
          <p>
            해태타이거즈를 인수한 KIA타이거즈는 지난 2001년 8월 2일 인천에서
            SK와이번스와 역사적인 첫 경기를 가지며 그 출범을 알렸습니다. 이후
            KIA타이거즈는 과거의 훌륭한 전통과 세계 5대 자동차 메이커로의 도약을
            노리는 현대-기아자동차그룹의 노하우를 접목시키며 야구명가의 자존심을
            확고히 하고 그 위치를 굳혀나가고 있습니다.
            <br />
            <br />
            무엇보다 KIA타이거즈 최고의 자산은 끈끈한 팀워크와 투혼입니다.{" "}
            <br />
            선후배가 똘똘 뭉쳐 그 어느팀보다 탄탄한 팀워크로 무장되어 있고,
            매서운 눈빛과 근성은 날이 갈수록 더욱 더 매서워지고 있습니다. <br />
            KIA타이거즈는 이제 한국을 넘어 세계적인 명문구단으로 발돋음하기 위해
            더욱 힘찬 발걸음을 내디딜 것입니다.
          </p>
        </div>
      </article>
      <article>
        <div>
          <img
            src="/img/sub/intro2_dt03.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>한국프로야구 스타의 요람</h5>
          <p>
            KIA타이거즈는 한국야구의 스타들의 요람입니다.
            <br />
            김봉연, 김성한, 김종모, 이순철, 한대화, 선동열, 이강철, 이종범,
            이대진, 장성호 등 90년대를 대표하는 프로야구 스타들이 모두 호랑이
            유니폼을 입고, 그라운드를 누비며 팬들의 환호를 받았습니다.
            <br />
            <br />
            이후 2000년대에 접어들어서도 스타 탄생의 산실로서 그 자리를 확고히
            굳히고 있습니다. <br />
            양현종, 나성범, 최형우, 김선빈, 이의리 등이
            대표적으로 지금의 한국프로야구를 이끌어가고 있는 스타들입니다.
            <br />
            지금도 이들은 곳곳에서 한국프로야구를 위해 굵은 땀방울을 흘리며
            최선의 노력을 다하고 있습니다.
          </p>
        </div>
      </article>
      <article>
        <div>
          <img
            src="/img/sub/intro2_dt04.png"
            alt="기아타이거즈 구단소개 이미지"
          />
        </div>
        <div>
          <h5>팬과 함께 하는 KIA타이거즈</h5>
          <p>
            가장 열성적인 팬을 보유하고 있는 KIA타이거즈는 행복합니다.
            <br />
            국내 그 어느 구단보다 애정 가득한 응원을 보내주는 팬들이 있기에
            오늘의 KIA타이거즈가 존재한다고 해도 과언이 아닐 것입니다.
            <br />
            KIA타이거즈는 지난 2001년 창단과 함께 '팬과 함께 하는 구단'을
            선언했습니다.
            <br />
            KIA타이거즈는 팬들이 진정 원하는 것이 무엇인지 정확히 헤아릴 줄 아는
            구단으로 성장하기 위해 부단한 노력을 기울이고 있습니다.
            <br />
            또한 선수단은 팬들을 위해 매 경기 최선을 다하는 플레이를 펼치고
            있고, 프런트는 팬들을 위한 서비스에 더욱 만전을 기할 것입니다.
          </p>
        </div>
      </article>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
