import React from 'react';
import SubMenuComponent from '../common/SubMenu.component';

const ContComponent = (props) => (
    <div className="mb_cont sub_cont gameH">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName}
                thirdMenu={props.thirdMenu}
                thirdMenuName={props.thirdMenuName} />
            <ul className="tab02 w5 clear">
                <li className={props.tabNum === 1 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 1)}>상대팀별</a>
                </li>
                <li className={props.tabNum === 2 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 2)}>홈원정별</a>
                </li>
                <li className={props.tabNum === 3 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 3)}>주야간별</a>
                </li>
                <li className={props.tabNum === 4 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 4)}>요일별</a>
                </li>
                <li className={props.tabNum === 5 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 5)}>월별</a>
                </li>
            </ul>
            <div className="rankingTeam">
                <section>
                    <h6>투수성적</h6>
                    <div className="srch">
                        <p onClick={evt => props.onClickBtnSearch(evt, 'pitcher')}
                            className="btn_srch" style={{ cursor: "pointer" }}>검색</p>
                        <p>
                            <label htmlFor="cell" className="blind">년도</label>
                            <select id="cell"
                                defaultValue={props.selectedYearForPitcher}
                                onChange={evt => props.onChangeSelectYearList(evt, 'selectedYearForPitcher')}>
                                {props.yearSelectList.map((item, idx) =>
                                    <option key={idx} value={item.value}>{item.text}</option>
                                )}
                            </select>
                        </p>
                    </div>
                    {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
                    {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
                    {props.pitcherRecordList.length > 0 ?
                        <div className="mbTable mb">
                            <ul>
                                <li>구분</li>
                                {props.pitcherRecordList.map((item, idx) =>
                                    <li key={idx}>{item.groupif}</li>
                                )}
                            </ul>
                        </div>
                        : null}
                    {/* <!--//mbTable --> */}
                    {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
                    <div className={props.pitcherRecordList.length === 0 ? 'noPlayer' : "table_Typ01 mbScroll thFix1 w16"}>
                        {props.pitcherRecordList.length === 0
                            ?
                            <table>
                                <caption>조건별기록 현황표</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td colSpan="16">기록이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <table>
                                <caption>조건별기록 현황표</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        {props.pitcherColumnHeaderList.map((item, idx) =>
                                            <th key={idx} onClick={evt => props.sortTable('pitcher', item.key)} scope="col" style={item.key === "era" ? {fontSize:"11px"} : {}}>
                                                {item.name}<span className={idx > 0 ? 'downArow' : ''}></span>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.pitcherRecordList.map((item, idx) =>
                                        <tr key={idx}>
                                            <th scope="row">{item.groupif}</th>
                                            <td>{item.gamenum}</td>
                                            <td>{item.w}</td>
                                            <td>{item.l}</td>
                                            <td>{item.sv}</td>
                                            <td>{item.hold}</td>
                                            <td>{item.inndisplay}</td>
                                            <td>{item.tugucount}</td>
                                            <td>{item.hit}</td>
                                            <td>{item.hr}</td>
                                            <td>{item.hp}</td>
                                            <td>{item.wp}</td>
                                            <td>{item.kk}</td>
                                            <td>{item.r}</td>
                                            <td>{item.er}</td>
                                            <td>{item.era}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                    {/* <!--//table_Typ01 --> */}
                </section>
                <section>
                    <h6>타자성적</h6>
                    <div className="srch">
                        <p onClick={evt => props.onClickBtnSearch(evt, 'hitter')}
                            className="btn_srch" style={{ cursor: "pointer" }}>검색</p>
                        <p>
                            <label htmlFor="cell" className="blind">년도</label>
                            <select id="cell"
                                defaultValue={props.selectedYearForHitter}
                                onChange={evt => props.onChangeSelectYearList(evt, 'selectedYearForHitter')}>
                                {props.yearSelectList.map((item, idx) =>
                                    <option key={idx} value={item.value}>{item.text}</option>
                                )}
                            </select>
                        </p>
                    </div>
                    {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
                    {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
                    {props.hitterRecordList.length > 0 ?
                        <div className="mbTable mb">
                            <ul>
                                <li>구분</li>
                                {props.hitterRecordList.map((item, idx) =>
                                    <li key={idx}>{item.groupif}</li>
                                )}
                            </ul>
                        </div>
                        : null}
                    {/* <!--//mbTable --> */}
                    {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
                    <div className={props.hitterRecordList.length === 0 ? 'noPlayer' : "table_Typ01 mbScroll thFix1 w16"}>
                        {props.hitterRecordList.length === 0
                            ?
                            <table>
                                <caption>조견별기록 현황표</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td colSpan="16">기록이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <table>
                                <caption>조견별기록 현황표</caption>
                                <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        {
                                            props.hitterColumnHeaderList.map((item, idx) =>
                                                <th key={idx} onClick={evt => props.sortTable('hitter', item.key)} scope='col'>
                                                    {item.name}
                                                    <span className={idx > 0 ? 'downArow' : ''}></span>
                                                </th>
                                            )
                                        }
                                        {/* <th scope="col">구분</th>
                                        <th scope="col">경기<span className="downArow"></span></th>
                                        <th scope="col">타수<span className="downArow"></span></th>
                                        <th scope="col">득점<span className="downArow"></span></th>
                                        <th scope="col">안타<span className="downArow"></span></th>
                                        <th scope="col">2타<span className="downArow"></span></th>
                                        <th scope="col">3타<span className="downArow"></span></th>
                                        <th scope="col">홈런<span className="downArow"></span></th>
                                        <th scope="col">타점<span className="downArow"></span></th>
                                        <th scope="col">도루<span className="downArow"></span></th>
                                        <th scope="col">4구<span className="downArow"></span></th>
                                        <th scope="col">삼진<span className="downArow"></span></th>
                                        <th scope="col">병살<span className="downArow"></span></th>
                                        <th scope="col">타율<span className="downArow"></span></th>
                                        <th scope="col">장타율<span className="downArow"></span></th>
                                        <th scope="col">출루율<span className="downArow"></span></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.hitterRecordList.map((item, idx) =>
                                        <tr key={idx}>
                                            <th scope="row">{item.groupif}</th>
                                            <td>{item.gamenum}</td>
                                            <td>{item.ab}</td>
                                            <td>{item.run}</td>
                                            <td>{item.hit}</td>
                                            <td>{item.h2}</td>
                                            <td>{item.h3}</td>
                                            <td>{item.hr}</td>
                                            <td>{item.rbi}</td>
                                            <td>{item.sb}</td>
                                            <td>{item.hp}</td>
                                            <td>{item.kk}</td>
                                            <td>{item.gd}</td>
                                            <td>{item.hra}</td>
                                            <td>{item.slg}</td>
                                            <td>{item.bra}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                    {/* <!--//table_Typ01 --> */}
                </section>
            </div>
        </div>
    </div>
)

export default ContComponent;
