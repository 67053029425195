import React from "react";

import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';

const ContComponent = (props) => (
  <div className="mb_cont ticket" style={{
    backgroundColor: '#EFEFEF'
  }}>
    <div className="mb_contIn">
      {props.ticketList && props.ticketList.length > 0 ? (
        <div className="ticket_list">
          {props.ticketList.map((item, index) => (
            <div className="ticket" key={index} style={{
              background: 'url("/img/ticket/ticket_bg.png") no-repeat',
              width: '315px',
              height: '480px',
              margin: '10px auto',
              backgroundSize: '100%',
              position: 'relative'
            }}>
              <img src={item.awayTeamSrc} style={{
                position: 'absolute',
                top: '190px',
                left: '25px',
                width: '50px',
                height: '50px'
              }} />
              <p className="title" style={{
                position: 'absolute',
                top: '246px',
                left: '25px',
                fontSize: '18px',
                fontWeight: 'bold',
                letterSpacing: '-5%',
                color: '#515257'

              }}>
                {"VS" + item.scheduleName.split("vs")[1]}
              </p>
              <p className="date" style={{
                position: 'absolute',
                top: '274px',
                left: '25px',
                fontSize: '12px',
                fontWeight: 'light',
                letterSpacing: '-5%',
                color: '#515257'
              }}>
                  {item.playDateTime}
              </p>
              <div style={{
                position: 'absolute',
                top: '336px',
                left: '25px',
                fontSize: '12px',
                fontWeight: 'bold',
                letterSpacing: '-5%',
                color: '#515257',
                lineHeight: '1.5'
              }}>
                <p>{item.productGradeName}{" "}</p>
                <p>{item.seatInfo}</p>
                <p>{item.denominationName}</p>
                <p className="ticket_no" style={{
                  fontWeight: 'lighter'
                }}>{item.ticketNo.substring(1)}</p>
              </div>

              <QRCode value={item.ticketNo} size={78} style={{
                position: 'absolute',
                top: '336px',
                right: '28px'
              }} />
              <div style={{
                display:'none'
              }}>
              {setInterval(() => {
                document.getElementById("clock"+index).innerHTML = dayjs().format("HH:mm:ss");
                document.getElementById("dot"+index).style.visibility = document.getElementById("dot"+index).style.visibility == "hidden" ? "visible" : "hidden"
                }, 1000)}
              </div>
              <span id={"dot" + index} style={{
                color: "#FF0000",
                fontSize: "8px",
                position:'absolute',
                top: '423px',
                right: '70px',
                visibility: 'hidden'
              }}>●</span>
              <p id={"clock" + index} style={{
                color:"#58595B",
                fontSize: '10px',
                position:'absolute',
                top: '423px',
                right: '29px',
                fontWeight: 'lighter',
                letterSpacing: '-5%'
              }}></p>
            </div>
          ))}
        </div>
) : (

      <div className="no_ticket">
        <p className="img">
          스마트티켓이 없습니다
        </p>
      </div>
    )}
    </div>


  </div>
);

export default ContComponent;
