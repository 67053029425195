import React from "react";

const ContFindCellComponent = props => (
  <div className="mb_cont join fnd">
    <div className="mb_contIn">
      <div className="box">
        <div>
          <p>
            가입 당시 등록한 정보를 통해 아이디를 찾을 수 있습니다.
            {/*<br />*/}
            {/*휴대폰 또는 이메일정보 중 한가지를 선택해주세요.*/}
          </p>
          <ul className="btn_fnd">
            <li className="on">휴대폰</li>
            {/*<li onClick={evt => props.onClickBtnTab(1)}>이메일</li>*/}
          </ul>
        </div>
        <form action="/" method="post">
          <fieldset>
            <legend>아이디 찾기</legend>
            <div className="cell">
              <label htmlFor="cell">휴대폰 번호</label>
              {/* <select id="cell"
                                defaultValue={props.cellObj.num1}
                                onChange={props.onChangeSelectCellphoneNum1}>
                                <option value="010" >010</option>
                                <option value="011" >011</option>
                                <option value="018" >018</option>
                            </select> */}
              <input
                type="text"
                id="cell"
                maxLength="3"
                value={props.cellObj.num1}
                onChange={evt => props.onChangeInput(evt, "cell", "num1")}
                onKeyPress={evt => props.onEnterKey(evt)}
                alt="휴대폰 인증"
              />
              <span>-</span>
              <input
                type="text"
                title="휴대전화 앞자리 번호"
                maxLength="4"
                value={props.cellObj.num2}
                onChange={evt => props.onChangeInput(evt, "cell", "num2")}
                onKeyPress={evt => props.onEnterKey(evt)}
                alt="휴대폰 인증"
              />
              <span>-</span>
              <input
                type="text"
                title="휴대전화 뒷자리 번호"
                maxLength="4"
                value={props.cellObj.num3}
                onChange={evt => props.onChangeInput(evt, "cell", "num3")}
                onKeyPress={evt => props.onEnterKey(evt)}
                alt="휴대폰 인증"
              />
              {/* <span
                className="chk_jnStep2"
                onClick={evt => props.onClickBtnRequestAuthNum(evt, "cell")}
              >
                인증요청
              </span> */}
            </div>
            <div>
              <label htmlFor="certName" style={{ float: "left" }}>
                이름
              </label>
              <div>
                <input
                  type="text"
                  id="certName"
                  alt="이름"
                  onChange={(e) => {props.onChangeName(e)}}
                />
                <span
                  className="chk_jnStep2 apply_btn"
                  onClick={evt => props.onClickBtnRequestAuthNum(evt, "cell")}
                >
                  인증요청
                </span>
              </div>
            </div>
            <div>
              <label htmlFor="useNum" style={{ float: "left" }}>
                인증번호
              </label>
              <input
                type="text"
                id="useNum"
                placeholder={props.cellObj.placeholder}
                value={props.authNum}
                onChange={props.onChangeAuthNum}
                maxLength="6"
                onKeyPress={evt => props.onEnterKey(evt)}
                alt="휴대폰 인증 완료"
                style={{ float: "left" }}
              />
              <div
                className="comp_btn fnd_id_btn"
                onClick={evt => props.onClickBtnRequestAuth(evt, "cell")}
              >
                아이디 찾기
              </div>
              <em>* 휴대폰 번호 입력 후 인증요청 버튼을 선택해 주세요. </em>
            </div>
          </fieldset>
          {/* <p className="txt">
            * 회원가입 시 선택사항으로 이메일 정보를 추가로 입력한 고객만
            이메일로 아이디 찾기가 가능합니다.
          </p> */}
        </form>
      </div>
    </div>
  </div>
);

const ContFindEmailComponent = props => (
  <div className="mb_cont join fnd">
    <div className="mb_contIn">
      <div className="box">
        <div>
          <p>
            가입 당시 등록한 정보를 통해 아이디를 찾을 수 있습니다.
            <br />
            휴대폰 또는 이메일정보 중 한가지를 선택해주세요.
          </p>
          <ul className="btn_fnd">
            <li onClick={evt => props.onClickBtnTab(0)}>휴대폰</li>
            <li className="on">이메일</li>
          </ul>
        </div>
        <form action="/" method="post">
          <fieldset>
            <legend>아이디 찾기</legend>
            <div className="email">
              <p className="clear">
                <label htmlFor="userEm">이메일 주소</label>
                <input
                  type="text"
                  title="이메일ID"
                  id="userEm"
                  value={props.emailObj.emailId}
                  onChange={evt => props.onChangeInput(evt, "email", "emailId")}
                  onKeyPress={evt => props.onEnterKey(evt)}
                  alt="이메일 인증"
                />
                <span>@</span>
                <input
                  type="text"
                  title="이메일주소"
                  className="mbW2"
                  value={props.emailObj.emailDomain}
                  onChange={evt =>
                    props.onChangeInput(evt, "email", "emailDomain")
                  }
                  onKeyPress={evt => props.onEnterKey(evt)}
                  alt="이메일 인증"
                />
                <label htmlFor="userEm2" className="blind">
                  도메인주소
                </label>
                <select
                  id="userEm2"
                  className="em3"
                  onChange={props.onChangeEmailDomainSelectList}
                >
                  <option value="">직접입력</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="naver.com">naver.com</option>
                  <option value="hanmail.net">hanmail.net</option>
                </select>
                <span
                  className="chk_jnStep2"
                  onClick={evt => props.onClickBtnRequestAuthNum(evt, "email")}
                >
                  인증요청
                </span>
              </p>
            </div>
            <div>
              <label htmlFor="useNum" style={{ float: "left" }}>
                인증번호
              </label>
              <input
                type="text"
                id="useNum"
                placeholder={props.emailObj.placeholder}
                value={props.authNum}
                onChange={props.onChangeAuthNum}
                maxLength="6"
                onKeyPress={evt => props.onEnterKey(evt)}
                alt="이메일 인증 완료"
                style={{ float: "left" }}
              />
              <div
                className="comp_btn email_fnd_btn"
                onClick={evt => props.onClickBtnRequestAuth(evt, "email")}
              >
                아이디 찾기
              </div>
              <em>* 이메일 주소 입력 후 인증요청 버튼을 선택해 주세요. </em>
            </div>
          </fieldset>
        </form>
      </div>
      <p className="txt">
        * 회원가입 시 선택사항으로 이메일 정보를 추가로 입력한 고객만 이메일로
        아이디 찾기가 가능합니다.
      </p>
    </div>
  </div>
);

export { ContFindEmailComponent, ContFindCellComponent };
