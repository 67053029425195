import React from 'react';

const HitterComponent = props => (
    <div className="rankingTeam">
        <section>
            <h6><span className="blind">타자 역대성적</span></h6>
            {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
            <div className="table_Typ01 w15">
                <table>
                    <caption>타자 역대성적</caption>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">년도</th>
                            <th scope="col">경기수</th>
                            <th scope="col">타수</th>
                            <th scope="col">안타</th>
                            <th scope="col">홈런</th>
                            <th scope="col">도루</th>
                            <th scope="col">득점</th>
                            <th scope="col">타율</th>
                            <th scope="col">장타율</th>
                            <th scope="col" className="tbBdRNone">출루율</th>
                            <th scope="col" className="pc">2루타</th>
                            <th scope="col" className="pc">3루타</th>
                            <th scope="col" className="pc">볼넷</th>
                            <th scope="col" className="pc">사구</th>
                            <th scope="col" className="pc">희비</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.list.map((item, idx) =>
                            <tr key={idx}>
                                <td>{item.gyear}</td>
                                <td>{item.gamenum}</td>
                                <td>{item.ab}</td>
                                <td>{item.hit}</td>
                                <td>{item.hr}</td>
                                <td>{item.sb}</td>
                                <td>{item.run}</td>
                                <td>{item.hra}</td>
                                <td>{item.slg}</td>
                                <td className="tbBdRNone">{item.sba}</td>
                                <td className="pc">{item.h2}</td>
                                <td className="pc">{item.h3}</td>
                                <td className="pc">{item.bb}</td>
                                <td className="pc">{item.hp}</td>
                                <td className="pc">{item.sf}</td>
                            </tr>
                        )}
                        {/* <!--  */}
                        {/* <tr>
                            <td colSpan="15">등록된 선수가 없습니다.</td>
                        </tr> */}
                        {/* --> */}
                    </tbody>
                </table>
            </div>
            {/* <!--//table_Typ01 --> */}
        </section>
    </div>
)
export default HitterComponent;