import React, { Component } from "react";

import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import SubHeadAppViewComponent from "../../../../common/SubHeadAppView.component";

class InfoContainer extends Component {
  constructor(props) {
    super(props);
    const subMenu = "champions-field";
    const thirdMenu = "facility-guide";

    const mainIdx = props.mainIdx;
    const subIdx = props.subIdx;
    let title = "";
    let imgLength = 0;
    switch (mainIdx) {
      case 1:
        title = "관람석";
        imgLength = 8;
        break;
      case 2:
        title = "선수단 시설";
        imgLength = 5;
        break;
      // case 3: title = "경기장 내부"; imgLength = 1; break;
      case 4:
        title = "경기장 외부";
        imgLength = 5;
        break;
      case 5:
        title = "팬존";
        imgLength = 10;
        break;
      case 6:
        title = "스카이 박스";
        imgLength = 10;
        break;
      default:
        break;
    }

    let pcSlideList = [];
    let mobileSlideList = [];
    for (let i = 1; i <= imgLength; i++) {
      let imgUrl = `/img/sub/champ_stadium0${mainIdx}_${(i < 10 ? "0" : "") +
        i}.png`;
      console.log(imgUrl);
      pcSlideList.push(imgUrl);
      mobileSlideList.push(imgUrl);
    }
    if (imgLength > 1) {
      for (let i = 1; i <= imgLength; i++) {
        let imgUrl = `/img/sub/champ_stadium0${mainIdx}_${(i < 10 ? "0" : "") +
          i}.png`;
        console.log(imgUrl);
        pcSlideList.push(imgUrl);
      }
    }

    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "시설안내",
      tabNum: 3,

      title,
      mainIdx,
      subIdx,

      pcSlideList,
      mobileSlideList,
      initialSlideNum: subIdx - 1
    };

    console.log(props.match);
  }

  setSliderRef = (key, ref) => {
    console.log("setSliderRef", key, ref);
    if (key === "pc-slider") {
      this.pcSlider = ref;
    } else if (key === "nav-slider") {
      this.navSlider = ref;
    } else if (key === "mobile-slider") {
      this.mobileSlider = ref;
    }
  };

  componentDidMount = _ => {
    this.setState({
      pcSlider: this.pcSlider,
      navSlider: this.navSlider,
      mobileSlider: this.mobileSlider
    });
  };

  onClickBtnSlidePrev = _ => {
    this.mobileSlider.slickPrev();
  };

  onClickBtnSlideNext = _ => {
    this.mobileSlider.slickNext();
  };

  render = _ => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <SubHeadAppViewComponent />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        title={this.state.title}
        pcSlideList={this.state.pcSlideList}
        mobileSlideList={this.state.mobileSlideList}
        setSliderRef={this.setSliderRef}
        initialSlideNum={this.state.initialSlideNum}
        pcSlider={this.state.pcSlider}
        navSlider={this.state.navSlider}
        onClickBtnSlidePrev={this.onClickBtnSlidePrev}
        onClickBtnSlideNext={this.onClickBtnSlideNext}
        onClickBtnShowList={this.props.onClickBtnShowList}
      />
    </div>
  );
}

export default InfoContainer;
