import React from 'react';
import Slider from 'react-slick';
import * as ImageHelper from '../../../../helpers/Image.helper';
import ImgComponent from '../../../common/Img.component';
const pcSliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    // asNavFor: '.PcpictoNav'
}

const navSliderOptions = {
    slidesToShow: 6,
    // lazyLoad: true,
    slidesToScroll: 1,
    //dots: false,
    centerMode: false,
    focusOnSelect: true,
    // asNavFor: '.pcPictoFor'
}

const mobileSliderOptions = {
    autoplay: false,
    speed: 500,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
}

// $('.mbPictoSlide').slick({
//     autoplay : false,
//     speed : 500,
//     arrows: true,
//     infinite: true,			
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: false,
//     prevArrow: $('.mbPicoPrev'),
//     nextArrow: $('.mbPicoNext')
// });

const ContDetailComponent = (props) => (
    props.info ?
        <div className="mb_cont pictorial">
            <div className="mb_contIn">
                <div className="pictoView">
                    <div className="picoDate">
                        <p>{props.info.artcTitle}</p>
                        <ul>
                            <li>{props.info.regDate}</li>
                            <li><span className="look"></span>{props.info.viewCnt}</li>
                            <li><span className="good"></span>{props.info.likeCnt}</li>
                        </ul>
                    </div>
                    <div className="pictoSlide pc">
                        <div className="pictoPc">
                            <div className="pictoBig">
                                <Slider
                                    className='pcPictoFor'
                                    ref={slider => props.setSliderRef('pcSlider', slider)}
                                    {...pcSliderOptions}
                                    asNavFor={props.navSlider}>
                                    {props.pcSlideList.map((item, idx) =>
                                        <li key={idx}>
                                            <ImgComponent
                                                imgUrl={item.path}
                                                defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                                                imgName={item.description} />
                                        </li>
                                    )}
                                </Slider>
                                {/* <ul className="pcPictoFor">
                                <li><img src="/img/sub/pictorial_img01.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img02.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img03.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img04.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img05.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img06.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img01.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img02.jpg" alt="" /></li>
                                <li><img src="/img/sub/pictorial_img03.jpg" alt="" /></li>
                            </ul> */}
                            </div>
                            {/* <!-- //big --> */}
                            <div className="pictoSmall">
                                <Slider
                                    className="PcpictoNav"
                                    ref={slider => props.setSliderRef('navSlider', slider)}
                                    {...navSliderOptions}
                                    asNavFor={props.pcSlider}>
                                    {props.navSlideList.map((item, idx) =>
                                        <li key={idx}>
                                            <ImgComponent
                                                imgUrl={item.path}
                                                defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                                                imgName={item.description} />
                                        </li>
                                    )}
                                </Slider>
                            </div>
                            {/* <!-- //small --> */}
                        </div>
                        {/* <!-- // pictoPc --> */}
                    </div>
                    <div className="pictoMb tb">
                        <div>
                            <Slider
                                className='mbPictoSlide'
                                ref={slider => props.setSliderRef('mobileSlider', slider)}
                                {...mobileSliderOptions}
                                asNavFor={props.navSlider}
                            >
                                {props.pcSlideList.map((item, idx) =>
                                    <li key={idx}>
                                        <ImgComponent
                                            imgUrl={item.path}
                                            defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                                            imgName={item.description} />
                                    </li>
                                )}
                            </Slider>
                            <p onClick={evt => props.onClickBtnSliderPrev(evt, 'mobileSlider')} className="mbPicoPrev"><img src="/img/sub/view_prev.png" alt="슬라이드 다음버튼" /></p>
                            <p onClick={evt => props.onClickBtnSliderNext(evt, 'mobileSlider')} className="mbPicoNext"><img src="/img/sub/view_next.png" alt="슬라이드 다음버튼" /></p>
                        </div>
                    </div>

                    <div className="btnView">
                        <div className="btnViewIn">
                            <button onClick={props.onClickBtnList} type="button" className="goList">목록</button>
                            <button onClick={props.onClickBtnLike} type="button" className={(props.checkDidLike ? 'like2' : 'like') + ' heart'}><i></i>{props.info.likeCnt}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : null
)

export default ContDetailComponent;