import React from "react";
import { Link } from "react-router-dom";

const ContStep2Component = (props) => (
  <div className="mb_cont join_step1">
    <div className="mb_contIn">
      {console.log(props.isChild)}
      {props.isChild ? (
        <p className="join_prcs pc_tb">
          <img
            src="/img/sub/join_prcs02_child.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 약관동의 단계"
          />
        </p>
      ) : (
        <p className="join_prcs pc_tb">
          <img
            src="/img/sub/join_prcs02.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 약관동의 단계"
          />
        </p>
      )}
      {props.isChild ? (
        <p className="join_prcs mb">
          <img
            src="/img/sub/mb_join_prcs02_child.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 약관동의 단계"
          />
        </p>
      ) : (
        <p className="join_prcs mb">
          <img
            src="/img/sub/mb_join_prcs02.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 약관동의 단계"
          />
        </p>
      )}
      <form action="/" method="post">
        <fieldset>
          <legend>약관동의</legend>
          <div>
            <h4>
              서비스 이용약관 동의(필수)
              <input
                type="checkbox"
                id="agrSv1"
                checked={props.step2Obj.hasAgreeWithTerms1}
                onChange={(evt) =>
                  props.onChangeCheckboxInStep2(
                    evt,
                    "step2Obj",
                    "hasAgreeWithTerms1"
                  )
                }
              />
              <label htmlFor="agrSv1">
                <span className="pc_tb mb">동의</span>
              </label>
            </h4>

            <div>
              {props.termsObj["terms1Text"].split("\n").map((line, idx) => (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
            <p className="">※ 서비스 이용약관 동의 (필수)에 대해 동의를 거부할 권리가 있으며, 필수 동의를 거부하는 경우 회원가입 및 서비스 이용이 제한됩니다.</p>
          </div>
          {/* <div>
                        <h4>위치정보 이용약관</h4>
                        <div>{
                            props.termsObj['terms2Text'].split('\n').map((line, idx) => (<span key={idx}>{line}<br /></span>))
                        }</div>
                        <p className="pc_tb">※ 이용약관에 대한 동의를 거부할 수 있으며, 미 동의 시 회원가입을 하실 수 없습니다.</p>
                        <p className="chk">
                            <input type="checkbox" id="agrSv2"
                                checked={props.step2Obj.hasAgreeWithTerms2}
                                onChange={evt => props.onChangeCheckboxInStep1(evt, "step2Obj", 'hasAgreeWithTerms2')} />
                            <label htmlFor="agrSv2"><span className="pc_tb">위치정보 이용에 동의합니다.</span><span className="mb">동의</span></label>
                        </p>
                    </div> */}
          <div>
            <h4>
              개인정보 수집 및 이용 동의 (필수)
              <input
                type="checkbox"
                id="agrSv2"
                checked={props.step2Obj.hasAgreeWithTerms2}
                onChange={(evt) =>
                  props.onChangeCheckboxInStep2(
                    evt,
                    "step2Obj",
                    "hasAgreeWithTerms2"
                  )
                }
              />
              <label htmlFor="agrSv2">
                <span className="pc_tb mb">
                  동의
                </span>
              </label>
            </h4>
            <div>
              {props.termsObj["terms2Text"].split("\n").map((line, idx) => {
                let spanStyle = {};
                if (line.includes("##")) {
                  line = line.replace("##", "");
                  spanStyle.fontWeight = "bold";
                }
                if (line.includes("%%")) {
                  line = line.replace("%%", "");
                  spanStyle.fontSize = "20px";
                }
                return (
                  <span key={idx} style={spanStyle}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </div>
            <p className="">※ 개인정보 수집 및 이용 동의 (필수)에 대해 동의를 거부할 권리가 있으며, 필수 동의를 거부하는 경우 회원가입 및 서비스 이용이 제한됩니다.</p>
          </div>
          <div>
            <h4>
              마케팅 및 광고 활용 동의 (<span style={{color: "#e0242b", marginLeft: 0}}>선택</span>)
              <input
                type="checkbox"
                id="agrSv3"
                name="agrSv3"
                checked={props.step2Obj.hasAgreeWithTerms3}
                onChange={(evt) =>
                  props.onChangeCheckboxInStep2(
                    evt,
                    "step2Obj",
                    "hasAgreeWithTerms3"
                  )
                }
              />
              <label htmlFor="agrSv3">
                <span className="pc_tb mb">
                  동의
                </span>
              </label>
            </h4>
            <div>
              {props.termsObj["terms3Text"].split("\n").map((line, idx) => (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              ))}
            </div>
            <p className="">※ 마케팅 및 광고 활용에 동의 (선택)에 대해 동의를 거부할 권리가 있으며, 선택 동의를 거부하더라도 회원가입 및 서비스 이용이 가능합니다.</p>
            <p className="chk">

              {/* <input type="checkbox" id="agrSv5" name="agrSv4"
                                checked={props.step2Obj.hasAgreeWithTerms4}
                                onChange={evt => props.onChangeCheckboxInStep1(evt, "step2Obj", 'hasAgreeWithTerms4')} />
                            <label for="agrSv5"><span className="pc_tb">개인정보 제 3자 제공에 동의 합니다.</span><span className="mb">동의</span></label> */}
            </p>
          </div>
          <div className="agrAll">
            <input
              type="checkbox"
              id="agrAll"
              checked={props.step2Obj.hasAgreeWithAllTerms}
              onChange={(evt) =>
                props.onChangeCheckboxInStep2(
                  evt,
                  "step2Obj",
                  "hasAgreeWithAllTerms"
                )
              }
            />
            <label htmlFor="agrAll">
              전체 동의하기(<span style={{color: "#e0242b"}}>선택 동의 포함</span>)
            </label>
          </div>
          <div className="login_btn01">
            <ul className="login_btn01In">
              <li>
                <a href="#" onClick={props.onClickBtnOkStep2}>
                  동의합니다.
                </a>
              </li>
              <li>
                <a href="#" onClick={props.onClickBtnCancelStep2}>
                  동의하지 않습니다.
                </a>
              </li>
            </ul>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
);

export default ContStep2Component;
