import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import GuideContainer from './guide/Guide.container';
import TigersContainer from './tigers/Tigers.container';
import FaqContainer from './faq/Faq.container';
import InquiryContainer from './inquiry/Inquiry.container';
import EventContainer from './event/Event.container';
import SmartOrderContainer from './smart-order/SmartOrder.container';

class TigersMembershipContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}/guide`} component={GuideContainer} />
            <Route path={`${this.props.match.path}/tigers`} component={TigersContainer} />
            <Route exact path={`${this.props.match.path}/faq`} component={FaqContainer} />
            <Route path={`${this.props.match.path}/inquiry`} component={InquiryContainer} />
            <Route exact path={`${this.props.match.path}/event`} component={EventContainer} />
            <Route exact path={`${this.props.match.path}/smart-order`} component={SmartOrderContainer} />
        </Switch>
    )
}

export default TigersMembershipContainer;