import React, { Component } from 'react';

import SubHeadComponent from '../common/SubHead.component';

import ContComponent from './Cont.component';

class ServiceContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subMenu: 'service',
            subMenuName: "서비스이용약관"
        }
    }
    render = _ => (
        <div className="container">
            <SubHeadComponent
                subMenuName={this.state.subMenuName} />
            <ContComponent
                subMenu={this.state.subMenu}
            />
        </div>
    )
}

export default ServiceContainer;