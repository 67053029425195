import React from 'react';
import { Link } from 'react-router-dom';
const ContStep4Component = (props) => (
    <div className="mb_cont join_step4">
        <div className="mb_contIn">
            <p className="join_prcs pc_tb"><img src="../../img/sub/join_prcs04.png" alt="약관동의-정보입력-보호자인증-가입완료 중 가입완료 단계" /></p>
            <p className="join_prcs mb"><img src="../../img/sub/mb_join_prcs04.png" alt="약관동의-정보입력-보호자인증-가입완료 중 가입완료 단계" /></p>
            <div className="box">
                <h3><img src="../../img/sub/login_ci.png" alt="KIA TIGERS" /></h3>
                <p><strong>KIA TIGERS 회원가입이 완료되었습니다.</strong></p>
                <p>KIA TIGERS 서비스를 가입해 주셔서 감사합니다.<br />경기 정보 및 티켓예매, 주차예약 등 다양한 <br />서비스를 편리하게 이용하실 수 있습니다.</p>
                <div className="login_btn01">
                    <ul className="login_btn01In">
                        <li><Link to={"/"}>홈으로 가기</Link></li>
                        <li><Link to={"/login"}>로그인 하기</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
)
export default ContStep4Component;