import React, { Component } from 'react';
import ContComponent from './Cont.component';

import * as CommonHelper from '../../../../../helpers/Common.helper';
import * as DateHelper from '../../../../../helpers/Date.helper';
import * as GameService from '../../../../../services/Game.service';

const pitcherColumnHeaderList = [
    { key: 'teamName', name: '팀명', onSorting: false, isSortable: false, className: '' },
    { key: 'gamenum', name: '경기', onSorting: false, isSortable: true, className: '' },
    { key: 'w', name: '승', onSorting: false, isSortable: true, className: '' },
    { key: 'l', name: '패', onSorting: false, isSortable: true, className: '' },
    { key: 'sv', name: '세', onSorting: false, isSortable: true, className: '' },
    { key: 'hold', name: '홀드', onSorting: false, isSortable: true, className: '' },
    { key: 'inn', name: '이닝', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'tugucount', name: '투구수', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'hit', name: '안타', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'hr', name: '홈런', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'bb', name: '4구', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'hp', name: '사구', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'kk', name: '삼진', onSorting: false, isSortable: true, className: '' },
    { key: 'r', name: '실점', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'er', name: '자책', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'era', name: '평균자책점', onSorting: false, isSortable: true, className: '' },
]

const hitterColumnHeaderList = [
    { key: 'teamName', name: '팀명', onSorting: false, isSortable: true, className: '' },
    { key: 'gamenum', name: '경기', onSorting: false, isSortable: true, className: '' },
    { key: 'ab', name: '타수', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'run', name: '득점', onSorting: false, isSortable: true, className: '' },
    { key: 'hit', name: '안타', onSorting: false, isSortable: true, className: '' },
    { key: 'h2', name: '2타', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'h3', name: '3타', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'hr', name: '홈런', onSorting: false, isSortable: true, className: '' },
    { key: 'rbi', name: '타점', onSorting: false, isSortable: true, className: '' },
    { key: 'sb', name: '도루', onSorting: false, isSortable: true, className: '' },
    { key: 'bb', name: '4구', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'kk', name: '삼진', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'gd', name: '병살', onSorting: false, isSortable: true, className: 'pc' },
    { key: 'hra', name: '타율', onSorting: false, isSortable: true, className: '' },
    { key: 'slg', name: '장타율', onSorting: false, isSortable: true, className: '' },
    { key: 'bra', name: '출루율', onSorting: false, isSortable: true, className: '' }
]


class EachTeamScoreContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearSelectList: DateHelper.getYearSelectList(),

            selectedYearForPitcher: process.env.REACT_APP_SEASON_YEAR,
            sortingKeyForPitcher: null,
            selectedYearForHitter: process.env.REACT_APP_SEASON_YEAR,
            sortingKeyForHitter: null,

            pitcherColumnHeaderList: pitcherColumnHeaderList,
            hitterColumnHeaderList: hitterColumnHeaderList,
            pitcherResult: null,
            pitcherList: [],

            hitterResult: null,
            hitterList: [],
        }
    }

    componentDidMount = async _ => {
        try {
            const pitcherResult = await GameService.getTeamPitcherRankingBySeason(this.state.selectedYearForPitcher);
            const pitcherList = pitcherResult.list;

            const hitterResult = await GameService.getTeamHitterRankingBySeason(this.state.selectedYearForHitter);
            const hitterList = hitterResult.list;

            this.setState({
                pitcherResult,
                pitcherList,

                hitterResult,
                hitterList,
            })
            console.log(pitcherResult)
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    onChangeSelectList = (evt, key) => {
        this.setState({ [key]: evt.target.value });
    }

    onClickBtnSearch = async (evt, type) => {
        if (type === 'pitcher') {
            const pitcherResult = await GameService.getTeamPitcherRankingBySeason(this.state.selectedYearForPitcher);
            const pitcherList = pitcherResult.list;

            for (let i = 0; i < pitcherColumnHeaderList.length; i++) {
                pitcherColumnHeaderList[i]['onSorting'] = false;
            }

            this.setState({
                pitcherResult,
                pitcherList,

                pitcherColumnHeaderList
            })
        } else if (type === 'hitter') {
            const hitterResult = await GameService.getTeamHitterRankingBySeason(this.state.selectedYearForHitter);
            const hitterList = hitterResult.list;

            for (let i = 0; i < hitterColumnHeaderList.length; i++) {
                hitterColumnHeaderList[i]['onSorting'] = false;
            }

            this.setState({
                hitterResult,
                hitterList,

                hitterColumnHeaderList
            })
        }
    }
    onClickBtnSort = async (evt, key, type) => {
        if (type === 'pitcher') {
            let sortingKey = key.toUpperCase();
            const pitcherResult = await GameService.getTeamPitcherRankingBySeason(this.state.selectedYearForPitcher, sortingKey);
            const pitcherList = pitcherResult.list;

            for (let i = 0; i < pitcherColumnHeaderList.length; i++) {
                if (pitcherColumnHeaderList[i]['key'] === key) {
                    pitcherColumnHeaderList[i]['onSorting'] = true;
                } else {
                    pitcherColumnHeaderList[i]['onSorting'] = false;
                }
            }

            this.setState({
                pitcherColumnHeaderList,
                pitcherResult,
                pitcherList
            })
        } else if (type === 'hitter') {
            let sortingKey = key.toUpperCase();
            const hitterResult = await GameService.getTeamHitterRankingBySeason(this.state.selectedYearForPitcher, sortingKey);
            const hitterList = hitterResult.list;

            for (let i = 0; i < hitterColumnHeaderList.length; i++) {
                if (hitterColumnHeaderList[i]['key'] === key) {
                    hitterColumnHeaderList[i]['onSorting'] = true;
                } else {
                    hitterColumnHeaderList[i]['onSorting'] = false;
                }
            }

            this.setState({
                hitterColumnHeaderList,
                hitterResult,
                hitterList
            })
        }
    }

    render = _ => (
      <ContComponent
        yearSelectList={this.state.yearSelectList}

        selectedYearForPitcher={this.state.selectedYearForPitcher}
        selectedYearForHitter={this.state.selectedYearForHitter}

        onChangeSelectList={this.onChangeSelectList}
        onClickBtnSearch={this.onClickBtnSearch}
        onClickBtnSort={this.onClickBtnSort}

        pitcherColumnHeaderList={this.state.pitcherColumnHeaderList}
        hitterColumnHeaderList={this.state.hitterColumnHeaderList}

        pitcherList={this.state.pitcherList}
        hitterList={this.state.hitterList} />
    )
}

export default EachTeamScoreContainer
