import React from "react";
import { Link } from "react-router-dom";

import * as ImageHelper from "../../../helpers/Image.helper";
import ImgComponent from "../../common/Img.component";
import SubMenuComponent from "./SubMenu.component";

const PlayerListContComponent = (props) => (
  <div className="mb_cont sub_cont player">
    <div className="mb_contIn">
      <SubMenuComponent
        subMenu={props.subMenu}
        subMenuName={props.subMenuName}
      />
      <div className="list">
        <div className="srchArea">
          {/*<input*/}
          {/*  placeholder="선수명을 입력해주세요."*/}
          {/*  onChange={(evt) => {*/}
          {/*    props.onChangeSearchPlayerKeyWord(evt);*/}
          {/*  }}*/}
          {/*  onKeyPress={(evt) => {*/}
          {/*    props.onEnterKey(evt);*/}
          {/*  }}*/}
          {/*></input>*/}
          {/*<button*/}
          {/*  type="button"*/}
          {/*  onClick={() => {*/}
          {/*    props.onClickBtnSearchPlayer();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  검색*/}
          {/*</button>*/}
        </div>
        <ul className="clear">
          {props.list.map((item, idx) => (
            <li key={idx}>
              { props.uri !== "/players/military-enlisted" ? (
              <Link to={`${props.uri}/${item.pcode}`}>
                {/*<Link to={"#"} onClick={() => {alert("3월 31일 16시 오픈 예정입니다.")} }>*/}
                <ImgComponent
                  imgUrl={item.playerImg}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListOffImg()}
                  imgName={item.playerName}
                />
                <div className="txt">
                  <ul>
                    <li className="num">NO.{item.backnum}</li>
                    <li className="name">{item.playerName}</li>
                  </ul>
                </div>
              </Link>
              ) : (
                <Link to={`${props.uri}/${item.pcode}`}>
                <ImgComponent
                  imgUrl={item.playerImg}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListOffImg()}
                  imgName={item.playerName}
                />
                <div className="txt">
                <ul>
                <li className="num">NO.{item.backnum}</li>
                <li className="name">{item.playerName}</li>
                </ul>
                </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default PlayerListContComponent;
