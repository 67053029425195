import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"


import SubHeadAppViewComponent from "../../../common/SubHeadAppView.component";

import * as ResourceService from "../../../../services/Resource.service";

class TeamGuideContainer extends Component {
  constructor(props) {
    super(props);

    const subMenu = "champions-field";
    const thirdMenu = "team-guide";
    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "구장안내",
      tabNum: 3,
      imgUrlObj: null
    };
  }


  static contextType = AnalyticsContext;
  
  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "03";
    currentAnalyticsVal.deps4 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;
    
    await CommonService.insertAnalytics(this.context.state);
    const pcResult = await ResourceService.getChampionsFieldInfoImg();
    const mResult = await ResourceService.getChampionsFieldInfoImg(false);

    const imgUrlObj = {
      pc: pcResult.resource.value,
      m: mResult.resource.value
    };

    this.setState({ imgUrlObj });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <SubHeadAppViewComponent />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        imgUrlObj={this.state.imgUrlObj}
      />
    </div>
  );
}

export default TeamGuideContainer;
