import React, { Component } from "react";

import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as QnaService from "../../../../services/Qna.service";
import * as AuthHelper from "../../../../helpers/Auth.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class FormContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    const articleId = this.props.match.params.articleId;

    this.state = {
      subMenu: "inquiry",
      subMenuName: "1:1 문의",
      categorySelectList: [],
      selectedCategoryCode: "",
      articleId,
      title: "",
      contents: "",
      file: null,
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "04";
      currentAnalyticsVal.deps4 = "02";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      let title = "";
      let contents = "";
      let selectedCategoryCode = "";
      if (this.state.articleId) {
        const result = await QnaService.getInfo(this.state.articleId);
        title = result.detail.topicTitle;
        contents = result.detail.topicContents;
        selectedCategoryCode = result.detail.topicType;
      }

      const categoryResult = await QnaService.getCategoryList();
      this.setState({
        categorySelectList: categoryResult.list,
        selectedCategoryCode,
        title,
        contents,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
      if(err.message === "1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.") {
        document.location.href = "/";
      }

      if (err.message == "인증 오류입니다. 로그인을 하신 후에 이용해 주세요") {
        AuthHelper.removeLoginInfo();
        document.location.href = "/login";
      }
    }
  };

  setFileRef = (ref) => {
    this.fileRef = ref;
  };

  onChangeInputTitle = (evt) => {
    this.setState({ title: evt.target.value });
  };
  onChangeTextAreaContents = (evt) => {
    this.setState({ contents: evt.target.value });
  };
  onChangeCategoryList = (evt) => {
    this.setState({ selectedCategoryCode: evt.target.value });
  };

  onClickBtnAddFile = (evt) => {
    this.fileRef.click();
  };

  onChangeFile = (evt) => {
    this.setState({ file: evt.target.files[0] });
  };

  onClickBtnCreate = async (evt) => {
    try {
      if (this.state.title.trim() === "") {
        throw new Error("제목을 입력해주세요.");
      } else if (this.state.selectedCategoryCode.trim() === "") {
        throw new Error("분류를 선택해주세요.");
      } else if (this.state.contents.trim() === "") {
        throw new Error("내용을 입력해주세요.");
      }

      const result = await QnaService.createInfo(
        this.state.title,
        this.state.contents,
        this.state.selectedCategoryCode,
        this.state.file
      );
      alert("문의가 정상적으로 등록되었습니다.");
      this.props.history.goBack();
    } catch (err) {
      alert(err.message);
      if(err.message === "1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.") {
        document.location.href = "/";
      }
    }
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <ContComponent
        list={this.state.list}
        tabNum={this.state.tabNum}
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        file={this.state.file}
        setFileRef={this.setFileRef}
        onClickBtnAddFile={this.onClickBtnAddFile}
        onClickBtnCreate={this.onClickBtnCreate}
        onChangeFile={this.onChangeFile}
        selectedCategoryCode={this.state.selectedCategoryCode}
        categorySelectList={this.state.categorySelectList}
        onChangeCategoryList={this.onChangeCategoryList}
        title={this.state.title}
        onChangeInputTitle={this.onChangeInputTitle}
        contents={this.state.contents}
        onChangeTextAreaContents={this.onChangeTextAreaContents}
      />
    </div>
  );
}

export default FormContainer;
