import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ResourceService from "../../../services/Resource.service";
import * as CommonService from "../../../services/Common.service";
import AnalyticsContext from "../../../context/AnalyticsContext";
import * as AuthHelper from '../../../helpers/Auth.helper'
const baseUrl =
  process.env.REACT_APP_TICKET_URL +
  "/facility/direct/member/reserve?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=";
class ReservationContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    this.state = {
      subMenu: "season-pass",
      subMenuName: "시즌권 안내",
      imgUrlObj: null,
    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "05";
    currentAnalyticsVal.deps3 = "02";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    const pcResult = await ResourceService.getSeasonPassImg();
    const mResult = await ResourceService.getSeasonPassImg(false);

    const imgUrlObj = {
      pc: pcResult.resource.value,
      m: mResult.resource.value,
    };

    this.setState({ imgUrlObj });
  };
  onClickBtnReservation = async (_) => {
    console.log("!!");
    try {
      if (!AuthHelper.checkHasLogin()) {
        throw new Error("로그인 이후 이용 가능합니다.");
      } else if (!AuthHelper.checkHasRealName()) {
        this.setState({ isOnShowPopup: true });
        return false;
      }
      /* 입장권 판매기간이 되었을 때 주석 제거. */

      const result = await CommonService.getEncryptedId();
      const encryptedStr = result.encryptedStr;

      const url = `${baseUrl}${encryptedStr}`;

      window.open(url, "");

      // alert("티켓 예매는 금일 17:00 부터 가능합니다.");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  onClickBtnRedirectStore = (_) => {
    window.open('/app', "");
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      {process.env.REACT_APP_PREPARING_SEASON_TICKET_PAGE_YN  === "Y" ? (
        <div className="mb_cont intro1">
          <div className="mb_contIn">
            <div style={{ marginTop: "50px" }}>
              <img
                  src="/img/seasonstop_2024_pc.png"
                  alt="season_off_pc"
                  className="pc"
                  />
                <img
                  src="/img/seasonstop_2024_mobile.png"
                  alt="season_off_mb"
                  className="greeting_mb tb"
                />
              </div>
            </div>
          </div>
      ) : (
      <ContComponent imgUrlObj={this.state.imgUrlObj} 
        onClickBtnReservation={this.onClickBtnReservation} 
        onClickBtnRedirectStore={this.onClickBtnRedirectStore}/>
      )}
     </div>
  );
}

export default ReservationContainer;
