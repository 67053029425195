import React from "react";

const GuideTabComponent = (props) => (
  <div className="mb_contIn">
    <ul className="tab02 w2 clear">
      <li className={props.tabNum === 1 ? "on" : ""}>
        <a onClick={(evt) => props.onClickBtnChangeMembership(evt, 1)}>
          멤버십
        </a>
      </li>
      <li className={props.tabNum === 2 ? "on" : ""}>
        <a onClick={(evt) => props.onClickBtnChangeMembership(evt, 2)}>
          갸린이클럽
        </a>
      </li>
    </ul>
  </div>
);

export default GuideTabComponent;
