import React from "react";
import { Link } from "react-router-dom";

import * as ImageHelper from "../../../helpers/Image.helper";
import ImgComponent from "../../common/Img.component";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont game msrView">
    <div className="mb_contIn">
      <section className="section01">
        <CurrentGameInfoComponent {...props} />
        <ScoreBoardComponent {...props} />
      </section>
      <section className="section02 clear">
        <OtherTeamInfoComponent {...props} />
        <EntryPitcherInfoComponent {...props} />
      </section>
      <ExpiredEntryStatusComponent {...props} />
      <section className="section04 clear">
        <article className="w50p mb100p">
          <h3 className="arcTit">
            타이거즈 뉴스
            <Link to="/contents/news" className="link_more">
              더보기 +
            </Link>
          </h3>
          <div className="bd_thumbList bdtRed">
            <ul>
              {props.newsList.map((item, idx) => (
                <li key={idx}>
                  <Link to={`/contents/news/${item.artcSeq}`} className="clear">
                    <p className="thumb">
                      <ImgComponent
                        imgUrl={item.imgFilePath}
                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                        imgName={item.artcTitle}
                      />
                    </p>
                    <p>
                      <strong>{item.artcTitle}</strong>
                      <em className="date">{item.regDate}</em>
                      <em className="cnt">{item.viewCnt}</em>
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </article>
        <article className="w50p mb100p">
          <h3 className="arcTit">
            생생화보
            <Link to="/game/pictures" className="link_more">
              더보기 +
            </Link>
          </h3>
          <div className="bd_galList bdtRed">
            <ul className="clear fl">
              {props.picturesList.map((item, idx) => (
                <li key={idx}>
                  <a
                    onClick={(evt) =>
                      props.onClickBtnPicture(evt, item.artcSeq)
                    }
                  >
                    <ImgComponent
                      imgUrl={item.imgFilePath}
                      defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                      imgName={item.artcSubTitle}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </article>
      </section>
      {/*
            {props.isEndGame ?
                <GameRecordComponent {...props} />
                : null} */}
    </div>
  </div>
);

const CurrentGameInfoComponent = (props) =>
  props.currentGameInfo ? (
    <div className="gameSchedule">
      <div className="inner">
        <p className="date">
          {props.currentGameInfo.formattedGameDate} (
          {props.currentGameInfo.gweek})
        </p>
        <div className="clear">
          <div className="teamOn teamL">
            <figure>
              <p className="emble">
                <img
                  src={ImageHelper.getEmblemImgByTeamCode(
                    props.currentGameInfo.visitKey
                  )}
                  alt={props.currentGameInfo.visit}
                />
              </p>
              <figcaption>{props.currentGameInfo.visit}</figcaption>
            </figure>
            {props.currentGameInfo.vname ? (
              <p className="info">
                {props.currentGameInfo.endFlag === "1" &&
                  props.currentGameInfo.hscore !==
                  props.currentGameInfo.vscore && (
                    <span
                      className={
                        (props.currentGameInfo.hscore <
                        props.currentGameInfo.vscore
                          ? "win"
                          : "lose") + " WoL"
                      }
                    ></span>
                  )}
                {props.currentGameInfo.vname}
              </p>
            ) : props.awayEntryPitcherInfo.playerName ? (
              <p className="info">
                선발 : {props.awayEntryPitcherInfo.playerName}
              </p>
            ) : props.currentGameInfo.cancelFlag !== "1" ? (
              <p className="info">선발 발표 전</p>
            ) : null}
          </div>
          <div className="gameOn clear">
            <strong
              className={
                "score teamL" +
                (props.currentGameInfo.visitKey !== "HT" ? " op6" : " ")
              }
            >
              {props.scoreBoard[0].run}
            </strong>
            <p>
              <span>{props.currentGameInfo.stadium}</span>
              <span>{props.currentGameInfo.gtime}</span>
            </p>
            <strong
              className={
                "score teamR" +
                (props.currentGameInfo.homeKey !== "HT" ? " op6" : " ")
              }
            >
              {props.scoreBoard[1].run}
            </strong>
            <span className="bdRndBox now">
              {props.currentGameInfo.status === 3
                ? "경기 종료"
                : props.currentGameInfo.status === 2
                  ? "경기 중"
                  : props.currentGameInfo.cancelFlag === "1"
                    ? "경기 취소"
                    : "경기 전"}
            </span>
          </div>
          <div className="teamOn teamR">
            <figure>
              <p className="emble">
                <img
                  src={ImageHelper.getEmblemImgByTeamCode(
                    props.currentGameInfo.homeKey
                  )}
                  alt={props.currentGameInfo.home}
                />
              </p>
              <figcaption>{props.currentGameInfo.home}</figcaption>
            </figure>
            {/* 이름이 없을경우 선발 발표 전 */}
            {props.currentGameInfo.hname ? (
              <p className="info">
                {props.isEndGame === true &&
                  props.currentGameInfo.hscore !==
                  props.currentGameInfo.vscore && (
                    <span
                      className={
                        (props.currentGameInfo.hscore >
                        props.currentGameInfo.vscore
                          ? "win"
                          : "lose") + " WoL"
                      }
                    ></span>
                  )}
                {props.currentGameInfo.hname}
              </p>
            ) : props.homeEntryPitcherInfo.playerName ? (
              <p className="info">
                선발 : {props.homeEntryPitcherInfo.playerName}
              </p>
            ) : props.currentGameInfo.cancelFlag !== "1" ? (
              <p className="info">선발 발표 전</p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;

const ScoreBoardComponent = (props) =>
  props.scoreBoard ? (
    <div className="gameScoreBox">
      <div className="inner">
        <table className="table_score">
          <caption>경기 진행현황 표</caption>
          <colgroup>
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <col width="5%" />
                <col width="5%" />
                <col width="5%" />
              </>
            )}
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
            <col width="5%" />
          </colgroup>
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">1</th>
            <th scope="col">2</th>
            <th scope="col">3</th>
            <th scope="col">4</th>
            <th scope="col">5</th>
            <th scope="col">6</th>
            <th scope="col">7</th>
            <th scope="col">8</th>
            <th scope="col">9</th>
            <th scope="col">10</th>
            <th scope="col">11</th>
            <th scope="col">12</th>
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <th scope="col">13</th>
                <th scope="col">14</th>
                <th scope="col">15</th>
              </>
            )}
            <th scope="col">R</th>
            <th scope="col">H</th>
            <th scope="col">E</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">{props.currentGameInfo.visit}</th>
            <td>{props.scoreBoard[0]["score1"]}</td>
            <td>{props.scoreBoard[0]["score2"]}</td>
            <td>{props.scoreBoard[0]["score3"]}</td>
            <td>{props.scoreBoard[0]["score4"]}</td>
            <td>{props.scoreBoard[0]["score5"]}</td>
            <td>{props.scoreBoard[0]["score6"]}</td>
            <td>{props.scoreBoard[0]["score7"]}</td>
            <td>{props.scoreBoard[0]["score8"]}</td>
            <td>{props.scoreBoard[0]["score9"]}</td>
            <td>{(props.scoreBoard[0]["score10"] === "" || props.scoreBoard[0]["score10"] === undefined) ? "-" : props.scoreBoard[0]["score10"]}</td>
            <td>{(props.scoreBoard[0]["score11"] === "" || props.scoreBoard[0]["score11"] === undefined) ? "-" : props.scoreBoard[0]["score11"]}</td>
            <td>{(props.scoreBoard[0]["score12"] === "" || props.scoreBoard[0]["score12"] === undefined) ? "-" : props.scoreBoard[0]["score12"]}</td>
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <td>{props.scoreBoard[0]["score13"] === "" ? "-" : props.scoreBoard[0]["score13"]}</td>
                <td>{props.scoreBoard[0]["score14"] === "" ? "-" : props.scoreBoard[0]["score14"]}</td>
                <td>{props.scoreBoard[0]["score15"] === "" ? "-" : props.scoreBoard[0]["score15"]}</td>
              </>
            )}
            <td>{props.scoreBoard[0]["run"]}</td>
            <td>{props.scoreBoard[0]["hit"]}</td>
            <td>{props.scoreBoard[0]["error"]}</td>
          </tr>
          <tr>
            <th scope="row">{props.currentGameInfo.home}</th>
            <td>{props.scoreBoard[1]["score1"]}</td>
            <td>{props.scoreBoard[1]["score2"]}</td>
            <td>{props.scoreBoard[1]["score3"]}</td>
            <td>{props.scoreBoard[1]["score4"]}</td>
            <td>{props.scoreBoard[1]["score5"]}</td>
            <td>{props.scoreBoard[1]["score6"]}</td>
            <td>{props.scoreBoard[1]["score7"]}</td>
            <td>{props.scoreBoard[1]["score8"]}</td>
            <td>{props.scoreBoard[1]["score9"]}</td>
            <td>{(props.scoreBoard[1]["score10"] === "" || props.scoreBoard[1]["score10"] === undefined) ? "-" : props.scoreBoard[1]["score10"]}</td>
            <td>{(props.scoreBoard[1]["score11"] === "" || props.scoreBoard[1]["score11"] === undefined) ? "-" : props.scoreBoard[1]["score11"]}</td>
            <td>{(props.scoreBoard[1]["score12"] === "" || props.scoreBoard[1]["score12"] === undefined) ? "-" : props.scoreBoard[1]["score12"]}</td>
            {props.scoreBoard[0]["score13"] !== undefined && (
              <>
                <td>{props.scoreBoard[1]["score13"] === "" ? "-" : props.scoreBoard[1]["score13"]}</td>
                <td>{props.scoreBoard[1]["score14"] === "" ? "-" : props.scoreBoard[1]["score14"]}</td>
                <td>{props.scoreBoard[1]["score15"] === "" ? "-" : props.scoreBoard[1]["score15"]}</td>
              </>
            )}
            <td className="runHitError">{props.scoreBoard[1]["run"]}</td>
            <td className="runHitError">{props.scoreBoard[1]["hit"]}</td>
            <td className="runHitError">{props.scoreBoard[1]["error"]}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null;

const OtherTeamInfoComponent = (props) => (
  <article className="w50p mb100p otherGames">
    <h3 className="arcTit">타 구단 경기결과</h3>
    {props.otherTeamInfoList.length > 0 ? (
      <table className="bdtRed trBdb">
        <caption>타 구단 경기결과 표</caption>
        <colgroup>
          <col width="33.333%" />
          <col width="11.111%" />
          <col width="11.111%" />
          <col width="11.111%" />
          <col width="33.333%" />
        </colgroup>
        <tbody>
        {props.otherTeamInfoList.map((item, idx) => (
          <tr key={idx}>
            <td scope="col" className="tar">
              <span className="mgr15">{item.visit}</span>
              <span className="emble">
                  <img src={item.awayEmblemImgUrl} alt={item.visit} />
                </span>
            </td>
            <td>{item.vscore}</td>
            <td className="vs">VS</td>
            <td>{item.hscore}</td>
            <td className="tal">
                <span className="emble mgr15">
                  <img src={item.homeEmblemImgUrl} alt="두산 엠블럼" />
                </span>
              <span>{item.home}</span>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    ) : (
      <p className="noList">타 구단 경기가 존재하지 않습니다.</p>
    )}
  </article>
);

const EntryPitcherInfoComponent = (props) =>
  props.awayEntryPitcherInfo && props.homeEntryPitcherInfo ? (
    <article className="w50p mb100p startingPitcher">
      <h3 className="arcTit">선발투수 안내</h3>
      <table className="bdtRed">
        <caption>선발투수 안내 표</caption>
        <colgroup>
          <col width="33.333%" />
          <col width="33.333%" />
          <col width="33.333%" />
        </colgroup>
        <thead>
        <tr>
          <th scope="col" className="tar">
              <span className="emble mgr15">
                <img
                  src={props.awayEmblemImgUrl}
                  alt={props.awayTeamName + " 엠블럼"}
                />
              </span>
            <span>{props.awayEntryPitcherInfo.playerName}</span>
          </th>
          <th scope="col" className="vs">
            vs
          </th>
          <th scope="col" className="tal">
              <span className="mgr15">
                {props.homeEntryPitcherInfo.playerName}
              </span>
            <span className="emble">
                <img
                  src={props.homeEmblemImgUrl}
                  alt={props.homeTeamName + " 엠블럼"}
                />
              </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td scope="row" className="tar">
            {props.awayEntryPitcherInfo.gamenum}G&nbsp;
            {props.awayEntryPitcherInfo.wtotal}승&nbsp;
            {props.awayEntryPitcherInfo.ltotal}패&nbsp;
            {props.awayEntryPitcherInfo.svtotal}세&nbsp;
          </td>
          <td>성적</td>
          <td className="tal">
            {props.homeEntryPitcherInfo.gamenum}G&nbsp;
            {props.homeEntryPitcherInfo.wtotal}승&nbsp;
            {props.homeEntryPitcherInfo.ltotal}패&nbsp;
            {props.homeEntryPitcherInfo.svtotal}세&nbsp;
          </td>
        </tr>
        <tr>
          <td scope="row" className="tar">
            {props.awayEntryPitcherInfo.eratotal}
          </td>
          <td>방어율</td>
          <td className="tal">{props.homeEntryPitcherInfo.eratotal}</td>
        </tr>
        <tr>
          <td scope="row" className="tar">
            {props.awayEntryPitcherInfo.kk}
          </td>
          <td>탈삼진</td>
          <td className="tal">{props.homeEntryPitcherInfo.kk}</td>
        </tr>
        <tr>
          <td scope="row" className="tar">
            {props.awayEntryPitcherInfo.w}승&nbsp;
            {props.awayEntryPitcherInfo.l}패&nbsp;
            {props.awayEntryPitcherInfo.sv}세&nbsp;
          </td>
          <td>상대팀 성적</td>
          <td className="tal">
            {props.homeEntryPitcherInfo.w}승&nbsp;
            {props.homeEntryPitcherInfo.l}패&nbsp;
            {props.homeEntryPitcherInfo.sv}세&nbsp;
          </td>
        </tr>
        <tr>
          <td scope="row" className="tar">
            {props.awayEntryPitcherInfo.era}
          </td>
          <td>상대팀 방어율</td>
          <td className="tal">{props.homeEntryPitcherInfo.era}</td>
        </tr>
        </tbody>
      </table>
    </article>
  ) : null;

const ExpiredEntryStatusComponent = (props) =>
  Object.keys(props.expiredEntryStatusListObj).length > 0 ? (
    <section className="section03">
      <article className="w100p">
        <h3 className="arcTit">
          엔트리 변동사항
          <Link to="/players/entry-status?tabNum=4" className="link_more">
            더보기 +
          </Link>
        </h3>
        <div className="table_Typ01">
          <table>
            <caption>엔트리 변동사항 표</caption>
            <colgroup>
              <col width="33.333%" />
              <col width="33.333%" />
              <col width="33.333%" />
            </colgroup>
            <tbody>
            {Object.keys(props.expiredEntryStatusListObj).map((key, idx) => (
              <tr key={idx}>
                <td>{key}</td>
                <td>{props.expiredEntryStatusListObj[key]["regY"]}</td>
                <td>{props.expiredEntryStatusListObj[key]["regN"]}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </article>
    </section>
  ) : null;
export default ContComponent;
