import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import IntroContainer from "./intro/Intro.container";
import EmblemContainer from "./emblem/Emblem.container";
import UniformContainer from "./uniform/Uniform.container";
import MascotContainer from "./mascot/Mascot.container";

class BiContainer extends Component {
  render = () => (
    <Switch>
      <Route
        path={`${this.props.match.path}/intro`}
        component={IntroContainer}
      />
      <Route
        path={`${this.props.match.path}/emblem`}
        component={EmblemContainer}
      />
      <Route
        path={`${this.props.match.path}/uniform`}
        component={UniformContainer}
      />
      <Route
        path={`${this.props.match.path}/mascot`}
        component={MascotContainer}
      />

      <Redirect
        from={`${this.props.match.path}`}
        to={`${this.props.match.path}/intro`}
      />
    </Switch>
  );
}
export default BiContainer;
