import React, { Component } from 'react';

import { Route, Switch, Link } from 'react-router-dom';

import PrivacyContainer from './privacy/Privacy.container';
import ServiceContainer from './service/Service.container';
import OperationContainer from './operation/Operation.container';
import EmailContainer from './email/Email.container';


class TermsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabNum: 1
        }
    }

    onClickBtnTab = (evt, tabNum) => {
        this.setState({ tabNum });
    }

    render = _ => (
        <Switch>
            <Route path={`${this.props.match.path}/privacy`}
                render={props => <PrivacyContainer {...props} />} />
            <Route path={`${this.props.match.path}/service`}
                render={props => <ServiceContainer {...props} />} />
            <Route path={`${this.props.match.path}/operation`}
                render={props => <OperationContainer {...props} />} />
            <Route path={`${this.props.match.path}/email`}
                render={props => <EmailContainer {...props} />} />
        </Switch>
    )
}

export default TermsContainer;