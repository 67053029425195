import React, { useState } from "react";
import { Link } from "react-router-dom";

const GoodsAuctionTab = ({ children }) => {
  return (
    <div className="goods-auction-tab">
      <div className="sbHead_appView tb" />
      <div className="sub_cont game msr tabLeft">
        <div className="mb_contIn">
          <div className="tabWrap">
            <ul className="tab01 clear">
              <li
                className={
                  window.location.pathname.startsWith("/auction/goods-list")
                    ? "on"
                    : ""
                }
              >
                <Link to="/auction/goods-list">경매목록</Link>
              </li>
              <li
                className={
                  window.location.pathname.startsWith("/auction/my-bid-list")
                    ? "on"
                    : ""
                }
              >
                <Link to="/auction/my-bid-list">내 입찰목록</Link>
              </li>
            </ul>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default GoodsAuctionTab;
