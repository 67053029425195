import React from 'react';

const ContComponent = props => (
    <div className="mb_cont membership">
        <div className="mb_contIn inqWrite">
            <h6 className="h6Bar">문의하기</h6>
            <form action="/" method="post" className="bdWrite">
                <fieldset>
                    <legend>회원가입양식</legend>
                    <ul>
                        <li><label htmlFor="tit">제목</label></li>
                        <li className="tit">
                            <input type="text" id="tit" placeholder="제목을 입력해주세요."
                                onChange={props.onChangeInputTitle}
                                value={props.title}
                                maxLength='35' />
                            <p><strong>{props.title.length}</strong>/<span>{35}</span></p>
                        </li>
                    </ul>
                    <ul>
                        <li><label htmlFor="catego">분류</label></li>
                        <li className="cate">
                            <select id="catego"
                                value={props.selectedCategoryCode}
                                onChange={props.onChangeCategoryList}>
                                <option value=''>분류를 선택해주세요</option>
                                {props.categorySelectList.map((item, idx) =>
                                    <option key={idx} value={item.catCode}>{item.catTitle}</option>
                                )}
                            </select>
                        </li>
                    </ul>
                    <ul className="txt">
                        <li><label htmlFor="textfield">내용</label></li>
                        <li>
                            <textarea cols="30" id="textfield"
                                placeholder="문의 글이 많아 답변에 시간이 소요될 수 있습니다. 우천 취소, 예매취소/환불, 멤버십 무료예매권 등과 관련한 문의 사항은 FAQ를 통해 확인하실 수 있으니 FAQ를 먼저 확인해 주세요."
                                onChange={props.onChangeTextAreaContents}
                                value={props.contents}
                            ></textarea>
                        </li>
                    </ul>
                </fieldset>
                {props.file && <div style={{ margin: "10px" }}>첨부 파일 : {props.file.name}</div>}
            </form>

            <div className="inQBtn clear">
                <input type='file'
                    accept='image/*'
                    style={{ display: "none" }}
                    value=''
                    ref={ref => props.setFileRef(ref)}
                    onChange={props.onChangeFile}
                />
                <button onClick={props.onClickBtnAddFile} type="file" className="fileAdd">파일추가</button>
                <button onClick={props.onClickBtnCreate} type="button"><em className="myQ"></em>등록하기</button>

            </div>
        </div>
        {/* <!-- //mb_contIn --> */}
    </div>
)

export default ContComponent;
