import React, { Component } from "react";

import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import SubHeadAppViewComponent from "../../../../common/SubHeadAppView.component";

class ListContainer extends Component {
  constructor(props) {
    super(props);
    const subMenu = "champions-field";
    const thirdMenu = "facility-guide";
    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "시설안내",
      tabNum: 3
    };
  }

  render = _ => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <SubHeadAppViewComponent />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        onClickBtnImg={this.props.onClickBtnImg}
      />
    </div>
  );
}

export default ListContainer;
