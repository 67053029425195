import React from "react";

const DetailProfileComponent = (props) => (

  <dl className={props.subTabNum === 3 ? "on" : ""}>
    <dt
      className={props.subTabNum === 3 ? "act" : ""}
      onClick={(evt) => {
        props.onClickBtnSubTab(evt, 3);
      }}
    >
      상세프로필
    </dt>
    <dd className="prf">
      <ul className="prfBg">
        <li></li>
        <li></li>
      </ul>
      <dl>
        <dt>생년월일</dt>
        <dd>
          {props.info.birth.substr(0, 4) + "년"}
          {props.info.birth.substr(4, 2) + "월"}
          {props.info.birth.substr(6, 2) + "일"}
        </dd>
        <dt>신장/체중</dt>
        {/* <dd>{props.info.height} / {props.info.weight}</dd> */}
        <dd>{props.info.heightWeight}&nbsp;</dd>
        <dt>투타</dt>
        <dd>{props.info.hittype}&nbsp;</dd>
        <dt>출신학교</dt>
        <dd>{props.info.career}&nbsp;</dd>
        <dt>프로입단</dt>
        <dd>
          {props.info.indate.substr(0, 4) +
            "년 " +
            props.info.indate.substr(4, 6) +
            "월"}
          &nbsp;
        </dd>
        <dt>연봉</dt>
        <dd>{props.info.money.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}&nbsp;</dd>
        <dt>입대/제대</dt>
        <dd>{props.info.armyment}&nbsp;</dd>
      </dl>
      <dl>
        <dt>경력사항</dt>
        <dd>
          {props.info.pro.split(" - ").map((line, idx) => {
            return (
              <span key={"career" + idx}>
                {line}
                <br />
              </span>
            );
          })}{" "}
          <br />{" "}
          {props.info.maincareer.split(" - ").map((line, idx) => {
            return (
              <span key={"maincareer" + idx}>
                {line}
                <br />
              </span>
            );
          })}
        </dd>
      </dl>
    </dd>
  </dl>
);

export default DetailProfileComponent;
