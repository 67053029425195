import * as DateHelper from './Date.helper';

export const getPositionInitialByType = type => {
    switch (type) {
        case 'pitcher': return '투';
        case 'catcher': return '포';
        case 'infielder': return '내';
        case 'outfielder': return '외';
        case 'military-enlisted': return '군';
        default: return '';
    }
}

export const makeExpiredEntryObj = list => {
    let listObj = {};
    for (let i = 0; i < list.length; i++) {
        const info = list[i];
        const regYnDate = DateHelper.makeSeparatedDate(info['regYnDate']);
        if (!listObj[regYnDate]) {
            listObj[regYnDate] = {
                regY: '',
                regN: ''
            };
        }
        if (info['regYn'] === 'Y') {
            listObj[regYnDate]['regY'] += `, ${info['playerName']}`
        }
        if (info['regYn'] === 'N') {
            listObj[regYnDate]['regN'] += `, ${info['playerName']}`
        }
    }

    Object.keys(listObj).map((key, idx) => {
        listObj[key]['regY'] = listObj[key]['regY'].length > 0 ? listObj[key]['regY'].substr(1, (listObj[key]['regY'].length - 1)) : listObj[key]['regY'];
        listObj[key]['regN'] = listObj[key]['regN'].length > 0 ? listObj[key]['regN'].substr(1, (listObj[key]['regN'].length - 1)) : listObj[key]['regN'];
    });

    return listObj;
}

export const makeResultStr = (wls) => {
    let resultStr = '';
    switch (wls) {
        case "W": resultStr = "승"; break;
        case "L": resultStr = "패"; break;
        // case "D": resultStr = "무"; break;
        // case "": resultStr = "무"; break;
        case "S": resultStr = "세"; break;
        // case "H": resultStr = "홀드"; break;
        default: break;
    }
    return resultStr;
}

export const checkIsEntryPitcher = (changeinn) => {
    if (!changeinn) {
        return true;
    } else if (changeinn === '') {
        return true;
    }
    return false;
}