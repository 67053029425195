import React from 'react';
import { Link } from 'react-router-dom';

const SubMenuComponent = (props) => (
    <div className="tabWrap">
        <ul className="tab01 clear">
            <li className={props.thirdMenu === 'episode' ? "on" : ""}>
                <Link to="/contents/archive/episode">타이거즈 에피소드</Link>
            </li>
            <li className={props.thirdMenu === 'webzine' ? "on" : ""}>
                <Link to="/contents/archive/webzine">타이거즈 웹진</Link>
            </li>
        </ul>
    </div>
)

export default SubMenuComponent;