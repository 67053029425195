import React from 'react';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../common/Pagination.component';

const ContComponent = props => (
  <div className="mb_cont membership inqList">
      <div className="mb_contIn ">
          <div className="board">
              {/* <!-- srchArea --> */}
              <div className="srchArea">
                  <input type="text" placeholder="검색어를 입력해주세요"
                         onChange={props.onChangeInput}
                         value={props.searchKeyword} />
                  <button type="button"
                          onClick={props.onClickBtnSearch}>검색</button>
              </div>
              {/* <!-- //srchArea --> */}
              <div className=" inQBtn clear">
                  {/* {props.isMineYn === 'N' ?
                        <button onClick={props.onClickBtnToggleType} type="button">
                            <em className="myQ"></em>내 문의 보기
                    </button>
                        :
                        <button onClick={props.onClickBtnToggleType} type="button">
                            <em className="myQ"></em>모든 문의보기
                    </button>} */}
                  <button onClick={props.onClickBtnWrite} type="button"><em className="q"></em>문의하기</button>
              </div>

              {/* <!-- board -->			 */}
              <div className="bdList">
                  <ul>
                      {props.list.map((item, idx) =>
                        <li key={idx} className="clear">
                            <p className="num">
                                {item.replyYn === "Y" ?
                                  <span className="repl">답변완료</span>
                                  :
                                  <span className="replNone">미답변</span>
                                }
                            </p>
                            <p className="sbj">
                                <Link to={`/membership/inquiry/${item.topicSeq}`}>{item.topicTitle}</Link>
                                <span className="ico">
                                        <i className="replNum">[{item.replyCnt}]</i>
                                    </span>
                            </p>
                            <p className="info clear">
                                {/* <span>{item.simpleId}</span> */}
                                <span className="date">
                                        {item.regDate}
                                    <em className="time">{item.regTime}</em>
                                    </span>
                            </p>
                            {/* <p className="info clear">
                                    <span>id</span>
                                    <span className="date">2019.11.05<em className="time">14:03</em></span>
                                </p> */}
                        </li>
                      )}
                  </ul>
              </div>
              {/* <!-- //bdList --> */}
              <PaginationComponent
                paginationObj={props.paginationObj}
                onClickBtnPageNum={props.onClickBtnPageNum} />
          </div>
          {/* <!-- //board --> */}
      </div>
      {/* <!-- //mb_contIn --> */}
  </div>
)

export default ContComponent;
