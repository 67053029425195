import React from 'react';

const ContComponent = (props) => (
    <div className="rankingTeam">
        <section>
            <h6>투수성적</h6>
            <div className="srch pc">
                <p className="btn_srch" style={{ cursor: "pointer" }}
                    onClick={evt => props.onClickBtnSearch(evt, 'pitcher')}>검색</p>
                <p>
                    <label htmlFor="cell" className="blind">년도</label>
                    <select id="cell"
                        defaultValue={props.selectedYearForPitcher}
                        onChange={evt => props.onChangeSelectList(evt, 'selectedYearForPitcher')}>
                        {props.yearSelectList.map((item, idx) =>
                            <option key={idx} value={item.value}>{item.text}</option>
                        )}
                    </select>
                </p>
            </div>
            {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
            {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
            {props.pitcherList.length > 0 ?
                <div className="mbTable mb">
                    <ul>
                        <li>팀명</li>
                        {props.pitcherList.map((item, idx) =>
                            <li key={idx}>{item.teamName}</li>
                        )}
                    </ul>
                </div>
                : null}
            {/* <!--//mbTable --> */}
            <div className={props.pitcherList.length === 0 ? "noPlayer" : "table_Typ01 mbScroll thFix1 w16 mbH1wM}"}>
                {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
                {props.pitcherList.length === 0 ?
                    <table>
                        <caption>투수성적 현황표</caption>
                        <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td colSpan="16">등록된 선수가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <table>
                        <caption>투수성적 현황표</caption>
                        <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                {props.pitcherColumnHeaderList.map((item, idx) =>
                                    item.isSortable ?
                                        <th key={idx}
                                            onClick={evt => props.onClickBtnSort(evt, item.key, 'pitcher')}
                                            className={item.className}
                                            scope='col'>
                                            {item.name}
                                            <span className={'downArow'}></span>
                                        </th>
                                        :
                                        <th key={idx} scope='col' className={item.className}>{item.name}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {props.pitcherList.map((item, idx) =>
                                <tr key={idx}>
                                    <th scope="row">{item.teamName}</th>
                                    <td>{item.gamenum}</td>
                                    <td>{item.w}</td>
                                    <td>{item.l}</td>
                                    <td>{item.sv}</td>
                                    <td>{item.hold}</td>
                                    <td className="pc">{item.innDisplay}</td>
                                    <td className="pc">{item.tugucount}</td>
                                    <td className="pc">{item.hit}</td>
                                    <td className="pc">{item.hr}</td>
                                    <td className="pc">{item.bb}</td>
                                    <td className="pc">{item.hp}</td>
                                    <td>{item.kk}</td>
                                    <td className="pc">{item.r}</td>
                                    <td className="pc">{item.er}</td>
                                    <td>{item.era}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </div>
            {/* <!--//table_Typ01 --> */}
        </section>
        <section>
            <h6>타자성적</h6>
            <div className="srch pc">
                <p className="btn_srch" style={{ cursor: "pointer" }}
                    onClick={evt => props.onClickBtnSearch(evt, 'hitter')}>검색</p>
                <p>
                    <label htmlFor="cell" className="blind">년도</label>
                    <select id="cell"
                        defaultValue={props.selectedYearForHitter}
                        onChange={evt => props.onChangeSelectList(evt, 'selectedYearForHitter')}>
                        {props.yearSelectList.map((item, idx) =>
                            <option key={idx} value={item.value}>{item.text}</option>
                        )}
                    </select>
                </p>
            </div>
            {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
            {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
            {props.hitterList.length > 0 ?
                <div className="mbTable mb">
                    <ul>
                        <li>구분</li>
                        {props.hitterList.map((item, idx) =>
                            <li key={idx}>{item.teamName}</li>
                        )}
                    </ul>
                </div>
                : null}
            {/* <!--//mbTable --> */}
            {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
            <div className={props.hitterList.length === 0 ? 'noPlayer' : "table_Typ01 mbScroll thFix1 w16 mbH1wM"} >
                {
                    props.hitterList.length === 0
                        ?
                        <table>
                            <caption>타자성적 현황표</caption>
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td colSpan="16">등록된 선수가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        <table>
                            <caption>타자성적 현황표</caption>
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                {props.hitterColumnHeaderList.map((item, idx) =>
                                    item.isSortable ?
                                        <th key={idx}
                                            onClick={evt => props.onClickBtnSort(evt, item.key, 'hitter')}
                                            className={item.className}
                                            scope='col'>
                                            {item.name}
                                            <span className={item.onSorting ? 'downArow on' : 'downArow'}></span>
                                        </th>
                                        :
                                        <th key={idx} scope='col' className={item.className}>{item.name}</th>
                                )}
                                </tr>
                            </thead>
                            <tbody>
                                {props.hitterList.map((item, idx) =>
                                    <tr key={idx}>
                                        <th scope="row">{item.teamName}</th>
                                        <td>{item.gamenum}</td>
                                        <td className="pc">{item.ab}</td>
                                        <td>{item.run}</td>
                                        <td>{item.hit}</td>
                                        <td className="pc">{item.h2}</td>
                                        <td className="pc">{item.h3}</td>
                                        <td>{item.hr}</td>
                                        <td>{item.rbi}</td>
                                        <td>{item.sb}</td>
                                        <td className="pc">{item.bb}</td>
                                        <td className="pc">{item.kk}</td>
                                        <td className="pc">{item.gd}</td>
                                        <td>{item.hra}</td>
                                        <td>{item.slg}</td>
                                        <td>{item.bra}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                }
            </div>
            {/* <!--//table_Typ01 --> */}
        </section>
    </div>
)

export default ContComponent;