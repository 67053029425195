import React from "react"
import Slider from "react-slick"

const pcSliderOptions = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  responsive: [
    {
      breakpoint: 780,
      settings: {
        arrows: true
        //dots: true,
      }
    }
  ]
}

const navSliderOptions = {
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  focusOnSelect: true
}

const ContComponent = (props) => (
  <div className="mb_cont contents field">
    <div className="mb_contIn">
      <section className="sec01 clear">
        {props.isShowVideoPopup ? (
          <div className="pop on">
            <p className="popBg"></p>
            <div className="popIn">
              <p className="popX">
                <img onClick={props.onClickBtnCloseVideoPopup} src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
              </p>
              <div>
                <h3>기아 챌린저스 필드 소개 영상</h3>
                <iframe
                  className="playBox"
                  width="100%"
                  height="720px"
                  src={props.challengersYoutube.value}
                  frameBorder="0"
                  title="기아 챌린저스 필드 소개 영상"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flr por">
          <p>
            <img src="/img/sub/field_video.jpg" alt="기아챌린저스 필드 소개영상 썸네일 이미지" />
          </p>
          <div className="con pc_tb">
            <a onClick={props.onClickBtnShowVideoPopup}>
              <img src="/img/sub/-e-play.png" alt="재생 아이콘" />
              기아 챌린저스 필드 소개영상
            </a>
            <p>※ 인터넷 브라우저 익스플로러 9 이상, 크롬에서 영상 재생이 원활합니다.</p>
            <p>※ 인터넷 브라우저 익스플로러 8 이하의 경우 로딩 시간이 길어질 수 있습니다.</p>
          </div>
        </div>
        <div className="fll">
          <h4 style={{whiteSpace: "nowrap"}}>
            <span>KIA</span> Challengers Field
          </h4>
          <p>기아 챌린저스 필드는 유망선수의 체계적 육성 및 안정적 인프라 구축을 통한 중장기 전력 강화를 위해 건립되었습니다.</p>
          <dl>
            <dt>소재</dt>
            <dd>
              전라남도 함평군 학교면 영산강로 3371 <br />
            </dd>
            <dt>면적</dt>
            <dd>74,774㎡(22,650평)</dd>
            <dt>시설구성</dt>
            <dd>
              <span>메인구장</span>
              <p>- (좌ㆍ우 100m, 중 120m) </p>
              <p>– 4,600여평</p>
            </dd>
            <dd>
              <span>신축구장</span>
              <p>- (좌ㆍ우 100m, 중 120m) </p>
              <p>– 4,600여평</p>
            </dd>
            <dd>
              <span>운영관</span>
              <p>- 400여평</p>
            </dd>
            <dd>
              <span>보조구장</span>
              <p>- (좌ㆍ우 60m, 중 63m) </p>
              <p>– 1,300여평</p>
            </dd>
            <dd>
              <span>클럽하우스</span>
              <p>- (지하 1층, 지상 2층)</p>
              <p>- 숙소20실(2인 1실)</p>
              <p>- 1,120여평</p>
            </dd>
            <dd>
              <span>실내연습장</span>
              <p>- (50m*50m*15m)</p>
              <p>– 760여평</p>
            </dd>
          </dl>

          <dl>
            <dt>구조</dt>
            <dd>철근 콘크리트 + 철골조</dd>
            <dt>공사기간</dt>
            <dd>2010.4 ~ 2013.8 (41개월)</dd>
          </dl>
        </div>
        <div className="con mb">
          <a onClick={props.onClickBtnShowVideoPopup}>
            <img src="/img/sub/-e-play.png" alt="재생 아이콘" />
            기아 챌린저스 필드 소개영상
          </a>
          <p>※ 인터넷 브라우저 익스플로러 9 이상, 크롬에서 영상 재생이 원활합니다.</p>
          <p>※ 인터넷 브라우저 익스플로러 8 이하의 경우 로딩 시간이 길어질 수 있습니다.</p>
        </div>
      </section>
      <section className="sec02 clear">
        <p className="mask"></p>
        <div className="slBox">
          <h5 className="por">
            기아 챌린저스 필드 메인구장<span>i</span>
          </h5>
          <div className="popI">
            <div className="por">
              <button type="button" className="closeX">
                <img src="/img/sub/ico_field_x.png" alt="X" />
              </button>
              <h6>기아 챌린저스 필드 메인구장</h6>
              <dl>
                <dt>소재</dt>
                <dd>5.000평</dd>
                <dt>구조</dt>
                <dd>철근 콘크리트 + 철골조</dd>
                <dt>시설구성</dt>
                <dd>야구장(좌ㆍ우 100m, 중 120m), 운영관</dd>
                <dt>영관 구성</dt>
                <dd>감독실, 코치실, 방송/기록실, 심판실, 감독관실, 덕아웃, 불펜, 물리치료실, 홈/원정 라커룸, 원정팀 샤워실 및 식당, 사무실</dd>
                <dt>주요특징</dt>
                <dd>
                  ① 원정팀 편의시설 확보 → 원정팀 샤워실 및 식당, 라커룸 구비
                  <br />② 반지하(지면에서 1m) 덕아웃 적용
                </dd>
              </dl>
            </div>
          </div>
          <div className="slWrap">
            <Slider
              className="forSl forSl01"
              ref={(slider) => props.setSliderRef("pcSlider1", slider)}
              {...pcSliderOptions}
              asNavFor={props.navSlider1}
            >
              <li>
                <img src="/img/sub/field01_04.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_05.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_07.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_08.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_11.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_12.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_13.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_14.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_15.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_16.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_17.png" alt="메인구장" />
              </li>
            </Slider>
            <p onClick={(evt) => props.onClickBtnSliderPrev(evt, "pcSlider1")} className="forSl_arr forSl_arr01 forSl_prev mb">
              <img src="/img/sub/ico_nvSlide.png" alt="이전" />
            </p>
            <p onClick={(evt) => props.onClickBtnSliderNext(evt, "pcSlider1")} className="forSl_arr forSl_arr01 forSl_next mb">
              <img src="/img/sub/ico_nvSlide.png" alt="다음" />
            </p>
            <Slider
              className="navSl navSl01 pc"
              {...navSliderOptions}
              ref={(slider) => props.setSliderRef("navSlider1", slider)}
              asNavFor={props.pcSlider1}
            >
              <li>
                <img src="/img/sub/field01_04.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_05.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_07.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_08.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_11.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_12.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_13.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_14.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_15.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_16.png" alt="메인구장" />
              </li>
              <li>
                <img src="/img/sub/field01_17.png" alt="메인구장" />
              </li>
            </Slider>
          </div>
        </div>
        <div className="slBox">
          <h5 className="por">
            기아 챌린저스 필드 클럽하우스<span>i</span>
          </h5>
          <div className="popI">
            <div className="por">
              <button type="button" className="closeX">
                <img src="/img/sub/ico_field_x.png" alt="X" />
              </button>
              <h6>기아 챌린저스 필드 클럽하우스 </h6>
              <dl>
                <dt>면적</dt>
                <dd>1.880평(클럽하우스 1.120여평/ 실내연습장 760여평)</dd>
                <dt>구조</dt>
                <dd>철근 콘크리트 + 철골조</dd>
                <dt>시설구성</dt>
                <dd>클럽하우스 (지하1층, 지상 2층), 실내연습장</dd>
                <dt>클럽하우스 구성</dt>
                <dd>지하층 - 방재실 등 기계실</dd>
                <dd>1층 - 선수단 식당, 웨이트실, 물리치료실, 트레이너실, 라커룸, 파우더룸, 사우나실 (냉ㆍ온탕/건식 사우나)</dd>
                <dd>2층 - 숙실(20실/2인 1실), 휴게실, 전략회의실, 사무실, 세탁건조실, 린넨실 등</dd>
                <dd>
                  실내연습장 구성: 가로 50m*세로 50m*높이 15m
                  <br />
                  3단계 자동 개폐형 그물망 적용으로 동시 다발적 훈련 가능 및 공간효율성 극대화 연습장 2개면 개폐로 자연환기 및 채광 가능한 자연친화적
                  연습장
                </dd>
                <dd>
                  <span>주요특징:</span>
                  <br />① 클럽하우스-실내연습장 일체형으로 선수단 동선 최적화
                  <br />② 타이거즈의 "T"자를 형상화한 클럽하우스 구조
                </dd>
              </dl>
            </div>
          </div>
          <div className="slWrap">
            <Slider
              className="forSl forSl02"
              ref={(slider) => props.setSliderRef("pcSlider2", slider)}
              {...pcSliderOptions}
              asNavFor={props.navSlider2}
            >
              <li>
                <img src="/img/sub/field02_01.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_02.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_03.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_04.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_05.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_06.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_07.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_08.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_09.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_10.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_11.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_12.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_13.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_14.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_15.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_16.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_17.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_18.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_19.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_20.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_21.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_22.png" alt="클럽하우스" />
              </li>
            </Slider>
            <p onClick={(evt) => props.onClickBtnSliderPrev(evt, "pcSlider2")} className="forSl_arr forSl_arr02 forSl_prev mb">
              <img src="/img/sub/ico_nvSlide.png" alt="이전" />
            </p>
            <p onClick={(evt) => props.onClickBtnSliderNext(evt, "pcSlider2")} className="forSl_arr forSl_arr02 forSl_next mb">
              <img src="/img/sub/ico_nvSlide.png" alt="다음" />
            </p>
            <Slider
              className="navSl navSl02 pc"
              ref={(slider) => props.setSliderRef("navSlider2", slider)}
              {...navSliderOptions}
              asNavFor={props.pcSlider2}
            >
              <li>
                <img src="/img/sub/field02_01.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_02.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_03.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_04.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_05.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_06.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_07.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_08.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_09.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_10.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_11.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_12.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_13.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_14.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_15.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_16.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_17.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_18.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_19.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_20.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_21.png" alt="클럽하우스" />
              </li>
              <li>
                <img src="/img/sub/field02_22.png" alt="클럽하우스" />
              </li>
            </Slider>
          </div>
        </div>
        <div className="slBox">
          <h5 className="por">
            KIA타이거즈 재활센터<span>i</span>
          </h5>
          <div className="popI">
            <div className="por">
              <button type="button" className="closeX">
                <img src="/img/sub/ico_field_x.png" alt="X" />
              </button>
              <h6>KIA타이거즈 재활센터</h6>
              <dl>
                <dt>소재</dt>
                <dd>5.000평</dd>
                <dt>구조</dt>
                <dd>철근 콘크리트 + 철골조</dd>
                <dt>시설구성</dt>
                <dd>야구장(좌ㆍ우 100m, 중 120m), 운영관</dd>
                <dt>운영관 구성</dt>
                <dd>감독실, 코치실, 방송/기록실, 심판실, 감독관실, 덕아웃, 불펜, 물리치료실, 홈/원정 라커룸, 원정팀 샤워실 및 식당, 사무실</dd>
                <dt>주요특징</dt>
                <dd>
                  ① 원정팀 편의시설 확보 → 원정팀 샤워실 및 식당, 라커룸 구비
                  <br />② 반지하(지면에서 1m) 덕아웃 적용
                </dd>
              </dl>
            </div>
          </div>
          <div className="slWrap">
            <Slider
              className="forSl forSl02"
              ref={(slider) => props.setSliderRef("pcSlider3", slider)}
              {...pcSliderOptions}
              asNavFor={props.navSlider3}
            >
              <li>
                <img src="/img/sub/field03_01.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_02.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_03.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_04.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_06.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_07.png" alt="재활센터" />
              </li>
            </Slider>
            <p onClick={(evt) => props.onClickBtnSliderPrev(evt, "pcSlider3")} className="forSl_arr forSl_arr03 forSl_prev mb">
              <img src="/img/sub/ico_nvSlide.png" alt="이전" />
            </p>
            <p onClick={(evt) => props.onClickBtnSliderNext(evt, "pcSlider3")} className="forSl_arr forSl_arr03 forSl_next mb">
              <img src="/img/sub/ico_nvSlide.png" alt="다음" />
            </p>
            <Slider
              className="navSl navSl03 pc"
              ref={(slider) => props.setSliderRef("navSlider3", slider)}
              {...navSliderOptions}
              asNavFor={props.pcSlider3}
            >
              <li>
                <img src="/img/sub/field03_01.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_02.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_03.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_04.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_06.png" alt="재활센터" />
              </li>
              <li>
                <img src="/img/sub/field03_07.png" alt="재활센터" />
              </li>
            </Slider>
          </div>
        </div>
      </section>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
)
export default ContComponent
