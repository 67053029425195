import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import SearchPlayerListContComponent from "../../common/SearchPlayerListCont.component";

import * as GameService from "../../../../services/Game.service";

import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class SearchContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const searchKeyWord = this.props.location.pathname.split("/")[3];

    this.state = {
      subMenuName: "선수검색",
      searchKeyWord,
      searchPitcherList: [],
      searchBatterList: [],
      onChangeKeyWord: null,
    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "02";
    currentAnalyticsVal.deps3 = "08";
    currentAnalyticsVal.deps4 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    try {
      const searchData = await GameService.getSearchPlayerList(
        this.state.searchKeyWord
      );

      searchData.batters.map((item, idx) => {
        switch (item.position) {
          case "내":
            return (item.position = "infielder");
          case "포":
            return (item.position = "catcher");
          case "외":
            return (item.position = "outfielder");
          default:
            return "";
        }
      });

      this.setState({
        searchPitcherList: searchData.pitchers,
        searchBatterList: searchData.batters,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnSearchPlayer = () => {
    window.location.href = `/players/search/${this.state.onChangeKeyWord}`;
  };

  onChangeSearchPlayerKeyWord = (evt) => {
    this.state.onChangeKeyWord = evt.target.value;
  };

  onEnterKey = (evt) => {
    if (evt.key === "Enter") {
      this.onClickBtnSearchPlayer();
    }
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      <div className="sbHead_appView tb"></div>
      <SearchPlayerListContComponent
        searchPitcherList={this.state.searchPitcherList}
        searchBatterList={this.state.searchBatterList}
        onChangeSearchPlayerKeyWord={this.onChangeSearchPlayerKeyWord}
        onClickBtnSearchPlayer={this.onClickBtnSearchPlayer}
        onEnterKey={this.onEnterKey}
      />
    </div>
  );
}

export default SearchContainer;
