export const notice = `

<span class="notice">
- 최고 가격을 제시한 입찰자에게 낙찰이 이루어지는 경매 방식입니다.
- 경매 물품 별 1일 1회만 입찰가 제안이 가능하며, 미성년자는 참여할 수 없습니다.
- 다수의 인원이 동일한 가격으로 입찰한 경우, 먼저 입찰하신 분이 낙찰됩니다.
- 낙찰 시에는 등록하신 휴대전화로 낙찰 결과 및 입금에 대한 후속 과정을 안내해 드립니다. 입찰 전, 본인의 휴대전화 번호를 꼭! 확인해주세요.
- <span style="color:red">경매 과열 방지를 위해 현 입찰가에서 최대 2만원까지 금액을 높여 입찰하실 수 있습니다.</span>
</span>

<p style="color:red; margin-bottom: 1em; font-size: 14px">
※ 경매 낙찰 금액은 환경단체에 습지 보전을 위한 활동에 지원됩니다. 
</p>
<p style="color:red; margin-bottom: 1em; font-size: 14px">
※ 낙찰 시 취소는 불가합니다. 낙찰자가 물품 수령 거부 또는 취소 시, 위약금(낙찰가의 10%)을 납부해야 하니, 신중한 결정 해주시기 바랍니다. (경매 이용약관 7번 참조)
</p>
`
