import axios from "axios"

const baseUrl = "/v1"

const isDevEnvironment = false
// process.env.NODE_ENV === "development"

function mockResponse(data, delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, delay ?? 1000)
  })
}

const mockProducts = [
  {
    productId: 1,
    productName: "테스트상품1",
    startPrice: "100,000원",
    currentPrice: "100,000원",
    startDt: "2022-06-17",
    endDt: "3033-06-25",
    imagePath: "/img/download/mascot/zoom_mascot.jpg",
    status: "I"
  },
  {
    productId: 2,
    productName: "테스트상품2",
    startPrice: "100,000원",
    currentPrice: "100,000원",
    startDt: "2022-06-17",
    endDt: "2022-06-25",
    imagePath: "/img/download/mascot/zoom_mascot.jpg",
    status: "E"
  }
]
// 상품 목록 읽어오기
export const getGoods = (params) => {
  if (isDevEnvironment) {
    return mockResponse(mockProducts)
  }
  return getInternal("/auction/goods/list", params).then((data) => data.list)
}

const mockDetail = {
  productId: 1,
  productName: "테스트상품1",
  startPrice: "100,000원",
  currentPrice: "100,000원",
  startDt: "2022-06-17",
  endDt: "3033-06-25",
  imagePath: "/img/download/mascot/zoom_mascot.jpg",
  status: "I"
}
// 상품 상세
export const getGoodsDetail = (id) => {
  if (isDevEnvironment) {
    return mockResponse(mockDetail)
  }
  return getGoodsDetailApi(`/auction/goods/${id}`).then((data) => data.detail)
}

// 입찰
export const bidGoods = async (bid) => {
  const form = new FormData()
  form.append("bidderName", bid.bidderName)
  form.append("phoneNumber", bid.phoneNumber)
  form.append("productId", bid.productId)
  form.append("bidPrice", bid.bidPrice)
  form.append("termsYn", bid.termsYn)

  let url = `${baseUrl}/auction/bid`
  return axios
    .post(url, form)
    .then(({status, data}) => {
      if (data.status !== 0) {
        throw new Error(data.message)
      }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}

export const getGoodsDetailApi = async (endPoint) => {
  let url = `${baseUrl}${endPoint}`
  return axios
    .get(url)
    .then(({status, data}) => {
      // if (data.status !== 0) {
      //   throw new Error(data.message)
      // }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}

// 공통 로직
export const getInternal = async (endPoint, params) => {
  let url = `${baseUrl}${endPoint}`
  return axios
    .get(url, {params})
    .then(({status, data}) => {
      // if (data.status !== 0) {
      //   throw new Error(data.message)
      // }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}
