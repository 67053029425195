import React from 'react';

import Slider from 'react-slick';
import * as ImageHelper from '../../helpers/Image.helper';
import { Link } from 'react-router-dom';

const options = {
    arrows: false,
    autoplay: true,
    dots: true,
    speed: 700,
    infinite: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    customPaging: function (i) {
        return (<div>
            <img src="/img/main/a_tigers_sl_off.png" alt="슬라이드 바로가기 아이콘" />
            <img src="/img/main/a_tigers_sl_on.png" alt="슬라이드 바로가기 아이콘" />
        </div>)
    }
}

const SlideAdComponent = props => (
    <div className='atSl'>
        <ul className="at_slide">
            <Slider
                {...options}
                className="at_slide">
                {props.list.map((item, idx) =>
                    <li key={idx}>
                        <a onClick={evt => props.onClickBtnLink(evt, item.link)} className="pc" style={{cursor:"pointer"}}>
                            <img style={{width:"100%"}} src={item['pcImgFilePath']} alt="슬라이드 광고 pc" />
                        </a>
                        <a onClick={evt => props.onClickBtnLink(evt, item.link)} className="tb" style={{cursor:"pointer"}}>
                            <img style={{width:"100%"}} src={item['mobileImgFilePath']} alt="슬라이드 광고 mb" />
                        </a>
                    </li>
                )}
            </Slider>
        </ul>
    </div>
)

export default SlideAdComponent