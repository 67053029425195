import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ResourceService from "../../../services/Resource.service";
import AnalyticsContext from "../../../context/AnalyticsContext";
import * as CommonService from "../../../services/Common.service";

class SkyboxContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    this.state = {
      subMenu: "skybox",
      subMenuName: "스카이박스 시즌권 안내",
      imgUrlObj: null,
    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "05";
    currentAnalyticsVal.deps3 = "03";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    const pcResult = await ResourceService.getSkyboxImg();
    const mResult = await ResourceService.getSkyboxImg(false);

    const imgUrlObj = {
      pc: pcResult.resource.value,
      m: mResult.resource.value,
    };

    this.setState({ imgUrlObj });
  };

  onClickBtnDownload = (file, filename) => {
    console.log("onClickBtnDownload");
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      {process.env.REACT_APP_PREPARING_SKYBOX_TICKET_PAGE_YN === "Y" ? (
        <div className="mb_cont intro1">
          <div className="mb_contIn">
            <div style={{ marginTop: "50px" }}>
              <img
                  src="/img/seasonstop_2024_pc.png"
                  alt="season_off_pc"
                  className="pc"
                  />
                <img
                  src="/img/seasonstop_2024_mobile.png"
                  alt="season_off_mb"
                  className="greeting_mb tb"
                />
              </div>
            </div>
          </div>
      ) : (
      <ContComponent
        imgUrlObj={this.state.imgUrlObj}
        onClickBtnDownload={this.onClickBtnDownload}
      />
      )}
    </div>
  );
}

export default SkyboxContainer;
