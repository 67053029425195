import axios from "axios"

const baseUrl = "/v1"

const isDevEnvironment = process.env.NODE_ENV === "development"

export const auctionBidList = async () => {
  let page = 1
  let pageUnit = 20

  let url = `${baseUrl}/auction/bid/list?page=${page}&pageUnit=${pageUnit}`

  return axios
    .get(url)
    .then(({status, data}) => {
      if (data.status !== 0) {
        throw new Error(data.message)
      }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}

export const getInternal = async (endPoint) => {
  let url = `${baseUrl}${endPoint}`
  return axios
    .get(url)
    .then(({status, data}) => {
      if (data.status !== 0) {
        throw new Error(data.message)
      }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}

export const auctionBidDetail = (id) => {
  return getInternal(`/auction/bid/${id}`).then((data) => data.detail)
}
