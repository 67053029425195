import React from "react";
import { Link } from "react-router-dom";

import PaginationComponent from "../../../common/Pagination.component";

import * as DateHelper from "../../../../helpers/Date.helper";

const ContListComponent = (props) => (
  <div className="mb_cont membership love">
    <div className="mb_contIn ">
      <div className="loveBnr por">
        <p className="bg">
          <img src="/img/sub/bnr_love.jpg" alt="배경이미지" className="pc_tb" />
          <img src="/img/sub/bnr_love_mb.jpg" alt="배경이미지" className="mb" />
        </p>
        <div className="txt clear">
          <strong>
            <span>KIA타이거즈</span>를 사랑하는 사람들{" "}
          </strong>
          <p>
            아름다운 변화, Hello 네티켓 Clean 호사방
            <br />
            건전한 게시판 문화를만들기 위해 회원님들의 적극적인 협조
            부탁드립니다.
          </p>
          <p>문의사항은 FAQ나 1:1문의를 이용하여 주시기 바랍니다.</p>
          <span className="btns">
            <Link to="/membership/faq">FAQ</Link>
            <Link to="/membership/inquiry">1:1문의</Link>
          </span>
        </div>
        <div className="loveNoti">
          <p>
            ※ 호랑이 사랑방은
            <strong style={{ color: "red", fontWeight: "bold" }}>
              &nbsp;T-CLUB 회원 들의 전용 공간
            </strong>
            입니다. <br className="mb" />
            일반 회원분들은 게시글 읽기, 좋아요만 가능합니다.
          </p>
        </div>
      </div>
      <div className="board">
        {/* <!-- srchArea --> */}
        <div className="srchArea clear">
          <select
            onChange={props.onChangeSelectList}
            value={props.selectedSearchFilter}
            name=""
            id=""
          >
            {props.searchFilterSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          <input
            value={props.searchKeyword}
            onChange={props.onChangeInput}
            onKeyPress={props.onEnterKeyPress}
            type="text"
            placeholder="검색어를 입력해주세요"
          />
          <button onClick={props.onClickBtnSearch} type="button">
            검색
          </button>
        </div>
        {/* <!-- //srchArea --> */}

        {/* <!-- board --> */}
        <div className="bdTab">
          <ul className="clear fl">
            <li className={props.tabNum === 0 ? "on" : ""}>
              <Link to="./tigers">All</Link>
            </li>
            <li className={props.tabNum === 1 ? "on" : ""}>
              <Link to="./tigers?tabNum=1">New</Link>
            </li>
            <li className={props.tabNum === 2 ? "on" : ""}>
              <Link to="./tigers?tabNum=2">Hot</Link>
            </li>
            <li className={props.tabNum === 3 ? "on" : ""}>
              <Link to="./tigers?tabNum=3">Best</Link>
            </li>
          </ul>
        </div>
        <div className="bdList">
          <ul>
            {props.list.map((item, idx) => (
              <li
                key={idx}
                className={(item.noticeYn === "Y" ? "on" : "") + " clear"}
              >
                {item.noticeYn === "Y" ? (
                  <p className="num">
                    <i className="noti">
                      <em className="blind">T</em>
                    </i>
                  </p>
                ) : (
                  <p className="num">
                    <i className="noti noti2">
                      <em className="blind">T</em>
                    </i>
                  </p>
                )}
                <p className="sbj">
                  <Link to={`/membership/tigers/${item.artcSeq}`}>
                    {item.artcTitle}
                  </Link>
                  {/* <!-- pc일때 댓글수 --> */}
                  {item.replyCnt > 0 ? (
                    <span className="repl pc_tb">
                      [<em>{item.replyCnt}</em>]
                    </span>
                  ) : null}
                  <span className="ico">
                    {item.likeCnt > 30 ? <i className="b">B</i> : null}
                    {item.viewCnt > 1000 ? <i className="h">H</i> : null}
                    {item.regDttm > DateHelper.getTwoDaysAgoTimestamp() ? (
                      <i className="n">N</i>
                    ) : null}
                  </span>
                </p>
                <p className="info clear">
                  {/* <!-- 모바일일때 댓글수 --> */}
                  {item.replyCnt > 0 ? (
                    <span className="repl mb">
                      <i></i>
                      {item.replyCnt}
                    </span>
                  ) : null}
                  <span className="like">
                    <i></i>
                    {item.likeCnt}
                  </span>
                  <span className="view">
                    <i></i>
                    {item.viewCnt}
                  </span>
                  <span className="date">
                    {item.regDate}
                    <em className="time">{item.regTime}</em>
                  </span>
                  <span
                    className="writer"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      (item.noticeYn === "N" || item.noticeYn === "n") &&
                        props.onClickBtnBlockPop(e, item.artcSeq);
                    }}
                  >
                    {item.writerName}({item.simpleId})
                  </span>
                </p>
              </li>
            ))}
            {props.blockPopStatus.visible === true ? (
              <div>
                <div
                  className="block_pop pc"
                  style={{
                    top: props.blockPopStatus.locationY - 700 + "px",
                    left: "75%",
                  }}
                  onClick={() => {
                    props.onClickBtnApplyBlock();
                  }}
                >
                  <p>블라인드 처리하기</p>
                </div>
                <div
                  className="block_pop tb"
                  style={{
                    top: props.blockPopStatus.locationY - 500 + "px",
                    left: "25%",
                  }}
                  onClick={() => {
                    props.onClickBtnApplyBlock();
                  }}
                >
                  <p>블라인드 처리하기</p>
                </div>
              </div>
            ) : null}

            {/* <li className="on clear">

                            <p className="num"><i className="noti"><em className="blind">T</em></i></p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="on clear">
                            <p className="num"><i className="noti"><em className="blind">T</em></i></p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                </span>
                            </p>
                            <p className="on info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="on clear">
                            <p className="num"><i className="noti"><em className="blind">T</em></i></p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="on clear">
                            <p className="num"><i className="noti"><em className="blind">T</em></i></p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">10</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">9</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">8</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">7</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">6</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">5</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">4</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">3</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">2</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li>
                        <li className="clear">
                            <p className="num">1</p>
                            <p className="sbj">
                                <a href="">제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목제목 제목제목제목 제목제 목제목</a>
                                <span className="ico">
                                    <i className="h">H</i>
                                    <i className="b">B</i>
                                    <i className="n">N</i>
                                    <i className="mbw"><em className="blind">모바일아이콘</em></i>
                                </span>
                            </p>
                            <p className="info clear">
                                <span className="like"><i></i>16</span>
                                <span className="view"><i></i>1779</span>
                                <span className="date">2019.11.05<em className="time">14:03</em></span>
                            </p>
                        </li> */}
          </ul>
        </div>
        <div className="loveW inQBtn">
          {props.isAccessMember && props.userPermission !== "N" && (
            <button type="button" onClick={props.onClickBtnWrite}>
              <em className="wr"></em>글쓰기
            </button>
          )}
        </div>
        {/* <!-- //bdList --> */}
        <PaginationComponent
          paginationObj={props.paginationObj}
          onClickBtnPageNum={props.onClickBtnPageNum}
        />
      </div>
      {/* <!-- //board --> */}
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContListComponent;
