import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'
import * as AuthHelper from '../../../helpers/Auth.helper'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {checkEventTarget, getEventApplyStatus, getEventDetail, requestApplyEvent} from '../../../services/Event.service'
import { decodeAES256 } from '../../../helpers/Crypto.helper'
import { getMyInfo } from '../../../services/User.service'
import {checkIsOnWebview} from "../../../helpers/Common.helper";

dayjs.locale('ko')

const kboTeamList = {
  63: "/img/ticket/emblem/hanwha.png",
  58: "/img/ticket/emblem/kia.png",
  62: "/img/ticket/emblem/kt.png",
  59: "/img/ticket/emblem/lg.png",
  60: "/img/ticket/emblem/lotte.png",
  64: "/img/ticket/emblem/nc.png",
  55: "/img/ticket/emblem/doosan.png",
  476: "/img/ticket/emblem/ssg.png",
  57: "/img/ticket/emblem/samsung.png",
  61: "/img/ticket/emblem/kiwoom.png"
}

class DetailContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    const eventIdx = this.props.match.params.eventIdx;
    if(!eventIdx || isNaN(eventIdx)) {
      alert('잘못된 접근입니다.');
      document.location.href = '/';
    }
    this.state = {
      subMenu: "guide",
      subMenuName: "시구 시타 사연 접수",
      title: '',
      contents: '',
      userId: '',
      userName: '',
      mobile_no: '',
      answer_1: '',
      answer_2: '',
      answer_3: '',
      agree: "",
      eventIdx,
      eventInfo: {},
    };
  }

  componentDidMount = async (_) => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/quiz/' + this.state.eventIdx;
      return;
    } else {
      this.setState({ isInit: true });
    }

    try {
      const eventInfo = await getEventDetail(this.state.eventIdx);
      console.log(eventInfo);

      // start 20230424 10:00 ~ 202304226 17:00
      const now = dayjs();
      const start = dayjs(eventInfo['start_apply'], 'YYYYMMDD HH:mm');
      const end = dayjs(eventInfo['end_apply'], 'YYYYMMDD HH:mm');
      if (now.isBefore(start) || now.isAfter(end)) {
        alert('이벤트 응모 기간이 아닙니다.');
        if(checkIsOnWebview()) {
          document.location.href = 'about:blank';
        } else {
          document.location.href = '/';
        }
        return;
      }

      const loginInfo = AuthHelper.getLoginInfo();

      const userId = loginInfo.user.simpleId;
      const userName = loginInfo.user.memberName;
      // const userMobile = loginInfo.user.mobpNo;
      // if(decodeAES256(userMobile) === "") {
      //   alert("등록된 전화번호가 없습니다.\n마이페이지에서 휴대폰 번호를 입력해주세요.")
      //   document.location.href = '/mypage';
      //   return;
      // }

      //230130 주석
      this.setState({userId: userId, userName: userName, eventInfo: eventInfo});
    } catch (e) {
      alert(e.message);
      if(e.message === "세션이 만료되었습니다.") {
        document.location.href = '/login?redirect=/event/quiz/' + this.state.eventIdx;
        return;
      }
      if(checkIsOnWebview()) {
        document.location.href = 'about:blank';
      } else {
        document.location.href = '/';
      }
    }
  };

  onChangeTextAreaContents = (evt) => {
    this.setState({ apply_reason: evt.target.value });
  };
  onChangeInputMobileNo = (evt) => {
    console.log(evt.target.value);
    if(evt.target.value.length > 11) {
      return;
    }
    evt.target.value = evt.target.value.replace(/[^0-9]/g, '');
    const _tmp = evt.target.value
    this.setState({ mobile_no: _tmp });
  };

  onChangeInputAnswer_1 = (evt) => {
    this.setState({ answer_1: evt.target.value });
  };
  onChangeInputAnswer_2 = (evt) => {
    this.setState({ answer_2: evt.target.value });
  };

  onChangeInputAnswer_3 = (evt) => {
    this.setState({ answer_3: evt.target.value });
  };

  onChangeInputAgree = (evt) => {
    this.setState({ agree: evt.target.value });
  }

  onClickBtnRequest = async (_) => {
    const { mobile_no, answer_1, answer_2, answer_3, userId, userName, agree, eventInfo } = this.state;

    if(!mobile_no) {
      alert('연락처를 입력해주세요.');
      return;
    }
    const decodeMobileNo = mobile_no;

    const isValidPhoneNumber = phoneNumber => {
      const pattern = /^01[0-9]{9}$/;
      return pattern.test(phoneNumber.replace(/-/g, ''));
    }

    if(decodeMobileNo.length < 10 || !isValidPhoneNumber(decodeMobileNo) ) {
      alert('연락처를 올바르게 입력해주세요.\n예시) 01012345678');
      return;
    }


    let trimAnswer_1 = "";
    if(eventInfo.pic1_name !== "" && !answer_1) {
      trimAnswer_1 = answer_1.replace(/\s/g, "");
      if (!trimAnswer_1) {
        alert(eventInfo.pic1_name +" 항목을 입력해주세요")
        return;
      }
    }

    let trimAnswer_2 = "";
    if(eventInfo.pic2_name !== "" && !answer_2) {
      trimAnswer_2 = answer_2.replace(/\s/g, "");
      if (!trimAnswer_2) {
        alert(eventInfo.pic2_name +" 항목을 입력해주세요")
        return;
      }
    }

    let trimAnswer_3 = "";
    if(eventInfo.pic3_name !== "" && !answer_3) {
      trimAnswer_3 = answer_3.replace(/\s/g, "");
      if (!trimAnswer_3) {
        alert(eventInfo.pic3_name +" 항목을 입력해주세요")
        return;
      }
    }


    if(agree === "") {
      alert("이벤트 기본 수집 동의를 하셔야 이벤트에 응모됩니다.");
      return;
    } else if(agree === "N") {
      alert("이벤트 응모에 필요한 기본 수집 내용을 동의하셔야 이벤트에 응모됩니다.");
      return;
    }

    const check = window.confirm("이벤트 신청 시 취소 및 수정이 불가합니다.\n이벤트 신청을 하시겠습니까?")
    if(check) {
      const data = {
        userId: decodeAES256(userId),
        userName: decodeAES256(userName),
        mobile_no: decodeMobileNo,
        eventIdx: this.state.eventIdx
      }
      if(eventInfo.pic1_name !== "") {
        data.add_info1 = answer_1;
      }
      if(eventInfo.pic2_name !== "") {
        data.add_info2 = answer_2;
      }
      if(eventInfo.pic3_name !== "") {
        data.add_info3 = answer_3;
      }

      const result = await requestApplyEvent(data)
      if(result.status) {
        alert('이벤트 응모가 완료되었습니다.');
        if(checkIsOnWebview()) {
          document.location.href = '/event/success';
        } else {
          document.location.href = '/';
        }
      } else {
        alert('이벤트 응모에 실패하였습니다.\n' + result.message);
      }
    }


  }



  render = (_) => (
    <div className="container">
      {/*<SubHeadComponent subMenuName={this.state.subMenuName} />*/}

      {/* 230127 주석 */}
      {/*{ !CommonHelper.checkIsOnWebview() && (*/}
      {/*  <GuideTabComponent*/}
      {/*    onClickBtnChangeMembership={this.onClickBtnChangeMembership}*/}
      {/*    tabNum={this.state.tabNum}*/}
      {/*  />*/}
      {/*)}*/}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        onChangeTextAreaContents={this.onChangeTextAreaContents}
        onChangeInputAnswer_1={this.onChangeInputAnswer_1}
        onChangeInputAnswer_2={this.onChangeInputAnswer_2}
        onChangeInputAnswer_3={this.onChangeInputAnswer_3}
        onChangeInputMobileNo={this.onChangeInputMobileNo}
        onClickBtnRequest={this.onClickBtnRequest}
        onChangeInputAgree={this.onChangeInputAgree}
        // contents={this.state.contents}
        // title={this.state.title}
        userId={this.state.userId}
        userName={this.state.userName}
        mobile_no={this.state.mobile_no}
        // child_name={this.state.child_name}
        // apply_reason={this.state.apply_reason}
        agree={this.state.agree}
        eventInfo={this.state.eventInfo}
      /> }
    </div>
  );
}

export default DetailContainer;
