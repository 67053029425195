import React from "react";

import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const ContComponent = (props) => (
  <div className="mb_cont membership">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">시구 시타 사연 신청</h6>
      <img src={"https://kia-tigers-public.s3.ap-northeast-2.amazonaws.com/event/ceremonial.jpg"} style={{margin: "10px 0", width: "100%"}} />
      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <ul>
            <li><label htmlFor="user_id">아이디</label></li>
            <li className="tit">

              <input type="text" id="user_id"
                     value={decodeAES256(props.userId)}
                     readOnly={true}
                     disabled={true}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="user_name">이름</label></li>
            <li className="tit">

              <input type="text" id="user_name"
                     value={decodeAES256(props.userName)}
                     readOnly={true}
                     disabled={true}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="mobile_no">연락처</label></li>
            <li className="tit">

              <input type="text" id="mobile_no"
                     placeholder={"연락처를 입력해주세요"}
                     onChange={props.onChangeInputMobileNo}
                     value={props.mobile_no}
              />
              <p><strong>{props.mobile_no.length}</strong>/<span>{11}</span></p>
            </li>
          </ul>
          <ul>
            <li><label htmlFor="child_name">어린이 이름</label></li>
            <li className="tit">

              <input type="text" id="child_name"
                     placeholder={"어린이 이름을 입력해주세요"}
                     onChange={props.onChangeInputChildName}
                     value={props.child_name}
              />
            </li>
          </ul>
          <ul className="txt">
            <li style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><label htmlFor="apply_reason">신청사연</label></li>
            <li style={{borderLeft: "1px solid #e4e5e6 "}}>
                <textarea cols="30" id="apply_reason"
                          placeholder="신청사연을 입력해주세요"
                          onChange={props.onChangeTextAreaContents}
                          value={props.apply_reason}
                ></textarea>
            </li>
          </ul>
        </fieldset>

      </form>
      <div className="inQBtn clear">

        <button onClick={props.onClickBtnRequest} type="button" style={{
          left: "50%",
          position: "relative",
          marginLeft: "-25%"
        }}><em className="myQ"></em>신청하기</button>

      </div>

    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
