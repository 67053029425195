import React from 'react';

export const BestHitRateComponent = props => (
    <table>
        <caption>최고타율 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최고타율</th>
                <td>{props.bestHraObj.matchTeam.hra}</td>
                <td>
                    {props.bestHraObj.matchTeam.imgUrl ?
                        <img src={props.bestHraObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}

                </td>
            </tr>
            <tr>
                <th>월별 최고타율</th>
                <td>{props.bestHraObj.monthly.hra}</td>
                <td>{props.bestHraObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최고타율</th>
                <td>{props.bestHraObj.weekly.hra}</td>
                <td>{props.bestHraObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestHitRateComponent;