const msOffset = 32400000;

export const getTwoDaysAgoTimestamp = () => {
    return Date.now() - (48 * 60 * 60);
}

export const getCurrentTimestamp = _ => {
    return Date.now();
}

export const convertTimestampToDatetime = (timestamp) => {
    return convertTimestampToDate(timestamp) + " " + convertTimestampToTime(timestamp);
}
export const convertTimestampToDate = (timestamp, separator="-") => {
    timestamp = parseInt(timestamp) + msOffset
    const dateString = (new Date(timestamp)).toISOString();
    return dateString.split("T")[0].replace(/-/g, separator);
}
export const convertTimestampToTime = (timestamp) => {
    timestamp = parseInt(timestamp) + msOffset
    const dateString = (new Date(timestamp)).toISOString();
    return dateString.split("T")[1].substr(0, 5);
}

export const getCurrentYear = _ => {
    return (new Date()).getFullYear();
}

export const getCurrentMonth = _ => {
    let month = (new Date()).getMonth() + 1;
    return (month < 10 ? "0" + month : month) + "";
}

export const getYearSelectList = (beginYear = 2000) => {
    let list = [];

    for (let i = getCurrentYear(); i >= beginYear; i--) {
        list.push({
            text: `${i}년`,
            value: i
        });
    }
    return list;
}

export const getMonthSelectList = (lastMonth = 12) => {
    let list = [];

    for (let i = 1; i <= lastMonth; i++) {
        list.push({
            text: `${i}월`,
            value: i
        });
    }
    return list;
}

export const getDayOfWeekSelectList = () => {
    return [
        { text: "일", value: "일" },
        { text: "월", value: "월" },
        { text: "화", value: "화" },
        { text: "수", value: "수" },
        { text: "목", value: "목" },
        { text: "금", value: "금" },
        { text: "토", value: "토" }
    ]
}

export const getDayOfWeek = (year, month, day) => {
    const monthNum = parseInt(month, 10)
    month = monthNum < 10 ? `0${monthNum}` : monthNum + '';
    const dayNum = parseInt(day, 10)
    day = dayNum ? `0${dayNum}` : dayNum + '';
    return (new Date(`${year}-${month}-${day}`)).getDay();
}

export const getDaySelectList = (year, month) => {
    const lastDay = getLastDayOfMonth(year, month);
    let list = [];
    for (let i = 1; i <= lastDay; i++) {
        list.push({ text: `${i}일`, value: i });
    }
    return list;
}

export const getLastDayOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
}

export const makeSeparatedDate = (fullDate, separator = '.') => {
    const year = fullDate.substr(0, 4);
    const month = fullDate.substr(4, 2);
    const day = fullDate.substr(6, 2);

    return `${year}${separator}${month}${separator}${day}`;
}