import axios from "axios";

const baseUrl = "/v1";

// 코칭 스태프 목록
export const getCoachingStaffList = async (_) => {
  return axios
    .get(`${baseUrl}/game/coachsteplist`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 코칭 스태프 정보
export const getCoachingStaffInfo = async (pCode) => {
  return axios
    .get(`${baseUrl}/game/coachstep?gameSearch.pcode=${pCode}`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 엔트리 현황
export const getEntryStatus = async (entry, sortKey = "NAME") => {
  return axios
    .get(
      `${baseUrl}/game/entry/daily?gameSearch.entry=${entry}&gameSearch.sortKey=${sortKey}`
    )
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

// 등록 말소
export const getExpiredEntryStatus = async (gyear, gmonth, gday) => {
  let url = `${baseUrl}/game/entry/inout?gameSearch.gyear=${gyear}`;
  if (gmonth) {
    url += `&gameSearch.gmonth=${gmonth}`;
  }
  if (gday) {
    url += `&gameSearch.gday=${gday}`;
  }

  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// http://cms.kiatigers.info/api/v1/game/monthschedule?gameSearch.yearMonth=201909
export const getMajorMonthlySchedule = async (yearMonth) => {
  return axios
    .get(`${baseUrl}/game/monthschedule?gameSearch.yearMonth=${yearMonth}`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data.list;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

//game-경기일정/결과-1군
// https://cms.kiatigers.info/api/v1/game/recentGames
export const getMajorRecentlyGames = async (_) => {
  return axios
    .get(`${baseUrl}/game/recentGames`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

//game-경기일정/결과-2군
// 	https://cms.kiatigers.info/api/v1/game/monthminorschedule
export const getMinorMonthlySchedule = async (yearMonth) => {
  return axios
    .get(`${baseUrl}/game/monthminorschedule?gameSearch.yearMonth=${yearMonth}`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data.list;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};
// https://cms.kiatigers.info/api/v1/game/recentMinorGames
export const getMinorRecentlyGames = async (_) => {
  return axios
    .get(`${baseUrl}/game/recentMinorGames`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getPlayerList = async (initial) => {
  // console.log("getPlayerList");

  const data = {
    "gameSearch.position": initial
  }
  return axios
    .post(`${baseUrl}/game/playerlist`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      // console.error(err);
      throw new Error(err.message);
    });
};

export const getPitcherInfo = async (pCode, year) => {
  console.log("getPitcherInfo");
  const url = `${baseUrl}/game/pitcherdetail?gameSearch.pcode=${pCode}&gameSearch.targetYear=${year}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getHitterInfo = async (pCode, year) => {
  console.log("getHitterInfo");
  const url = `${baseUrl}/game/batterdetail?gameSearch.pcode=${pCode}&gameSearch.targetYear=${year}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 군입대 선수 목록
// https://api.kiatigers.info/v1/game/playerlist/army
export const getMilitaryEnlistedList = async (year) => {
  console.log("getMilitaryEnlistedList");
  const url = `${baseUrl}/game/playerlist/army`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 군입대 선수 정보
// https://api.kiatigers.info/v1/game/playerdetail/army?gameSearch.targetYear=2019&gameSearch.pcode=66615
export const getMilitaryEnlistedInfo = async (pcode, year) => {
  console.log("getMilitaryEnlistedInfo");
  const url = `${baseUrl}/game/playerdetail/army?gameSearch.targetYear=${year}&gameSearch.pcode=${pcode}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//game-states-조건별기록
export const getPitcherRecordByConditions = async (year) => {
  // console.log("getPitcherInfo");
  return axios
    .get(
      `${baseUrl}/game/stats/record/condition/pitcher?gameSearch.targetYear=${year}`
    )
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

export const getHitterRecordByConditions = async (year) => {
  console.log("getHitterRecordByConditions");
  return axios
    .get(
      `${baseUrl}/game/stats/record/condition/batter?gameSearch.targetYear=${year}`
    )
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//game-stats-투수순위
export const getPitcherRanking = async (year, sortKey, params = {}) => {
  // console.log("getPitcherRanking");
  let url = `${baseUrl}/game/stats/ranking/pitcher?gameSearch.gyear=${year}`;
  url += `&gameSearch.sortKey=${sortKey}`;
  if (params["teamCode"]) {
    url += `&gameSearch.teamCode=${params["teamCode"]}`;
  }
  if (params["inFlag"]) {
    url += `&gameSearch.inFlag=${params["inFlag"]}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

//game-stats-타자순위
export const getHitterRanking = async (year, sortKey, params = {}) => {
  // console.log("getHitterRanking");
  let url = `${baseUrl}/game/stats/ranking/batter?gameSearch.gyear=${year}`;
  url += `&gameSearch.sortKey=${sortKey}`;
  if (params["teamCode"]) {
    url += `&gameSearch.teamCode=${params["teamCode"]}`;
  }
  if (params["inFlag"]) {
    url += `&gameSearch.inFlag=${params["inFlag"]}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

export const getEachTeamWinLose = async (year, params = {}) => {
  console.log("getEachTeamWinLose");
  let url = `${baseUrl}/game/stats/team/vs?gameSearch.gyear=${year}`;
  if (params["month"] !== "") {
    const month =
      parseInt(params["month"], 10) < 10
        ? "0" + params["month"]
        : params["month"];
    url += `&gameSearch.gmonth=${month}`;
  }
  if (params["homeAwayFlag"] !== "") {
    url += `&gameSearch.tb=${params["homeAwayFlag"]}`;
  }
  if (params["dayNightFlag"] !== "") {
    url += `&gameSearch.gtime=${params["dayNightFlag"]}`;
  }
  if (params["dayOfWeek"] !== "") {
    url += `&gameSearch.gweek=${params["dayOfWeek"]}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getTeamPitcherRankingBySeason = async (year, sortKey = null) => {
  console.log("getTeamPitcherRankingBySeason");
  let url = `${baseUrl}/game/stats/ranking/team/pitching?gameSearch.gyear=${year}`;
  if (sortKey) {
    url += `&gameSearch.sortKey=${sortKey}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const getTeamHitterRankingBySeason = async (year, sortKey = null) => {
  console.log("getTeamHitterRankingBySeason");
  let url = `${baseUrl}/game/stats/ranking/team/batting?gameSearch.gyear=${year}`;
  if (sortKey) {
    url += `&gameSearch.sortKey=${sortKey}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//game-stats-역대성적-투수
export const getPitcherRankingByTeamCode = async (teamCode) => {
  // console.log("getPitcherRankingByTeamCode");
  let url = `${baseUrl}/game/stats/ranking/team/pitching?gameSearch.teamCode=${teamCode}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

//game-stats-역대성적-타자
export const getHitterRankingByTeamCode = async (teamCode) => {
  // console.log("getHitterRankingByTeamCode");
  let url = `${baseUrl}/game/stats/ranking/team/batting?gameSearch.teamCode=${teamCode}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

export const getFuturesRankingBySeason = async (year, league) => {
  console.log("getFuturesRankingBySeason");
  let url = `${baseUrl}/game/stats/teamrankbyyear/minor?gameSearch.gyear=${year}&gameSearch.league=${league}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//game-stats-선수들간 기록 비교 - (시즌)
export const getTeamListForCompareRecord = async (year) => {
  // console.log("getTeamListForCompareRecord");
  let url = `${baseUrl}/game/stats/compare/teamlist?gameSearch.gyear=${year}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};

// 선수들간 기록 비교 - (시즌, 팀, 포지션(P - 투수, B - 타자))
// https://api.kiatigers.info/v1/game/stats/compare/playerlist?gameSearch.teamCode=HH&gameSearch.gyear=2019&gameSearch.position=B
export const getPlayerListForCompareRecord = async (
  year,
  teamCode,
  position
) => {
  let url = `${baseUrl}/game/stats/compare/playerlist?gameSearch.teamCode=${teamCode}`;
  url += `&gameSearch.gyear=${year}`;
  url += `&gameSearch.position=${position}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
// 선수들간 기록 비교 - (좌측 플레이어 코드, 우측 플레이어코드, 시즌)
// https://api.kiatigers.info/v1/game/stats/compare/playerrecords?gameSearch.leftPcode=77637&gameSearch.rightPcode=77829&gameSearch.gyear=2019
export const getInfoForCompareRecord = async (leftPcode, rightPcode, year) => {
  let url = `${baseUrl}/game/stats/compare/playerrecords?gameSearch.leftPcode=${leftPcode}`;
  url += `&gameSearch.rightPcode=${rightPcode}`;
  url += `&gameSearch.gyear=${year}`;

  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 1군 팀 순위 목록
// https://api.kiatigers.info/v1/game/stats/teamrankbyyear?gameSearch.gyear=2019
export const getMajorTeamRankingByYear = (year) => {
  let url = `${baseUrl}/game/stats/teamrankbyyear?gameSearch.gyear=${year}`;

  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 1군 박스스코어
// https://api.kiatigers.info/v1/game/boxscore?gameSearch.gmkey=20190901LTHT0&gameSearch.gameDate=20190901
export const getMajorBoxScore = (params = {}) => {
  let url = `${baseUrl}/game/boxscore?gameSearch.gmkey=${params["gameKey"]}&gameSearch.gameDate=${params["gameDate"]}`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
// 박스스코어 상단 정보
// https://api.kiatigers.info/v1/game/currentschedule
export const getMajorCurrentGameInfo = (params = {}) => {
  let url = `${baseUrl}/game/currentschedule`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//  퓨처스 리그 박스 스코어
// https://api.kiatigers.info/v1/game/boxscore/minor?gameSearch.gmkey=20190903HTHH0&gameSearch.gameDate=20190903
export const getMinorBoxScore = (params = {}) => {
  let url = `${baseUrl}/game/boxscore/minor?1=1`;
  if (params["gameKey"]) {
    url += `&gameSearch.gmkey=${params["gameKey"]}`;
  }
  if (params["gameDate"]) {
    url += `&gameSearch.gameDate=${params["gameDate"]}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

//선수 검색 리스트 조회
export const getSearchPlayerList = (pname) => {
  const data = `gameSearch.pname=${pname}`;
  return axios
    .post(`${baseUrl}/game/player/search`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
