import React, {Component} from "react"
import SubHeadComponent from "../../common/SubHead.component"

import queryString from "query-string"
import ContComponent from "./Cont.component"

import * as ArticleService from "../../../../services/Article.service"
import * as ListHelper from "../../../../helpers/List.helper"
import * as DateHelper from "../../../../helpers/Date.helper"
import * as AuthHelper from "../../../../helpers/Auth.helper"
import * as UserService from "../../../../services/User.service"
import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"
import * as AuthService from "../../../../services/Auth.service"
import {decodeAES256} from "../../../../helpers/Crypto.helper"


class ListContainer extends Component {
  static contextType = AnalyticsContext

  constructor(props) {
    super(props)

    const queryStringParams = queryString.parse(props.location.search)

    const tabNum = parseInt(queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 0, 10)
    const currentPage = parseInt(queryStringParams["page"] ? queryStringParams["page"] : 1, 10)
    const selectedSearchFilter = queryStringParams["searchFilter"] ? queryStringParams["searchFilter"] : 100
    const searchKeyword = queryStringParams["searchKeyword"] ? queryStringParams["searchKeyword"] : ""
    const searchFilterSelectList = ListHelper.getSearchFilterSelectList()

    this.state = {
      subMenu: "tigers",
      subMenuName: "호랑이 사랑방",
      tabNum,
      searchKeyword,
      selectedSearchFilter,
      searchFilterSelectList,
      currentPage,
      totalPage: 0,
      paginationObj: null,
      perPage: 15,
      list: [],
      blockPopStatus: {
        visible: false
      },
      userPermission: ""
    }
  }

  componentDidMount = async (_) => {
    // console.log("componentDidMount", this.state)
    try {
      if (!AuthHelper.checkHasRealName()) {
        this.setState({isOnShowPopup: true})
        return false
      }
      if (AuthHelper.checkHasLoveRoomAuth()) {
        this.setState({isAccessMember: true})
      }
      //230130 주석
      const userPermission = await AuthService.getUserBoardPermission()

      const currentAnalyticsVal = this.context.state
      const prevUrl = currentAnalyticsVal.currentUrl
      this.context.action(currentAnalyticsVal)

      currentAnalyticsVal.deps2 = "06"
      currentAnalyticsVal.deps3 = "02"
      currentAnalyticsVal.prevUrl = prevUrl
      currentAnalyticsVal.queryString = window.location.href.split("?").length === 2 ? decodeURI(window.location.href.split("?")[1]) : ""
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0]

      await CommonService.insertAnalytics(this.context.state)

      const offset = ListHelper.makeOffset(this.state.currentPage, this.state.perPage)

      //230130 주석
      const result = await this.getList(offset, this.state.searchKeyword, this.state.selectedSearchFilter, this.getFilterCode(this.state.tabNum))

      let totalPage = 0
      let paginationObj = null
      //230130 주석
      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage
          paginationObj = ListHelper.makePaginationObj(totalPage, this.state.currentPage, this.state.perPage, 5)
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(result.list[i]["regDttm"])
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(result.list[i]["regDttm"])
      }

      this.setState({
        //230130 주석
        list: result.list,
        totalPage,
        paginationObj,
        //230130 주석
        userPermission: userPermission.boardPermission
      })
    } catch (err) {
      console.error(err)
      // alert(err.message)
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const currentAnalyticsVal = this.context.state
      const prevUrl = currentAnalyticsVal.currentUrl
      this.context.action(currentAnalyticsVal)

      currentAnalyticsVal.deps2 = "06"
      currentAnalyticsVal.deps3 = "02"
      currentAnalyticsVal.prevUrl = prevUrl
      currentAnalyticsVal.queryString = window.location.href.split("?").length === 2 ? decodeURI(window.location.href.split("?")[1]) : ""
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0]

      await CommonService.insertAnalytics(this.context.state)

      this.setState({blockPopStatus: {visible: false}})
      const queryStringParams = queryString.parse(this.props.location.search)

      const page = parseInt(queryStringParams["page"] ? queryStringParams["page"] : 1, 10)
      const searchKeyword = queryStringParams["searchKeyword"] ? queryStringParams["searchKeyword"] : ""
      const searchFilter = queryStringParams["searchFilter"] ? queryStringParams["searchFilter"] : 100
      const tabNum = parseInt(queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 0, 10)

      this.updateList(page, searchKeyword, searchFilter, tabNum)
    }
  }

  updateList = async (page, searchKeyword, searchFilter, tabNum) => {
    console.log("updateList", page, searchKeyword, searchFilter, tabNum)
    try {
      const offset = ListHelper.makeOffset(page, this.state.perPage)

      //230130 주석
      const result = await this.getList(offset, searchKeyword, searchFilter, this.getFilterCode(tabNum))

      let totalPage = 0
      let paginationObj = null
      //230130 주석
      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage
          paginationObj = ListHelper.makePaginationObj(totalPage, page, this.state.perPage, 5, tabNum)
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(result.list[i]["regDttm"])
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(result.list[i]["regDttm"])
      }
      console.log(paginationObj)
      this.setState({
        //230130 주석
        list: result.list,
        totalPage,
        paginationObj,
        searchKeyword,
        selectedSearchFilter: searchFilter,
        currentPage: page,
        tabNum
      })
    } catch (err) {
      // alert(err.message)
      console.error(err)
    }
  }

  getFilterCode = (tabNum) => {
    switch (tabNum) {
      case 0:
        return null
      case 1:
        return "new"
      case 2:
        return "hot"
      case 3:
        return "best"
      default:
        return null
    }
  }

  getList = async (offset, searchKeyword, searchFilter, filterCode) => {
    console.log("getList", offset, searchKeyword, searchFilter, filterCode)
    const loginInfo = AuthHelper.getLoginInfo()

    let listParams = {
      "search.pos": offset,
      "search.max": this.state.perPage,
      "article.simpleId": decodeAES256(loginInfo.user.simpleId)
    }

    if (searchKeyword) {
      listParams["search.searchWord"] = searchKeyword
      listParams["search.searchCategory"] = searchFilter
    }

    if (filterCode) {
      listParams["article.filter"] = filterCode
    }

    return ArticleService.getTigersArticleList(listParams)
  }

  onChangeSelectList = (evt) => {
    this.setState({selectedSearchFilter: evt.target.value})
  }
  onChangeInput = (evt) => {
    console.log(evt.target.value)
    this.setState({searchKeyword: evt.target.value})
  }

  onClickBtnSearch = (evt) => {
    let url = "/membership/tigers?page=1"
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`
    }
    url += `&searchFilter=${this.state.selectedSearchFilter}`
    url += `&tabNum=${this.state.tabNum}`
    this.props.history.push(url)
  }

  //230130 주석
  onClickBtnWrite = async (_) => {
    const permission = await AuthService.getUserBoardPermission()
    permission.boardPermission !== "N" ? this.props.history.push("/membership/tigers/write") : alert("쓰기 권한이 없습니다.")
  }

  onClickBtnPageNum = (evt, pageNum) => {
    console.log("onClickBtnPageNum", pageNum, this.state)
    let url = `/membership/tigers?page=${pageNum}`
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`
    }
    url += `&searchFilter=${this.state.selectedSearchFilter}`
    url += `&tabNum=${this.state.tabNum}`
    this.props.history.push(url)
  }

  onClickBtnCancelPopup = (_) => {
    this.props.history.push("/")
  }
  onClickBtnConfirmPopup = (_) => {
    this.props.history.push("/mypage")
  }

  onClickBtnBlockPop = (evt, seq) => {
    const tbSizeHeightOption = window.innerWidth < 1200 ? (window.innerWidth > 540 ? 100 : 20) : -10
    this.state.blockPopStatus.visible === true
      ? this.state.blockPopStatus.currentSeq === seq
        ? this.setState({blockPopStatus: {visible: false}})
        : this.setState({
            blockPopStatus: {
              visible: true,
              locationY: evt.pageY - tbSizeHeightOption,
              currentSeq: seq
            }
          })
      : this.setState({
          blockPopStatus: {
            visible: true,
            locationY: evt.pageY - tbSizeHeightOption,
            currentSeq: seq
          }
        })
  }

  //230130 주석
  onClickBtnApplyBlock = async () => {
    if (window.confirm("해당 유저가 쓴 게시물을 블라인드 하시겠습니까?")) {
      const info = AuthHelper.getLoginInfo()

      await UserService.applyBlindList(this.state.blockPopStatus.currentSeq, decodeAES256(info.user.memberId), decodeAES256(info.user.simpleId))
        .then((data) => {
          if (!data.error) {
            alert(data.blindResult + "가 블라인드 되었습니다.")
            window.location.reload()
          } else {
            alert(data.error)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  onEnterKeyPress = (evt) =>{
    if(evt.key === 'Enter'){
      this.onClickBtnSearch();
    }
  }

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />

      {/* 230127 주석 */}
      <ContComponent
        selectedSearchFilter={this.state.selectedSearchFilter}
        searchKeyword={this.state.searchKeyword}
        searchFilterSelectList={this.state.searchFilterSelectList}
        onClickBtnSearch={this.onClickBtnSearch}
        onChangeSelectList={this.onChangeSelectList}
        onChangeInput={this.onChangeInput}
        onClickBtnTab={this.onClickBtnTab}
        tabNum={this.state.tabNum}
        onClickBtnWrite={this.onClickBtnWrite}
        isAccessMember={this.state.isAccessMember}
        list={this.state.list}
        paginationObj={this.state.paginationObj}
        onClickBtnPageNum={this.onClickBtnPageNum}
        blockPopStatus={this.state.blockPopStatus}
        onClickBtnBlockPop={this.onClickBtnBlockPop}
        onClickBtnApplyBlock={this.onClickBtnApplyBlock}
        userPermission={this.state.userPermission}
        onEnterKeyPress={this.onEnterKeyPress}
      />

    </div>
  )
}

export default ListContainer
