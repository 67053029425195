import React, { Component } from 'react';
import ContComponent from './Cont.component';

import * as CommonHelper from '../../../../../helpers/Common.helper';
import * as DateHelper from '../../../../../helpers/Date.helper';
import * as GameService from '../../../../../services/Game.service';

class FuturesRankingContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearSelectList: DateHelper.getYearSelectList(),

            selectedYearForNorthLeague: process.env.REACT_APP_SEASON_YEAR,
            selectedYearForSouthLeague: process.env.REACT_APP_SEASON_YEAR,

            northLeagueList: [],
            southLeagueList: []
        }
    }

    componentDidMount = async _ => {
        try {
            const northLeagueResult = await GameService.getFuturesRankingBySeason(this.state.selectedYearForNorthLeague, 'north');
            const northLeagueList = northLeagueResult.list;

            const southLeagueResult = await GameService.getFuturesRankingBySeason(this.state.selectedYearForNorthLeague, 'south');
            const southLeagueList = southLeagueResult.list;

            this.setState({
                northLeagueResult,
                northLeagueList,

                southLeagueResult,
                southLeagueList
            })
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    onChangeSelectList = (evt, key) => {
        this.setState({ [key]: evt.target.value });
    }

    onClickBtnSearch = async (evt, type) => {
        if (type === 'north') {
            const northLeagueResult = await GameService.getFuturesRankingBySeason(this.state.selectedYearForNorthLeague, 'north');
            const northLeagueList = northLeagueResult.list;

            this.setState({
                northLeagueResult,
                northLeagueList,
            })
        } else if (type === 'south') {
            const southLeagueResult = await GameService.getFuturesRankingBySeason(this.state.selectedYearForNorthLeague, 'south');
            const southLeagueList = southLeagueResult.list;

            this.setState({
                southLeagueResult,
                southLeagueList
            })
        }
    }

    render = _ => (
      <ContComponent
        yearSelectList={this.state.yearSelectList}

        selectedYearForNorthLeague={this.state.selectedYearForNorthLeague}
        selectedYearForSouthLeague={this.state.selectedYearForSouthLeague}

        onChangeSelectList={this.onChangeSelectList}
        onClickBtnSearch={this.onClickBtnSearch}

        northLeagueList={this.state.northLeagueList}
        southLeagueList={this.state.southLeagueList} />
    )
}

export default FuturesRankingContainer
