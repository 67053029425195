import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as GameService from "../../../../services/Game.service";

import * as DateHelper from "../../../../helpers/Date.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

const pitcherColumnHeaderList = [
  { key: "groupIf", name: "구분", sorting: "" },
  { key: "gamenum", name: "경기", sorting: "" },
  { key: "w", name: "승", sorting: "" },
  { key: "l", name: "패", sorting: "" },
  { key: "sv", name: "세", sorting: "" },
  { key: "hold", name: "홀드", sorting: "" },
  { key: "innDisplay", name: "이닝", sorting: "" },
  { key: "tugucount", name: "투구수", sorting: "" },
  { key: "hit", name: "안타", sorting: "" },
  { key: "hr", name: "홈런", sorting: "" },
  { key: "hp", name: "4구", sorting: "" },
  { key: "wp", name: "사구", sorting: "" },
  { key: "kk", name: "삼진", sorting: "" },
  { key: "r", name: "실점", sorting: "" },
  { key: "er", name: "자책", sorting: "" },
  { key: "era", name: "평균자책점", sorting: "" },
];
const hitterColumnHeaderList = [
  { key: "groupIf", name: "구분", sorting: "" },
  { key: "gamenum", name: "경기", sorting: "" },
  { key: "ab", name: "타수", sorting: "" },
  { key: "run", name: "득점", sorting: "" },
  { key: "hit", name: "안타", sorting: "" },
  { key: "h2", name: "2타", sorting: "" },
  { key: "h3", name: "3타", sorting: "" },
  { key: "hr", name: "홈런", sorting: "" },
  { key: "rbi", name: "타점", sorting: "" },
  { key: "sb", name: "도루", sorting: "" },
  { key: "hp", name: "4구", sorting: "" },
  { key: "kk", name: "삼진", sorting: "" },
  { key: "gd", name: "병살", sorting: "" },
  { key: "hra", name: "타율", sorting: "" },
  { key: "slg", name: "장타율", sorting: "" },
  { key: "bra", name: "출루율", sorting: "" },
];

class ConditionRankingContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "조건별 기록",
      tabNum: 1,

      selectedYearForPitcher: process.env.REACT_APP_SEASON_YEAR,
      selectedYearForHitter: process.env.REACT_APP_SEASON_YEAR,
      yearSelectList: DateHelper.getYearSelectList(),

      pitcherResult: null,
      hitterResult: null,

      pitcherColumnHeaderList: pitcherColumnHeaderList,
      pitcherRecordList: [],
      hitterColumnHeaderList: hitterColumnHeaderList,
      hitterRecordList: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "04";
      currentAnalyticsVal.deps3 = "03"; //
      currentAnalyticsVal.deps4 = "05";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const pitcherResult = await GameService.getPitcherRecordByConditions(
        this.state.selectedYearForPitcher
      );
      const hitterResult = await GameService.getHitterRecordByConditions(
        this.state.selectedYearForHitter
      );

      const pitcherRecordList = this.getRecordListByCondition(
        this.state.tabNum,
        pitcherResult
      );
      const hitterRecordList = this.getRecordListByCondition(
        this.state.tabNum,
        hitterResult
      );

      this.setState({
        pitcherResult,
        hitterResult,
        pitcherRecordList: JSON.parse(JSON.stringify(pitcherRecordList)),
        hitterRecordList: JSON.parse(JSON.stringify(hitterRecordList)),
      });
    } catch (err) {
      alert(err.message);
    }
  };

  onClickBtnSearch = async (evt, type) => {
    if (type === "pitcher") {
      const pitcherResult = await GameService.getPitcherRecordByConditions(
        this.state.selectedYearForPitcher
      );
      const pitcherRecordList = this.getRecordListByCondition(
        this.state.tabNum,
        pitcherResult
      );
      let pitcherColumnHeaderList = this.state.pitcherColumnHeaderList;
      for (let i = 0; i < pitcherColumnHeaderList.length; i++) {
        pitcherColumnHeaderList[i]["sorting"] = "";
      }
      this.setState({
        pitcherResult,
        pitcherColumnHeaderList,
        pitcherRecordList: JSON.parse(JSON.stringify(pitcherRecordList)),
      });
    } else if (type === "hitter") {
      const hitterResult = await GameService.getHitterRecordByConditions(
        this.state.selectedYearForHitter
      );
      const hitterRecordList = this.getRecordListByCondition(
        this.state.tabNum,
        hitterResult
      );
      let hitterColumnHeaderList = this.state.hitterColumnHeaderList;
      for (let i = 0; i < hitterColumnHeaderList.length; i++) {
        hitterColumnHeaderList[i]["sorting"] = "";
      }
      this.setState({
        hitterResult,
        hitterColumnHeaderList,
        hitterRecordList: JSON.parse(JSON.stringify(hitterRecordList)),
      });
    }
  };

  onChangeSelectYearList = async (evt, key) => {
    this.setState({ [key]: evt.target.value });
  };

  getRecordListByCondition = (tabNum, pitcherResult) => {
    switch (tabNum) {
      case 1:
        return pitcherResult["bymatchteam"];
      case 2:
        return pitcherResult["byvisit"];
      case 3:
        return pitcherResult["bydaytime"];
      case 4:
        return pitcherResult["byweek"];
      case 5:
        return pitcherResult["bymonth"];
      default:
        break;
    }
  };

  onClickBtnTab = (evt, tabNum) => {
    this.setState({ tabNum });
    let pitcherRecordList = null;
    let hitterRecordList = null;
    if (this.state.pitcherResult) {
      pitcherRecordList = this.getRecordListByCondition(
        tabNum,
        this.state.pitcherResult
      );
    }
    if (this.state.hitterResult) {
      hitterRecordList = this.getRecordListByCondition(
        tabNum,
        this.state.hitterResult
      );
    }

    let pitcherColumnHeaderList = this.state.pitcherColumnHeaderList;
    for (let i = 0; i < pitcherColumnHeaderList.length; i++) {
      pitcherColumnHeaderList[i]["sorting"] = "";
    }
    let hitterColumnHeaderList = this.state.hitterColumnHeaderList;
    for (let i = 0; i < hitterColumnHeaderList.length; i++) {
      hitterColumnHeaderList[i]["sorting"] = "";
    }

    this.setState({
      pitcherRecordList,
      hitterRecordList,
      pitcherColumnHeaderList,
      hitterColumnHeaderList,
    });
  };

  sortTable = (objName, sortingField) => {
    console.log("sortTable");
    console.log(objName, sortingField);
    let recordListName = null;
    let columnHeaderListName = null;

    if (objName === "pitcher") {
      recordListName = "pitcherRecordList";
      columnHeaderListName = "pitcherColumnHeaderList";
    } else if (objName === "hitter") {
      recordListName = "hitterRecordList";
      columnHeaderListName = "hitterColumnHeaderList";
    }

    let recordList = this.state[recordListName];
    let columnHeaderList = this.state[columnHeaderListName];

    let sorting = "";
    for (let i = 0; i < columnHeaderList.length; i++) {
      console.log(columnHeaderList[i]);
      if (columnHeaderList[i]["key"] === sortingField) {
        console.log(columnHeaderList[i]);
        console.log(columnHeaderList[i]["sorting"]);
        if (
          columnHeaderList[i]["sorting"] === "" ||
          columnHeaderList[i]["sorting"] === "desc"
        ) {
          columnHeaderList[i]["sorting"] = "asc";
          sorting = "asc";
        }
        if (columnHeaderList[i]["sorting"] === "asc") {
          columnHeaderList[i]["sorting"] = "desc";
          sorting = "desc";
        }
        console.log(columnHeaderList[i]);
      } else {
        columnHeaderList[i]["sorting"] = "";
        sorting = "";
      }
    }

    recordList.sort((a, b) => {
      if (sorting === "asc") {
        return a[sortingField] < b[sortingField]
          ? -1
          : a[sortingField] > b[sortingField]
            ? 1
            : 0;
      } else {
        return a[sortingField] < b[sortingField]
          ? 1
          : a[sortingField] > b[sortingField]
            ? -1
            : 0;
      }
    });
    console.log("recordList", recordList);
    console.log("columnHeaderList", columnHeaderList);
    this.setState({
      [recordListName]: recordList,
      [columnHeaderListName]: JSON.parse(JSON.stringify(columnHeaderList)),
    });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        tabNum={this.state.tabNum}
        onClickBtnTab={this.onClickBtnTab}
        sortTable={this.sortTable}
        onClickBtnSearch={this.onClickBtnSearch}
        onChangeSelectYearList={this.onChangeSelectYearList}
        selectedYearForPitcher={this.state.selectedYearForPitcher}
        selectedYearForHitter={this.state.selectedYearForHitter}
        yearSelectList={this.state.yearSelectList}
        pitcherColumnHeaderList={this.state.pitcherColumnHeaderList}
        pitcherRecordList={this.state.pitcherRecordList}
        hitterColumnHeaderList={this.state.hitterColumnHeaderList}
        hitterRecordList={this.state.hitterRecordList}
      />
    </div>
  );
}

export default ConditionRankingContainer;
