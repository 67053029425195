import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'
import * as AuthHelper from '../../../helpers/Auth.helper'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {
  checkEventTarget,
  getEventApplyStatus, getEventDetail, requestApplyEvent,
  requestApplyEventNotAuth
} from '../../../services/Event.service'
import { decodeAES256 } from '../../../helpers/Crypto.helper'
import { getMyInfo } from '../../../services/User.service'
import {checkIsOnWebview} from "../../../helpers/Common.helper";

dayjs.locale('ko')

const kboTeamList = {
  63: "/img/ticket/emblem/hanwha.png",
  58: "/img/ticket/emblem/kia.png",
  62: "/img/ticket/emblem/kt.png",
  59: "/img/ticket/emblem/lg.png",
  60: "/img/ticket/emblem/lotte.png",
  64: "/img/ticket/emblem/nc.png",
  55: "/img/ticket/emblem/doosan.png",
  476: "/img/ticket/emblem/ssg.png",
  57: "/img/ticket/emblem/samsung.png",
  61: "/img/ticket/emblem/kiwoom.png"
}

class DetailContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    this.state = {
      subMenu: "guide",
      subMenuName: "KIA TIGERS YOUTH BASEBALL CAMP 참가자 모집",
      title: '',
      contents: '',
      applicant_name: "",
      applicant_age: "",
      applicant_locale: "",
      applicant_phone: "",
      applicant_gender: "",
      applicant_birthday: "",
      applicant_shoe_size: "",
      parent_email: "",
      parent_phone: "",
      parent_relation: "",
      uniform_num: "",
      size_check_schedule: "",
      confirm: '',
      target_confirm: "",
      privacy_confirm: "",
      social_number_confirm: "",
      privacy_handle_confirm: "",
      record_confirm: "",
      userId: "",
      userName: "",
      mobile_no: "",
      isInit: false,
      eventIdx: 100,
      eventInfo: {},
      inputField: []
    };
  }

  componentDidMount = async (_) => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/kids/tball-festa';
      return;
    } else {
      this.setState({ isInit: true });
    }

    // eventInfo request
    try {
      const eventInfo = await getEventDetail(this.state.eventIdx);
      console.log(eventInfo);



      const now = dayjs();
      const start = dayjs(eventInfo['start_apply'], 'YYYYMMDD HH:mm');
      const end = dayjs(eventInfo['end_apply'], 'YYYYMMDD HH:mm');

      if (now.isBefore(start) || now.isAfter(end)) {
        alert('이벤트 기간이 아닙니다.');
        if(checkIsOnWebview()) {
          document.location.href = '/event/return';
        } else {
          document.location.href = '/';
        }
        return;
      }

      if(eventInfo.event_target !== "all") {

        const checkTarget = await checkEventTarget(this.state.eventIdx)
        if (!checkTarget) {
          alert('해당 이벤트 참여 대상이 아닙니다.')
          document.location.href = '/event/return';
          return;
        }
      }

      // const alreadyApply = await getEventApplyStatus({eventIdx:1});
      // if(!alreadyApply) {
      //   alert('이미 신청이 완료되었습니다.')
      //   // history back
      //   window.history.back();
      //   return;
      // }

      const loginInfo = AuthHelper.getLoginInfo();

      const userId = loginInfo.user.simpleId;
      const userName = loginInfo.user.memberName;
      const userMobile = loginInfo.user.mobileNo;

      const inputField = [];
      if(eventInfo.pic1_name) {
        inputField.push({fieldName: eventInfo.pic1_name, key: 'pic1_name', change: this.onChangeInput});
      }
      if(eventInfo.pic2_name) {
        inputField.push({fieldName: eventInfo.pic2_name, key: 'pic2_name', change: this.onChangeInput});
      }
      if(eventInfo.pic3_name) {
        inputField.push({fieldName: eventInfo.pic3_name, key: 'pic3_name', change: this.onChangeInput});
      }

      if(eventInfo.ext_colletc_info) {
        const extInfo = eventInfo.ext_colletc_info.split("|");
        for(let i=0; i<extInfo.length; i++) {
          inputField.push({fieldName: extInfo[i], key: 'ext_colletc_info_'+i, change: this.onChangeInput});
        }

      }
      //230130 주석
      this.setState({userId: userId, userName: userName, eventInfo: eventInfo, inputField: inputField});
    } catch (e) {
      if(e.message === "세션이 만료되었습니다.") {
        document.location.href = '/login?redirect=/event/kids/tball-festa';
        return;
      }
      if(checkIsOnWebview()) {
        document.location.href = '/event/return';
      } else {
        document.location.href = '/';
      }
    }
  };


  // onChangeInputConfirm  = (evt) => {
  //   this.setState({confirm: evt.target.checked});
  // }

  onChangeInputConfirm = (evt) => {
    this.setState({confirm: evt.target.value});
  }


  onChangeInput = (evt) => {
    console.log(evt.target.name);
    // find input Field
    const inputField = this.state.inputField.find((item) => item.key === evt.target.name);
    if(inputField) {
      inputField.value = evt.target.value;
    }
  }

  onClickBtnRequest = async (_) => {
    const { confirm } = this.state;

    const loginInfo = AuthHelper.getLoginInfo();
    const userNo = loginInfo.user.userNo;
    const userNm = loginInfo.user.memberName;
    const userMobile = loginInfo.user.mobpNo;

    console.log(loginInfo)
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/kids/tball-festa';
      return;
    }


    let pic1_name = '';
    let pic2_name = '';
    let pic3_name = '';
    let ext_colletc_info = this.state.inputField;
    if(this.state.inputField.length > 0) {
      for(let i=0; i<this.state.inputField.length; i++) {
        if(!this.state.inputField[i].value) {
          alert(this.state.inputField[i].fieldName + '을 입력해주세요.');
          return;
        }

        if(this.state.inputField[i].key === 'pic1_name') {
          pic1_name = this.state.inputField[i].value;
        }
        if(this.state.inputField[i].key === 'pic2_name') {
          pic2_name = this.state.inputField[i].value;
        }
        if(this.state.inputField[i].key === 'pic3_name') {
          pic3_name = this.state.inputField[i].value;
        }
      }
    }


    console.log("!!!!", confirm);
    if(confirm === "") {
      alert("신청 내용을 확인해주세요.");
      document.getElementById("confirm_y").focus()
      return;
    } else if(confirm === "N") {
      alert("신청 내용을 확인해주세요.");
      document.getElementById("confirm_y").focus()
      return;
    } else if(confirm === false) {
      alert("신청 내용을 확인해주세요.");
      document.getElementById("confirm_y").focus()
      return;
    }

    const check = window.confirm("이벤트 신청 시 취소 및 수정이 불가합니다.\n이벤트 신청을 하시겠습니까?")
    if(check) {
      const data = {

        eventIdx: this.state.eventIdx,
        confirm,
        add_info1: pic1_name,
        add_info2: pic2_name,
        add_info3: pic3_name,
        mobile_no: decodeAES256(userMobile),

        extData: ext_colletc_info
      }
      console.log(data);
      const result = await requestApplyEvent(data)
      if(result.status) {
        alert('이벤트 응모가 완료되었습니다.');
        if(checkIsOnWebview()) {
          document.location.href = '/event/success';
        } else {
          document.location.href = '/';
        }
      } else {
        alert('이벤트 신청에 실패하였습니다.\n' + result.message);
        if(checkIsOnWebview()) {
          document.location.href = '/event/return';
        } else {
          document.location.href = '/';
        }
      }
    }


  }



  render = (_) => (
    <div className="container">
      {/*<SubHeadComponent subMenuName={this.state.subMenuName} />*/}

      {/* 230127 주석 */}
      {/*{ !CommonHelper.checkIsOnWebview() && (*/}
      {/*  <GuideTabComponent*/}
      {/*    onClickBtnChangeMembership={this.onClickBtnChangeMembership}*/}
      {/*    tabNum={this.state.tabNum}*/}
      {/*  />*/}
      {/*)}*/}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        onChangeInputApplicantName={this.onChangeInputApplicantName}
        onChangeInputApplicantAge={this.onChangeInputApplicantAge}
        onChangeInputApplicantLocale={this.onChangeInputApplicantLocale}
        onChangeInputApplicantPhone={this.onChangeInputApplicantPhone}
        onChangeInputApplicantBirthday={this.onChangeInputApplicantBirthday}
        onChangeInputApplicantShoeSize={this.onChangeInputApplicantShoeSize}
        onChangeInputParentEmail={this.onChangeInputParentEmail}
        onChangeInputParentPhone={this.onChangeInputParentPhone}
        onChangeInputUniformNum={this.onChangeInputUniformNum}
        onChangeInputConfirm={this.onChangeInputConfirm}
        onChangeInputSizeCheck={this.onChangeInputSizeCheck}
        onChangeInputParentRelation={this.onChangeInputParentRelation}
        onChangeInputTargetConfirm={this.onChangeInputTargetConfirm}
        onChangeInputPrivacyConfirm={this.onChangeInputPrivacyConfirm}
        onChangeInputSocialNumberConfirm={this.onChangeInputSocialNumberConfirm}
        onChangeInputPrivateHandleConfirm={this.onChangeInputPrivateHandleConfirm}
        onChangeInputRecordConfirm={this.onChangeInputRecordConfirm}
        onChangeInputApplicantGender={this.onChangeInputApplicantGender}
        userId={this.state.userId}
        userName={this.state.userName}
        mobile_no={this.state.mobile_no}
        applicant_name={this.props.applicant_name}
        applicant_phone={this.props.applicant_phone}
        applicant_birthday={this.props.applicant_birthday}
        parent_email={this.props.parent_email}
        parent_phone={this.props.parent_phone}
        uniform_num={this.props.uniform_num}
        onClickBtnRequest={this.onClickBtnRequest}
        eventInfo={this.state.eventInfo}
        inputField={this.state.inputField}
        onChangeInput={this.onChangeInput}

      /> }
    </div>
  );
}

export default DetailContainer;
