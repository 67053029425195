import React from 'react';
import { Link } from 'react-router-dom';
import SubMenuComponent from '../../common/SubMenu.component';
import EntryTabComponent from '../common/EntryTab.component';
import ImgComponent from '../../../common/Img.component';

import * as ImageHelper from '../../../../helpers/Image.helper';

const ContComponent = (props) => (
    <div className="mb_cont sub_cont player coach">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName} />
            <EntryTabComponent
                onClickBtnEntryTab={props.onClickBtnEntryTab}
                entryTabNum={props.entryTabNum} />
            <div className="list">
                <ul className="clear">
                    {props.list.map((item, idx) =>
                        <li key={idx}>
                            <a onClick={evt => props.onClickBtnPlayer(evt, item.pcode)}>
                                <ImgComponent
                                    imgUrl = {item.playerImg}
                                    defaultImgUrl = {ImageHelper.getDefaultPlayerListOffImg()}
                                    imgName = {item.playerName} />
                                {/* <img src={item.playerImg ? item.playerImg : ImageHelper.getDefaultPlayerListImg()} alt={item.playerName}
                                    onError={(e) => {
                                        e.target.onError = null
                                        e.target.src = ImageHelper.getDefaultPlayerListImg()
                                    }} /> */}
                                <div className="txt">
                                    <ul>
                                        <li>{item.position2}</li>
                                        <li className="num">NO.{item.backnum}</li>
                                        <li className="name">{item.playerName}</li>
                                    </ul>
                                </div>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
)

export default ContComponent;
