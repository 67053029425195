import React from 'react';

export const BestWinningRateComponent = props => (
    <table>
        <caption>최고승률 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최고승률</th>
                <td>{props.bestWraObj.matchTeam.wra}</td>
                <td>
                    {props.bestWraObj.matchTeam.imgUrl ?
                        <img src={props.bestWraObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최고승률</th>
                <td>{props.bestWraObj.monthly.wra}</td>
                <td>{props.bestWraObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최고승률</th>
                <td>{props.bestWraObj.weekly.wra}</td>
                <td>{props.bestWraObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>

)
export default BestWinningRateComponent;