import React from 'react';
import { Link } from 'react-router-dom';

const SubMenuComponent = (props) => (
    <div className="tabWrap ovX">
        <ul className="tab01 clear">
            <li className={props.thirdMenu === 'team-ranking' ? "on" : ""} >
                <Link to="/game/stats/team-ranking">팀순위</Link>
            </li>
            <li className={props.thirdMenu === 'ranking-history' ? "on" : ""} >
                <Link to="/game/stats/ranking-history">역대성적</Link>
            </li>
            <li className={props.thirdMenu === 'pitcher-ranking' ? "on" : ""} >
                <Link to="/game/stats/pitcher-ranking">투수순위</Link>
            </li>
            <li className={props.thirdMenu === 'hitter-ranking' ? "on" : ""} >
                <Link to="/game/stats/hitter-ranking">타자순위</Link>
            </li>
            <li className={props.thirdMenu === 'condition-ranking' ? "on" : ""} >
                <Link to="/game/stats/condition-ranking">조건별 기록</Link>
            </li>
            <li className={props.thirdMenu === 'compare' ? "on" : ""} >
                <Link to="/game/stats/compare">선수간 기록 비교</Link>
            </li>
            <li className={props.thirdMenu === 'calculation' ? "on pc" : "pc"} >
                <Link to="/game/stats/calculation">기록 계산법</Link>
            </li>
        </ul>
    </div>
)

export default SubMenuComponent;