import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";
import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

class EmblemContainer extends Component {
  static contextType = AnalyticsContext;

  componentDidMount = async () => {
      const currentAnalyticsVal = this.context.state
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "01";
      currentAnalyticsVal.deps3 = "05";
      currentAnalyticsVal.deps4 = "02";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);
  }

  constructor(props) {
    super(props);

    const subMenu = "bi";
    const thirdMenu = "emblem";
    this.state = {
      subMenu,
      subMenuName: "CI",
      thirdMenu,
      thirdMenuName: "엠블럼"
    };
  }
  onClickBtnDownload = (imgUrl, filename) => {
    fetch(imgUrl).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        onClickBtnDownload={this.onClickBtnDownload}
      />
    </div>
  );
}

export default EmblemContainer;
