import React from 'react';
import SubMenuComponent from '../../common/SubMenu.component';

import Slider from 'react-slick';


const pcSliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    // asNavFor: 'pcSlider'
}
const navSliderOptions = {
    slidesToShow: 5,
    slidesToScroll: 1,
    // asNavFor: 'navSlider',
    //dots: false,
    //centerMode: true,
    focusOnSelect: true
}
const mobileSliderOptions = {
    autoplay: false,
    speed: 500,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: false,
    // prevArrow: $('.mbChampPrev'),
    // nextArrow: $('.mbChampNext')
}

const ContComponent = props => (
    props.info ?
        <div className="mb_cont contents">
            <div className="mb_contIn bdView">
            <SubMenuComponent thirdMenu={props.thirdMenu} /> {/* 추가 */}
                <div className="board">
                    <div className="h6Bar">
                        {props.info.artcTitle}
                        <p className="icoWrap">
                            <span className="date">{props.info.regDate}</span>
                            <span className="view"><i></i>{props.info.viewCnt}</span>
                            <span className="like"><i></i>{props.info.likeCnt}</span>
                        </p>
                    </div>
                    <div className="viewCont">
                        <p dangerouslySetInnerHTML={{ __html: props.info.artcContent }}>
                        </p>
                    </div>
                    <div className="icoWrap">
                        <div>
                            <button onClick={props.onClickBtnList} className="bgGray">목록</button>
                            <button onClick={props.onClickBtnLike} type="button" className={(props.checkDidLike ? 'like2' : 'like') + ' heart'}><i></i>{props.info.likeCnt}</button>
                        </div>
                    </div>
                </div>
                {/* <!-- //board --> */}
            </div>
            {/* <!-- //mb_contIn --> */}
        </div>
        : null
)

export default ContComponent;