import React, {createContext, useCallback, useContext, useReducer} from "react"
import Markdown from "markdown-to-jsx"

const PopupContext = createContext(null)

const PopupDispatchContext = createContext(null)

export function PopupProvider({children}) {
  const [popup, dispatch] = useReducer(reducer, initialPopup)

  return (
    <PopupContext.Provider value={popup}>
      <PopupDispatchContext.Provider value={dispatch}>
        {children}
        <Popup />
        <Alert />
      </PopupDispatchContext.Provider>
    </PopupContext.Provider>
  )
}

// closePopup 을 주입받는 element 에서 호출해야 한다.
// 기본으로 닫기 버튼이 없다.
export const usePopup = () => {
  const dispatch = useContext(PopupDispatchContext)

  const openPopup = (element) => {
    dispatch({type: "openPopup", element})
  }

  const closePopup = () => {
    dispatch({type: "closePopup"})
  }

  return {openPopup, closePopup}
}

function Popup() {
  const popup = useContext(PopupContext)

  return <div className={`popup alert ${popup.openPopup ? "on" : ""}`}>{popup.element}</div>
}

export const useAlert = () => {
  const dispatch = useContext(PopupDispatchContext)

  const openAlert = (message, onConfirm) => {
    dispatch({type: "openAlert", payload: {message, onConfirm}})
  }

  const closeAlert = () => {
    dispatch({type: "closeAlert"})
  }

  return {openAlert, closeAlert}
}

function Alert() {
  const popup = useContext(PopupContext)
  const dispatch = useContext(PopupDispatchContext)

  const onClose = useCallback(() => {
    dispatch({type: "closeAlert"})
    if (typeof popup.onConfirm === "function") {
      popup.onConfirm()
    }
  }, [dispatch, popup])

  return (
    <div className={`popup alert ${popup.openAlert ? "on" : ""}`}>
      <div className="container">
        <div className="content">{popup.message && <Markdown className="text">{popup.message}</Markdown>}</div>
        <div className="bottom">
          <button className="oneButton" onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  )
}

function reducer(state, action) {
  switch (action.type) {
    case "openPopup":
      return {
        openPopup: true,
        element: action.element
      }
    case "openAlert":
      return {
        openAlert: true,
        message: action.payload.message,
        onConfirm: action.payload.onConfirm
      }
    case "closePopup":
      return initialPopup
    case "closeAlert":
      return initialPopup
    default:
      return state
  }
}

const initialPopup = {
  openPopup: false,
  element: null,
  openAlert: false,
  message: ""
}
