import React from 'react';

const EntryTabComponent = (props) => (
    <ul className="tab02 w2 clear">
        <li className={props.entryTabNum === 1 ? "on" : ""}>
            <a onClick={evt => props.onClickBtnEntryTab(evt, 1)}>1군</a>
        </li>
        <li className={props.entryTabNum === 2 ? "on" : ""}>
            <a onClick={evt => props.onClickBtnEntryTab(evt, 2)}>퓨쳐스</a>
        </li>
        {/* <li className={props.entryTabNum === 3 ? "on" : ""}>
            <a onClick={evt => props.onClickBtnEntryTab(evt, 3)} className="mb_line2">육성/잔류/<br />재활군</a>
        </li> */}
    </ul>
)

export default EntryTabComponent;