export const agreement = `
### KIA타이거즈 경매 이용약관

#### 1.	목적
-	본 약관은 온라인 굿즈경매를 이용함에 있어 이용자의 권리 및 책임 사항을 규정함을 목적으로 합니다. 

#### 2.	정의
-	낙찰 : 입찰에 의하여 경매물품의 거래가 성립되는 것 
-	낙찰자 : 경매물품을 낙찰 받은 사람 
-	최고가 경매 : 최고가격을 입찰한 입찰자에게 낙찰이 이루어지는 방식 

#### 3.	회원정보 
-	입찰 전 회원정보의 휴대폰번호를 확인해주세요. 
- 회원의 휴대전화번호가 변경되었을 경우, 현재 사용 중인 번호로 업데이트를 부탁 드립니다. (모바일 앱 로그인 > 설정 > 계정 설정).
-	경매 낙찰 시, 회원정보의 휴대폰번호로 낙찰에 따른 후속 진행을 안내 드립니다. 
-	경매 낙찰 후, 낙찰자 분에게 낙찰 사실 및 입금 계좌에 대한 내용을 공지한 후에 회원정보에 등록된 주소로 물품을 배송해드립니다. 
-	개인정보처리방침에 따라 회원으로부터 제공받은 정보를 수집 및 보관합니다. 

#### 4.	입찰방법 
-	진행되는 경매가 복수의 건일 경우, 각각의 경매에 개별 입찰 가능합니다.
-	입찰 제안은 1,000원 단위로 입력 가능합니다.
-	입찰가 제안을 완료한 후에는 입찰가를 수정하실 수 없습니다.
-	아래 해당하는 경우에는 입찰이 불가합니다. 

가. 입찰 당시 최고 입찰자인 경우에는 입찰이 불가합니다.

나. 경매 과열 방지를 위해 현 입찰가에서 최대 2만원까지 금액을 높여 입찰하실 수 있습니다.

#### 5.	낙찰 
-	낙찰은 최고 입찰가를 기준으로 정해집니다. 
-	최고가격을 입찰한 입찰자가 선정되며, 동일한 가격으로 여러 명이 입찰 할 경우, 시간상 먼저 입찰하신 분이 낙찰됩니다. 

#### 6.	허위 행위 금지
-	허위 입찰이란, 구매의사가 없지만 경매에 참여하여 고의적으로 입찰가를 상승시키는 행위를 말합니다.
-	허위 입찰을 고의적으로 했다는 사실이 밝혀질 시, 서비스 이용약관 제19조에 의해 경매에 대한 참여 권한이 제한될 수 있습니다.

#### 7.	낙찰 취소에 대한 조치

-	<span style="color:red">낙찰자는 입금 기간(48시간 이내) 물품 수령 거부 또는 취소 시, 해당 건에 대해 위약금(낙찰가의 10%)을 납부해야 합니다. </span>
-	<span style="color:red">구단은 안정성과 신뢰성을 위해 낙찰자 구매의사 거부, 허위입찰자에 대해서는 향후 응찰 자격이 제한될 수 있습니다.</span>


<p style="font-weight: bold; text-align: center; color: black; padding: 20px 8px;">공정하고 원활한 경매가 이루어지는 공간으로 거듭날 수 있도록 회원 여러분들의 많은 협조와 양해 부탁 드립니다.</p>
`
