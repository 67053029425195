import React, {useCallback} from "react"
import * as ScorePointService from "../../../services/ScorePoint.service"
import {useQuery} from "react-query"
import {useHistory} from "react-router-dom"
import {useAlert} from "../../../context/PopupContext"
import {webViewCloseBridge} from "../../../helpers/JSBridge.helper"

export const order = {
  ordCnt: 1,
  productId: undefined,
  point: 0,
  productOptionId: undefined
}

function ScorePointProducts() {
  let history = useHistory()
  const {openAlert} = useAlert()
  const {data: scorePoint} = useQuery("scorePoint", ScorePointService.getPoint, {
    staleTime: 1000,
    retry: false,
    refetchOnWindowFocus: false
  })

  const {isLoading, data} = useQuery("scorePointProducts", ScorePointService.getProducts, {
    onSuccess: (res) => {
      res.length === 0 && openAlert("득점 포인트 상품 신청이 마감되었습니다", webViewCloseBridge)
    }
  })

  const onClickProduct = useCallback((id) => {
    history.push(`/score-points/products/${id}`)
  }, [])

  // const onClickAlert = useCallback(() => {
  //   openAlert(
  //     `교환 신청이 완료되었습니다.\n\n상품 준비가 완료되면 문자메시지로\n\n교환권을 보내드립니다.\n\n교환 신청 현황은\n\n**기아타이거즈앱 > 득점 포인트 > 신청 현황**\n\n에서 확인 가능합니다.`
  //   )
  // }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="score-points-products">
      <div className="point">
        보유 <span>{scorePoint ?? 0}</span>p
      </div>
      {data && data.length > 0 ? (
        <ul>
          {data.map((product) => (
            <li className="card" key={product.productid} onClick={() => onClickProduct(product.productid)}>
              <div className="card-header">
                <img
                  src={product.imageInfoList && product.imageInfoList.length > 0 ? product.imageInfoList[0].path : "/img/main/no_goods_img.png"}
                  alt={`${product.productName} 이미지`}
                />
              </div>
              <div className="card-body">
                <h5>{product.productName}</h5>
                <p>{`${product.price}p`}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div>상품이 존재하지 않습니다.</div>
      )}
    </div>
  )
}

export default ScorePointProducts
