import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";
import * as ArticleService from "../../../../services/Article.service";

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

class MascotContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = "bi";
    const thirdMenu = "mascot";
    this.state = {
      subMenu,
      subMenuName: "CI",
      thirdMenu,
      thirdMenuName: "마스코트",
      popupObj: {
        onShow: false,
        imgUrl: "",
        onZoom: false
      }
    };
  }

  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "05";
    currentAnalyticsVal.deps4 = "04";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);
    console.log("componentDidMount");
  };

  onClickBtnDownload = (imgUrl, filename) => {
    fetch(imgUrl).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  onClickBtnShowPopup = imgSeq => {
    let popupObj = this.state.popupObj;
    popupObj["onShow"] = true;
    popupObj["onZoom"] = false;
    popupObj["imgUrl"] = `/img/sub/mascot${imgSeq}.png`;
    this.setState({ popupObj });
  };

  onClickBtnZoomIn = _ => {
    let popupObj = this.state.popupObj;
    popupObj["onZoom"] = true;
    this.setState({ popupObj });
  };
  onClickBtnZoomOut = _ => {
    let popupObj = this.state.popupObj;
    popupObj["onZoom"] = false;
    this.setState({ popupObj });
  };

  onClickBtnClosePopup = _ => {
    let popupObj = this.state.popupObj;
    popupObj["onShow"] = false;
    popupObj["onZoom"] = false;
    this.setState({ popupObj });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        onClickBtnDownload={this.onClickBtnDownload}
        onClickBtnShowPopup={this.onClickBtnShowPopup}
        onClickBtnClosePopup={this.onClickBtnClosePopup}
        onClickBtnZoomIn={this.onClickBtnZoomIn}
        onClickBtnZoomOut={this.onClickBtnZoomOut}
        popupObj={this.state.popupObj}
      />
    </div>
  );
}

export default MascotContainer;
