import React from "react";

import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const ContComponent = (props) => (
  <div className="mb_cont membership" style={{
    marginTop: '0'
  }}>
    <div className="mb_contInTop" style={{
      textAlign: 'center'
    }}>
      <img src="/img/sub/mascot00.png" alt="기아 타이거즈 마스코트" style={{
        margin: '0 auto',
        display: 'block'
      }}/>
      <h2 className="mb_contInTopTit" style={{
        fontSize: '1.5rem',
      }}>다시 APP으로 이동해 주세요.</h2>
    </div>
  </div>
);

export default ContComponent;
