import React from "react";
export const YearRecordListComponent = (props) =>
  props.info.position2 === "투" ? (
    <Pitcher {...props} />
  ) : (
    <Hitter {...props} />
  );

const Pitcher = (props) => (
  <table>
    <caption>통산기록</caption>
    <colgroup>
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">연도</th>
        <th scope="col">소속</th>
        <th scope="col">승</th>
        <th scope="col">패</th>
        <th scope="col">세</th>
        <th scope="col">홀드</th>
        <th scope="col">이닝</th>
        <th scope="col">투구수</th>
        <th scope="col">피안타</th>
        <th scope="col">피홈런</th>
        <th scope="col">4구</th>
        <th scope="col">사구</th>
        <th scope="col">삼진</th>
        <th scope="col">실점</th>
        <th scope="col">자책</th>
        <th scope="col">평균자책점</th>
      </tr>
    </thead>
    <tbody>
      {props.yearrecordlist.length > 0 ? (
        props.yearrecordlist.map((item, idx) => (
          <tr key={idx}>
            <td>{item.gyear}</td>
            <td>{item.teamName}</td>
            <td>{item.w}</td>
            <td>{item.l}</td>
            <td>{item.sv}</td>
            <td>{item.hold}</td>
            <td>{item.innDisplay}</td>
            <td>{item.tugucount}</td>
            <td>{item.hit}</td>
            <td>{item.hr}</td>
            <td>{item.bb}</td>
            <td>{item.hp}</td>
            <td>{item.kk}</td>
            <td>{item.r}</td>
            <td>{item.er}</td>
            <td>{item.era}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="16">조회된 기록이 없습니다.</td>
        </tr>
      )}
    </tbody>
  </table>
);

const Hitter = (props) => (
  <table>
    <caption>통산기록</caption>
    <colgroup>
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th scope="col">연도</th>
        <th scope="col">소속</th>
        <th scope="col">타수</th>
        <th scope="col">득점</th>
        <th scope="col">안타</th>
        <th scope="col">2타</th>
        <th scope="col">3타</th>
        <th scope="col">홈런</th>
        <th scope="col">타점</th>
        <th scope="col">도루</th>
        <th scope="col">4구</th>
        <th scope="col">삼진</th>
        <th scope="col">병살</th>
        <th scope="col">타율</th>
        <th scope="col">장타율</th>
        <th scope="col">출루율</th>
      </tr>
    </thead>
    <tbody>
      {props.yearrecordlist.length > 0 ? (
        props.yearrecordlist.map((item, idx) => (
          <tr key={idx}>
            <td>{item.gyear}</td>
            <td>{item.teamName}</td>
            <td>{item.ab}</td>
            <td>{item.run}</td>
            <td>{item.hit}</td>
            <td>{item.h2}</td>
            <td>{item.h3}</td>
            <td>{item.hr}</td>
            <td>{item.rbi}</td>
            <td>{item.sb}</td>
            <td>{item.bb}</td>
            <td>{item.kk}</td>
            <td>{item.gd}</td>
            <td>{item.hra}</td>
            <td>{item.slg}</td>
            <td>{item.bra}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="16">조회된 기록이 없습니다.</td>
        </tr>
      )}
    </tbody>
  </table>
);

export default YearRecordListComponent;
