import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TeamRankingContainer from './team-ranking/TeamRanking.container';
import RankingHistoryContainer from './ranking-history/RankingHistory.container';
import PitcherRankingContainer from './pitcher-ranking/PitcherRanking.container';
import HitterRankingContainer from './hitter-ranking/HitterRanking.container';
import ConditionRankingContainer from './condition-ranking/ConditionRanking.container';
import CompareContainer from './compare/Compare.container';
import CalculationContainer from './calculation/Calculation.container';

class StatsContainer extends Component {
    constructor(props) {
        super(props);
        // const subMenuName = props.match.url.split('/')[2];
        // this.state = {
        //     subMenuName,
        //     list: [],
        //     pictureId: null
        // }
    }

    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/team-ranking`} component={TeamRankingContainer} />
            <Route path={`${this.props.match.path}/ranking-history`} component={RankingHistoryContainer} />
            <Route path={`${this.props.match.path}/pitcher-ranking`} component={PitcherRankingContainer} />
            <Route path={`${this.props.match.path}/hitter-ranking`} component={HitterRankingContainer} />
            <Route path={`${this.props.match.path}/condition-ranking`} component={ConditionRankingContainer} />
            <Route path={`${this.props.match.path}/compare`} component={CompareContainer} />
            <Route path={`${this.props.match.path}/calculation`} component={CalculationContainer} />

            <Redirect from={`${this.props.match.path}`} to={`${this.props.match.path}/team-ranking`} />
        </Switch>
    )
}

export default StatsContainer;