import React, {Component} from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as AuthHelper from "../../../helpers/Auth.helper";
import * as CommonService from "../../../services/Common.service";

import * as ResourceService from "../../../services/Resource.service";

import AuthNameConfirmPopup from "../../common/AuthNameConfirmPopup.component";

import $ from "jquery";
import AnalyticsContext from "../../../context/AnalyticsContext";
import AlertPopupComponent from "../../common/AlertPopup.component";

//const baseUrl = 'http://alpha-facility.ticketlink.co.kr/facility/direct/member/reserve?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=';
const baseUrl =
  process.env.REACT_APP_TICKET_URL +
  "/facility/direct/member/reserve?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=";
const redirectUrl = "/app";

const myPageBaseUrl =
  process.env.REACT_APP_TICKET_URL +
  "/facility/direct/member/mypage?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=";


  window.gtag('event', "TicketView"); //GA
class ReservationContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      subMenu: "reservation",
      subMenuName: "티켓 안내 및 예매",
      reservationUrl: "",
      imgUrlObj: null,
      isOnShowPopup: false,

      imgObj: {
        isInit: false,
        naturalImgWidth: 0,
        naturalImgHeight: 0,
        ratio: 1,
        ticketMapHeight: 0,
      },
      showTicketNotice: false,
      alertTitle: "<문화누리카드 할인정책 변경안내>",
      alertMsg: "안녕하세요 기아타이거즈입니다. \n" +
        "문화누리카드 할인이 티켓 재판매에 악용되는 등, \n" +
        "폐해가 누적되어 6월28일 경기(6월 21일 예매개시)부터\n" +
        "다음과 같이 할인정책이 변경됩니다. \n\n" +
        "기존 : K9,K8,K5,K3,외야석 성인권종 40%할인 \n" +
        "변경 : K5,K3,외야석 성인권종 40%할인\n" +
        "(K9,K8 할인제외)",

    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "05";
    currentAnalyticsVal.deps3 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    $(window).resize(this.onResizeWindow);
    const pcResult = await ResourceService.getTickerReservationImg();
    const mResult = await ResourceService.getTickerReservationImg(false);
    const stadiumResult = await ResourceService.getChampionsFieldGuideImg();
    const seasonPassResultPc = await ResourceService.getSeasonPassImg();
    const seasonPassResultM = await ResourceService.getSeasonPassImg(false);

    const imgUrlObj = {
      pc: pcResult.resource.value,
      m: mResult.resource.value,
      stadium: stadiumResult.resource.value,
      seasonPassPc: seasonPassResultPc.resource.value,
      seasonPassM: seasonPassResultM.resource.value
    };

    this.setState({ imgUrlObj });
  };

  componentWillUnmount = (_) => {
    $(window).unbind("resize");
  };

  onClickBtnReservation = async (_) => {
    console.log("!!");
    try {
      if (!AuthHelper.checkHasLogin()) {
        alert("로그인 이후 이용 가능합니다.")
        AuthHelper.removeLoginInfo();
        document.location.href = '/login?redirect=/ticket/reservation';
        return false;
      } else if (!AuthHelper.checkHasRealName()) {
        this.setState({ isOnShowPopup: true });
        return false;
      }
      /* 입장권 판매기간이 되었을 때 주석 제거. */

      // this.setState({showTicketNotice: true});
      //
      await this.onClickBtnOk();
      // const result = await CommonService.getEncryptedId();
      // // result.status === 8004
      // const checkId = localStorage.getItem("loginID");
      // const returnId = result.loginId;
      //
      // if(checkId !== returnId || result.status === 8004){
      //   alert("로그인 정보가 변경되었습니다. 다시 로그인 해주세요.");
      //   AuthHelper.removeLoginInfo();
      //   document.location.href = '/login?redirect=/ticket/reservation';
      //   return false;
      // }
      //
      // const encryptedStr = result.encryptedStr;
      //
      // const url = `${baseUrl}${encryptedStr}`;
      //
      //
      // alert("<문화누리카드 할인정책 변경안내>\n" +
      //   "\n" +
      //   "안녕하세요 기아타이거즈입니다.  \n" +
      //   "문화누리카드 할인이 티켓 재판매에 악용되는 등, \n" +
      //   "폐해가 누적되어 6월28일 경기\n" +
      //   "(6월 21일 11시 예매개시)분 부터\n" +
      //   "다음과 같이 할인정책이 변경됩니다. \n" +
      //   "기존 : K9,K8,K5,K3,외야석 성인권종 40%할인 \n" +
      //   "변경 : K5,K3,외야석 성인권종 40%할인(K9,K8 할인제외)");
      //
      // window.open(url, "");

      // alert("티켓 예매는 금일 17:00 부터 가능합니다.");
    } catch (err) {
      console.error(err);

      alert(err.message);
    }
  };

  /* 예매 취소 버튼 */
  onClickBtnCancelReservation = async() => {
    console.log("c");
    try {
      if (!AuthHelper.checkHasLogin()) {
        alert("로그인 이후 이용 가능합니다.")
        AuthHelper.removeLoginInfo();
        document.location.href = '/login?redirect=/ticket/reservation';
        return false;
      } else if (!AuthHelper.checkHasRealName()) {
        this.setState({ isOnShowPopup: true });
        return false;
      }

      const result = await CommonService.getEncryptedId();
      const checkId = localStorage.getItem("loginID");
      const returnId = result.loginId;

      if(checkId !== returnId || result.status === 8004){
        alert("로그인 정보가 변경되었습니다. 다시 로그인 해주세요.");
        AuthHelper.removeLoginInfo();
        document.location.href = '/login?redirect=/ticket/reservation';
        return false;
      }

      const encryptedStr = result.encryptedStr;

      const url = `${myPageBaseUrl}${encryptedStr}`;

      window.open(url, "");

    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }



  onClickBtnRedirectStore = (_) => {
    const url = `${redirectUrl}`;

    window.open(url, "");
  };

  onClickBtnCancelPopup = (_) => {
    this.setState({ isOnShowPopup: false });
  };

  onClickBtnConfirmPopup = (_) => {
    this.props.history.push("/mypage");
  };

  onLoadImg = ({ target: img }) => {
    console.log("onLoadImg");
    console.log(img.naturalWidth);
    console.log(img.naturalHeight);

    let imgObj = this.state.imgObj;
    imgObj.naturalWidth = img.naturalWidth;
    imgObj.naturalHeight = img.naturalHeight;
    imgObj.ratio = imgObj.naturalWidth / imgObj.naturalHeight;
    this.setState({ imgObj }, (_) => $(window).trigger("resize"));
  };

  onResizeWindow = (evt) => {
    let imgObj = this.state.imgObj;
    const ticketMapWidth = $(".ticketMap").width();
    const ticketMapHeight = ticketMapWidth / this.state.imgObj.ratio;
    if (!imgObj.isInit) {
      imgObj.isInit = true;
    }
    imgObj.ticketMapHeight = ticketMapHeight;
    this.setState({ imgObj });
  };

  generateTicketUrl = async (_) => {
    const result = await CommonService.getEncryptedId();
    const checkId = localStorage.getItem("loginID");
    const returnId = result.loginId;

    if (checkId !== returnId || result.status === 8004) {
      alert("로그인 정보가 변경되었습니다. 다시 로그인 해주세요.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/ticket/reservation';
      return false;
    }
    const encryptedStr = result.encryptedStr;

    return `${baseUrl}${encryptedStr}`;
  }

  onClickBtnOk = async (_) => {
    this.setState({showTicketNotice: false});

    const url = await this.generateTicketUrl();
    window.open(url, "");
  };

  onClickBtnCancelPopup = (_) => {
    this.setState({showTicketNotice: false});
  }



  render = (_) => (
    <div className="container">
      {this.state.showTicketNotice ? (
      <AlertPopupComponent
        onClickBtnOk={this.onClickBtnOk}
        onClickBtnclose={this.onClickBtnCancelPopup}
        message={this.state.alertMsg}
        title={this.state.alertTitle}
      />
        ) : null}
        {console.log("process.env.REACT_APP_PREPARING_TICKET_PAGE_YN???", process.env.REACT_APP_PREPARING_TICKET_PAGE_YN)}
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      {process.env.REACT_APP_PREPARING_TICKET_PAGE_YN === "Y" ? (
        <div className="mb_cont intro1">
          <div className="mb_contIn">
            <div style={{ marginTop: "50px" }}>
              <img
                  src="/img/seasonstop_2024_pc.png"
                  alt="season_off_pc"
                  className="pc"
                  />
                <img
                  src="/img/seasonstop_2024_mobile.png"
                  alt="season_off_mb"
                  className="greeting_mb tb"
                />
              </div>
            </div>
          </div>
      ) : (
      <div>
      <ContComponent
        onClickBtnRedirectStore={this.onClickBtnRedirectStore}
        onClickBtnReservation={this.onClickBtnReservation}
        onClickBtnCancelReservation={this.onClickBtnCancelReservation}
        imgObj={this.state.imgObj}
        imgUrlObj={this.state.imgUrlObj}
      />
      {this.state.isOnShowPopup ? (
        <AuthNameConfirmPopup
          isOnShowPopup={this.state.isOnShowPopup}
          onClickBtnCancel={this.onClickBtnCancelPopup}
          onClickBtnConfirm={this.onClickBtnConfirmPopup}
        />
      ) : null}
      {this.state.imgUrlObj ? (
        <div style={{ display: "none" }}>
          <img src={this.state.imgUrlObj.stadium} onLoad={this.onLoadImg} />
        </div>
      ) : null}
      </div>
      )}
    </div>
  );
}

export default ReservationContainer;
