import React from 'react';
import SubMenuComponent from '../common/SubMenu.container';
const ContEmailComponent = props => (
	<div className="mb_cont sub_cont policy">
		<div className="mb_contIn">
			<SubMenuComponent
				subMenu={props.subMenu} />
			<article>
				<h5>이메일 무단수집 거부</h5>
				<div className="polyBox">
					KIA타이거즈 회원에게 무차별적으로 보내지는 타사의 메일을 차단하기위해, 본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.<br /><br />
					[게시일 2006년 7월 12일]
				</div>
			</article>
		</div>
	</div>
)

export default ContEmailComponent;