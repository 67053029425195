import React from "react";

import Slider from "react-slick";
import ImgComponent from "../common/Img.component";
import * as ImageHelper from "../../helpers/Image.helper";
const options = {
  autoplay: true,
  speed: 500,
  infinite: true,
  autoplaySpeed: 2000,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  fade: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlideHotIssueComponent = (props) => (
  <div className="m0auto">
    <section className="hotISU">
      <h3>
        <span>HOT </span> ISSUE
      </h3>

      <legend className="blind">
        {props.list.length < 3
          ? (options.slidesToShow = props.list.length)
          : null}
        {props.list.length < 2
          ? (options.responsive[0].settings.slidesToShow = props.list.length)
          : null}{" "}
        게시글 개수
      </legend>
      <div
        className="sl3"
        style={{
          margin: "0 auto",
          display: "block",
          maxWidth: "calc(397px*" + props.list.length + ")",
          minWidth: "345px",
        }}
      >
        <Slider
          ref={(slider) => props.setSliderRef("slide-hot-issue", slider)}
          {...options}
        >
          {props.list.map((item, idx) => (
            <div
              onClick={(evt) =>
                props.onClickBtnHotIssue(evt, item.boardCode, item.artcSeq)
              }
              className="hotBox"
              key={idx}
            >
              <p className="hotImg">
                <ImgComponent
                  imgUrl={item.imgFilePath}
                  alt={item.artcTitle}
                  defaultImgUrl={ImageHelper.getDefaultHotIssueItemImg()}
                />
              </p>
              <div className="hotTxt">
                <dl>
                  <dt>{item.boardName}</dt>
                  <dd>{item.artcTitle}</dd>
                  <dd>
                  {item.artcContent ? item.artcContent
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/(&[a-zA-Z]+;)/gi, "")
                    : ''}
                  </dd>
                </dl>
                <ul>
                  <li>
                    <span className="icoDate"></span>
                    {item.regDate}
                  </li>
                  <li>
                    <span className="icoNum"></span>
                    {item.viewCnt}
                  </li>
                  <li>
                    <span className="icoGood"></span>
                    {item.likeCnt}
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {props.list.length > 3 ? (
        <div>
          <p
            onClick={(evt) => props.onClickBtnSlidePrev(evt, "slide-hot-issue")}
            className="sl3_prev"
          >
            <img src="./img/main/sl3_prev.png" alt="slide3이전" />
          </p>
          <p
            onClick={(evt) => props.onClickBtnSlideNext(evt, "slide-hot-issue")}
            className="sl3_next"
          >
            <img src="./img/main/sl3_next.png" alt="slide3다음" />
          </p>
        </div>
      ) : (
        <div className="mb tb">
          <p
            onClick={(evt) => props.onClickBtnSlidePrev(evt, "slide-hot-issue")}
            className="sl3_prev"
          >
            <img src="./img/main/sl3_prev.png" alt="slide3이전" />
          </p>
          <p
            onClick={(evt) => props.onClickBtnSlideNext(evt, "slide-hot-issue")}
            className="sl3_next"
          >
            <img src="./img/main/sl3_next.png" alt="slide3다음" />
          </p>
        </div>
      )}
    </section>
    {/* <!-- //vs_slide3 --> */}
  </div>
);

export default SlideHotIssueComponent;
