import React from "react";

import CKEditor from "ckeditor4-react";

// const config = {
//     toolbar: [
//         ['Source', '-', 'NewPage', 'Preview'],
//         ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo'],
//         ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
//         ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
//         '/',
//         ['Styles', 'Format', 'Font', 'FontSize'],
//         ['TextColor', 'BGColor'],
//         ['Table', 'SpecialChar', 'Link', 'Unlink']
//     ]
// }
const config = {
  toolbar: [
    ["Undo", "Redo", "-", "Bold", "Italic", "Underline", "Strike", "FontSize"],
    ["TextColor", "BGColor"],
    ["Table", "SpecialChar", "Link", "Unlink"],
  ],
};

const ContComponent = (props) => (
  <div className="mb_cont membership">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">게시글 작성</h6>
      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <legend>회원가입양식</legend>
          <ul>
            <li>
              <label htmlFor="tit">제목</label>
            </li>
            <li className="tit">
              <input
                type="text"
                id="tit"
                placeholder="제목을 입력해주세요."
                onChange={props.onChangeInputTitle}
                value={props.title}
                maxLength="35"
              />
              <p>
                <strong>{props.title.length}</strong>/<span>35</span>
              </p>
            </li>
          </ul>
          <ul className="txt">
            <li>
              <label htmlFor="textfield">내용</label>
            </li>
            <li>
              {/* <CKEditor
                config={config}
                data={props.contents}
                onChange={props.onChangeCKEditor}
              /> */}
              {props.editor}
            </li>
          </ul>
        </fieldset>
      </form>
      <div className="inQBtn clear">
        <button
          onClick={() =>
            props.userPermissionByInfo === "Y" && props.onClickBtnCreate()
          }
          type="button"
        >
          등록하기
        </button>
        <button
          onClick={props.onClickBtnCancel}
          type="button"
          className="fileAdd"
        >
          취소
        </button>
      </div>
    </div>
  </div>
);

export default ContComponent;
