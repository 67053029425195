import React from "react";

const ContComponent = props => (
  <div className="rankingTeam">
    <section>
      <h6>팀순위</h6>
      {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
      {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
      <div className="mbTable mb">
        <ul>
          <li>순위</li>
          {props.teamRankingList.map((item, idx) => (
            <li key={idx} className={item.teamCode === "HT" ? "kia" : ""}>
              {item.rank}
            </li>
          ))}
        </ul>
        <ul>
          <li>팀명</li>
          {props.teamRankingList.map((item, idx) => (
            <li key={idx} className={item.teamCode === "HT" ? "kia" : ""}>
              {item.teamName}
            </li>
          ))}
        </ul>
      </div>
      {/* <!--//mbTable --> */}
      <div className="table_Typ01 mbScroll w13 mbH2wM">
        {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
        <table>
          <caption>엔트리현황, 선수 현황표</caption>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
          <tr>
            <th scope="col">순위</th>
            <th scope="col">팀명</th>
            <th scope="col">경기</th>
            <th scope="col">승</th>
            <th scope="col">패</th>
            <th scope="col">무</th>
            <th scope="col" className="pc">
              홈승패
            </th>
            <th scope="col" className="pc">
              원정승패
            </th>
            <th scope="col" className="pc">
              팀타율
            </th>
            <th scope="col" className="pc">
              팀평균자책점
            </th>
            <th scope="col">승률</th>
            <th scope="col">승차</th>
            <th scope="col" className="pc">
              연속
            </th>
          </tr>
          </thead>
          <tbody>
          {props.teamRankingList.map((item, idx) => (
            <tr key={idx} className={item.teamCode === "HT" ? "kia" : ""}>
              <th scope="row">{item.rank}</th>
              <th scope="row">{item.teamName}</th>
              <td>{item.game}</td>
              <td>{item.win}</td>
              <td>{item.lose}</td>
              <td>{item.drawn}</td>
              <td className="pc">{item.homeWld}</td>
              <td className="pc">{item.visitWld}</td>
              <td className="pc">{(parseFloat(item.hra)).toFixed(3)}</td>
              <td className="pc">{item.era}</td>
              <td>{item.wra}</td>
              <td>{item.gb}</td>
              <td className="pc">{item.continue1}</td>
            </tr>
          ))}
          {/* <tr>
                            <th scope="row">2</th>
                            <th scope="row">키움</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <th scope="row">SSG</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <th scope="row">LG</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <th scope="row">NC</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <th scope="row">KT</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr className="kia">
                            <th scope="row">7</th>
                            <th scope="row">KIA</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <th scope="row">삼성</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <th scope="row">한화</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <th scope="row">롯데</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="pc">&nbsp;</td>
                        </tr> */}
          </tbody>
        </table>
      </div>
      {/* <!--//table_Typ01 --> */}
    </section>
    <section>
      <h6>팀간 승패</h6>
      <div className="srch pc">
        <p
          className="btn_srch"
          onClick={props.onClickBtnSearch}
          style={{ cursor: "pointer" }}
        >
          검색
        </p>
        <p>
          <label htmlFor="cell" className="blind">
            요일
          </label>
          <select
            defaultValue={props.teamRankingSelectListObj.selectedDayOfWeek}
            onChange={evt =>
              props.onChangeSelectList(
                evt,
                "teamRankingSelectListObj",
                "selectedDayOfWeek"
              )
            }
            id="cell"
          >
            <option value="">요일</option>
            {props.dayOfWeekSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label htmlFor="cell" className="blind">
            홈/원정
          </label>
          <select
            id="cell"
            onChange={evt =>
              props.onChangeSelectList(
                evt,
                "teamRankingSelectListObj",
                "selectedHomeAwayFlag"
              )
            }
          >
            <option value="">홈/원정</option>
            <option value="1">홈</option>
            <option value="2">원정</option>
          </select>
        </p>
        <p>
          <label htmlFor="cell" className="blind">
            주/야간
          </label>
          <select
            id="cell"
            onChange={evt =>
              props.onChangeSelectList(
                evt,
                "teamRankingSelectListObj",
                "selectedDayNightFlag"
              )
            }
          >
            <option value="">주/야간</option>
            <option value="day">주</option>
            <option value="night">야간</option>
          </select>
        </p>
        <p>
          <label htmlFor="cell" className="blind">
            월
          </label>
          <select
            id="cell"
            value={props.teamRankingSelectListObj.selectedMonth}
            onChange={evt =>
              props.onChangeSelectList(
                evt,
                "teamRankingSelectListObj",
                "selectedMonth"
              )
            }
          >
            <option value="">월</option>
            {props.monthSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label htmlFor="cell" className="blind">
            년도
          </label>
          <select
            id="cell"
            defaultValue={props.teamRankingSelectListObj.selectedYear}
            onChange={evt =>
              props.onChangeSelectList(
                evt,
                "teamRankingSelectListObj",
                "selectedYear"
              )
            }
          >
            {props.yearSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
        </p>
      </div>
      {/* <!-- [N]::모바일일때 table_Type01의 tableBody head --> */}
      {Object.keys(props.winLoseListObj).length !== 0 && (
        <div className="mbTable mb">
          {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
          <ul>
            <li>팀명</li>
            <li>두산</li>
            <li>키움</li>
            <li>SSG</li>
            <li>LG</li>
            <li>NC</li>
            <li>KT</li>
            <li>KIA</li>
            <li>삼성</li>
            <li>한화</li>
            <li>롯데</li>
            <li className="tfoot">합계</li>
          </ul>
        </div>
      )}
      {/* <!--//mbTable --> */}

      <div
        className={
          Object.keys(props.winLoseListObj).length > 0
            ? "table_Typ01 mbScroll thFix1 w12"
            : "noPlayer"
        }
      >
        {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
        {Object.keys(props.winLoseListObj).length === 0 ? (
          <table>
            <tbody>
            <tr>
              <td colSpan="12">시즌 경기 데이터가 존재하지 않습니다.</td>
            </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <caption>엔트리현황, 선수 현황표</caption>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
            <tr>
              <th scope="col">팀명</th>
              <th scope="col">두산</th>
              <th scope="col">키움</th>
              <th scope="col">SSG</th>
              <th scope="col">LG</th>
              <th scope="col">NC</th>
              <th scope="col">KT</th>
              <th scope="col">KIA</th>
              <th scope="col">삼성</th>
              <th scope="col">한화</th>
              <th scope="col">롯데</th>
              <th scope="col">합계</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">두산</th>
              <td>{props.winLoseListObj["OB"]["OB"]}</td>
              <td>{props.winLoseListObj["OB"]["WO"]}</td>
              <td>{props.winLoseListObj["OB"]["SK"]}</td>
              <td>{props.winLoseListObj["OB"]["LG"]}</td>
              <td>{props.winLoseListObj["OB"]["NC"]}</td>
              <td>{props.winLoseListObj["OB"]["KT"]}</td>
              <td>{props.winLoseListObj["OB"]["HT"]}</td>
              <td>{props.winLoseListObj["OB"]["SS"]}</td>
              <td>{props.winLoseListObj["OB"]["HH"]}</td>
              <td>{props.winLoseListObj["OB"]["LT"]}</td>
              <td>{props.winLoseListObj["OB"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">키움</th>
              <td>{props.winLoseListObj["WO"]["OB"]}</td>
              <td>{props.winLoseListObj["WO"]["WO"]}</td>
              <td>{props.winLoseListObj["WO"]["SK"]}</td>
              <td>{props.winLoseListObj["WO"]["LG"]}</td>
              <td>{props.winLoseListObj["WO"]["NC"]}</td>
              <td>{props.winLoseListObj["WO"]["KT"]}</td>
              <td>{props.winLoseListObj["WO"]["HT"]}</td>
              <td>{props.winLoseListObj["WO"]["SS"]}</td>
              <td>{props.winLoseListObj["WO"]["HH"]}</td>
              <td>{props.winLoseListObj["WO"]["LT"]}</td>
              <td>{props.winLoseListObj["WO"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">SSG</th>
              <td>{props.winLoseListObj["SK"]["OB"]}</td>
              <td>{props.winLoseListObj["SK"]["WO"]}</td>
              <td>{props.winLoseListObj["SK"]["SK"]}</td>
              <td>{props.winLoseListObj["SK"]["LG"]}</td>
              <td>{props.winLoseListObj["SK"]["NC"]}</td>
              <td>{props.winLoseListObj["SK"]["KT"]}</td>
              <td>{props.winLoseListObj["SK"]["HT"]}</td>
              <td>{props.winLoseListObj["SK"]["SS"]}</td>
              <td>{props.winLoseListObj["SK"]["HH"]}</td>
              <td>{props.winLoseListObj["SK"]["LT"]}</td>
              <td>{props.winLoseListObj["SK"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">LG</th>
              <td>{props.winLoseListObj["LG"]["OB"]}</td>
              <td>{props.winLoseListObj["LG"]["WO"]}</td>
              <td>{props.winLoseListObj["LG"]["SK"]}</td>
              <td>{props.winLoseListObj["LG"]["LG"]}</td>
              <td>{props.winLoseListObj["LG"]["NC"]}</td>
              <td>{props.winLoseListObj["LG"]["KT"]}</td>
              <td>{props.winLoseListObj["LG"]["HT"]}</td>
              <td>{props.winLoseListObj["LG"]["SS"]}</td>
              <td>{props.winLoseListObj["LG"]["HH"]}</td>
              <td>{props.winLoseListObj["LG"]["LT"]}</td>
              <td>{props.winLoseListObj["LG"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">NC</th>
              <td>{props.winLoseListObj["NC"]["OB"]}</td>
              <td>{props.winLoseListObj["NC"]["WO"]}</td>
              <td>{props.winLoseListObj["NC"]["SK"]}</td>
              <td>{props.winLoseListObj["NC"]["LG"]}</td>
              <td>{props.winLoseListObj["NC"]["NC"]}</td>
              <td>{props.winLoseListObj["NC"]["KT"]}</td>
              <td>{props.winLoseListObj["NC"]["HT"]}</td>
              <td>{props.winLoseListObj["NC"]["SS"]}</td>
              <td>{props.winLoseListObj["NC"]["HH"]}</td>
              <td>{props.winLoseListObj["NC"]["LT"]}</td>
              <td>{props.winLoseListObj["NC"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">KT</th>
              <td>{props.winLoseListObj["KT"]["OB"]}</td>
              <td>{props.winLoseListObj["KT"]["WO"]}</td>
              <td>{props.winLoseListObj["KT"]["SK"]}</td>
              <td>{props.winLoseListObj["KT"]["LG"]}</td>
              <td>{props.winLoseListObj["KT"]["NC"]}</td>
              <td>{props.winLoseListObj["KT"]["KT"]}</td>
              <td>{props.winLoseListObj["KT"]["HT"]}</td>
              <td>{props.winLoseListObj["KT"]["SS"]}</td>
              <td>{props.winLoseListObj["KT"]["HH"]}</td>
              <td>{props.winLoseListObj["KT"]["LT"]}</td>
              <td>{props.winLoseListObj["KT"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">KIA</th>
              <td>{props.winLoseListObj["HT"]["OB"]}</td>
              <td>{props.winLoseListObj["HT"]["WO"]}</td>
              <td>{props.winLoseListObj["HT"]["SK"]}</td>
              <td>{props.winLoseListObj["HT"]["LG"]}</td>
              <td>{props.winLoseListObj["HT"]["NC"]}</td>
              <td>{props.winLoseListObj["HT"]["KT"]}</td>
              <td>{props.winLoseListObj["HT"]["HT"]}</td>
              <td>{props.winLoseListObj["HT"]["SS"]}</td>
              <td>{props.winLoseListObj["HT"]["HH"]}</td>
              <td>{props.winLoseListObj["HT"]["LT"]}</td>
              <td>{props.winLoseListObj["HT"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">삼성</th>
              <td>{props.winLoseListObj["SS"]["OB"]}</td>
              <td>{props.winLoseListObj["SS"]["WO"]}</td>
              <td>{props.winLoseListObj["SS"]["SK"]}</td>
              <td>{props.winLoseListObj["SS"]["LG"]}</td>
              <td>{props.winLoseListObj["SS"]["NC"]}</td>
              <td>{props.winLoseListObj["SS"]["KT"]}</td>
              <td>{props.winLoseListObj["SS"]["HT"]}</td>
              <td>{props.winLoseListObj["SS"]["SS"]}</td>
              <td>{props.winLoseListObj["SS"]["HH"]}</td>
              <td>{props.winLoseListObj["SS"]["LT"]}</td>
              <td>{props.winLoseListObj["SS"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">한화</th>
              <td>{props.winLoseListObj["HH"]["OB"]}</td>
              <td>{props.winLoseListObj["HH"]["WO"]}</td>
              <td>{props.winLoseListObj["HH"]["SK"]}</td>
              <td>{props.winLoseListObj["HH"]["LG"]}</td>
              <td>{props.winLoseListObj["HH"]["NC"]}</td>
              <td>{props.winLoseListObj["HH"]["KT"]}</td>
              <td>{props.winLoseListObj["HH"]["HT"]}</td>
              <td>{props.winLoseListObj["HH"]["SS"]}</td>
              <td>{props.winLoseListObj["HH"]["HH"]}</td>
              <td>{props.winLoseListObj["HH"]["LT"]}</td>
              <td>{props.winLoseListObj["HH"]["homeTotal"]}</td>
            </tr>
            <tr>
              <th scope="row">롯데</th>
              <td>{props.winLoseListObj["LT"]["OB"]}</td>
              <td>{props.winLoseListObj["LT"]["WO"]}</td>
              <td>{props.winLoseListObj["LT"]["SK"]}</td>
              <td>{props.winLoseListObj["LT"]["LG"]}</td>
              <td>{props.winLoseListObj["LT"]["NC"]}</td>
              <td>{props.winLoseListObj["LT"]["KT"]}</td>
              <td>{props.winLoseListObj["LT"]["HT"]}</td>
              <td>{props.winLoseListObj["LT"]["SS"]}</td>
              <td>{props.winLoseListObj["LT"]["HH"]}</td>
              <td>{props.winLoseListObj["LT"]["LT"]}</td>
              <td>{props.winLoseListObj["LT"]["homeTotal"]}</td>
            </tr>
            <tr className="tfoot">
              <th scope="row">합계</th>
              <td>{props.winLoseListObj["OB"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["WO"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["SK"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["LG"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["NC"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["KT"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["HT"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["SS"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["HH"]["awayTotal"]}</td>
              <td>{props.winLoseListObj["LT"]["awayTotal"]}</td>
              <td>&nbsp;</td>
            </tr>
            {/* <!--  */}
            {/* --> */}
            </tbody>
          </table>
        )}
      </div>
      {/* <!--//table_Typ01 --> */}
    </section>
  </div>
);

export default ContComponent;
