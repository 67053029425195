import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as DateHelper from "../../../../helpers/Date.helper";
import * as GameService from "../../../../services/Game.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

const columnHeaderList = [
  { key: "rank", name: "순위", sorting: "", isSortable: false },
  { key: "playerName", name: "선수명", sorting: "", isSortable: false },
  { key: "teamName", name: "팀명", sorting: "", isSortable: false },
  { key: "gamenum", name: "경기", sorting: "", isSortable: true },
  {
    key: "ab",
    name: "타수",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  { key: "run", name: "득점", sorting: "", isSortable: true },
  { key: "hit", name: "안타", sorting: "", isSortable: true },
  { key: "h2", name: "2타", sorting: "", isSortable: true, className: "pc_tb" },
  { key: "h3", name: "3타", sorting: "", isSortable: true, className: "pc_tb" },
  { key: "hr", name: "홈런", sorting: "", isSortable: true },
  { key: "rbi", name: "타점", sorting: "", isSortable: true },
  { key: "sb", name: "도루", sorting: "", isSortable: true },
  {
    key: "bb",
    name: "사구",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  {
    key: "kk",
    name: "삼진",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  {
    key: "gd",
    name: "병살",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  {
    key: "sh",
    name: "희타",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  {
    key: "sf",
    name: "희비",
    sorting: "",
    isSortable: true,
    className: "pc_tb",
  },
  { key: "hra", name: "타율", sorting: "", isSortable: true },
  { key: "slg", name: "장타율", sorting: "", isSortable: true },
  { key: "bra", name: "출루율", sorting: "", isSortable: true },
];

class HitterRankingContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "타자순위",
      tabNum: 1,

      selectedYear: process.env.REACT_APP_SEASON_YEAR,
      sortingKey: "HRA",
      selectedTeamCode: null,
      yearSelectList: DateHelper.getYearSelectList(),

      columnHeaderList: columnHeaderList,
      rankingResult: null,
      rankingList: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "04";
      currentAnalyticsVal.deps3 = "03"; //
      currentAnalyticsVal.deps4 = "04";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const rankingResult = await GameService.getHitterRanking(
        this.state.selectedYear,
        this.state.sortingKey
      );
      const rankingList = rankingResult.list;
      this.setState({
        rankingResult,
        rankingList,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnSort = async (evt, key) => {
    const sortingKey = key.toUpperCase();
    let params = {};
    if (this.state.tabNum === 2) {
      params["teamCode"] = "KIA";
    }
    const rankingResult = await GameService.getHitterRanking(
      this.state.selectedYear,
      sortingKey,
      params
    );
    const rankingList = rankingResult.list;
    let columnHeaderList = this.state.columnHeaderList;
    for (let i = 0; i < columnHeaderList.length; i++) {
      columnHeaderList[i]["sorting"] =
        columnHeaderList[i]["key"] === key ? "desc" : "";
    }
    this.setState({
      sortingKey,
      columnHeaderList,
      rankingResult,
      rankingList,
    });
  };

  onClickBtnSearch = async (evt, type) => {
    const sortingKey = "HRA";
    let params = {};
    if (this.state.tabNum === 2) {
      params["teamCode"] = "KIA";
    }
    const selectedYear = this.state.selectedYear;

    const rankingResult = await GameService.getHitterRanking(
      selectedYear,
      sortingKey,
      params
    );
    const rankingList = rankingResult.list;
    for (let i = 0; i < columnHeaderList.length; i++) {
      columnHeaderList[i]["sorting"] = "";
    }
    this.setState({
      sortingKey,
      columnHeaderList,
      rankingResult,
      rankingList,
    });
  };

  onChangeSelectList = (evt) => {
    this.setState({ selectedYear: evt.target.value });
  };

  onClickBtnTab = async (evt, tabNum) => {
    const sortingKey = "HRA";
    let params = {};
    if (tabNum === 2) {
      params["teamCode"] = "KIA";
    }
    const rankingResult = await GameService.getHitterRanking(
      this.state.selectedYear,
      sortingKey,
      params
    );
    const rankingList = rankingResult.list;
    let columnHeaderList = this.state.columnHeaderList;
    for (let i = 0; i < columnHeaderList.length; i++) {
      columnHeaderList[i]["sorting"] = "";
    }

    this.setState({
      tabNum,
      rankingResult,
      rankingList,
    });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        tabNum={this.state.tabNum}
        onClickBtnTab={this.onClickBtnTab}
        selectedYear={this.state.selectedYear}
        yearSelectList={this.state.yearSelectList}
        onClickBtnSearch={this.onClickBtnSearch}
        onChangeSelectList={this.onChangeSelectList}
        onClickBtnSort={this.onClickBtnSort}
        columnHeaderList={this.state.columnHeaderList}
        rankingList={this.state.rankingList}
        sortingKey={this.state.sortingKey}
      />
    </div>
  );
}

export default HitterRankingContainer;
