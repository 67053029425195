import React from "react"

const ContComponent = (props) => (
  <div className="mb_cont join">
    <div className="mb_contIn">
      <div className="box">
        <h3>
          <img src="/img/sub/login_ci.png" alt="KIA TIGERS" />
        </h3>
        <p style={{fontWeight: "bold"}}>
          KIA TIGERS에 가입하여 티켓예매, 경기상세기록 등<br />
          다양한 서비스를 편리하게 이용해보세요.
        </p>
        <ul className="clear">
          <li>
            <a href="#" onClick={props.onClickBtnRegular}>
              일반 회원가입
            </a>
          </li>
          {/*<li>*/}
          {/*  <a href="#" onClick={props.onClickBtnChild}>*/}
          {/*    14세 미만 회원가입*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
        <div>
          <p>
            회원등급 안내
            <br />- 회원등급 정책은 매년 변경되며 자세한 내용은 멤버십 안내 페이지를 통해 확인하세요.
          </p>
          {/*<p>[만 14세 미만 회원가입 안내] 만 14세 미만은 법률에 의거하여 보호자(법적대리인)의 동의가 필요합니다.</p>*/}
          {/* <p>
            2. Basic 등급
            <span>- 경기장 1회 이상 방문 시</span>
          </p>
          <p>
            2. 일반 등급
            <span>- 멤버십 회원 가입 시</span>
          </p>
          <p>
            [만 14세 미만 회원가입 안내]
            <br />만 14세 미만은 법률에 의거하여 보호자(법적대리인)의 동의가
            필요합니다.
          </p> */}
        </div>
      </div>
    </div>
  </div>
)

export default ContComponent
