import React, { Component } from 'react';

import SubHeadComponent from '../common/SubHead.component';

import ContComponent from './Cont.component';

class PrivacyContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subMenu: 'privacy',
            subMenuName: "개인정보처리방침"
        }
    }
    render = _ => (
        <div className="container">
            <SubHeadComponent
                subMenuName={this.state.subMenuName} />
            <ContComponent
                subMenu={this.state.subMenu}
            />
        </div>
    )
}

export default PrivacyContainer;