import React, { Component } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import EpisodeContainer from './episode/Episode.container';
import WebzineContainer from './webzine/Webzine.container';

class ArchiveContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/episode`} component={EpisodeContainer} />
            <Route path={`${this.props.match.path}/webzine`} component={WebzineContainer} />
            <Redirect from={`${this.props.match.path}`} to={`${this.props.match.path}/episode`} />
        </Switch>
    )
}

export default ArchiveContainer;