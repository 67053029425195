import React, { Component } from "react";

import ListContainer from "./list/List.container";
import InfoContainer from "./info/Info.container";

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

class FacilityGuideContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    const subMenu = "champions-field";
    const thirdMenu = "facility-guide";

    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "시설안내",
      tabNum: 3,
      mode: "list",
      title: "",
      slideList: []
    };

    console.log("fafa List", this.props.match.path);
  }

  setSliderRef = (key, ref) => {
    console.log("setSliderRef", key, ref);
    if (key === "pc-slider") {
      this.pcSlider = ref;
    } else if (key === "nav-slider") {
      this.navSlider = ref;
    }
  };

  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    this.context.action(currentAnalyticsVal);
    const prevUrl = currentAnalyticsVal.currentUrl;

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "03";
    currentAnalyticsVal.deps4 = "02";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;
    
    await CommonService.insertAnalytics(this.context.state);

    this.setState({
      pcSlider: this.pcSlider,
      navSlider: this.navSlider
    });
  };

  onClickBtnImg = (mainIdx, subIdx) => {
    this.setState({ mode: "info", mainIdx, subIdx });
  };

  onClickBtnShowList = _ => {
    this.setState({ mode: "list" });
  };

  render = () =>
    this.state.mode === "list" ? (
      <ListContainer onClickBtnImg={this.onClickBtnImg} />
    ) : (
      <InfoContainer
        onClickBtnShowList={this.onClickBtnShowList}
        mainIdx={this.state.mainIdx}
        subIdx={this.state.subIdx}
      />
    );
}

export default FacilityGuideContainer;
