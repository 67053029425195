import React from 'react';

import Slider from 'react-slick';

const options = {
    autoplay: true,
    speed: 500,
    arrows: true,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
}

const SlideBannerPcComponent = props => (
    <section className="vs_slide1 clear pc_tb">
        <ul className="sl1">
            <Slider
                ref={slider => props.setSliderRef('slide-banner-pc', slider)}
                {...options}>
                {props.list.map((item, idx) => 
                    <li key={idx}><img style={{width:"100%"}} src={item.pcImgFilePath} alt={item.name} /></li>
                )}
            </Slider>
        </ul>
        <div className="sl1_bg"></div>
        {props.list.length > 1 && (
            <>
                <p onClick={evt => props.onClickBtnSlidePrev(evt, 'slide-banner-pc')} className="sl1_prev"><img src="/img/main/sl1_prev.png" alt="비주얼 슬라이드 이전버튼" /></p>
                <p onClick={evt => props.onClickBtnSlideNext(evt, 'slide-banner-pc')} className="sl1_next"><img src="/img/main/sl1_next.png" alt="비주얼 슬라이드 다음튼" /></p>
            </>
        )}
    </section>
)

export default SlideBannerPcComponent;