import React, { Component } from 'react';
import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';

import * as ResourceService from '../../../services/Resource.service';
import AnalyticsContext from "../../../context/AnalyticsContext"
import * as CommonService from "../../../services/Common.service"

class OrganizationContainer extends Component {
    static contextType = AnalyticsContext;

    constructor(props) {
        super(props);

        const subMenu = 'organization';
        const subMenuName = '조직도'
        this.state = {
            subMenu,
            subMenuName,
            imgUrlObj: null
        }
    }

    componentDidMount = async _ => {
        const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);
    
        currentAnalyticsVal.deps2 = "01";
        currentAnalyticsVal.deps3 = "06";
        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href;
    
        await CommonService.insertAnalytics(this.context.state);

        const pcResult = await ResourceService.getOrganizationImg();

        const imgUrlObj = {
            pc: pcResult.resource.value,
        };

        this.setState({ imgUrlObj });
    }

    render = () => (
        <div className='container'>
            <SubHeadComponent
                subMenuName={this.state.subMenuName}
                thirdMenuName={this.state.thirdMenuName} />
            {/* <div className="sbHead_appView tb"></div> */}

            <ContComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName}
                thirdMenu={this.state.thirdMenu}
                thirdMenuName={this.state.thirdMenuName}
                imgUrlObj={this.state.imgUrlObj}

                tabNum={this.state.tabNum}
                onClickBtnTab={this.onClickBtnTab}
            />
        </div>
    )
}

export default OrganizationContainer;