import React from "react"
import SubMenuComponent from "../common/SubMenu.component"

const ContComponent = (props) => (
  <div className="mb_cont sub_cont tigers">
    <div className="mb_contIn uniform">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      {/* <!-- //tabWrap --> */}
      <article>
        <h5 className="lineRed">홈경기 유니폼</h5>
        <ul>
          <li>
            <img src="/img/sub/uniform/uniform-1.jpg" alt="홈경기유니폼" />
          </li>
        </ul>
      </article>
      <article>
        <h5 className="lineRed">원정경기 유니폼</h5>
        <ul>
          <li>
            <img src="/img/sub/uniform/uniform-2.jpg" alt="원정경기유니폼" />
          </li>
        </ul>
      </article>
      <article>
        <h5 className="lineRed">기타</h5>
        <ul>
          {/* <li>
            <img src="/img/sub/img-uniform-3.png" alt="벨크로모자" />
            <strong>벨크로모자</strong>
          </li> */}
          <li>
            <img src="/img/sub/uniform/jumper1_2024.png" alt="점퍼" />
            <img src="/img/sub/uniform/jumper2_2024.png" alt="점퍼" />
            <strong>점퍼</strong>
          </li>
          <li>
            <img src="/img/sub/uniform/wind1_2024.png" alt="바람막이" />
            <img src="/img/sub/uniform/wind2_2024.png" alt="바람막이" />
            <strong>바람막이</strong>
          </li>
          <li>
            <img src="/img/sub/uniform/hood_2024.png" alt="후드" />
            <strong>후드</strong>
          </li>
          {/* <li>
            <img src="/img/sub/img-uniform-7.png" alt="풀오버" />
            <strong>풀오버</strong>
          </li>
           <li>
            <img src="/img/sub/img-uniform-8.png" alt="배번티셔츠" />
            <strong>배번티셔츠</strong>
          </li>
          <li>
            <img src="/img/sub/img-uniform-9.png" alt="BP티셔츠" />
            <strong>BP티셔츠</strong>
          </li>
          <li>
            <img src="/img/sub/img-uniform-10.png" alt="폴로셔츠" />
            <strong>폴로셔츠</strong>
          </li>
          <li>
            <img src="/img/sub/img-uniform-11.png" alt="아이싱 티셔츠" />
            <strong>아이싱 티셔츠</strong>
          </li> */}
        </ul>
      </article>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
)
export default ContComponent
