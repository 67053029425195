import axios from "axios";

const psnToken = "dummyToken";

const baseUrl = "/v1";

// https://api.kiatigers.info/v1/qna/getQnaList?search.pos=10&search.max=10&qna.isMineYn=N&search.searchCategory=60000&search.searchWord=환불1
export const getList = async (offset, limit, isMineYn, keyword = "") => {
  let url = `${baseUrl}/qna/getQnaList`;
  url += `?search.pos=${offset}`;
  url += `&search.max=${limit}`;
  url += `&qna.isMineYn=${isMineYn}`;
  if (keyword.trim() !== "") {
    url += `&search.searchWord=${keyword}`;
    url += `&search.sort=100`;
  }

  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};

// https://api.kiatigers.info/v1/qna/getQnaDetail
// qna.topicSeq	String	일련번호	필수
export const getInfo = async articleId => {
  let url = `${baseUrl}/qna/getQnaDetail`;
  // let data = new FormData();
  // data.append("qna.topicSeq", articleId);

  const data = {
    "qna.topicSeq": articleId
  }

  return axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getCategoryList = async _ => {
  const url = `${baseUrl}/qna/getQnaCategoryList`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};

// https://api.kiatigers.info/v1/qna/createTopic
export const createInfo = async (
  title,
  contents,
  categoryCode,
  file = null
) => {
  let data = new FormData();
  data.append("qna.topicTitle", title);
  data.append("qna.topicContents", contents);
  data.append("qna.catCode", categoryCode);
  if (file) {
    data.append("file", file);
  }

  const url = `${baseUrl}/qna/createTopic`;
  return axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};

// https://api.kiatigers.info/v1/qna/deleteMyQnaTopic
export const deleteInfo = async articleId => {
  const url = `${baseUrl}/qna/deleteMyQnaTopic`;
  // let data = new FormData();
  // data.append("qna.topicSeq", articleId);

  const data = {
    "qna.topicSeq": articleId
  }

  return axios
    .post(url, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err.message);
    });
};
