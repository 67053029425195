import React from "react";

import Slider from "react-slick";

const options = {
  arrows: false,
  autoplay: true,
  dots: true,
  speed: 700,
  infinite: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
  customPaging: function (i) {
    return (
      <div>
        <img
          src="/img/main/a_tigers_sl_off.png"
          alt="슬라이드 바로가기 아이콘"
        />
        <img
          src="/img/main/a_tigers_sl_on.png"
          alt="슬라이드 바로가기 아이콘"
        />
      </div>
    );
  },
};

const LayerPopupComponent = (props) => (
  <div>
    <div className={props.popup ? "pop_overlay" : ""} />
    <div className={"popMain " + (props.popup ? "on" : "")}>
      <div className="popupIn">
        <ul className="at_slide">
          <Slider {...options} className="at_slide">
            {props.popupContentList.map((item, idx) => (
              <li key="idx">
                {item.url ? (
                  <a href={item.url} target="_blank" rel="noopener">
                    <img
                      src={item.webImgFilePath}
                      alt={item.popupTitle}
                      className="pc_tb"
                    />
                    <img
                      src={item.mobileImgFilePath}
                      alt={item.popupTitle + "모바일"}
                      className="mb"
                    />
                  </a>
                ) : (
                  <a>
                    <img
                      src={item.webImgFilePath}
                      alt={item.popupTitle}
                      className="pc_tb"
                    />
                    <img
                      src={item.mobileImgFilePath}
                      alt={item.popupTitle + "모바일"}
                      className="mb"
                    />
                  </a>
                )}
              </li>
            ))}
          </Slider>
        </ul>
        <div className="popupClose">
          <p onClick={(evt) => props.closePopup(evt)} className="close">
            닫기
          </p>

          <p onClick={(evt) => props.closePopup(evt)} className="one_day_close">
            <input type="checkbox" className="one_day_close"></input>
            오늘 하루 보지않기
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default LayerPopupComponent;
