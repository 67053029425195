import React, {Component} from 'react';
import cookie from 'react-cookies';
import {withUserAgent} from 'react-useragent'
import {BrowserRouter as Router, Route} from 'react-router-dom';

import ErrorBoundary from './scenes/common/ErrorBoundary.container';

import HeaderContainer from './scenes/layout/header/Header.container';
import FooterComponent from './scenes/layout/Footer.component';

import LoginContainer from './scenes/login/Login.container';
import SignupContainer from './scenes/signup/Signup.container';
import MypageContainer from './scenes/mypage/Mypage.container';

import HomeContainer from './scenes/home/Home.container';
import ContentsContainer from './scenes/contents/Contents.container';
import GameContainer from './scenes/game/Game.container';
import PlayersContainer from './scenes/players/Players.container';
import TicketContainer from './scenes/ticket/Ticket.container';
import TigerMembershipContainer from './scenes/tiger-membership/TigerMembership.container';
import TigersContainer from './scenes/tigers/Tigers.container';
import TermsContainer from './scenes/terms/Terms.container';
import TigerPointContainer from './scenes/point/TigerPoint.container';

import AnalyticsContext from "./context/AnalyticsContext";

import * as AuthHelper from './helpers/Auth.helper';
import * as CommonHelper from './helpers/Common.helper'
import Switch from "react-router-dom/es/Switch";
import ScorePoints from "./scenes/score-points/ScorePoints";
import GoodsAuction from './scenes/auction/GoodsAuction';
import {NaverLive} from "./scenes/live/NaverLive";
import SmartTicketContainer from './scenes/smartTicket/smartTicket.container'
import EventContainer from './scenes/event/event.container'

const AxiosInterceptor = require('./interceptor/Axios.interceptor');

class SceneContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    this.state = {
      isInit: false,
      isMobileDevice: false,
      loginInfo: null,
      redirect: null,
    };

    // console.log(this.props.match)
  }

  setLoginInfo = (loginInfo) => {
    // console.log('setLoginInfo');
    if (loginInfo) {
      // console.log('loginInfo', loginInfo);
      AuthHelper.setLoginInfo(loginInfo);
      this.setState({loginInfo: loginInfo});
    } else {
      // console.log("logout");
      cookie.remove('loginInfo');
      this.setState({
        loginInfo: null,
        redirect: "/"
      });
    }

    // console.log(this.props);
  }

  componentWillMount = () => {
    this.context.state.isMobile = CommonHelper.checkIsOnWebview();
  }

  componentDidMount = () => {
    // console.log('sceneContainer');

    // this.history = useHistory();

    AxiosInterceptor.init();

    const loginInfo = AuthHelper.getLoginInfo();
    // console.log('loginInfo', loginInfo);
    // axios.defaults.baseURL = "https://cms.kiatigers.info/api/v1";
    // axios.defaults.headers.common['AKey'] = 'f68cNbKYSKJYan41zIcjOmbRUxQ=';
    // axios.defaults.headers.common['App-Agent'] = 'platformCode=70;platformVer=1.0.0;deviceId=WEB;appName=tigersWeb;appVer=1.0.0;deviceModel=Browser;';


    this.setState({isInit: true, loginInfo});
  }

  render = () => (
    this.state.isInit
      ?
      <Router>
        <Switch>
        <Route path="/naver-live" component={NaverLive} />
        <Route>
          <ErrorBoundary>
            <HeaderContainer
              loginInfo={this.state.loginInfo}
              setLoginInfo={this.setLoginInfo}/>

            <Route exact path="/" component={HomeContainer}/>

            <Route path="/login"
                   render={(props) => <LoginContainer {...props} setLoginInfo={this.setLoginInfo}/>}/>
            <Route exact path="/signup" component={SignupContainer}/>
            <Route exact path="/mypage"
                   render={(props) => <MypageContainer
                     {...props}
                     loginInfo={this.state.loginInfo}
                     setLoginInfo={this.setLoginInfo}/>}/>

            <Route path="/tigers" component={TigersContainer}/>
            <Route path="/players" component={PlayersContainer}/>
            <Route path="/contents" component={ContentsContainer}/>
            <Route path="/game" component={GameContainer}/>
            <Route path="/ticket" component={TicketContainer}/>
            <Route path="/membership" component={TigerMembershipContainer}/>
            <Route path="/point" component={TigerPointContainer}/>
            <Route path="/smartTicket" component={SmartTicketContainer}/>
            <Route path="/event" component={EventContainer}/>

            {/* {"DeviceDetect.getUA" + DeviceDetect.getUA}
                    {"DeviceDetect : " + DeviceDetect.getUA.includes('tigersApp')} */}
            <Route path="/terms" component={TermsContainer}/>
            {/* 상품교환 페이지 */}
            <Route path="/score-points" component={ScorePoints}/>
            {/* 굿즈경매 페이지 */}
            <Route path="/auction" component={GoodsAuction}/>
            <FooterComponent/>
          </ErrorBoundary>
        </Route>
        </Switch>
      </Router>
      :
      null
  )
}

export default withUserAgent(SceneContainer);
