import React from 'react';
import { Link } from 'react-router-dom';

const SubMenuComponent = (props) => (
    <div className="tabWrap">
        <ul className="tab01 clear">
            <li className={props.thirdMenu === 'team-guide' ? "on" : ""}>
                <Link to="/tigers/champions-field/team-guide">구장안내</Link>
            </li>
            <li className={props.thirdMenu === 'facility-guide' ? "on" : ""}>
                <Link to="/tigers/champions-field/facility-guide">시설안내</Link>
            </li>
            <li className={props.thirdMenu === 'stadium-guide' ? "on" : ""}>
                <Link to="/tigers/champions-field/stadium-guide">구장안내도</Link>
            </li>
            {/* <li className={props.thirdMenu === 'seat-guide' ? "on" : ""}>
                <Link to="/tigers/champions-field/seat-guide">좌석안내도</Link>
            </li> */}
        </ul>
    </div>
)

export default SubMenuComponent;
