import React from "react";
import { Link } from "react-router-dom";

const SubMenuComponent = props => (
  <div className="tabWrap">
    <ul className="tab01 clear">
      <li className={props.subMenu === "coaching-staff" ? "on" : ""}>
        <Link to="/players/coaching-staff">코칭스태프</Link>
      </li>
      <li className={props.subMenu === "pitcher" ? "on" : ""}>
        <Link to="/players/pitcher">투수</Link>
      </li>
      <li className={props.subMenu === "catcher" ? "on" : ""}>
        <Link to="/players/catcher">포수</Link>
      </li>
      <li className={props.subMenu === "infielder" ? "on" : ""}>
        <Link to="/players/infielder">내야수</Link>
      </li>
      <li className={props.subMenu === "outfielder" ? "on" : ""}>
        <Link to="/players/outfielder">외야수</Link>
      </li>
      <li className={props.subMenu === "military-enlisted" ? "on" : ""}>
        <Link to="/players/military-enlisted">군입대선수</Link>
      </li>
      <li className={props.subMenu === "entry-status" ? "on" : ""}>
        <Link to="/players/entry-status">엔트리 현황</Link>
      </li>
    </ul>
  </div>
);

export default SubMenuComponent;
