import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import GreetingContainer from './greeting/Greeting.container';
import IntroContainer from './intro/Intro.container';
import CheeringPartyContainer from './cheering-party/CheeringParty.container';
import ChampionsFieldContainer from './champions-field/ChampionsField.container';
import ChallengersFieldContainer from './challengers-field/ChallengersField.container';
import BiContainer from './bi/Bi.container';
import OrganizationContainer from './organization/Organization.container';
import SponsorContainer from './sponsor/Sponsor.container';

class TigersContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/greeting`} component={GreetingContainer} />
            <Route path={`${this.props.match.path}/intro`} component={IntroContainer} />
            <Route path={`${this.props.match.path}/cheering-party`} component={CheeringPartyContainer} />
            <Route path={`${this.props.match.path}/champions-field`} component={ChampionsFieldContainer} />
            <Route path={`${this.props.match.path}/challengers-field`} component={ChallengersFieldContainer} />
            <Route path={`${this.props.match.path}/bi`} component={BiContainer} />
            <Route path={`${this.props.match.path}/history`} component={GreetingContainer} />
            <Route path={`${this.props.match.path}/organization`} component={OrganizationContainer} />
            <Route path={`${this.props.match.path}/sponsor`} component={SponsorContainer} />
        </Switch>
    )
}

export default TigersContainer;