export const makeOffset = (page, articlePerPage = 10) => {
    return (page - 1) * articlePerPage;
}
export const makePaginationObj = (totalPage, page, articlePerPage, listPerPage = 5) => {
    console.log('makePaginationObj', totalPage, page, articlePerPage, listPerPage);
    const mod = page % listPerPage;
    console.log('mod', mod);

    // idx of in list
    const idxInList = mod > 0 ? mod - 1 : listPerPage - 1;
    const beginPageNum = (Math.ceil(page / listPerPage) - 1) * listPerPage + 1;

    const lastPage = (beginPageNum - 1 + listPerPage);
    const endPageNum = lastPage <= totalPage ? lastPage : totalPage;

    let pageList = [];
    for (let i = beginPageNum; i <= endPageNum; i++) {
        pageList.push(i);
    }

    const prevPageNum = (page > 1 ? page - 1 : 1);
    const nextPageNum = (page < totalPage ? page + 1 : totalPage);

    return {
        firstPageNum: 1,
        prevPageNum,
        idxInList,
        pageList,
        nextPageNum,
        lastPageNum: totalPage
    }
}
export const getSearchFilterSelectList = _ => [
    { value: 100, text: "제목" },
    { value: 200, text: "내용" },
    // {value:300, text:"이름"},
    // {value:400, text:"작성자"}
]
export const getSortSelectList = _ => [
    { value: '', text: "최근순" },
    { value: 'old', text: "오래된순" },
    { value: 'viewCnt', text: "조회수" },
    { value: 'likeCnt', text: "좋아요" }]