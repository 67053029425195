import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import ScheduleContainer from './schedule/Schedule.container';
import BaseballContainer from './baseball/Baseball.container';
import PicturesContainer from './pictures/Pictures.container';
import StatsContainer from './stats/Stats.container';

class GameContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/schedule`} component={ScheduleContainer} />
            <Route path={`${this.props.match.path}/baseball`} component={BaseballContainer} />
            <Route path={`${this.props.match.path}/pictures`} component={PicturesContainer} />
            <Route path={`${this.props.match.path}/stats`} component={StatsContainer} />
        </Switch>
    )
}

export default GameContainer;