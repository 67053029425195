import React from "react";

export const RoutoMapComponent = () => {
  React.useEffect(() => {

    const script = document.createElement("script");
    script.src = "https://api.routo.com/v1/maps/map?key=5daeb007-043c-4150-8a26-ef346a6b4040";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line no-undef
      const map = new routo.maps.Map("map", {
        center: { lat: 35.168362, lng: 126.888968 },
        zoom: 15,
      });
      // eslint-disable-next-line no-undef
      new routo.maps.Marker({
        position: { lat: 35.168362, lng: 126.888968 },
        map: map,
      });

      // eslint-disable-next-line no-undef
      const zoomControl = new routo.maps.ZoomControl();

      map.addControl(zoomControl);
    }

    return () => {
      document.body.removeChild(script);
    };


  }, []);

  return <div id="map" className="map"></div>;
};
