import React from "react"
import {useHistory, useParams} from "react-router-dom"
import {useMutation, useQuery} from "react-query"
import {getProductDetail, orderProduct} from "../../../services/ScorePoint.service"
import {order} from "./ScorePointProducts"
import ProductImageSlider from "./ProductImageSlider"
import {useAlert} from "../../../context/PopupContext"
import * as AuthHelper from "../../../helpers/Auth.helper"
import * as UserService from "../../../services/User.service"
import { decodeAES256 } from "../../../helpers/Crypto.helper"

function ScorePointOrderProduct() {
  const history = useHistory()
  const {id} = useParams()
  const [agreed, setAgreed] = React.useState({
    confirm: false
  })
  const {openAlert} = useAlert()

  useQuery("MyInfo", () => UserService.getMyInfo(), {
    onSuccess: (data) => {
      setPhoneNumber(data.user.mobpNo)
    },
    staleTime: 1000,
    refetchOnWindowFocus: false
  })

  const loginInfo = AuthHelper.getLoginInfo()
  const [phoneNumber, setPhoneNumber] = React.useState(loginInfo ? decodeAES256(loginInfo.user.mobpNo) : "")

  const allAgreed = agreed.confirm

  const {isLoading, data} = useQuery(["scorePointProduct", id], () => getProductDetail(id))
  const orderPointProduct = useMutation((newOrder) => orderProduct(newOrder), {
    onSuccess: () => {
      openAlert(
        `교환 신청이 완료되었습니다.\n\n상품 준비가 완료되면 문자메시지로\n\n교환권을 보내드립니다.\n\n교환 신청 현황은\n\n**기아타이거즈앱 > 득점 포인트 > 신청 현황**\n\n에서 확인 가능합니다.`
      )
      history.push("/score-points/products")
    },
    onError: (error) => {
      openAlert(error.message)
    }
  })

  const onClickExchange = React.useCallback(() => {
    if (allAgreed) {
      console.log("Request Order")
      orderPointProduct.mutate(order)
    } else {
      openAlert("구매 유의사항에 동의하셔야 교환 가능합니다.")
    }
  }, [agreed])

  const onCheckAgree = React.useCallback(
    (e) => {
      setAgreed({
        ...agreed,
        [e.target.name]: e.target.checked
      })
    },
    [agreed]
  )

  const toggleAllAgreed = React.useCallback(() => {
    setAgreed({
      confirm: !allAgreed
    })
  }, [agreed])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="product-detail">
      <header>
        <ProductImageSlider images={data.imgInfoList} />
        <div className="title">
          <h5>{data.productName}</h5>
          <p>{data.price}p</p>
        </div>
      </header>
      <main>
        <div className="summary">
          <dl>
            <div className="lined">
              <dt>개수</dt>
              <dd>{order.ordCnt}개</dd>
            </div>
            <div className="lined">
              <dt>모바일교환권 금액</dt>
              <dd>{order.point}</dd>
            </div>
            <div className="lined">
              <dt>모바일교환권 수신번호</dt>
              <dd>{phoneNumber}</dd>
            </div>
          </dl>
          <div className="line" />
          <p>모바일교환권은 본인에게만 발송 가능합니다. 수신번호를 변경하시려면 회원정보의 휴대폰번호를 변경한 후 다시 시도해 주세요.</p>
        </div>
        <div className="gap" />
        <form className="agreement">
          {/*<h6>약관동의</h6>*/}
          {/*<div className="lined">*/}
          {/*  <div className="item">*/}
          {/*    <input type="checkbox"*/}
          {/*           name="privacy"*/}
          {/*           checked={agreed.privacy}*/}
          {/*           onChange={onCheckAgree}/>*/}
          {/*    <label>개인정보 수집/이용 동의<span>[필수]</span></label>*/}
          {/*  </div>*/}
          {/*  <div>{">"}</div>*/}
          {/*</div>*/}
          {/*<div className="lined">*/}
          {/*  <div className="item">*/}
          {/*    <input type="checkbox"*/}
          {/*           name="delegate"*/}
          {/*           checked={agreed.delegate}*/}
          {/*           onChange={onCheckAgree}/>*/}
          {/*    <label>개인정보 위탁처리 동의<span>[필수]</span></label>*/}
          {/*  </div>*/}
          {/*  <div>{">"}</div>*/}
          {/*</div>*/}
          {/*<div className="line" />*/}
          <div className="lined">
            <div className="item">
              <input type="checkbox" name="all" checked={allAgreed} onChange={toggleAllAgreed} />
              <label>위 구매조건을 모두 확인하고 결제를 진행합니다.</label>
            </div>
          </div>
          <p className="warning">접수 이후 단계에서는 취소가 불가하며, 사용하신 득점 포인트도 반환되지 않는 것을 동의합니다.</p>
        </form>
        <div className="spacing-box">{/* 교환신청 버튼 위치 조정 dummy box */}</div>
      </main>
      <div className="bottom">
        <div className="button-wrapper">
          <button onClick={onClickExchange}>교환 신청하기</button>
        </div>
      </div>
    </div>
  )
}

export default ScorePointOrderProduct
