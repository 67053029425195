import React from "react";

const imageStyle = {
  width: "100%"
}
const PrepareComponent = () => {
  return (
    <div className="box1">
      <img src="/img/KIA_service_stop.png" alt="서비스중단" style={imageStyle} />
    </div>
  );
};

export default PrepareComponent;
