import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";
import queryString from "query-string";

import * as AuthHelper from "../../../../helpers/Auth.helper";
import * as QnaService from "../../../../services/Qna.service";
import * as DateHelper from "../../../../helpers/Date.helper";
import * as CommonHelper from "../../../../helpers/Common.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class InfoContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    const articleId = this.props.match.params.articleId;

    this.state = {
      subMenu: "inquiry",
      subMenuName: "1:1문의",
      articleId,
      info: null,
      mediaInfo: null,
      replyList: [],
    };
  }

  componentDidMount = async (_) => {
    console.log("info.container console");
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "04";
      currentAnalyticsVal.deps4 = "01";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const result = await QnaService.getInfo(this.state.articleId);

      let info = result.detail;
      info["regDate"] = DateHelper.convertTimestampToDate(info["regDttm"], ".");
      info["regTime"] = DateHelper.convertTimestampToTime(info["regDttm"]);
      info["isMine"] = info.memberId === AuthHelper.getMemberId();

      let replyList = result.reply;
      for (let i = 0; i < replyList.length; i++) {
        let replyInfo = replyList[i];
        replyInfo["regDate"] = DateHelper.convertTimestampToDate(
          info["regDttm"],
          "."
        );
        replyInfo["regTime"] = DateHelper.convertTimestampToTime(
          info["regDttm"]
        );
        replyList[i] = replyInfo;
      }

      let mediaInfo = null;
      if (result.media) {
        mediaInfo = result.media;
      }

      this.setState({ info, mediaInfo, replyList });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  onClickBtnList = (_) => {
    const queryStringParams = queryString.parse(this.props.location.search);
    const isFromHome =
      (queryStringParams["fromHome"]
        ? queryStringParams["fromHome"]
        : "n"
      ).toLowerCase() === "y";
    if (isFromHome) {
      this.props.history.push("/membership/inquiry");
    } else {
      this.props.history.goBack();
    }
  };

  onClickBtnRemove = async (_) => {
    try {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        const result = await QnaService.deleteInfo(this.state.articleId);
        alert("삭제되었습니다.");
        this.props.history.goBack();
      }
    } catch (err) {
      alert(err.message);
      if(err.message === "1:1 문의하기는 시스템 점검, 개선으로 인하여 대표메일(kiatigers@kiatigers.co.kr)로 받고 있습니다. 양해 부탁드립니다.") {
        document.location.href = "/";
      }
    }
  };

  onClickBtnModify = (_) => {
    this.props.history.push(
      `/membership/inquiry/${this.state.articleId}/modify`
    );
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <ContComponent
        info={this.state.info}
        mediaInfo={this.state.mediaInfo}
        replyList={this.state.replyList}
        onClickBtnList={this.onClickBtnList}
        onClickBtnRemove={this.onClickBtnRemove}
        oClickBtnModify={this.oClickBtnModify}
      />
    </div>
  );
}

export default InfoContainer;
