import React from "react";
import * as CommonHelper from "../../../../helpers/Common.helper";

const SubHeadComponent = props =>
  !CommonHelper.checkIsOnWebview() ? (
    <div className="sub_head">
      <div className="sub_HeadIn1 tigers_head_l">
        {props.thirdMenuName ? <h2>{props.thirdMenuName}</h2> : null}
        <ul>
          <li>
            <img src="/img/sub/ico_home.png" alt="홈 바로가기" />
          </li>
          <li>TIGERS</li>
          {props.subMenuName ? <li>{props.subMenuName}</li> : null}
          {props.thirdMenuName ? <li>{props.thirdMenuName}</li> : null}
        </ul>
      </div>
    </div>
  ) : null;

export default SubHeadComponent;
