import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as DateHelper from "../../../../helpers/Date.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class CalculationContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "기록 계산법",

      winningRateObj: {
        winCnt: "",
        lostCnt: "",
        result: "",
      },
      subWinningObj: {
        winCnt1: "",
        winCnt2: "",
        lostCnt1: "",
        lostCnt2: "",
        result: "",
      },
      hitRateObj: {
        hitCnt: "",
        swingCnt: "",
        result: "",
      },
      avgFailedScoreObj: {
        failedScore: "",
        pitchCnt: "",
        result: "",
      },
      onBaseAvgObj: {
        hitCnt: "",
        deadBallCnt: "",
        swingCnt: "",
        sacrificeFlyCnt: "",
        result: "",
      },
      sluggingRateObj: {
        hitCnt: "",
        swingCnt: "",
        result: "",
      },
      defenceRateObj: {
        putOut: "",
        assist: "",
        failedCnt: "",
        result: "",
      },
      plateObj: {
        gameCnt: "",
        result: "",
      },
      pitchObj: {
        gameCnt: "",
        result: "",
      },
      opsObj: {
        runRate: "",
        longHitRate: "",
        result: "",
      },
      obaObj: {
        hitCnt: "",
        swingCnt: "",
        result: "",
      },
      whipObj: {
        hitCnt: "",
        deadBallCnt: "",
        inning: "",
        result: "",
      },
    };
  }

  componentDidMount = async () => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "04";
    currentAnalyticsVal.deps3 = "03"; //
    currentAnalyticsVal.deps4 = "07";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);
  };

  onChangeInput = (evt, objName, key) => {
    let obj = this.state[objName];
    obj[key] = evt.target.value;
    this.setState({ [objName]: obj });
  };
  onBlurInput = (evt, objName, key) => {
    let obj = this.state[objName];
    if (isNaN(evt.target.value) || evt.target.value.trim() === "") {
      console.log("not a number");
      obj[key] = "";
      this.setState({ [objName]: obj });
    } else {
      if (objName === "opsObj") {
        obj[key] = parseFloat(evt.target.value);
      } else {
        obj[key] = parseInt(evt.target.value, 10);
      }
      this.setState({ [objName]: obj });
    }
  };
  onClickBtn = (evt, objName) => {
    let obj = this.state[objName];
    let isPercentage = false;
    if (objName === "winningRateObj") {
      obj["result"] = obj["winCnt"] / (obj["winCnt"] + obj["lostCnt"]);
      isPercentage = true;
    } else if (objName === "subWinningObj") {
      obj["result"] =
        (obj["winCnt1"] -
          obj["winCnt2"] -
          (obj["lostCnt1"] - obj["lostCnt2"])) /
        2;
    } else if (objName === "hitRateObj") {
      obj["result"] = obj["hitCnt"] / obj["swingCnt"];
      isPercentage = true;
    } else if (objName === "avgFailedScoreObj") {
      obj["result"] = (obj["failedScore"] * 9) / obj["pitchCnt"];
    } else if (objName === "onBaseAvgObj") {
      obj["result"] =
        (obj["hitCnt"] + obj["deadBallCnt"]) /
        (obj["swingCnt"] + obj["deadBallCnt"] + obj["sacrificeFlyCnt"]);
      isPercentage = true;
    } else if (objName === "sluggingRateObj") {
      obj["result"] = obj["hitCnt"] / obj["swingCnt"];
      isPercentage = true;
    } else if (objName === "defenceRateObj") {
      obj["result"] =
        (obj["putOut"] + obj["assist"]) /
        (obj["putOut"] + obj["assist"] + obj["failedCnt"]);
      isPercentage = true;
    } else if (objName === "plateObj") {
      obj["result"] = obj["gameCnt"] * 3.1;
    } else if (objName === "pitchObj") {
      obj["result"] = obj["gameCnt"];
    } else if (objName === "opsObj") {
      obj["result"] = obj["runRate"] + obj["longHitRate"];
      isPercentage = true;
    } else if (objName === "obaObj") {
      obj["result"] = obj["hitCnt"] / obj["swingCnt"];
      isPercentage = true;
    } else if (objName === "whipObj") {
      obj["result"] = (obj["hitCnt"] + obj["deadBallCnt"]) / obj["inning"];
      isPercentage = true;
    }

    if (isNaN(obj["result"])) {
      obj["result"] = "";
    } else {
      obj["result"] = obj["result"].toFixed(2); //+ (isPercentage ? "%" : "");
    }

    this.setState({ [objName]: obj });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        onChangeInput={this.onChangeInput}
        onBlurInput={this.onBlurInput}
        onClickBtn={this.onClickBtn}
        winningRateObj={this.state.winningRateObj}
        subWinningObj={this.state.subWinningObj}
        hitRateObj={this.state.hitRateObj}
        avgFailedScoreObj={this.state.avgFailedScoreObj}
        onBaseAvgObj={this.state.onBaseAvgObj}
        sluggingRateObj={this.state.sluggingRateObj}
        defenceRateObj={this.state.defenceRateObj}
        plateObj={this.state.plateObj}
        pitchObj={this.state.pitchObj}
        opsObj={this.state.opsObj}
        obaObj={this.state.obaObj}
        whipObj={this.state.whipObj}
      />
    </div>
  );
}

export default CalculationContainer;
