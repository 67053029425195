import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonHelper from "../../../../helpers/Common.helper";
import * as GameService from "../../../../services/Game.service";
import * as ImageHelper from "../../../../helpers/Image.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

const pitcherCompareObj = {
  era: { name: "평균 자책점", max: 10, type: "lessThan" },
  w: { name: "승리", max: 150, type: "greaterThan" },
  l: { name: "패배", max: 150, type: "lessThan" },
  hold: { name: "홀드", max: 200, type: "greaterThan" },
  sv: { name: "세이브", max: 300, type: "greaterThan" },
  kk: { name: "삼진", max: 2000, type: "greaterThan" },
  innDisplay: { name: "이닝", max: 3000, type: "greaterThan" },
  hit: { name: "피안타", max: 3000, type: "lessThan" },
  hr: { name: "피홈런", max: 300, type: "lessThan" },
  bb: { name: "볼넷", max: 1000, type: "lessThan" },
  whip: { name: "WHIP", max: 5, type: "lessThan" },
  qs: { name: "QS", max: 200, type: "greaterThan" },
  qsPlus: { name: "QS+", max: 200, type: "greaterThan" },
  sho: { name: "완봉", max: 30, type: "greaterThan" },
  wCg: { name: "완투", max: 100, type: "greaterThan" },
};
const pitcherCheckboxObj = {
  era: true,
  w: true,
  l: true,
  hold: true,
  sv: true,
  kk: true,
  innDisplay: true,
  hit: true,
  hr: true,
  bb: true,
  whip: true,
  qs: true,
  qsPlus: true,
  sho: true,
  wCg: true,
};

const hitterCompareObj = {
  hra: { name: "타율", max: 0.5, type: "greaterThan" },
  hit: { name: "안타", max: 3000, type: "greaterThan" },
  hr: { name: "홈런", max: 500, type: "greaterThan" },
  rbi: { name: "타점", max: 1500, type: "greaterThan" },
  sb: { name: "도루", max: 500, type: "greaterThan" },
  kk: { name: "삼진", max: 1500, type: "lessThan" },
  bb: { name: "볼넷", max: 1500, type: "greaterThan" },
  pa: { name: "타석", max: 5000, type: "greaterThan" },
  ab: { name: "타수", max: 5000, type: "greaterThan" },
  run: { name: "득점", max: 1500, type: "greaterThan" },
  h2: { name: "2루타", max: 500, type: "greaterThan" },
  h3: { name: "3루타", max: 120, type: "greaterThan" },
  slg: { name: "장타율", max: 0.7, type: "greaterThan" },
  bra: { name: "출루율", max: 0.5, type: "greaterThan" },
  ops: { name: "OPS", max: 1.0, type: "greaterThan" },
};
const hitterCheckboxObj = {
  hra: true,
  hit: true,
  hr: true,
  rbi: true,
  sb: true,
  kk: true,
  bb: true,
  pa: true,
  ab: true,
  run: true,
  h2: true,
  h3: true,
  slg: true,
  bra: true,
  ops: true,
};

class CompareContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "선수간 기록 비교",

      leftTeamSelectList: [],
      rightTeamSelectList: [],
      selectedLeftTeamCode: "",
      selectedRightTeamCode: "",
      // selectedLeftTeamCode: 'HH',
      // selectedRightTeamCode: 'KT',

      leftPlayerSelectList: [],
      rightPlayerSelectList: [],
      selectedLeftPlayerCode: "",
      selectedRightPlayerCode: "",
      // selectedLeftPlayerCode: '62864',
      // selectedRightPlayerCode: '61700',

      selectedSeason: process.env.REACT_APP_SEASON_YEAR,
      selectedPositionCode: "P",

      checkboxObj: CommonHelper.cloneObj(pitcherCheckboxObj),
      compareObj: CommonHelper.cloneObj(pitcherCompareObj),
      // checkboxObj: CommonHelper.cloneObj(hitterCheckboxObj),
      // compareObj: CommonHelper.cloneObj(hitterCompareObj),

      compareResultObj: null,
      leftPlayerObj: null,
      rightPlayerObj: null,
    };
  }

  componentDidMount = async (_) => {
    // this.onClickBtnCompare(null);

    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "04";
      currentAnalyticsVal.deps3 = "03"; //
      currentAnalyticsVal.deps4 = "06";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const teamList = await GameService.getTeamListForCompareRecord(
        this.state.selectedSeason
      );
      this.setState({
        leftTeamSelectList: teamList["leftTeamList"],
        rightTeamSelectList: teamList["rightTeamList"],
      });
      console.log(teamList);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnSeason = async (evt, season) => {
    try {
      const teamList = await GameService.getTeamListForCompareRecord(
        this.state.selectedSeason
      );
      this.setState({
        leftTeamSelectList: teamList["leftTeamList"],
        rightTeamSelectList: teamList["rightTeamList"],
        leftPlayerSelectList: [],
        rightPlayerSelectList: [],
        selectedSeason: season,
        selectedLeftTeamCode: "",
        selectedRightTeamCode: "",
        selectedLeftPlayerCode: "",
        selectedRightPlayerCode: "",
        playerCompareObj: null,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnPositionCode = (evt, position) => {
    console.log("onClickBtnPositionCode", position);
    const checkboxObj = CommonHelper.cloneObj(
      position === "P" ? pitcherCheckboxObj : hitterCheckboxObj
    );
    const compareObj = CommonHelper.cloneObj(
      position === "P" ? pitcherCompareObj : hitterCompareObj
    );

    this.setState({
      selectedPositionCode: position,
      leftPlayerSelectList: [],
      rightPlayerSelectList: [],
      selectedLeftTeamCode: "",
      selectedRightTeamCode: "",
      selectedLeftPlayerCode: "",
      selectedRightPlayerCode: "",
      checkboxObj,
      compareObj,
      playerCompareObj: null,
    });
  };

  onChangeTeamSelectList = async (evt, pos, key) => {
    console.log("evt.target.value", evt.target.value);
    const code = evt.target.value;
    if (code === "") {
      if (pos === "left") {
        this.setState({
          leftPlayerSelectList: [],
          selectedLeftTeamCode: "",
          playerCompareObj: null,
        });
      } else if (pos === "right") {
        this.setState({
          rightPlayerSelectList: [],
          selectedRightTeamCode: "",
          playerCompareObj: null,
        });
      }
    } else {
      let result = await GameService.getPlayerListForCompareRecord(
        this.state.selectedSeason,
        code,
        this.state.selectedPositionCode
      );
      console.log(result.playerList);
      console.log("code", code);
      if (pos === "left") {
        this.setState({
          leftPlayerSelectList: result.playerList,
          selectedLeftTeamCode: code,
        });
      } else if (pos === "right") {
        this.setState({
          rightPlayerSelectList: result.playerList,
          selectedRightTeamCode: code,
        });
      }
    }
  };
  onChangePlayerSelectList = (evt, pos, key) => {
    this.setState({
      [key]: evt.target.value,
      playerCompareObj: null,
    });
    console.log(this.state);
  };

  onClickCheckbox = (evt, key) => {
    let checkboxObj = this.state["checkboxObj"];
    checkboxObj[key] = !checkboxObj[key];
    this.setState({
      checkboxObj,
      playerCompareObj: null,
    });
  };

  onClickBtnCompare = async (evt) => {
    console.log("onClickBtnCompare");
    try {
      if (
        this.state.selectedLeftTeamCode === "" ||
        this.state.selectedRightTeamCode === ""
      ) {
        throw new Error("팀을 선택해주세요.");
      } else if (
        this.state.selectedLeftPlayerCode === "" ||
        this.state.selectedRightPlayerCode === ""
      ) {
        throw new Error("선수를 선택해주세요.");
      } else if (
        this.state.selectedLeftPlayerCode === this.state.selectedRightPlayerCode
      ) {
        throw new Error("서로 다른 선수를 선택해주세요.");
      }

      let compareResultObj = await GameService.getInfoForCompareRecord(
        this.state.selectedLeftPlayerCode,
        this.state.selectedRightPlayerCode,
        this.state.selectedSeason
      );
      if (!compareResultObj.leftPlayer) {
        throw new Error("왼쪽 선수의 데이터가 없습니다.");
      } else if (!compareResultObj.rightPlayer) {
        throw new Error("우측 선수의 데이터가 없습니다.");
      }

      let hasCheckedCompareItem = false;
      for (const key in this.state.checkboxObj) {
        if (this.state.checkboxObj[key]) {
          hasCheckedCompareItem = true;
          break;
        }
      }
      if (!hasCheckedCompareItem) {
        throw new Error("기록 비교항목을 하나 이상 선택해주세요.");
      }

      const playerCompareObj = this.makeCompareInfo(
        this.state.checkboxObj,
        this.state.compareObj,
        compareResultObj,
        this.state.selectedPositionCode
      );
      // console.log(compareResultObj);
      this.setState({ compareResultObj, playerCompareObj });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  makeCompareInfo = (checkboxObj, compareObj, resultObj, positionCode) => {
    let playerCompareObj = {};

    let leftPlayerObj = CommonHelper.cloneObj(resultObj.leftPlayer);
    let rightPlayerObj = CommonHelper.cloneObj(resultObj.rightPlayer);

    Object.keys(compareObj).map((key, idx) => {
      if (checkboxObj[key]) {
        let leftObj = {};
        let rightObj = {};

        const leftValue = parseFloat(leftPlayerObj[key]);
        const rightValue = parseFloat(rightPlayerObj[key]);
        if (compareObj[key]["type"] === "lessThan") {
          if (leftValue < rightValue) {
            leftObj["hasWin"] = true;
            rightObj["hasWin"] = false;
          } else if (leftValue > rightValue) {
            leftObj["hasWin"] = false;
            rightObj["hasWin"] = true;
          } else {
            leftObj["hasWin"] = false;
            rightObj["hasWin"] = false;
          }
        } else {
          if (leftValue > rightValue) {
            leftObj["hasWin"] = true;
            rightObj["hasWin"] = false;
          } else if (leftValue < rightValue) {
            leftObj["hasWin"] = false;
            rightObj["hasWin"] = true;
          } else {
            leftObj["hasWin"] = false;
            rightObj["hasWin"] = false;
          }
        }
        leftObj["rate"] = ((leftValue / compareObj[key]["max"]) * 100).toFixed(
          2
        );
        rightObj["rate"] = (
          (rightValue / compareObj[key]["max"]) *
          100
        ).toFixed(2);

        console.log(key, leftValue, compareObj[key]["max"], leftObj["rate"]);

        leftObj["value"] = leftValue;
        rightObj["value"] = rightValue;

        playerCompareObj[key] = {};
        playerCompareObj[key]["name"] = compareObj[key]["name"];
        playerCompareObj[key]["left"] = leftObj;
        playerCompareObj[key]["right"] = rightObj;
      }
    });
    // leftPlayerObj['name'] = compareObj['name'];
    // rightPlayerObj['name'] = compareObj['name'];

    // playerCompareObj['left'] = leftPlayerObj;
    // playerCompareObj['right'] = rightPlayerObj;

    playerCompareObj["positionCode"] = positionCode;
    playerCompareObj["left"] = leftPlayerObj;
    playerCompareObj["left"][
      "emblemImgUrl"
      ] = ImageHelper.getEmblemImgByTeamCode(this.state.selectedLeftTeamCode);
    playerCompareObj["right"] = rightPlayerObj;
    playerCompareObj["right"][
      "emblemImgUrl"
      ] = ImageHelper.getEmblemImgByTeamCode(this.state.selectedRightTeamCode);

    console.log("playerCompareObj", playerCompareObj);

    return playerCompareObj;
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        onClickBtnSeason={this.onClickBtnSeason}
        onClickBtnPositionCode={this.onClickBtnPositionCode}
        onChangeTeamSelectList={this.onChangeTeamSelectList}
        onChangePlayerSelectList={this.onChangePlayerSelectList}
        selectedSeason={this.state.selectedSeason}
        selectedPositionCode={this.state.selectedPositionCode}
        leftTeamSelectList={this.state.leftTeamSelectList}
        rightTeamSelectList={this.state.rightTeamSelectList}
        selectedLeftTeamCode={this.state.selectedLeftTeamCode}
        selectedRightTeamCode={this.state.selectedRightTeamCode}
        leftPlayerSelectList={this.state.leftPlayerSelectList}
        rightPlayerSelectList={this.state.rightPlayerSelectList}
        selectedLeftPlayerCode={this.state.selectedLeftPlayerCode}
        selectedRightPlayerCode={this.state.selectedRightPlayerCode}
        checkboxObj={this.state.checkboxObj}
        onClickCheckbox={this.onClickCheckbox}
        compareResultObj={this.state.compareResultObj}
        playerCompareObj={this.state.playerCompareObj}
        onClickBtnCompare={this.onClickBtnCompare}
      />
    </div>
  );
}

export default CompareContainer;
