import React from 'react';

const HomeAwayRecordComponent = props => (
    props.info.position2 === '투' ?
        <HomeAwayRecordComponentForPitcher {...props} />
        :
        <HomeAwayRecordComponentForHitter {...props} />
)

const HomeAwayRecordComponentForHitter = props => (
    <table>
        <caption>홈/원정별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">홈/원정</th>
                <th scope="col">타석</th>
                <th scope="col">타수</th>
                <th scope="col">득점</th>
                <th scope="col">안타</th>
                <th scope="col">2타</th>
                <th scope="col">3타</th>
                <th scope="col">홈런</th>
                <th scope="col">타점</th>
                <th scope="col">희타</th>
                <th scope="col">희비</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">병살</th>
                <th scope="col">타율</th>
            </tr>
        </thead>
        <tbody>
            {props.homeAwayRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.run}</td>
                    <td>{item.hit}</td>
                    <td>{item.h2}</td>
                    <td>{item.h3}</td>
                    <td>{item.hr}</td>
                    <td>{item.rbi}</td>
                    <td>{item.sh}</td>
                    <td>{item.sf}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.gd}</td>
                    <td>{item.hra}</td>
                </tr>
            )}
            {props.homeAwayRecordList.length === 0 ?
                <tr>
                    <td colSpan="16">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.homeAwayTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.homeAwayTotalRecord.pa}</td>
                    <td>{props.homeAwayTotalRecord.ab}</td>
                    <td>{props.homeAwayTotalRecord.run}</td>
                    <td>{props.homeAwayTotalRecord.hit}</td>
                    <td>{props.homeAwayTotalRecord.h2}</td>
                    <td>{props.homeAwayTotalRecord.h3}</td>
                    <td>{props.homeAwayTotalRecord.hr}</td>
                    <td>{props.homeAwayTotalRecord.rbi}</td>
                    <td>{props.homeAwayTotalRecord.sh}</td>
                    <td>{props.homeAwayTotalRecord.sf}</td>
                    <td>{props.homeAwayTotalRecord.bb}</td>
                    <td>{props.homeAwayTotalRecord.hp}</td>
                    <td>{props.homeAwayTotalRecord.kk}</td>
                    <td>{props.homeAwayTotalRecord.gd}</td>
                    <td>{(props.homeAwayTotalRecord.hit / props.homeAwayTotalRecord.ab).toFixed(3)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)

const HomeAwayRecordComponentForPitcher = props => (
    <table>
        <caption>홈/원정별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">홈/원정</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">세이브</th>
                <th scope="col">홀드</th>
                <th scope="col">이닝</th>
                <th scope="col">투구</th>
                <th scope="col">타자</th>
                <th scope="col">타수</th>
                <th scope="col">안타</th>
                <th scope="col">홈런</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">폭투</th>
                <th scope="col">보크</th>
                <th scope="col">실점</th>
                <th scope="col">자책</th>
                <th scope="col">방어율</th>
            </tr>
        </thead>
        <tbody>
            {props.homeAwayRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.w}</td>
                    <td>{item.l}</td>
                    <td>{item.sv}</td>
                    <td>{item.hold}</td>
                    <td>{item.innDisplay}</td>
                    <td>{item.tugucount}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.hit}</td>
                    <td>{item.hr}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.wp}</td>
                    <td>{item.bk}</td>
                    <td>{item.r}</td>
                    <td>{item.er}</td>
                    <td>{item.era}</td>
                </tr>
            )}
            {props.homeAwayRecordList.length === 0 ?
                <tr>
                    <td colSpan="19">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.homeAwayTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.homeAwayTotalRecord.w}</td>
                    <td>{props.homeAwayTotalRecord.l}</td>
                    <td>{props.homeAwayTotalRecord.sv}</td>
                    <td>{props.homeAwayTotalRecord.hold}</td>
                    <td>
                        {props.homeAwayTotalRecord.inn2 % 3 === 0
                        ? parseInt(props.homeAwayTotalRecord.inn2 / 3)
                        : parseInt(props.homeAwayTotalRecord.inn2 / 3) +
                            " " +
                            (props.homeAwayTotalRecord.inn2 % 3) +
                            "/3"}
                    </td>
                    <td>{props.homeAwayTotalRecord.tugucount}</td>
                    <td>{props.homeAwayTotalRecord.pa}</td>
                    <td>{props.homeAwayTotalRecord.ab}</td>
                    <td>{props.homeAwayTotalRecord.hit}</td>
                    <td>{props.homeAwayTotalRecord.hr}</td>
                    <td>{props.homeAwayTotalRecord.bb}</td>
                    <td>{props.homeAwayTotalRecord.hp}</td>
                    <td>{props.homeAwayTotalRecord.kk}</td>
                    <td>{props.homeAwayTotalRecord.wp}</td>
                    <td>{props.homeAwayTotalRecord.bk}</td>
                    <td>{props.homeAwayTotalRecord.r}</td>
                    <td>{props.homeAwayTotalRecord.er}</td>
                    <td>{((props.homeAwayTotalRecord.er * 9) / (props.homeAwayTotalRecord.inn2 / 3)).toFixed(2)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)


export default HomeAwayRecordComponent;