import React from 'react';

export const BestHitComponent = props => (
    <table>
        <caption>최다출루율 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최고출루율</th>
                <td>{props.bestBraObj.matchTeam.bra}</td>
                <td>
                    {props.bestBraObj.matchTeam.imgUrl ?
                        <img src={props.bestBraObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최고출루율</th>
                <td>{props.bestBraObj.monthly.bra}</td>
                <td>{props.bestBraObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최고출루율</th>
                <td>{props.bestBraObj.weekly.bra}</td>
                <td>{props.bestBraObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestHitComponent;