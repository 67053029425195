import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import CoachingStaffContainer from './coaching-staff/CoachingStaff.container';
// import PitcherContainer from './pitcher/Pitcher.container';
// import HitterContainer from './hitter/Hitter.container';
import MemberContainer from './member/Member.container';
// import MilitaryEnlistedContainer from './military-enlisted/MilitaryEnlisted.container';
import EntryStatusContainer from './entry-status/EntryStatus.container';
import SearchContainer from './member/search/Search.container';

class PlayersContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/coaching-staff`} component={CoachingStaffContainer} />
            <Route path={`${this.props.match.path}/pitcher`} component={MemberContainer} />
            <Route path={`${this.props.match.path}/catcher`} component={MemberContainer} />
            <Route path={`${this.props.match.path}/infielder`} component={MemberContainer} />
            <Route path={`${this.props.match.path}/outfielder`} component={MemberContainer} />
            <Route path={`${this.props.match.path}/military-enlisted`} component={MemberContainer} />
            <Route path={`${this.props.match.path}/entry-status`} component={EntryStatusContainer} />
            <Route path={`${this.props.match.path}/search`} component={SearchContainer} />
        </Switch>
    )
}

export default PlayersContainer;
