import React, { Component } from "react";
import CKEditor from "ckeditor4-react";

import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ArticleService from "../../../../services/Article.service";
import * as AuthHelper from "../../../../helpers/Auth.helper";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";
import * as AuthService from "../../../../services/Auth.service";

class FormContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    const config = {
      toolbar: [
        [
          "Undo",
          "Redo",
          "-",
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "FontSize",
        ],
        ["TextColor", "BGColor"],
        ["Table", "SpecialChar", "Link", "Unlink"],
      ],
      height: "400px",
      resize_enabled: false,
    };

    const articleId = this.props.match.params.articleId;

    this.state = {
      subMenu: "tigers",
      subMenuName: "호랑이 사랑방",
      articleId,
      title: "",
      contents: "",
      banWordList:[],
      config: config,
      userPermissionByInfo: "",
    };
  }

  componentDidMount = async (_) => {
    let copiedState = {...this.state}
    try {
      const userPermissionByInfo = await AuthService.getUserBoardPermission();
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "06";
      currentAnalyticsVal.deps3 = "02";
      currentAnalyticsVal.deps4 = "02";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);
      
      let title = "";
      let contents = "";

      if (this.state.articleId) {
        const result = await ArticleService.getTigersArticleInfo(
          this.state.articleId
        );

        title = result.article.artcTitle;
        contents = result.article.artcContent;

        this.setEditor(contents);
      } else {
        this.setEditor("");
      }
      
      copiedState = {
        ...copiedState, 
        title,
        contents,
        userPermissionByInfo: userPermissionByInfo.boardPermission
      }

      //banword호출 코드
      const banWordList = await ArticleService.getFilterWord().then((data)=>data)
      copiedState.banWordList = banWordList
      
    } catch (err) {
      console.error(err);
      //alert(err.message);
      if (err.message == "인증 오류입니다. 로그인을 하신 후에 이용해 주세요") {
        AuthHelper.removeLoginInfo();
        document.location.href = "/login";
      }
    }
    this.setState({
      ...copiedState
    })
  };

  onChangeInputTitle = (evt) => {
    this.setState({ title: evt.target.value });
  };
  onChangeCKEditor = (evt) => {
    // console.log('onChangeCKEditor', evt.editor.getData());
    this.setState({ contents: evt.editor.getData() });
  };
  
  onClickBtnCreate = async (evt) => {
    try {
      if (this.state.title.trim() === "") {
        throw new Error("제목을 입력해주세요.");
      } else if (this.state.contents.trim() === "") {
        throw new Error("내용을 입력해주세요.");
      }

      const userPermissionByInfo = await AuthService.getUserBoardPermission();

      if (userPermissionByInfo.boardPermission === "N") {
        throw new Error("글 쓰기 권한이 없습니다.");
      }

      const wordFilter = this.state.banWordList.some(i=> this.state.contents.includes(i) || this.state.title.includes(i))
      if (wordFilter) {
        throw new Error(
          "이 글에는 홈페이지 이용 정책에 위배되는 단어가 포함되어 있어 글을 등록할 수 없습니다. 이를 위반하고 글을 작성할 경우, 일정 기간 글쓰기 제한 또는 이용 해지 처리될 수 있으니 주의해주세요. 홈페이지 하단의 이용 정책을 확인하신 후, 이에 맞게 글을 수정하시어 모두가 이용하고 싶은 호랑이 사랑방을 만들어주세요."
        )
      }

      if (!this.state.articleId) {
        // create
        const result = await ArticleService.createArticle(
          this.state.title,
          this.state.contents
        );
        console.log(result);
        alert("정상적으로 등록되었습니다.");
        this.props.history.goBack();
      } else {
        // modify
        const result = await ArticleService.updateArticle(
          this.state.articleId,
          this.state.title,
          this.state.contents
        );
        console.log(result);
        alert("수정 성공하였습니다.");
        this.props.history.goBack();
      }
    } catch (err) {
      //alert(err.message);
    }
  };

  onClickBtnCancel = (_) => {
    this.props.history.goBack();
  };

  setEditor = (data) => {
    const editor = (
      <CKEditor
        config={this.state.config}
        data={data}
        onChange={this.onChangeCKEditor}
      />
    );
    this.setState({ editor: editor });
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        title={this.state.title}
        contents={this.state.contents}
        onChangeInputTitle={this.onChangeInputTitle}
        onChangeCKEditor={this.onChangeCKEditor}
        onClickBtnCreate={this.onClickBtnCreate}
        onClickBtnCancel={this.onClickBtnCancel}
        editor={this.state.editor}
        userPermissionByInfo={this.state.userPermissionByInfo}
      />
    </div>
  );
}

export default FormContainer;
