import React from 'react';

const ContComponent = props => (
    props.info ?
        <div className="mb_cont">
            <div className="mb_contIn bdView inqView">
                <div className="board">
                    <div className="h6Bar">
                        {props.info.replyYn === 'Y' ?
                            <p className="answTit on">답변</p>
                            :
                            <p className="answTit">미답변</p>
                        }
                        <span>{props.info.topicTitle}</span>
                        <p className="icoWrap">
                            <span className="date">{props.info.regDate}</span>
                            {/* <span className="view"><i></i>{props.info.replyCnt}</span>
                            <span className="like"><i></i>{props.info.likeCnt}</span> */}
                        </p>
                    </div>
                    <div className="viewCont">
                        <div>
                            <p>
                                {props.info.topicContents.split('\n').map((line, i) => {
                                    return (<span key={i}>{line}<br /></span>)
                                })}
                            </p>
                        </div>

                        {/* <!-- answ 답변완료 일때 보이는 답변 --> */}
                        {props.info.replyYn === 'Y' ?
                            props.replyList.map((item, idx) =>
                                <div key={idx} className="answ">
                                    <p>
                                        {item.replyContents.split('\n').map(line => {
                                            return (<span>{line}<br /></span>)
                                        })}
                                    </p>
                                </div>
                            )
                            : null}
                    </div>
                    {props.mediaInfo.length > 0 && (<div><a href={props.mediaInfo[0].mediaPath} download={props.mediaInfo[0].originalFileName} target="blank">등록 파일 다운로드 : {props.mediaInfo[0].originalFileName}</a></div>)}
                    <div className="icoWrap">
                        <div>
                            <button onClick={props.onClickBtnList} type="button" className={(props.info.isMine ? "pc_tb" : "") + " bgGray"}>목록</button>
                            {/* <!-- 수정버튼 없는 페이지 일때는 본버튼에 className="pc_tb"떼고 사용해주세요 --> */}
                        </div>
                    </div>
                    {props.info.isMine ?
                        <div className="btnWrap">
                            {/* <!-- 수정이 필요한 페이지 일때 버튼추가 / .icoWrap 목록버튼에서 className="pc_tb"를 추가해주세요--> */}
                            <button onClick={props.onClickBtnRemove} className="del">삭제</button>
                            {/* <button onClick={props.onClickBtnModify} className="chng">수정</button> */}
                            <button onClick={props.onClickBtnList} className="mb goList">목록</button>
                        </div>
                        : null}
                </div>
                {/* <!-- //board --> */}
                {/* <div className="arrowPN">
                    <p><i className="prev"></i><strong>이전글</strong><span>이전글이 옵니다.</span></p>
                    <p><i className="next"></i><strong>다음글</strong><span>다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다. 다음글이 없습니다.  다음글이 없습니다 다음글이 없습니다 다음글이 없습니다 다음글이 없습니다</span></p>
                </div> */}
            </div>
            {/* <!-- //mb_contIn --> */}
        </div>
        : null
)

export default ContComponent;