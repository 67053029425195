import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ContComponent from "./Cont.component";
import * as CommonHelper from "../../../helpers/Common.helper";

import * as AuthService from "../../../services/Auth.service";
import * as AuthHelper from "../../../helpers/Auth.helper";

import AnalyticsContext from "../../../context/AnalyticsContext";
import * as CommonService from "../../../services/Common.service";

import $ from "jquery";

class HeaderContainer extends Component {
  static contextType = AnalyticsContext;

  history = null;

  constructor(props) {
    super(props);
    this.state = {
      gnbFocusingIdx: 0,
      isOpenedGnb: false,
      pcObj: {
        isOpenedMenu: false
      },
      mObj: {
        isOpenedMenu: false
      }
    };

    //console.log('props.loginInfo', props.loginInfo);
  }

  componentDidUpdate = prevPros => {
    if (this.props.location != prevPros.location) {
      window.scrollTo(0, 0);
    }
  };

  onMouseOverGnb = (evt, gnbIdx) => {
    this.setState({
      isOpenedGnb: true,
      gnbFocusingIdx: gnbIdx
    });
  };
  onMouseLeaveGnb = (evt, gnbIdx) => {
    this.setState({
      isOpenedGnb: false,
      gnbFocusingIdx: 0
    });
  };

  onMouseOverGnbIn = evt => {
    this.setState({ isOpenedGnb: true });
  };
  onMouseLeaveGnbIn = evt => {
    this.setState({ isOpenedGnb: false });
  };

  closeAllGnb = () => {
    $(".gnbIn > li").removeClass("act");
    $(".gnbIn > li")
      .children("div")
      .slideUp("fast");

    $(".snbBtn_close").removeClass("on");
    $(".snbBtn").addClass("on");
    $(".gnbIn").css("display", "none");

    $(".menuBg").hide();
    $(".mb_snb").removeClass("on");
    $(".mb_gnb, .mb_snb").removeClass("on");

    $(".mb_snbIn li").removeClass("on");
    $(".mb_snbIn li > a")
      .parent("li")
      .addClass("on");
  };

  componentDidMount = () => {
    //console.log("componentDidMount");
    $(".field .sec02 h5 span").click(function() {
      $("html, body").css("overflow-y", "hidden");
      $(".field .mask").fadeIn();
      $(this)
        .parents()
        .next(".popI")
        .fadeIn();
    });

    $(".field .sec02 .popI .closeX, .field .mask").click(function() {
      $(".field .sec02 .popI").fadeOut();
      $(".field .mask").fadeOut();
      $("html, body").css("overflow-y", "visible");
    });

    //
    this.props.history.listen((location, action) => {
      //console.log("routeChange", location, action);
      this.closeAllGnb();
      setTimeout(() => {
        $(".gnbIn").css("display", "");
      }, 100);
    });

    // PC GNB
    // 20020226 수정분 반영
    $(".gnbIn > ul > li")
      .mouseover(evt => {
        $(evt.currentTarget).addClass("act");
        $(".gnbIn").css("display", "");
        $(".gnbIn").addClass("on");
      })
      .mouseleave(evt => {
        $(evt.currentTarget).removeClass("act");
        $(".gnbIn").removeClass("on");
      });

    $(".gnbIn").mouseover(evt => {
      $(evt.currentTarget).addClass("on");
    });
    $(".gnbIn").mouseleave(evt => {
      $(evt.currentTarget).removeClass("on");
    });
    $(".gnbInIn > dl").mouseover(evt => {
      var i = $(".gnbInIn > dl").index(evt.currentTarget);
      $(evt.currentTarget)
        .parents(".gnb")
        .find("li")
        .eq(i)
        .addClass("act");
    });
    $(".gnbInIn > dl").mouseleave(evt => {
      var i = $(".gnbInIn > dl").index(evt.currentTarget);
      $(evt.currentTarget)
        .parents(".gnb")
        .find("li")
        .eq(i)
        .removeClass("act");
    });
    // 20020226 수정분 반영

    // M GNB
    // 20020226 수정분 반영
    $(".btn_mbMenu a").click(evt => {
      evt.preventDefault();
      $("html, body").css("overflow-y", "hidden");
      $(".menuBg").show();
      $(".mb_gnb").addClass("on");
    });
    $(".btn_mbMenu_close").click(evt => {
      $(".menuBg").hide();
      $(".mb_gnb").removeClass("on");
      $(".mb_gnbIn li").removeClass("on");
      $("html, body").css("overflow-y", "visible");
    });
    $(".menuBg").click(evt => {
      $(".menuBg").hide();
      $(".mb_gnb, .mb_snb").removeClass("on");
      $("html, body").css("overflow-y", "visible");
    });

    $(".btn_mbLogin a").click(evt => {
      evt.preventDefault();
      $("html, body").css("overflow-y", "hidden");
      $(".menuBg").show();
      $(".mb_snb").addClass("on");
    });
    $(".btn_mbLogin_close").click(function() {
      $(".menuBg").hide();
      $(".mb_snb").removeClass("on");
      $(".mb_snb, .mb_snbIn li").removeClass("on");
      $("html, body").css("overflow-y", "visible");
    });
    $(".snb_btn a").click(function() {
      console.log("snb-btn");
      $("html, body").css("overflow-y", "visible");
    });
    // 20020226 수정분 반영

    // 1뎁스 메뉴 토글
    // 20020226 수정분 반영
    $(".mb_gnbIn li > a").click(evt => {
      evt.preventDefault();
      var i = $(evt.currentTarget).parent("li");
      $(".mb_gnbIn li")
        .not(i)
        .removeClass("on");
      $(evt.currentTarget)
        .parent("li")
        .toggleClass("on");
    });

    $(".mb_snb h2 a").click(evt => {
      $("html, body").css("overflow-y", "visible");
    });
    $(".mb_snbIn li > a").click(evt => {
      evt.preventDefault();
      var i = $(evt.currentTarget).parent("li");
      $(".mb_snbIn li")
        .not(i)
        .removeClass("on");
      $(evt.currentTarget)
        .parent("li")
        .toggleClass("on");
    });
    $(".mb_snbIn li > div a").click(evt => {
      $("html, body").css("overflow-y", "visible");
    });
    // 20020226 수정분 반영
    $(".mb_gnb h2 a").click(evt => {
      $("html, body").css("overflow-y", "visible");
    });
    // 2뎁스 메뉴 클릭
    $(".mb_gnbIn li > div > p > a").click(evt => {
      console.log(".mb_gnbIn li > div > p > a");
      $("html, body").css("overflow-y", "visible");
    });

    $(window).scroll(evt => {
      let innerWidth = window.innerWidth;
      const scrollTop = $(evt.currentTarget).scrollTop();
      if (innerWidth >= 1200) {
        if (scrollTop < 50) {
          $(".topM").show();
          $(".mainLogo").removeClass("on");
          $("nav, .contIn").removeClass("on");
        } else if (scrollTop >= 50 && scrollTop < 154) {
        } else {
          $(".topM").hide();
          $(".mainLogo").addClass("on");
          $("nav, .contIn").addClass("on");
        }
      } else {
        if (scrollTop < 50) {
          $(".btn_mbMenu").css("position", "absolute");
          $(".btn_mbLogin").css("position", "absolute");
          $(".topM").show();
          $(".mainLogo").removeClass("on");
          $("nav, .contIn").removeClass("on");
        } else {
          $(".btn_mbMenu").css("position", "fixed");
          $(".btn_mbLogin").css("position", "fixed");
          $(".topM").hide();
          $(".mainLogo").addClass("on");
          $("nav, .contIn").addClass("on");
        }
      }
    });
  };

  onClickBtnLogout = async _ => {
    console.log("onClickBtnLogout");
    try {
      await AuthService.logout();
    } catch (err) {
      console.error(err);
    } finally {
      AuthHelper.removeLoginInfo();
      document.location.href = "/";
    }
  };

  onClickHistorySite = async () => {
    const AnalyticsValue = this.context.state;
    this.context.action(AnalyticsValue);

    AnalyticsValue.deps2 = "07";
    AnalyticsValue.prevUrl = this.context.state.currentUrl;
    AnalyticsValue.currentUrl = "https://tigers.co.kr/history"
    
    await CommonService.insertAnalytics(this.context.state);
    
    AnalyticsValue.currentUrl = this.context.state.prevUrl;
  }

  render = () =>
    !CommonHelper.checkIsOnWebview() ? (
      <ContComponent
        loginInfo={this.props.loginInfo}
        pcObj={this.state.pcObj}
        mObj={this.state.mObj}
        isOpenedGnb={this.state.isOpenedGnb}
        gnbFocusingIdx={this.state.gnbFocusingIdx}
        onMouseOverGnb={this.onMouseOverGnb}
        onMouseLeaveGnb={this.onMouseLeaveGnb}
        onMouseOverGnbIn={this.onMouseOverGnbIn}
        onMouseLeaveGnbIn={this.onMouseLeaveGnbIn}
        onClickBtnLogout={this.onClickBtnLogout}
        onClickHistorySite={this.onClickHistorySite}
      />
    ) : null;
}

export default withRouter(HeaderContainer);
