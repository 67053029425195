import * as React from 'react';
import {useQuery} from "react-query";
import axios from "axios";

export function NaverLive() {

  const {isLoading, data: liveURL} = useQuery(
    "naver-live",
    () => axios.get('/v1/game/live').then(res => res.data.data.liveURL), {
      onSuccess: (data) => {
        console.log("NaverLive", data)
      }
    })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div style={{display: 'flex', position: 'relative', width: '100%', paddingTop: '56.25%', height: 0, overflow: 'hidden'}}>
      <iframe src={liveURL}
              style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
              frameBorder='no'
              scrolling='no'
              marginWidth='0'
              marginHeight='0'
              width='800'
              height='450'
              allow='autoplay'
              allowFullScreen>
      </iframe>
    </div>
  );
}
