import React from 'react';
import SubMenuComponent from '../common/SubMenu.component';
import PitcherComponent from './Pitcher.component';
import HitterComponent from './Hitter.component';

const ContComponent = (props) => (
    <div className="mb_cont sub_cont gameH">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName}
                thirdMenu={props.thirdMenu}
                thirdMenuName={props.thirdMenuName} />
            <ul className="tab02 w2 clear">
                <li className={props.tabNum === 1 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 1)}>투수</a>
                </li>
                <li className={props.tabNum === 2 ? "on" : ""}>
                    <a onClick={evt => props.onClickBtnTab(evt, 2)}>타자</a>
                </li>
            </ul>
            {props.tabNum === 1 ? <PitcherComponent list={props.list} /> : null}
            {props.tabNum === 2 ? <HitterComponent list={props.list} /> : null}
        </div>
    </div>
)

export default ContComponent;
