import React from 'react';

const ContComponent = props => (
    <div className="mb_cont">
        <div className="mb_contIn smart">
            <img src="/img/sub/img_smartorder.png" alt="스마트오더 안내 이미지" />
            <div className="comp_btn">바로가기</div>
        </div>
        {/* <!-- //mb_contIn --> */}
    </div>
)

export default ContComponent;