import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import ContComponent from "./Cont.component";
import queryString from "query-string";

import * as ArticleService from "../../../../../services/Article.service";
import * as ListHelper from "../../../../../helpers/List.helper";
import * as DateHelper from "../../../../../helpers/Date.helper";
import AnalyticsContext from "../../../../../context/AnalyticsContext";
import * as CommonService from "../../../../../services/Common.service";

class ListContainer extends Component {
  static contextType = AnalyticsContext;
 

  constructor(props) {
    super(props);

    const queryStringParams = queryString.parse(this.props.location.search);

    const currentPage = parseInt(
      queryStringParams["page"] ? queryStringParams["page"] : 1,
      10
    );
    const selectedSearchFilter = queryStringParams["searchFilter"]
      ? queryStringParams["searchFilter"]
      : 100;
    const searchKeyword = queryStringParams["searchKeyword"]
      ? queryStringParams["searchKeyword"]
      : "";
    const searchFilterSelectList = ListHelper.getSearchFilterSelectList();

    const subMenu = "archive";
    const thirdMenu = "episode";

    this.state = {
      searchKeyword,
      selectedSearchFilter,
      searchFilterSelectList,
      currentPage,
      totalPage: 0,
      paginationObj: null,
      list: [],
      subMenu,
      subMenuName: "지난 콘텐츠 보기",
      thirdMenu,
      thirdMenuName: "타이거즈 에피소드",
      tabNum: 1,
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "03";
      currentAnalyticsVal.deps3 = "07"; //
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const offset = ListHelper.makeOffset(this.state.currentPage, 8);

      const result = await this.getList(
        offset,
        this.state.searchKeyword,
        this.state.selectedSearchFilter
      );

      let totalPage = 0;
      let paginationObj = null;

      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            this.state.currentPage,
            10,
            5
          );
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(
          result.list[i]["regDttm"]
        );
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(
          result.list[i]["regDttm"]
        );
      }

      this.setState({ list: result.list, totalPage, paginationObj });
    } catch (err) {
      //alert(err.message);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "03";
      currentAnalyticsVal.deps3 = "07"; //
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const queryStringParams = queryString.parse(this.props.location.search);

      const page = parseInt(
        queryStringParams["page"] ? queryStringParams["page"] : 1,
        10
      );
      const searchKeyword = queryStringParams["searchKeyword"]
        ? queryStringParams["searchKeyword"]
        : "";
      const searchFilter = queryStringParams["searchFilter"]
        ? queryStringParams["searchFilter"]
        : 100;

      this.updateList(page, searchKeyword, searchFilter);
    }
  };

  updateList = async (page, searchKeyword, searchFilter) => {
    console.log("updateList", page, searchKeyword, searchFilter);
    try {
      const offset = ListHelper.makeOffset(page, 8);

      const result = await this.getList(offset, searchKeyword, searchFilter);

      let totalPage = 0;
      let paginationObj = null;

      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(totalPage, page, 10, 5);
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(
          result.list[i]["regDttm"]
        );
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(
          result.list[i]["regDttm"]
        );
      }
      console.log(paginationObj);
      this.setState({
        list: result.list,
        totalPage,
        paginationObj,
        currentPage: page,
      });
    } catch (err) {
      //alert(err.message);
    }
  };

  getList = async (offset, searchKeyword, searchFilter) => {
    let listParams = {
      "search.pos": offset,
      "search.max": 8,
    };
    if (searchKeyword) {
      listParams["search.searchWord"] = searchKeyword;
      listParams["search.searchCategory"] = searchFilter;
    }

    console.log("listParams", listParams);

    return ArticleService.getEpisodeArticleList(listParams);
  };

  onChangeSelectList = (evt) => {
    this.setState({ selectedSearchFilter: evt.target.value });
  };
  onChangeInput = (evt) => {
    console.log(evt.target.value);
    this.setState({ searchKeyword: evt.target.value });
  };

  onClickBtnSearch = (evt) => {
    let url = "/contents/archive/episode?page=1";
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
      url += `&searchFilter=${this.state.selectedSearchFilter}`;
    }
    this.props.history.push(url);
  };

  onClickBtnPageNum = (evt, pageNum) => {
    console.log("onClickBtnPageNum", pageNum, this.state);
    let url = `/contents/archive/episode?page=${pageNum}`;
    if (this.state.searchKeyword.trim() !== "") {
      url += `&searchKeyword=${this.state.searchKeyword.trim()}`;
      url += `&searchFilter=${this.state.selectedSearchFilter}`;
    }
    this.props.history.push(url);
  };

  onEnterKeyPress = (evt) =>{
    if(evt.key === 'Enter'){
      this.onClickBtnSearch();
    }
  }

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <ContComponent
        selectedSearchFilter={this.state.selectedSearchFilter}
        searchKeyword={this.state.searchKeyword}
        searchFilterSelectList={this.state.searchFilterSelectList}
        onClickBtnSearch={this.onClickBtnSearch}
        onChangeSelectList={this.onChangeSelectList}
        onChangeInput={this.onChangeInput}
        list={this.state.list}
        paginationObj={this.state.paginationObj}
        onClickBtnPageNum={this.onClickBtnPageNum}
        onEnterKeyPress={this.onEnterKeyPress}
        
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
      />
    </div>
  );
}

export default ListContainer;
