import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as CommonHelper from "../../../helpers/Common.helper";
import PrepareComponent from '../../layout/Prepare.component'
import dayjs from 'dayjs';
import {decodeAES256} from "../../../helpers/Crypto.helper";

const zoomPanPinchOptions = {
  transformEnabled: true,
  centerContent: true,
};

const redirectYn = process.env.REACT_APP_REDIRECT_STORE_YN;

const ContComponent = (props) => {

  return (
      <div className="mb_cont join fnd pw refundAccount">
        <div className="mb_contIn">
          <div className="box">
            <div>
              <h4>무통장예매 환불계좌 확인</h4>
              <p>무통장 결제 후 취소 환불을 위해 아래와 같이 등록 된 환불계좌로 환불이 진행됩니다.</p>
            </div>
            <form action="/" method="post">
              <fieldset>
                <legend>비밀번호 확인</legend>
                <div>
                  <label htmlFor="userID">아이디</label>
                  <input
                      type="text"
                      id="userID"
                      value={decodeAES256(props.userId)}
                      readOnly
                      placeholder="아이디"
                  />
                </div>
                <div>
                  <label htmlFor="bankCd">은행</label>
                  <select onChange={props.onChangeBankCode}>
                    <option value="">은행선택</option>
                    {props.bankCodeList.map((item, index) => {
                      if (item.bankCd !== props.refundAccountInfo.bankCd) {
                        return (
                            <option key={index} value={item.bankCd}>{item.bankCdAlias}</option>
                        );
                      } else {
                        return (
                            <option key={index} value={item.bankCd} selected>{item.bankCdAlias}</option>
                        );
                      }
                    })}
                  </select>
                </div>
                <div>
                  <label htmlFor="bankAc">계좌번호</label>
                  <input
                      type="text"
                      id="bankAcc"
                      value={props.refundAccountInfo.bankAcc}
                      onChange={props.onChangeBankAcc}
                  />
                </div>
                <div>
                  <label htmlFor="depositor">예금주</label>
                  <input
                      type="text"
                      id="depositor"
                      value={props.refundAccountInfo.depositor}
                      readOnly
                      placeholder="예금주"
                  />
                </div>
                <div>

                  <p>● 환불 처리를 위해 계좌정보(예금주, 은행, 계좌번호)를 수집하며 관련 법령에 따라 5년간 보관 후 삭제됩니다.</p>
                  <p>● 등록된 환불계좌는 정보 입력 또는 수정일로부터 1년간 보관하며, 1년 경과 시 금융 정보 보호 정책에 따라 삭제됩니다.</p>
                  <p>● 환불 처리를 위한 필요 최소한의 개인 정보이므로 동의를 해주셔야만 서비스를 이용하실 수 있습니다.</p>
                  <p>● 환불계좌는 본인 명의 계좌만 등록/변경이 가능합니다.</p>
                </div>
                <div class="checkBox">
                  <input type={"checkbox"} id={"confirm_y"} name={"confirm"} value={"Y"} onChange={props.onChangeInputConfirm} /><label htmlFor="confirm_y">개인정보 수집, 이용 동의</label>
                </div>
              </fieldset>
              <div className="comp_btn" onClick={props.onSubmitBankInfo}>
                등록하기
              </div>
            </form>
          </div>
        </div>
      </div>
  )
}

export default ContComponent;
