import React, { useEffect, useState } from "react";
import { turnNormalUser } from "../../../services/Auth.service";
import ContComponent from "./Cont.component";

import * as AuthService from "../../../services/Auth.service";
import * as AuthHelper from "../../../helpers/Auth.helper";

const TurnNormalContainer = ({ userInfo, history, setLoginInfo }) => {
  const [showingResult, setShowingResult] = useState(false);

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);
  const authPhone = (evt) => {
    window.open(
      `/v1/user/identify/phone/request`,
      "mobilem",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  const authIpin = (evt) => {
    window.open(
      `/v1/user/identify/ipin/request`,
      "ipin2",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  const goHome = () => {
    history.push("/");
  };

  const onMessage = async (e) => {
    if (e.data.type === "IPIN2" || e.data.type === "MOBILEM") {
      const request = {
        id: userInfo.id,
        niceData: encodeURIComponent(e.data.data),
      };

      const result = await turnNormalUser(e.data.type, { ...request });

      console.log(result)

      if(result.status === 200) {
        const loginInfo = await AuthService.login(userInfo.id, userInfo.pw);

        setLoginInfo(loginInfo);

        setShowingResult(true);
      }
    }
  };

  return (
    <div className="container">
      <ContComponent
        authPhone={authPhone}
        authIpin={authIpin}
        showingResult={showingResult}
        goHome={goHome}
        history={history}
      />
    </div>
  );
};

export default TurnNormalContainer;
