import React from 'react';

const SubHeadComponent = () => (
    <div className="sub_head login">
        <div className="sub_HeadIn1">
            <h2>회원가입</h2>
            <ul>
                <li><img src="/img/sub/ico_home.png" alt="홈 바로가기" /></li>
                <li>회원가입</li>
            </ul>
        </div>
        {/* <!-- //sub_HeadIn1 --> */}
        <div className="sub_HeadIn2 tb"></div>
    </div>
    // <!-- //sub_head -->
)
export default SubHeadComponent;