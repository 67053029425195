import React from 'react';

const SubHeadConfirmPasswordComponent = (props) => (
    <div className="sub_head login">
        <div className="sub_HeadIn1">
            <h2>비밀번호 확인</h2>
            <ul>
                <li><img src="/img/sub/ico_home.png" alt="홈 바로가기" /></li>
                <li>마이페이지</li>
                <li>비밀번호 확인</li>
            </ul>
        </div>
        <div className="sub_HeadIn2 tb"></div>
    </div>
)

export default SubHeadConfirmPasswordComponent;