import React from "react";
import SubMenuComponent from "../common/SubMenu.component";

const ContComponent = props => (
  <div className="mb_cont sub_cont tigers">
    <div className="mb_contIn bi">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      {/* <!-- //tabWrap --> */}
      <article>
        <h5 className="lineRed">KIA타이거즈 CI</h5>
        <ul>
          <li>
            <img src="/img/sub/bi_bi01.png" alt="KIA타이거즈 CI" />
          </li>
          <li className="txt">
            <p>
              <span className="txtRed">승리의 </span> KIA타이거즈 CI
            </p>
            <p>
              KIA타이거즈의 워드마크는 리뉴얼 앰블럼 디자인에 활용된 로고타입을
              계승하여 로고타입의 좌우를 향한사선의 조형요소를 강조하여
              표현하였다.
              <br />
              VICTORY의 V조형과 기아레드를 활용하여 장식요소를 배제한 워드마크는
              KIA타이거즈의 승리하는 명문구단의 이미지를 전달하고 있다.
            </p>
          </li>
        </ul>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/bi/wordmark.jpg",
                "wordmark.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
      <article>
        <h5 className="lineRed">이니셜 로고</h5>
        <ul>
          <li>
            <img src="/img/sub/bi_bi02.png" alt="이니셜로고" />
          </li>
          <li className="txt">
            <p>
              <span className="txtRed">강인함의 </span> KIA타이거즈 이니셜 로고{" "}
            </p>
            <p>
              KIA타이거즈의 이니셜 로고는 워드마크와 더불어 구단의 아이덴티티를
              전달하는 핵심적인 요소이다.
              <br />
              투사의 투구 안면부를 연상하는 조형을 모티브로 제작하여
              KIA타이거즈의 강력한 이미지를 전달하고 명문구단의 강인함을 상징화
              하였다.
            </p>
          </li>
        </ul>
        <img src="/img/sub/bi_bi03.png" alt="배경이 다른 이니셜로고 4가지" />
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/bi/initial-logo.jpg",
                "initial-logo.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
