import axios from "axios";
import { encodeSHA256 } from "../helpers/Crypto.helper";
import TeamGuideContainer from "../scenes/tigers/champions-field/team-guide/TeamGuide.container";

const baseUrl = "/v1";
// const baseUrl = "http://localhost:8080/v1";

const pnsToken = "dummyToken";

export const checkEventTarget = async (event_idx) => {

  if(!event_idx) {
    throw new Error('event_idx is required');
  }

  return axios
    .get(`${baseUrl}/user/event/permission`,{params: {eventIdx: event_idx}})
    // .get(`/user/event/permission`)
    .then(({ data }) => {
      if(data.status === 1) {
        return false;
      }
      if(data.status === 0) {
        return true;
      }
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const requestApplyEventNotAuth = async (params) => {
  const data = {
    eventIdx: params.eventIdx,
    extData: {
      applicant_name: params.extData.applicant_name,
      applicant_age: params.extData.applicant_age,
      applicant_locale: params.extData.applicant_locale,
      applicant_phone: params.extData.applicant_phone,
      applicant_birthday: params.extData.applicant_birthday,
      applicant_shoe_size: params.extData.applicant_shoe_size,
      applicant_gender: params.extData.applicant_gender,
      parent_email: params.extData.parent_email,
      parent_phone: params.extData.parent_phone,
      parent_relation: params.extData.parent_relation,
      uniform_num: params.extData.uniform_num,
      size_check_schedule: params.extData.size_check_schedule,
      confirm: params.extData.confirm,
      target_confirm: params.extData.target_confirm,
      privacy_confirm: params.extData.privacy_confirm,
      social_number_confirm: params.extData.social_number_confirm,
      privacy_handle_confirm: params.extData.privacy_handle_confirm,
      record_confirm: params.extData.record_confirm,
      userId: params.extData.userId,
      userNm: params.extData.userNm,
      userMobile: params.extData.userMobile
    }
  }


  return axios
      .post(`${baseUrl}/user/event/cultureCampApply`, data)
      // .post(`/user/event/apply`, params)
      .then(({data}) => {

        if(data.status === 1) {

          return {message: data.message, status: false};
        }
        if(data.status === 0) {
          return {message: '', status: true};
        }
      }).catch((err) => {
        console.error(err);
        throw new Error(err.message);
      });


}
export const requestApplyEvent = async (params) => {
//                userNo,
//                userNm,
//                userMobile,
//                child_name,
//                mobile_no,
//                child_birthdate,
//                eventIdx: 1
  const data = {
    phone_no: params.mobile_no,
    eventIdx: params.eventIdx
  }

  if(params.add_info1) {
    data.add_info1 = params.add_info1;
  }
  if(params.add_info2) {
    data.add_info2 = params.add_info2;
  }
  if(params.add_info3) {
    data.add_info3 = params.add_info3;
  }

  if(params.confirm) {
    data.confirm = params.confirm;
  }
  if(params.add_info3) {
    data.add_info3 = params.add_info3;
  }

  if(params.extData) {
    data.ext_info = params.extData;
  }



  return axios
    .post(`${baseUrl}/user/event/apply`, data)
    // .post(`/user/event/apply`, params)
    .then(({data}) => {
      console.log(data);

      if(data.status === 1) {

        return {message: data.message, status: false};
      }
      if(data.status === 0) {
        return {message: '', status: true};
      }
    }).catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });

}


export const getEventApplyStatus = async (params) => {
  return axios
    .get(`${baseUrl}/user/event/apply/status/${params.eventIdx}`)
    .then(({data}) => {
      console.log(data);
      if(data.status === 1) {
        return false;
      }
      if(data.status === 0) {
        return true;
      }
    }).catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
}

export const getEventDetail = async (eventIdx) => {
    // url /user/event/:idx
    return axios
        .get(`${baseUrl}/user/event/${eventIdx}`)
        .then(({data}) => {
            if(data.status === 1) {
                throw new Error(data.message);
            }
            if(data.status === 0) {
                return data.data;
            }
        }).catch((err) => {
            console.error(err);
            throw new Error(err.message);
        });
}
