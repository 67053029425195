import React from 'react';

export const BestHoldComponent = props => (
    <table>
        <caption>최다홀드 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최다홀드</th>
                <td>{props.bestHoldObj.matchTeam.hold}</td>
                <td>
                    {props.bestHoldObj.matchTeam.imgUrl ?
                        <img src={props.bestHoldObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최다홀드</th>
                <td>{props.bestHoldObj.monthly.hold}</td>
                <td>{props.bestHoldObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최다홀드</th>
                <td>{props.bestHoldObj.weekly.hold}</td>
                <td>{props.bestHoldObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestHoldComponent;