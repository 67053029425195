import React from "react";
import styled from 'styled-components';
import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const StyleUl = styled.ul`
  background: #f8f8fa;
`

const StyleLiFirst = styled.li`
  border-right: 0 !important;
`
const StyleLiSecond = styled.li`
  border-left: 1px solid #e4e5e6;
  background: #ffffff;
`

const StyleLabel = styled.label`
  font-size: min(3vw, 15px);
`

const StyleLabel2 = styled.label`
  font-size: min(3vw, 15px);
  //font-weight: bold;
  color: #ea0029;
`

const StrongStyleP = styled.p`
  //font-weight: bold;
  color: #ea0029;
  position: inherit !important;
  top: 0 !important;
  right: 0 !important; 
`

const StrongStyleSpan = styled.span`
  //font-weight: bold;
  color: #ea0029;
`

const ContComponent = (props) => (
  <div className="mb_cont membership sub_cont ">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">&nbsp;</h6>
      <img src={"https://kia-tigers-public.s3.ap-northeast-2.amazonaws.com/event/youthcamp_20231128.jpg"} style={{margin: "10px 0", width: "100%"}} />

      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="user_id">아이디</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" id="user_id"
                     value={decodeAES256(props.userId)}
                     readOnly={true}
                     disabled={true}
              />
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="user_name">이름</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" id="user_name"
                     value={decodeAES256(props.userName)}
                     readOnly={true}
                     disabled={true}
              />
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_name">참가자 이름</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">

              <input type="text" id="applicant_name"
                     placeholder={"참가자 이름을 입력해주세요"}
                     onChange={props.onChangeInputApplicantName}
                     value={props.applicant_name}
              />
              *적어주신 이름을 기준으로 유니폼이 제작될 예정이니, 정확한 기재 부탁드립니다.
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_birthday">참가자 생년월일</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" id="applicant_birthday"
                     placeholder={"참가자 생년월일을 입력해주세요"}
                     onChange={props.onChangeInputApplicantBirthday}
                     value={props.applicant_birthday}
              />
              *0000.00.00 8자리 / 해당 형식으로 입력 부탁드립니다
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_gender">참가자 성별</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <select id="applicant_gender"  onChange={props.onChangeInputApplicantGender}
                      style={{
                        height: "33px",
                        padding: "5px",
                        border: "1px solid #9a9a9a",
                        fontSize: "12px",
                        width: "100%"
                      }}>
                <option value="none">성별을 선택해주세요</option>
                <option value="남자">남자</option>
                <option value="여자">여자</option>
              </select>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_age">참가자 나이</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <select id="applicant_age"  onChange={props.onChangeInputApplicantAge}
                      style={{
                        height: "33px",
                        padding: "5px",
                        border: "1px solid #9a9a9a",
                        fontSize: "12px",
                        width: "100%"
                      }}>
                <option value="none">나이를 선택해주세요</option>
                <option value="13">만 13세</option>
                <option value="14">만 14세</option>
                <option value="15">만 15세</option>
                <option value="16">만 16세</option>
                <option value="17">만 17세</option>
                <option value="18">만 18세</option>
              </select>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_locale">참가자 거주지역</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <select id="applicant_locale" onChange={props.onChangeInputApplicantLocale}
                      style={{
                        height: "33px",
                        padding: "5px",
                        border: "1px solid #9a9a9a",
                        fontSize: "12px",
                        width: "100%"
                      }}>
                <option value="none">지역을 선택해주세요</option>
                <option value="광주">광주</option>
                <option value="전남">전남</option>
                <option value="전북">전북</option>
              </select>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_phone">참가자 연락처</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">

              <input type="text" id="applicant_phone"
                     placeholder={"참가자 연락처를 입력해주세요"}
                     onChange={props.onChangeInputApplicantPhone}
                     value={props.applicant_phone}
              />
              *참가자 연락처가 없을 경우 보호자 연락처 기입 부탁드립니다.
            </StyleLiSecond>
          </StyleUl>

          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="applicant_shoe_size">참가자 신발 사이즈</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <select id="applicant_shoe_size" onChange={props.onChangeInputApplicantShoeSize}
                      style={{
                        height: "33px",
                        padding: "5px",
                        border: "1px solid #9a9a9a",
                        fontSize: "12px",
                        width: "100%"
                      }}>
                <option value="none">신발 사이즈를 선택해주세요</option>
                <option value="230">230mm</option>
                <option value="235">235mm</option>
                <option value="240">240mm</option>
                <option value="245">245mm</option>
                <option value="250">250mm</option>
                <option value="255">255mm</option>
                <option value="260">260mm</option>
                <option value="265">265mm</option>
                <option value="270">270mm</option>
                <option value="275">275mm</option>
                <option value="280">280mm</option>
                <option value="285">285mm</option>
              </select>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="uniform_num">희망하는 유니폼 번호</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" pattern="\d*" maxLength="2" id="uniform_num"
                     placeholder={"희망하는 유니폼 번호를 입력해주세요"}
                     onChange={props.onChangeInputUniformNum}
                     value={props.uniform_num}
              />
              *1~99까지 선택 가능합니다.
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="parent_phone">보호자 연락처</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">

              <input type="text" id="parent_phone"
                     placeholder={"보호자 연락처을 입력해주세요"}
                     onChange={props.onChangeInputParentPhone}
                     value={props.parent_phone}
              />
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="parent_relation">보호자 관계</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <select id="parent_relation" onChange={props.onChangeInputParentRelation}
                      style={{
                        height: "33px",
                        padding: "5px",
                        border: "1px solid #9a9a9a",
                        fontSize: "12px",
                        width: "100%"
                      }}>
                <option value="none">관계를 선택해주세요</option>
                <option value="부">부</option>
                <option value="모">모</option>
                <option value="조부">조부</option>
                <option value="조모">조모</option>
                <option value="기타">기타</option>
              </select>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="parent_email">보호자 이메일</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">

              <input type="text" id="parent_email"
                     placeholder={"보호자 이메일을 입력해주세요"}
                     onChange={props.onChangeInputParentEmail}
                     value={props.parent_email}
              />
              *참가자에 한해 유니폼 사이즈 관련해서 메일을 통해 연락드릴 예정입니다. 반드시 수신 가능한 이메일 기입 부탁드립니다.
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst>사전 방문 일정(택1)</StyleLiFirst>
            <StyleLiSecond className="tit">
              <StyleLabel htmlFor="sizeCheck">모집 이후 헬멧 사이즈 실측 및 증빙 서류 확인을 위해 1회 광주-기아챔피언스필드 방문이 필요합니다.<br/>방문 가능한 일정을 선택 부탁드립니다. (자세한 사항은 추후 참가자에 한해 개별 안내 예정)</StyleLabel>
              <div className="agree">
                <input type={"radio"} id={"size_check_1"} name={"size_check"} value={"2023-12-06"} onChange={props.onChangeInputSizeCheck}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel htmlFor={"size_check_1"}>1. 12/7(목) 오전9:00 – 오후5:00</StyleLabel>
                <br/>
                <input type={"radio"} id={"size_check_2"} name={"size_check"} value={"2023-12-07"} onChange={props.onChangeInputSizeCheck}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel htmlFor={"size_check_2"}>2. 12/8(금) 오전9:00 – 오후5:00</StyleLabel>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst></StyleLiFirst>
            <StyleLiSecond className="tit">
              <StyleLabel htmlFor="confirm">모집 후 적어주신 내용을 바탕으로 유니폼 및 각종 장비 제작이 진행될 예정입니다.<br/>제출 후 정보 수정은 불가하며 정보 오기입으로 인한 책임은 지지 않습니다.<br/>신중하게 작성 후 제출 부탁드립니다.</StyleLabel>
              <div className="agree">
                <input type={"checkbox"} id={"confirm_y"} name={"confirm"} value={"Y"} onChange={props.onChangeInputConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel htmlFor={"confirm_y"}>확인했습니다.</StyleLabel>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst></StyleLiFirst>
            <StyleLiSecond className="tit">
              <StyleLabel htmlFor="target_confirm">KIA TIGERS YOUTH BASEBALL CAMP는 다문화가족 청소년(만 13세 ~ 18세)을 대상으로 진행하는 주말 야구캠프입니다.<br/>선착순 모집 후 증빙 서류 확인 절차가 있을 예정입니다.<br/>모집 대상이 아니거나 서류 증빙이 불가능한 경우, 모집 대상에서 제외되며 다른 사람에게 참여 기회가 주어집니다.<br/>모집 대상 확인하였으며 증빙 서류 확인 절차 관련에 동의합니다.</StyleLabel>
              <div className="agree">
                <input type={"radio"} id={"target_confirm_y"} name={"target_confirm"} value={"Y"} onChange={props.onChangeInputTargetConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel htmlFor={"target_confirm_y"}>동의</StyleLabel>
                <input type={"radio"} id={"target_confirm_n"} name={"target_confirm"} value={"N"} onChange={props.onChangeInputTargetConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                <StyleLabel htmlFor={"target_confirm_n"}>미동의</StyleLabel>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><StyleLabel>참가 동의 안내(1~4)</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <div>
                - 본인은 기아타이거즈 주식회사 (이하  '당사' 라 한다.) 가 본인의 개인정보를 아래 기재한 바와 같이 처리한다는 사실을 안내 받았으며,<br/><StrongStyleSpan>이에 동의하는 뜻으로 ‘동의함’ 에 체크합니다.</StrongStyleSpan>
                <br/><br/>
                - <StrongStyleSpan>본인은  개인정보 수집 · 이용에  동의하지 않을 수 있습니다.</StrongStyleSpan> 수집되는 개인정보의 항목과 개인정보의 수집 · 이용에 대한 거부를 할 수 있는 권리가 있다는 사실을 충분히 설명 받고 숙지하였으며, <StrongStyleSpan>미동의시 부득이 참가 거부 의사표시로 간주되어 참가 신청이 불가합니다.</StrongStyleSpan>
                <br/><br/>
                - 당사는 개인정보를 수집 및 이용 목적에 필요한 범위에서 적합하게 처리하고 있으며, 그 목적 이외의 용도로 사용하지 않습니다.<br/>수집한 개인정보는 아래 명시된 보유 · 이용 기간 종료시 즉시 파기하고 있습니다.<br/>개인정보를 제공한 계약 당사자는 언제든 자신이 입력한 개인정보를 열람 및 수정할 수 있으며, 정보제공을 철회할 수 있습니다.
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><StyleLabel>1. 개인정보 수집 · 이용에 대한 동의 (필수)</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <div>
                □ 수집하는 개인정보 항목 : 1) 성명, 2) 생년월일, 3) 성별, 4) 연락처, 5) 의류, 신발 사이즈, 6) 보호자 정보 : 관계, 연락처, 이메일, 7) 거주지<br/>
                □ 수집 및 이용 목적 : 캠프 진행 (KIA TIGERS YOUTH BASEBALL CAMP) , 참가 혜택 상품 제작 및 제공(유니폼, 운동화, 헬멧, 글러브), 여행자 보험 가입<br/>
                □ 보유 · 이용 기간 : 캠프일이 속한 해당년도 12월 31일까지 / 단, 기타 개별법령에서 보유기간을 정하고 있는 경우 법령에서 정한 기간 동안 보관함(별도 분리보관) / 여행자 보험 가입 목적의 경우, 여행자보험 가입 기간 종료시까지<br/><br/>

                □ 만 14세 미만 : 보호자 (법정대리인) 정보 - 성명, 생년월일, 연락처<br/>
                □ 수집 및 이용 목적 : 만 14세 미만 참가자의 정보처리를 위한 법정대리인 동의<br/>
                □ 보유 · 이용 기간 : 캠프일이 속한 해당년도 12월 31일까지<br/>
              </div>
              <div className="agree">
                <StrongStyleP>위 개인정보의 수집 및 이용에 동의하십니까?</StrongStyleP>
                <input type={"radio"} id={"privacy_confirm_y"} name={"privacy_confirm"} value={"Y"} onChange={props.onChangeInputPrivacyConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"privacy_confirm_y"} style={{ fontWeight: "bold" }}>동의</StyleLabel2>
                <input type={"radio"} id={"privacy_confirm_n"} name={"privacy_confirm"} value={"N"} onChange={props.onChangeInputPrivacyConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"privacy_confirm_n"} style={{ fontWeight: "bold" }}>미동의</StyleLabel2>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><StyleLabel>2. 고유식별정보의 수집 · 이용에 대한 동의 (필수)</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <div>
                □ 수집하는 개인정보 항목 : 주민등록번호<br/>
                □ 수집 및 이용 목적 : 여행자보험 가입<br/>
                □ 보유 · 이용 기간 : 여행자보험 가입 기간 종료시까지 / 단, 기타 개별법령에서 보유기간을 정하고 있는 경우 법령에서 정한 기간 동안 보관함
              </div>
              <StrongStyleP>※ 주민등록번호는 추후 참가 확정된 인원을 대상으로 별도 안내 후 수집하겠습니다.</StrongStyleP>
              <div className="agree">
                <StrongStyleP>위 고유식별정보의 수집 및 이용에 동의하십니까?</StrongStyleP>
                <input type={"radio"} id={"social_number_y"} name={"social_number"} value={"Y"} onChange={props.onChangeInputSocialNumberConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"social_number_y"} style={{ fontWeight: "bold" }}>동의</StyleLabel2>
                <input type={"radio"} id={"social_number_n"} name={"social_number"} value={"N"} onChange={props.onChangeInputSocialNumberConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"social_number_n"} style={{ fontWeight: "bold" }}>미동의</StyleLabel2>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><StyleLabel>3. 개인정보 처리 위탁에 대한 동의 (필수)</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <div>
                □ 수탁업체 : 추후 이벤트 대행사 선정 확정시 참가자 개별 안내<br/>
                □ 위탁업무 내용 : 캠프 진행 및 통솔, 여행자 보험 가입, 보험금 처리<br/>
                □ 보유 · 이용 기간 : 당해년도 캠프 행사 종료에 따른 이용 목적 달성 후 파기
              </div>
              <div className="agree">
                <StrongStyleP>위 개인정보 처리 위탁에 동의하십니까?</StrongStyleP>
                <input type={"radio"} id={"private_handle_y"} name={"private_handle"} value={"Y"} onChange={props.onChangeInputPrivateHandleConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"private_handle_y"} style={{ fontWeight: "bold" }}>동의</StyleLabel2>
                <input type={"radio"} id={"private_handle_n"} name={"private_handle"} value={"N"} onChange={props.onChangeInputPrivateHandleConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"private_handle_n"} style={{ fontWeight: "bold" }}>미동의</StyleLabel2>
              </div>
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst style={{height: "inherit", lineHeight: "inherit", borderLeft: 0 }}><StyleLabel>4. 촬영 및 초상권 활용 동의 안내 (필수)</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <div>
                촬영 주체: KIA 타이거즈 구단 및 대단한미디어(구단의 영상 뉴미디어 제작 업체)
                <br/>
                촬영 및 초상권 활용 내용: 본 캠프 참여 모습, 영리와 홍보목적 활용
                <br/>
                콘텐츠 게재 채널: KIA 타이거즈 공식 SNS 채널(인스타그램, 유튜브 등)
                <br/>
                초상권 활용 기간: 본 동의 체결일로부터 유효하며, 별도로 기한을 제한하지 않음
                <br/>
                <StrongStyleSpan>*[촬영 및 초상권 보유·이용 동의 안내] 본인은/는 위의 내용의 사진 또는 영상을 촬영하고, 본인의 초상권을 제공하는 것에 동의합니다.</StrongStyleSpan>
              </div>
              <div className="agree">
                <input type={"radio"} id={"record_confirm_y"} name={"record_confirm"} value={"Y"} onChange={props.onChangeInputRecordConfirm}  style={{width: 'inherit', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"record_confirm_y"} style={{ fontWeight: "bold" }}>동의</StyleLabel2>
                <input type={"radio"} id={"record_confirm_n"} name={"record_confirm"} value={"N"} onChange={props.onChangeInputRecordConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                <StyleLabel2 htmlFor={"record_confirm_n"} style={{ fontWeight: "bold" }}>미동의</StyleLabel2>
              </div>
            </StyleLiSecond>
          </StyleUl>
        </fieldset>

      </form>
      <div className="inQBtn clear">

        <button onClick={props.onClickBtnRequest} type="button" style={{
          left: "50%",
          position: "relative",
          marginLeft: "-25%"
        }}><em className="myQ"></em>응모하기</button>

      </div>

    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
