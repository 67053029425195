import React, { Component } from "react";

import SubHeadComponent from "./SubHead.component";
import {
  ContFindCellComponent,
  ContFindEmailComponent
} from "./Cont.component";

import * as UserService from "../../../services/User.service";
import * as RegExHelper from "../../../helpers/RegEx.helper";

class FindIdContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIdx: 0,
      simpleId: "",
      certSeq: "",
      authNum: "",
      name: "",
      cellObj: {
        num1: "010",
        num2: "",
        num3: "",
        placeholder: "인증번호 6자 입력",
        cellphoneNum: ""
      },
      emailObj: {
        emailId: "",
        emailDomain: "",
        placeholder: "인증번호 6자 입력",
        email: ""
      }
    };
  }

  onClickBtnTab = tabIdx => {
    if (this.state.tabIdx !== tabIdx) {
      // 탭 바뀌면 입력된 데이터 초기화
      let cellObj = this.state.cellObj;
      cellObj.num1 = "010";
      cellObj.num2 = "";
      cellObj.num3 = "";
      cellObj.cellphoneNum = "";

      let emailObj = this.state.emailObj;
      emailObj.emailId = "";
      emailObj.emailDomain = "";
      emailObj.email = "";

      this.setState({
        tabIdx,
        certSeq: "",
        authNum: "",
        name: "",
        cellObj,
        emailObj
      });
    }
  };

  onFocusInput = (evt, type, key) => {
    console.log("onFocusInput", type, key);
    if (type === "cell") {
      let cellObj = this.state.cellObj;
      if (key === "validNum" && !cellObj.hasFocused) {
        cellObj["hasFocused"] = true;
        cellObj[key] = "";
        this.setState({ cellObj });
      }
    } else if (type === "email") {
      let emailObj = this.state.emailObj;
      if (key === "validNum" && !emailObj.hasFocused) {
        emailObj["hasFocused"] = true;
        emailObj[key] = "";
        this.setState({ emailObj });
      }
    }
  };

  onChangeInput = (evt, type, key) => {
    if (type === "cell") {
      if (!isNaN(evt.target.value)) {
        let cellObj = this.state.cellObj;
        cellObj[key] = evt.target.value;

        cellObj[
          "cellphoneNum"
        ] = `${cellObj["num1"]}-${cellObj["num2"]}-${cellObj["num3"]}`;

        this.setState({ cellObj });
      }
    } else if (type === "email") {
      let emailObj = this.state.emailObj;
      emailObj[key] = evt.target.value;

      emailObj["email"] = `${emailObj["emailId"]}@${emailObj["emailDomain"]}`;

      this.setState({ emailObj });
    }
  };

  onChangeSelectCellphoneNum1 = evt => {
    let cellObj = this.state.cellObj;
    cellObj["num1"] = evt.target.value;
    this.setState({ cellObj });
  };

  onChangeEmailDomainSelectList = evt => {
    // console.log(onChangeEmailDomainSelectList);
    let emailObj = this.state.emailObj;
    emailObj["emailDomain"] = evt.target.value;
    emailObj["email"] = `${emailObj["emailId"]}@${emailObj["emailDomain"]}`;
    this.setState({ emailObj });
  };

  onChangeAuthNum = evt => {
    if (isNaN(evt.target.value)) {
      return false;
    }
    this.setState({ authNum: evt.target.value });
  };

  onChangeName = evt => {
    this.setState({ name: evt.target.value });
  };

  onClickBtnRequestAuthNum = async (evt, type) => {
    try {
      if (type === "cell") {
        let cellObj = this.state.cellObj;
        const name = this.state.name;
        console.log(name)

        if (cellObj["cellphoneNum"].length < 12) {
          throw new Error("휴대폰 번호를 정확히 입력해주세요.");
        }

        if(name.trim() === ""){
          throw new Error("이름을 입력해주세요.");
        }
        const certSeq = await UserService.requestAuthNumByCellphone(
          "FIND_ID",
          cellObj["cellphoneNum"],
          null,
          name
        );
        this.setState({ certSeq });
        alert("인증 번호를 발송했습니다.");
      } else if (type === "email") {
        let emailObj = this.state.emailObj;
        if (!RegExHelper.checkEmail(emailObj["email"])) {
          throw new Error("이메일 주소를 정확히 입력해주세요.");
        }
        const certSeq = await UserService.requestAuthNumByEmail(
          "FIND_ID",
          emailObj["email"]
        );
        this.setState({ certSeq });
        alert("인증 번호를 발송했습니다.");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  onClickBtnRequestAuth = async (evt, type) => {
    try {
      console.log(this.state);
      const certSeq = this.state.certSeq;
      const authNum = this.state.authNum;
      if (certSeq.trim() === "") {
        throw new Error("인증요청을 먼저 해주세요.");
      } else if (authNum.trim() === "") {
        throw new Error("인증번호를 입력해주세요.");
      }

      // 010-9740-2642
      if (type === "cell") {
        const cellphoneNum = this.state.cellObj.cellphoneNum;
        await UserService.requestCheckAuthNumByCellphone(
          "FIND_ID",
          certSeq,
          authNum,
          cellphoneNum
        );
        const result = await UserService.findId(100, cellphoneNum);
        if (result.listCnt === 0) {
          throw new Error("휴대폰 번호로 등록된 아이디가 없습니다.");
        } else {
          let text = `휴대폰 번호로 등록된 아이디가 ${result.listCnt}개 있습니다.\n`;
          let list = result["list"];
          for (let i = 0; i < list.length; i++) {
            if (i > 0) {
              text += ", ";
            }
            text += `"${list[i].simpleId}"`;
            if(list[i].sleep_yn === "Y"){
              text += "(휴면)";
            } else {
              text += "(활성)";
            }
          }
          alert(text);
          this.props.history.push("/login");
        }
      }
      // yjjang2020@dev.brusoft.co.kr
      else if (type === "email") {
        const email = this.state.emailObj.email;
        await UserService.requestCheckAuthNumByEmail(
          "FIND_ID",
          certSeq,
          authNum,
          email
        );
        const result = await UserService.findId(200, email);
        if (result.listCnt === 0) {
          throw new Error("이메일로 등록된 아이디가 없습니다.");
        } else {
          let text = `이메일로 등록된 아이디가 ${result.listCnt}개 있습니다.\n`;
          let list = result["list"];
          for (let i = 0; i < list.length; i++) {
            if (i > 0) {
              text += ", ";
            }
            text += `"${list[i].simpleId}"`;
          }
          alert(text);
          this.props.history.push("/login");
        }
        console.log(result);
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onEnterKey = evt => {
    evt.key === "Enter" &&
      evt.target.alt === "휴대폰 인증" &&
      this.onClickBtnRequestAuthNum(evt, "cell");
    evt.key === "Enter" &&
      evt.target.alt === "이메일 인증" &&
      this.onClickBtnRequestAuthNum(evt, "email");
    evt.key === "Enter" &&
      evt.target.alt === "휴대폰 인증 완료" &&
      this.onClickBtnRequestAuth(evt, "cell");
    evt.key === "Enter" &&
      evt.target.alt === "이메일 인증 완료" &&
      this.onClickBtnRequestAuth(evt, "email");
  };

  render = () => (
    <div className="container">
      <SubHeadComponent />
      {this.state.tabIdx === 0 ? (
        <ContFindCellComponent
          onEnterKey={this.onEnterKey}
          onClickBtnTab={this.onClickBtnTab}
          cellObj={this.state.cellObj}
          name={this.state.name}
          onClickBtnRequestAuthNum={this.onClickBtnRequestAuthNum}
          onClickBtnRequestAuth={this.onClickBtnRequestAuth}
          onChangeAuthNum={this.onChangeAuthNum}
          onChangeName={this.onChangeName}
          onFocusInput={this.onFocusInput}
          onChangeInput={this.onChangeInput}
        />
      ) : (
        <ContFindEmailComponent
          onEnterKey={this.onEnterKey}
          onClickBtnTab={this.onClickBtnTab}
          emailObj={this.state.emailObj}
          onClickBtnRequestAuthNum={this.onClickBtnRequestAuthNum}
          onClickBtnRequestAuth={this.onClickBtnRequestAuth}
          onChangeSelectCellphoneNum1={this.onChangeSelectCellphoneNum1}
          onChangeAuthNum={this.onChangeAuthNum}
          onFocusInput={this.onFocusInput}
          onChangeInput={this.onChangeInput}
          onChangeEmailDomainSelectList={this.onChangeEmailDomainSelectList}
        />
      )}
    </div>
  );
}

export default FindIdContainer;
