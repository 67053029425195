import React, { Component } from "react";

import Slider from "react-slick";

import * as ArticleService from "../../../../services/Article.service";
import * as ImageHelper from "../../../../helpers/Image.helper";
import ImgComponent from "../../../common/Img.component";

const sliderPcOptions = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true
  // asNavFor: '.pcPopSlideNav'
};
const sliderNavOptions = {
  slidesToShow: 5,
  slidesToScroll: 1,
  // asNavFor: '.pcPopSlideFor',
  //dots: false,
  centerMode: true,
  focusOnSelect: true
};
const sliderMobileOptions = {
  autoplay: false,
  speed: 500,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false
  // prevArrow: $('.mbPopPrev'),
  // nextArrow: $('.mbPopNext')
};
// $(".popClose").click(function () {
//     $(".popup").removeClass("on");
// });

class GalleryPopupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pcSlider: null,
      navSlider: null,
      mobileSlider: null,

      info: null
    };
  }

  onClickBtnClosePopup = _ => {
    // console.log('onClickBtnClosePopup');
    this.props.onClickBtnClosePopup();
  };

  componentDidMount = () => {
    try {
      const info = this.props.galleryList;
      this.props.galleryList.length < 5
        ? (sliderNavOptions.slidesToShow = this.props.galleryList.length)
        : (sliderNavOptions.slidesToShow = 5);
      // await ArticleService.getPlayerPicture(this.props.selectedGalleryPlayerName);
      this.setState({
        info,
        pcSlider: this.pcSlider,
        navSlider: this.navSlider,
        mobileSlider: this.mobileSlider
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  setSliderRef = (sliderName, ref) => {
    // console.log('setSliderRef', sliderName, ref);
    if (!this[sliderName]) {
      this[sliderName] = ref;
      this.setState({ [sliderName]: ref });
    }
  };

  onClickBtnMobileSliderPerv = _ => {
    this.mobileSlider.slickPrev();
  };

  onClickBtnMobileSliderNext = _ => {
    this.mobileSlider.slickNext();
  };

  render = _ =>
    this.state.info ? (
      <div className="popup on">
        <div className="pcPopIn pc">
          <p className="popClose" onClick={this.onClickBtnClosePopup}>
            <img src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
          </p>
          <div className="pcPopBig">
            <Slider
              className="pcPopSlideFor"
              ref={slider => this.setSliderRef("pcSlider", slider)}
              {...sliderPcOptions}
              asNavFor={this.state.navSlider}
            >
              {this.state.info.map((item, idx) => (
                <li key={idx}>
                  <ImgComponent
                    imgUrl={item.path}
                    defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                    imgName={item.description}
                  />
                </li>
              ))}
            </Slider>
          </div>
          {/* <!-- //big --> */}
          <div
            className="pcPopSmall"
            style={{
              width:
                "calc(25%*" +
                (this.state.info.length <= 2
                  ? this.state.info.length
                  : this.state.info.length > 3
                  ? this.state.info.length - 0.6
                  : "2.7") +
                ")"
            }}
          >
            <Slider
              className="pcPopSlideNav"
              ref={slider => this.setSliderRef("navSlider", slider)}
              {...sliderNavOptions}
              asNavFor={this.state.pcSlider}
            >
              {this.state.info.map((item, idx) => (
                <li key={idx}>
                  <ImgComponent
                    imgUrl={item.path}
                    defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                    imgName={item.description}
                    style={{ maxHeight: "566px" }}
                  />
                </li>
              ))}
            </Slider>
          </div>
          {/* <!-- //small --> */}
        </div>
        {/* <!-- //poPopIn --> */}
        <div className="popInMb tb">
          <p className="popClose" onClick={this.onClickBtnClosePopup}>
            <img src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
          </p>
          <Slider
            className="mbPopSlide"
            ref={slider => this.setSliderRef("mobileSlider", slider)}
            {...sliderMobileOptions}
          >
            {this.props.galleryList.map((item, idx) => (
              <li key={idx}>
                <ImgComponent
                  imgUrl={item.path}
                  defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                  imgName={item.description}
                />
              </li>
            ))}
          </Slider>
          <p onClick={this.onClickBtnMobileSliderPerv} className="mbPopPrev">
            <img src="/img/sub/pop_prev.png" alt="슬라이드 다음버튼" />
          </p>
          <p onClick={this.onClickBtnMobileSliderNext} className="mbPopNext">
            <img src="/img/sub/pop_next.png" alt="슬라이드 다음버튼" />
          </p>
        </div>
        {/* <!-- //popInMb --> */}
      </div>
    ) : null;
}

export default GalleryPopupContainer;
