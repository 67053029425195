import React from "react";

import Slider from "react-slick";

import ImgComponent from "../../../common/Img.component";
import * as ImageHelper from "../../../../helpers/Image.helper";

const sliderOptions = {
  autoplay: true,
  speed: 500,
  arrows: true,
  infinite: true,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  fade: false
};
const GalleryListSliderComponent = props =>
  props.galleryList.length > sliderOptions.slidesToShow ? (
    <Slider
      ref={slider => props.setSliderRef("gallery", slider)}
      {...sliderOptions}
    >
      {props.galleryList.map((item, idx) => (
        <li
          onClick={evt => props.onClickBtnPicture(evt, item.artcSeq)}
          key={idx}
          className="gallery-list-item"
        >
          <p>
            <ImgComponent
              imgUrl={item.path}
              defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
              imgName={item.description}
            />
          </p>
        </li>
      ))}
    </Slider>
  ) : (
    props.galleryList.map((item, idx) => (
      <li
        onClick={evt => props.onClickBtnPicture(evt, item.artcSeq)}
        key={idx}
        className="gallery-list-item"
      >
        <p>
          <ImgComponent
            imgUrl={item.path}
            defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
            imgName={item.description}
          />
        </p>
      </li>
    ))
  );
export default GalleryListSliderComponent;
