import React from "react";
import DaumPostCode from "react-daum-postcode";

const postCodePopupStyle = {
  width: "300px",
  height: "400px",
  left:
    ((window.innerWidth || document.documentElement.clientWidth) - 300) / 2 -
    5 +
    "px",
  top:
    ((window.innerHeight || document.documentElement.clientHeight) - 400) / 2 -
    5 +
    "px",
  border: "5px solid",
  position: "fixed",
  overflow: "hidden",
  zIndex: 1,
  display: "block",
};

const ContStep3Component = (props) => (
  <div className="mb_cont join_step2">
    <div className="mb_contIn">
      {props.isChild ? (
        <p className="join_prcs pc_tb">
          <img
            src="/img/sub/join_prcs03_child.png"
            alt="약관동의-정보입력-보호자인증-가입완료 중 정보입력 단계"
          />
        </p>
      ) : (
        <p className="join_prcs pc_tb">
          <img
            src="/img/sub/join_prcs03.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 정보입력 단계"
          />
        </p>
      )}
      {props.isChild ? (
        <p className="join_prcs mb">
          <img
            src="/img/sub/mb_join_prcs03_child.png"
            alt="약관동의-정보입력-보호자인증-가입완료 중 정보입력 단계"
          />
        </p>
      ) : (
        <p className="join_prcs mb">
          <img
            src="/img/sub/mb_join_prcs03.png"
            alt="약관동의-정보입력-본인인증-가입완료 중 정보입력 단계"
          />
        </p>
      )}
      <form action="/" method="post">
        <fieldset>
          <legend>기본정보</legend>
          <h4>
            <strong className="pc_tb">기본정보</strong>
            <span className="pc_tb">
              <em className="pc_tb">*</em>는 필수입력 사항입니다.
            </span>
            <span className="mb">* 필수 입력항목</span>
          </h4>
          <div className="stp2_box">
            <div>
              <div className="id_box">
                <label htmlFor="userID">
                  <strong>*</strong>아이디
                </label>
                <input
                  type="text"
                  id="userID"
                  //   placeholder={props.step3Obj.idPlaceholder}
                  value={props.step3Obj.id}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "id")
                  }
                />
                <span
                  className="chk_jnStep2"
                  onClick={(evt) => props.onClickBtnCheckExistsId(evt, "id")}
                >
                  중복확인
                </span>
              </div>
              <em>* 아이디는 영소문자, 숫자 포함 6자 이상을 입력해주세요.</em>
            </div>
            <div>
              <div className="id_box">
                <label htmlFor="userPw">
                  <strong>*</strong>비밀번호
                </label>
                <input
                  type="password"
                  id="userPw"
                  //   placeholder={props.step3Obj.pwPlaceholder}
                  value={props.step3Obj.pw}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "pw")
                  }
                />
              </div>
              <br />
              <em>
                * 비밀 번호는 영소문자, 숫자, 특수문자(`~!@#$%^&*|\'";:/?)
                <br className="mb" />
                포함 8자 이상을 입력해주세요.
              </em>
            </div>
            <div>
              <label htmlFor="userPw2">
                <strong>*</strong>비밀번호 확인
              </label>
              <input
                type="password"
                id="userPw2"
                // placeholder={props.step3Obj.rePwPlaceholder}
                value={props.step3Obj.rePw}
                onChange={(evt) =>
                  props.onChangeInputInStep3(evt, "step3Obj", "rePw")
                }
              />
            </div>
            <div className="cell">
              <label htmlFor="cell">
                <strong>*</strong>휴대폰 번호{console.log(props)}
              </label>
              <input
                type="text"
                id="cell"
                maxLength="3"
                readOnly={props.checkAuthType === "MOBILEM" ? true : false}
                value={props.step3Obj.cellNum1}
                onChange={(evt) =>
                  props.onChangeInputInStep3(evt, "step3Obj", "cellNum1")
                }
              />
              {/* <select id="cell"
                                value={props.step3Obj.cellNum1}
                                onChange={evt => props.onChangeSelectListInStep3(evt, 'step3Obj', 'cellNum1')} >
                                <option value="010">010</option>
                                <option value="011">011</option>
                                <option value="011">016</option>
                                <option value="011">017</option>
                                <option value="011">018</option>
                                <option value="018">019</option>
                            </select> */}
              <span>-</span>
              <input
                type="text"
                title="휴대전화 앞자리 번호"
                maxLength="4"
                readOnly={props.checkAuthType === "MOBILEM" ? true : false}
                value={props.step3Obj.cellNum2}
                onChange={(evt) =>
                  props.onChangeInputInStep3(evt, "step3Obj", "cellNum2")
                }
              />
              <span>-</span>
              <input
                type="text"
                title="휴대전화 뒷자리 번호"
                maxLength="4"
                readOnly={props.checkAuthType === "MOBILEM" ? true : false}
                value={props.step3Obj.cellNum3}
                onChange={(evt) =>
                  props.onChangeInputInStep3(evt, "step3Obj", "cellNum3")
                }
              />
              {props.checkAuthType !== "MOBILEM" ? (
                <span
                  className="chk_jnStep2"
                  onClick={props.onClickBtnRequestAuthNumInStep3}
                >
                  인증요청
                </span>
              ) : null}
            </div>
            {props.isChild ? (
              <div style={{ marginTop: "10px" }}>
                <em>
                  * 본인 휴대폰이 없을시 보호자 휴대폰 번호를 입력해주세요.
                </em>
              </div>
            ) : null}
            {props.checkAuthType !== "MOBILEM" ? (
              <div>
                <label htmlFor="useNum">
                  <strong>*</strong> 인증번호
                </label>
                <input
                  type="text"
                  id="useNum"
                  maxLength="6"
                  value={props.step3Obj.certNo}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "certNo")
                  }
                />
                <span
                  className="chk_jnStep2"
                  onClick={props.onClickBtnRequestAuthInStep3}
                >
                  인증확인
                </span>
                <em>* 인증번호 입력 후 ‘인증확인’ 버튼을 선택해주세요.</em>
              </div>
            ) : null}
            <div className="tb h4Line">
              <h4>선택 입력항목</h4>
            </div>
            <div>
              <label htmlFor="userAdrs"><strong>*</strong>주소</label>
              <input
                type="text"
                id="userAdrs"
                disabled
                value={props.step3Obj.address1}
              />
              <span
                className="chk_jnStep2"
                onClick={props.onClickBtnShowAddressSearchPopup}
              >
                주소찾기
              </span>
            </div>
            <div className="adress2">
              <label htmlFor="">
                &nbsp;<span className="blind">추가주소</span>
              </label>
              <input
                type="text"
                title="추가주소"
                value={props.step3Obj.address2}
                onChange={(evt) =>
                  props.onChangeInputInStep3(evt, "step3Obj", "address2")
                }
              />
            </div>
            <div className="email">
              <p className="clear">
                <label htmlFor="userEm"><strong style={{color: "#e0242b"}}>*</strong>이메일주소</label>
                <input
                  type="text"
                  title="이메일 주소"
                  id="userEm"
                  value={props.step3Obj.emailId}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "emailId")
                  }
                />
                <span>@</span>
                <input
                  type="text"
                  title="이메일 도메인주소"
                  className="mbW2"
                  value={props.step3Obj.emailDomain}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "emailDomain")
                  }
                />
                <label htmlFor="userEm2" className="blind">
                  도메인주소
                </label>
                <select
                  id="userEm2"
                  className="em2"
                  select={props.step3Obj.emailDomainSelectValue}
                  value={props.step3Obj.emailDomainSelectValue}
                  onChange={(evt) =>
                    props.onChangeSelectListInStep3(
                      evt,
                      "step3Obj",
                      "emailDomain"
                    )
                  }
                >
                  <option value="">직접입력</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="naver.com">naver.com</option>
                  <option value="hanmail.com">hanmail.net</option>
                </select>
                <input
                  type="checkbox"
                  id="agr"
                  className="pc_tb"
                  onChange={(evt) =>
                    props.onChangeCheckboxInStep3(
                      evt,
                      "step3Obj",
                      "isReceiveEmail"
                    )
                  }
                />
                <label htmlFor="agr" className="pc_tb">
                  이메일 수신 동의
                </label>
              </p>
              <em>
                * 이메일은 추후 아이디/비밀번호를 찾기 시 사용되니,실제로
                사용하시는 ‘이메일’을 기입해주세요.
              </em>
              {/* <p className="snsrecv mb">
                                <span>SMS 수신 동의</span>
                                <input type="radio" id="yes" name="receive2" />
                                <label htmlFor="yes">예</label>
                                <input type="radio" id="no" name="receive2" checked="checked" />
                                <label htmlFor="no">아니오</label>
                            </p> */}
            </div>
            {/*<div>*/}
            {/*  <label htmlFor="recomID">추천인 아이디</label>*/}
            {/*  <input*/}
            {/*    type="text"*/}
            {/*    id="recomID"*/}
            {/*    className="w2"*/}
            {/*    value={props.step3Obj.recommenderId}*/}
            {/*    onChange={(evt) =>*/}
            {/*      props.onChangeInputInStep3(evt, "step3Obj", "recommenderId")*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <span*/}
            {/*    className="chk_jnStep2 pc_tb"*/}
            {/*    onClick={props.onClickBtnCheckRecommenderIdInStep3}*/}
            {/*  >*/}
            {/*    아이디 확인*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        </fieldset>
        {props.isChild ? (
          <fieldset>
            <legend>어린이 입력항목</legend>
            <h4>
              <strong>어린이 입력항목</strong>
              <span className="pc_tb">
                <em className="pc_tb">*</em>는 필수입력 사항입니다.
                {console.log(props)}
              </span>
            </h4>
            <div className="stp2_box">
              <div>
                <label htmlFor="chldName">
                  <strong>*</strong>이름
                </label>
                <input
                  type="text"
                  id="chldName"
                  placeholder="실명입력"
                  value={props.step3Obj.childUserName}
                  onChange={(evt) =>
                    props.onChangeInputInStep3(evt, "step3Obj", "childUserName")
                  }
                />
              </div>
              <div className="date">
                <label htmlFor="chldYear">
                  <strong>*</strong>생년월일
                </label>
                <select
                  id="chldYear"
                  value={props.step3Obj.childBirthDateYear}
                  onChange={(evt) =>
                    props.onChangeSelectListInStep3(
                      evt,
                      "step3Obj",
                      "childBirthDateYear"
                    )
                  }
                >
                  <option value="">년도</option>
                  {props.yearSelectList.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <label htmlFor="chldMon" className="blind">
                  월
                </label>
                <select
                  id="chldMon"
                  value={props.step3Obj.childBirthDateMonth}
                  onChange={(evt) =>
                    props.onChangeSelectListInStep3(
                      evt,
                      "step3Obj",
                      "childBirthDateMonth"
                    )
                  }
                >
                  <option value="">월</option>
                  {props.monthSelectList.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <label htmlFor="chldDate" className="blind">
                  일
                </label>
                <select
                  id="chldDate"
                  value={props.step3Obj.childBirthDateDay}
                  onChange={(evt) =>
                    props.onChangeSelectListInStep3(
                      evt,
                      "step3Obj",
                      "childBirthDateDay"
                    )
                  }
                >
                  <option value="">일</option>
                  {props.daySelectList.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sx">
                <p>
                  <strong>*</strong>성별
                </p>
                <p>
                  <input
                    type="radio"
                    id="man"
                    name="receive"
                    value="1"
                    onClick={props.onClickChildSexRadioButton}
                    defaultChecked={props.step3Obj.childSex == 1}
                  />
                  <label htmlFor="yes">남</label>
                  <input
                    type="radio"
                    id="no"
                    name="receive"
                    value="2"
                    onClick={props.onClickChildSexRadioButton}
                    defaultChecked={props.step3Obj.childSex == 2}
                  />
                  <label htmlFor="no">여</label>
                </p>
              </div>
            </div>
          </fieldset>
        ) : null}
      </form>

      <div className="loginBtn_wrap">
        <div className="login_btn01">
          <ul className="login_btn01In">
            <li>
              <a onClick={props.onClickBtnOkStep3}>확인</a>
            </li>
            <li>
              <a onClick={props.onClickBtnCancelStep3}>취소</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      style={{ display: props.isOpenedAddressSearchPopup ? "block" : "none" }}
    >
      <div onClick={props.onClickBtnModalBg} className="modal-bg"></div>
      <div style={postCodePopupStyle}>
        <DaumPostCode
          onComplete={props.onCompleteAddress}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  </div>
);

export default ContStep3Component;
