import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import ListContainer from './list/List.container';
import InfoContainer from './info/Info.container';

import * as GameService from '../../../services/Game.service';

class CoachingStaffContainer extends Component {
    slider = null;
    constructor(props) {
        super(props);

        this.state = {
            isInit: false,
            subMenu: 'coaching-staff',
            subMenuName: '코칭스태프',
            entryTabNum: 1,
            coachingStaffList: [],
        }
    }
    componentDidMount = async () => {
        try {
            const result = await GameService.getCoachingStaffList();
            console.log("componentDidMount", result.list);

            this.setState({
                isInit: true,
                coachingStaffList: result.list
            });
            console.log("coachingStaffList", this.state.coachingStaffList)

        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    onClickBtnShowDetail = async (evt, pCode) => {
        console.log('onClickBtnShowDetail', pCode);
        try {
            const info = await GameService.getCoachingStaffInfo(pCode);
            this.setState({ pCode, info });
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    setSliderRef = (slider) => {
        this.slider = slider;
    }

    onClickBtnSlidePrev = () => {
        this.slider.slickPrev();
    }
    onClickBtnSlideNext = () => {
        this.slider.slickNext();
    }

    setEntryTabNum = (tabNum) => {
        this.setState({entryTabNum:tabNum});
    }

    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}`}
                render={(props) => <ListContainer {...props}
                    setEntryTabNum={this.setEntryTabNum}
                    entryTabNum={this.state.entryTabNum}
                    subMenu={this.state.subMenu}
                    subMenuName={this.state.subMenuName}
                    coachingStaffList={this.state.coachingStaffList} />} />
            <Route exact path={`${this.props.match.path}/:playerCode`}
                render={(props) => <InfoContainer {...props}
                    setEntryTabNum={this.setEntryTabNum}
                    entryTabNum={this.state.entryTabNum}
                    subMenu={this.state.subMenu}
                    subMenuName={this.state.subMenuName}
                    coachingStaffList={this.state.coachingStaffList} />} />
        </Switch>
    )
}

export default CoachingStaffContainer;
