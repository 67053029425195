import React from "react";
import SubMenuComponent from "../common/SubMenu.component";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont gameH">
    <div className="mb_contIn">
      <SubMenuComponent
        subMenu={props.subMenu}
        subMenuName={props.subMenuName}
        thirdMenu={props.thirdMenu}
        thirdMenuName={props.thirdMenuName}
      />
      <ul className="tab02 w2 clear">
        <li className={props.tabNum === 1 ? "on" : ""}>
          <a onClick={(evt) => props.onClickBtnTab(evt, 1)}>전체투수</a>
        </li>
        <li className={props.tabNum === 2 ? "on" : ""}>
          <a onClick={(evt) => props.onClickBtnTab(evt, 2)}>KIA투수</a>
        </li>
      </ul>
      <div className="rankingPitcher">
        <section>
          <h6>투수 성적</h6>
          <div className="srch">
            <p
              className="btn_srch"
              style={{ cursor: "pointer" }}
              onClick={props.onClickBtnSearch}
            >
              검색
            </p>
            <p>
              <label for="cell" className="blind">
                년도
              </label>
              <select
                id="cell"
                defaultValue={props.selectedYear}
                onChange={props.onChangeSelectList}
              >
                {props.yearSelectList.map((item, idx) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </select>
            </p>
          </div>
          <div className="mbTable mb">
            <ul>
              <li>선수명</li>
              {props.rankingList.length > 0 ? (
                props.rankingList.map((item, idx) => <li>{item.playerName}</li>)
              ) : (
                <li>&nbsp;</li>
              )}
            </ul>
          </div>
          {/* <!--//mbTable --> */}
          {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
          <div
            className={
              (props.rankingList.length > 0 ? "" : "noPlayer") +
              " table_Typ01 mbScroll tableW20 th3Fix"
            }
          >
            <table>
              <caption>타자순위 현황표</caption>
              <thead>
                <tr>
                  {props.columnHeaderList.map((item, idx) =>
                    item.isSortable ? (
                      <th
                        key={idx}
                        onClick={(evt) => props.onClickBtnSort(evt, item.key)}
                        className={
                          (item.className ? item.className : "") +
                          (props.sortingKey === item.key.toUpperCase()
                            ? " on"
                            : "")
                        }
                        scope="col"
                      >
                        {item.name}
                        <span className="downArow"></span>
                      </th>
                    ) : (
                      <th key={idx} scope="col" className={item.className}>
                        {item.name}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {props.rankingList.map((item, idx) => (
                  <tr key={idx}>
                    <th>{idx + 1}</th>
                    <th>{item.playerName}</th>
                    <th>{item.teamName}</th>
                    <td>{item.gamenum}</td>
                    <td>{item.w}</td>
                    <td>{item.l}</td>
                    <td>{item.sv}</td>
                    <td>{item.hold}</td>
                    <td>{item.inndisplay}</td>
                    <td className="pc_tb">{item.bf}</td>
                    <td className="pc_tb">{item.hit}</td>
                    <td className="pc_tb">{item.hr}</td>
                    <td className="pc_tb">{item.bb}</td>
                    <td className="pc_tb">{item.hp}</td>
                    <td>{item.kk}</td>
                    <td className="pc_tb">{item.r}</td>
                    <td className="pc_tb">{item.er}</td>
                    <td>{item.wra}</td>
                    <td>{item.era}</td>
                  </tr>
                ))}
                {props.rankingList.length === 0 ? (
                  <tr>
                    <td colSpan="19">기록이 없습니다.</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          {/* <!--//table_Typ01 --> */}
        </section>
      </div>
    </div>
  </div>
);

export default ContComponent;
