import React from "react";
import * as PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";

const tabs = [
  {name: "상품설명"},
  {name: "상세정보"}
]

function ProductInfo({product}) {
  const [selectedTab, setSelectedTab] = React.useState(0)
  const onClickTab = React.useCallback((tab) => {
    setSelectedTab(tab)
  }, [selectedTab])

  return <>
    <ul className="tab">
      {tabs.map((t, i) => (
        <li key={t.name}
            className={selectedTab === i ? "active" : ""}
            onClick={() => onClickTab(i)}>
          {t.name}
        </li>
      ))}
    </ul>
    {selectedTab !== 1
      ? (
        <div className="description">
          <Markdown>{product.productDescription ?? "상품 정보가 없습니다."}</Markdown>
        </div>
      )
      : (
        <div className="detail-info">
          <img src={product.productDetailInfo} alt="상세정보"/>
        </div>
      )}
  </>;
}

ProductInfo.propTypes = {
  product: PropTypes.shape({
    productDescription: PropTypes.string,
    productDetailInfo: PropTypes.string
  }),
};

export default ProductInfo