import React from "react";
import SubMenuComponent from "../common/SubMenu.component";
import GoogleMapReact from "google-map-react";
import { RoutoMapComponent } from "../../../common/RoutoMap.component";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont tigers champ">
    <div className="mb_contIn">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      <div className="champInfo">
        {props.imgUrlObj ? (
          <div className="vs">
            <img
              src={props.imgUrlObj.pc}
              alt="광주-기아챔피언스필드 구장 사진"
              className="pc"
            />
            <img
              src={props.imgUrlObj.m}
              alt="광주-기아챔피언스필드 구장 사진"
              className="tb"
            />
            <dl>
              <dt>
                <span>광주 - 기아</span>&nbsp;
                <span className="txtRed">챔피언스 필드</span>
              </dt>
              <dd>
                국내 최신, 최고의 시설! 미래를 만드는 꿈의 구장! <br />
                광주-기아 챔피언스 필드입니다.
              </dd>
              <dd>
                <ul>
                  <li>
                    <p>구장명</p>
                    <p>광주-기아 챔피언스 필드</p>
                  </li>
                  <li>
                    <p>주소</p>
                    <p>광주 북구 서림로 10</p>
                  </li>
                  <li>
                    <p>좌석수</p>
                    <p>관람석 20,500석</p>
                  </li>
                  <li>
                    <p>총면적</p>
                    <p>57,646㎥</p>
                  </li>
                  <li>
                    <p>규모</p>
                    <p>지하 2층 지상 5층</p>
                  </li>
                  <li>
                    <p>펜스</p>
                    <p>좌ㆍ우 99m, 중 121m</p>
                  </li>
                  <li>
                    <p>특징</p>
                    <p>국내 최초 개방형 야구장</p>
                  </li>
                  <li>
                    <p>KIA타이거즈 사무실</p>
                    <p>광주 북구 서림로 10 광주-기아 챔피언스 필드 내2층</p>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        ) : null}
        {/* <!-- //vs --> */}
        <article className={"detail"}>
          <h5>광주-기아 챔피언스필드 오시는 길</h5>
          <ul className="clear">
            <li>
              <dl>
                <dt>광천동 유스퀘어 광주종합버스터미널 ⟶ 광주기아챔피언스필드 정문(시외버스 및 고속버스 이용시)</dt>
                <dd className="bus">
                  1) 매월26, 일곡38, 송암47, 상무64번 승차 ⟶ 제2광천교 정류장 하차 ⟶ 도보로 6분 이동(총 13분 소요)<br />
                  2) 매월16 승차 ⟶ 광주기아챔피언스필드 정문 정류장 하차 ⟶ 도보로 3분 이동(총 12분 소요)<br />
                  3) 용전84 승차 ⟶ 임동주공아파트 정류장 하차 ⟶ 도보로 5분 이동(총 12분 소요)<br />
                </dd>
                <dd className="taxi">유스퀘어 택시 승강장 승차 ⟶ 챔피언스필드 정문 하차(5분 소요)</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>광주송정역 ⟶ 광주기아챔피언스필드 정문(KTX 및 SRT 이용시)</dt>
                <dd className="bus">
                광주송정역 정류장 송정98 승차 ⟶ 무등야구장 정류장 하차(챔피언스필드 후문쪽) ⟶ 도보로 5분 이동(정문)
                </dd>
                {/* <dd className="taxi">10∼15분 소요</dd> */}
                <dd className="subwaybus">
                1) 광주송정역 4번 출구까지 도보 이동(3분 소요) ⟶ 광주송정역 1호선 녹동방면 지하철 승차 ⟶ 화정역 하차 ⟶ 4번 출구로 나와서 화정현대아파트 정류장까지 도보 이동(5분 소요) ⟶ 매월 26 승차 ⟶ 제2광천교 정류장 하차 ⟶ 도보 6분 이동(총 37분 소요)<br />
                2) 광주송정역 4번 출구까지 도보 이동(3분 소요) ⟶ 광주송정역 1호선 녹동방면 지하철 승차 ⟶ 쌍촌역 하차 ⟶ 4번 출구로 나와서 상무1동행정복지센터 정류장까지 도보 이동(4분 소요) ⟶ 송암47 승차 ⟶ 제2광천교 정류장 하차 ⟶ 도보 6분 이동(총 36분 소요)</dd>
                <dd className="subwaytaxi">
                  광주송정역 ⟶ 농성역 하차 ⟶ 택시 이용
                </dd>
                <dd className="taxi">
                광주송정역 택시 승강장 승차 ⟶ 챔피언스필드 정문 하차(30분 소요)
                </dd>
              </dl>

            </li>
            <li>
              <dl>
                <dt>광주공항 ⟶ 광주기아챔피언스필드 정문(비행기 이용시)</dt>
                <dd className="bus">
                공항역 정류장 일곡38 승차 ⟶ 제2광천교 정류장 하차 ⟶ 도보 6분 이동(총 50분 소요)
                </dd>
                <dd className="subway">
                공항역 승차 후 KTX 및 SRT 이용 시와 같음
                </dd>
                <dd className="taxi">
                광주공항 택시 승강장 승차 ⟶ 챔피언스필드 정문 하차(25분 소요)
                </dd>
              </dl>
            </li>
          </ul>
        </article>
        {/* <script>
                    function initMap() {
					var uluru = {lat: 35.168362, lng: 126.888968};
					var map = new google.maps.Map(document.getElementById('map'), {
                        zoom: 15,
            center: uluru
          });
					var marker = new google.maps.Marker({
                        position: uluru,
            map: map,
            title:"광주-기아 챔피언스 필드"
          });

					//var infowindow = new google.maps.InfoWindow({
                        //	content:"기아 챔피언스 필드"
                        //});
                        infowindow.open(map, marker); //infowindow열어라

        }
				window.onload=function(){
                        initMap();
        }
			</script> */}
        <article>
          <h5>광주 - 기아 챔피언스 필드 위치 안내</h5>
          <RoutoMapComponent />
          {/* <div id="map" className="map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyBWZxi08FYwXoUTNIP8YLDbj2MZPmJ5bPA",
              }}
              defaultCenter={{ lat: 35.168362, lng: 126.888968 }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                let marker = new maps.Marker({
                  position: { lat: 35.168362, lng: 126.888968 },
                  map,
                });
                return marker;
              }}
            ></GoogleMapReact>
          </div> */}
        </article>
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
