import React from "react";
import styled from 'styled-components';

import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const StyleUl = styled.ul`
  background: #f8f8fa;
`

const StyleLiFirst = styled.li`
  border-right: 0 !important;
`
const StyleLiSecond = styled.li`
  border-left: 1px solid #e4e5e6;
  background: #ffffff;
`

const StyleLabel = styled.label`
  font-size: min(3vw, 15px);
`
const ContComponent = (props) => (
  <div className="mb_cont membership">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">{props.eventInfo.event_name}</h6>
        {/*<h2 className="h2Tit">{props.eventInfo.event_name}</h2>*/}
      <img src={props.eventInfo.thumnail_pic} style={{margin: "10px 0", width: "100%"}} />
      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <ul>
            <li><label htmlFor="user_id">아이디</label></li>
            <li className="tit">

              <input type="text" id="user_id"
                     value={decodeAES256(props.userId)}
                     readOnly={true}
                     disabled={true}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="user_name">이름</label></li>
            <li className="tit">

              <input type="text" id="user_name"
                     value={decodeAES256(props.userName)}
                     readOnly={true}
                     disabled={true}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="mobile_no">연락처</label></li>
            <li className="tit">

              <input type="text" id="mobile_no"
                     placeholder={"연락처를 입력해주세요"}
                     onChange={props.onChangeInputMobileNo}
                     value={props.mobile_no}
              />
              <p><strong>{props.mobile_no.length}</strong>/<span>{11}</span></p>
            </li>
          </ul>
          <ul>
            <li><label htmlFor="child_name">어린이 이름</label></li>
            <li className="tit">

              <input type="text" id="child_name"
                     placeholder={"어린이 이름을 입력해주세요"}
                     onChange={props.onChangeInputChildName}
                     value={props.child_name}
              />
            </li>
          </ul>
          <ul>
            <li><label htmlFor="child_birthdate">어린이 생년월일</label></li>
            <li className="tit">

              <input type="text" id="child_birthdate"
                     placeholder={"어린이 생년월일을 입력해주세요"}
                     onChange={props.onChangeInputChildBirthDate}
                     value={props.child_birth_date}
              />
            </li>
          </ul>
          <ul>

            <StyleUl>
              <StyleLiFirst></StyleLiFirst>
              <StyleLiSecond className="tit">
                <StyleLabel htmlFor="target_confirm" dangerouslySetInnerHTML={ { __html: props.eventInfo.data_terms_content}}></StyleLabel>
                <div className="agree">
                  <input type={"checkbox"} id={"confirm_y"} name={"confirm"} value={"Y"}
                         onChange={props.onChangeInputConfirm} style={{width: 'inherit', marginRight: '5px'}}/>
                  <StyleLabel htmlFor={"confirm_y"}>확인했습니다.</StyleLabel>
                </div>
              </StyleLiSecond>
            </StyleUl>
          </ul>
        </fieldset>

      </form>
      <div className="inQBtn clear">

        <button onClick={props.onClickBtnRequest} type="button" style={{
          left: "50%",
          position: "relative",
          marginLeft: "-25%"
        }}><em className="myQ"></em>신청하기
        </button>

      </div>

    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
