import React from 'react';

const MonthlyRecordComponent = props => (
    props.info.position2 === '투' ?
        <MonthlyRecordComponentForPitcher {...props} />
        :
        <MonthlyRecordComponentForHitter {...props} />
)

const MonthlyRecordComponentForHitter = props => (
    <table>
        <caption>엔트리현황, 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">월</th>
                <th scope="col">타석</th>
                <th scope="col">타수</th>
                <th scope="col">득점</th>
                <th scope="col">안타</th>
                <th scope="col">2타</th>
                <th scope="col">3타</th>
                <th scope="col">홈런</th>
                <th scope="col">타점</th>
                <th scope="col">희타</th>
                <th scope="col">희비</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">병살</th>
                <th scope="col">타율</th>
            </tr>
        </thead>
        <tbody>
            {props.monthlyRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.run}</td>
                    <td>{item.hit}</td>
                    <td>{item.h2}</td>
                    <td>{item.h3}</td>
                    <td>{item.hr}</td>
                    <td>{item.rbi}</td>
                    <td>{item.sh}</td>
                    <td>{item.sf}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.gd}</td>
                    <td>{item.hra}</td>
                </tr>
            )}
            {props.monthlyRecordList.length === 0 ?
                <tr>
                    <td colSpan="16">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.monthlyTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.monthlyTotalRecord.pa}</td>
                    <td>{props.monthlyTotalRecord.ab}</td>
                    <td>{props.monthlyTotalRecord.run}</td>
                    <td>{props.monthlyTotalRecord.hit}</td>
                    <td>{props.monthlyTotalRecord.h2}</td>
                    <td>{props.monthlyTotalRecord.h3}</td>
                    <td>{props.monthlyTotalRecord.hr}</td>
                    <td>{props.monthlyTotalRecord.rbi}</td>
                    <td>{props.monthlyTotalRecord.sh}</td>
                    <td>{props.monthlyTotalRecord.sf}</td>
                    <td>{props.monthlyTotalRecord.bb}</td>
                    <td>{props.monthlyTotalRecord.hp}</td>
                    <td>{props.monthlyTotalRecord.kk}</td>
                    <td>{props.monthlyTotalRecord.gd}</td>
                    <td>{(props.monthlyTotalRecord.hit / props.monthlyTotalRecord.ab).toFixed(3)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)

const MonthlyRecordComponentForPitcher = props => (
    <table>
        <caption>엔트리현황, 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">월</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">세이브</th>
                <th scope="col">홀드</th>
                <th scope="col">이닝</th>
                <th scope="col">투구</th>
                <th scope="col">타자</th>
                <th scope="col">타수</th>
                <th scope="col">안타</th>
                <th scope="col">홈런</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">폭투</th>
                <th scope="col">보크</th>
                <th scope="col">실점</th>
                <th scope="col">자책</th>
                <th scope="col">방어율</th>
            </tr>
        </thead>
        <tbody>
            {props.monthlyRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.w}</td>
                    <td>{item.l}</td>
                    <td>{item.sv}</td>
                    <td>{item.hold}</td>
                    <td>{item.innDisplay}</td>
                    <td>{item.tugucount}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.hit}</td>
                    <td>{item.hr}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.wp}</td>
                    <td>{item.bk}</td>
                    <td>{item.r}</td>
                    <td>{item.er}</td>
                    <td>{item.era}</td>
                </tr>
            )}
            {props.monthlyRecordList.length === 0 ?
                <tr>
                    <td colSpan="19">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.monthlyTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.monthlyTotalRecord.w}</td>
                    <td>{props.monthlyTotalRecord.l}</td>
                    <td>{props.monthlyTotalRecord.sv}</td>
                    <td>{props.monthlyTotalRecord.hold}</td>
                    <td>
                        {props.monthlyTotalRecord.inn2 % 3 === 0
                        ? parseInt(props.monthlyTotalRecord.inn2 / 3)
                        : parseInt(props.monthlyTotalRecord.inn2 / 3) +
                            " " +
                            (props.monthlyTotalRecord.inn2 % 3) +
                            "/3"}
                    </td>
                    <td>{props.monthlyTotalRecord.tugucount}</td>
                    <td>{props.monthlyTotalRecord.pa}</td>
                    <td>{props.monthlyTotalRecord.ab}</td>
                    <td>{props.monthlyTotalRecord.hit}</td>
                    <td>{props.monthlyTotalRecord.hr}</td>
                    <td>{props.monthlyTotalRecord.bb}</td>
                    <td>{props.monthlyTotalRecord.hp}</td>
                    <td>{props.monthlyTotalRecord.kk}</td>
                    <td>{props.monthlyTotalRecord.wp}</td>
                    <td>{props.monthlyTotalRecord.bk}</td>
                    <td>{props.monthlyTotalRecord.r}</td>
                    <td>{props.monthlyTotalRecord.er}</td>
                    <td>{((props.monthlyTotalRecord.er * 9) / (props.monthlyTotalRecord.inn2 / 3)).toFixed(2)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)


export default MonthlyRecordComponent;