import React, { Component } from 'react';
import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';
import AnalyticsContext from "../../../context/AnalyticsContext";
import * as CommonService from "../../../services/Common.service"

class IntroContainer extends Component {
    static contextType = AnalyticsContext;

    componentDidMount = async () => {
        const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);

        currentAnalyticsVal.deps2 = "01";
        currentAnalyticsVal.deps3 = "02";
        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href;
        
        await CommonService.insertAnalytics(this.context.state);
    }


    constructor(props) {
        super(props);

        const subMenu = 'intro';
        const subMenuName = '구단소개'
        this.state = {
            subMenu,
            subMenuName
        }
    }

    render = () => (
        <div className='container'>
            <SubHeadComponent
                subMenuName={this.state.subMenuName}
                thirdMenuName={this.state.thirdMenuName} />
            {/* <div className="sbHead_appView tb"></div> */}

            <ContComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName}
                thirdMenu={this.state.thirdMenu}
                thirdMenuName={this.state.thirdMenuName}

                tabNum={this.state.tabNum}
                onClickBtnTab={this.onClickBtnTab}
            />
        </div>
    )
}

export default IntroContainer;