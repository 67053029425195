import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import GuideContainer from './guide/Guide.container';

class TigersPointContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}/guide`} component={GuideContainer} />
        </Switch>
    )
}

export default TigersPointContainer;
