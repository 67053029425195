import React, { Component } from 'react';
import SubHeadComponent from '../../common/SubHead.component';
import ContComponent from './Cont.component';
import queryString from 'query-string';

import * as DateHelper from '../../../../helpers/Date.helper';
import * as ArticleService from '../../../../services/Article.service';
import * as GameService from '../../../../services/Game.service';
import * as ImageHelper from '../../../../helpers/Image.helper';
import * as PlayerHelper from '../../../../helpers/Player.helper';
import * as CommonHelper from '../../../../helpers/Common.helper';

import $ from 'jquery';

class InfoContainer extends Component {
    constructor(props) {
        super(props);

        const queryStringParams = queryString.parse(this.props.location.search)

        const type = queryStringParams['type'] ? queryStringParams['type'] : 'major';
        const gameDate = queryStringParams['gameDate'] ? queryStringParams['gameDate'] : null;
        const gameKey = queryStringParams['gameKey'] ? queryStringParams['gameKey'] : null;
        const isPrevInfo = (type && gameDate);

        const selectedYear = gameDate.substr(0, 4);
        const selectedMonth = parseInt(gameDate.substr(4, 2));
        const selectedDay = parseInt(gameDate.substr(6, 2));

        const selectYearList = DateHelper.getYearSelectList(2012);
        const selectMonthList = DateHelper.getMonthSelectList();
        const selectDayList = DateHelper.getDaySelectList(selectedYear, selectedMonth)

        this.state = {
            subMenuName: "경기일정/결과",
            thirdMenuName: (type === 'major' ? '1군' : "퓨처스리그"),

            isInit: false,

            selectYearList,
            selectMonthList,
            selectDayList,

            selectedYear,
            selectedMonth,
            selectedDay,

            isPrevInfo,
            isEndGame: false,

            type,
            gameKey,
            gameDate,

            currentGameInfo: null,
            scoreBoard: null,

            lastInning: 15,
            colWidth: '3.9%',

            expiredEntryStatusListObj: null,

            boxScoreResult: null,

            homeTeamInfo: null,
            awayTeamInfo: null,


            homePitcherRecordList: [],
            awayPitcherRecordList: [],
            homeHitterRecordList: [],
            homeHitterTotalRecord: null,
            awayHitterRecordList: [],
            awayHitterTotalRecord: null,

            homeTeamName: '',
            awayTeamName: '',

            hitterTotalRecordColSpan: 18,
        }
    }

    componentDidMount = async _ => {
        $(window).resize(this.onResizeWindow).trigger('resize');
        this.updateInfo(this.state.isPrevInfo, this.state.type, this.state.gameKey, this.state.gameDate);
    }

    componentDidUpdate = async prevProps => {
        if (this.props.location !== prevProps.location) {
            const queryStringParams = queryString.parse(this.props.location.search);

            const type = queryStringParams['type'] ? queryStringParams['type'] : 'major';
            const gameKey = queryStringParams['gameKey'] ? queryStringParams['gameKey'] : null;
            const gameDate = queryStringParams['gameDate'] ? queryStringParams['gameDate'] : null;
            const isPrevInfo = (type && gameDate);

            this.updateInfo(isPrevInfo, type, gameKey, gameDate);
        }
    }

    onChangeSelectList = (evt, key) => {
        this.setState({ [key]: parseInt(evt.target.value, 10) })
    }

    onClickBtnPrevGame = _ => {
        console.log('onClickBtnPrevGame')
        if (!this.state.boxScoreResult.schedule.hasOwnProperty("prev")) {
            alert('이전 경기가 없습니다.');
            return;
        }
        let gameDate = this.state.boxScoreResult.schedule.prev.gameDate;
        let gameKey = this.state.boxScoreResult.schedule.prev.gmkey;
        const url = `${this.props.match.url}?type=${this.state.type}&gameKey=${gameKey}&gameDate=${gameDate}`;
        this.props.history.push(url);
    }

    onClickBtnNextGame = _ => {
        console.log('onClickBtnNextGame')
        if (!this.state.boxScoreResult.schedule.hasOwnProperty("next")) {
            alert('다음 경기가 없습니다.');
            return;
        }
        let gameDate = this.state.boxScoreResult.schedule.next.gameDate;
        let gameKey = this.state.boxScoreResult.schedule.next.gmkey;
        const url = `${this.props.match.url}?type=${this.state.type}&gameKey=${gameKey}&gameDate=${gameDate}`;
        this.props.history.push(url);

    }

    onClickBtnPrevDay = _ => {
        // console.log('onClickBtnPrevDay')
        // let selectedYear = this.state.selectedYear;
        // let selectedMonth = this.state.selectedMonth;
        // let selectedDay = this.state.selectedDay - 1;

        // if (selectedDay === 0) {
        //     selectedMonth -= 1;
        //     if (selectedMonth === 0) {
        //         selectedMonth = 12;
        //         selectedYear -= 1;
        //     }
        //     selectedDay = DateHelper.getLastDayOfMonth(selectedYear, selectedMonth);
        // }

        let gameDate = this.state.boxScoreResult.schedule.prev.gameDate;
        let gameKey = this.state.boxScoreResult.schedule.prev.gmkey;
        // gameDate += (selectedMonth < 10 ? "0" + selectedMonth : selectedMonth) + '';
        // gameDate += (selectedDay < 10 ? "0" + selectedDay : selectedDay) + '';
        const url = `${this.props.match.url}?type=${this.state.type}&gameKey=${gameKey}&gameDate=${gameDate}`;
        this.props.history.push(url);
    }
    onClickBtnNextDay = _ => {
        // console.log('onClickBtnNextDay')
        // let selectedYear = this.state.selectedYear;
        // let selectedMonth = this.state.selectedMonth;
        // let selectedDay = this.state.selectedDay + 1;

        // if (selectedDay > DateHelper.getLastDayOfMonth(selectedYear, selectedMonth)) {
        //     selectedMonth += 1;
        //     if (selectedMonth === 13) {
        //         selectedMonth = 1;
        //         selectedYear += 1;
        //     }
        //     selectedDay = 1;
        // }

        // let gameDate = selectedYear;
        // gameDate += (selectedMonth < 10 ? "0" + selectedMonth : selectedMonth) + '';
        // gameDate += (selectedDay < 10 ? "0" + selectedDay : selectedDay) + '';

        // const url = `${this.props.match.url}?type=${this.state.type}&gameDate=${gameDate}`;
        // this.props.history.push(url);

        // let selectDayList = DateHelper.getDaySelectList(selectedYear, selectedMonth);

        // this.setState({ selectedYear, selectedMonth, selectedDay, selectDayList });

        let gameDate = this.state.boxScoreResult.schedule.next.gameDate;
        let gameKey = this.state.boxScoreResult.schedule.next.gmkey;
        const url = `${this.props.match.url}?type=${this.state.type}&gameKey=${gameKey}&gameDate=${gameDate}`;
        this.props.history.push(url);
    }
    onClickBtnShowInfo = _ => {
        console.log('onClickBtnShowInfo')

    }

    updateInfo = async (isPrevInfo, type, gameKey, gameDate) => {
        console.log(isPrevInfo, type, gameKey, gameDate);
        try {
            // 게임 정보
            let currentGameInfo;
            // 스코어 보드 정보
            let scoreBoard;

            // 홈 팀 정보, 원정 팀 정보
            let homeTeamInfo = { name: '', emblemImgUrl: '', gameInfo: null };
            let awayTeamInfo = { name: '', emblemImgUrl: '', gameInfo: null };

            // 다른팀 경기 정보
            let otherTeamInfoList = null;

            // 등록 말소 현황목록 객체
            let expiredEntryStatusListObj = {};
            // 뉴스
            let newsList = [];
            // 화보
            let picturesList = [];

            // 홈 투수 기록 목록
            let homePitcherRecordList = [];
            // 홈 타자 기록 목록 / 총 계
            let homeHitterRecordList = [];
            let homeHitterTotalRecord = null

            // 원정 투수 기록 목록
            let awayPitcherRecordList = [];
            // 원정 타자 기록 목록 / 총 계
            let awayHitterRecordList = []
            let awayHitterTotalRecord = null;

            let boxScoreResult = null;
            let currentScheduleInfo = {};
            let isEndGame = false;

            let lastInning = this.state.lastInning;
            let colWidth = this.state.colWidth;
            // let hitterTotalRecordColSpan = this.state.hitterTotalRecordColSpan;

            const params = { gameKey, gameDate }

            if (type === 'major') {
                boxScoreResult = await GameService.getMajorBoxScore(params);
            } else {
                boxScoreResult = await GameService.getMinorBoxScore(params);
            }
            currentGameInfo = boxScoreResult.schedule.current;
            scoreBoard = boxScoreResult.scoreboard;
            if (scoreBoard.length == 0) {
                scoreBoard = [
                    { score1: '-', score2: '-', score3: '-', score4: '-', score5: '-', score6: '-', score7: '-', score8: '-', score9: '-', score10: '-', score11: '-', score12: '-', run: '0', hit: '0', error: '0', bhome: 3 },
                    { score1: '-', score2: '-', score3: '-', score4: '-', score5: '-', score6: '-', score7: '-', score8: '-', score9: '-', score10: '-', score11: '-', score12: '-', run: '0', hit: '0', error: '0' },
                ]
            }


            console.log(currentGameInfo);
            // 게임 키와 게임 날짜 설정()
            currentGameInfo['formattedGameDate'] = DateHelper.makeSeparatedDate(currentGameInfo['gameDate'] + '');

            homeTeamInfo['name'] = currentGameInfo.home;
            homeTeamInfo['emblemImgUrl'] = ImageHelper.getEmblemImgByTeamCode(currentGameInfo.homeKey);
            awayTeamInfo['name'] = currentGameInfo.visit;
            awayTeamInfo['emblemImgUrl'] = ImageHelper.getEmblemImgByTeamCode(currentGameInfo.visitKey);

            lastInning = CommonHelper.getLastInning(scoreBoard);
            colWidth = (74.1 / (lastInning + 4)) + '%';
            console.log('lastInning, colWidth', lastInning, colWidth);

            homePitcherRecordList = boxScoreResult.hpitchers;
            for (let i = 0; i < homePitcherRecordList.length; i++) {
                homePitcherRecordList[i]['resultStr'] = PlayerHelper.makeResultStr(homePitcherRecordList[i].wls);
                if (PlayerHelper.checkIsEntryPitcher(homePitcherRecordList[i]['changeinn'])) {
                    homeTeamInfo['entryPitcherInfo'] = homePitcherRecordList[i];
                }
                if (homePitcherRecordList[i].wls === 'W' || homePitcherRecordList[i].wls === 'L') {
                    homeTeamInfo['pitcherInfo'] = homePitcherRecordList[i];
                }
            }

            awayPitcherRecordList = boxScoreResult.vpitchers;
            for (let i = 0; i < awayPitcherRecordList.length; i++) {
                awayPitcherRecordList[i]['resultStr'] = PlayerHelper.makeResultStr(awayPitcherRecordList[i].wls);
                if (PlayerHelper.checkIsEntryPitcher(awayPitcherRecordList[i]['changeinn'])) {
                    awayTeamInfo['entryPitcherInfo'] = awayPitcherRecordList[i];
                }
                if (awayPitcherRecordList[i].wls === 'W' || awayPitcherRecordList[i].wls === 'L') {
                    awayTeamInfo['pitcherInfo'] = awayPitcherRecordList[i];
                }
            }

            let hTotalHitRate = 0;
            let hTotalHra = 0;
            if (boxScoreResult.hbatters.length > 0) {
                for (let i = 0; i < boxScoreResult.hbatters.length; i++) {
                    let hitRate = boxScoreResult.hbatters[i]['accmAb'] == 0 ? 0 : (boxScoreResult.hbatters[i]['accmHit'] / boxScoreResult.hbatters[i]['accmAb']);
                    hTotalHitRate += hitRate;
                    boxScoreResult.hbatters[i]['sHitRate'] = hitRate.toFixed(3);

                    // hTotalHitRate += parseFloat(boxScoreResult.hbatters[i]['accmHit']) / parseFloat(boxScoreResult.hbatters[i]['accmAb']);
                    // boxScoreResult.hbatters[i]['sHitRate'] = (boxScoreResult.hbatters[i]['accmHit'] / boxScoreResult.hbatters[i]['accmAb']).toFixed(3);
                    if (boxScoreResult.hbatterrecent[i]) {
                        if (boxScoreResult.hbatters[i]['pcode'] === boxScoreResult.hbatterrecent[i]['pcode']) {
                            boxScoreResult.hbatters[i]['hra'] = parseFloat(boxScoreResult.hbatterrecent[i]['hra']).toFixed(3);
                            hTotalHra += parseFloat(boxScoreResult.hbatterrecent[i]['hra']);
                        }
                    }
                }
                homeHitterRecordList = boxScoreResult.hbatters.slice(0, boxScoreResult.hbatters.length - 1);
                homeHitterTotalRecord = boxScoreResult.hbatters[boxScoreResult.hbatters.length - 1];
                homeHitterTotalRecord['sHitRate'] = hTotalHitRate.toFixed(3);// / (boxScoreResult.length -1)).toFixed(3)
                homeHitterTotalRecord['hra'] = hTotalHra.toFixed(3);
            }

            let vTotalHitRate = 0;
            let vTotalHra = 0;
            if (boxScoreResult.vbatters.length > 0) {
                for (let i = 0; i < boxScoreResult.vbatters.length; i++) {
                    let hitRate = boxScoreResult.vbatters[i]['accmAb'] == 0 ? 0 : (boxScoreResult.vbatters[i]['accmHit'] / boxScoreResult.vbatters[i]['accmAb']);
                    vTotalHitRate += hitRate;
                    boxScoreResult.vbatters[i]['sHitRate'] = hitRate.toFixed(3);
                    if (i < boxScoreResult.vbatterrecent.length) {
                        boxScoreResult.vbatters[i]['hra'] = boxScoreResult.vbatterrecent[i]['hra'];
                    }
                    if (boxScoreResult.vbatterrecent[i]) {
                        if (boxScoreResult.vbatters[i]['pcode'] === boxScoreResult.vbatterrecent[i]['pcode']) {
                            boxScoreResult.vbatters[i]['hra'] = parseFloat(boxScoreResult.vbatterrecent[i]['hra']).toFixed(3);
                            vTotalHra += parseFloat(boxScoreResult.vbatterrecent[i]['hra']);
                        }
                    }
                }
                console.log('boxScoreResult.vbatters', boxScoreResult.vbatters);
                awayHitterRecordList = boxScoreResult.vbatters.slice(0, boxScoreResult.vbatters.length - 1);
                awayHitterTotalRecord = boxScoreResult.vbatters[boxScoreResult.vbatters.length - 1];
                awayHitterTotalRecord['sHitRate'] = vTotalHitRate.toFixed(3);// / (boxScoreResult.length -1)).toFixed(3)
                awayHitterTotalRecord['hra'] = vTotalHra.toFixed(3);
            }

            console.log('homeTeamInfo', homeTeamInfo);
            console.log('awayTeamInfo', awayTeamInfo);

            const selectedYear = gameDate.substr(0, 4);
            const selectedMonth = parseInt(gameDate.substr(4, 2));
            const selectedDay = parseInt(gameDate.substr(6, 2));

            this.setState({
                isInit: true,
                lastInning,
                currentGameInfo,
                // 스코어 보드 정보
                scoreBoard,

                // 홈팀, 원정팀 정보
                homeTeamInfo, awayTeamInfo,

                // 다른팀 경기 정보
                otherTeamInfoList,

                // 등록 말소 현황목록 객체
                expiredEntryStatusListObj,
                // 뉴스
                newsList,
                // 화보
                picturesList,

                // 홈 투수 기록 목록
                homePitcherRecordList,
                // 홈 타자 기록 목록 / 총 계
                homeHitterRecordList,
                homeHitterTotalRecord,

                // 원정 투수 기록 목록
                awayPitcherRecordList,
                // 원정 타자 기록 목록 / 총 계
                awayHitterRecordList,
                awayHitterTotalRecord,

                // 년 월 일
                selectedYear,
                selectedMonth,
                selectedDay,

                boxScoreResult,
                currentScheduleInfo,
                isEndGame
            }, _ => {
                $(window).trigger('resize');
            })
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    }

    componentWillUnmount = _ => {
        $(window).unbind('resize');
    }

    onResizeWindow = evt => {
        const width = evt.target.innerWidth;
        let hitterTotalRecordColSpan = '9';
        if (width > 780) {
            hitterTotalRecordColSpan = this.state.lastInning + 3;
        } else {
            hitterTotalRecordColSpan = this.state.lastInning;
        }
        this.setState({ hitterTotalRecordColSpan });
    }

    onClickBtnPicture = (evt, articleId) => {
        const url = `/game/pictures/${articleId}`;
        this.props.history.push(url);
    }

    onClickBtnBack = _ => {
        const enCodeDate = this.state.gameDate.substr(0,6);
        this.props.history.push(`/game/schedule/${this.state.type}/${enCodeDate}`);
    }

    render = () => (
      <div className='container'>
          <SubHeadComponent
            subMenuName={this.state.subMenuName}
            thirdMenuName={this.state.thirdMenuName} />
          {this.state.isInit ?
            <ContComponent
              isPrevInfo={this.state.isPrevInfo}
              isEndGame={this.state.isEndGame}
              currentGameInfo={this.state.currentGameInfo}

              selectedYear={this.state.selectedYear}
              selectedMonth={this.state.selectedMonth}
              selectedDay={this.state.selectedDay}

              selectYearList={this.state.selectYearList}
              selectMonthList={this.state.selectMonthList}
              selectDayList={this.state.selectDayList}

              onClickBtnPrevGame={this.onClickBtnPrevGame}
              onClickBtnNextGame={this.onClickBtnNextGame}
              onClickBtnShowInfo={this.onClickBtnShowInfo}


              boxScoreResult={this.state.boxScoreResult}

              otherTeamInfoList={this.state.otherTeamInfoList}

              homeTeamInfo={this.state.homeTeamInfo}
              awayTeamInfo={this.state.awayTeamInfo}

              lastInning={this.state.lastInning}
              colWidth={this.state.colWidth}

              hitterTotalRecordColSpan={this.state.hitterTotalRecordColSpan}

              scoreBoard={this.state.scoreBoard}

              expiredEntryStatusListObj={this.state.expiredEntryStatusListObj}
              newsList={this.state.newsList}
              picturesList={this.state.picturesList}
              onClickBtnPicture={this.onClickBtnPicture}

              homePitcherRecordList={this.state.homePitcherRecordList}
              awayPitcherRecordList={this.state.awayPitcherRecordList}
              homeHitterRecordList={this.state.homeHitterRecordList}
              homeHitterTotalRecord={this.state.homeHitterTotalRecord}
              awayHitterRecordList={this.state.awayHitterRecordList}
              awayHitterTotalRecord={this.state.awayHitterTotalRecord}

              onClickBtnBack={this.onClickBtnBack}
              onChangeSelectList={this.onChangeSelectList}
            />
            : null}
      </div>
    )
}

export default InfoContainer;
