import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ContentService from "../../../../services/Content.service";
import * as AuthHelper from "../../../../helpers/Auth.helper";
import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";
import PrepareComponent from '../../../layout/Prepare.component' 

class WebzineContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = "archive";
    const thirdMenu = "webzine";
    this.state = {
      isInit: false,
      subMenu,
      subMenuName: "지난 콘텐츠 보기",
      thirdMenu,
      thirdMenuName: "타이거즈 웹진",
      tabNum: 2,
      //selectedYear: process.env.REACT_APP_SEASON_YEAR,
      selectedYear: '2022',
      yearSelectList: [],
      currentWebzineInfo: null,
      list: [],

    };
  }

  componentDidMount = async (_) => {
    // 230126 주석
    // if (!AuthHelper.checkHasLogin()) {
    //   alert("로그인 후 이용하실 수 있는 페이지입니다.");
    //   AuthHelper.removeLoginInfo();
    //   document.location.href = "/login";
    // } 
    // else {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "03";
      currentAnalyticsVal.deps3 = "05"; //
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      this.updateWebzine(this.state.selectedYear);
    // }
  };

  updateWebzine = async (selectedYear) => {
    try {
      const result = await ContentService.getWebzineList(selectedYear);
      const yearSelectList = result.years;
      const currentWebzineInfo = result.webzines[0];
      const list = result.webzines;
      this.setState({
        isInit: true,
        yearSelectList,
        currentWebzineInfo,
        list,
        selectedYear,
      });
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  setSliderRef = (ref) => {
    this.slider = ref;
  };

  onClickBtnSliderPrev = (_) => {
    console.log("onClickBtnSliderPrev");
    this.slider.slickPrev();
  };

  onClickBtnSliderNext = (_) => {
    console.log("onClickBtnSliderNext");
    this.slider.slickNext();
  };

  onClickBtnShowWebzine = (evt) => {
    console.log("onClickBtnShowWebzine");
    let file = this.state.currentWebzineInfo.pdfPath;
    let filename = file.split("/")[file.split("/").length - 1];
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  onClickBtnItem = (evt, idx) => {
    const currentWebzineInfo = this.state.list[idx];
    this.setState({ currentWebzineInfo });
  };
  onChangeSelectList = (evt) => {
    this.setState({ selectedYear: evt.target.value });
    this.updateWebzine(evt.target.value);
  };

  onClickBtnSearch = (_) => {
    this.updateWebzine(this.state.selectedYear);
  };

  increaseWebzineViewCnt = async (currentSeq) => {
    try {
      await ContentService.increaseWebzineViewCnt(currentSeq);
    } catch (err) {
      console.err(err);
    }
  };

  render = (_) => (
    // 230126 주석
    this.state.isInit ? (
      <div className="container">
        <SubHeadComponent
          subMenuName={this.state.subMenuName}
          thirdMenuName={this.state.thirdMenuName}
        />
        <ContComponent
          setSliderRef={this.setSliderRef}
          onClickBtnSliderPrev={this.onClickBtnSliderPrev}
          onClickBtnSliderNext={this.onClickBtnSliderNext}
          onChangeSelectList={this.onChangeSelectList}
          onClickBtnSearch={this.onClickBtnSearch}
          yearSelectList={this.state.yearSelectList}
          currentWebzineInfo={this.state.currentWebzineInfo}
          onClickBtnShowWebzine={this.onClickBtnShowWebzine}
          onClickBtnItem={this.onClickBtnItem}
          increaseWebzineViewCnt={this.increaseWebzineViewCnt}
          list={this.state.list}
          
          subMenu={this.state.subMenu}
          subMenuName={this.state.subMenuName}
          thirdMenu={this.state.thirdMenu}
          thirdMenuName={this.state.thirdMenuName}
        />
      </div>
    ) : null);
}

export default WebzineContainer;
