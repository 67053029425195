class CommonError extends Error {
    constructor(message, status = 0) {
        super(message);
        status = parseInt(status, 10);

        let action = 'message';

        switch (status) {
            case 2016: action = 'login'; break;
            case 8111: action = 'login'; break;
            case 8112: action = 'refresh'; break;
            case 8121: action = 'login'; break;
            case 8122: action = 'login'; break;
            default: action = 'message'; break;
        }

        this.name = this.constructor.name;
        this.status = status;
        this.action = action;

        Error.captureStackTrace(this, this.constructor);
    }
}


// 2016	로그인 필요한 API인데, AccessToken을 전송하지 않았을 때	로그인 필요한 API이기 때문에 AccessToken을 넣어준다.
// 8111	AccessToken이 유효하지 않아 로그인정보를 찾을 수 없을 때 (다른 곳에서 동일한 채널로 로그인 했을 때)	"세션이 만료되었습니다." (재로그인 동작으로 넘어간다.)
// 8112	로그인정보는 유효하나 AccessToken이 만료되었을 때	내부적으로 refreshAccessToken (AccessToken 재발급) API를 호출하여 AccessToken/RefreshToken을 갱신한 후 해당 API를 재호출한다.
// 8121	RefreshToken 유효하지 않을 때 (다른 곳에서 동일한 채널로 로그인했을 때)	"세션이 만료되었습니다." (재로그인 동작)
// 8122	RefreshToken 만료되었을 때	"세션이 만료되었습니다." (재로그인 동작)

export default CommonError;