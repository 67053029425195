import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import SkyboxContainer from './skybox/Skybox.container';
import SeasonPassContainer from './season-pass/SeasonPass.container';
import ReservationContainer from './reservation/Reservation.container';
import RefundAccountContainer from "./refundAccount/RefundAccount.container";

class TicketContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}/reservation`} component={ReservationContainer} />
            <Route exact path={`${this.props.match.path}/season-pass`} component={SeasonPassContainer} />
            <Route exact path={`${this.props.match.path}/skybox`} component={SkyboxContainer} />
            <Route exact path={`${this.props.match.path}/refundAccount`} component={RefundAccountContainer} />
        </Switch>
    )
}

export default TicketContainer;