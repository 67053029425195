import {endOfMinute, format, isPast, parse} from "date-fns"
import React, {useCallback} from "react"
import {useQuery} from "react-query"
import {useHistory} from "react-router-dom"
import * as GoodsAuctionService from "../../../services/GoodsAuction.service"
import GoodsAuctionTab from "./GoodsAuctionTab"

export const params = {
  page: 1,
  pageUnit: 20
}

function GoodsAuctionList() {
  const history = useHistory()

  const onClickGoods = useCallback(
    (id) => {
      history.push(`/auction/goods-list/${id}`)
    },
    [history]
  )

  const {isLoading, data} = useQuery("goodsAuctionProducts", () => GoodsAuctionService.getGoods(params), {staleTime: 1000})
  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <GoodsAuctionTab>
      <div className="goods-list-products">
        <ul>
          {data && data.length > 0 ? (
            data.map((goods) => {
              const startDate = parse(goods.startDt, "yyyy-MM-dd HH:mm:ss", 0)
              const endDate = parse(goods.endDt, "yyyy-MM-dd HH:mm:ss", 0)
              const isEnd = goods.status === "E" || isPast(endOfMinute(endDate))

              return (
                <li className="card" key={goods.productId} onClick={!isEnd ? () => onClickGoods(goods.productId) : undefined}>
                  <div className="card-header">
                    <img src={goods.imagePath === null ? "/img/main/no_goods_img.png" : goods.imagePath} alt={`${goods.productName} 이미지`} />
                    {isEnd ? (
                      <div className="auction-end" onClick={() => {}}>
                        마감
                      </div>
                    ) : (
                      <div className="auction-progress">진행중</div>
                    )}
                  </div>
                  <div className="card-body">
                    <h5>{goods.productName}</h5>
                    <p>
                      {`최저가 : ${goods.startPrice.toLocaleString("en")}`}원 <br />
                      <span>현재가 : {(goods.currentPrice ?? goods.startPrice).toLocaleString("en")}원</span>
                      <br />
                    </p>
                    <h6>{`${format(startDate, "yy.MM.dd")} ~ ${format(endDate, "yy.MM.dd")}`}</h6>
                  </div>
                </li>
              )
            })
          ) : (
            <div className="no-goods">현재 진행중인 경매 목록이 없습니다.</div>
          )}
        </ul>
      </div>
    </GoodsAuctionTab>
  )
}

export default GoodsAuctionList
