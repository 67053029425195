import React from "react";

const SecessionPopupComponent = props => (
  <div className="popup dropOut on">
    <div className="pcPopIn">
      <p onClick={props.onClickBtnClosePopup} className="popClose">
        <img src="/img/sub/pop_close.png" alt="팝업닫기 버튼" />
      </p>
      <div>
        <h3>회원탈퇴하기</h3>
        <p className="txtBold">
          회원탈퇴를 하시기 전에 아래 사항을 꼭 확인하세요
        </p>
        <br />
        <p className="txtRed">
          회원탈퇴를 하시면 작성하신 게시물은 삭제되지 않습니다.
          <br />
          필요 시 탈퇴 전 모두 수정, 삭제 해주세요.
        </p>
        <br />
        <p className="txtBold">탈퇴사유</p>
        <p className="popRadio">
          <input
            type="radio"
            id="radio1"
            name="receive"
            onClick={props.onClickSecessionReason}
            value="1"
            defaultChecked={props.secessionObj.type === 1}
          />
          <label htmlFor="radio1">기타</label>
          <input
            type="radio"
            id="radio2"
            name="receive"
            onClick={props.onClickSecessionReason}
            value="2"
            defaultChecked={props.secessionObj.type === 2}
          />
          <label htmlFor="radio2">서비스 불만족</label>
          <input
            type="radio"
            id="radio3"
            name="receive"
            onClick={props.onClickSecessionReason}
            value="3"
            defaultChecked={props.secessionObj.type === 3}
          />
          <label htmlFor="radio3">서비스 이용 불편</label>
        </p>
        <p>
          <label htmlFor="textfield">
            <span className="blind">탈퇴사유</span>
          </label>
          <textarea
            cols="30"
            rows="5"
            id="textfield"
            placeholder="탈퇴 사유 혹은 Kia Tigers에 바라는 점을 남겨주세요."
            onChange={evt => props.onChangeInput(evt, "secessionObj", "reason")}
          ></textarea>
        </p>
        <div className="loginBtn_wrap">
          <div className="login_btn01">
            <ul className="login_btn01In">
              <li>
                <a onClick={props.onClickBtnSecession}>탈퇴하기</a>
              </li>
              <li>
                <a onClick={props.onClickBtnClosePopup}>취소</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default SecessionPopupComponent;
