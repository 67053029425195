import React, {Component} from "react"
import SubHeadComponent from "../common/SubHead.component"
import ContComponent from "./Cont.component"
import SubMenuComponent from "../common/SubMenu.component"

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

class UniformContainer extends Component {
  static contextType = AnalyticsContext

  componentDidMount = async () => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl
    this.context.action(currentAnalyticsVal)

    currentAnalyticsVal.deps2 = "01"
    currentAnalyticsVal.deps3 = "05"
    currentAnalyticsVal.deps4 = "03"
    currentAnalyticsVal.prevUrl = prevUrl
    currentAnalyticsVal.currentUrl = window.location.href

    await CommonService.insertAnalytics(this.context.state)
  }

  constructor(props) {
    super(props)

    const subMenu = "bi"
    const thirdMenu = "uniform"
    this.state = {
      subMenu,
      subMenuName: "CI",
      thirdMenu,
      thirdMenuName: "유니폼"
    }
  }

  render = () => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} thirdMenuName={this.state.thirdMenuName} />
      <div className="sbHead_appView tb"></div>
      {/* 240123 주석시작 */}
      {/* {process.env.REACT_APP_PREPARING_UNIFORM_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont tigers">
          <div className="mb_contIn uniform">
            <SubMenuComponent thirdMenu={this.state.thirdMenu} />
            <div className="mb_contIn">
              <div style={{marginTop: "50px"}}>
                <img src="https://tigers.co.kr/files/resource/2022/11/07/20221107151458.e2f-fa16c579d272.png" alt="season_off_pc" className="pc" />
                <img
                  src="https://tigers.co.kr/files/resource/2022/11/07/20221107151446.ef8-5e17a78266fa.png"
                  alt="season_off_mb"
                  className="greeting_mb tb"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ContComponent
          subMenu={this.state.subMenu}
          subMenuName={this.state.subMenuName}
          thirdMenu={this.state.thirdMenu}
          thirdMenuName={this.state.thirdMenuName}
        />
      )} */}
      {/* 240123 주석끝 */}
        <ContComponent
          subMenu={this.state.subMenu}
          subMenuName={this.state.subMenuName}
          thirdMenu={this.state.thirdMenu}
          thirdMenuName={this.state.thirdMenuName}
        />
    </div>
  )
}

export default UniformContainer
