import React from 'react';
import { Link } from 'react-router-dom';
import ImgComponent from '../../../common/Img.component';
import * as ImageHelper from '../../../../helpers/Image.helper';

import PaginationComponent from '../../../common/Pagination.component';
const ContComponent = (props) => (
    <div className="mb_cont tigersNews">
        <div className="mb_contIn ">
            <div className="board">
                {/* <!-- srchArea --> */}
                <div className="srchArea">
                    <select
                        onChange={props.onChangeSelectList}
                        defaultValue={props.selectedSearchFilter}
                        name="" id="">
                        {props.searchFilterSelectList.map((item, idx) =>
                            <option key={idx} value={item.value}>{item.text}</option>
                        )}
                    </select>
                    <input defaultValue={props.searchKeyword}
                        onChange={props.onChangeInput}
                        onKeyPress={props.onEnterKeyPress}
                        type="text" placeholder="검색어를 입력해주세요" />
                    <button
                        onClick={props.onClickBtnSearch}
                        type="button">검색</button>
                </div>
                {/* <!-- //srchArea --> */}

                {/* <!-- board --> */}
                <div className="thumb">
                    <ul>
                        {props.list.map((item, idx) =>
                            <li key={idx}>
                                <Link to={`/contents/news/${item.artcSeq}`}>
                                    <div className="img">
                                        <ImgComponent
                                            defaultImgUrl={ImageHelper.getContentsNewsListNoImg()}
                                            imgUrl={item.imgFilePath} />
                                    </div>
                                    <dl>
                                        <dt>{item.artcTitle}</dt>
                                        <dd className="txt">{item.artcContent}</dd>
                                        <dd className="icoWrap">
                                            <span className="date">{item.regDate}</span>
                                            <span className="view"><i></i>{item.viewCnt}</span>
                                            <span className="like"><i></i>{item.likeCnt}</span>
                                        </dd>
                                    </dl>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
                {/* <!-- //bdList --> */}
                <PaginationComponent
                    paginationObj={props.paginationObj}
                    onClickBtnPageNum={props.onClickBtnPageNum}
                />
                {/* <div className="paging">
                    <div className="pgingIn">
                        <p><img src="/img/sub/pging_prev2.png" alt="게시판글 페이징 10씩 앞글보기" /></p>
                        <p><img src="/img/sub/pging_prev.png" alt="게시판글 페이징 1씩 앞글보기" /></p>
                        <ol>
                            <li className="on">1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>10</li>
                        </ol>
                        <p><img src="/img/sub/pging_next.png" alt="게시판글 페이징 1씩 뒷글보기" /></p>
                        <p><img src="/img/sub/pging_next2.png" alt="게시판글 페이징 10씩 뒷글보기" /></p>
                    </div>
                </div> */}
            </div>
            {/* <!-- //board --> */}
        </div>
        {/* <!-- //mb_contIn --> */}
    </div>
)
export default ContComponent;