import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ResourceService from "../../../services/Resource.service";
import AnalyticsContext from "../../../context/AnalyticsContext";
import * as CommonService from "../../../services/Common.service";

class InfoContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      subMenu: "sns",
      subMenuName: "구단 SNS",
      imgPath: {},
      twitterUrl: "https://twitter.com/Kiatigers",
      instagramUrl: "https://www.instagram.com/always_kia_tigers",
      youtubeUrl: "https://www.youtube.com/kiabaseballclub",
      naverPostUrl: "https://post.naver.com/my.nhn?memberNo=35664311",
      kakaoUrl: "https://pf.kakao.com/_bxexdDj",
      _1boonUrl: "https://1boon.kakao.com/kiatigers",
    };
  }
  componentDidMount = async () => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "03";
    currentAnalyticsVal.deps3 = "08"; //
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    this.getSnsImg();
  };

  getSnsImg = async () => {
    const imgPath = await ResourceService.getResourceList();
    console.log(imgPath.map);
    this.setState({ imgPath: imgPath.map });
  };

  goSnsLink = async (snsUrl, subject) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    switch (subject) {
      case "twitter":
        currentAnalyticsVal.deps1 = "02";
        break;

      case "instagram":
        currentAnalyticsVal.deps1 = "03";
        break;

      case "youtube":
        currentAnalyticsVal.deps1 = "04";
        break;

      case "naver":
        currentAnalyticsVal.deps1 = "05";
        break;

      case "kakao":
        currentAnalyticsVal.deps1 = "06";
        break;

      case "1boon":
        currentAnalyticsVal.deps1 = "07";
        break;

      default:
        break;
    }

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.prevUrl = window.location.href;
    currentAnalyticsVal.currentUrl = snsUrl;

    await CommonService.insertAnalytics(this.context.state);

    currentAnalyticsVal.currentUrl = prevUrl;
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <ContComponent
        twitterUrl={this.state.twitterUrl}
        instagramUrl={this.state.instagramUrl}
        youtubeUrl={this.state.youtubeUrl}
        naverPostUrl={this.state.naverPostUrl}
        kakaoUrl={this.state.kakaoUrl}
        _1boonUrl={this.state._1boonUrl}
        imgPath={this.state.imgPath}
        goSnsLink={this.goSnsLink}
      />
    </div>
  );
}

export default InfoContainer;
