import React, { Component } from "react";
import ContComponent from "./Cont.component";
import * as TicketService from "../../../services/Ticket.service";
import dayjs from 'dayjs';
import { convertTimestampToDate } from "../../../helpers/Date.helper";
dayjs.locale('ko')

const kboTeamList = {
    HH: "/img/ticket/emblem/hanwha.png",
    HT: "/img/ticket/emblem/kia.png",
    KT: "/img/ticket/emblem/kt.png",
    LG: "/img/ticket/emblem/lg.png",
    LT: "/img/ticket/emblem/lotte.png",
    NC: "/img/ticket/emblem/nc.png",
    OB: "/img/ticket/emblem/doosan.png",
    SK: "/img/ticket/emblem/ssg.png",
    SS: "/img/ticket/emblem/samsung.png",
    WO: "/img/ticket/emblem/kiwoom.png"
  }

class ListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        mobile: "",
        idx: "",
        giftTicketInfo: [],
        pitcherSlide: [],
        ticketList: [],
        unconfirmed: "",
        seat_info: [],
        game_date: ['ALL'],
        originTicketInfo: [],
        timeInterval: ''
      };
  }

  //query
  componentDidMount = async() => {
    const current = window.location.search;
    const idx = current.substring(4);
    this.setState({idx: idx});
  }

  //핸드폰 번호 입력
  onChangeInput = (e) => {
    this.setState({ mobile: e.target.value});
  };
  onChangeGameDate = (e) => {
    const game_date = e.target.value;
    const ticket = this.state.originTicketInfo;
    if(game_date === '모두보기'){
      this.setState({giftTicketInfo: ticket});
      return;
    }
    const filterTicket = ticket.filter((item, index) => {
      console.log(game_date, item.origin_game_date)
      if(item.origin_game_date.includes(game_date)) {
        console.log(item);
        return item;
      }

    });
    console.log(filterTicket);
    this.setState({giftTicketInfo: filterTicket});
  }

  //확인버튼
  onChangeSubmit = async(e) => {
    e.preventDefault();
    //선물확인
    const giftTicketInfo = await TicketService.getGiftTicket({mobile: this.state.mobile, idx: this.state.idx });

    const ticket = [];

    if(giftTicketInfo === {err: 'Not Found'} || giftTicketInfo === null || giftTicketInfo === undefined ){
      this.setState({unconfirmed: '해당 번호로 선물받은 티켓 정보가 없습니다.'})
    };

    const ticketInfo = giftTicketInfo.body.itemList;

    //선발투수 슬라이드
    const pitcher = await TicketService.startingPitcher();
    // const pitcherInfo = pitcher[Object.keys(pitcher)[1]];

    const game_date = ['모두보기'];
    ticketInfo.map((item, index) => {


      const game_datetime = item.game_date[0] + item.game_date[2];
      if (pitcher['data'].hasOwnProperty(game_datetime)) {
        const pitcherInfo = pitcher[Object.keys(pitcher)[1]][0].MEDIA_FILE;
        this.setState({pitcherSlide: pitcherInfo});
      } else if (pitcher['data'].hasOwnProperty(item.game_date[0])) {
        const pitcherInfo = pitcher[Object.keys(pitcher)[1]][0].MEDIA_FILE;
        this.setState({pitcherSlide: pitcherInfo});
      }

      //todo: 정책 확인 후 수정 필요
      //seat_detail 4,5가 null일 때(외야석 또는 지정좌석 없을 때) 안보이게 수정
      const seat4 = item.seat_detail.seat_Attribute_4
      const seat5 = item.seat_detail.seat_Attribute_5


      let seatInfo = "";
      if (seat4 === null || seat5 === null) {
        // this.setState({seat_info: ""});
      } else {
        const seat_detail = item.seat_detail.seat_Attribute_4 + " " + item.seat_detail.seat_Attribute_5 + " " + item.seat_detail.seat_Attribute_6;
        // this.setState({seat_info: seat_detail});
        seatInfo = seat_detail;
      }
      if(!game_date.includes(item.game_date[0])){
        game_date.push(item.game_date[0]);
      }
      const _info = {
        away_team: item.away_team,
        away_team_name: item.away_team_name,
        game_date: dayjs(item.game_date[0] + item.game_date[2], 'YYYY-MM-DDHH:mm').format('MM월DD일(ddd) HH:mm'),
        origin_game_date: item.game_date[0].toString(),
        home_team_name: item.home_team_name,
        match_info: item.match_info,
        product_denomination_name: item.product_denomination_name,
        product_grade_name: item.product_grade_name,
        ticket_issue_no: item.ticket_issue_no,
        ticket_no: item.ticket_no,
        awayTeamSrc: kboTeamList[item.away_team],
        pitcherSlide: pitcher,
        seat_info: seatInfo
      }

      ticket.push(_info);

    });
    // ticket in game_date order asc
    ticket.sort((a, b) => {
      return a.game_date < b.game_date ? -1 : a.game_date > b.game_date ? 1 : 0;
    });

    // game_date in game_date order asc
    game_date.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });

    this.setState({ originTicketInfo: ticket });
    this.setState({ giftTicketInfo: ticket });
    this.setState({ game_date: game_date });
  }

  render = () => (
    <div className="container">
      <ContComponent
        onChangeInput={this.onChangeInput}
        onChangeSubmit={this.onChangeSubmit}
        onChangeGameDate={this.onChangeGameDate}
        giftTicketInfo={this.state.giftTicketInfo}
        pitcherSlide={this.state.pitcherSlide}
        unconfirmed={this.state.unconfirmed}
        game_date={this.state.game_date}
      />
    </div>
  );
}

export default ListContainer;
