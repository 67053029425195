import axios from "axios";

const psnToken = "dummyToken";

const baseUrl = "/v1";
export const getWebzineList = async (year = null) => {
  let url = `${baseUrl}/content/webzine`;
  if (year) {
    url += `?webzine.year=${year}`;
  }
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const increaseWebzineViewCnt = async (seq = null) => {
  // let url = `${baseUrl}/content/webzine/increasecnt`;
  let url = `${baseUrl}/content/webzine/detail`;
  if (seq) {
    url += `?webzine.seq=${seq}`;
  }

  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getSnsInfo = async () => {
  let url = `${baseUrl}/content/sns`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getMainBannerList = async (_) => {
  let url = `${baseUrl}/contents/mainBanners`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// 	https://api.kiatigers.info/v1/contents/banners
// 이름	데이터 타입	내용	필수여부	비고
// list[i].name	String	배너명	필수
// list[i].type	String	배너타입	필수	MAIN, TOPLEFT, TOP, TOPRIGHT, LEFT, CENTER, RIGHT
// list[i].pcImgFilePath	String	PC이미지	필수
// list[i].mobileImgFilePath	String	모바일이미지	필수
// list[i].seq	Number	일련번호	필수
export const getBannerList = async (_) => {
  let url = `${baseUrl}/contents/banners`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getAllTigers = async (_) => {
  let url = `${baseUrl}/contents/getTigersContents`;
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getPopupContents = async () => {
  let url = `${baseUrl}/content/popup`;
  return axios.get(url).then(({ status, data }) => {
    if (data.status !== 0) {
      throw new Error(data.message);
    }
    return data.data;
  });
};
