import React from "react";

const ContComponent = (props) => (
  <div className="mb_cont intro1">
    <div className="mb_contIn">
      {/* <div className="vs">
        <img src="/img/sub/intro1_vs.png" alt="TIGERS" />
      </div> */}
      <div className="txt clear">
        <img
          src="/img/sub/greeting_pc_240308.png"
          alt="greeting_pc"
          className="pc"
          style={{ width: "100%" }}
        />
        <img
          src="/img/sub/greeting_m_240308.png"
          alt="greeting_mb"
          className="greeting_mb tb"
          style={{ width: "100%" }}
        />
        {/* <p>
          KIA타이거즈(주) 대표이사 <strong>이화원</strong>
        </p>
        <ul>
          <li>
            KIA타이거즈를 사랑하고 응원해주시는 팬 여러분 반갑습니다. 늘 한결
            같은 마음으로 성원해 주셔서 감사합니다.
            <br />
            <br />
            지난 시즌 KIA타이거즈는 팬 여러분께 큰 실망을 드렸습니다. 숭고한 팬
            여러분의 뜨거운 성원에 보답하지 못한 채 2019시즌을 마무리 했습니다.
            기대에 미치지 못한 성적표를 받아 들고 통렬하게 반성했습니다.
            <br />
            <br />
            우리는 ‘야구 명가’ 타이거즈를 재건해야 하는 과제를 안고 올 시즌을
            출발합니다. 다시 비장한 각오로 나섭니다. 확 바뀐 새로운 모습의
            2020시즌을 만들어 가겠습니다.
            <br />
            <br />
            야구로 즐거움을 드릴 수 있는 구단이 될 수 있도록 노력하겠습니다.
            언제나 팬과 함께 하며, 팬 여러분께 선물 같은 KIA타이거즈가
            되겠습니다.
          </li>
          <li>
            팀의 잠재력을 이끌어 낼 맷 윌리엄스 감독의 지휘 아래 선수단은
            플로리다 스프링캠프에서 치열하게 담금질을 했습니다. 팬들에게 좋은
            경기를 보여드리기 위해 굵은 땀방울을 흘렸습니다.
            <br />
            <br />
            이제 우리는 데이터를 적극 활용하는 과학적인 야구로 과감한 발걸음을
            내딛겠습니다. 견고한 시스템과 효율적인 매뉴얼을 구축해 꾸준한
            강팀으로 우뚝 서겠습니다. <br />
            <br />
            팬 여러분!
            <br />
            <br />
            올 해 캐치프레이즈는 ‘Always KIA TIGERS_도전, 새로운 미래’입니다.
            도전의 자세로 KIA타이거즈의 새로운 미래를 열어 가겠습니다. 야구
            이상의 즐거움과 감동을 안겨드릴 수 있는 KIA타이거즈가 될 것을 약속
            드립니다. 올 시즌에도 변함 없는 응원과 사랑 부탁 드립니다.
            <br />
            <br />
            고맙습니다.
          </li>
        </ul> */}
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
