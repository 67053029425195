import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import SubHeadAppViewComponent from "../../../common/SubHeadAppView.component";

import * as ResourceService from "../../../../services/Resource.service";

import AnalyticsContext from "../../../../context/AnalyticsContext"
import * as CommonService from "../../../../services/Common.service"

import $ from "jquery";

class StadiumGuideContainer extends Component {
  static contextType = AnalyticsContext

  constructor(props) {
    super(props);

    const subMenu = "champions-field";
    const thirdMenu = "stadium-guide";
    this.state = {
      subMenu,
      subMenuName: "광주 - 기아 챔피언스 필드",
      thirdMenu,
      thirdMenuName: "구장안내도",
      tabNum: 3,
      imgUrlObj: null,

      imgObj: {
        isInit: false,
        naturalImgWidth: 0,
        naturalImgHeight: 0,
        ratio: 1,
        ticketMapHeight: 0
      }
    };
  }

  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "03";
    currentAnalyticsVal.deps4 = "03";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;
    
    await CommonService.insertAnalytics(this.context.state);

    $(window).resize(this.onResizeWindow);
    const stadiumResult = await ResourceService.getChampionsFieldGuideImg();

    const imgUrlObj = {
      stadium: stadiumResult.resource.value
    };

    this.setState({ imgUrlObj });
  };

  componentWillUnmount = _ => {
    $(window).unbind("resize");
  };

  onClickBtnTicketReservation = evt => {
    this.props.history.push("/ticket/reservation");
  };

  onLoadImg = ({ target: img }) => {
    console.log("onLoadImg");
    console.log(img.naturalWidth);
    console.log(img.naturalHeight);

    let imgObj = this.state.imgObj;
    imgObj.naturalWidth = img.naturalWidth;
    imgObj.naturalHeight = img.naturalHeight;
    imgObj.ratio = imgObj.naturalWidth / imgObj.naturalHeight;
    this.setState({ imgObj }, _ => $(window).trigger("resize"));
  };

  onResizeWindow = evt => {
    let imgObj = this.state.imgObj;
    const ticketMapWidth = $(".ticketMap").width();
    const ticketMapHeight = ticketMapWidth / this.state.imgObj.ratio;
    if (!imgObj.isInit) {
      imgObj.isInit = true;
    }
    imgObj.ticketMapHeight = ticketMapHeight;
    this.setState({ imgObj });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <SubHeadAppViewComponent />
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        imgObj={this.state.imgObj}
        imgUrlObj={this.state.imgUrlObj}
        onClickBtnTicketReservation={this.onClickBtnTicketReservation}
      />
      {this.state.imgUrlObj ? (
        <div style={{ display: "none" }}>
          <img src={this.state.imgUrlObj.stadium} onLoad={this.onLoadImg} />
        </div>
      ) : null}
    </div>
  );
}

export default StadiumGuideContainer;
