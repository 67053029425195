import React from "react";
import SubMenuComponent from "../common/SubMenu.component";

const ContComponent = props => (
  <div className="mb_cont sub_cont tigers">
    <div className="mb_contIn emblem">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      {/* <!-- //tabWrap --> */}
      <article>
        <h5 className="lineRed">엠블럼</h5>
        <div className="tb imgBox">
          <img src="/img/sub/emblem01_01.png" alt="엠블럼 모바일" />
        </div>
        <p>
          KIA타이거즈의 엠블럼은 워드마크와 함께 구단명칭을 표현한 것으로 각종
          매체에 적용되어 대외적인 커뮤니케이션을 보다 친밀감 있게 연결해 주는
          역할을 한다.
          <br />
          야구장의 홈베이스와 Tiger nose 형태를 결합하여 프레임 화 한 형태의
          엠블럼은 승리를 나타내는 V모티프를 통해 전통 명문구단의 대표성과
          위상을 표현하고 다이아몬드의 강인한 속성을 은유적으로 표현하였다.
        </p>
        <div className="pc imgBox">
          <img src="/img/sub/emblem01_01.png" alt="엠블럼 pc" />
        </div>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/emblem/emblem.jpg",
                "emblem.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
      <article>
        <h5 className="lineRed">타이거 심볼</h5>
        <ul className="tb imgBox2">
          <li>
            <img src="/img/sub/emblem02_01.png" alt="타이거 심볼 모바일" />
          </li>
          <li>
            <img src="/img/sub/emblem02_02.png" alt="타이거 심볼 모바일" />
          </li>
        </ul>
        <p>
          KIA 타이거즈의 타이거 심볼은 구단의 상징 동물인 호랑이를 활용하여 강인하고 용맹스러운 구단의 모습을 구체적으로 표현하였다.<br/>프로야구단의 용맹한 이미지를 효과적으로 전달하기 위하여 한국 전통 호랑이의 인상과 호랑이 머리의 형태를 반영하여 제작되었다.<br/>이마 무늬의 VICTORY 조형 표현은 승리하는 명문구단의 도전정신을 상징한다.
          <br />
        </p>
        <ul className="pc imgBox2">
          <li>
            <img src="/img/sub/emblem02_01.png" alt="타이거 심볼pc" />
          </li>
          <li>
            <img src="/img/sub/emblem02_02.png" alt="타이거 심볼pc" />
          </li>
        </ul>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/emblem/tiger-emblem.jpg",
                "tiger-emblem.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
      {/*<article>*/}
      {/*  <h5 className="lineRed">마스코트 엠블럼</h5>*/}
      {/*  <ul className="tb imgBox2">*/}
      {/*    <li>*/}
      {/*      <img src="/img/sub/emblem03_01.png" alt="마스코트 엠블럼 모바일" />*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <img src="/img/sub/emblem03_02.png" alt="마스코트 엠블럼 모바일" />*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <p>*/}
      {/*    KIA타이거즈의 마스코트는 구단의 상징동물인 호랑이를 귀엽고 친근한*/}
      {/*    이미지로 의인화 한 것으로 구단과 팬사이의 커뮤니케이션을 쉽고*/}
      {/*    친밀감있게 연결해 주는 역할을 한다.*/}
      {/*    <br />*/}
      {/*    타이거 심볼이 구단의 강인한 이미지를 대표한다면 마스코트는 주로*/}
      {/*    팬서비스 응용이나 포로모션용으로 다양하게 활용되어 팬에게 부드럽게*/}
      {/*    접근할 수 있는 이미지를 제공한다.*/}
      {/*  </p>*/}
      {/*  <ul className="pc imgBox2">*/}
      {/*    <li>*/}
      {/*      <img src="/img/sub/emblem03_01.png" alt="마스코트 엠블럼 pc" />*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <img src="/img/sub/emblem03_02.png" alt="마스코트 엠블럼 pc" />*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <p className="alignR pc">*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="btnDown"*/}
      {/*      onClick={evt =>*/}
      {/*        props.onClickBtnDownload(*/}
      {/*          "/img/download/emblem/mascot-emblem.zip",*/}
      {/*          "mascot-emblem.zip"*/}
      {/*        )*/}
      {/*      }*/}
      {/*    >*/}
      {/*      다운로드 JPG <span></span>*/}
      {/*    </button>*/}
      {/*  </p>*/}
      {/*</article>*/}
      <article>
        <h5 className="lineRed">10주년 기념 엠블럼 </h5>
        <div className="imgBox">
          <img src="/img/sub/emblem04_01.png" alt="10주년 기념 엠블럼" />
        </div>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/emblem/10th-logo.jpg",
                "10th-logo.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
        <h5 className="lineRed">챔피언스필드 개장 10주년 기념 엠블럼</h5>
        <div className="imgBox">
          <img src="/img/sub/kia_anniversary_240411.png" alt="10주년 기념 엠블럼" />
        </div>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/emblem/kia_anniversary_240411.png",
                "10th-logo-2024.jpg"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
      <article>
        <h5 className="lineRed">우승 기념 엠블럼 </h5>
        <ul className="imgBox3">
          <li>
            <img src="/img/sub/emblem05_01.png" alt="우승 기념 엠블럼" />
            <strong>1983년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_02.png" alt="우승 기념 엠블럼" />
            <strong>1986년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_03.png" alt="우승 기념 엠블럼" />
            <strong>1987년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_04.png" alt="우승 기념 엠블럼" />
            <strong>1988년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_05.png" alt="우승 기념 엠블럼" />
            <strong>1989년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_06.png" alt="우승 기념 엠블럼" />
            <strong>1991년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_07.png" alt="우승 기념 엠블럼" />
            <strong>1993년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_08.png" alt="우승 기념 엠블럼" />
            <strong>1996년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_09.jpg" alt="우승 기념 엠블럼" />
            <strong>1997년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_10.png" alt="우승 기념 엠블럼" />
            <strong>2009년</strong>
          </li>
          <li>
            <img src="/img/sub/emblem05_11.png" alt="우승 기념 엠블럼" />
            <strong>2017년</strong>
          </li>
        </ul>
        <p className="alignR pc">
          <button
            type="button"
            className="btnDown"
            onClick={evt =>
              props.onClickBtnDownload(
                "/img/download/emblem/kia-v-emblem.zip",
                "kia-v-emblem.zip"
              )
            }
          >
            다운로드 JPG <span></span>
          </button>
        </p>
      </article>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
