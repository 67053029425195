import React, {Component} from "react"
import SubHeadComponent from "../common/SubHead.component"
import ContComponent from "./Cont.component"

import * as DateHelper from "../../../helpers/Date.helper"
import * as ArticleService from "../../../services/Article.service"
import * as GameService from "../../../services/Game.service"
import * as ImageHelper from "../../../helpers/Image.helper"
import * as PlayerHelper from "../../../helpers/Player.helper"
import * as CommonHelper from "../../../helpers/Common.helper"

import * as CommonService from "../../../services/Common.service"

import $ from "jquery"
import AnalyticsContext from "../../../context/AnalyticsContext"

class ScheduleContainer extends Component {
  static contextType = AnalyticsContext

  constructor(props) {
    super(props)

    this.state = {
      subMenuName: "Tigers Baseball",
      isInit: false,
      isEndGame: false,

      currentGameInfo: null,
      scoreBoard: null,

      boxScoreResult: null,

      otherTeamInfoList: null,
      homeEntryPitcherInfo: null,
      awayEntryPitcherInfo: null,

      expiredEntryStatusListObj: null,

      homeTeamName: "",
      awayTeamName: "",

      homePitcherRecordList: null,
      awayPitcherRecordList: null,
      homeHitterRecordList: null,
      homeHitterTotalRecord: null,
      awayHitterRecordList: null,
      awayHitterTotalRecord: null,

      hitterTotalRecordColSpan: 18,
      lastInning: 15,
      colWidth: "3.9%"
    }
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl
    this.context.action(currentAnalyticsVal)

    currentAnalyticsVal.deps2 = "04"
    currentAnalyticsVal.deps3 = "02"
    currentAnalyticsVal.prevUrl = prevUrl
    currentAnalyticsVal.currentUrl = window.location.href

    await CommonService.insertAnalytics(this.context.state)

    $(window).resize(this.onResizeWindow).trigger("resize")
    this.updateInfo("major")
  }

  updateInfo = async (type) => {
    try {
      if (process.env.REACT_APP_PREPARING_GAME_PAGE_YN === "N") {
        // 게임 정보
        let currentGameInfo
        // 가장 최근 종료시합 정보
        let lastGameInfo

        // 스코어 보드 정보
        let scoreBoard = []

        // 홈 팀, 원정 팀 명
        let homeTeamName = ""
        let homeEmblemImgUrl = ""
        let awayTeamName = ""
        let awayEmblemImgUrl = ""

        // 다른팀 경기 정보
        let otherTeamInfoList = null

        // 선발 투수 정보 홈 / 원정
        let homeEntryPitcherInfo = null
        let awayEntryPitcherInfo = null

        // 등록 말소 현황목록 객체
        let expiredEntryStatusListObj = {}
        // 뉴스
        let newsList = []
        // 화보
        let picturesList = []

        let boxScoreResult = []
        let isEndGame = false
        let currentScheduleInfo = []

        let gameYear = null
        let gameMonth = null
        let gameDay = null

        let homePitcherRecordList = []
        let awayPitcherRecordList = []
        let homeHitterRecordList = []
        let homeHitterTotalRecord = null
        let awayHitterRecordList = []
        let awayHitterTotalRecord = null

        let lastInning = null
        let colWidth = null

        let currentGameInfoResult = null
        currentGameInfoResult = await GameService.getMajorCurrentGameInfo()
        currentGameInfo = currentGameInfoResult.currentgame
        lastGameInfo = currentGameInfoResult.lastGame

        let today = new Date()
        let year = today.getFullYear()
        let month = today.getMonth() + 1
        let day = today.getDate()
        if (("" + month).length === 1) {
          month = "0" + month
        }
        if (("" + day).length === 1) {
          day = "0" + day
        }
        let todayDate = year.toString() + month.toString() + day.toString()

        let hour = today.getHours()

        // 경기가 없는 날이거나, 경기있는날 오전10시 이전인 경우 이전시합의 경기결과를 보여준다
        if (Number(todayDate) !== currentGameInfo.gameDate || hour < 10) {
          console.log(today)
          console.log(todayDate)
          console.log(currentGameInfo)

          if(!Object.keys(lastGameInfo).length === 0){ //lastGameInfo가 객체가 빈값이 아닐 때만 지난경기 가져오기
            const gameKey = lastGameInfo.GMKEY
            const gameDate = lastGameInfo.GAME_DATE 
            this.props.history.replace(`/game/schedule/view?type=major&gameKey=${gameKey}&gameDate=${gameDate}`)
            return false;
          }
          
        } else if (currentGameInfo.status === "3" || currentGameInfo.status === 3) {
          console.log(currentGameInfo)
          const gameKey = currentGameInfo.gmkey
          const gameDate = currentGameInfo.gameDate
          this.props.history.replace(`/game/schedule/view?type=major&gameKey=${gameKey}&gameDate=${gameDate}`)
          return false
        }

        // 다른 팀 경기 정보
        otherTeamInfoList = currentGameInfoResult.otherteamlist
        for (let i = 0; i < otherTeamInfoList.length; i++) {
          otherTeamInfoList[i]["homeEmblemImgUrl"] = ImageHelper.getEmblemImgByTeamCode(otherTeamInfoList[i].homeKey)
          otherTeamInfoList[i]["awayEmblemImgUrl"] = ImageHelper.getEmblemImgByTeamCode(otherTeamInfoList[i].visitKey)
        }

        // 홈 선발투수 정보
        homeEntryPitcherInfo = currentGameInfoResult.hstartpitcher
        homeEntryPitcherInfo["pitcherName"] = currentGameInfo.hname
        // 원정 선발투수 정보
        awayEntryPitcherInfo = currentGameInfoResult.vstartpitcher
        awayEntryPitcherInfo["pitcherName"] = currentGameInfo.vname

        let innigscorelist = currentGameInfoResult.inningScoreList

        if (currentGameInfo["status"] === 1 || currentGameInfo["status"] === 4) {
          scoreBoard = [
            {
              score1: "-",
              score2: "-",
              score3: "-",
              score4: "-",
              score5: "-",
              score6: "-",
              score7: "-",
              score8: "-",
              score9: "-",
              score10: "-",
              score11: "-",
              score12: "-",
              run: "0",
              hit: "0",
              error: "0",
              bhome: 3
            },
            {
              score1: "-",
              score2: "-",
              score3: "-",
              score4: "-",
              score5: "-",
              score6: "-",
              score7: "-",
              score8: "-",
              score9: "-",
              score10: "-",
              score11: "-",
              score12: "-",
              run: "0",
              hit: "0",
              error: "0"
            }
          ]
        } else if (innigscorelist.length === 2 && innigscorelist[0].bhome === 3 && innigscorelist[1].bhome !== null) {
          scoreBoard[0] = innigscorelist[1]
          scoreBoard[1] = innigscorelist[0]
        } else {
          console.log("ELSE",innigscorelist )
          for (let i = 0; i < innigscorelist.length; i++) {
            Object.keys(innigscorelist[i]).map((key) => (innigscorelist[i][key] = innigscorelist[i][key] !== "-" ? innigscorelist[i][key] : ""))
          }
          scoreBoard = innigscorelist
        }

        // 게임 키와 게임 날짜 설정()
        currentGameInfo["formattedGameDate"] = DateHelper.makeSeparatedDate(currentGameInfo["gameDate"] + "")
        let gameKey = currentGameInfo.gmkey
        let gameDate = currentGameInfo.gameDate + ""

        homeTeamName = currentGameInfo.home
        awayTeamName = currentGameInfo.visit
        homeEmblemImgUrl = ImageHelper.getEmblemImgByTeamCode(currentGameInfo.homeKey)
        awayEmblemImgUrl = ImageHelper.getEmblemImgByTeamCode(currentGameInfo.visitKey)

        // 이전 경기정보가 아닐 경우
        gameYear = gameDate.substr(0, 4)
        gameMonth = gameDate.substr(4, 2)
        gameDay = gameDate.substr(6, 2)

        // 등록말소 현황
        let expiredEntryStatusResult = await GameService.getExpiredEntryStatus(gameYear, gameMonth, gameDay)
        expiredEntryStatusListObj = PlayerHelper.makeExpiredEntryObj(expiredEntryStatusResult.inOutList)

        // 타이거즈 뉴스
        const newsListParams = {
          "search.pos": 0,
          "search.max": 3
        }
        const newsListResult = await ArticleService.getNewsArticleList(newsListParams)
        newsList = newsListResult.list
        for (let i = 0; i < newsList.length; i++) {
          newsList[i]["regDate"] = DateHelper.convertTimestampToDate(newsList[i]["regDttm"])
        }
        // 생생화보
        const picturesListParams = {
          "search.pos": 0,
          "search.max": 2,
          "article.searchYear": process.env.REACT_APP_SEASON_YEAR
        }

        const picturesListResult = await ArticleService.getPicturesList(picturesListParams)

        picturesList = picturesListResult.list
        for (let i = 0; i < picturesList.length; i++) {
          picturesList[i]["regDate"] = DateHelper.convertTimestampToDate(picturesList[i]["regDttm"], ".")
        }

        this.setState(
          {
            isInit: true,

            currentGameInfo,

            // 스코어 보드 정보
            scoreBoard,

            // 홈 팀, 원정 팀 명
            homeTeamName,
            homeEmblemImgUrl,
            awayTeamName,
            awayEmblemImgUrl,

            // 다른팀 경기 정보
            otherTeamInfoList,

            // 선발 투수 정보 홈 / 원정
            homeEntryPitcherInfo,
            awayEntryPitcherInfo,

            // 등록 말소 현황목록 객체
            expiredEntryStatusListObj,
            // 뉴스
            newsList,
            // 화보
            picturesList,

            boxScoreResult,
            currentScheduleInfo,
            isEndGame,

            homePitcherRecordList,
            awayPitcherRecordList,
            homeHitterRecordList,
            homeHitterTotalRecord,
            awayHitterRecordList,
            awayHitterTotalRecord,
            lastInning,
            colWidth
          },
          (_) => {
            $(window).trigger("resize")
          }
        )
      }
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  componentWillUnmount = (_) => {
    $(window).unbind("resize")
  }

  onResizeWindow = (evt) => {
    const width = evt.target.innerWidth
    let hitterTotalRecordColSpan = "9"
    if (width > 780) {
      hitterTotalRecordColSpan = this.state.lastInning + 3
    } else {
      hitterTotalRecordColSpan = this.state.lastInning
    }
    this.setState({hitterTotalRecordColSpan})
  }

  onClickBtnPicture = (evt, articleId) => {
    const url = `/game/pictures/${articleId}`
    this.props.history.push(url)
  }

  render = () => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} thirdMenuName={this.state.thirdMenuName} />
      {process.env.REACT_APP_PREPARING_GAME_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont game msrView">
          <div className="mb_contIn">
            <img src="/img/sub/tigers_22_off_greeting_pc.png" alt="season_off_pc" className="pc" />
            <img
              src="/img/sub/tigers_22_off_greeting_mb.jpg"
              alt="season_off_mb"
              className="greeting_mb tb"
            />
          </div>
        </div>
      ) : this.state.isInit ? (
        <ContComponent
          isPrevInfo={this.state.isPrevInfo}
          isEndGame={this.state.isEndGame}
          currentGameInfo={this.state.currentGameInfo}
          boxScoreResult={this.state.boxScoreResult}
          otherTeamInfoList={this.state.otherTeamInfoList}
          homeTeamName={this.state.homeTeamName}
          awayTeamName={this.state.awayTeamName}
          homeEmblemImgUrl={this.state.homeEmblemImgUrl}
          awayEmblemImgUrl={this.state.awayEmblemImgUrl}
          lastInning={this.state.lastInning}
          colWidth={this.state.colWidth}
          hitterTotalRecordColSpan={this.state.hitterTotalRecordColSpan}
          scoreBoard={this.state.scoreBoard}
          expiredEntryStatusListObj={this.state.expiredEntryStatusListObj}
          newsList={this.state.newsList}
          picturesList={this.state.picturesList}
          onClickBtnPicture={this.onClickBtnPicture}
          homeEntryPitcherInfo={this.state.homeEntryPitcherInfo}
          awayEntryPitcherInfo={this.state.awayEntryPitcherInfo}
          homePitcherRecordList={this.state.homePitcherRecordList}
          awayPitcherRecordList={this.state.awayPitcherRecordList}
          homeHitterRecordList={this.state.homeHitterRecordList}
          homeHitterTotalRecord={this.state.homeHitterTotalRecord}
          awayHitterRecordList={this.state.awayHitterRecordList}
          awayHitterTotalRecord={this.state.awayHitterTotalRecord}
        />
      ) : null}
    </div>
  )
}

export default ScheduleContainer
