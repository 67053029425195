import React from 'react';
export const SeasonAllGameComponent = props => (
    (props.info.position2 === '투' ?
        <Pitcher {...props} />
        :
        <Hitter {...props} />)
)
const Pitcher = props => (
    <table>
        <caption>상세기록</caption>
        <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
        </colgroup>
        <thead>
            <tr>
                <th scope="col">일자</th>
                <th scope="col">구장</th>
                <th scope="col">상대</th>
                <th scope="col">DH</th>
                <th scope="col">승/패</th>
                <th scope="col">이닝</th>
                <th scope="col">투구</th>
                <th scope="col">타자</th>
                <th scope="col">타수</th>
                <th scope="col">안타</th>
                <th scope="col">홈런</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">폭투</th>
                <th scope="col">보크</th>
                <th scope="col">실점</th>
                <th scope="col">자책</th>
                <th scope="col">평균<br />자책점</th>
            </tr>
        </thead>
        <tbody>
            {props.seasonallgame.map((item, idx) =>
                <tr key={"seasonAllGame" + idx}>
                    <td>{item.displayDate}</td>
                    <td>{item.stadium}</td>
                    <td>{item.matchTeamName}</td>
                    <td>{'-'}</td>
                    <td>{item.wlsString}</td>
                    <td>{item.innDisplay}</td>
                    <td>{item.tugucount}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.hit}</td>
                    <td>{item.hr}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.wp}</td>
                    <td>{item.bk}</td>
                    <td>{item.r}</td>
                    <td>{item.er}</td>
                    <td>{item.era}</td>
                </tr>
            )}
            {props.seasonallgame.length === 0 ?
                <tr>
                    <td colSpan="19">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        {props.seasonallgameTotal ?
            <tfoot>
                <tr>
                    <td colSpan='7'>합계</td>
                    <td>{props.seasonallgameTotal.pa}</td>
                    <td>{props.seasonallgameTotal.ab}</td>
                    <td>{props.seasonallgameTotal.hit}</td>
                    <td>{props.seasonallgameTotal.hr}</td>
                    <td>{props.seasonallgameTotal.bb}</td>
                    <td>{props.seasonallgameTotal.hp}</td>
                    <td>{props.seasonallgameTotal.kk}</td>
                    <td>{props.seasonallgameTotal.wp}</td>
                    <td>{props.seasonallgameTotal.bk}</td>
                    <td>{props.seasonallgameTotal.r}</td>
                    <td>{props.seasonallgameTotal.er}</td>
                    <td>{props.seasonallgameTotal.era}</td>
                </tr>
            </tfoot>
            : null}
    </table>
)
const Hitter = props => (
    <table>
        <caption>상세기록</caption>
        <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
        </colgroup>
        <thead>
            <tr>
                <th scope="col">일자</th>
                <th scope="col">구장</th>
                <th scope="col">상대</th>
                <th scope="col">DH</th>
                <th scope="col">타석</th>
                <th scope="col">타수</th>
                <th scope="col">득점</th>
                <th scope="col">안타</th>
                <th scope="col">2타</th>
                <th scope="col">3타</th>
                <th scope="col">홈런</th>
                <th scope="col">타점</th>
                <th scope="col">도루</th>
                <th scope="col">희타</th>
                <th scope="col">희비</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">병살</th>
                <th scope="col">타율</th>
            </tr>
        </thead>
        <tbody>
            {props.seasonallgame.map((item, idx) =>
                <tr>
                    <td>{item.displayDate}</td>
                    <td>{item.stadium}</td>
                    <td>{item.matchTeamName}</td>
                    <td>{'-'}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.run}</td>
                    <td>{item.hit}</td>
                    <td>{item.h2}</td>
                    <td>{item.h3}</td>
                    <td>{item.hr}</td>
                    <td>{item.rbi}</td>
                    <td>{item.sb}</td>
                    <td>{item.sh}</td>
                    <td>{item.sf}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.gd}</td>
                    <td>{item.hra}</td>
                </tr>
            )}
            {props.seasonallgame.length === 0 ?
                <tr>
                    <td colSpan="20">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        {props.seasonallgameTotal ?
            <tfoot>
                <tr>
                    <td colSpan='4'>합계</td>
                    <td>{props.seasonallgameTotal.pa}</td>
                    <td>{props.seasonallgameTotal.ab}</td>
                    <td>{props.seasonallgameTotal.run}</td>
                    <td>{props.seasonallgameTotal.hit}</td>
                    <td>{props.seasonallgameTotal.h2}</td>
                    <td>{props.seasonallgameTotal.h3}</td>
                    <td>{props.seasonallgameTotal.hr}</td>
                    <td>{props.seasonallgameTotal.rbi}</td>
                    <td>{props.seasonallgameTotal.sb}</td>
                    <td>{props.seasonallgameTotal.sh}</td>
                    <td>{props.seasonallgameTotal.sf}</td>
                    <td>{props.seasonallgameTotal.bb}</td>
                    <td>{props.seasonallgameTotal.hp}</td>
                    <td>{props.seasonallgameTotal.kk}</td>
                    <td>{props.seasonallgameTotal.gd}</td>
                    <td>{props.seasonallgameTotal.gra}</td>
                </tr>
            </tfoot>
            : null}
    </table>
)
export default SeasonAllGameComponent;
