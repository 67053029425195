import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MajorContainer from './major/Major.container';
import MinorContainer from './minor/Minor.container';
import InfoContainer from './info/Info.container';


import * as DateHelper from '../../../helpers/Date.helper';

class ScheduleContainer extends Component {
    currentYear = '';
    currentMonth = '';
    constructor(props) {
        super(props);


        this.currentYear = DateHelper.getCurrentYear();
        this.currentMonth = DateHelper.getCurrentMonth();

        // console.log(this.props.match.path);
    }
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/view`} component={InfoContainer} />
            <Route path={`${this.props.match.path}/major/:yearmonth`} component={MajorContainer} />
            <Route path={`${this.props.match.path}/minor/:yearmonth`} component={MinorContainer} />
            <Redirect from={`${this.props.match.path}/major`} to={`${this.props.match.path}/major/${this.currentYear}${this.currentMonth}`} />
            <Redirect from={`${this.props.match.path}/minor`} to={`${this.props.match.path}/minor/${this.currentYear}${this.currentMonth}`} />
            <Redirect from={`${this.props.match.path}`} to={`${this.props.match.path}/major/${this.currentYear}${this.currentMonth}`} />
            {/* <Redirect from="{`${this.props.match.path}`}" to={`${this.props.match.path}/major/${this.currentYear}${this.currentMonth}`} /> */}
        </Switch>
    )
}

export default ScheduleContainer;