import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PressContainer from './press/Press.container';
import NoticeContainer from './notice/Notice.container';
import NewsContainer from './news/News.container';
import WebzineContainer from './webzine/Webzine.container';
import EpisodeContainer from './episode/Episode.container';
import TvContainer from './tv/Tv.container';
import SnsContainer from './sns/Sns.container';
import ArchiveContainer from './archive/Archive.container';
class ContentsContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/press`} component={PressContainer} />
            <Route path={`${this.props.match.path}/notice`} component={NoticeContainer} />
            <Route path={`${this.props.match.path}/news`} component={NewsContainer} />
            <Route path={`${this.props.match.path}/webzine`} component={WebzineContainer} />
            <Route path={`${this.props.match.path}/episode`} component={EpisodeContainer} />
            <Route path={`${this.props.match.path}/tv`} component={TvContainer} />
            <Route path={`${this.props.match.path}/sns`} component={SnsContainer} />
            <Route path={`${this.props.match.path}/archive`} component={ArchiveContainer} />
        </Switch>
    )
}

export default ContentsContainer;