import * as DeviceDetect from "react-device-detect";

export const getTeamCodeList = _ => {
  // 오비, 키움, SK, LG, NC, KT, 기아, 삼성, 한화, 롯데
  return ["OB", "WO", "SK", "LG", "NC", "KT", "HT", "SS", "HH", "LT"];
};

export const getTeamCodeByName = teamName => {
  switch (teamName) {
    case "오비":
      return "OB";
    case "키움":
      return "WO";
    case "SK":
      return "SK";
    case "LG":
      return "LG";
    case "NC":
      return "NC";
    case "KT":
      return "KT";
    case "기아":
      return "HT";
    case "삼성":
      return "SS";
    case "한화":
      return "HH";
    case "롯데":
      return "LT";
    default:
      return "";
  }
};

export const getCellphoneNum1List = _ => {
  return ["010", "011", "016", "017", "018", "019"];
};

export const cloneObj = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const checkIsEmptyObj = obj => {
  if (!obj) {
    return true;
  } else if (Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

export const checkIsOnWebview = _ =>
  DeviceDetect.getUA.includes("TigersWebView");
// export const checkIsOnWebview = _ => {
//     if (DeviceDetect.getUA.includes('tigersApp') >= 0) {
//         return true;
//     } else if (DeviceDetect.isAndroid || DeviceDetect.isIOS) {
//         if (DeviceDetect.isChrome || DeviceDetect.isChromium
//             || DeviceDetect.isSafari || DeviceDetect.isMobileSafari
//             || DeviceDetect.isIE || DeviceDetect.isEdge
//             || DeviceDetect.isOpera || DeviceDetect.isFirefox) {
//             return false;
//         } else {
//             return true;
//         }
//     } else {
//         return false;
//     }
// }

export const checkIsOnMobile = _ => DeviceDetect.isMobile;

export const getBoardNameByCategoryCode = categoryCode => {
  switch (categoryCode) {
    case 1:
      return "경기";
    case 2:
      return "선수";
    case 3:
      return "회원";
    case 4:
      return "홈페이지";
    case 5:
      return "이벤트";
    case 6:
      return "상품구매";
    case 7:
      return "입장권";
    case 8:
      return "기타";
    case 9:
      return "공식파트너";
    case 10:
      return "프리미엄 스폰서";
    case 11:
      return "오피셜 스폰서";
    case 12:
      return "제휴카드";
    case 13:
      return "지정병원";
    case 14:
      return "공식 공급업체";
    case 15:
      return "TV";
    default:
      return 0;
  }
};
export const getBoardNameByBoardCode = boardCode => {
  switch (boardCode) {
    case "hosabang":
      return "호랑이 사랑방";
    case "press_release":
      return "보도자료";
    case "notice":
      return "공지사항";
    case "news":
      return "타이거즈 뉴스";
    case "episode":
      return "타이거즈 에피소드";
    case "tv":
      return "타이거즈TV";
    // case "photo":
    //   return "생생화보";
    case "photo":
      return "타이거즈 포토";
    case "sponsor":
      return "스폰서";
    case "faq":
      return "FAQ";
    default:
      return "";
  }
};
export const getUriByCategoryCode = categoryCode => {
  switch (categoryCode) {
    case "hosabang":
      return "/membership/tigers";
    case "press_release":
      return "/contents/press";
    case "notice":
      return "/contents/notice";
    case "news":
      return "/contents/news";
    case "episode":
      return "/contents/episode";
    case "tv":
      return "/contents/tv";
    case "photo":
      return "/game/pictures";
    case "sponsor":
      return "/contents/sns";
    case "faq":
      return "/membership/faq";
    default:
      return "/";
  }
};
export const getLastInning = scoreboard => {
  if (scoreboard.length === 0) {
    return 0;
  } else if (!scoreboard[0].score10) {
    return 12;
  } else if (scoreboard[0].score10 === "-") {
    return 12;
  } else if (scoreboard[0].score11 === "-") {
    return 12;
  } else if (scoreboard[0].score12 === "-") {
    return 12;
  } else if (scoreboard[0]["score12"]) {
    return 12;
  }
};
