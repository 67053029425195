import React from 'react';
import './App.css';

import SceneContainer from './Scene.container';
import {AnalyticsProvider} from "./context/AnalyticsContext";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000
    }
  }
})

function App() {

  return (
    <AnalyticsProvider>
      <QueryClientProvider client={queryClient}>
        <SceneContainer/>
      </QueryClientProvider>
    </AnalyticsProvider>
  );
}

export default App;
