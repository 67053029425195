import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'
import * as AuthHelper from '../../../helpers/Auth.helper'
import * as TicketService from "../../../services/Ticket.service";
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { reserveList } from '../../../services/Ticket.service'

dayjs.locale('ko')

const kboTeamList = {
  63: "/img/ticket/emblem/hanwha.png",
  58: "/img/ticket/emblem/kia.png",
  62: "/img/ticket/emblem/kt.png",
  59: "/img/ticket/emblem/lg.png",
  60: "/img/ticket/emblem/lotte.png",
  64: "/img/ticket/emblem/nc.png",
  55: "/img/ticket/emblem/doosan.png",
  476: "/img/ticket/emblem/ssg.png",
  57: "/img/ticket/emblem/samsung.png",
  61: "/img/ticket/emblem/kiwoom.png"
}

class ListContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    this.state = {
      subMenu: "guide",
      subMenuName: "포인트 안내",
      imgUrlObj: null,
      tabNum: 1,
      ticketList: []
    };
  }

  componentDidMount = async (_) => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/list';
    } else {
      this.setState({ isInit: true });
    }

    const result = await CommonService.getEncryptedId();
    const encryptedStr = result.encryptedStr;
    console.log(encryptedStr);
    // param.startDate = 어제, param.endDate = 1달 뒤
    const param = {
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      encryptedId: decodeURIComponent(encryptedStr)
    }

    const ticketList = await TicketService.reserveList(param);

    const issueTicket = [];

    console.log(ticketList)
    if(ticketList !== null) {
      ticketList.map( (reserve) => {
        if (reserve.reserveStateCode !== "ALL_CANCEL") {
          if (reserve.playDate === dayjs().format( 'YYYYMMDD' )) {


            const tickets = reserve.seatDetails;
            tickets.map( (ticket) => {
              console.log( "TICKET", ticket )
              if (ticket.issueStateCode === "ISSUE" && ticket.issueClassCode === "API" && ticket.cancelYn === "N") {
                const _tmp = {
                  scheduleName: reserve.scheduleName,
                  reserveNo: reserve.reserveNo,
                  playDate: reserve.playDate,
                  playTime: reserve.playTime,
                  playDateTime: dayjs( reserve.playDate + reserve.playTime, 'YYYY-MM-DDHH:mm' ).format( 'MM월DD일(ddd) HH:mm' ),
                  leagueName: reserve.leagueName,
                  productGradeName: ticket.productGradeName,
                  denominationName: ticket.denominationName,
                  seatInfo: ticket.seatInfo,
                  ticketNo: "T" + ticket.ticketNo,
                  awayTeamId: reserve.awayTeamId,
                  awayTeamSrc: kboTeamList[reserve.awayTeamId],
                }
                issueTicket.push( _tmp );
              }
            } );
          }
        }
      } )
    }

    console.log(issueTicket);



    //230130 주석
    this.setState({ ticketList: issueTicket });
  };



  render = (_) => (
    <div className="container">
      {/*<SubHeadComponent subMenuName={this.state.subMenuName} />*/}

      {/* 230127 주석 */}
      {/*{ !CommonHelper.checkIsOnWebview() && (*/}
      {/*  <GuideTabComponent*/}
      {/*    onClickBtnChangeMembership={this.onClickBtnChangeMembership}*/}
      {/*    tabNum={this.state.tabNum}*/}
      {/*  />*/}
      {/*)}*/}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        ticketList={this.state.ticketList}

      /> }
    </div>
  );
}

export default ListContainer;
