import React from "react";
import styled from 'styled-components';
import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const StyleUl = styled.ul`
  background: #f8f8fa;
`

const StyleLiFirst = styled.li`
  border-right: 0 !important;
`
const StyleLiSecond = styled.li`
  border-left: 1px solid #e4e5e6;
  background: #ffffff;
`

const StyleLabel = styled.label`
  font-size: min(3vw, 15px);
`

const StyleLabel2 = styled.label`
  font-size: min(3vw, 15px);
  //font-weight: bold;
  color: #ea0029;
`

const StrongStyleP = styled.p`
  //font-weight: bold;
  color: #ea0029;
  position: inherit !important;
  top: 0 !important;
  right: 0 !important; 
`

const StrongStyleSpan = styled.span`
  //font-weight: bold;
  color: #ea0029;
`


const checkParticle = (text) => {
  const charCode = text.charCodeAt(text.length - 1);
  //유니코드의 한글 범위 내에서 해당 코드의 받침 확인
  const consonantCode = (charCode - 44032) % 28;

  if(consonantCode === 0){
    return `${text}를`;
  }
  return `${text}을`;
}

const InputField = ({field}) => {
  const particle = checkParticle(field.fieldName);
  const placeHolder = `${particle} 입력해주세요`
  return (
    <>
      <StyleLiFirst><StyleLabel htmlFor={field.key}>{field.fieldName}</StyleLabel></StyleLiFirst>
      <StyleLiSecond className="tit">
        <input type="text" id={field.key}
               placeholder={placeHolder}
               onChange={field.change}
               name={field.key}
        />
      </StyleLiSecond>
    </>
  )
}

const InputFieldList = ({list}) => {
  return (
    <>

    {list.map(field => (
      <StyleUl key={field.key}>
        <InputField field={field} />
      </StyleUl>
    ))}

    </>
  )
}

const ContComponent = (props) => (
  <div className="mb_cont membership sub_cont ">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">&nbsp;</h6>
      <img src={props.eventInfo.thumnail_pic} style={{margin: "10px 0 30px 0", maxWidth: "100%"}}/>

      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="user_id">아이디</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" id="user_id"
                     value={decodeAES256(props.userId)}
                     readOnly={true}
                     disabled={true}
              />
            </StyleLiSecond>
          </StyleUl>
          <StyleUl>
            <StyleLiFirst><StyleLabel htmlFor="user_name">이름</StyleLabel></StyleLiFirst>
            <StyleLiSecond className="tit">
              <input type="text" id="user_name"
                     value={decodeAES256(props.userName)}
                     readOnly={true}
                     disabled={true}
              />
            </StyleLiSecond>
          </StyleUl>
          <InputFieldList list={props.inputField}/>
          <ul>

            <StyleUl>
              <StyleLiFirst></StyleLiFirst>
              <StyleLiSecond className="tit">
                <StyleLabel htmlFor="target_confirm"
                            dangerouslySetInnerHTML={{__html: props.eventInfo.data_terms_content}}></StyleLabel>
                <div className="agree">
                  <input type={"radio"} id={"confirm_y"} name={"confirm"} value={"Y"} onChange={props.onChangeInputConfirm} style={{width: 'inherit', marginRight: '5px'}}/>
                  <StyleLabel htmlFor={"confirm_y"}>예</StyleLabel>
                  <input type={"radio"} id={"confirm_n"} name={"confirm"} value={"N"} onChange={props.onChangeInputConfirm} style={{width: 'inherit', marginLeft: '20px', marginRight: '5px'}}/>
                  <StyleLabel htmlFor={"confirm_n"}>아니요</StyleLabel>

                  {/*<input type={"checkbox"} id={"confirm_y"} name={"confirm"} value={"Y"}*/}
                  {/*       // onChange={props.onChangeInputConfirm} style={{width: 'inherit', marginRight: '5px'}}/>*/}
                  {/*<StyleLabel htmlFor={"confirm_y"}>확인했습니다.</StyleLabel>*/}
                </div>
              </StyleLiSecond>
            </StyleUl>
          </ul>
        </fieldset>

      </form>
      <div className="inQBtn clear">

        <button onClick={props.onClickBtnRequest} type="button" style={{
          left: "50%",
          position: "relative",
          marginLeft: "-25%"
        }}><em className="myQ"></em>응모하기
        </button>

      </div>

    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
