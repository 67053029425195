import React from "react";
import DaumPostCode from "react-daum-postcode";
import SecessionPopupComponent from "./SecessionPopup.component";

const postCodePopupStyle = {
  width: "300px",
  height: "400px",
  left:
    ((window.innerWidth || document.documentElement.clientWidth) - 300) / 2 -
    5 +
    "px",
  top:
    ((window.innerHeight || document.documentElement.clientHeight) - 400) / 2 -
    5 +
    "px",
  border: "5px solid",
  position: "fixed",
  overflow: "hidden",
  zIndex: 1,
  display: "block",
};

const ContComponent = (props) => (
  <div className="mb_cont join_step2 mypg">
    <div className="mb_contIn">
      <form action="/" method="post">
        <fieldset>
          <legend>기본정보</legend>
          <h4>
            <strong
              className={props.myPageTabOptions.status === 1 && "on"}
              onClick={(evt) => {
                props.onClickChangeTab(evt, 1);
              }}
            >
              내 정보 관리
            </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <strong
              className={props.myPageTabOptions.status === 2 && "on"}
              onClick={(evt) => {
                props.onClickChangeTab(evt, 2);
              }}
            >
              블라인드리스트
            </strong>
            {props.myPageTabOptions.status === 1 && (
              <span>
                <em>*</em>는 필수입력 사항입니다.
              </span>
            )}
          </h4>
          {props.myPageTabOptions.status === 1 ? (
            <div className="stp2_box">
              <div>
                <label>
                  <strong>*</strong>아이디
                </label>
                <i>{props.userObj.simpleId}</i>
              </div>
              {props.isRealName ? (
                <div>
                  <label>
                    <strong>*</strong>이름
                  </label>
                  <i className="name">{props.userObj.memberName}</i>
                </div>
              ) : (
                <div>
                  <label>
                    <strong>*</strong>이름
                  </label>
                  <i className="name">{props.userObj.memberName}</i>
                  <p className="my2Btn">
                    <span onClick={props.onClickBtnOpenCellAuth}>
                      휴대폰(본인 명의)
                    </span>
                    <span onClick={props.onClickBtnOpenIpinAuth}>아이핀</span>
                  </p>
                  <em>
                    * KIA TIGERS 서비스 이용을 위해 휴대폰 및 아이핀을 이용하여
                    본인인증을 해주시기 바랍니다.
                  </em>
                </div>
              )}

              <div>
                <label>
                  <strong>*</strong>회원등급
                </label>
                <i>{props.userObj.memberDivDispNm}</i>
              </div>
              <div>
                <label>
                  <strong>*</strong>멤버십 번호
                </label>
                <i>{props.userObj.mobileCardNo}</i>
              </div>
              <div className="cell">
                <label htmlFor="cell">
                  <strong>*</strong>휴대폰 번호
                </label>
                <input
                  type="text"
                  id="cell"
                  maxLength="3"
                  value={props.mobpAuthObj.mobpNo1}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "mobpAuthObj", "mobpNo1")
                  }
                  disabled={!props.isRealName}
                />
                <span>-</span>
                <input
                  type="text"
                  title="휴대전화 앞자리 번호"
                  maxLength="4"
                  value={props.mobpAuthObj.mobpNo2}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "mobpAuthObj", "mobpNo2")
                  }
                  disabled={!props.isRealName}
                />
                <span>-</span>
                <input
                  type="text"
                  title="휴대전화 뒷자리 번호"
                  maxLength="4"
                  value={props.mobpAuthObj.mobpNo3}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "mobpAuthObj", "mobpNo3")
                  }
                  disabled={!props.isRealName}
                />
                <span
                  onClick={props.onClickBtnRequestAuthNum}
                  className="chk_jnStep2"
                >
                  인증요청
                </span>
              </div>
              <div>
                <label htmlFor="cell2">
                  <strong>*</strong>인증 번호
                </label>
                <input
                  type="text"
                  id="cell2"
                  maxLength="6"
                  value={props.mobpAuthObj.certNo}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "mobpAuthObj", "certNo")
                  }
                  disabled={!props.isRealName}
                />
                <span
                  onClick={props.onClickBtnRequestAuth}
                  className="chk_jnStep2"
                >
                  인증확인
                </span>
                <em>* 인증번호 입력 후 ‘인증확인’ 버튼을 선택해주세요.</em>
              </div>
              <div>
                <label htmlFor="userAdrs"><strong>*</strong>주소</label>
                <input
                  type="text"
                  id="userAdrs"
                  value={props.userObj.addr1}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "userObj", "addr1")
                  }
                  disabled={!props.isRealName}
                />
                <span
                  className="chk_jnStep2"
                  onClick={props.onClickBtnShowAddressSearchPopup}
                >
                  주소찾기
                </span>
              </div>
              <div className="adress2">
                <label htmlFor="">
                  &nbsp;<span className="blind">추가주소</span>
                </label>
                <input
                  type="text"
                  title="추가주소"
                  value={props.userObj.addr2}
                  onChange={(evt) =>
                    props.onChangeInput(evt, "userObj", "addr2")
                  }
                  disabled={!props.isRealName}
                />
              </div>
              <div className="email">
                <div className="clear">
                  <p>
                    <label htmlFor="userEm"><strong style={{color: "#e0242b"}}>*</strong>이메일주소</label>
                    <input
                      type="text"
                      title="이메일 주소"
                      id="userEm"
                      value={props.userObj.memberEmailId}
                      onChange={(evt) =>
                        props.onChangeInput(evt, "userObj", "memberEmailId")
                      }
                      disabled={!props.isRealName}
                    />
                    <span>@</span>
                    <input
                      type="text"
                      title="이메일 도메인주소"
                      className="mbW2"
                      value={props.userObj.memberEmailDomain}
                      onChange={(evt) =>
                        props.onChangeInput(evt, "userObj", "memberEmailDomain")
                      }
                      disabled={!props.isRealName}
                    />
                  </p>
                  <p>
                    <label htmlFor="userEm2">
                      <span className="blind">도메인주소</span>
                    </label>
                    <select
                      id="userEm2"
                      defaultValue={props.userObj.emailDomainSelectValue}
                      onChange={(evt) =>
                        props.onChangeSelectList(
                          evt,
                          "userObj",
                          "emailDomainSelectValue"
                        )
                      }
                      disabled={!props.isRealName}
                    >
                      <option value="">직접입력</option>
                      <option value="gmail.com">gmail.com</option>
                      <option value="naver.com">naver.com</option>
                      <option value="hanmail.net">hanmail.net</option>
                    </select>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      id="agrEmail"
                      checked={props.userObj.emailYn === "Y"}
                      onChange={(evt) =>
                        props.onChangeCheckbox(evt, "userObj", "emailYn")
                      }
                      disabled={!props.isRealName}
                    />
                    <label htmlFor="agrEmail">이메일 수신 동의</label>
                  </p>
                </div>
                <em>
                  * 이메일은 추후 아이디/비밀번호를 찾기 시 사용되니,실제로
                  사용하시는 ‘이메일’을 기입해주세요.
                </em>
              </div>
              <div className="ad_sms_terms">
                <div className="clear">
                  <label htmlFor="agrSms"><strong style={{color: "#e0242b"}}>*</strong>마케팅 및 광고 활용 동의</label>
                  <p>
                    <input
                      type="checkbox"
                      id="agrSms"
                      checked={props.userObj.smsYn === "Y"}
                      onChange={(evt) =>
                        props.onChangeCheckbox(evt, "userObj", "smsYn")
                      }
                      disabled={!props.isRealName}
                      style={{margin: "5px 5px 0 20px"}}
                    />
                    <label htmlFor="agrSms">동의</label>
                  </p>

                </div>
                <div
                  style={{    padding: "15px 30px",
                    marginBottom: "15px",
                    height: "200px",
                    border: "2px solid #dfdfdf",
                    fontSize: "15px",
                    color: "#888",
                    lineHeight: "20px",
                    boxSizing: "border-box",
                    overflowY: "scroll"
                }}
                >
                  {props.userObj['ad_sms_terms'].split("\n").map((line, idx) => (
                    <span key={idx}>
                    {line}
                      <br/>
                    </span>
                  ))}
                </div>
              </div>
              <div className="repw">
                <p>
                  <label htmlFor="userPw">
                    <strong>*</strong>기존 비밀번호
                  </label>
                  <input
                    type="password"
                    id="userPw"
                    placeholder="8자리 이상(영문자, 숫자, 특수문자)"
                    value={props.userObj.memberPw}
                    onChange={(evt) =>
                      props.onChangeInput(evt, "userObj", "memberPw")
                    }
                    disabled={!props.isRealName}
                  />
                </p>
                <p>* 비밀번호 변경시에만 입력하세요.</p>
              </div>
              <div className="repw">
                <p>
                  <label htmlFor="userPw2">
                    <strong>*</strong>변경 비밀번호
                  </label>
                  <input
                    type="password"
                    id="userPw2"
                    placeholder="8자리 이상(영문자, 숫자, 특수문자)"
                    value={props.userObj.memberNewPw}
                    onChange={(evt) =>
                      props.onChangeInput(evt, "userObj", "memberNewPw")
                    }
                    disabled={!props.isRealName}
                  />
                </p>
                <p className="pw">* 비밀번호 변경시에만 입력하세요.</p>
              </div>
              <div className="repw">
                <p>
                  <label htmlFor="userPw3">
                    <strong>*</strong>변경 비밀번호 확인
                  </label>
                  <input
                    type="password"
                    id="userPw3"
                    placeholder="8자리 이상(영문자, 숫자, 특수문자)"
                    value={props.userObj.memberNewPwRe}
                    onChange={(evt) =>
                      props.onChangeInput(evt, "userObj", "memberNewPwRe")
                    }
                    disabled={!props.isRealName}
                  />
                </p>
                <p className="pw">* 비밀번호 변경시에만 입력하세요.</p>
              </div>
            </div>
          ) : (
            <div className="stp2_box">
              <table>
                <thead>
                <tr>
                  <td>NO</td>
                  <td>이름(아이디)</td>
                  <td>등록일</td>
                </tr>
                </thead>
                <tbody>
                {props.blindList.length !== 0 ? (
                  props.blindList.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.isChecked}
                          onChange={(e) => {
                            props.onChangeBlindChkBox(idx);
                          }}
                        />
                        {item.memberName}&nbsp;({item.blindSimpleId})
                      </td>
                      <td>{item.regDttm}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">블라인드 내역이 없습니다.</td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          )}
        </fieldset>
      </form>
      {props.myPageTabOptions.status === 1 ? (
        <div className="loginBtn_wrap">
          <div className="login_btn01">
            <ul className="login_btn01In">
              <li>
                <a onClick={props.onClickBtnModify}>수정</a>
              </li>
              <li>
                <a onClick={props.onClickBtnOpenSecessionPopup}>회원 탈퇴</a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="loginBtn_wrap">
          <div className="login_btn01">
            <ul className="login_btn01In">
              <li>
                <a
                  onClick={() => {
                    props.onClickBtnCheckAll();
                  }}
                >
                  전체선택
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    props.blindList.length !== 0 &&
                    props.onClickBtnDeleteBlind();
                  }}
                >
                  삭제
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
    <div
      style={{display: props.isOpenedAddressSearchPopup ? "block" : "none"}}
    >
      <div onClick={props.onClickBtnModalBg} className="modal-bg"></div>
      <div style={postCodePopupStyle}>
        <DaumPostCode
          onComplete={props.onCompleteAddress}
          width="100%"
          height="100%"
        />
      </div>
    </div>
    {props.secessionObj.isOpen ? (
      <SecessionPopupComponent
        secessionObj={props.secessionObj}
        onChangeInput={props.onChangeInput}
        onClickSecessionReason={props.onClickSecessionReason}
        onClickBtnSecession={props.onClickBtnSecession}
        onClickBtnClosePopup={props.onClickBtnClosePopup}
      />
    ) : null}
  </div>
);

export default ContComponent;
