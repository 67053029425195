import React, { Component } from 'react';
import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';

import * as ArticleService from '../../../services/Article.service';
import AnalyticsContext from "../../../context/AnalyticsContext"
import * as CommonService from "../../../services/Common.service"
import PrepareComponent from '../../layout/Prepare.component'
import * as ContentService from "../../../services/Content.service";

class SponsorContainer extends Component {
    static contextType = AnalyticsContext;

    constructor(props) {
        super(props);

        const subMenu = 'sponsor';
        const subMenuName = '스폰서'
        this.state = {
            subMenu,
            subMenuName,
            authorizedList: [],
            premiumList: [],
            officialList: [],
            collaboratedList: [],
            hospitalList: [],
            supportList: [],
            kitList: [],
            sponsorLink: ''
        }
    }

    onClickBtnDownload = (file, filename) => {
        fetch(file)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                })
            })
    }

    onClickBtnLink = (url) => {
        let a = document.createElement('a');
        a.href = url
        a.target = "_blank"
        a.click();
    }

    componentDidMount = async _ => {
        const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);

        currentAnalyticsVal.deps2 = "01";
        currentAnalyticsVal.deps3 = "08";
        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href;

        await CommonService.insertAnalytics(this.context.state);

        try {
            const result = await ArticleService.getSponsorList();
            const list = result.list;

            //스폰서배너링크
            let bannerListResult = await ContentService.getBannerList();        
            let sponsorLink = bannerListResult.list.filter(el => {
                return el.type === 'TOPRIGHT';
            }).map(el => {
                return el.link; 
            });

            let authorizedList = []; // 9
            let premiumList = []; // 10
            let officialList = []; // 11
            let collaboratedList = []; // 12
            let hospitalList = []; // 13
            let supportList = []; // 14
            let kitList = []; //15
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                switch (item.boardCatSeq) {
                    case 9: authorizedList.push(item); break;
                    case 10: premiumList.push(item); break;
                    case 11: officialList.push(item); break;
                    case 12: collaboratedList.push(item); break;
                    case 13: hospitalList.push(item); break;
                    case 14: supportList.push(item); break;
                    case 15: kitList.push(item); break;
                    default: break;
                }
            }
            this.setState({
                authorizedList,
                premiumList,
                officialList,
                collaboratedList,
                hospitalList,
                supportList,
                kitList,
                sponsorLink
            })

            console.log(result);
        } catch (err) {
            console.error(err);
            //alert(err.message);
        }
    }

    render = () => (
        <div className='container'>
            <SubHeadComponent
                subMenuName={this.state.subMenuName}
                thirdMenuName={this.state.thirdMenuName} />
            { <div className="sbHead_appView tb"></div> }

            { <ContComponent
                subMenu={this.state.subMenu}
                subMenuName={this.state.subMenuName}
                thirdMenu={this.state.thirdMenu}
                thirdMenuName={this.state.thirdMenuName}

                onClickBtnLink={this.onClickBtnLink}

                authorizedList={this.state.authorizedList}
                premiumList={this.state.premiumList}
                officialList={this.state.officialList}
                collaboratedList={this.state.collaboratedList}
                hospitalList={this.state.hospitalList}
                supportList={this.state.supportList}
                kitList={this.state.kitList}
                sponsorLink={this.state.sponsorLink}
            /> }
            {/*<PrepareComponent />*/}
        </div>
    )
}

export default SponsorContainer;
