import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TeamGuideContainer from './team-guide/TeamGuide.container';
import FacilityGuideContainer from './facility-guide/FacilityGuide.container';
import StadiumGuideContainer from './stadium-guide/StadiumGuide.container';
import StadiumSeatGuideContainer from './seat-guide/StadiumSeatGuide.container';
class ChampionsFieldContainer extends Component {
    render = () => (
        <Switch>
            <Route path={`${this.props.match.path}/team-guide`} component={TeamGuideContainer} />
            <Route path={`${this.props.match.path}/facility-guide`} component={FacilityGuideContainer} />
            <Route path={`${this.props.match.path}/stadium-guide`} component={StadiumGuideContainer} />
            <Route path={`${this.props.match.path}/seat-guide`} component={StadiumSeatGuideContainer} />
            <Redirect from={`${this.props.match.path}`} to={`${this.props.match.path}/team-guide`} />
        </Switch>
    )
}
export default ChampionsFieldContainer
