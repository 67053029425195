import React from "react"
import {useHistory, useParams} from "react-router-dom"
import {useQuery} from "react-query"
import {getProductDetail} from "../../../services/ScorePoint.service"
import ProductImageSlider from "./ProductImageSlider"
import ProductInfo from "./ProductInfo"
import {order} from "./ScorePointProducts"
import {useAlert} from "../../../context/PopupContext"

function ScorePointProductDetail(props) {
  const history = useHistory()
  const [showOption, setShowOption] = React.useState(false)
  const optionsRef = React.useRef(null)
  const {id} = useParams()
  const {openAlert} = useAlert()

  const {isLoading, data} = useQuery(["scorePointProduct", id], () => getProductDetail(id))

  const onClickAway = (e) => {
    if (showOption) {
      setShowOption(false)
    }
  }

  const goToOrder = React.useCallback(() => {
    // Show the next screen
    order.productId = data.productId
    order.point = data.price
    order.productOptionId = optionsRef.current
    setShowOption(false)
    history.push(`/score-points/products/${data.productId}/order`)
  }, [data, optionsRef])

  const onClickExchange = React.useCallback(() => {
    // 옵션이 있는데, 선택하지 않은 경우 팝업 표시
    if (data.optionInfoList && data.optionInfoList.length > 0) {
      if (optionsRef.current && showOption) {
        goToOrder()
      } else {
        showOption === false ? setShowOption(true) : openAlert("옵션을 선택해주세요")
      }
    } else {
      goToOrder()
    }
  }, [data, showOption])

  const onChangeOptions = React.useCallback((e) => {
    optionsRef.current = e.target.value
  }, [])

  const imagesSlider = React.useMemo(() => data && <ProductImageSlider images={data.imgInfoList} />, [data])

  if (isLoading) {
    return <div>Loading...</div>
  }

  const isSoldOut = data.status === "SOLDOUT"

  return (
    <div className="product-detail">
      <div onClick={onClickAway}>
        <header>
          {imagesSlider}
          <div className="score-title">
            <h5>{data.productName}</h5>
            <p>{data.price}p</p>
          </div>
        </header>
        <main>
          <ProductInfo product={data} />
        </main>
      </div>
      <div className="bottom">
        <div className={`bottom-sheet ${showOption ? "" : "closed"}`} onClick={(e) => e.stopPropagation()}>
          <form className="content options">
            {data.optionInfoList &&
              data.optionInfoList.map((option) => (
                <div className="item" key={option.productOptionId}>
                  <input type="radio" id={option.productOptionId} name="productOption" value={option.productOptionId} onChange={onChangeOptions} />
                  <label key={option.productOptionId}>{option.optionName}</label>
                </div>
              ))}
          </form>
        </div>
        <div className={`button-wrapper`}>
          <button disabled={isSoldOut} onClick={onClickExchange}>
            {isSoldOut ? "품절" : "교환 신청하기"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ScorePointProductDetail
