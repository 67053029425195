import cookie from "react-cookies"
import {decodeAES256} from "./Crypto.helper"
import * as DateHelper from "./Date.helper"

export const getMemberId = (_) => {
  try {
    const loginInfo = cookie.load("loginInfo")
    return decodeAES256(loginInfo.user.memberId)
  } catch (err) {
    cookie.remove("loginInfo")
    console.error(err)
    return null
  }
}
export const getAccessToken = (_) => {
  try {
    const loginInfo = cookie.load("loginInfo")
    return loginInfo.accessToken
  } catch (err) {
    cookie.remove("loginInfo")
    console.error(err)
    return null
  }
}
export const getRefreshToken = (_) => {
  try {
    const loginInfo = cookie.load("loginInfo")
    return loginInfo.refreshToken
  } catch (err) {
    cookie.remove("loginInfo")
    console.error(err)
    return null
  }
}
export const getLoginInfo = (_) => {
  try {
    // JSON형태일경우 자동으로 파싱
    const loginInfo = cookie.load("loginInfo")
    return loginInfo
  } catch (err) {
    cookie.remove("loginInfo")
    console.error(err)
    return null
  }
}
export const setLoginInfo = (loginInfo) => {
  loginInfo["issuedTimestamp"] = DateHelper.getCurrentTimestamp()
  cookie.save("loginInfo", loginInfo, {path: "/"})
  return true
}
export const removeLoginInfo = (_) => {
  console.log("commonHelper:removeLoginInfo")
  cookie.remove("loginInfo", {path: "/"})
  return true
}

export const checkHasLogin = (_) => {
  try {
    const loginInfo = getLoginInfo()
    if (loginInfo) {
      return true
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}

export const checkHasRealName = (_) => {
  try {
    const loginInfo = getLoginInfo()
    return loginInfo.user.realnameAuthYn === "Y"
  } catch (err) {
    return false
  }
}

export const checkHasLoveRoomAuth = (_) => {
  try {
    const loginInfo = getLoginInfo()
    return loginInfo.user.isLifelongMember === "Y" || loginInfo.user.isTclubMember === "Y"
  } catch (err) {
    return false
  }
}

export const updateRealName = (_) => {
  try {
    let loginInfo = getLoginInfo()
    loginInfo.user.realnameAuthYn = "Y"
    cookie.save("loginInfo", loginInfo, {path: "/"})
    return true
  } catch (err) {
    return false
  }
}
