import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import SubHeadComponent from '../common/SubHead.component';
import ContComponent from './Cont.component';

class EventContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenu: 'event',
            subMenuName: "온라인 이벤트"
        }
    }

    render = _ => (
        <div className="container">
            <SubHeadComponent
                subMenuName={this.state.subMenuName} />
            <ContComponent />
        </div>
    )
}

export default EventContainer;