import React from 'react';

const PaginationComponent = ({ paginationObj, onClickBtnPageNum }) => (
    paginationObj ?
        <div className="paging">
            <div className="pgingIn">
                <p onClick={evt => onClickBtnPageNum(evt, paginationObj.firstPageNum)}><img src="/img/sub/pging_prev2.png" alt="게시판글 페이징 10씩 앞글보기" /></p>
                <p onClick={evt => onClickBtnPageNum(evt, paginationObj.prevPageNum)} ><img src="/img/sub/pging_prev.png" alt="게시판글 페이징 1씩 앞글보기" /></p>
                <ol>
                    {paginationObj.pageList.map((pageNum, idx) =>
                        <li key={idx}
                            onClick={evt => onClickBtnPageNum(evt, pageNum)}
                            className={paginationObj.idxInList === idx ? "on" : ""}>{pageNum}</li>
                    )}
                </ol>
                <p onClick={evt => onClickBtnPageNum(evt, paginationObj.nextPageNum)}><img src="/img/sub/pging_next.png" alt="게시판글 페이징 1씩 뒷글보기" /></p>
                <p onClick={evt => onClickBtnPageNum(evt, paginationObj.lastPageNum)}><img src="/img/sub/pging_next2.png" alt="게시판글 페이징 10씩 뒷글보기" /></p>
            </div>
        </div>
        : null
)

export default PaginationComponent;