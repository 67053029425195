import React from 'react';

const PitcherComponent = props => (
    <div className="rankingTeam">
        <section>
            <h6><span className="blind">투수 역대성적</span></h6>
            {/* <!-- [N]::등록선수가 없을때div className="noPlayer" --> */}
            <div className="table_Typ01 w10">
                <table>
                    <caption>투수 역대성적</caption>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">년도</th>
                            <th scope="col">경기수</th>
                            <th scope="col">승수</th>
                            <th scope="col">패수</th>
                            <th scope="col">무승부수</th>
                            <th scope="col">승률</th>
                            <th scope="col">이닝</th>
                            <th scope="col">실점</th>
                            <th scope="col">자책점</th>
                            <th scope="col">평균자책점</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.list.map((item, idx) =>
                            <tr key={idx}>
                                <td>{item.gyear}</td>
                                <td>{item.gamenum}</td>
                                <td>{item.w}</td>
                                <td>{item.l}</td>
                                <td>{item.d}</td>
                                <td>{item.wra}</td>
                                <td>{item.innDisplay}</td>
                                <td>{item.r}</td>
                                <td>{item.er}</td>
                                <td>{item.era}</td>
                            </tr>
                        )}
                        {/* <!--  */}
                        {/* <tr>
                            <td colSpan="10">등록된 선수가 없습니다.</td>
                        </tr> */}
                        {/* --> */}
                    </tbody>
                </table>
            </div>
            {/* <!--//table_Typ01 --> */}
        </section>
    </div>
)
export default PitcherComponent;