import React from "react";

import * as CommonHelper from "../../../helpers/Common.helper";
import QRCode from 'react-qr-code'

import dayjs from 'dayjs';
import { decodeAES256 } from '../../../helpers/Crypto.helper'

const ContComponent = (props) => (
  <div className="mb_cont membership">
    <div className="mb_contIn inqWrite">
      <h6 className="h6Bar">{props.eventInfo.event_name}</h6>
      {props.eventInfo.thumnail_pic !== "" &&
        <img src={props.eventInfo.thumnail_pic} style={{margin: "10px 0", width: "100%"}}/>
      }
      <form action="/" method="post" className="bdWrite">
        <fieldset>
          <ul>
            <li><label htmlFor="user_id">아이디</label></li>
            <li className="tit">

              <input type="text" id="user_id"
                     value={decodeAES256(props.userId)}
                     readOnly={true}
                     disabled={true}
                     style={{background: "#DDDDDD"}}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="user_name">이름</label></li>
            <li className="tit">

              <input type="text" id="user_name"
                     value={decodeAES256(props.userName)}
                     readOnly={true}
                     disabled={true}
                     style={{background: "#DDDDDD"}}
              />
              {/*<p><strong>{props.title.length}</strong>/<span>{35}</span></p>*/}
            </li>
          </ul>
          <ul>
            <li><label htmlFor="mobile_no">연락처</label></li>
            <li className="tit">

              <input type="text" id="mobile_no"
                     placeholder={"연락처를 입력해주세요"}
                     onChange={props.onChangeInputMobileNo}
                     value={props.mobile_no}
              />
              <p><strong>{props.mobile_no.length}</strong>/<span>{11}</span></p>
            </li>
          </ul>
          {props.eventInfo.pic1_name !== "" &&
            <ul>
              <li><label htmlFor="answer_1">{props.eventInfo.pic1_name}</label></li>
              <li className="tit">

                <input type="text" id="answer_1"
                       placeholder={""}
                       onChange={props.onChangeInputAnswer_1}
                       value={props.answer_1}
                />
              </li>
            </ul>
          }
          {props.eventInfo.pic2_name !== "" &&
          <ul>
            <li><label htmlFor="answer_2">{props.eventInfo.pic2_name}</label></li>
            <li className="tit">

              <input type="text" id="answer_2"
                     placeholder={""}
                     onChange={props.onChangeInputAnswer_2}
                     value={props.answer_2}
              />
            </li>
          </ul>
          }
          {props.eventInfo.pic3_name !== "" &&
          <ul>
            <li><label htmlFor="answer_3">{props.eventInfo.pic3_name}</label></li>
            <li className="tit">

              <input type="text" id="answer_3"
                     placeholder={""}
                     onChange={props.onChangeInputAnswer_3}
                     value={props.answer_3}
              />
            </li>
          </ul>
          }
          <ul>
            <li style={{height: "inherit", lineHeight: "inherit", borderLeft: 0}}><label htmlFor="disagree">개인정보 수집 및
              이용에 관한 동의</label></li>
            <li className="tit" style={{borderLeft: "1px solid #e4e5e6 "}}>
              <textarea readOnly={true} style={{
                width: "100%",
                height: "100px",
                padding: "10px",
                boxSizing: "border-box",
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                marginBottom: "10px",
                resize: "none"
              }}
                        value={
                          '개인정보 수집 및 이용에 관한 동의사항\n' + '\n' + '1. 수집한 개인정보의 이용\n' + '- 이용자 식별, 원활한 의사소통\n' + '- 행사 시 정보 전달 및 민원 처리\n' + '\n' + '2. 수집하는 개인정보 항목\n' + '아이디, 이름, 연락처\n' + '\n' + '3. 개인정보의 보유기간 및 이용 기간\n' + '수집된 정보는 이벤트 종료 후 이벤트 당첨자의 이벤트 참여 완료된 이후 지체없이 파기됩니다.\n' + '\n' + '4. 동의 거부 권리 및 동의 거부 시 불이익 내용\n' + '동의 거부 시 이벤트에 참가할 수 없습니다.'
                        }>
              </textarea>
              <div className="agree">
                <input type={"radio"} id={"agree"} name={"agree"} value={"Y"} onChange={props.onChangeInputAgree}
                       checked={props.agree === "Y"} style={{width: 'inherit'}}/>
                <label htmlFor={"agree"}>동의</label>
                <input type={"radio"} id={"disagree"} name={"agree"} value={"N"} onChange={props.onChangeInputAgree}
                       checked={props.agree === "N"} style={{width: 'inherit', marginLeft: '20px'}}/>
                <label htmlFor={"disagree"}>미동의</label>
              </div>
            </li>
          </ul>
        </fieldset>

      </form>
      <div className="inQBtn clear">

        <button onClick={props.onClickBtnRequest} type="button" style={{
          left: "50%",
          position: "relative",
          marginLeft: "-25%"
        }}><em className="myQ"></em>응모하기
        </button>

      </div>

    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
