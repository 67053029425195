import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";

class TeamRankingContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "팀 순위",
      tabNum: 1,
    };
  }

  componentDidMount = async () => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "04";
    currentAnalyticsVal.deps3 = "03"; //
    currentAnalyticsVal.deps4 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);
  };

  onClickBtnTab = (evt, tabNum) => {
    this.setState({ tabNum });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>

      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        tabNum={this.state.tabNum}
        onClickBtnTab={this.onClickBtnTab}
      />
    </div>
  );
}

export default TeamRankingContainer;
