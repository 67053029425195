import React from 'react';

const DayNightRecordComponent = props => (
    props.info.position2 === '투' ?
        <DayNightRecordComponentForPitcher {...props} />
        :
        <DayNightRecordComponentForHitter {...props} />
)

const DayNightRecordComponentForHitter = props => (
    <table>
        <caption>주/야간별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">주/야간별</th>
                <th scope="col">타석</th>
                <th scope="col">타수</th>
                <th scope="col">득점</th>
                <th scope="col">안타</th>
                <th scope="col">2타</th>
                <th scope="col">3타</th>
                <th scope="col">홈런</th>
                <th scope="col">타점</th>
                <th scope="col">희타</th>
                <th scope="col">희비</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">병살</th>
                <th scope="col">타율</th>
            </tr>
        </thead>
        <tbody>
            {props.dayNightRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.run}</td>
                    <td>{item.hit}</td>
                    <td>{item.h2}</td>
                    <td>{item.h3}</td>
                    <td>{item.hr}</td>
                    <td>{item.rbi}</td>
                    <td>{item.sh}</td>
                    <td>{item.sf}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.gd}</td>
                    <td>{item.hra}</td>
                </tr>
            )}
            {props.dayNightRecordList.length === 0 ?
                <tr>
                    <td colSpan="16">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.dayNightTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.dayNightTotalRecord.pa}</td>
                    <td>{props.dayNightTotalRecord.ab}</td>
                    <td>{props.dayNightTotalRecord.run}</td>
                    <td>{props.dayNightTotalRecord.hit}</td>
                    <td>{props.dayNightTotalRecord.h2}</td>
                    <td>{props.dayNightTotalRecord.h3}</td>
                    <td>{props.dayNightTotalRecord.hr}</td>
                    <td>{props.dayNightTotalRecord.rbi}</td>
                    <td>{props.dayNightTotalRecord.sh}</td>
                    <td>{props.dayNightTotalRecord.sf}</td>
                    <td>{props.dayNightTotalRecord.bb}</td>
                    <td>{props.dayNightTotalRecord.hp}</td>
                    <td>{props.dayNightTotalRecord.kk}</td>
                    <td>{props.dayNightTotalRecord.gd}</td>
                    <td>{(props.dayNightTotalRecord.hit / props.dayNightTotalRecord.ab).toFixed(3)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)

const DayNightRecordComponentForPitcher = props => (
    <table>
        <caption>주/야간별별 선수 현황표</caption>
        <thead>
            <tr>
                <th scope="col">주/야간별</th>
                <th scope="col">승</th>
                <th scope="col">패</th>
                <th scope="col">세이브</th>
                <th scope="col">홀드</th>
                <th scope="col">이닝</th>
                <th scope="col">투구</th>
                <th scope="col">타자</th>
                <th scope="col">타수</th>
                <th scope="col">안타</th>
                <th scope="col">홈런</th>
                <th scope="col">4구</th>
                <th scope="col">사구</th>
                <th scope="col">삼진</th>
                <th scope="col">폭투</th>
                <th scope="col">보크</th>
                <th scope="col">실점</th>
                <th scope="col">자책</th>
                <th scope="col">방어율</th>
            </tr>
        </thead>
        <tbody>
            {props.dayNightRecordList.map((item, idx) =>
                <tr key={idx}>
                    <th>{item.groupIf}</th>
                    <td>{item.w}</td>
                    <td>{item.l}</td>
                    <td>{item.sv}</td>
                    <td>{item.hold}</td>
                    <td>{item.innDisplay}</td>
                    <td>{item.tugucount}</td>
                    <td>{item.pa}</td>
                    <td>{item.ab}</td>
                    <td>{item.hit}</td>
                    <td>{item.hr}</td>
                    <td>{item.bb}</td>
                    <td>{item.hp}</td>
                    <td>{item.kk}</td>
                    <td>{item.wp}</td>
                    <td>{item.bk}</td>
                    <td>{item.r}</td>
                    <td>{item.er}</td>
                    <td>{item.era}</td>
                </tr>
            )}
            {props.dayNightRecordList.length === 0 ?
                <tr>
                    <td colSpan="19">조회된 기록이 없습니다.</td>
                </tr>
                : null}
        </tbody>
        <tfoot>
            {props.dayNightTotalRecord ?
                <tr className="tFoot">
                    <th>합계</th>
                    <td>{props.dayNightTotalRecord.w}</td>
                    <td>{props.dayNightTotalRecord.l}</td>
                    <td>{props.dayNightTotalRecord.sv}</td>
                    <td>{props.dayNightTotalRecord.hold}</td>
                    <td>
                        {props.dayNightTotalRecord.inn2 % 3 === 0
                        ? parseInt(props.dayNightTotalRecord.inn2 / 3)
                        : parseInt(props.dayNightTotalRecord.inn2 / 3) +
                            " " +
                            (props.dayNightTotalRecord.inn2 % 3) +
                            "/3"}
                    </td>
                    <td>{props.dayNightTotalRecord.tugucount}</td>
                    <td>{props.dayNightTotalRecord.pa}</td>
                    <td>{props.dayNightTotalRecord.ab}</td>
                    <td>{props.dayNightTotalRecord.hit}</td>
                    <td>{props.dayNightTotalRecord.hr}</td>
                    <td>{props.dayNightTotalRecord.bb}</td>
                    <td>{props.dayNightTotalRecord.hp}</td>
                    <td>{props.dayNightTotalRecord.kk}</td>
                    <td>{props.dayNightTotalRecord.wp}</td>
                    <td>{props.dayNightTotalRecord.bk}</td>
                    <td>{props.dayNightTotalRecord.r}</td>
                    <td>{props.dayNightTotalRecord.er}</td>
                    <td>{((props.dayNightTotalRecord.er * 9) / (props.dayNightTotalRecord.inn2 / 3)).toFixed(2)}</td>
                </tr>
                : null}
        </tfoot>
    </table>
)


export default DayNightRecordComponent;