import * as DeviceDetect from "react-device-detect"

export const testBridge = (at, rt) => {
  var isIOS = false
  var isAndroid = false
  console.log(".........testBridge  accessToekn = " + at + "  refreshToken = " + rt)

  if (DeviceDetect.isAndroid) {
    console.log("......... isAndroid !!!")
    isAndroid = true
  }
  if (DeviceDetect.isIOS) {
    console.log("......... isIOS !!!")
    isIOS = true
  }

  if (isAndroid) {
    var data = {
      cmdId: "au_tokenRefresh",
      params: {
        accessToken: at,
        refreshToken: rt
      }
    }
    window.tigers20.callNative(JSON.stringify(data))
  } else if (isIOS) {
    var data = {
      cmdId: "au_tokenRefresh",
      params: {
        accessToken: at,
        refreshToken: rt
      }
    }
    var url = "tigers20://callNative=('" + JSON.stringify(data) + "')"
    var iframe = document.createElement("iframe")
    iframe.style.display = "none"
    iframe.setAttribute("src", "data:text/html;charset=utf-8," + url)
    document.documentElement.appendChild(iframe)
    iframe.parentNode.removeChild(iframe)
    iframe = null
  }

  return null
}

export const webViewCloseBridge = () => {
  const deviceType = DeviceDetect.isAndroid ? "ANDROID" : DeviceDetect.isIOS ? "IOS" : "PC"

  const data = {
    cmdId: "nv_close",
    params: "{refresh: true}"
  }

  if (deviceType === "PC") {
    window.location.pathname = "/"
  } else if (deviceType === "ANDROID") {
    window.tigers20.callNative(JSON.stringify(data))
  } else if (deviceType === "IOS") {
    const url = `tigers20://callNative=('${JSON.stringify(data)}')`
    const iframe = document.createElement("iframe")

    iframe.style.display = "none"
    iframe.setAttribute("src", "data:text/html;charset=utf-8," + url)
    document.documentElement.appendChild(iframe)
    iframe.parentNode.removeChild(iframe)
  }
}
