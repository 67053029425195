import axios from "axios";
import { encodeSHA256 } from "../helpers/Crypto.helper";
import TeamGuideContainer from "../scenes/tigers/champions-field/team-guide/TeamGuide.container";

const baseUrl = "/v1";
const baseUrl2 = "/v2";

const pnsToken = "dummyToken";

export const signup = async (params) => {
  const data = {};
  for (const key in params) {
    data[key] = params[key];
  }
  return axios
    .post(`${baseUrl}/user/web/join`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getTermsList = async (id, pw) => {
  return axios
    .get(`${baseUrl}/user/terms`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const checkExistsId = async (id) => {
  const data = {
    'user.simpleId': id
  }

  return axios
    .post(`${baseUrl}/user/id/check`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const requestAuthNum = async (
  reqType,
  certType,
  value,
  simpleId = null,
  certName = "",
) => {
  const data = {
    reqType: reqType,
    certType: certType,
  }

  if (certType === 100) {
    // 휴대폰
    data.mobpNo = value;
    data.certName = certName;
  } else if (certType === 200) {
    // 이메일
    data.memberEmail = value;
  } else {
    throw new Error("잘못된 호출입니다.");
  }

  if (simpleId) {
    data.simpleId = simpleId;
  }

  console.log("data :::: ", certName)

  return axios
    .post(`${baseUrl}/user/web/authNo/request`, data)
    .then(({ status, data }) => {
      console.log(data);
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data.certSeq;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const requestAuthNumByCellphone = async (
  reqType,
  cellphoneNum,
  simpleId = null,
  certName = ""
) => {
  return await requestAuthNum(reqType, 100, cellphoneNum, simpleId, certName);
};
export const requestAuthNumByEmail = async (
  reqType,
  email,
  simpleId = null
) => {
  return await requestAuthNum(reqType, 200, email, simpleId);
};

export const requestCheckAuthNum = async (
  reqType,
  certType,
  certSeq,
  certNo,
  value,
  simpleId = null
) => {
  const data = {};

  // data.append("reqType", reqType);
  // data.append("certType", certType);
  // data.append("certSeq", certSeq);
  // data.append("certNo", certNo);

  data.reqType = reqType;
  data.certType = certType;
  data.certSeq = certSeq;
  data.certNo = certNo;

  if (certType === 100) {
    // data.append("mobpNo", value);
    data.mobpNo = value;
  } else if (certType === 200) {
    // data.append("memberEmail", value);
    data.memberEmail = value;
  } else {
    throw new Error("잘못된 호출입니다.");
  }

  if (simpleId) {
    // data.append("simpleId", simpleId);
    data.simpleId = simpleId;
  }

  return axios
    .post(`${baseUrl}/user/web/checkAuthNo/request`, data)
    .then(({ status, data }) => {
      console.log(data);
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const requestCheckAuthNumByCellphone = async (
  reqType,
  certSeq,
  certNo,
  cellphoneNum,
  simpleId = null
) => {
  return await requestCheckAuthNum(
    reqType,
    100,
    certSeq,
    certNo,
    cellphoneNum,
    simpleId
  );
};
export const requestCheckAuthNumByEmail = async (
  reqType,
  certSeq,
  certNo,
  email,
  simpleId = null
) => {
  return await requestCheckAuthNum(
    reqType,
    200,
    certSeq,
    certNo,
    email,
    simpleId
  );
};

export const findId = async (findType, value) => {
  // let data = new FormData();
  const data = {};


  // data.append("findInfo.findType", findType);
  // data.append("hkey", encodeSHA256(value));

  data['findInfo.findType'] = findType;
  data['hkey'] = encodeSHA256(value);

  if (findType == 100) {
    // data.append("findInfo.mobpNo", value);
    data['findInfo.mobpNo'] = value;
  } else if (findType == 200) {
    // data.append("findInfo.memberEmail", value);
    data['findInfo.memberEmail'] = value;
  } else {
    throw new Error("잘못된 접근방식입니다.");
  }

  return axios
    .post(`${baseUrl2}/user/id/find`, data)
    .then(({ status, data }) => {
      console.log(data);
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const getMyInfo = async () => {
  return axios
    .get(`${baseUrl2}/user/info`)
    .then(({ status, data }) => {
      console.log(data);
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const updateMyInfo = async (params) => {
  console.log(params);

  // let data = new FormData();
  const data = {};

  // data.append("user.simpleId", params.simpleId);
  // data.append("user.memberId", params.memberId);
  // data.append("user.mobpNo", params.mobpNo);
  // data.append("user.memberEmail", params.memberEmail);
  // data.append("user.isAdult", params.isAdult);
  // data.append("user.postNo", params.postNo);
  // data.append("user.addr1", params.addr1);
  // data.append("user.addr2", params.addr2);
  // data.append("user.smsYn", params.smsYn);
  // data.append("user.emailYn", params.emailYn);

  data['user.simpleId'] = params.simpleId;
  data['user.memberId'] = params.memberId;
  data['user.mobpNo'] = params.mobpNo;
  data['user.memberEmail'] = params.memberEmail;
  data['user.isAdult'] = params.isAdult;
  data['user.postNo'] = params.postNo;
  data['user.addr1'] = params.addr1;
  data['user.addr2'] = params.addr2;
  data['user.smsYn'] = params.smsYn;
  data['user.emailYn'] = params.emailYn;

  if (params.memberPw) {
    // data.append("user.memberPw", params.memberPw);
    // data.append("user.memberNewPw", params.memberNewPw);
    data['user.memberPw'] = params.memberPw;
    data['user.memberNewPw'] = params.memberNewPw;
  }
  return axios
    .post(`${baseUrl}/user/info/update`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const updatePw = async (simpleId, password) => {
  // let data = new FormData();
  const data = {};
  // data.append("simpleId", simpleId);
  // data.append("newPw", password);
  // data.append("hkey", encodeSHA256(simpleId))

  data['simpleId'] = simpleId;
  data['newPw'] = password;
  data['hkey'] = encodeSHA256(simpleId)


  return axios
    .post(`${baseUrl2}/user/web/pw/update`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        alert(data.message);
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const secession = async (type, reason) => {
  // let data = new FormData();
  const data = {};

  // data.append("withdraw.type", type);
  // data.append("withdraw.reason", reason);

  data['withdraw.type'] = type;
  data['withdraw.reason'] = reason;

  return axios
    .post(`${baseUrl}/user/delete`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
export const authRealName = async (authType, certData) => {
  // let data = new FormData();
  const data = {};
  // data.append("isOver14y", "Y");
  // data.append("realNameAuthType", authType);
  // data.append("certData", certData);
  //
  data['isOver14y'] = "Y";
  data['realNameAuthType'] = authType;
  data['certData'] = certData;

  return axios
    .post(`${baseUrl}/user/web/regRealnameAuth`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getBlindList = async (memberId) => {
  // let data = new FormData();
  const data = {};
  // data.append("memberId", memberId);
  data['memberId'] = memberId;
  return axios
    .post(`${baseUrl}/user/blind/list`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.mesaage);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const deleteBlindList = async (blindSeq, memberId) => {
  // let data = new FormData();
  const data = {};
  // data.append("blindSeq", blindSeq);
  // data.append("memberId", memberId);

  data['blindSeq'] = blindSeq;
  data['memberId'] = memberId;


  return axios
    .post(`${baseUrl}/user/blind/delete`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.mesaage);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const applyBlindList = async (articleSeq, memberId, simpleId) => {
  // let data = new FormData();
  const data = {};
  // data.append("artcSeq", articleSeq);
  // data.append("memberId", memberId);
  // data.append("simpleId", simpleId);

  data['artcSeq'] = articleSeq;
  data['memberId'] = memberId;
  data['simpleId'] = simpleId;

  return axios
    .post(`${baseUrl}/user/blind/add`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.mesaage);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const checkUsedId = async (realNameAuthType, certData, isOver14y) => {
  // let data = new FormData();
  const data = {};
  // data.append("realNameAuthType", realNameAuthType);
  // data.append("certData", certData);
  // data.append("isOver14y", isOver14y);

  data['realNameAuthType'] = realNameAuthType;
  data['certData'] = certData;
  data['isOver14y'] = isOver14y;

  return axios
    .post(`${baseUrl}/user/web/preIdentification`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        if (data.status === 2400) {
          alert(data.message);
          return false;
        }
        throw new Error(data.mesaage);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const checkOwnPassword = async (currentPw) => {
  // let data = new FormData();
  const data = {};
  // data.append("user.memberPw", currentPw);

  data['user.memberPw'] = currentPw;
  return axios.post(`${baseUrl}/auth/pw`, data).then(({ data }) => {
    if (data.status !== 0) {
      return data;
    }
    return true;
  });
};

export const delayChangePassword = async () => {
  return axios.post(`${baseUrl}/user/pw/changeNextTime`).then(({ data }) => {
    if (data.status !== 0) {
      alert(data.mesaage);
      return false;
    }
  });
};

export const activeUserAcount = async (params, simpleId) => {
  // let data = new FormData();
  // data.append("realNameAuthType", params.realNameAuthType);
  // data.append("certData", params.certData);

  const data = {};
  data['realNameAuthType'] = params.realNameAuthType;
  data['certData'] = params.certData;

  return axios
    .post(`${baseUrl}/user/status/activation/id/${simpleId}`, data)
    .then(({ data }) => {
      if (data.status !== 0) {
        console.error(data.status);
        throw new Error(data.message);
      }

      return true;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};
