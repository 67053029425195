import React from "react";
import SubMenuComponent from "../common/SubMenu.component";
import PrepareComponent from "../../../layout/Prepare.component";

const ContComponent = props => (
  <div className="mb_cont sub_cont tigers">
    <div className="mb_contIn uniform">
      <SubMenuComponent thirdMenu={props.thirdMenu} />
      {/* <!-- //tabWrap --> */}
      {/*<PrepareComponent />*/}

      <div className="mascotIn">
        <div className="clear">
          <h5 className="tb">
            KIA 타이거즈 <strong>MASCOT</strong>
          </h5>
          <ul>
            <li>
              <img src="/img/sub/mascot00.png" alt="기아 타이거즈 마스코트" />
            </li>
            <li>
              <h5 className="pc">
                KIA 타이거즈 <strong>MASCOT</strong>
              </h5>
              <p>
                KIA 타이거즈의 마스코트는 구단의 상징 동물인 호랑이를 귀엽고
                친근한 이미지로 의인화 한 것으로
                <br className="pc" /> 구단과 팬 사이의 커뮤니케이션을 쉽고
                친밀감 있게 연결해 주는 역할을 한다.
                <br />
                타이거즈 심볼이 구단의 강인한 이미지를 대표한다면, 마스코트는
                주로 팬서비스 용품이나 프로모션류에
                <br className="pc" /> 다양하게 활용되어 팬에게 부드럽게 접근할
                수 있는 이미지를 제공한다.
              </p>
              <div className="pc">
                {/* <button
                                    onClick={evt => props.onClickBtnShowPopup('00_1')}
                                    type="button" className="btnZoom">ZOOM</button> */}
                <button
                  type="button"
                  className="btnDown"
                  onClick={evt =>
                    props.onClickBtnDownload(
                      "/img/download/mascot/zoom_mascot.jpg",
                      "zoom_mascot.jpg"
                    )
                  }
                >
                  다운로드 JPG <span></span>
                </button>
              </div>
            </li>
          </ul>
        </div>
        <ul>
          <li>
            <img src="/img/sub/mascot01.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('01')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot01.jpg",
                    "zoom_mascot01.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot02.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('02')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot02.jpg",
                    "zoom_mascot02.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot03.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('03')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot03.jpg",
                    "zoom_mascot03.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot04.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('04')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot04.jpg",
                    "zoom_mascot04.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot05.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('05')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot05.jpg",
                    "zoom_mascot05.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot06.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('06')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot06.jpg",
                    "zoom_mascot06.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot07.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('07')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot07.jpg",
                    "zoom_mascot07.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot08.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('08')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot08.jpg",
                    "zoom_mascot08.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
          <li>
            <img src="/img/sub/mascot09.png" alt="기아 타이거즈 마스코트" />
            <div className="pc">
              {/* <button
                                onClick={evt => props.onClickBtnShowPopup('09')}
                                type="button" className="btnZoom">ZOOM</button> */}
              <button
                type="button"
                className="btnDown"
                onClick={evt =>
                  props.onClickBtnDownload(
                    "/img/download/mascot/zoom_mascot09.jpg",
                    "zoom_mascot09.jpg"
                  )
                }
              >
                다운로드 JPG <span></span>
              </button>
            </div>
          </li>
        </ul>
        <div className={(props.popupObj.onShow ? "on" : "") + " zoom"}>
          <div className="zoomBg"></div>
          <p className={props.popupObj.onZoom ? "on" : ""}>
            <span
              onClick={props.onClickBtnZoomIn}
              className={
                (!props.popupObj.onZoom ? "on" : "") + " popBtnZoomOut"
              }
            >
              <img src="/img/sub/btn_zoom_out.png" alt="줌아웃버튼" />
            </span>
            <span
              onClick={props.onClickBtnZoomOut}
              className={(props.popupObj.onZoom ? "on" : "") + " popBtnZoomIn"}
            >
              <img src="/img/sub/btn_zoom_in.png" alt="줌인버튼" />
            </span>
            <span
              onClick={props.onClickBtnClosePopup}
              className="popBtnClose on"
            >
              <img src="/img/sub/btn_zoom_close.png" alt="닫기버튼" />
            </span>
            <img src={props.popupObj.imgUrl} alt="기아 타이거즈 마스코트" />
          </p>
        </div>
      </div>
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);
export default ContComponent;
