import React, { Component } from "react";
import SubHeadComponent from "../../../contents/common/SubHead.component";
import ContComponent from "./Cont.component";
import queryString from "query-string";

import * as ArticleService from "../../../../services/Article.service";
import * as ListHelper from "../../../../helpers/List.helper";
import * as DateHelper from "../../../../helpers/Date.helper";

import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";

class ListContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const queryStringParams = queryString.parse(this.props.location.search);

    const currentPage = parseInt(
      queryStringParams["page"] ? queryStringParams["page"] : 1,
      10
    );
    const selectedYear = parseInt(
      queryStringParams["year"] ? queryStringParams["year"] : 2019,
      10
    );
    const yearSelectList = DateHelper.getYearSelectList();

    this.state = {
      subMenu: "pictures",
      subMenuName: "타이거즈 포토",

      selectedYear: process.env.REACT_APP_SEASON_YEAR,
      yearSelectList,

      currentPage,
      perPage: 12,
      totalPage: 0,
      paginationObj: null,
      list: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "03"; //
      currentAnalyticsVal.deps3 = "04"; //
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const offset = ListHelper.makeOffset(
        this.state.currentPage,
        this.state.perPage
      );

      const result = await this.getList(offset, this.state.selectedYear);

      let totalPage = 0;
      let paginationObj = null;

      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            this.state.currentPage,
            this.state.perPage,
            5
          );
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(
          result.list[i]["regDttm"]
        );
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(
          result.list[i]["regDttm"]
        );
      }

      this.setState({ list: result.list, totalPage, paginationObj });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "03";
      currentAnalyticsVal.deps3 = "04"; //
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.queryString =
        window.location.href.split("?").length === 2
          ? decodeURI(window.location.href.split("?")[1])
          : "";
      currentAnalyticsVal.currentUrl = window.location.href.split("?")[0];

      await CommonService.insertAnalytics(this.context.state);

      const queryStringParams = queryString.parse(this.props.location.search);

      const page = parseInt(
        queryStringParams["page"] ? queryStringParams["page"] : 1,
        10
      );
      const year = parseInt(
        queryStringParams["year"] ? queryStringParams["year"] : process.env.REACT_APP_SEASON_YEAR,
        10
      );

      this.updateList(page, year);
    }
  };

  updateList = async (page, year) => {
    console.log("updateList", page, year);
    try {
      const offset = ListHelper.makeOffset(page, this.state.perPage);

      const result = await this.getList(offset, year);

      let totalPage = 0;
      let paginationObj = null;

      for (let i = 0; i < result.list.length; i++) {
        if (i === 0) {
          totalPage = result.list[0].totalPage;
          paginationObj = ListHelper.makePaginationObj(
            totalPage,
            page,
            this.state.perPage,
            5
          );
        }

        result.list[i]["regDate"] = DateHelper.convertTimestampToDate(
          result.list[i]["regDttm"]
        );
        result.list[i]["regTime"] = DateHelper.convertTimestampToTime(
          result.list[i]["regDttm"]
        );
      }
      console.log(paginationObj);
      this.setState({
        list: result.list,
        totalPage,
        paginationObj,
        currentPage: page,
        selectedYear: year,
      });
    } catch (err) {
      alert(err.message);
    }
  };

  getList = async (offset, year = null) => {
    let listParams = {
      "search.pos": offset,
      "search.max": this.state.perPage,
    };
    if (year) {
      listParams["article.searchYear"] = year;
    }
    console.log("listParams", listParams);
    return ArticleService.getPicturesList(listParams);
  };

  onChangeYearSelectList = (evt) => {
    this.setState({ selectedYear: evt.target.value });
  };

  onClickBtnSearch = (evt) => {
    let url = `/game/pictures?page=1&year=${this.state.selectedYear}`;
    this.props.history.push(url);
  };

  onClickBtnPageNum = (evt, pageNum) => {
    console.log("onClickBtnPageNum", pageNum, this.state);
    let url = `/game/pictures?page=${pageNum}&year=${this.state.selectedYear}`;
    this.props.history.push(url);
  };

  onClickBtnArticle = (evt, articleSeq) => {
    let url = `/game/pictures/${articleSeq}`;
    this.props.history.push(url);
  };

  render = () => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />
      <ContComponent
        selectedYear={this.state.selectedYear}
        onChangeYearSelectList={this.onChangeYearSelectList}
        yearSelectList={this.state.yearSelectList}
        onClickBtnSearch={this.onClickBtnSearch}
        list={this.state.list}
        onClickBtnArticle={this.onClickBtnArticle}
        paginationObj={this.state.paginationObj}
        onClickBtnPageNum={this.onClickBtnPageNum}
      />
    </div>
  );
}

export default ListContainer;
