import axios from "axios";
import { encodeSHA256 } from "../helpers/Crypto.helper";
import TeamGuideContainer from "../scenes/tigers/champions-field/team-guide/TeamGuide.container";

const baseUrl = "/v1";

const pnsToken = "dummyToken";

export const reserveList = async (params) => {

  const data = {};
  data.startDate = params.startDate;
  data.endDate = params.endDate;
  data.encryptedId = params.encryptedId;



  return axios
    .post(`${baseUrl}/ticketlink/reserveList`, data)
    .then(({ data }) => {
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};


//선물받은 티켓
export const getGiftTicket = async (params) => {
  const data = {};
  return axios
    .get(`${baseUrl}/smartTicket/checkgift?phonenum=${params.mobile}&id=${params.idx}`, data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      // throw new Error(err.message);
    });
};


//선발투수 이미지
export const startingPitcher = async() => {
  return axios
    .get(`${baseUrl}/smartTicket/slide`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
}


// /v1/ticketlink/bankCode
export const getBankCode = async () => {
    return axios
        .get(`${baseUrl}/ticketlink/bankCode`)
        .then(({ data }) => {
        return data.data;
        })
        .catch((err) => {
        console.error(err);
        throw new Error(err.message);
        });

}

export const check_refundAccount = async () => {
    return axios
        .get(`${baseUrl}/ticketlink/checkAccountInfo`)
        .then(({ data }) => {
        return data.data;
        })
        .catch((err) => {
        console.error(err);
        throw new Error(err.message);
        });
}

export const reg_refundAccount = async (params) => {
    return axios
        .post(`${baseUrl}/ticketlink/regAccountInfo`, params)
        .then(({ data }) => {
        return data;
        })
        .catch((err) => {
        console.error(err);
        throw new Error(err.response.data.message);
        });
}