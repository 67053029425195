import React from "react";
import { Link } from "react-router-dom";

import PaginationComponent from "../../../common/Pagination.component";

const ContComponent = (props) => (
  <div className="mb_cont contents">
    <div className="mb_contIn bdView tv">
      <div className="board">
        {/* <!-- srchArea --> */}
        <div className="srchArea clear">
          <select
            name=""
            id=""
            value={props.selectedSort}
            onChange={(evt) => props.onChangeSelectList(evt, "selectedSort")}
          >
            {props.sortSelectList.map((item, idx) => (
              <option value={item.value}>{item.text}</option>
            ))}
          </select>
          <select
            name=""
            id=""
            value={props.selectedSearchFilter}
            onChange={(evt) =>
              props.onChangeSelectList(evt, "selectedSearchFilter")
            }
          >
            {props.searchFilterSelectList.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          <input
            value={props.searchKeyword}
            onChange={props.onChangeInput}
            onKeyPress={props.onEnterKeyPress}
            type="text"
            placeholder="검색어를 입력해주세요"
          />
          <button onClick={props.onClickBtnSearch} type="button">
            검색
          </button>
        </div>
        {/* <!-- //srchArea --> */}
        {/* <!-- board --> */}
        <div className="bdTab">
          <ul className="clear fl">
            <li className={props.tabNum === 1 ? "on" : ""}>
              <a onClick={(evt) => props.onClickBtnTab(evt, 1)}>전체 영상</a>
            </li>
            <li className={props.tabNum === 2 ? "on" : ""}>
              <a onClick={(evt) => props.onClickBtnTab(evt, 2)}>
                경기 하이라이트
              </a>
            </li>
            <li className={props.tabNum === 3 ? "on" : ""}>
              <a onClick={(evt) => props.onClickBtnTab(evt, 3)}>갸TV 영상</a>
            </li>
          </ul>
        </div>
        <ul className="tvList clear">
          {props.list.map((item, idx) => (
            <li key={idx}>
              <Link to={`/contents/tv/${item.artcSeq}`}>
                <div>
                  <img src={item.imgFilePath} alt="비디오영역" />
                </div>
                <p>{item.artcTitle}</p>
                <p className="icoWrap">
                  <span className="date">{item.regDate}</span>
                  <span className="view">
                    <i></i>
                    {item.viewCnt}
                  </span>
                  <span className="like">
                    <i></i>
                    {item.likeCnt}
                  </span>
                </p>
              </Link>
            </li>
          ))}
        </ul>
        <PaginationComponent
          paginationObj={props.paginationObj}
          onClickBtnPageNum={props.onClickBtnPageNum}
        />
      </div>
      {/* <!-- //board --> */}
    </div>
    {/* <!-- //mb_contIn --> */}
  </div>
);

export default ContComponent;
