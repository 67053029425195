import React from "react";
import { Link } from "react-router-dom";
import SubMenuComponent from "../common/SubMenu.component";
import Slider from "react-slick";

const sliderOptions = {
  autoplay: false,
  speed: 500,
  arrows: true,
  infinite: false,
  autoplaySpeed: 3000,
  slidesToShow: 4,
  slidesToScroll: 1,
  fade: false,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const ContComponent = (props) => {
  console.log("웹비즈 컴포넌트 props", props);

  return (
<div className="mb_cont sub_cont tigers champ"> {/* 추가 */}
    <div className="mb_contIn"> {/* 추가 */}
    <SubMenuComponent thirdMenu={props.thirdMenu} /> {/* 추가 */}
    <div className="mb_cont contents webjine">
      {props.currentWebzineInfo ? (
        <div className="mb_contIn">
          <section className="sec01">
            <h4>
              <span>한 달에 한번 만나는 즐거움!</span>
              Tigers Webzine!
            </h4>
            <div className="box clear">
              <div className="fll">
                <p>
                  <img
                    src={props.currentWebzineInfo[0].IMG_FILE_PATH}
                    alt={props.currentWebzineInfo[0].TITLE}
                  />
                </p>
              </div>
              <div className="flr">
                <strong>목차</strong>
                <div className="list clear">
                  <ul className="fll">
                    {props.currentWebzineInfo.map((item, idx) => {
                      if (idx <= props.currentWebzineInfo.length / 2)
                        return (
                          <li key={idx}>
                            <span>{item.PAGE}</span>
                            <p
                              dangerouslySetInnerHTML={{ __html: item.TITLE }}
                            ></p>
                          </li>
                        );
                      else return null;
                    })}
                  </ul>
                  <ul className="flr">
                    {props.currentWebzineInfo.map((item, idx) => {
                      if (props.currentWebzineInfo.length / 2 < idx)
                        return (
                          <li key={idx}>
                            <span>{item.PAGE}</span>
                            <p
                              dangerouslySetInnerHTML={{ __html: item.TITLE }}
                            ></p>
                          </li>
                        );
                      else return null;
                    })}
                  </ul>
                </div>
                <a href={props.currentWebzineInfo[0].PDF_PATH.replace(
                    "/contents/",
                    ""
                  )}
                  target="_blank"
                  onClick={() => {
                    props.increaseWebzineViewCnt(
                      props.currentWebzineInfo[0].IDX_WZ_SEQ
                    );
                  }}
                >
                  웹진보기
                </a>
                {/* {props.currentWebzineInfo.pdfPath} */}
              </div>
            </div>
          </section>
          <section className="sec02">
            <div className="yearSel">
              <select onChange={props.onChangeSelectList} name="" id="">
              {props.yearSelectList.map((value, idx) => (
                <option key={idx} value={value}>
                  {value}
                </option>
              ))}
            </select>
              {/* <button onClick={props.onClickBtnSearch} type="button">검색</button> */}
            </div>
            <div className="slWrap">
              <div className="in">
                {/* <ul className="row"> */}
                <Slider
                  className="row wjSlide"
                  ref={(slider) => props.setSliderRef(slider)}
                  {...sliderOptions}
                >
                {props.list.map((item, idx) => {
                  if (item[0].IMG_FILE_PATH === null) {
                    return null; 
                  }
                return (
                  <li key={idx}>
                    <a
                      onClick={(evt) => props.onClickBtnItem(evt, idx)}
                      className="por"
                    >
              
                      <img src={item[0].IMG_FILE_PATH} alt={item[0].WZ_TITLE} />
                      <span className="tag">{item[0].WZ_MONTH}월</span>
                    </a>
                  </li>
                )})}          
                </Slider>
                {/* </ul> */}
                <p onClick={props.onClickBtnSliderPrev} className="wjSl_prev">
                  <img src="/img/sub/webjine_arr.png" alt="이전" />
                </p>
                <p onClick={props.onClickBtnSliderNext} className="wjSl_next">
                  <img src="/img/sub/webjine_arr.png" alt="다음" />
                </p>
              </div>
            </div>
          </section>
        </div>
      ) : null}
      {/* <!-- //mb_contIn --> */}
    </div>
    
    </div>
    </div>
  );
};

export default ContComponent;