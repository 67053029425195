import React from "react";
import { Link } from "react-router-dom";

import * as CommonHelper from "../../helpers/Common.helper";

const FooterComponent = (props) =>
  !CommonHelper.checkIsOnWebview() ? (
    <footer>
      <div className="foot_sns">
        <div className="foot_snsIn m0auto">
          <ul>
            <li>
              <Link to="/terms/privacy" style={{color:"#ea0029", fontWeight:"bold"}}>개인정보처리방침</Link>
            </li>
            <li>
              <Link to="/terms/service">서비스이용약관</Link>
            </li>
            <li>
              <Link to="/terms/operation">운영정책</Link>
            </li>
            <li className="pc">
              <Link to="/terms/email">이메일주소무단수집거부</Link>
            </li>
            <li className="pc">
              <Link to="/tigers/sponsor">KIA타이거즈 스폰서</Link>
            </li>
            <li className="pc">
              <a
                target="_blank"
                href="https://photo.tigers.co.kr/"
              >
                언론사진 자료실
              </a>
            </li>
          </ul>
          <ul className="clear pc">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://post.naver.com/my.nhn?memberNo=35664311"
              >
                <img
                  src="/img/main/sns_naver.png"
                  alt="네이버포스트 바로가기"
                />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://1boon.kakao.com/kiatigers"
              >
                <img src="/img/main/sns_1boon.png" alt="1분 바로가기" />
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pf.kakao.com/_bxexdDj"
              >
                <img src="/img/main/sns_kakao.png" alt="카카오플친 바로가기" />
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/kiabaseballclub"
              >
                <img src="/img/main/sns_yt.png" alt="유튜브 바로가기" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/always_kia_tigers"
              >
                <img src="/img/main/sns_ins.png" alt="인스타그램 바로가기" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Kiatigers"
              >
                <img src="/img/main/sns_tw.png" alt="트위터 바로가기" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="foot_copy m0auto pc">
        <p>
        상호 : 기아타이거즈(주) | 대표자 : 최준영 | 주소 : 광주광역시 북구
        서림로 10 광주-기아 챔피언스 필드 내 2층 KIA타이거즈 사무실
        </p>
        <p>
        사업등록번호 : 409-81-02844 | 통신판매신고 : 광주북구 2009-138호 <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4098102844" target="_blank"> [사업자정보확인]</a> | 호스팅사업자 : 현대오토에버(주)
        </p>
        <p>
        전화 : 070-7686-8000 | 팩스 : 062-525-5350 | 이메일 : kiatigers@kiatigers.co.kr
        </p>
        <p>Copyright © 2020 KIA TIGERS. All rights reserved.</p>
      </div>
      <div className="foot_copy m0auto tb">
        <p>
          상호 : 기아타이거즈(주) | <br className="mb" />
          대표자 : 최준영 | 주소 : 광주광역시 북구 서림로 10 광주-기아 챔피언스 필드 내 2층
        </p>
        <p>KIA타이거즈 사무실</p>
        <p>사업등록번호 : 409-81-02 844 | </p>
        <p>통신판매신고 : 광주북구 2009-138호 <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=4098102844" target="_blank"> [사업자정보확인]</a>  | </p>
        <p>호스팅사업자 : 현대오토에버(주) | </p>
        <p>전화 : 070-7686-8000 | 팩스 : 062-525-5350 | <br className="mb" />
        이메일 : kiatigers@kiatigers.co.kr</p>
        <p className="mb_copy">
          Copyright © 2020 KIA TIGERS. All rights reserved.
        </p>
      </div>
    </footer>
  ) : null;

export default FooterComponent;
