import axios from "axios"

const baseUrl = "/v1"

const isDevEnvironment = process.env.NODE_ENV === "development"

function mockResponse(data, delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, delay ?? 1000);
  });
}

const mockProducts = [
  {
    productid: 1,
    productName: "테스트상품",
    price: 10,
    imageInfoList: [
      {
        imageId: 1,
        path: "https://loremflickr.com/320/240/baseball,goods"
      },
      {
        imageId: 2,
        path: "https://loremflickr.com/320/240/baseball,goods"
      }
    ],
    optionsInfoList: [{optionName: "사이즈", productOptionId: 1}],
    status: "INSTOCK"
  },
  {
    productid: 2,
    productName: "테스트상품2",
    price: 10,
    imageInfoList: [
      {
        imageId: 1,
        path: "https://loremflickr.com/320/240/baseball,goods"
      },
      {
        imageId: 2,
        path: "https://loremflickr.com/320/240/baseball,goods"
      }
    ],
    optionsInfoList: [
      {optionName: "사이즈", productOptionId: 1},
      {optionName: "사이즈", productOptionId: 2}
    ],
    productDescription: "제품 설명",
    getProductDetailInfo: "https://loremflickr.com/320/1000/goods",
    status: "INSTOCK",
  },
];
// 상품 목록 읽어오기
export const getProducts = () => {
  if (isDevEnvironment) {
    return mockResponse(mockProducts);
  }
  return getInternal("/scorePoint/product/list").then((data) => data.list)
}

const mockDetail = {
  productId: 1,
  productName: "테스트상품",
  price: 10,
  productDescription:
    "### 상품종류\n\n" +
    " -모바일교환권\n\n" +
    "---\n\n" +
    "### 유효기간\n\n" +
    " -구매 다음 날부터 30일 이내\n\n" +
    "---\n\n" +
    "### 이용처\n\n" +
    " -포인트로만 교환 가능 (현금과 함께 사용 불가능)\n\n" +
    " -타상품으로 교환 불가능",
  productDetailInfo: "https://loremflickr.com/320/1000/goods",
  termsOfUse: null,
  refundMethods: null,
  status: "SOLDOUT",
  imgInfoList: [
    {
      imageId: 1,
      path: "localhost:8080"
    },
    {
      imageId: 2,
      path: "localhost:8081"
    }
  ],
  optionInfoList: [
    // {
    //   "productOptionId": 3,
    //   "optionName": "사이즈(100)"
    // },
    // {
    //   "productOptionId": 2,
    //   "optionName": "사이즈(95)"
    // },
    // {
    //   "productOptionId": 1,
    //   "optionName": "사이즈(90)"
    // }
  ],
};
// 상품 상세
export const getProductDetail = (id) => {
  if (isDevEnvironment) {
    return mockResponse(mockDetail);
  }
  return getInternal(`/scorePoint/product/${id}`).then((data) => data.detail)
}

// 포인트
export const getPoint = () => {
  if (isDevEnvironment) {
    return mockResponse(100);
  }
  return getInternal("/scorePoint").then((data) => data.point)
}

// 포인트 히스토리
export const getPointHistory = async (opCode) => getInternal(`/scorePoint/hist?opCode=${opCode}`).then((data) => data.scorePoint)

// 상품 주문
export const orderProduct = async (order) => {
  const form = new FormData()
  form.append("ordCnt", order.ordCnt)
  form.append("productId", order.productId)
  form.append("point", order.point)
  if (order.productOptionId) {
    form.append("productOptionId", order.productOptionId)
  }
  console.log(form.get("productId"));
  if (isDevEnvironment) {
    return mockResponse(Math.random() * 10 > 5);
  }
  let url = `${baseUrl}/scorePoint/ord`
  return axios
    .post(url, form)
    .then(({status, data}) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data.data.result;
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message)
    })
}

// 공통 로직
export const getInternal = async (endPoint) => {
  let url = `${baseUrl}${endPoint}`
  return axios
    .get(url)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        // throw new Error(data.message)
      }
      return data.data
    })
    .catch((err) => {
      console.error(err)
      // throw new Error(err.message)
    })
}
