import React from "react";
import { Link } from "react-router-dom";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont game msr tabLeft">
    <div className="mb_contIn">
      <div className="tabWrap">
        <ul className="tab01 clear">
          <li className={props.thirdMenu === "major" ? "on" : ""}>
            <Link to="/game/schedule/major">1군</Link>
          </li>
          <li className={props.thirdMenu === "minor" ? "on" : ""}>
            <Link to="/game/schedule/minor">퓨처스리그</Link>
          </li>
        </ul>
      </div>

      <section className="section01 pc">
        <ul className="clear">
          {props.recentlyGamesPrev ? (
            <li>
              <p>
                {props.recentlyGamesPrev.gyear}.{props.recentlyGamesPrev.gmonth}
                .{props.recentlyGamesPrev.gday}({props.recentlyGamesPrev.gweek})
              </p>
              <span>
                {props.recentlyGamesPrev.stadium}
                {props.recentlyGamesPrev.gtime}
              </span>
              <div className="clear">
                <div className="teamOn teamL">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesPrev.visitEmblemUrl}
                        alt={props.recentlyGamesPrev.visitFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesPrev.visit}</figcaption>
                  </figure>
                  <p className="info">
                    {props.recentlyGamesPrev.visitDecision === undefined
                      ? "선발 : " +
                      (props.recentlyGamesPrev.visitStarter
                        ? props.recentlyGamesPrev.visitStarter
                        : "미정")
                      : props.recentlyGamesPrev.visitDecision +
                      (props.recentlyGamesPrev.visitDecisionPitcher
                        ? " : " + props.recentlyGamesPrev.visitDecisionPitcher
                        : "")}
                  </p>
                </div>
                <div className="gameOn clear">
                  <p>
                    <strong className="score teamL">
                      {props.recentlyGamesPrev.visitScore}
                    </strong>
                    <span>vs</span>
                    <strong className="score teamR">
                      {props.recentlyGamesPrev.homeScore}
                    </strong>
                  </p>
                  <span
                    onClick={(evt) =>
                      props.onClickBtnGameInfo(
                        evt,
                        props.recentlyGamesPrev.gmkey,
                        props.recentlyGamesPrev.displayDate
                      )
                    }
                    className="bdRndBox"
                    style={{ cursor: "pointer" }}
                  >
                    경기결과
                  </span>
                </div>
                <div className="teamOn teamR">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesPrev.homeEmblemUrl}
                        alt={props.recentlyGamesPrev.homeFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesPrev.home}</figcaption>
                  </figure>
                  <p className="info">
                    {props.recentlyGamesPrev.homeDecision === undefined
                      ? "선발 :" +
                      (props.recentlyGamesPrev.homeStarter
                        ? props.recentlyGamesPrev.homeStarter
                        : "미정")
                      : props.recentlyGamesPrev.homeDecision +
                      (props.recentlyGamesPrev.homeDecisionPitcher
                        ? " :" + props.recentlyGamesPrev.homeDecisionPitcher
                        : "")}
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <li style={{ visibility: "hidden" }}></li>
          )}
          {props.recentlyGamesCurrent ? (
            <li className="center">
              <p>
                {props.recentlyGamesCurrent.gyear}.
                {props.recentlyGamesCurrent.gmonth}.
                {props.recentlyGamesCurrent.gday}(
                {props.recentlyGamesCurrent.gweek})
              </p>
              <span>
                {props.recentlyGamesCurrent.stadium}
                {props.recentlyGamesCurrent.gtime}
              </span>
              <div className="clear">
                <div className="teamOn teamL">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesCurrent.visitEmblemUrl}
                        alt={props.recentlyGamesCurrent.visitFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesCurrent.visit}</figcaption>
                  </figure>
                  <p className="info">
                    {!props.recentlyGamesCurrent.visitDecision
                      ? "선발 : " +
                      (!props.recentlyGamesCurrent.visitStarter
                        ? "미정"
                        : props.recentlyGamesCurrent.visitStarter)
                      : props.recentlyGamesCurrent.visitDecision +
                      " :" +
                      props.recentlyGamesCurrent.visitDecisionPitcher}
                  </p>
                </div>
                <div className="gameOn clear">
                  <p>
                    <strong className="score teamL">
                      {!props.recentlyGamesCurrent.visitScore
                        ? "0"
                        : props.recentlyGamesCurrent.visitScore}
                    </strong>
                    <span>vs</span>
                    <strong className="score teamR">
                      {!props.recentlyGamesCurrent.homeScore
                        ? "0"
                        : props.recentlyGamesCurrent.homeScore}
                    </strong>
                  </p>
                  {props.recentlyGamesCurrent.outcome === "취" ? (
                    <span className="bdRndBox">경기취소</span>
                  ) : props.recentlyGamesCurrent.outcome === "중" ? (
                    <span
                      onClick={(evt) => {
                        window.location.href = "/game/baseball";
                      }}
                      className="bdRndBox"
                      style={{ cursor: "pointer" }}
                    >
                      경기중
                    </span>
                  ) : (
                    <span
                      onClick={(evt) => {
                        props.recentlyGamesCurrent.outcome.trim() !== "" &&
                        props.onClickBtnGameInfo(
                          evt,
                          props.recentlyGamesCurrent.gmkey,
                          props.recentlyGamesCurrent.displayDate
                        );
                      }}
                      className="bdRndBox"
                      style={{ cursor: "pointer" }}
                    >
                      경기결과
                    </span>
                  )}
                </div>
                <div className="teamOn teamR">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesCurrent.homeEmblemUrl}
                        alt={props.recentlyGamesCurrent.homeFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesCurrent.home}</figcaption>
                  </figure>
                  <p className="info">
                    {!props.recentlyGamesCurrent.homeDecision
                      ? "선발 : " +
                      (!props.recentlyGamesCurrent.homeStarter
                        ? "미정"
                        : props.recentlyGamesCurrent.homeStarter)
                      : props.recentlyGamesCurrent.homeDecision +
                      " : " +
                      props.recentlyGamesCurrent.homeDecisionPitcher}
                  </p>
                </div>
              </div>
            </li>
          ) : null}
          {props.recentlyGamesNext ? (
            <li>
              <p>
                {props.recentlyGamesNext.gyear}.{props.recentlyGamesNext.gmonth}
                .{props.recentlyGamesNext.gday}({props.recentlyGamesNext.gweek})
              </p>
              <span>
                {props.recentlyGamesNext.stadium}{" "}
                {props.recentlyGamesNext.gtime}
              </span>
              <div className="clear">
                <div className="teamOn teamL">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesNext.visitEmblemUrl}
                        alt={props.recentlyGamesNext.visitFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesNext.visit}</figcaption>
                  </figure>
                  <p className="info">
                    선발 :{" "}
                    {!props.recentlyGamesNext.visitStarter
                      ? "미정"
                      : props.recentlyGamesNext.visitStarter}
                  </p>
                </div>
                <div className="gameOn clear">
                  <p>
                    <strong className="score teamL"></strong>
                    <span>vs</span>
                    <strong className="score teamR"></strong>
                  </p>
                </div>
                <div className="teamOn teamR">
                  <figure>
                    <p className="emble">
                      <img
                        src={props.recentlyGamesNext.homeEmblemUrl}
                        alt={props.recentlyGamesNext.homeFullname}
                      />
                    </p>
                    <figcaption>{props.recentlyGamesNext.home}</figcaption>
                  </figure>
                  <p className="info">
                    선발 :{" "}
                    {!props.recentlyGamesNext.homeDecisionPitcher
                      ? "미정"
                      : props.recentlyGamesNext.homeDecisionPitcher}
                  </p>
                </div>
              </div>
            </li>
          ) : (
            <li>
              <p></p>
              <span></span>
              <div className="noGame clear">예정된 경기가 없습니다.</div>
            </li>
          )}
        </ul>
      </section>
      <section className="section02">
        <div className="calendar">
          <div className="calTop por tCenter clear">
            <div className="control">
              <select
                name=""
                id=""
                className="selYear"
                value={props.selectedYear}
                onChange={props.onChangeSelectedYear}
              >
                {props.yearSelectList.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              <select
                name=""
                id=""
                className="selMonth"
                value={props.selectedMonth}
                onChange={props.onChangeSelectedMonth}
              >
                {props.monthSelectList.map((item, idx) => (
                  <option
                    key={idx}
                    value={item.value < 10 ? "0" + item.value : item.value}
                  >
                    {item.text}
                  </option>
                ))}
              </select>
              {/* <button
                type="button"
                className="calBtn"
                onClick={props.onClickBtnShow}
              >
                이동
              </button> */}
              <a onClick={props.onClickBtnPrevMonth} className="prev fll">
                <img src="/img/sub/btn_arr.png" alt="이전" />
              </a>
              <a onClick={props.onClickBtnNextMonth} className="next flr">
                <img src="/img/sub/btn_arr.png" alt="다음" />
              </a>
            </div>

            <div className="calInfo">
              <em className="bullet org">DH</em>더블헤더
              <em className="circle home"></em>홈경기
              <em className="circle"></em>원정경기
            </div>
          </div>
          <table className="table_calendar bdtRed">
            <colgroup>
              <col width="14.2%" />
              <col width="14.2%" />
              <col width="14.2%" />
              <col width="14.2%" />
              <col width="14.2%" />
              <col width="14.2%" />
              <col width="14.2%" />
            </colgroup>
            <thead>
            <tr>
              <th>일</th>
              <th>월</th>
              <th>화</th>
              <th>수</th>
              <th>목</th>
              <th>금</th>
              <th>토</th>
            </tr>
            </thead>
            <tbody>
            {props.monthlyScheduleObj.map((weeklyScheduleObj, weeklyIdx) => (
              <tr key={weeklyIdx}>
                {weeklyScheduleObj.map((dailyScheduleObj, dailyIdx) =>
                  !dailyScheduleObj ? (
                    <td key={dailyIdx}>
                      <span className="date"></span>
                    </td>
                  ) : !dailyScheduleObj["info"] ? (
                    <td key={dailyIdx}>
                      <span className="date">{dailyScheduleObj.day}</span>
                    </td>
                  ) : dailyScheduleObj["info"]["gameStatus"] === "2" &&
                  dailyScheduleObj["info"]["gameResult"] !== "취" ? (
                    <td
                      key={dailyIdx}
                      className={
                        dailyScheduleObj["info"]["isHomeGame"] ? "home" : ""
                      }
                    >
                      <span className="date">{dailyScheduleObj.day}</span>
                      <img
                        src={dailyScheduleObj["info"]["matchTeamEmblemUrl"]}
                        alt={dailyScheduleObj["info"]["matchTeamName"]}
                        className="emble"
                      />
                      <p className="time pc">
                        {dailyScheduleObj["info"]["stadiumName"]}
                        {dailyScheduleObj["info"]["gameTime"]}
                      </p>
                      <strong
                        className="result"
                        style={
                          !dailyScheduleObj["info"]["doubleHeader"] ||
                          dailyScheduleObj["info"]["doubleHeader"][
                            "gameResult"
                            ] === ""
                            ? { cursor: "pointer" }
                            : { cursor: "pointer" }
                        }
                      >
                        <em
                          className="bullet red"
                          onClick={() => {
                            window.location.href = "/game/baseball";
                          }}
                        >
                          ON AIR
                        </em>
                      </strong>
                    </td>
                  ) : (
                    <td
                      key={dailyIdx}
                      className={
                        dailyScheduleObj["info"]["isHomeGame"] ? "home" : ""
                      }
                    >
                      <span className="date">{dailyScheduleObj.day}</span>
                      <img
                        src={dailyScheduleObj["info"]["matchTeamEmblemUrl"]}
                        alt={dailyScheduleObj["info"]["matchTeamName"]}
                        className="emble"
                      />
                      <p className="time pc">
                        {dailyScheduleObj["info"]["stadiumName"]}
                        {dailyScheduleObj["info"]["gameTime"]}
                        {dailyScheduleObj.info.doubleHeader
                          ? " / " +
                          dailyScheduleObj.info.doubleHeader.gameTime
                          : null}
                      </p>
                      {dailyScheduleObj["info"]["gameStatus"] ||
                      dailyScheduleObj["info"]["gameResult"] != "" ? null : (
                        <p className="time tb">
                          {dailyScheduleObj["info"]["stadiumName"]}
                          <br />
                          {dailyScheduleObj["info"]["gameTime"]}
                        </p>
                      )}
                      {dailyScheduleObj["info"]["doubleHeader"] &&
                      !dailyScheduleObj["info"]["gameResult"] ? (
                        <em className="bullet org">DH</em>
                      ) : null}
                      <p
                        className="mbPoa"
                        style={
                          dailyScheduleObj["info"]["gameResult"] !== "취"
                            ? { cursor: "pointer" }
                            : { cursor: "" }
                        }
                      >
                        {dailyScheduleObj["info"]["gameResult"] === "승" ? (
                          <strong
                            onClick={(evt) =>
                              props.onClickBtnGameInfo(
                                evt,
                                dailyScheduleObj.info.gameKey,
                                dailyScheduleObj.info.gameDate,
                                dailyScheduleObj.info.gameResult
                              )
                            }
                            className="result red"
                          >
                            <em className="circle">승</em>
                            <span>
                                {dailyScheduleObj["info"]["awayScore"]}
                              &nbsp;:&nbsp;
                              {dailyScheduleObj["info"]["homeScore"]}
                              </span>
                          </strong>
                        ) : dailyScheduleObj["info"]["gameResult"] === "서" &&
                        dailyScheduleObj["info"]["suspendedFlag"] ===
                        "1" ? (
                          <strong className="result gray">
                            <em className="circle">S</em>
                          </strong>
                        ) : null}
                        {dailyScheduleObj["info"]["gameResult"] === "패" ? (
                          <strong
                            className="result blue"
                            onClick={(evt) =>
                              props.onClickBtnGameInfo(
                                evt,
                                dailyScheduleObj.info.gameKey,
                                dailyScheduleObj.info.gameDate,
                                dailyScheduleObj.info.gameResult
                              )
                            }
                          >
                            <em className="circle">패</em>
                            <span>
                                {dailyScheduleObj["info"]["awayScore"]}
                              &nbsp;:&nbsp;
                              {dailyScheduleObj["info"]["homeScore"]}
                              </span>
                          </strong>
                        ) : null}
                        {dailyScheduleObj["info"]["gameResult"] === "무" ? (
                          <strong
                            className="result gray"
                            onClick={(evt) =>
                              props.onClickBtnGameInfo(
                                evt,
                                dailyScheduleObj.info.gameKey,
                                dailyScheduleObj.info.gameDate,
                                dailyScheduleObj.info.gameResult
                              )
                            }
                          >
                            <em className="circle">무</em>
                            <span>
                                {dailyScheduleObj["info"]["awayScore"]}
                              &nbsp;:&nbsp;
                              {dailyScheduleObj["info"]["homeScore"]}
                              </span>
                          </strong>
                        ) : null}
                        {dailyScheduleObj["info"]["gameResult"] === "취" ? (
                          <strong className="result gray">
                            <em className="circle">취</em>
                          </strong>
                        ) : null}
                        {dailyScheduleObj["info"]["doubleHeader"] ? (
                          dailyScheduleObj["info"]["doubleHeader"][
                            "gameStatus"
                            ] === "2" ? (
                            <strong className="double-game">
                              <br className="pc" />
                              <em
                                className="bullet red"
                                onClick={() => {
                                  window.location.href = "/game/baseball";
                                }}
                              >
                                ON AIR
                              </em>
                            </strong>
                          ) : (
                            <div
                              onClick={(evt) => {
                                if (
                                  dailyScheduleObj["info"]["doubleHeader"][
                                    "gameResult"
                                    ] !== "취"
                                ) {
                                  props.onClickBtnGameInfo(
                                    evt,
                                    dailyScheduleObj.info.doubleHeader
                                      .gameKey,
                                    dailyScheduleObj.info.doubleHeader
                                      .gameDate,
                                    dailyScheduleObj.info.doubleHeader
                                      .gameResult
                                  );
                                }
                              }}
                              style={
                                dailyScheduleObj["info"]["doubleHeader"][
                                  "gameResult"
                                  ] !== "취"
                                  ? { cursor: "pointer" }
                                  : { cursor: "auto" }
                              }
                            >
                              {dailyScheduleObj["info"]["doubleHeader"][
                                "gameResult"
                                ] === "승" ? (
                                <strong className="result red">
                                  <em className="circle">승</em>
                                  <span>
                                      {
                                        dailyScheduleObj["info"][
                                          "doubleHeader"
                                          ]["awayScore"]
                                      }
                                    &nbsp;:&nbsp;
                                    {
                                      dailyScheduleObj["info"][
                                        "doubleHeader"
                                        ]["homeScore"]
                                    }
                                    </span>
                                </strong>
                              ) : null}
                              {dailyScheduleObj["info"]["doubleHeader"][
                                "gameResult"
                                ] === "패" ? (
                                <strong className="result blue">
                                  <em className="circle">패</em>
                                  <span>
                                      {
                                        dailyScheduleObj["info"][
                                          "doubleHeader"
                                          ]["awayScore"]
                                      }
                                    &nbsp;:&nbsp;
                                    {
                                      dailyScheduleObj["info"][
                                        "doubleHeader"
                                        ]["homeScore"]
                                    }
                                    </span>
                                </strong>
                              ) : null}
                              {dailyScheduleObj["info"]["doubleHeader"][
                                "gameResult"
                                ] === "무" ? (
                                <strong className="result gray">
                                  <em className="circle">무</em>
                                  <span>
                                      {
                                        dailyScheduleObj["info"][
                                          "doubleHeader"
                                          ]["awayScore"]
                                      }
                                    &nbsp;:&nbsp;
                                    {
                                      dailyScheduleObj["info"][
                                        "doubleHeader"
                                        ]["homeScore"]
                                    }
                                    </span>
                                </strong>
                              ) : null}
                              {dailyScheduleObj["info"]["doubleHeader"][
                                "gameResult"
                                ] === "취" ? (
                                <strong className="result gray">
                                  <em className="circle">취</em>
                                </strong>
                              ) : null}
                            </div>
                          )
                        ) : null}
                      </p>
                    </td>
                  )
                )}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
);

export default ContComponent;
