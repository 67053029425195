import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import ListContainer from './list/List.container';
import FormContainer from './form/Form.container';
import InfoContainer from './info/Info.container';

import * as AuthHelper from '../../../helpers/Auth.helper';

import AuthNameConfirmPopup from '../../common/AuthNameConfirmPopup.component';
import AlertPopup from '../../common//AlertPopup.component';

class TigerContainer extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            isInit: false,
            isOnShowNameAuthConfirmPopup: false,
            isOnShowAlertPopup: false
        });
    }

    componentDidMount = _ => {
        try {
            if (!AuthHelper.checkHasLogin()) {
                alert("로그인 후 이용하실 수 있는 페이지입니다.");
                AuthHelper.removeLoginInfo();
                document.location.href = '/login';
            } else if (!AuthHelper.checkHasRealName()) {
                this.setState({ isOnShowNameAuthConfirmPopup: true });
            } else {
                this.setState({ isInit: true });
            }
        } catch (err) {
            console.error(err);
            //alert(err.message);
        }
    }

    onClickBtnCancelAuthNamePopup = _ => {
        this.props.history.goBack();
    }
    onClickBtnConfirmAuthNamePopup = _ => {
        this.props.history.push('/mypage');
    }

    onClickBtnOkAlertPopup = _ => {
        this.props.history.goBack();
    }

    render = _ => (
        this.state.isInit ?
            <Switch>
                <Route exact path={`${this.props.match.path}`} component={ListContainer} />
                <Route exact path={`${this.props.match.path}/write`} component={FormContainer} />
                <Route exact path={`${this.props.match.path}/:articleId`} component={InfoContainer} />
                <Route exact path={`${this.props.match.path}/:articleId/modify`} component={FormContainer} />
            </Switch>
            :
            <div>
                {this.state.isOnShowNameAuthConfirmPopup ?
                    <AuthNameConfirmPopup
                        onClickBtnCancel={this.onClickBtnCancelAuthNamePopup}
                        onClickBtnConfirm={this.onClickBtnConfirmAuthNamePopup} /> : null}
                {this.state.isOnShowAlertPopup ?
                    <AlertPopup
                        message={'"평생회원" 혹은 "티클럽 회원"인 경우에만 이용 가능합니다.'}
                        onClickBtnOk={this.onClickBtnOkAlertPopup} /> : null}
            </div>

    )
}

export default TigerContainer;
