import React from "react";

import SubMenuComponent from "../../common/SubMenu.component";
import EntryTabComponent from "../common/EntryTab.component";

import PlayerListSliderComponent from "../../common/info/PlayerListSlider.component";

import NewsListSliderComponent from "../../common/info/NewsListSlider.component";
import GalleryListSliderComponent from "../../common/info/GalleryListSlider.component";
import DetailProfileComponent from "../../common/info/DetailProfile.component";

import RegularRecordComponent from "../../common/info/RegularRecord.component";
import GalleryPopupContainer from "../../common/info/GalleryPopup.container";

import ImgComponent from "../../../common/Img.component";
import * as ImageHelper from "../../../../helpers/Image.helper";

const ContComponent = (props) => (
  <div className="mb_cont sub_cont player">
    <div className="mb_contIn">
      <SubMenuComponent
        subMenu={props.subMenu}
        subMenuName={props.subMenuName}
      />
      <EntryTabComponent
        onClickBtnEntryTab={props.onClickBtnEntryTab}
        entryTabNum={props.entryTabNum}
      />
      <PlayerListSliderComponent {...props} />
    </div>
    {props.info ? (
      <section className="plyr_view01">
        <h6 className="blind">프로필</h6>
        <div className="plyrVs">
          <div className="plyrImg">
            <p>
              <ImgComponent
                imgUrl={props.info.playerProfileImg}
                defaultImgUrl={"https://tigers.co.kr/files/playerImg/tigersImg2/00002_2022-11-04_090908.jpg"}
                alt="선수사진"
              />
            </p>
          </div>
          <div className="plyr_view01In">
            <p>
              {props.info.position2
                ? props.info.position2
                : props.info.position}
              /{props.info.hittype}
            </p>
            <h5>
              <strong>{props.info.backnum}</strong> {props.info.playerName}
            </h5>
            <dl>
              <dt>생년월일</dt>
              <dd>
                {props.info.birth.substr(0, 4) + "년"}&nbsp;
                {props.info.birth.substr(4, 2) + "월"}&nbsp;
                {props.info.birth.substr(6, 2) + "일"}&nbsp;
              </dd>
              <dt>프로입단</dt>
              <dd>
                {props.info.indate.substr(0, 4) + "년"}&nbsp;
                {/*{props.info.indate.substr(4, 2) + "월"}&nbsp;*/}
                {/* {props.info.indate.substr(6, 2) + "일"}&nbsp; */}
                &nbsp;
              </dd>
              <dt>신장/체중</dt>
              {/* <dd>{props.info.height} / {props.info.weight}</dd> */}
              <dd>{props.info.heightWeight}&nbsp;</dd>
              <dt>출신학교</dt>
              <dd>{props.info.career}&nbsp;</dd>
            </dl>
            {/* <dl>
              <dt>입단연도</dt>
                                <dd>{props.info.gyear}</dd>
              <dt>계약금/연봉</dt>
              <dd>
                {props.info.promise
                  ? props.info.promise
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "-"}
                <br className="pc" />
                /&nbsp;
                {props.info.money
                  ? props.info.money
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "-"}
              </dd>
            </dl> */}
          </div>
        </div>
      </section>
    ) : null}
    {props.info ? (
      <section className="view_tab view_tab01 pc">
        <h6 className="blind">탭메뉴</h6>
        {/* 뉴스 리스트  */}
        <div className="view_tabIn">
          <dl className={props.subTabNum === 1 ? "on" : ""}>
            <dt
              className={props.subTabNum === 1 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 1)}
            >
              NEWS
            </dt>
            <dd>
              {props.newsList.length === 0 ? (
                <div className="empty_photo news">
                  <h1>자료가 없습니다.</h1>
                </div>
              ) : (
                <div>
                  <ul className="news" style={{ marginLeft: "22px" }}>
                    <NewsListSliderComponent {...props} />
                  </ul>
                  {props.newsList.length > 4 && (
                    <div>
                      <p
                        className="news_prev"
                        onClick={(evt) =>
                          props.onClickBtnSlidePrev(evt, "news")
                        }
                      >
                        <img
                          src="/img/sub/view_prev.png"
                          alt="슬라이드 이전버튼"
                        />
                      </p>
                      <p
                        className="news_next"
                        onClick={(evt) =>
                          props.onClickBtnSlideNext(evt, "news")
                        }
                      >
                        <img
                          src="/img/sub/view_next.png"
                          alt="슬라이드 다음버튼"
                        />
                      </p>
                    </div>
                  )}
                </div>
              )}
            </dd>
          </dl>
          <dl className={props.subTabNum === 2 ? "on" : ""}>
            <dt
              className={props.subTabNum === 2 ? "act" : ""}
              onClick={(evt) => props.onClickBtnSubTab(evt, 2)}
            >
              GALLERY
            </dt>
            <dd>
              {props.galleryList.length === 0 ? (
                <div className="empty_photo gallery pc">
                  <h1>자료가 없습니다.</h1>
                </div>
              ) : (
                <div>
                  <ul className="gallery" style={{ marginLeft: "22px" }}>
                    <GalleryListSliderComponent {...props} />
                  </ul>
                  {props.galleryList.length > 4 && (
                    <div>
                      <p
                        className="gallery_prev"
                        onClick={(evt) =>
                          props.onClickBtnSlidePrev(evt, "gallery")
                        }
                      >
                        <img
                          src="/img/sub/view_prev.png"
                          alt="슬라이드 이전버튼"
                        />
                      </p>
                      <p
                        className="gallery_next"
                        onClick={(evt) =>
                          props.onClickBtnSlideNext(evt, "gallery")
                        }
                      >
                        <img
                          src="/img/sub/view_next.png"
                          alt="슬라이드 다음버튼"
                        />
                      </p>
                    </div>
                  )}
                </div>
              )}
            </dd>
          </dl>
          <DetailProfileComponent {...props} />
        </div>
      </section>
    ) : null}
    <section className="view_tab view_tab01 clear">
      <h6 className="blind">상세기록 TAB MENU</h6>
      <div
        className="viewBtn view_tabIn viewTabLitt viewTabPc1 divHeight"
        style={{ height: props.subtabTabletHeight }}
      >
        {/* <dl
          className={
            props.screenMode === "pc" || props.subTabNum === 1 ? "on" : ""
          }
        >
          <dt
            className={
              props.screenMode === "pc" || props.subTabNum === 1 ? "act" : ""
            }
            onClick={(evt) => props.onClickBtnSubTab(evt, 1)}
          >
            상세기록
          </dt>
          <dd>
            <h6>통산기록</h6>
            <div className="table_Typ01 mbScroll tableW19 mbThNofix">
              <RegularRecordComponent {...props} />
            </div>
          </dd>
        </dl> */}

        <dl className={props.subTabNum === 2 ? "tb on" : "tb"}>
          <dt
            className={props.subTabNum === 2 ? "act" : ""}
            onClick={(evt) => props.onClickBtnSubTab(evt, 2)}
          >
            NEWS
          </dt>
          <dd>
            <ul className="mb_news">
              {props.newsList.map((item, idx) =>
                idx < 3 ? (
                  <li
                    key={idx}
                    onClick={(evt) => props.onClickBtnNews(evt, item.artcSeq)}
                  >
                    <div className="img">
                      <ImgComponent
                        imgUrl={item.imgFilePath}
                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                        imgName={item.artcTitle}
                      />
                    </div>
                    <div>
                      <p>{item.artcTitle}</p>
                      <p>{item.contents}</p>
                      <p>
                        <span className="date">{item.regDate}</span>
                        <span className="num">{item.viewCnt}</span>
                      </p>
                    </div>
                  </li>
                ) : null
              )}
            </ul>
          </dd>
        </dl>
        <dl className={props.subTabNum === 3 ? "tb on" : "tb"}>
          <dt
            className={props.subTabNum === 3 ? "act" : ""}
            onClick={(evt) => props.onClickBtnSubTab(evt, 3)}
          >
            GALLERY
          </dt>
          <dd>
            <ul className="mb_gallery clear">
              {props.galleryList.map((item, idx) =>
                idx < 4 ? (
                  <li
                    key={idx}
                    onClick={(evt) =>
                      props.onClickBtnPicture(evt, item.artcSeq)
                    }
                  >
                    <p>
                      {console.log(item)}
                      <ImgComponent
                        imgUrl={item.path}
                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                        imgName={item.description}
                      />
                    </p>
                  </li>
                ) : null
              )}
            </ul>
          </dd>
        </dl>
      </div>
    </section>
    {props.selectedPopupContent ? <GalleryPopupContainer {...props} /> : null}
  </div>
);

export default ContComponent;
