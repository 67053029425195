import React from 'react';
import SubMenuComponent from '../../common/SubMenu.component';
// import { Link } from 'react-router-dom';

const ContComponent = (props) => (
    <div className="mb_cont sub_cont tigers champ">
        <div className="mb_contIn">
            <SubMenuComponent
                thirdMenu={props.thirdMenu} />
            <div className="champStd">
            <article>
                    <h5 className="lineRed">관람석</h5>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(1, 1)}><img src="/img/sub/champ_stadium01_01.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 2)}><img src="/img/sub/champ_stadium01_02.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 3)}><img src="/img/sub/champ_stadium01_03.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 4)}><img src="/img/sub/champ_stadium01_04.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 5)}><img src="/img/sub/champ_stadium01_05.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 6)}><img src="/img/sub/champ_stadium01_06.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 7)}><img src="/img/sub/champ_stadium01_07.png" alt="관람석 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(1, 8)}><img src="/img/sub/champ_stadium01_08.png" alt="관람석 사진" /></a></li>
                    </ul>
                </article>
                <article>
                    <h5 className="lineRed">선수단 시설</h5>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(2, 1)}><img src="/img/sub/champ_stadium02_01.png" alt="선수단시설 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(2, 2)}><img src="/img/sub/champ_stadium02_02.png" alt="선수단시설 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(2, 3)}><img src="/img/sub/champ_stadium02_03.png" alt="선수단시설 사진" /></a></li>
                        <li></li>
                    </ul>
                </article>
                <article>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(2, 4)}><img src="/img/sub/champ_stadium02_04.png" alt="선수단시설 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(2, 5)}><img src="/img/sub/champ_stadium02_05.png" alt="선수단시설 사진" /></a></li>
                    </ul>
                </article>
                <article>
                    <h5 className="lineRed">경기장 외부</h5>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(4, 1)}><img src="/img/sub/champ_stadium04_01.png" alt="경기장외부 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(4, 2)}><img src="/img/sub/champ_stadium04_02.png" alt="경기장외부 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(4, 3)}><img src="/img/sub/champ_stadium04_03.png" alt="경기장외부 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(4, 4)}><img src="/img/sub/champ_stadium04_04.png" alt="경기장외부 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(4, 5)}><img src="/img/sub/champ_stadium04_05.png" alt="경기장외부 사진" /></a></li>
                    </ul>
                </article>
                <article>
                    <h5 className="lineRed">팬존</h5>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(5, 1)}><img src="/img/sub/champ_stadium05_01.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 2)}><img src="/img/sub/champ_stadium05_02.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 3)}><img src="/img/sub/champ_stadium05_03.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 4)}><img src="/img/sub/champ_stadium05_04.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 5)}><img src="/img/sub/champ_stadium05_05.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 6)}><img src="/img/sub/champ_stadium05_06.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 7)}><img src="/img/sub/champ_stadium05_07.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 8)}><img src="/img/sub/champ_stadium05_08.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 9)}><img src="/img/sub/champ_stadium05_09.png" alt="팬존 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(5, 10)}><img src="/img/sub/champ_stadium05_10.png" alt="팬존 사진" /></a></li>
                    </ul>
                </article>
                <article>
                    <h5 className="lineRed">스카이 박스</h5>
                    <ul className="clear">
                        <li><a onClick={evt => props.onClickBtnImg(6, 1)}><img src="/img/sub/champ_stadium06_01.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 2)}><img src="/img/sub/champ_stadium06_02.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 3)}><img src="/img/sub/champ_stadium06_03.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 4)}><img src="/img/sub/champ_stadium06_04.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 5)}><img src="/img/sub/champ_stadium06_05.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 6)}><img src="/img/sub/champ_stadium06_06.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 7)}><img src="/img/sub/champ_stadium06_07.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 8)}><img src="/img/sub/champ_stadium06_08.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 9)}><img src="/img/sub/champ_stadium06_09.png" alt="스카이박스 사진" /></a></li>
                        <li><a onClick={evt => props.onClickBtnImg(6, 10)}><img src="/img/sub/champ_stadium06_10.png" alt="스카이박스 사진" /></a></li>
                    </ul>
                </article>
            </div>
        </div>
        {/* <!-- //mb_contIn --> */}
    </div>
)
export default ContComponent