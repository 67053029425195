import React from 'react';

const SubHeadComponent = (props) => (
    <div className="sub_head login">
        <div className="sub_HeadIn1">
            <h2>아이디 찾기</h2>
            <ul>
                <li><img src="../../img/sub/ico_home.png" alt="홈 바로가기" /></li>
                <li>로그인</li>
                <li>아이디 찾기</li>
            </ul>
        </div>
        <div className="sub_HeadIn2 tb"></div>
    </div>
)

export default SubHeadComponent;