import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import AnalyticsContext from "../../../context/AnalyticsContext"
import * as CommonService from "../../../services/Common.service"

import * as ResourceService from "../../../services/Resource.service";

import $ from "jquery";

class ChallengersFieldContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = "challengers-field";
    const subMenuName = "기아 챌린저스 필드";
    this.state = {
      subMenu,
      subMenuName,
      isShowVideoPopup: false
    };
  }

  setSliderRef = (key, ref) => {
    this[key] = ref;
  };

  componentDidMount = async _ => {
    const currentAnalyticsVal = this.context.state
    this.context.action(currentAnalyticsVal);
    const prevUrl = currentAnalyticsVal.currentUrl;

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "04";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    this.getChallengersYoutubeLink();
    this.setState({
      pcSlider1: this.pcSlider1,
      pcSlider2: this.pcSlider2,
      pcSlider3: this.pcSlider3,
      navSlider1: this.navSlider1,
      navSlider2: this.navSlider2,
      navSlider3: this.navSlider3
    });

    $(".field .sec02 h5 span").click(evt => {
      $("html body").css('overflow-y","hidden');
      $(".field .mask").fadeIn();
      $(evt.target)
        .parent()
        .next(".popI")
        .fadeIn();
    });

    $(".field .sec02 .popI .closeX, .field .mask").click(function() {
      $(".field .sec02 .popI").fadeOut();
      $(".field .mask").fadeOut();
      $("html, body").css("overflow-y", "visible");
    });
  };
  getChallengersYoutubeLink = async () => {
    const challengersYoutube = await ResourceService.getChallengersYoutube();
    this.setState({ challengersYoutube: challengersYoutube.resource });
  };

  onClickBtnSliderPrev = (evt, objName) => {
    this.state[objName].slickPrev();
  };
  onClickBtnSliderNext = (evt, objName) => {
    this.state[objName].slickNext();
  };

  onClickBtnShowVideoPopup = evt => {
    this.setState({ isShowVideoPopup: true });
  };
  onClickBtnCloseVideoPopup = evt => {
    this.setState({ isShowVideoPopup: false });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      {/* <div className="sbHead_appView tb"></div> */}

      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        isShowVideoPopup={this.state.isShowVideoPopup}
        onClickBtnShowVideoPopup={this.onClickBtnShowVideoPopup}
        onClickBtnCloseVideoPopup={this.onClickBtnCloseVideoPopup}
        setSliderRef={this.setSliderRef}
        onClickBtnInfo={this.onClickBtnInfo}
        pcSlider1={this.pcSlider1}
        pcSlider2={this.pcSlider2}
        pcSlider3={this.pcSlider3}
        navSlider1={this.navSlider1}
        navSlider2={this.navSlider2}
        navSlider3={this.navSlider3}
        onClickBtnSliderPrev={this.onClickBtnSliderPrev}
        onClickBtnSliderNext={this.onClickBtnSliderNext}
        challengersYoutube={this.state.challengersYoutube}
      />
    </div>
  );
}

export default ChallengersFieldContainer;
