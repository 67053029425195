import React from 'react';

export const BestHitComponent = props => (
    <table>
        <caption>최다도루 현황표</caption>
        <colgroup>
            <col />
            <col />
            <col />
        </colgroup>
        <tbody>
            <tr className="borderHeadTop">
                <th>상대구단별 최다도루</th>
                <td>{props.bestSbObj.matchTeam.sb}</td>
                <td>
                    {props.bestSbObj.matchTeam.imgUrl ?
                        <img src={props.bestSbObj.matchTeam.imgUrl} alt="상대구단 이미지" />
                        : ''}
                </td>
            </tr>
            <tr>
                <th>월별 최다도루</th>
                <td>{props.bestSbObj.monthly.sb}</td>
                <td>{props.bestSbObj.monthly.groupIf}</td>
            </tr>
            <tr>
                <th>요일별 최다도루</th>
                <td>{props.bestSbObj.weekly.sb}</td>
                <td>{props.bestSbObj.weekly.groupIf}</td>
            </tr>
        </tbody>
    </table>
)
export default BestHitComponent;