import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as AuthHelper from "../../../helpers/Auth.helper";
import * as CommonService from "../../../services/Common.service";

import * as ResourceService from "../../../services/Resource.service";

import AuthNameConfirmPopup from "../../common/AuthNameConfirmPopup.component";

import $ from "jquery";
import AnalyticsContext from "../../../context/AnalyticsContext";
import {check_refundAccount, getBankCode, reg_refundAccount} from "../../../services/Ticket.service";

//const baseUrl = 'http://alpha-facility.ticketlink.co.kr/facility/direct/member/reserve?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=';
const baseUrl =
  process.env.REACT_APP_TICKET_URL +
  "/facility/direct/member/reserve?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=";
const redirectUrl = "/app";

const myPageBaseUrl = 
  process.env.REACT_APP_TICKET_URL + 
  "/facility/direct/member/mypage?partnerNo=Txgc6fHhxEo3HzPaDoc6oQ%3D%3D&memberIdEnc=";


  window.gtag('event', "TicketView"); //GA
class RefundAccountContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      subMenu: "refund account",
      subMenuName: "환불계좌 확인",
      reservationUrl: "",
      bankCodeList: [],
      refundAccountInfo: {
        bankCd: "",
        bankAcc: "",
        depositor: "",
        accountName: ""
      },
      requireAccountInfo: false,
      bankList: {}

    };
  }

  componentDidMount = async (_) => {
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 이후 이용 가능합니다.")
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/ticket/refundAccount';
    } else if (!AuthHelper.checkHasRealName()) {
      this.setState({ isOnShowPopup: true });
      return false;
    }


    const loginInfo = AuthHelper.getLoginInfo();

    const userId = loginInfo.user.simpleId;
    const userName = loginInfo.user.memberName;

    const bankCode = await getBankCode();


    const bankList = {};
    for(let i = 0; i < bankCode.length; i++){
      bankList[bankCode[i].bankCd] = bankCode[i].bankCdAlias;
    }
    console.log("BANKLIST : ", bankList)

    //{
    //     "success": true,
    //     "message": "success",
    //     "data": {
    //         "bankCd": null,
    //         "bankAcc": null,
    //         "depositor": null
    //     }
    // }
    const checkAccount = await check_refundAccount();
    console.log(checkAccount, "!!!!!!!!!!!!!!");
    if(checkAccount.bankAcc !== null){
      this.setState({ refundAccountInfo: checkAccount, requireAccountInfo: false, userId: userId, userName: userName, bankList: bankList, bankCodeList: bankCode, accountName: checkAccount.accountName });
    } else {
      this.state.refundAccountInfo.depositor = checkAccount.accountName
      this.setState({ requireAccountInfo: true, userId: userId, userName: userName, bankList: bankList, bankCodeList: bankCode });
    }
  };

  componentWillUnmount = (_) => {
    $(window).unbind("resize");
  };


  onChangeBankAcc = (e) => {
    const { refundAccountInfo } = this.state;
    refundAccountInfo.bankAcc = e.target.value;
    this.setState({ refundAccountInfo: refundAccountInfo });
  };

  onChangeBankCode = (e) => {
    const { refundAccountInfo } = this.state;
    refundAccountInfo.bankCd = e.target.value;
    this.setState({ refundAccountInfo: refundAccountInfo });
  };

  onSubmitBankInfo = async (_) => {
    console.log("@@");
    try {
      const { refundAccountInfo } = this.state;
      if (!refundAccountInfo.bankCd) {
        alert("은행을 선택해주세요.");
        return;
      }
      if (!refundAccountInfo.bankAcc) {
        alert("계좌번호를 입력해주세요.");
        return;
      }

      if (!refundAccountInfo.depositor) {
        alert("예금주를 입력해주세요.");
        return;
      }

      if (this.state.confirm !== "Y") {
        alert("개인정보 수집, 이용에 동의해주세요.");
        return;
      }

      console.log(refundAccountInfo)

      const res = await reg_refundAccount(refundAccountInfo);
      // {"success":true,"message":"success"}
      if (res.success) {
        alert("환불계좌가 등록되었습니다.");
        document.location.reload();
      } else {
        alert(res.message);
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }
  onChangeInputConfirm  = (evt) => {
    this.setState({confirm: evt.target.value})
  }



  render = (_) => (
    <div className="container">

      <ContComponent
        refundAccountInfo={this.state.refundAccountInfo}
        requireAccountInfo={this.state.requireAccountInfo}
        bankList={this.state.bankList}
        bankCodeList={this.state.bankCodeList}
        userId={this.state.userId}
        onChangeBankAcc={this.onChangeBankAcc}
        onChangeBankCode={this.onChangeBankCode}
        onSubmitBankInfo={this.onSubmitBankInfo}
        onChangeInputConfirm={this.onChangeInputConfirm}
      />

    </div>
  );
}

export default RefundAccountContainer;
