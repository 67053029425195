import React from "react";

const ContComponent = props =>
  props.info ? (
    <div className="mb_cont contents">
      <div className="mb_contIn bdView tv">
        <div className="board">
          <h6>{props.info.artcTitle}</h6>
          <div className="h6Bar">
            {props.info.artcTitle}
            <p className="icoWrap">
              <span className="date">{props.info.regDate}</span>
              <span className="view">
                <i></i>
                {props.info.viewCnt}
              </span>
              <span className="like">
                <i></i>
                {props.info.likeCnt}
              </span>
            </p>
          </div>
          <div className="viewCont tvView">
            <div>
              <div className="video">
                {props.info.videoLink.includes("youtube") ? (
                  <iframe
                    src={props.info.videoLink}
                    frameBorder="0"
                    title={props.info.artcTitle}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video width="100%" height="100%" controls>
                    <source src={`${props.info.videoLink}`} type="video/mp4" />
                  </video>
                )}

                {/* <video width='100%' height='100%'>
                                    <source src={`/${props.info.videoLink}`} type="video/mp4" />
                                </video> */}
                {/* <img src="../../img/sub/sample_tigers_tv.jpg" alt="비디오영역" /> */}
              </div>
              {/* <p className="icoWrap">
                                <span className="date">{props.info.regDate}</span>
                                <span className="view"><i></i>{props.info.viewCnt}</span>
                                <span className="like"><i></i>{props.info.likeCnt}</span>
                            </p> */}
            </div>
            {/* <ul className="clear">
                            <li><a onClick={evt => props.onClickBtnSnsShare(evt, 'share')} href=""><img src="/img/sub/ico_sns01.png" alt="sns 공유" /></a></li>
                            <li><a onClick={evt => props.onClickBtnSnsShare(evt, 'kakaotalk')} href=""><img src="/img/sub/ico_sns02.png" alt="카카오톡 공유" /></a></li>
                            <li><a onClick={evt => props.onClickBtnSnsShare(evt, 'facebook')} href=""><img src="/img/sub/ico_sns03.png" alt="페이스북 공유" /></a></li>
                        </ul> */}
          </div>
          <div className="icoWrap">
            <div>
              <button onClick={props.onClickBtnList} className="bgGray">
                목록
              </button>
              <button
                onClick={props.onClickBtnLike}
                type="button"
                className={(props.checkDidLike ? "like2" : "like") + " heart"}
              >
                <i></i>
                {props.info.likeCnt}
              </button>
            </div>
          </div>
        </div>
        {/* <!-- //board --> */}
      </div>
      {/* <!-- //mb_contIn --> */}
    </div>
  ) : null;

export default ContComponent;
