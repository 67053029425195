import React from "react";

const MatchTeamRecordComponent = props =>
  props.info.position2 === "투" ? (
    <MatchTeamRecordComponentForPitcher {...props} />
  ) : (
    <MatchTeamRecordComponentForHitter {...props} />
  );

const MatchTeamRecordComponentForHitter = props => (
  <table>
    <caption>엔트리현황, 선수 현황표</caption>
    <thead>
      <tr>
        <th scope="col">상대</th>
        <th scope="col">타석</th>
        <th scope="col">타수</th>
        <th scope="col">득점</th>
        <th scope="col">안타</th>
        <th scope="col">2타</th>
        <th scope="col">3타</th>
        <th scope="col">홈런</th>
        <th scope="col">타점</th>
        <th scope="col">희타</th>
        <th scope="col">희비</th>
        <th scope="col">4구</th>
        <th scope="col">사구</th>
        <th scope="col">삼진</th>
        <th scope="col">병살</th>
        <th scope="col">타율</th>
      </tr>
    </thead>
    <tbody>
      {props.matchTeamRecordList.map((item, idx) => (
        <tr key={idx}>
          <th>{item.groupIf}</th>
          <td>{item.pa}</td>
          <td>{item.ab}</td>
          <td>{item.run}</td>
          <td>{item.hit}</td>
          <td>{item.h2}</td>
          <td>{item.h3}</td>
          <td>{item.hr}</td>
          <td>{item.rbi}</td>
          <td>{item.sh}</td>
          <td>{item.sf}</td>
          <td>{item.bb}</td>
          <td>{item.hp}</td>
          <td>{item.kk}</td>
          <td>{item.gd}</td>
          <td>{item.hra}</td>
        </tr>
      ))}
      {props.matchTeamRecordList.length === 0 ? (
        <tr>
          <td colSpan="16">조회된 기록이 없습니다.</td>
        </tr>
      ) : null}
    </tbody>
    <tfoot>
      {props.matchTeamTotalRecord ? (
        <tr className="tFoot">
          <th>합계</th>
          <td>{props.matchTeamTotalRecord.pa}</td>
          <td>{props.matchTeamTotalRecord.ab}</td>
          <td>{props.matchTeamTotalRecord.run}</td>
          <td>{props.matchTeamTotalRecord.hit}</td>
          <td>{props.matchTeamTotalRecord.h2}</td>
          <td>{props.matchTeamTotalRecord.h3}</td>
          <td>{props.matchTeamTotalRecord.hr}</td>
          <td>{props.matchTeamTotalRecord.rbi}</td>
          <td>{props.matchTeamTotalRecord.sh}</td>
          <td>{props.matchTeamTotalRecord.sf}</td>
          <td>{props.matchTeamTotalRecord.bb}</td>
          <td>{props.matchTeamTotalRecord.hp}</td>
          <td>{props.matchTeamTotalRecord.kk}</td>
          <td>{props.matchTeamTotalRecord.gd}</td>
          <td>{(props.matchTeamTotalRecord.hit / props.matchTeamTotalRecord.ab).toFixed(3)}</td>
        </tr>
      ) : null}
    </tfoot>
  </table>
);

const MatchTeamRecordComponentForPitcher = props => (
  <table>
    <caption>엔트리현황, 선수 현황표</caption>
    <thead>
      <tr>
        <th scope="col">상대</th>
        <th scope="col">승</th>
        <th scope="col">패</th>
        <th scope="col">세이브</th>
        <th scope="col">홀드</th>
        <th scope="col">이닝</th>
        <th scope="col">투구</th>
        <th scope="col">타자</th>
        <th scope="col">타수</th>
        <th scope="col">안타</th>
        <th scope="col">홈런</th>
        <th scope="col">4구</th>
        <th scope="col">사구</th>
        <th scope="col">삼진</th>
        <th scope="col">폭투</th>
        <th scope="col">보크</th>
        <th scope="col">실점</th>
        <th scope="col">자책</th>
        <th scope="col">방어율</th>
      </tr>
    </thead>
    <tbody>
      {props.matchTeamRecordList.map((item, idx) => (
        <tr key={idx}>
          <th>{item.groupIf}</th>
          <td>{item.w}</td>
          <td>{item.l}</td>
          <td>{item.sv}</td>
          <td>{item.hold}</td>
          <td>{item.innDisplay}</td>
          <td>{item.tugucount}</td>
          <td>{item.pa}</td>
          <td>{item.ab}</td>
          <td>{item.hit}</td>
          <td>{item.hr}</td>
          <td>{item.bb}</td>
          <td>{item.hp}</td>
          <td>{item.kk}</td>
          <td>{item.wp}</td>
          <td>{item.bk}</td>
          <td>{item.r}</td>
          <td>{item.er}</td>
          <td>{item.era}</td>
        </tr>
      ))}
      {props.matchTeamRecordList.length === 0 ? (
        <tr>
          <td colSpan="19">조회된 기록이 없습니다.</td>
        </tr>
      ) : null}
    </tbody>
    <tfoot>
      {props.matchTeamTotalRecord ? (
        <tr className="tFoot">
          <th>합계</th>
          <td>{props.matchTeamTotalRecord.w}</td>
          <td>{props.matchTeamTotalRecord.l}</td>
          <td>{props.matchTeamTotalRecord.sv}</td>
          <td>{props.matchTeamTotalRecord.hold}</td>
          <td>
            {props.matchTeamTotalRecord.inn2 % 3 === 0
              ? parseInt(props.matchTeamTotalRecord.inn2 / 3)
              : parseInt(props.matchTeamTotalRecord.inn2 / 3) +
                " " +
                (props.matchTeamTotalRecord.inn2 % 3) +
                "/3"}
          </td>
          <td>{props.matchTeamTotalRecord.tugucount}</td>
          <td>{props.matchTeamTotalRecord.pa}</td>
          <td>{props.matchTeamTotalRecord.ab}</td>
          <td>{props.matchTeamTotalRecord.hit}</td>
          <td>{props.matchTeamTotalRecord.hr}</td>
          <td>{props.matchTeamTotalRecord.bb}</td>
          <td>{props.matchTeamTotalRecord.hp}</td>
          <td>{props.matchTeamTotalRecord.kk}</td>
          <td>{props.matchTeamTotalRecord.wp}</td>
          <td>{props.matchTeamTotalRecord.bk}</td>
          <td>{props.matchTeamTotalRecord.r}</td>
          <td>{props.matchTeamTotalRecord.er}</td>
          <td>{((props.matchTeamTotalRecord.er * 9) / (props.matchTeamTotalRecord.inn2 / 3)).toFixed(2)}</td>
        </tr>
      ) : null}
    </tfoot>
  </table>
);

export default MatchTeamRecordComponent;
