import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import SubHeadComponent from "./SubHead.component";
import ContComponent from "./Cont.component";

import FindIdContainer from "./find-id/FindId.container";
import FindPwContainer from "./find-pw/FindPw.container";

import ThreeChangePwContainer from "./three-change-pw/ThreeChangePw.container";
import InactiveIdContainer from "./inactive-id/InactiveId.container";
import TurnNormalContainer from "./turn-normal/TurnNormal.Container";

import * as RegExHelper from "../../helpers/RegEx.helper";
import * as AuthHelper from "../../helpers/Auth.helper";
import * as AuthService from "../../services/Auth.service";

class LoginContainer extends Component {
  passwordRegEx = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

  constructor(props) {
    super(props);



    this.state = {
      idObj: {
        hasFocused: true,
        // value: "아이디(6자리 이상) 입력"
        value: "",
      },
      pwObj: {
        hasFocused: true,
        // value: "비밀번호(6자리 이상) 입력"
        value: "",
      },
      isShowPopup: false,
      needChangePassword: null,
      isInactiveMember: false,
      isTurnNormal: false,
    };

  }

  componentDidMount = () => {
    if (AuthHelper.getLoginInfo()) {
      this.props.history.replace("/");
    }

    // this.props.history.push('/login', { simpleId: this.state.simpleId, isInactiveMember: true });
    // isInactiveMember is true, then show inactiveId page
    // isInactiveMember is false, then show login page
    this.updateStateFromLocation();



  };


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateStateFromLocation();
    }
  }

  updateStateFromLocation = () => {
    console.log("UPDATE PROPS LOCATION", this.props.location.state);
    if (this.props.location.state && this.props.location.state.isInactiveMember) {
      this.setState({
        isInactiveMember: true,
        idObj: { value: this.props.location.state.simpleId }
      }, () => {
        this.props.location.state = {};
      });
    }
  };

  onFocusInput = (evt, type) => {
    console.log("onFocusInput");
    if (type === "id") {
      let idObj = this.state.idObj;
      if (!idObj.hasFocused) {
        idObj.hasFocused = true;
        idObj.value = "";
        this.setState(idObj);
      }
    } else if (type === "pw") {
      let pwObj = this.state.pwObj;
      if (!pwObj.hasFocused) {
        pwObj.hasFocused = true;
        pwObj.value = "";
        this.setState(pwObj);
      }
    }
  };

  onChangeInput = (evt, objName, key) => {
    const obj = this.state[objName];
    obj[key] = evt.target.value;
    this.setState({ [objName]: obj });
  };

  onClickBtnLogin = async (evt) => {
    try {
      if (!this.state.idObj.hasFocused || !this.state.pwObj.hasFocused) {
        throw new Error("아이디 또는 비밀번호를 입력해 주세요.");
      }

      const id = this.state.idObj.value;
      const pw = this.state.pwObj.value;

      if (id.trim() === "") {
        throw new Error("아이디를 입력해주세요.");
      }
      // RegExHelper.checkPassword(pw);
      console.log(this.state.needChangePassword);

      localStorage.removeItem("loginID")
      const loginInfo = await AuthService.login(id, pw);
      if (loginInfo.status === 2018) {
        this.setState({ isInactiveMember: true });
      } else if (loginInfo.status === 2020) {
        this.setState({ ...this.state, isTurnNormal: true });
      } else {
        this.props.setLoginInfo(loginInfo);
        // get url
        const url = new URL(window.location.href);
        const redirectUrl = url.searchParams.get("redirect");
        if (redirectUrl) {
          document.location.href = redirectUrl;
        }
        localStorage.setItem('loginID', id.trim());
        this.setState({ needChangePassword: loginInfo.needChangePassword });
        if (loginInfo.user.realnameAuthYn === "Y") {
        } else {
          this.setState({ isShowPopup: true });
        }
      }
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  onClickBtnNaverLogin = (evt) => {
    console.log("onClickBtnNaverLogin");
  };

  onClickBtnPopupCancel = (evt) => {
    this.setState({ isShowPopup: false }, (_) => {
      window.location.href = "/";
    });
  };

  onClickBtnPopupConfirm = (evt) => {
    this.setState({ isShowPopup: false }, (_) => {
      window.location.href = "/mypage";
    });
  };

  onEnterKey = (evt) => {
    if (evt.key === "Enter") {
      this.onClickBtnLogin();
    }
  };

  render = () => (
    <div className="container">
      <Switch>
        <Route exact path={this.props.match.url}>
          <SubHeadComponent />
          {/* <!-- //sub_head --> */}
          {this.state.isTurnNormal ? (
            <TurnNormalContainer
              userInfo={{
                id: this.state.idObj.value,
                pw: this.state.pwObj.value,
              }}
              history={this.props.history}
              setLoginInfo={this.props.setLoginInfo}
            />
          ) : this.state.needChangePassword === null ? (
            this.state.isInactiveMember ? (
              <InactiveIdContainer idObj={this.state.idObj} />
            ) : (
              <ContComponent
                idObj={this.state.idObj}
                pwObj={this.state.pwObj}
                isShowPopup={this.state.isShowPopup}
                onEnterKey={this.onEnterKey}
                onChangeInput={this.onChangeInput}
                onFocusInput={this.onFocusInput}
                onClickBtnLogin={this.onClickBtnLogin}
                onClickBtnPopupCancel={this.onClickBtnPopupCancel}
                onClickBtnPopupConfirm={this.onClickBtnPopupConfirm}
              />
            )
          ) : this.state.needChangePassword === 1 ? (
            <ThreeChangePwContainer />
          ) : (
            this.props.history.push("/")
          )}
        </Route>
        <Route
          exact
          path={`${this.props.match.path}/find-id`}
          component={FindIdContainer}
        />
        <Route
          exact
          path={`${this.props.match.path}/find-pw`}
          component={FindPwContainer}
        />
      </Switch>
    </div>
  );
}
export default withRouter(LoginContainer);
