import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import SubMenuComponent from "../common/SubMenu.component";
import SubTabComponent from "./SubTab.component";

import queryString from "query-string";

import * as DateHelper from "../../../helpers/Date.helper";
import * as PlayerHelper from "../../../helpers/Player.helper";
import * as GameService from "../../../services/Game.service";
import * as CommonService from "../../../services/Common.service";

import AnalyticsContext from "../../../context/AnalyticsContext";

class EntryStatusContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    const subMenu = "entry-status";
    const subMenuName = "엔트리 현황";

    const yearSelectList = DateHelper.getYearSelectList(2018);
    const monthSelectList = DateHelper.getMonthSelectList();

    const queryStringParams = queryString.parse(this.props.location.search);
    const tabNum = parseInt(
      queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 1,
      10
    );
    const date = new Date();

    this.state = {
      tabNum,
      subMenu,
      subMenuName,
      yearSelectList,
      monthSelectList,
      entryStatusObj: {
        list: [],
      },
      expiredEntryStatus: {
        // selectedYear: DateHelper.getCurrentYear(),
        // selectedMonth: DateHelper.getCurrentMonth(),
        selectedYear: date.getFullYear(),
        selectedMonth: date.getMonth() + 1,
        listObj: [],
      },
    };
  }
  componentDidMount = async (_) => {
    console.log("EntryStatusContainer");

    try {
      if (this.state.tabNum < 4) {
        const result = await GameService.getEntryStatus(this.state.tabNum);

        const currentAnalyticsVal = this.context.state
        const prevUrl = currentAnalyticsVal.currentUrl;
        this.context.action(currentAnalyticsVal);

        currentAnalyticsVal.deps2 = "02";
        currentAnalyticsVal.deps3 = "07";
        currentAnalyticsVal.prevUrl = prevUrl;
        currentAnalyticsVal.currentUrl = window.location.href.split('?')[0];

        await CommonService.insertAnalytics(this.context.state);


        let entryStatusObj = this.state.entryStatusObj;
        entryStatusObj["list"] = this.makeEntryStatusList(result.playerList);
        this.setState({ entryStatusObj });
      } else {
        let year = this.state.expiredEntryStatus.selectedYear;
        let month = this.state.expiredEntryStatus.selectedMonth;
        const result = await this.getList(year, month);

        let entryStatusObj = this.state.entryStatusObj;
        entryStatusObj["list"] = [];

        let expiredEntryStatus = this.state.expiredEntryStatus;
        expiredEntryStatus.listObj = PlayerHelper.makeExpiredEntryObj(
          result.inOutList
        );
        this.setState({ entryStatusObj, expiredEntryStatus });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      const queryStringParams = queryString.parse(this.props.location.search);
      const tabNum = parseInt(
        queryStringParams["tabNum"] ? queryStringParams["tabNum"] : 1
      );
      this.updateList(tabNum);
    }
  };

  updateList = async (tabNum) => {
    try {
      if (tabNum < 4) {
        const result = await GameService.getEntryStatus(tabNum);

        let entryStatusObj = this.state.entryStatusObj;
        entryStatusObj["list"] = this.makeEntryStatusList(result.playerList);

        let expiredEntryStatus = this.state.expiredEntryStatus;
        expiredEntryStatus["list"] = [];
        this.setState({ tabNum, entryStatusObj, expiredEntryStatus });
      } else {
        let year = this.state.expiredEntryStatus.selectedYear;
        let month = this.state.expiredEntryStatus.selectedMonth;
        const result = await this.getList(year, month);

        let entryStatusObj = this.state.entryStatusObj;
        entryStatusObj["list"] = [];

        let expiredEntryStatus = this.state.expiredEntryStatus;
        expiredEntryStatus.listObj = PlayerHelper.makeExpiredEntryObj(
          result.inOutList
        );
        this.setState({ tabNum, entryStatusObj, expiredEntryStatus });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnTab = async (evt, tabNum) => {
    const url = `/players/entry-status?tabNum=${tabNum}`;

    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "01";
    currentAnalyticsVal.deps3 = "07";
    currentAnalyticsVal.queryString = window.location.href.split('?').length === 2 ? window.location.href.split('?')[1] : "";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href.split('?')[0];


    await CommonService.insertAnalytics(this.context.state);

    this.props.history.push(url);
  };

  onClickBtnSort = async (sortKey) => {
    try {
      const result = await GameService.getEntryStatus(
        this.state.tabNum,
        sortKey
      );
      let entryStatusObj = this.state.entryStatusObj;
      entryStatusObj["list"] = this.makeEntryStatusList(result.playerList);
      this.setState({ entryStatusObj });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onChangeSelectList = async (evt, objName, key) => {
    let obj = this.state[objName];
    obj[key] = evt.target.value;
    this.setState({ [objName]: obj });
  };

  onClickBtnSearch = async (evt) => {
    try {
      let year = this.state.expiredEntryStatus.selectedYear;
      let month = this.state.expiredEntryStatus.selectedMonth;
      const result = await this.getList(year, month);

      let entryStatusObj = this.state.entryStatusObj;
      entryStatusObj["list"] = [];

      let expiredEntryStatus = this.state.expiredEntryStatus;
      expiredEntryStatus.listObj = PlayerHelper.makeExpiredEntryObj(
        result.inOutList
      );
      this.setState({ entryStatusObj, expiredEntryStatus });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  makeEntryStatusList = (entryStatusList) => {
    for (let i = 0; i < entryStatusList.length; i++) {
      entryStatusList[i]["birth"] = DateHelper.makeSeparatedDate(
        entryStatusList[i]["birth"]
      );

      // entryStatusList[i]['indate'] = DateHelper.makeSeparatedDate(entryStatusList[i]['indate']);
    }
    return entryStatusList;
  };

  getList = async (year, month) => {
    month = (parseInt(month, 10) < 10 ? "0" + month : month) + "";
    return await GameService.getExpiredEntryStatus(year, month);
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <div className="sbHead_appView tb"></div>
      { process.env.REACT_APP_PREPARING_PLAYERS_ENTRY_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont player">
          <div className="mb_contIn">
            <SubMenuComponent
              subMenu={this.state.subMenu}
              subMenuName={this.state.subMenuName}
            />
            <div style={{ marginTop: "50px" }}>
              <img
                src="/img/sub/tigers_22_off_greeting_pc.png"
                alt="season_off_pc"
                className="pc"
                />
              <img
                src="/img/sub/tigers_22_off_greeting_mb.jpg"
                className="mb"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      ) : (
          <ContComponent
            subMenu={this.state.subMenu}
            subMenuName={this.state.subMenuName}
            tabNum={this.state.tabNum}
            onClickBtnTab={this.onClickBtnTab}
            onClickBtnSort={this.onClickBtnSort}
            yearSelectList={this.state.yearSelectList}
            monthSelectList={this.state.monthSelectList}
            onChangeSelectList={this.onChangeSelectList}
            expiredEntryStatus={this.state.expiredEntryStatus}
            entryStatusObj={this.state.entryStatusObj}
            onClickBtnSearch={this.onClickBtnSearch}
          />
        )}
    </div>
  );
}

export default EntryStatusContainer;
