import React, { Component } from "react";
import ContComponent from "./Cont.component";

import * as RegExHelper from "../../../helpers/RegEx.helper";
import * as UserService from "../../../services/User.service";
import * as AuthHelper from "../../../helpers/Auth.helper";
import { decodeAES256 } from "../../../helpers/Crypto.helper";

class ThreeChangePwContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPwVal: "",
      changePwVal: "",
      changePwValCk: "",
    };
  }

  onClickBtnChangePw = async () => {
    const loginInfo = AuthHelper.getLoginInfo();
    const passwordCheckVal = await UserService.checkOwnPassword(
      this.state.currentPwVal
    );

    if (this.state.currentPwVal.trim() === "") {
      alert("현재 비밀번호를 입력해주세요.");
      return;
    } else if (this.state.changePwVal.trim() === "") {
      alert("변경 할 비밀번호를 입력해주세요.");
      return;
    } else if (this.state.changePwValCk.trim() === "") {
      alert("변경 할 비밀번호 확인을 입력해주세요.");
      return;
    } else if (this.state.changePwValCk !== this.state.changePwVal) {
      alert("변경 할 비밀번호가 일치하지 않습니다.");
      return;
    } else if (this.state.currentPwVal === this.state.changePwVal) {
      alert("현재 비밀번호와 변경 할 비밀번호가 같습니다.");
      return;
    } else if (passwordCheckVal.message) {
      alert("현재 비밀번호가 일지하지 않습니다.");
      return;
    }

    RegExHelper.checkPassword(this.state.changePwVal);

    await UserService.updatePw(decodeAES256(loginInfo.user.simpleId), this.state.changePwVal);
    alert("비밀번호가 변경되었습니다.");

    window.location.href = "/";
  };

  onChangePwVal = (evt, subject) => {
    let onChangeTargetValue = evt.target.value;
    this.setState({ [subject]: onChangeTargetValue });
  };

  onClickBtnDelayChangePw = async () => {
    await UserService.delayChangePassword();
    window.location.href = "/";
  };

  render = () => (
    <div className="container">
      <ContComponent
        currentPwVal={this.state.currentPwVal}
        changePwVal={this.state.changePwVal}
        changePwValCk={this.state.changePwValCk}
        onClickBtnChangePw={this.onClickBtnChangePw}
        onClickBtnSetDefault={this.onClickBtnSetDefault}
        onChangePwVal={this.onChangePwVal}
        onClickBtnDelayChangePw={this.onClickBtnDelayChangePw}
      />
    </div>
  );
}

export default ThreeChangePwContainer;
