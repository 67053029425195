import React from "react";

const ContComponent = ({ authIpin, authPhone, showingResult, history }) => {
  return (
    <div className="mb_cont login">
      {!showingResult ? (
        <div className="mb_contIn turn_normal">
          <img src="/img/main/kia-logo-login.png" />
          <p>
            <strong>일반회원 전환이 필요한 아이디입니다.</strong> <br />
            회원님 아이디는 일반회원 전환이 필요합니다.
            <br />
            (올해 1월 1일 기준 14세 이상 회원)
            <br />
            본인인증 및 일반회원 전환 후
            <br />
            KIA TIGERS 서비스 이용이 가능합니다.
          </p>

          <div>
            <button onClick={authPhone}>휴대폰 (본인명의)</button>
            <button onClick={authIpin}>아이핀</button>
          </div>
        </div>
      ) : (
        <div className="mb_contIn turn_normal">
          <img src="/img/main/kia-logo-login.png" />
          <p>
            <strong>KIA TIGERS 일반회원 전환이 완료되었습니다.</strong> <br />
            KIA TIERS를 사랑해주시는 회원님.
            <br />
            앞으로 더 좋은 서비스 제공을 위해 노력하겠습니다.
            <br />
            아래 버튼을 누르시면 정상적으로 서비스 이용이 가능합니다.
            <br />
          </p>

          <div>
            <button
              onClick={() => {
                history.push("/");
              }}
            >
              홈으로 가기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContComponent;
