import React from 'react';
import SubMenuComponent from '../common/SubMenu.component';
import * as ImageHelper from '../../../../helpers/Image.helper';
import ImgComponent from '../../../common/Img.component';

const ContComponent = (props) => {
    const current = new Date();

    return (
    <div className="mb_cont sub_cont game stats">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName}
                thirdMenu={props.thirdMenu}
                thirdMenuName={props.thirdMenuName} />
            <section className="statsSearch">
                <p>시즌과 포지션을 선택 후, 비교하고자 하는 팀과 선수를 선택하시고 <span>기록 비교 항목을 선택</span>하세요.</p>
                <table className=" tb mb_table_stats table_stats ">
                    <colgroup>
                        <col width="25%" />
                        <col width="75%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>시즌선택</th>
                            <td>
                                <a onClick={evt => props.onClickBtnSeason(evt, 2021)}
                                    className={(props.selectedSeason === 2021 ? "on" : "") + " year btnRG"}>2021년</a>
                                <a onClick={evt => props.onClickBtnSeason(evt, 2020)}
                                    className={(props.selectedSeason === 2020 ? "on" : "") + " year btnRG"}>2020년</a>
                                <a onClick={evt => props.onClickBtnSeason(evt, 2019)}
                                    className={(props.selectedSeason === 2019 ? "on" : "") + " year btnRG"}>2019년</a>
                                <a onClick={evt => props.onClickBtnSeason(evt, 2018)}
                                    className={(props.selectedSeason === 2018 ? "on" : "") + " year btnRG"}>2018년</a>
                            </td>
                        </tr>
                        <tr>
                            <th>포지션선택</th>
                            <td>
                                <a onClick={evt => props.onClickBtnPositionCode(evt, 'P')}
                                    className={(props.selectedPositionCode === 'P' ? "on" : "") + " posi glove btnRG"}>투수</a>
                                <a onClick={evt => props.onClickBtnPositionCode(evt, 'B')}
                                    className={(props.selectedPositionCode === 'B' ? "on" : "") + " posi bet btnRG"}>타자</a>
                            </td>
                        </tr>
                        <tr>
                            <th>팀과 선수 선택</th>
                            <td className="clear bgAnd">
                                <div className="fll w50p">
                                    <select value={props.selectedLeftTeamCode}
                                        onChange={evt => props.onChangeTeamSelectList(evt, 'left', 'selectedLeftTeamCode')}>
                                        <option value="">팀 선택</option>
                                        {props.leftTeamSelectList.map((item, idx) =>
                                            <option key={idx} value={item.teamCode}>{item.teamName}</option>
                                        )}
                                    </select>
                                    <select value={props.selectedLeftPlayerCode}
                                        onChange={evt => props.onChangePlayerSelectList(evt, 'left', 'selectedLeftPlayerCode')}
                                        className="mgt10">
                                        <option value="">선수 선택</option>
                                        {props.leftPlayerSelectList.map((item, idx) =>
                                            <option key={idx} value={item.pcode}>{item.playerName}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="flr w50p">
                                    <select value={props.selectedRightTeamCode}
                                        onChange={evt => props.onChangeTeamSelectList(evt, 'right', 'selectedRightTeamCode')}>
                                        <option value="">팀 선택</option>
                                        {props.rightTeamSelectList.map((item, idx) =>
                                            <option key={idx} value={item.teamCode}>{item.teamName}</option>
                                        )}
                                    </select>
                                    <select value={props.selectedRightPlayerCode}
                                        onChange={evt => props.onChangePlayerSelectList(evt, 'right', 'selectedRightPlayerCode')}
                                        className="mgt10">
                                        <option value="">선수 선택</option>
                                        {props.rightPlayerSelectList.map((item, idx) =>
                                            <option key={idx} value={item.pcode}>{item.playerName}</option>
                                        )}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>기록 비교 항목</th>
                            <td>
                                {props.selectedPositionCode === "P" ? <PitcherCompareCheckboxComponent {...props} /> : null}
                                {props.selectedPositionCode === "B" ? <HitterCompareCheckboxComponent {...props} /> : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="pc table_stats">
                    <colgroup>
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="40%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>시즌선택</th>
                            <th>포지션선택</th>
                            <th colSpan="2">팀과 선수 선택</th>
                            <th>기록 비교 항목</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a onClick={evt => props.onClickBtnSeason(evt, current.getFullYear())}
                                    className={(props.selectedSeason === current.getFullYear() ? "on" : "") + " year btnRG"}>{current.getFullYear()}년</a>
                            </td>
                            <td>
                                <a onClick={evt => props.onClickBtnPositionCode(evt, 'P')}
                                    className={(props.selectedPositionCode === 'P' ? "on" : "") + " posi glove btnRG"}>투수</a>
                            </td>
                            <td className="bdrn">
                                <select value={props.selectedLeftTeamCode}
                                    onChange={evt => props.onChangeTeamSelectList(evt, 'left', 'selectedLeftTeamCode')}>
                                    <option value="">팀 선택</option>
                                    {props.leftTeamSelectList.map((item, idx) =>
                                        <option key={idx} value={item.teamCode}>{item.teamName}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                <select value={props.selectedRightTeamCode}
                                    onChange={evt => props.onChangeTeamSelectList(evt, 'right', 'selectedRightTeamCode')}>
                                    <option value="">팀 선택</option>
                                    {props.rightTeamSelectList.map((item, idx) =>
                                        <option key={idx} value={item.teamCode}>{item.teamName}</option>
                                    )}
                                </select>
                            </td>
                            <td rowSpan="3" className="checks bdrn tLeft por">
                                {props.selectedPositionCode === "P" ? <PitcherCompareCheckboxComponent {...props} /> : null}
                                {props.selectedPositionCode === "B" ? <HitterCompareCheckboxComponent {...props} /> : null}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a onClick={evt => props.onClickBtnSeason(evt, current.getFullYear() - 1)}
                                    className={(props.selectedSeason === (current.getFullYear() - 1) ? "on" : "") + " year btnRG"}>{current.getFullYear() - 1}년</a>
                            </td>
                            <td>
                                <a onClick={evt => props.onClickBtnPositionCode(evt, 'B')}
                                    className={(props.selectedPositionCode === 'B' ? "on" : "") + " posi bet btnRG"}>타자</a>
                            </td>
                            <td className="bdrn">
                                <select value={props.selectedLeftPlayerCode}
                                    onChange={evt => props.onChangePlayerSelectList(evt, 'left', 'selectedLeftPlayerCode')}>
                                    <option value="">선수 선택</option>
                                    {props.leftPlayerSelectList.map((item, idx) =>
                                        <option key={idx} value={item.pcode}>{item.playerName}</option>
                                    )}
                                </select>
                            </td>
                            <td>
                                <select value={props.selectedRightPlayerCode}
                                    onChange={evt => props.onChangePlayerSelectList(evt, 'right', 'selectedRightPlayerCode')}>
                                    <option value="">선수 선택</option>
                                    {props.rightPlayerSelectList.map((item, idx) =>
                                        <option key={idx} value={item.pcode}>{item.playerName}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a onClick={evt => props.onClickBtnSeason(evt, current.getFullYear() - 2)}
                                    className={(props.selectedSeason === (current.getFullYear() - 2) ? "on" : "") + " year btnRG"}>{current.getFullYear() - 2}년</a>
                            </td>
                            <td></td>
                            <td colSpan="2" className="por"><span className="and">&amp;</span></td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" className="dB"
                    onClick={props.onClickBtnCompare}>기록 비교</button>
                {/* <script>
                $('.table_stats .posi').on('click',function(){
                    $('.table_stats .posi').removeClass('on');
                $(this).toggleClass('on');
            });
				$('.table_stats .year').on('click',function(){
                    $('.table_stats .year').removeClass('on');
                $(this).toggleClass('on');
            });
			</script> */}
            </section>
            {props.playerCompareObj ?
                <section className="statsResult">
                    <div className="playerBox">
                        <p className="width">{props.selectedSeason}년 {props.selectedPositionCode === "P" ? "투수" : "타자"}&nbsp;
                    <span className="red">{props.playerCompareObj.left.playerName}</span> &amp;
                    <span className="blue"> {props.playerCompareObj.right.playerName}</span> 기록비교 </p>
                        <p className="team">
                            <img src={props.playerCompareObj.left['emblemImgUrl']} alt={props.selectedLeftTeamCode} />
                            <span className="and">&amp;</span>
                            <img src={props.playerCompareObj.right['emblemImgUrl']} alt={props.selectedRightTeamCode} />
                        </p>
                        <div className="clear">
                            <div className="player fll">
                                <span>{props.selectedPositionCode === "P" ? "투수" : "타자"} / 좌투좌타</span>
                                <p className="name">
                                    <em className="num">
                                        {props.playerCompareObj.left.backNum}
                                    </em>{props.playerCompareObj.left.playerName}
                                </p>
                                <dl className="pc clear">
                                    <dt>생년월일</dt>
                                    <dd>
                                        {props.playerCompareObj.left.birth.substr(0, 4)}년
                                    {props.playerCompareObj.left.birth.substr(4, 2)}월
                                    {props.playerCompareObj.left.birth.substr(6, 2)}일
                                </dd>
                                    <dt>신장/체중</dt>
                                    <dd>{props.playerCompareObj.left.heightWeight}</dd>
                                    <dt>출신학교</dt>
                                    <dd>{props.playerCompareObj.left.career}</dd>
                                    <dt>계약금/연봉</dt>
                                    <dd>{props.playerCompareObj.left.promiseMoney}</dd>
                                </dl>
                                <p className="img">
                                    <ImgComponent
                                        imgUrl={props.playerCompareObj.left.playerRecordImg}
                                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                                        imgName={props.playerCompareObj.left.playerName} />
                                </p>
                            </div>
                            <div className="player flr">
                                <span>{props.selectedPositionCode === "P" ? "투수" : "타자"} / 좌투좌타</span>
                                <p className="name">
                                    <em className="num">
                                        {props.playerCompareObj.right.backNum}
                                    </em>{props.playerCompareObj.right.playerName}
                                </p>
                                <dl className="pc clear">
                                    <dt>생년월일</dt>
                                    <dd>
                                        {props.playerCompareObj.right.birth.substr(0, 4)}년
                                    {props.playerCompareObj.right.birth.substr(4, 2)}월
                                    {props.playerCompareObj.right.birth.substr(6, 2)}일
                                </dd>
                                    <dt>신장/체중</dt>
                                    <dd>{props.playerCompareObj.right.heightWeight}</dd>
                                    <dt>출신학교</dt>
                                    <dd>{props.playerCompareObj.right.career}</dd>
                                    <dt>계약금/연봉</dt>
                                    <dd>{props.playerCompareObj.right.promiseMoney}</dd>
                                </dl>
                                <p className="img">
                                <ImgComponent
                                        imgUrl={props.playerCompareObj.right.playerRecordImg}
                                        defaultImgUrl={ImageHelper.getDefaultPlayerListImg()}
                                        imgName={props.playerCompareObj.right.playerName} />
                                </p>
                                {/* <!-- 선수 이미지가 없을경우--> */}
                                {/* <!--  */}
                                {/* <p className="img noImg"><img src="../../img/sub/stats_mc_samsung.png" alt="삼성 캐릭터" /><span className="blind">선수 이미지가 없습니다.</span></p> */}
                                {/* --> */}
                                {/* <!-- //선수 이미지가 없을경우--> */}
                            </div>
                        </div>
                    </div>
                    {props.playerCompareObj.positionCode === "P" ?
                        <PitcherCompareComponent {...props} />
                        :
                        <HitterCompareComponent {...props} />}
                </section>
                : null}
        </div>
    </div>
)}

const PitcherCompareCheckboxComponent = props => (
    <p className="row">
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'era')} checked={props.checkboxObj.era} type="checkbox" /><i></i>평균자책점</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'w')} checked={props.checkboxObj.w} type="checkbox" /><i></i>승리</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'l')} checked={props.checkboxObj.l} type="checkbox" /><i></i>패배</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hold')} checked={props.checkboxObj.hold} type="checkbox" /><i></i>홀드</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'sv')} checked={props.checkboxObj.sv} type="checkbox" /><i></i>세이브</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'kk')} checked={props.checkboxObj.kk} type="checkbox" /><i></i>삼진</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'innDisplay')} checked={props.checkboxObj.innDisplay} type="checkbox" /><i></i>이닝</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hit')} checked={props.checkboxObj.hit} type="checkbox" /><i></i>피안타</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hr')} checked={props.checkboxObj.hr} type="checkbox" /><i></i>피홈런</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'bb')} checked={props.checkboxObj.bb} type="checkbox" /><i></i>볼넷</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'whip')} checked={props.checkboxObj.whip} type="checkbox" /><i></i>WHIP</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'qs')} checked={props.checkboxObj.qs} type="checkbox" /><i></i>QS</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'qsPlus')} checked={props.checkboxObj.qsPlus} type="checkbox" /><i></i>QS+</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'sho')} checked={props.checkboxObj.sho} type="checkbox" /><i></i>완봉</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'wCg')} checked={props.checkboxObj.wCg} type="checkbox" /><i></i>완투</label>
    </p>
)

const HitterCompareCheckboxComponent = props => (
    <p className="row">
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hra')} checked={props.checkboxObj.hra} type="checkbox" /><i></i>타율</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hit')} checked={props.checkboxObj.hit} type="checkbox" /><i></i>안타</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'hr')} checked={props.checkboxObj.hr} type="checkbox" /><i></i>홈런</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'rbi')} checked={props.checkboxObj.rbi} type="checkbox" /><i></i>타점</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'sb')} checked={props.checkboxObj.sb} type="checkbox" /><i></i>도루</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'kk')} checked={props.checkboxObj.kk} type="checkbox" /><i></i>삼진</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'bb')} checked={props.checkboxObj.bb} type="checkbox" /><i></i>볼넷</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'pa')} checked={props.checkboxObj.pa} type="checkbox" /><i></i>타석</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'ab')} checked={props.checkboxObj.ab} type="checkbox" /><i></i>타수</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'run')} checked={props.checkboxObj.run} type="checkbox" /><i></i>득점</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'h2')} checked={props.checkboxObj.h2} type="checkbox" /><i></i>2루타</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'h3')} checked={props.checkboxObj.h3} type="checkbox" /><i></i>3루타</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'slg')} checked={props.checkboxObj.slg} type="checkbox" /><i></i>장타율</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'bra')} checked={props.checkboxObj.bra} type="checkbox" /><i></i>출루율</label>
        <label className="chkbox"><input onChange={evt => props.onClickCheckbox(evt, 'ops')} checked={props.checkboxObj.ops} type="checkbox" /><i></i>OPS</label>
    </p>
)

// 투수
const PitcherCompareComponent = props => (
    <div className="resultBox clear">
        <div className="tCenter">
            <ul>
                {props.playerCompareObj.era ? <li>{props.playerCompareObj.era.name}</li> : null}
                {props.playerCompareObj.w ? <li>{props.playerCompareObj.w.name}</li> : null}
                {props.playerCompareObj.l ? <li>{props.playerCompareObj.l.name}</li> : null}
                {props.playerCompareObj.hold ? <li>{props.playerCompareObj.hold.name}</li> : null}
                {props.playerCompareObj.sv ? <li>{props.playerCompareObj.sv.name}</li> : null}
                {props.playerCompareObj.kk ? <li>{props.playerCompareObj.kk.name}</li> : null}
                {props.playerCompareObj.innDisplay ? <li>{props.playerCompareObj.innDisplay.name}</li> : null}
                {props.playerCompareObj.hit ? <li>{props.playerCompareObj.hit.name}</li> : null}
                {props.playerCompareObj.hr ? <li>{props.playerCompareObj.hr.name}</li> : null}
                {props.playerCompareObj.bb ? <li>{props.playerCompareObj.bb.name}</li> : null}
                {props.playerCompareObj.whip ? <li>{props.playerCompareObj.whip.name}</li> : null}
                {props.playerCompareObj.qs ? <li>{props.playerCompareObj.qs.name}</li> : null}
                {props.playerCompareObj.qsPlus ? <li>{props.playerCompareObj.qsPlus.name}</li> : null}
                {props.playerCompareObj.sho ? <li>{props.playerCompareObj.sho.name}</li> : null}
                {props.playerCompareObj.wCg ? <li>{props.playerCompareObj.wCg.name}</li> : null}
            </ul>
        </div>

        <div className="bar left">
            <dl>
                {/* 평균 자책점 */}
                {props.playerCompareObj.era ? <dt>평균자책점</dt> : null}
                {props.playerCompareObj.era ? <dd className={props.playerCompareObj.era.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.era.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.era.left.hasWin ? "red" : ""}>{props.playerCompareObj.era.left.value}</em>
                </dd> : null}

                {/* 승리 */}
                {props.playerCompareObj.w ? <dt>승리</dt> : null}
                {props.playerCompareObj.w ? <dd className={props.playerCompareObj.w.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.w.left.rate}%` }} ></span></p>
                    <em className={props.playerCompareObj.w.left.hasWin ? "red" : ""}>{props.playerCompareObj.w.left.value}</em>
                </dd> : null}

                {/* 패배 */}
                {props.playerCompareObj.l ? <dt>패배</dt> : null}
                {props.playerCompareObj.l ? <dd className={props.playerCompareObj.l.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.l.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.l.left.hasWin ? "red" : ""}>{props.playerCompareObj.l.left.value}</em>
                </dd> : null}

                {/* 홀드 */}
                {props.playerCompareObj.hold ? <dt>홀드</dt> : null}
                {props.playerCompareObj.hold ? <dd className={props.playerCompareObj.hold.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hold.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hold.left.hasWin ? "red" : ""}>{props.playerCompareObj.hold.left.value}</em>
                </dd> : null}

                {/* 세이브 */}
                {props.playerCompareObj.sv ? <dt>세이브</dt> : null}
                {props.playerCompareObj.sv ? <dd className={props.playerCompareObj.sv.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sv.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sv.left.hasWin ? "red" : ""}>{props.playerCompareObj.sv.left.value}</em>
                </dd> : null}

                {/* 삼진 */}
                {props.playerCompareObj.kk ? <dt>삼진</dt> : null}
                {props.playerCompareObj.kk ? <dd className={props.playerCompareObj.kk.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.kk.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.kk.left.hasWin ? "red" : ""}>{props.playerCompareObj.left.kk}</em>
                </dd> : null}

                {/* 이닝 */}
                {props.playerCompareObj.innDisplay ? <dt>이닝</dt> : null}
                {props.playerCompareObj.innDisplay ? <dd className={props.playerCompareObj.innDisplay.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.innDisplay.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.innDisplay.left.hasWin ? "red" : ""}>{props.playerCompareObj.innDisplay.left.value}</em>
                </dd> : null}

                {/* 피안타 */}
                {props.playerCompareObj.hit ? <dt>피안타</dt> : null}
                {props.playerCompareObj.hit ? <dd className={props.playerCompareObj.hit.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hit.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hit.left.hasWin ? "red" : ""}>{props.playerCompareObj.left.hit}</em>
                </dd> : null}

                {/* 피홈런 */}
                {props.playerCompareObj.hr ? <dt>피홈런</dt> : null}
                {props.playerCompareObj.hr ? <dd className={props.playerCompareObj.hr.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hr.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hr.left.hasWin ? "red" : ""}>{props.playerCompareObj.hr.left.value}</em>
                </dd> : null}

                {/* 볼넷 */}
                {props.playerCompareObj.bb ? <dt>볼넷</dt> : null}
                {props.playerCompareObj.bb ? <dd className={props.playerCompareObj.bb.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bb.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bb.left.hasWin ? "red" : ""}>{props.playerCompareObj.bb.left.value}</em>
                </dd> : null}

                {/* WHIP */}
                {props.playerCompareObj.whip ? <dt>WHIP</dt> : null}
                {props.playerCompareObj.whip ? <dd className={props.playerCompareObj.whip.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.whip.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.whip.left.hasWin ? "red" : ""}>{props.playerCompareObj.whip.left.value}</em>
                </dd> : null}

                {/* QS */}
                {props.playerCompareObj.qs ? <dt>QS</dt> : null}
                {props.playerCompareObj.qs ? <dd className={props.playerCompareObj.qs.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.qs.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.qs.left.hasWin ? "red" : ""}>{props.playerCompareObj.qs.left.value}</em>
                </dd> : null}

                {/* QS+ */}
                {props.playerCompareObj.qsPlus ? <dt>QS+</dt> : null}
                {props.playerCompareObj.qsPlus ? <dd className={props.playerCompareObj.qsPlus.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.qsPlus.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.qsPlus.left.hasWin ? "red" : ""}>{props.playerCompareObj.qsPlus.left.value}</em>
                </dd> : null}

                {/* 완봉 */}
                {props.playerCompareObj.sho ? <dt>완봉</dt> : null}
                {props.playerCompareObj.sho ? <dd className={props.playerCompareObj.sho.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sho.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sho.left.hasWin ? "red" : ""}>{props.playerCompareObj.sho.left.value}</em>
                </dd> : null}

                {/* 완투 */}
                {props.playerCompareObj.wCg ? <dt>완투</dt> : null}
                {props.playerCompareObj.wCg ? <dd className={props.playerCompareObj.wCg.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.wCg.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.wCg.left.hasWin ? "red" : ""}>{props.playerCompareObj.wCg.left.value}</em>
                </dd> : null}

            </dl>
        </div>
        <div className="bar right">
            <dl>

                {/* 평균 자책점 */}
                {props.playerCompareObj.era ? <dt>평균자책점</dt> : null}
                {props.playerCompareObj.era ? <dd className={props.playerCompareObj.era.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.era.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.era.right.hasWin ? "blue" : ""}>{props.playerCompareObj.era.right.value}</em>
                </dd> : null}

                {/* 승리 */}
                {props.playerCompareObj.w ? <dt>승리</dt> : null}
                {props.playerCompareObj.w ? <dd className={props.playerCompareObj.w.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.w.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.w.right.hasWin ? "blue" : ""}>{props.playerCompareObj.w.right.value}</em>
                </dd> : null}

                {/* 패배 */}
                {props.playerCompareObj.l ? <dt>패배</dt> : null}
                {props.playerCompareObj.l ? <dd className={props.playerCompareObj.right.l.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.l.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.right.l.hasWin ? "blue" : ""}>{props.playerCompareObj.l.right.value}</em>
                </dd> : null}

                {/* 홀드 */}
                {props.playerCompareObj.hold ? <dt>홀드</dt> : null}
                {props.playerCompareObj.hold ? <dd className={props.playerCompareObj.right.hold.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hold.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.right.hold.hasWin ? "blue" : ""}>{props.playerCompareObj.hold.right.value}</em>
                </dd> : null}

                {/* 세이브 */}
                {props.playerCompareObj.sv ? <dt>세이브</dt> : null}
                {props.playerCompareObj.sv ? <dd className={props.playerCompareObj.sv.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sv.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sv.right.hasWin ? "blue" : ""}>{props.playerCompareObj.sv.left.value}</em>
                </dd> : null}

                {/* 삼진 */}
                {props.playerCompareObj.kk ? <dt>삼진</dt> : null}
                {props.playerCompareObj.kk ? <dd className={props.playerCompareObj.kk.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.kk.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.kk.right.hasWin ? "blue" : ""}>{props.playerCompareObj.kk.right.value}</em>
                </dd> : null}

                {/* 이닝 */}
                {props.playerCompareObj.innDisplay ? <dt>이닝</dt> : null}
                {props.playerCompareObj.innDisplay ? <dd className={props.playerCompareObj.innDisplay.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.innDisplay.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.innDisplay.right.hasWin ? "blue" : ""}>{props.playerCompareObj.innDisplay.right.value}</em>
                </dd> : null}

                {/* 피안타 */}
                {props.playerCompareObj.hit ? <dt>피안타</dt> : null}
                {props.playerCompareObj.hit ? <dd className={props.playerCompareObj.right.hitHasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hit.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.right.hitHasWin ? "blue" : ""}>{props.playerCompareObj.right.hit}</em>
                </dd> : null}

                {/* 피홈런 */}
                {props.playerCompareObj.hr ? <dt>피홈런</dt> : null}
                {props.playerCompareObj.hr ? <dd className={props.playerCompareObj.hr.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hr.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hr.right.hasWin ? "blue" : ""}>{props.playerCompareObj.hr.right.value}</em>
                </dd> : null}

                {/* 볼넷 */}
                {props.playerCompareObj.bb ? <dt>볼넷</dt> : null}
                {props.playerCompareObj.bb ? <dd className={props.playerCompareObj.bb.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bb.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bb.right.hasWin ? "blue" : ""}>{props.playerCompareObj.bb.right.value}</em>
                </dd> : null}

                {/* WHIP */}
                {props.playerCompareObj.whip ? <dt>WHIP</dt> : null}
                {props.playerCompareObj.whip ? <dd className={props.playerCompareObj.whip.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.whip.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.whip.right.hasWin ? "blue" : ""}>{props.playerCompareObj.whip.right.value}</em>
                </dd> : null}

                {/* QS */}
                {props.playerCompareObj.qs ? <dt>QS</dt> : null}
                {props.playerCompareObj.qs ? <dd className={props.playerCompareObj.qs.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.qs.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.qs.right.hasWin ? "blue" : ""}>{props.playerCompareObj.qs.right.value}</em>
                </dd> : null}

                {/* QS+ */}
                {props.playerCompareObj.qsPlus ? <dt>QS+</dt> : null}
                {props.playerCompareObj.qsPlus ? <dd className={props.playerCompareObj.qsPlus.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.qsPlus.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.qsPlus.right.hasWin ? "blue" : ""}>{props.playerCompareObj.qsPlus.right.value}</em>
                </dd> : null}

                {/* 완봉 */}
                {props.playerCompareObj.sho ? <dt>완봉</dt> : null}
                {props.playerCompareObj.sho ? <dd className={props.playerCompareObj.sho.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sho.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sho.right.hasWin ? "blue" : ""}>{props.playerCompareObj.sho.right.value}</em>
                </dd> : null}

                {/* 완투 */}
                {props.playerCompareObj.wCg ? <dt>완투</dt> : null}
                {props.playerCompareObj.wCg ? <dd className={props.playerCompareObj.wCg.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.wCg.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.wCg.right.hasWin ? "blue" : ""}>{props.playerCompareObj.wCg.right.value}</em>
                </dd> : null}
            </dl>
        </div>
    </div>
)

const HitterCompareComponent = props => (
    <div className="resultBox clear">
        <div className="tCenter">
            <ul>
                {props.playerCompareObj.hra ? <li>{props.playerCompareObj.hra.name}</li> : null}
                {props.playerCompareObj.hit ? <li>{props.playerCompareObj.hit.name}</li> : null}
                {props.playerCompareObj.hr ? <li>{props.playerCompareObj.hr.name}</li> : null}
                {props.playerCompareObj.rbi ? <li>{props.playerCompareObj.rbi.name}</li> : null}
                {props.playerCompareObj.sb ? <li>{props.playerCompareObj.sb.name}</li> : null}
                {props.playerCompareObj.kk ? <li>{props.playerCompareObj.kk.name}</li> : null}
                {props.playerCompareObj.bb ? <li>{props.playerCompareObj.bb.name}</li> : null}
                {props.playerCompareObj.pa ? <li>{props.playerCompareObj.pa.name}</li> : null}
                {props.playerCompareObj.ab ? <li>{props.playerCompareObj.ab.name}</li> : null}
                {props.playerCompareObj.run ? <li>{props.playerCompareObj.run.name}</li> : null}
                {props.playerCompareObj.h2 ? <li>{props.playerCompareObj.h2.name}</li> : null}
                {props.playerCompareObj.h3 ? <li>{props.playerCompareObj.h3.name}</li> : null}
                {props.playerCompareObj.slg ? <li>{props.playerCompareObj.slg.name}</li> : null}
                {props.playerCompareObj.bra ? <li>{props.playerCompareObj.bra.name}</li> : null}
                {props.playerCompareObj.ops ? <li>{props.playerCompareObj.ops.name}</li> : null}
            </ul>
        </div>

        <div className="bar left">
            <dl>
                {props.playerCompareObj.hra ? <dt>{props.playerCompareObj.hra.name}</dt> : null}
                {props.playerCompareObj.hra ? <dd className={props.playerCompareObj.hra.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hra.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hra.left.hasWin ? "red" : ""}>{props.playerCompareObj.hra.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.hit ? <dt>{props.playerCompareObj.hit.name}</dt> : null}
                {props.playerCompareObj.hit ? <dd className={props.playerCompareObj.hit.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hit.left.rate}%` }} ></span></p>
                    <em className={props.playerCompareObj.hit.left.hasWin ? "red" : ""}>{props.playerCompareObj.hit.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.hr ? <dt>{props.playerCompareObj.hr.name}</dt> : null}
                {props.playerCompareObj.hr ? <dd className={props.playerCompareObj.hr.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hr.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hr.left.hasWin ? "red" : ""}>{props.playerCompareObj.hr.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.rbi ? <dt>{props.playerCompareObj.rbi.name}</dt> : null}
                {props.playerCompareObj.rbi ? <dd className={props.playerCompareObj.rbi.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.rbi.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.rbi.left.hasWin ? "red" : ""}>{props.playerCompareObj.rbi.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.sb ? <dt>{props.playerCompareObj.sb.name}</dt> : null}
                {props.playerCompareObj.sb ? <dd className={props.playerCompareObj.sb.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sb.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sb.left.hasWin ? "red" : ""}>{props.playerCompareObj.sb.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.kk ? <dt>{props.playerCompareObj.kk.name}</dt> : null}
                {props.playerCompareObj.kk ? <dd className={props.playerCompareObj.kk.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.kk.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.kk.left.hasWin ? "red" : ""}>{props.playerCompareObj.kk.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.bb ? <dt>{props.playerCompareObj.bb.name}</dt> : null}
                {props.playerCompareObj.bb ? <dd className={props.playerCompareObj.bb.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bb.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bb.left.hasWin ? "red" : ""}>{props.playerCompareObj.bb.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.pa ? <dt>{props.playerCompareObj.pa.name}</dt> : null}
                {props.playerCompareObj.pa ? <dd className={props.playerCompareObj.pa.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.pa.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.pa.left.hasWin ? "red" : ""}>{props.playerCompareObj.pa.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.ab ? <dt>{props.playerCompareObj.ab.name}</dt> : null}
                {props.playerCompareObj.ab ? <dd className={props.playerCompareObj.ab.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.ab.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.ab.left.hasWin ? "red" : ""}>{props.playerCompareObj.ab.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.run ? <dt>{props.playerCompareObj.run.name}</dt> : null}
                {props.playerCompareObj.run ? <dd className={props.playerCompareObj.run.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.run.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.run.left.hasWin ? "red" : ""}>{props.playerCompareObj.run.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.h2 ? <dt>{props.playerCompareObj.h2.name}</dt> : null}
                {props.playerCompareObj.h2 ? <dd className={props.playerCompareObj.h2.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.h2.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.h2.left.hasWin ? "red" : ""}>{props.playerCompareObj.h2.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.h3 ? <dt>{props.playerCompareObj.h3.name}</dt> : null}
                {props.playerCompareObj.h3 ? <dd className={props.playerCompareObj.h3.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.h3.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.h3.left.hasWin ? "red" : ""}>{props.playerCompareObj.h3.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.slg ? <dt>{props.playerCompareObj.slg.name}</dt> : null}
                {props.playerCompareObj.slg ? <dd className={props.playerCompareObj.slg.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.slg.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.slg.left.hasWin ? "red" : ""}>{props.playerCompareObj.slg.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.bra ? <dt>{props.playerCompareObj.bra.name}</dt> : null}
                {props.playerCompareObj.bra ? <dd className={props.playerCompareObj.bra.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bra.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bra.left.hasWin ? "red" : ""}>{props.playerCompareObj.bra.left.value}</em>
                </dd> : null}

                {props.playerCompareObj.ops ? <dt>{props.playerCompareObj.ops.name}</dt> : null}
                {props.playerCompareObj.ops ? <dd className={props.playerCompareObj.ops.left.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.ops.left.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.ops.left.hasWin ? "red" : ""}>{props.playerCompareObj.ops.left.value}</em>
                </dd> : null}
            </dl>
        </div>
        <div className="bar right">
            <dl>
                {props.playerCompareObj.hra ? <dt>{props.playerCompareObj.hra.name}</dt> : null}
                {props.playerCompareObj.hra ? <dd className={props.playerCompareObj.hra.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hra.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hra.right.hasWin ? "blue" : ""}>{props.playerCompareObj.hra.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.hit ? <dt>{props.playerCompareObj.hit.name}</dt> : null}
                {props.playerCompareObj.hit ? <dd className={props.playerCompareObj.hit.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hit.right.rate}%` }} ></span></p>
                    <em className={props.playerCompareObj.hit.right.hasWin ? "blue" : ""}>{props.playerCompareObj.hit.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.hr ? <dt>{props.playerCompareObj.hr.name}</dt> : null}
                {props.playerCompareObj.hr ? <dd className={props.playerCompareObj.hr.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.hr.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.hr.right.hasWin ? "blue" : ""}>{props.playerCompareObj.hr.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.rbi ? <dt>{props.playerCompareObj.rbi.name}</dt> : null}
                {props.playerCompareObj.rbi ? <dd className={props.playerCompareObj.rbi.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.rbi.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.rbi.right.hasWin ? "blue" : ""}>{props.playerCompareObj.rbi.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.sb ? <dt>{props.playerCompareObj.sb.name}</dt> : null}
                {props.playerCompareObj.sb ? <dd className={props.playerCompareObj.sb.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.sb.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.sb.right.hasWin ? "blue" : ""}>{props.playerCompareObj.sb.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.kk ? <dt>{props.playerCompareObj.kk.name}</dt> : null}
                {props.playerCompareObj.kk ? <dd className={props.playerCompareObj.kk.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.kk.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.kk.right.hasWin ? "blue" : ""}>{props.playerCompareObj.kk.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.bb ? <dt>{props.playerCompareObj.bb.name}</dt> : null}
                {props.playerCompareObj.bb ? <dd className={props.playerCompareObj.bb.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bb.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bb.right.hasWin ? "blue" : ""}>{props.playerCompareObj.bb.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.pa ? <dt>{props.playerCompareObj.pa.name}</dt> : null}
                {props.playerCompareObj.pa ? <dd className={props.playerCompareObj.pa.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.pa.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.pa.right.hasWin ? "blue" : ""}>{props.playerCompareObj.pa.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.ab ? <dt>{props.playerCompareObj.ab.name}</dt> : null}
                {props.playerCompareObj.ab ? <dd className={props.playerCompareObj.ab.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.ab.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.ab.right.hasWin ? "blue" : ""}>{props.playerCompareObj.ab.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.run ? <dt>{props.playerCompareObj.run.name}</dt> : null}
                {props.playerCompareObj.run ? <dd className={props.playerCompareObj.run.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.run.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.run.right.hasWin ? "blue" : ""}>{props.playerCompareObj.run.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.h2 ? <dt>{props.playerCompareObj.h2.name}</dt> : null}
                {props.playerCompareObj.h2 ? <dd className={props.playerCompareObj.h2.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.h2.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.h2.right.hasWin ? "blue" : ""}>{props.playerCompareObj.h2.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.h3 ? <dt>{props.playerCompareObj.h3.name}</dt> : null}
                {props.playerCompareObj.h3 ? <dd className={props.playerCompareObj.h3.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.h3.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.h3.right.hasWin ? "blue" : ""}>{props.playerCompareObj.h3.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.slg ? <dt>{props.playerCompareObj.slg.name}</dt> : null}
                {props.playerCompareObj.slg ? <dd className={props.playerCompareObj.slg.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.slg.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.slg.right.hasWin ? "blue" : ""}>{props.playerCompareObj.slg.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.bra ? <dt>{props.playerCompareObj.bra.name}</dt> : null}
                {props.playerCompareObj.bra ? <dd className={props.playerCompareObj.bra.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.bra.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.bra.right.hasWin ? "blue" : ""}>{props.playerCompareObj.bra.right.value}</em>
                </dd> : null}

                {props.playerCompareObj.ops ? <dt>{props.playerCompareObj.ops.name}</dt> : null}
                {props.playerCompareObj.ops ? <dd className={props.playerCompareObj.ops.right.hasWin ? "on" : ""}>
                    <p><span style={{ width: `${props.playerCompareObj.ops.right.rate}%` }}></span></p>
                    <em className={props.playerCompareObj.ops.right.hasWin ? "blue" : ""}>{props.playerCompareObj.ops.right.value}</em>
                </dd> : null}
            </dl>
        </div>
    </div>
)



export default ContComponent;
