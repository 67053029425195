import React from 'react';
import SubMenuComponent from '../common/SubMenu.component';

const ContComponent = (props) => (
    <div className="mb_cont sub_cont gameH">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu}
                subMenuName={props.subMenuName}
                thirdMenu={props.thirdMenu}
                thirdMenuName={props.thirdMenuName} />
            <section className="calculator">
                <table className="table_Typ01 mbScroll mbThNofix">
                    <caption></caption>
                    <colgroup>
                        <col width="12.5%" />
                        <col width="25%" />
                        <col width="38%" />
                        <col width="12%" />
                        <col width="12.5%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>공식 및 기준</th>
                            <th colSpan="2">계산</th>
                            <th>결과</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>승률</th>
                            <td>
                                <div className="formula">
                                    <p>승</p>
                                    <p>(승 + 패)</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.winningRateObj.winCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'winningRateObj', 'winCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'winningRateObj', 'winCnt')} /></p>
                                    <p>(<input type="text"
                                        value={props.winningRateObj.winCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'winningRateObj', 'winCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'winningRateObj', 'winCnt')} /> +
                                        <input type="text"
                                            value={props.winningRateObj.lostCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'winningRateObj', 'lostCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'winningRateObj', 'lostCnt')}
                                        />)</p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'winningRateObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.winningRateObj.result} />
                            </td>
                        </tr>
                        <tr>
                            <th>승차</th>
                            <td>
                                <div className="formula">
                                    <p>(A승수 - B승수)  - (A패수 - B패수)</p>
                                    <p>2</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p>(<input type="text"
                                        value={props.subWinningObj.winCnt1}
                                        onBlur={evt => props.onBlurInput(evt, 'subWinningObj', 'winCnt1')}
                                        onChange={evt => props.onChangeInput(evt, 'subWinningObj', 'winCnt1')} /> -
                                        <input type="text"
                                            value={props.subWinningObj.winCnt2}
                                            onBlur={evt => props.onBlurInput(evt, 'subWinningObj', 'winCnt2')}
                                            onChange={evt => props.onChangeInput(evt, 'subWinningObj', 'winCnt2')}
                                        />)  -
                                        (<input type="text"
                                            value={props.subWinningObj.lostCnt1}
                                            onBlur={evt => props.onBlurInput(evt, 'subWinningObj', 'lostCnt1')}
                                            onChange={evt => props.onChangeInput(evt, 'subWinningObj', 'lostCnt1')}
                                        /> - <input type="text"
                                            value={props.subWinningObj.lostCnt2}
                                            onBlur={evt => props.onBlurInput(evt, 'subWinningObj', 'lostCnt2')}
                                            onChange={evt => props.onChangeInput(evt, 'subWinningObj', 'lostCnt2')}
                                        />)</p>
                                    <p>2</p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'subWinningObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.subWinningObj.result} /></td>
                        </tr>
                        <tr>
                            <th>타율</th>
                            <td>
                                <div className="formula">
                                    <p>안타수</p>
                                    <p>타수</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.hitRateObj.hitCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'hitRateObj', 'hitCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'hitRateObj', 'hitCnt')} /></p>
                                    <p><input type="text"
                                        value={props.hitRateObj.swingCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'hitRateObj', 'swingCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'hitRateObj', 'swingCnt')} /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'hitRateObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.hitRateObj.result} /></td>
                        </tr>
                        <tr>
                            <th>평균자책점</th>
                            <td>
                                <div className="formula">
                                    <p>투수의 자책점 × 9</p>
                                    <p>투구횟수</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.avgFailedScoreObj.failedScore}
                                        onBlur={evt => props.onBlurInput(evt, 'avgFailedScoreObj', 'failedScore')}
                                        onChange={evt => props.onChangeInput(evt, 'avgFailedScoreObj', 'failedScore')} /> × 9</p>
                                    <p><input type="text"
                                        value={props.avgFailedScoreObj.pitchCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'avgFailedScoreObj', 'pitchCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'avgFailedScoreObj', 'pitchCnt')} /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'avgFailedScoreObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.avgFailedScoreObj.result} /></td>
                        </tr>
                        <tr>
                            <th>출루율</th>
                            <td>
                                <div className="formula">
                                    <p>안타수 + 사사구</p>
                                    <p>타수 + 사사구 + 희비</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.onBaseAvgObj.hitCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'onBaseAvgObj', 'hitCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'onBaseAvgObj', 'hitCnt')} /> +
                                        <input type="text"
                                            value={props.onBaseAvgObj.deadBallCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'onBaseAvgObj', 'deadBallCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'onBaseAvgObj', 'deadBallCnt')} /></p>
                                    <p><input type="text"
                                        value={props.onBaseAvgObj.swingCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'onBaseAvgObj', 'swingCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'onBaseAvgObj', 'swingCnt')} /> +
                                        <input type="text"
                                            value={props.onBaseAvgObj.deadBallCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'onBaseAvgObj', 'deadBallCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'onBaseAvgObj', 'deadBallCnt')} /> +
                                        <input type="text"
                                            value={props.onBaseAvgObj.sacrificeFlyCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'onBaseAvgObj', 'sacrificeFlyCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'onBaseAvgObj', 'sacrificeFlyCnt')} />
                                    </p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'onBaseAvgObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.onBaseAvgObj.result} /></td>
                        </tr>
                        <tr>
                            <th>장타율</th>
                            <td>
                                <div className="formula">
                                    <p>루타수</p>
                                    <p>타수</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.sluggingRateObj.hitCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'sluggingRateObj', 'hitCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'sluggingRateObj', 'hitCnt')} />
                                    </p>
                                    <p><input type="text"
                                        value={props.sluggingRateObj.swingCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'sluggingRateObj', 'swingCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'sluggingRateObj', 'swingCnt')} /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'sluggingRateObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.sluggingRateObj.result} /></td>
                        </tr>
                        <tr>
                            <th>수비율</th>
                            <td>
                                <div className="formula">
                                    <p>자살 + 보살</p>
                                    <p>자살 + 보살 + 실책</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.defenceRateObj.putOut}
                                        onBlur={evt => props.onBlurInput(evt, 'defenceRateObj', 'putOut')}
                                        onChange={evt => props.onChangeInput(evt, 'defenceRateObj', 'putOut')} /> +
                                    <input type="text"
                                            value={props.defenceRateObj.assist}
                                            onBlur={evt => props.onBlurInput(evt, 'defenceRateObj', 'assist')}
                                            onChange={evt => props.onChangeInput(evt, 'defenceRateObj', 'assist')} /></p>
                                    <p><input type="text"
                                        value={props.defenceRateObj.putOut}
                                        onBlur={evt => props.onBlurInput(evt, 'defenceRateObj', 'putOut')}
                                        onChange={evt => props.onChangeInput(evt, 'defenceRateObj', 'putOut')} /> +
                                    <input type="text"
                                            value={props.defenceRateObj.assist}
                                            onBlur={evt => props.onBlurInput(evt, 'defenceRateObj', 'assist')}
                                            onChange={evt => props.onChangeInput(evt, 'defenceRateObj', 'assist')} /> +
                                    <input type="text"
                                            value={props.defenceRateObj.failedCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'defenceRateObj', 'failedCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'defenceRateObj', 'failedCnt')} /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'defenceRateObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.defenceRateObj.result} /></td>
                        </tr>
                        <tr>
                            <th>규정타석</th>
                            <td>
                                <div className="formula noLine">
                                    경기수 × 3.1
							</div>
                            </td>
                            <td>
                                <div className="formula noLine">
                                    <input type="text"
                                        value={props.plateObj.gameCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'plateObj', 'gameCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'plateObj', 'gameCnt')} /> × 3.1
							</div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'plateObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.plateObj.result} /></td>
                        </tr>
                        <tr>
                            <th>규정투구횟수</th>
                            <td>
                                <div className="formula noLine">
                                    경기수 × 1<br />(2군 : 경기수 × 80%)
							</div>
                            </td>
                            <td>
                                <div className="formula noLine">
                                    <input type="text"
                                        value={props.pitchObj.gameCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'pitchObj', 'gameCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'pitchObj', 'gameCnt')} /> × 1
							</div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'pitchObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.pitchObj.result} /></td>
                        </tr>
                        <tr>
                            <th>OPS</th>
                            <td>
                                <div className="formula noLine">
                                    출루율 + 장타율
							</div>
                            </td>
                            <td>
                                <div className="formula noLine">
                                    <input type="text"
                                        value={props.opsObj.runRate}
                                        onBlur={evt => props.onBlurInput(evt, 'opsObj', 'runRate')}
                                        onChange={evt => props.onChangeInput(evt, 'opsObj', 'runRate')} /> +
                                        <input type="text"
                                        value={props.opsObj.longHitRate}
                                        onBlur={evt => props.onBlurInput(evt, 'opsObj', 'longHitRate')}
                                        onChange={evt => props.onChangeInput(evt, 'opsObj', 'longHitRate')} />
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'opsObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.opsObj.result} /></td>
                        </tr>
                        <tr>
                            <th>피안타율</th>
                            <td>
                                <div className="formula">
                                    <p>안타</p>
                                    <p>타수</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.obaObj.hitCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'obaObj', 'hitCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'obaObj', 'hitCnt')}
                                    /></p>
                                    <p><input type="text"
                                        value={props.obaObj.swingCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'obaObj', 'swingCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'obaObj', 'swingCnt')}
                                    /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'obaObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.obaObj.result} /></td>
                        </tr>
                        <tr>
                            <th>출루허용율<br />(WHIP)</th>
                            <td>
                                <div className="formula">
                                    <p>안타 + 사사구</p>
                                    <p>이닝</p>
                                </div>
                            </td>
                            <td>
                                <div className="formula">
                                    <p><input type="text"
                                        value={props.whipObj.hitCnt}
                                        onBlur={evt => props.onBlurInput(evt, 'whipObj', 'hitCnt')}
                                        onChange={evt => props.onChangeInput(evt, 'whipObj', 'hitCnt')} /> +
                                    <input type="text"
                                            value={props.whipObj.deadBallCnt}
                                            onBlur={evt => props.onBlurInput(evt, 'whipObj', 'deadBallCnt')}
                                            onChange={evt => props.onChangeInput(evt, 'whipObj', 'deadBallCnt')} /></p>
                                    <p><input type="text"
                                        value={props.whipObj.inning}
                                        onBlur={evt => props.onBlurInput(evt, 'whipObj', 'inning')}
                                        onChange={evt => props.onChangeInput(evt, 'whipObj', 'inning')}
                                    /></p>
                                </div>
                            </td>
                            <td><button type="button"
                                onClick={evt => props.onClickBtn(evt, 'whipObj')}>계산</button></td>
                            <td><input type="text"
                                value={props.whipObj.result} /></td>
                        </tr>
                        <tr>
                            <th>수비최고선수규정</th>
                            <td colSpan="4" className="tLeft">
                                <p>포 수 : 전경기의 50% 이상 출전</p>
                                <p>내야수 : 전경기의 2/3 이상 출전</p>
                                <p>외야수 : 전경기의 2/3 이상 출전</p>
                            </td>
                        </tr>
                        <tr>
                            <th>최우수신인선수기준</th>
                            <td colSpan="4" className="tLeft">
                                <p>다음과 같은 누계 출장수를 초과하지 않은 자</p>
                                <ul>
                                    <li>- 5시즌 이내 (당해 시즌 제외)</li>
                                    <li>- 투수는 30회 이내 (당해 시즌 제외)</li>
                                    <li>- 타자는 60타석 이내 (당해 시즌 제외)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>포스트시즌출장자격</th>
                            <td colSpan="4" className="tLeft">
                                <p>매년 7월 31일 현재 구단 소속 선수<br />(단, 군 제대 선수 제외, 외국인 선수는 8월 15일까지 등록된 선수에 한 함)</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    </div>
)

export default ContComponent;
