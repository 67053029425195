import React from "react";

const ContComponent = (props) => (
  <div className="mb_cont login">
    <div className="mb_contIn three_month_change">
      <form>
        <fieldset>
          <p>
            <h1>회원님의 비밀번호를 변경해주세요</h1>
            "KIA TIGERS"에서는
            <br className="mb" />
            회원님의 소중한 개인정보를 보호하기 위하여
            <br />
            <strong>3개월마다 비밀번호 변경을 안내해드리고 있습니다.</strong>
            <br />
            비밀번호 변경을 원하지 않을 경우,
            <br className="mb" /> "다음에 변경"을 누르시면 3개월 동안 변경
            안내가 가지 않습니다.
          </p>

          <div className="change_pw_area">
            <div>
              <label>현재 비밀번호</label>
              <input
                type="password"
                value={props.currentPwVal}
                onChange={(evt) => {
                  props.onChangePwVal(evt, "currentPwVal");
                }}
              ></input>
            </div>
            <div>
              <label>새 비밀번호 입력</label>
              <input
                type="password"
                value={props.changePwVal}
                onChange={(evt) => {
                  props.onChangePwVal(evt, "changePwVal");
                }}
              ></input>
            </div>
            <div>
              <label>새 비밀번호 확인</label>
              <input
                type="password"
                value={props.changePwValCk}
                onChange={(evt) => {
                  props.onChangePwVal(evt, "changePwValCk");
                }}
              ></input>
            </div>
          </div>

          <p className="pw_caution">
            ㆍ <strong>8자리 이상 영문, 숫자, 특수문자 조합</strong>으로
            변경해주세요.
            <br />ㆍ ID, 주민번호, 생일, 전화번호 등의 개인정보와 관련된 숫자,
            연속된 숫자, 반복된 문자등 타인이 쉽게 알아 낼 수 있는 비밀번호는
            <br className="pc" />
            개인정보가 유출될 가능성이 높으므로 사용을 자제해 주세요.
          </p>

          <div className="btn-area">
            <button
              type="button"
              className="btnLogin"
              onClick={() => props.onClickBtnChangePw()}
            >
              비밀번호 변경
            </button>
            <button
              type="button"
              className="btnLogin"
              onClick={() => props.onClickBtnDelayChangePw()}
            >
              다음에 변경
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
);

export default ContComponent;
