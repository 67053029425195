import React, {useCallback} from "react"
import {parse} from "date-fns"
import * as GoodsAuctionserviceAndBid from "../../../services/GoodsAuctionAndBid.service"
import {useQuery} from "react-query"
import {useHistory} from "react-router-dom"
import GoodsAuctionTab from "./GoodsAuctionTab"

function MyGoodsBidList() {
  let history = useHistory()
  const {isLoading, data} = useQuery("myGoodsBidList", GoodsAuctionserviceAndBid.auctionBidList, {staleTime: 1000})

  const onClickBiddingGoods = useCallback(
    (id) => {
      history.push(`/auction/my-bid-list/${id}`)
    },
    [history]
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <GoodsAuctionTab>
      <div className="goods-list-products">
        <ul>
          {data && data.list.length > 0 ? (
            data.list.map((bidding) => (
              <li className="card" key={bidding.productId} onClick={() => onClickBiddingGoods(bidding.productId)}>
                <div className="card-header">
                  <img
                    src={bidding.imagePath && bidding.imagePath.length > 0 ? bidding.imagePath : "/img/main/no_goods_img.png"}
                    alt={`${bidding.productName} 이미지`}
                  />
                </div>
                <div className="card-body">
                  <h5>{bidding.productName}</h5>
                  <p className={parse(bidding.endDt, "yyyy-MM-dd HH:mm:ss", 0) < Date.now() ? "bid-price" : "current-price"}>{`${
                    parse(bidding.endDt, "yyyy-MM-dd HH:mm:ss", 0) < Date.now() ? "낙찰가" : "현재가"
                  }: ${bidding.currentPrice.toLocaleString("en")}원`}</p>
                </div>
              </li>
            ))
          ) : (
            <div className="no-bidding-goods">내 입찰목록이 없습니다</div>
          )}
        </ul>
      </div>
    </GoodsAuctionTab>
  )
}

export default MyGoodsBidList
