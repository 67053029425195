import React, { Component } from "react";
import ContComponent from "./Cont.component";
import * as UserService from "../../../services/User.service";

class InactiveIdContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certData: null,
      realNameAuthType: null,
      idValue: this.props.idObj.value,
    };
  }

  componentDidMount = () => {
    window.addEventListener("message", this.onMessage);
  };

  componentWillUnmount = () => {
    window.removeEventListener("message", this.onMessage);
  };

  onClickBtnOpenCellAuth = () => {
    window.open(
      "/v1/user/identify/phone/request",
      "mobilem",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  onClickBtnOpenIpinAuth = () => {
    window.open(
      "/v1/user/identify/ipin/request",
      "ipin2",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
    );
  };

  onMessage = async (evt) => {
    if (evt.data.type === "IPIN2" || evt.data.type === "MOBILEM") {
      try {
        const simpleId = this.state.idValue;
        let realNameAuthType = this.state.realNameAuthType;
        let certData = this.state.certData;

        realNameAuthType = evt.data.type;
        certData = evt.data.data;

        const result = await UserService.activeUserAcount(
          { realNameAuthType, certData },
          simpleId
        );

        if (result === true) {
          alert("휴면상태가 해제되었습니다.");
          window.location.href = "/";
        }
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    }
  };

  render = () => (
    <div className="container">
      <ContComponent
        onClickBtnOpenCellAuth={this.onClickBtnOpenCellAuth}
        onClickBtnOpenIpinAuth={this.onClickBtnOpenIpinAuth}
      />
    </div>
  );
}

export default InactiveIdContainer;
