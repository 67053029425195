import * as CommonHelper from './Common.helper';

export const getContentsPressListNoImg = _ => "/img/main/sl3_no_img.jpg"
export const getContentsNewsListNoImg = _ => "/img/main/sl3_no_img.jpg"
export const getDefaultPlayerListImg = _ => "/img/sub/no_img_color.png"
export const getDefaultPlayerListOffImg = _ => "/img/sub/no_img.png"
export const getDefaultHotIssueItemImg = _ => '/img/sub/list_no_img.png'
export const getEmblemImgByTeamCode = teamCode => `/img/emblems/emblem_${teamCode.toLowerCase()}.png`;
export const getEmblemImgByTeamName = teamName => getEmblemImgByTeamCode(CommonHelper.getTeamCodeByName(teamName))

// playerImg = 목록에 쓰이는 이미지
// playerOffImg = 목록이미지에 흑백버젼
// playerProfileImg = 상세에 쓰이는 큰이미지
// playerRecordImg = 기록비교에쓰이는 작은 이미지