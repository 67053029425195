import React from "react";

const ContComponent = ({ onClickBtnOpenIpinAuth, onClickBtnOpenCellAuth }) => {
  return (
    <div className="mb_cont login">
      <div className="mb_contIn user_inactive">
        <form>
          <fieldset>
            <p>
              <h1>회원님 아이디는 휴면 상태로 전환 되었습니다.</h1>
              회원님은 로그인 한 지 1년 이상이 경과되어 아이디가
              <br className="mb" />
              휴면 상태로 전환되었습니다.
              <br />
              본인 확인 후 다시 KIA TIGERS 서비스 이용이 가능합니다.
            </p>
          </fieldset>
          <div className="active_btn">
            <button type="button" onClick={onClickBtnOpenCellAuth}>
              휴대폰 인증
            </button>
            <button type="button" onClick={onClickBtnOpenIpinAuth}>
              아이핀 인증
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContComponent;
