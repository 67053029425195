import {endOfMinute, format, isPast, parse} from "date-fns"
import React from "react"
import {useHistory, useParams} from "react-router-dom"
import {useMutation, useQuery} from "react-query"
import {useAlert, usePopup} from "../../../context/PopupContext"
import * as GoodsAuctionService from "../../../services/GoodsAuction.service"
import Markdown from "markdown-to-jsx"
import {agreement} from "./contract-agreement"
import {notice} from "./notice"
import * as AuthHelper from "../../../helpers/Auth.helper"
import * as UserService from "../../../services/User.service"
import { decodeAES256 } from "../../../helpers/Crypto.helper"

// 이름 마지막을 *로 대체
function maskName(name) {
  return name.slice(0, name.length - 1) + "*"
}

function GoodsDetail() {
  const history = useHistory()
  const {id} = useParams()
  const {openAlert} = useAlert()
  const {openPopup, closePopup} = usePopup()
  const [agreed, setAgreed] = React.useState({
    confirm: false,
    contract: false
  })

  const {isLoading, data, refetch} = useQuery(["goodsProductDetail", id], () => GoodsAuctionService.getGoodsDetail(id), {
    onSuccess: (detail) => {
      setBidPrice((detail.currentPrice ?? detail.startPrice) + detail.bidUnit)
    },
    staleTime: 1000,
    refetchOnWindowFocus: false
  })
  const {isLoading: isLoadingMyInfo, data: myInfo} = useQuery("MyInfo", () => UserService.getMyInfo(), {
    onSuccess: (data) => {
      setBidderName(decodeAES256(data.user.memberName))
      setPhoneNumber(decodeAES256(data.user.mobpNo))
    },
    staleTime: 1000,
    refetchOnWindowFocus: false
  })

  const loginInfo = AuthHelper.getLoginInfo()
  const [bidPrice, setBidPrice] = React.useState(data ? data.currentPrice ?? data.startPrice + data.bidUnit : 0)
  const [bidderName, setBidderName] = React.useState(loginInfo ? decodeAES256(loginInfo.user.memberName) : "")
  const [phoneNumber, setPhoneNumber] = React.useState(loginInfo ? decodeAES256(loginInfo.user.mobpNo) : "")
  const inputRef = React.useRef(null)
  const allAgreed = agreed.confirm && agreed.contract

  React.useEffect(() => {
    if (data) {
      const endDate = parse(data.endDt, "yyyy-MM-dd HH:mm:ss", 0)
      if (isPast(endOfMinute(endDate))) {
        openAlert("경매가 마감되었습니다.", () => {
          console.log("경매가 마감되었습니다.")
          history.replace("/auction/goods-list")
        })
      }
      // if (myBid) {
      //   if (isToday(myBid.bidDate)) {
      //     openAlert("경매는 1일 1회만 참여가능합니다.")
      //   } else if (myBid.myBidPrice === data.currentPrice) {
      //     openAlert("현재 최고 입찰자입니다. 더 이상 입찰하실 수 없습니다.", () => {
      //       history.goBack()
      //     })
      //   }
      // }
    }
  }, [data, history, openAlert])

  const onChangeAgree = React.useCallback(
    (e) => {
      setAgreed({...agreed, [e.target.name]: e.target.checked})
    },
    [agreed]
  )

  const onClickShowInputField = () => {
    if (inputRef != null) {
      inputRef.current.style.visibility = "visible"
      inputRef.current.focus()
      inputRef.current.setSelectionRange(0, inputRef.current.value.length)
    }
  }

  // blur 처리 시 유효값 체크
  const onBlurCheckInputData = (e) => {
    const currentElement = e.currentTarget
    currentElement.style.visibility = "hidden"
    if (bidPrice < minimumPrice) {
      openAlert("현재가보다 낮게 입찰하실 수 없습니다.")
      setBidPrice(minimumPrice)
    } else if (bidPrice > maximumPrice) {
      openAlert(`최대 입찰금액은 ${maximumPrice.toLocaleString("en")} 입니다.`)
      setBidPrice(maximumPrice)
    } else if (bidPrice % 1000 !== 0) {
      openAlert("1000원 단위로 입력해주세요")
      setBidPrice(Math.floor(bidPrice / 1000) * 1000)
    }
    console.log("keycode ::: ", e.target.value)
  }

  const onChangeInputBidPrice = React.useCallback((e) => {
    const value = e.target.value.replace(/\D/g, "")
    setBidPrice(Number(value))
  }, [])

  const onChangeBidderName = React.useCallback((e) => {
    setBidderName(e.target.value)
  }, [])

  const onChangePhoneNumber = React.useCallback((e) => {
    setPhoneNumber(e.target.value)
  }, [])

  const bidGoods = useMutation((bid) => GoodsAuctionService.bidGoods(bid), {
    onSuccess: (result) => {
      if (result.create) {
        openAlert("입찰에 성공하였습니다.", refetch())
        window.scrollTo({top: 0})
        setBidderName(myInfo ? decodeAES256(myInfo.user.memberName) : "")
        setPhoneNumber(myInfo ? decodeAES256(myInfo.user.mobpNo) : "")
        setAgreed({
          confirm: false,
          contract: false
        })
      } else {
        console.error("bidGoods", result)
        openAlert("입찰에 실패했습니다.")
      }
    },
    onError: (error) => {
      openAlert(error.message)
    }
  })

  if (isLoading || isLoadingMyInfo) {
    return <div>Loading...</div>
  }

  const startDate = parse(data.startDt, "yyyy-MM-dd HH:mm:ss", 0)
  const endDate = parse(data.endDt, "yyyy-MM-dd HH:mm:ss", 0)
  const currentPrice = data.currentPrice ?? data.startPrice
  const bidUnit = data.bidUnit
  const minimumPrice = currentPrice + bidUnit
  const maximumPrice = currentPrice + 20000

  const onClickPlus = () => {
    const newBidPrice = bidPrice + bidUnit > maximumPrice ? bidPrice : bidPrice + bidUnit
    setBidPrice(newBidPrice)
  }

  const onClickMinus = () => {
    const newBidPrice = bidPrice - bidUnit < minimumPrice ? bidPrice : bidPrice - bidUnit
    setBidPrice(newBidPrice)
  }

  // 약관 체크박스 클릭 시 event handler
  const onClickAgreeContract = () => {
    if (agreed.contract) {
      setAgreed({...agreed, contract: false})
    } else {
      openPopup(
        <div className="agree-popup">
          <div className="title">
            <h2>약관동의</h2>
            <div className="close" onClick={closePopup}>
              <img src="/img/main/snb_close.png" alt="닫기이미지" />
            </div>
          </div>
          <Markdown className="markdown">{agreement}</Markdown>
          <div className="bottom">
            <div className="button-wrapper">
              <button
                onClick={() => {
                  closePopup()
                  setAgreed({...agreed, contract: true})
                }}
              >
                동의하기
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const onClickBid = () => {
    if (!data) {
      openAlert("유효한 경매 굿즈가 아닙니다.")
      return
    }

    if (bidderName.length < 1) {
      openAlert("입찰자 이름을 입력해 주세요.")
      return
    }

    if (!/\b\d{9,11}\b/.test(phoneNumber)) {
      openAlert("전화번호가 유효하지 않습니다. '설정 > 계정설정'에서 업데이트해 주세요.")
      return
    }
    const bid = {
      bidderName,
      phoneNumber,
      productId: data.productId,
      bidPrice,
      termsYn: allAgreed ? "Y" : "N"
    }

    console.log("입찰하기", bid)
    bidGoods.mutate(bid)
  }

  return (
    <div>
      <div className="goods-detail">
        <header>
          <div className="top">{data && <img src={data.imagePath === null ? "/img/main/no_goods_img.png" : data.imagePath} alt="굿즈이미지" />}</div>
          <div className="title">
            <h5>{data.productName}</h5>
          </div>
        </header>
        <main>
          <div className="auction-content">
            <div>
              <div className="info">
                <h6>경매기간</h6>
                <h6>
                  {`${format(startDate, "yy.MM.dd (HH:mm)")}`} ~ <span>{`${format(endDate, "yy.MM.dd (HH:mm)")}`}</span>
                </h6>
              </div>
              <div className="info">
                <h6>입찰참여자</h6>
                <h6>{`${data.bidderCount}명 (최고낙찰자: ${maskName(data.bestBidderName)})`}</h6>
              </div>
            </div>
            <div className="price">
              <div className="startPrice">
                <div>최저가</div>
                <div>{data.startPrice.toLocaleString("en")}원</div>
              </div>
              <div className="currentPrice">
                <div>현재가</div>
                <div>{currentPrice.toLocaleString("en")}원</div>
              </div>
            </div>
            <form className="auction-bid">
              <p>
                입찰금액: <span>{(currentPrice + data.bidUnit).toLocaleString("en")}</span>원 부터 입찰하실 수 있습니다.
                <br />
                {`${data.bidUnit.toLocaleString("en")}`}원 단위로 입력 가능합니다.
              </p>
              <div className="bid-button-box">
                <div className="Btn" onClick={onClickMinus}>
                  <img src="/img/sub/goods_detail_minus.png" alt="이미지" />
                </div>
                <div className="bid-price" onClick={onClickShowInputField}>
                  {bidPrice.toLocaleString("en")}
                  <div className="bid-input">
                    <input ref={inputRef} type="text" name="bidTxt" value={bidPrice} onChange={onChangeInputBidPrice} onBlur={onBlurCheckInputData} />
                  </div>
                </div>
                <div className="Btn" onClick={onClickPlus}>
                  <img src="/img/sub/goods_detail_plus.png" alt="이미지" />
                </div>
              </div>
              <div className="bidder-info">
                <div>입찰자명</div>
                <input type="text" name="bidderName" placeholder="입찰자명을 입력하세요" value={bidderName} onChange={onChangeBidderName} readOnly />
                <div>휴대전화</div>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="'-' 없이 숫자만 입력해주세요"
                  value={phoneNumber}
                  onChange={onChangePhoneNumber}
                  readOnly
                />
                <small>
                  ※ 회원의 휴대전화번호가 변경되었을 경우, 현재 사용 중인 번호로 업데이트를 부탁 드립니다. (모바일 앱 로그인 > 설정 > 계정 설정)
                </small>
              </div>
            </form>
            <div className="agree">
              <div className="agree-content">
                <span>유의사항</span>
                <Markdown className="markdown">{notice}</Markdown>
              </div>
              <div className="agreement-check">
                <div className="agreement">
                  <input type="checkbox" name="confirm" id="confirm" checked={agreed.confirm} onChange={onChangeAgree} />
                  <label htmlFor="confirm">유의사항을 확인했습니다.</label>
                </div>
                <div className="agreement2">
                  <div>
                    <input type="checkbox" name="contract" id="contract" checked={agreed.contract} onChange={onClickAgreeContract} />
                    <label htmlFor="contract">약관 내용을 확인하였으며, 참가자 본인은 개인 정보 제공 및 입찰 참가 약관에 동의합니다.</label>
                  </div>
                  <div onClick={onClickAgreeContract}>
                    <div className="arrow">
                      <img src="/img/sub/arrow_next/drawable-hdpi/arrow.png" alt="이미지" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="bottom">
        <div className="button-wrapper">
          <button disabled={!allAgreed || bidderName.length < 1 || phoneNumber.length < 1 || bidGoods.isLoading} onClick={onClickBid}>
            {bidGoods.isLoading ? "입찰 중입니다." : "입찰하기"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GoodsDetail
