import React from 'react';

const SubHeadComponent = props => (
    <div className="sub_head login">
        <div className="sub_HeadIn1">
            <h2>{props.subMenuName}</h2>
            <ul>
                <li><img src="/img/sub/ico_home.png" alt="홈 바로가기" /></li>
                <li>{props.subMenuName}</li>
            </ul>
        </div>
        <div className="sub_HeadIn2 tb"></div>
    </div>

)
export default SubHeadComponent;