import React from "react";
import QRCode from "react-qr-code";
import dayjs from "dayjs";
dayjs.locale("ko");

let timeTick = [];
const ContComponent = (props) => {
  console.log("props??", props)

  if(timeTick.length > 0) {
    timeTick.map((item, index) => {
      clearInterval(item);
    })
    timeTick = [];
  }

  return (
    <>
      {props.giftTicketInfo && props.giftTicketInfo.length > 0 ? (
        <>
          <div
            className="mb_cont ticket"
            style={{
              backgroundColor: "#EFEFEF",
              paddingTop: "60px",
              marginTop: "0px"
            }}
          >
            <div
              className="ticket_list"
              style={{
                width: "100%",
                height: "50px",
                margin: "10px auto",
                textAlign: "center",
              }}
            >
              <select
                style={{
                  width: "100px",
                  height: "30px",
                  margin: "10px auto",
                  textAlign: "center",
                }}
                onChange={props.onChangeGameDate}
              >
                {props.game_date.map((item, index) => (
                  <option value={item}>{item}</option>
                  ))}
              </select>
            </div>


            <div className="mb_contIn">
              <div className="ticket_list">
                {props.giftTicketInfo.map((item, index) => (
                  // 기본 슬라이드
                  <div
                    className="ticket"
                      key={index}
                      style={{
                        background: 'url("/img/ticket/ticket_bg.png") no-repeat',
                        width: "315px",
                        height: "480px",
                        margin: "10px auto",
                        backgroundSize: "100%",
                        position: "relative",
                      }}
                    >
                      {/* 선발투수 슬라이드 시작 */}
                      <img
                        src={props.pitcherSlide}
                        style={{
                          position: "absolute",
                          top: "7px",
                          left: "11px",
                          height: "280px",
                        }}
                      />
                      {/* 선발투수 슬라이드 끝 */}
                      <img
                        src={item.awayTeamSrc}
                        style={{
                          position: "absolute",
                          top: "190px",
                          left: "25px",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <p
                        className="title"
                        style={{
                          position: "absolute",
                          top: "246px",
                          left: "25px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          letterSpacing: "-5%",
                          color: "#515257",
                        }}
                      >
                        {"VS" + item.match_info.split("vs")[1]}
                      </p>
                      <p
                        className="date"
                        style={{
                          position: "absolute",
                          top: "274px",
                          left: "25px",
                          fontSize: "12px",
                          fontWeight: "light",
                          letterSpacing: "-5%",
                          color: "#515257",
                        }}
                      >
                        {item.game_date}
                      </p>

                      <div
                        style={{
                          position: "absolute",
                          top: "336px",
                          left: "25px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          letterSpacing: "-5%",
                          color: "#515257",
                          lineHeight: "1.5",
                        }}
                      >
                        <p>{item.product_grade_name} </p>
                        <p>{item.seat_info}</p>
                        <p>{item.product_denomination_name}</p>
                        <p
                          className="ticket_no"
                          style={{
                            fontWeight: "lighter",
                          }}
                        >
                          {item.ticket_no}
                        </p>
                      </div>

                      <QRCode
                        value={item.ticket_issue_no}
                        size={78}
                        style={{
                          position: "absolute",
                          top: "336px",
                          right: "28px",
                        }}
                      />
                      <div
                        style={{
                          display: "none",
                        }}
                      >
                        {/* 시간.  */}
                        { timeTick.push(setInterval(() => {
                          document.getElementById("clock" + index).innerHTML =
                            dayjs().format("HH:mm:ss");
                          document.getElementById(
                            "dot" + index
                          ).style.visibility =
                            document.getElementById("dot" + index).style
                              .visibility == "hidden"
                              ? "visible"
                              : "hidden";
                        }, 1000))}
                      </div>
                      <span
                        id={"dot" + index}
                        style={{
                          color: "#FF0000",
                          fontSize: "8px",
                          position: "absolute",
                          top: "423px",
                          right: "70px",
                          visibility: "hidden",
                        }}
                      >
                      ●
                    </span>
                      <p
                        id={"clock" + index}
                        style={{
                          color: "#58595B",
                          fontSize: "10px",
                          position: "absolute",
                          top: "423px",
                          right: "29px",
                          fontWeight: "lighter",
                          letterSpacing: "-5%",
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
          ) : (
          <div className="mb_cont join fnd pw">
            <div className="mb_contIn">
              <div className="box">
                <div>
                  <h4>핸드폰 번호 확인</h4>
                  <p>
                    선물받는 분의 정확한 본인 확인을 위해 핸드폰 번호를
                    입력해주세요.
                </p>
              </div>
              <form action="/" method="post">
                <fieldset>
                  <legend>핸드폰 번호 확인</legend>
                  <div>
                    <label htmlFor="userPw">핸드폰 번호</label>
                    <input
                      type="text"
                      id="userPw"
                      value={props.mobile}
                      placeholder="핸드폰 번호 입력"
                      onChange={props.onChangeInput}
                    />
                  </div>
                  {/* 티켓정보 없을 때 */}
                  {props.unconfirmed && <div style={{color: "#ea0029", textAlign: "center"}}>{props.unconfirmed}</div>}
                </fieldset>
                <div className="comp_btn" onClick={props.onChangeSubmit} >
                  확인하기
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContComponent;
