import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as ResourceService from "../../../services/Resource.service";
import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'

class GuiideContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    this.state = {
      subMenu: "guide",
      subMenuName: "포인트 안내",
      imgUrlObj: null,
      tabNum: 1,
    };
  }

  componentDidMount = async (_) => {
    window.location.href = "/point/guide";
    return;

    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "06";
    currentAnalyticsVal.deps3 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    //230130 주석
    const pcResult = await ResourceService.getMembershipGuideImg();
    const mResult = await ResourceService.getMembershipGuideImg(false);

    //230130 주석
    const imgUrlObj = {
      pc: pcResult.resource.value,
      m: mResult.resource.value,
    };

    //230130 주석
    this.setState({ imgUrlObj });
  };

  onClickBtnChangeMembership = (evt, value) => {
    this.setState({ tabNum: value });
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} />

      {/* 230127 주석 */}
      { !CommonHelper.checkIsOnWebview() && (
        <GuideTabComponent
          onClickBtnChangeMembership={this.onClickBtnChangeMembership}
          tabNum={this.state.tabNum}
        />
      )}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        imgUrlObj={this.state.imgUrlObj}
        tabNum={this.state.tabNum}
      /> }
    </div>
  );
}

export default GuiideContainer;
