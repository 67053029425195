import React, {Component} from "react"
import SubHeadComponent from "../common/SubHead.component"
import ContComponent from "./Cont.component"

import AnalyticsContext from "../../../context/AnalyticsContext"
import * as CommonService from "../../../services/Common.service"
// import Img from '../../common/Img.container';

class GreetingContainer extends Component {
  static contextType = AnalyticsContext

  componentDidMount = async () => {
    const currentAnalyticsVal = this.context.state
    const prevUrl = currentAnalyticsVal.currentUrl
    this.context.action(currentAnalyticsVal)

    currentAnalyticsVal.deps2 = "01"
    currentAnalyticsVal.deps3 = "01"
    currentAnalyticsVal.prevUrl = prevUrl
    currentAnalyticsVal.currentUrl = window.location.href

    await CommonService.insertAnalytics(this.context.state)
  }

  constructor(props) {
    super(props)

    const subMenu = "greeting"
    const subMenuName = "인사말"
    this.state = {
      subMenu,
      subMenuName
    }
  }

  render = (props) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.state.subMenuName} thirdMenuName={this.state.thirdMenuName} />
      {process.env.REACT_APP_PREPARING_GREETING_PAGE_YN === "Y" ? (
        <div className="mb_cont intro1">
          <div className="mb_contIn">
            <div style={{marginTop: "50px"}}>
              <div className="txt clear">
                <img src="/img/KIA_service_stop.png" alt="season_off_pc" className="pc" />
                <img
                  src="/img/sub/tigers_22_off_greeting_pc.png"
                  alt="season_off_mb"
                  className="greeting_mb tb"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ContComponent
          subMenu={this.state.subMenu}
          subMenuName={this.state.subMenuName}
          thirdMenu={this.state.thirdMenu}
          thirdMenuName={this.state.thirdMenuName}
          tabNum={this.state.tabNum}
          onClickBtnTab={this.onClickBtnTab}
        />
      )}

      {/* <Img 
                src="https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
                targetWidth = {200}
                targetHeight = {100} /> */}
    </div>
  )
}

export default GreetingContainer
