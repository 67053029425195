import React, { Component } from "react";
import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as GameService from "../../../../services/Game.service";
import * as CommonService from "../../../../services/Common.service";
import AnalyticsContext from "../../../../context/AnalyticsContext";

class RankingHistoryContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    const subMenu = props.match.url.split("/")[2];
    const thirdMenu = props.match.url.split("/")[3];
    this.state = {
      subMenu,
      subMenuName: "STATS",
      thirdMenu,
      thirdMenuName: "역대성적",
      tabNum: 1,

      list: [],
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "04";
      currentAnalyticsVal.deps3 = "03"; //
      currentAnalyticsVal.deps4 = "02";
      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      const pitcherResult = await GameService.getPitcherRankingByTeamCode("HT");
      const list = pitcherResult.list;

      this.setState({ list });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnTab = async (evt, tabNum) => {
    let list = [];
    if (tabNum == 1) {
      const pitcherResult = await GameService.getPitcherRankingByTeamCode("HT");
      list = pitcherResult.list;
    } else if (tabNum == 2) {
      const hitterResult = await GameService.getHitterRankingByTeamCode("HT");
      list = hitterResult.list;
    }
    this.setState({ list, tabNum });
  };

  render = () => (
    <div className="container">
      <SubHeadComponent
        subMenuName={this.state.subMenuName}
        thirdMenuName={this.state.thirdMenuName}
      />
      <div className="sbHead_appView tb"></div>
      <ContComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
        thirdMenu={this.state.thirdMenu}
        thirdMenuName={this.state.thirdMenuName}
        tabNum={this.state.tabNum}
        onClickBtnTab={this.onClickBtnTab}
        list={this.state.list}
      />
    </div>
  );
}

export default RankingHistoryContainer;
