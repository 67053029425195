import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import listContainer from './list/List.container'
import giftContainer from './gift/List.container'

class SmartTicketContainer extends Component {
    render = () => (
        <Switch>
            <Route exact path={`${this.props.match.path}/list`} component={listContainer} />
            <Route exact path={`${this.props.match.path}/giftTicket`} component={giftContainer} />
        </Switch>
    )
}

export default SmartTicketContainer;
